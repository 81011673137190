import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Input, Switch, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack, Text, Tooltip } from '@chakra-ui/react';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import InstagramAccountUpdateSelector from 'components/adspresso/InstagramAccountUpdateSelector';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectSelectedAdset } from 'store/campaigns/campaignsSlice';
import CreateCarouselAdForm from './CreateCarouselAdForm';
import CreateSingleAdForm from './CreateSingleAdForm';
import { useState } from 'react';
import CreateAdPreviews from './CreateAdPreviews';
import CreateAdPreviewCaro from './CreateAdPreviewCaro';

const call_to_action_options = [
    { value: 'SHOP_NOW', label: 'Shop Now' },
    { value: 'LEARN_MORE', label: 'Learn More' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
];

const call_to_action_options_instantForms = [
    { value: 'SUBSCRIBE', label: 'Subscribe' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
    { value: 'GET_OFFER', label: 'Get offer' },
    { value: 'GET_QUOTE', label: 'Get quote' },
    { value: 'DOWNLOAD', label: 'Download' },
    { value: 'BOOK_TRAVEL', label: 'Book now' },
    { value: 'SIGN_UP', label: 'Sign Up' },
];

function CreateAdForm({ adsAccountData, isOpen, onClose, onSuccess }) {

    const selectedAdset = useSelector(selectSelectedAdset);
    const [formValues, setFormValues] = useState(null);
    const [isEnabled, setIsEnabled] = useState(true);


    let pageIdToSend = null;

    if (
        selectedAdset?.campaign?.objective === "OUTCOME_LEADS" &&
        selectedAdset?.destination_type === "PHONE_CALL" &&
        selectedAdset?.promoted_object?.page_id
    ) {
        pageIdToSend = selectedAdset?.promoted_object?.page_id;
    }

    const adFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            page_id: pageIdToSend ?  pageIdToSend : selectedAdset?.promoted_object?.page_id,
            instagram_actor_id:'',
            page_backed_insta_id: '',
            format: 'SINGLE',
            enroll_status: 'OPT_OUT',
        },
        onSubmit: (values, { setSubmitting, resetForm }) => {

        },
    });

    const renderCreativeComponent = () => {

        if (adFormik.values.format === 'CAROUSEL') {
            return (
                <CreateCarouselAdForm
                    adFormik={adFormik}
                    onClose={onClose}
                    adsAccountData={adsAccountData}
                    onSave={handleSave}
                    objective={selectedAdset?.campaign?.objective}
                    onSuccess={onSuccess}
                    call_to_action_options={call_to_action_options}
                    onFormValuesChange={(values) => setFormValues(values)}
                    call_to_action_options_instantForms={call_to_action_options_instantForms}
                />
            );
        } else if (adFormik.values.format === 'SINGLE') {
            return (
                <CreateSingleAdForm
                    adFormik={adFormik}
                    onClose={onClose}
                    adsAccountData={adsAccountData}
                    onSave={handleSave}
                    objective={selectedAdset?.campaign?.objective}
                    onSuccess={onSuccess}
                    call_to_action_options={call_to_action_options}
                    onFormValuesChange={(values) => setFormValues(values)}
                    call_to_action_options_instantForms={call_to_action_options_instantForms}
                />
            );
        }
    };

    const handleInstagramPageSelection = (instagram_actor_id) => {
        adFormik.setFieldValue('instagram_actor_id', instagram_actor_id);
    };

    const handleFacebookPageSelection = (page_id) => {
        adFormik.setFieldValue('page_id', page_id);
    };

    const handleSave = () => {

        if (!adFormik.values.page_id) {
            adFormik.setFieldError('page_id', 'Facebook page required');
        }

        if (!adFormik.values.name) {
            adFormik.setFieldError('name', 'Ad name is required');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Ad</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex gap={4}>
                        <Flex direction={'column'} flex={0.4}>
                            <form onSubmit={adFormik.handleSubmit}>
                                {/* Ad Name Section */}
                                <Flex direction="column" mb={4} bg={'gray.50'} p={4} borderRadius="md" gap={4}>
                                    <Flex direction={'column'} gap={2}>
                                        <Flex>
                                            <Text fontWeight="bold">Ad name</Text>
                                            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                        </Flex>
                                        <Input
                                            placeholder="Enter your ad name here..."
                                            name="name"
                                            value={adFormik.values.name}
                                            onChange={adFormik.handleChange}
                                            onBlur={adFormik.handleBlur}
                                            size={'sm'}
                                            borderRadius={'md'}
                                        />
                                        <Text color="red.500" fontSize={'12px'}>{adFormik.errors.name}</Text>
                                    </Flex>

                                    {/* Identity Section */}
                                    <Flex direction="column" gap={2}>
                                        <Text fontWeight="bold">Identity</Text>
                                        <Flex alignItems={'center'}>
                                            <Text fontWeight="bold">Facebook pages</Text>
                                            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p="5px">
                                                        <Text>Your Facebook Pages or Instagram accounts represents your business in ads. </Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" />
                                            </Tooltip>
                                        </Flex>
                                        <FaceBookPageSelector
                                            adsAccount={adsAccountData}
                                            pageIdToSend={pageIdToSend ? pageIdToSend : ''}
                                            selectedPageId={selectedAdset?.promoted_object?.page_id ? selectedAdset?.promoted_object?.page_id : ''}
                                            onSelectedPage={(page) => handleFacebookPageSelection(page)}
                                        />
                                        <Text color="red.500" fontSize={'12px'}>{adFormik.errors.page_id}</Text>

                                        <Divider />
                                        <Text fontWeight="bold">Instagram accounts</Text>

                                        <InstagramAccountUpdateSelector
                                            pageID={adFormik.values?.page_id}
                                            adsAccount={adsAccountData}
                                            selectedInstPageId={adFormik.values.creative?.object_story_spec?.instagram_actor_id}
                                            onSelectedPage={(page) => handleInstagramPageSelection(page)}
                                            isNewAdCreating={true}
                                            onPageBack={(pageId) => {
                                                adFormik.setFieldValue('page_backed_insta_id', pageId);
                                            }}                                        />

                                    </Flex>
                                </Flex>

                                {/* Ad Setup Section */}
                                <Flex direction="column" bg={'gray.50'} pl={4} pt={4} borderRadius="md" gap={2} mb={4}>

                                    {/* Creative Source Section */}
                                    <Flex direction="column" borderRadius="md" gap={2}>
                                        <Flex align={'center'}>
                                            <Text fontWeight="bold">Creative source &nbsp;</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p="5px">
                                                        <Text>Choose how you'd like to provide the media for your ad. </Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" />
                                            </Tooltip>
                                        </Flex>

                                    </Flex>

                                    {/* Format Section */}
                                    <Flex direction="column" borderRadius="md" gap={2}>
                                        <Flex align={'center'}>
                                            <Text fontWeight="bold">Format &nbsp;</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p="5px">
                                                        <Text>Choose how you'd like to structure your ad. </Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" />
                                            </Tooltip>
                                        </Flex>

                                        <RadioGroup
                                            onChange={(value) => {
                                                adFormik.setFieldValue("format", value);
                                                adFormik.setFieldValue('mediaOption', '')
                                            }}
                                            value={adFormik.values.format}
                                        >
                                            <Stack spacing={5} direction='column'>
                                                {/* Single image or video option */}
                                                <Flex direction="column">
                                                    <Flex align="center">
                                                        <Radio colorScheme='orange' value='SINGLE' />
                                                        <Text fontWeight="bold" ml={2}>Single image or video</Text>
                                                    </Flex>
                                                    <Text fontSize={'sm'} ml={6}>One image or video, or a slideshow with multiple images</Text>
                                                </Flex>

                                                {/* Carousal format option */}
                                                <Flex direction="column">
                                                    <Flex align="center">
                                                        <Radio colorScheme='orange' value='CAROUSEL' />
                                                        <Text fontWeight="bold" ml={2}>Carousel</Text>
                                                    </Flex>
                                                    <Text fontSize={'sm'} ml={6}>Two or more scrollable images or videos</Text>
                                                </Flex>
                                            </Stack>
                                        </RadioGroup>
                                    </Flex>         
                                    <Divider my={2}/>
                                    {/* Multi-advertiser ads Section */}
                                    <Flex direction="column" mb={2} borderRadius="md" gap={2}>
                                        <Checkbox
                                            onChange={(e) => {
                                                adFormik.setFieldValue("enroll_status", e.target.checked ? 'OPT_IN' : 'OPT_OUT');
                                            }}
                                            colorScheme="orange"
                                            isChecked={adFormik.values.enroll_status === 'OPT_IN'}
                                        >
                                        <Flex align={'center'}>
                                            <Text fontWeight="bold">Multi-advertiser ads &nbsp;</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p="5px">
                                                        <Text>Your ads can appear alongside other ads in the same ad unit to help people discover products and services from businesses that are personalized to them. Your ad creative may be resized or cropped to fit the ad unit.</Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" />
                                            </Tooltip>
                                        </Flex>
                                        </Checkbox>
                                    </Flex>

                                </Flex>
                                <Flex direction="column" bg={'gray.50'} pl={4} pt={4} borderRadius="md" gap={2}>
                                     {/* Ad creative Section */}
                                     <Flex direction="column" >
                                        <Box mb={'10px'} >
                                            <Flex alignItems={'center'}>
                                                <Text fontWeight="bold" >Ad creative &nbsp;</Text>
                                                <Tooltip
                                                    borderRadius={5}
                                                    label={
                                                        <Box fontSize="sm" p="5px">
                                                            <Text>Add a variety of creative elements and group the ones that can be shown together. We'll combine the right elements to show each person the version of your ad that they're most likely to engage with. </Text>
                                                        </Box>
                                                    }
                                                    fontSize="md"
                                                >
                                                    <InfoOutlineIcon pointerEvents="all" />
                                                </Tooltip>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </form>

                            {/* Media selection */}
                            <Flex direction="column">
                                <Flex alignItems="center" justifyContent={'space-between'} bg={'gray.50'}>
                                    <Flex direction='row' pl={4}>
                                        <Text fontWeight="bold">Media</Text>
                                        <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center" width={'100%'}>
                                    {renderCreativeComponent()}
                                </Flex>
                            </Flex>
                        </Flex>

                        { adFormik.values.format === 'SINGLE' ? (
                             <Flex direction={'column'} justifyContent={'space-between'} flex={0.6} px={4} borderRadius={'md'} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)" >
                                <Flex py={2} gap={2} direction={'column'}>
                                    <Flex gap={2}>
                                        <Switch isChecked={isEnabled} onChange={() => setIsEnabled(prevState => !prevState) } />
                                        <Text fontWeight={'bold'} fontSize={'14px'}>Ad preview  </Text>  
                                    </Flex>

                                    <Divider />
                                    {isEnabled && 
                                        <CreateAdPreviews adFormik={adFormik?.values} placements={selectedAdset} file={formValues} adsAccountData={adsAccountData} />
                                    }
                                </Flex>
                             
                                <Flex my={2} alignItems={'center'}>
                                    <Text fontSize={'12px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="12px" p="5px">
                                            <Text>
                                                Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                            </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                        >
                                        <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                                    </Tooltip>
                                </Flex>
                             </Flex>
                        ):(
                            <Flex direction={'column'} justifyContent={'space-between'} flex={0.6} px={4} borderRadius={'md'} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)" >
                                <Flex py={2} gap={2} direction={'column'}>
                                    <Flex gap={2}>
                                        <Switch isChecked={isEnabled} onChange={() => setIsEnabled(prevState => !prevState) } />
                                        <Text fontWeight={'bold'} fontSize={'14px'}>Ad preview  </Text>  
                                    </Flex>

                                    <Divider />
                                    {isEnabled && 
                                        <CreateAdPreviewCaro  adFormik={adFormik?.values} placements={selectedAdset} file={formValues} adsAccountData={adsAccountData} />
                                    }
                                </Flex>
                                
                                <Flex my={2} alignItems={'center'}>
                                    <Text fontSize={'12px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="12px" p="5px">
                                            <Text>
                                                Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                            </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                        >
                                        <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                                    </Tooltip>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default CreateAdForm
