import {
    Badge,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Table, TableContainer,
    Tabs,
    Tbody, Td,
    Text,
    Textarea,
    Tooltip,
    Tr,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import FileUploadBox from 'components/FileUploadBox';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiDelete, FiEdit2, FiChevronDown, FiSearch } from 'react-icons/fi';


import { useEditAccountMutation, useGetAccountsQuery, useGetActiveAccountsQuery, useRemoveAccountMutation, useReplyToAdAccountRequestMutation, useUpdateAdAccountStatusMutation, useUploadAdAccountFileMutation, useGetOwnedAdsAccountsByBmIdQuery, useGetClientAdsAccountsByBmIdQuery, useGetOwnedFacebookPagesByBmIdQuery, useGetClientFacebookPagesByBmIdQuery, useGetOwnedPixelsByBmIdQuery, useGetClientPixelsByBmIdQuery, useGetInstagramAccountsByBmIdQuery, useCheckAdsAccountByIdQuery, useGetBMAccountsQuery } from 'store/adAccounts/adAccountsApi';

import AppTableWithPagination from 'components/AppTableWithPagination';
import CircleIcon from 'components/CircleIcon';
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import LightBox from 'components/LightBox';
import MultiFileUploadButton from 'components/MultiFileUploadButton';
import SelectColumnFilter from 'components/SelectColumnFilter';
import SidebarWithHeader from 'components/SidebarWithHeader';
import moment from 'moment';
import { FiEye } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetCompaniesByUserIDQuery } from 'store/companies/companiesApi';
import timezones from "timezones.json";
import { editAdAccountRequestSchema, replyToRequestSchema } from 'utils/schema';
import ReactSelect from 'react-select';


const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 250, margin: 8 }),
    menu: (provided) => ({
        ...provided,
        marginLeft: 8,
        maxWidth: '607px',
        boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        boxShadow: "0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)",
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: 200,
        overflowY: 'auto',
        fontSize: "small"
    }),
};

function AccountListForAdmin() {
    const btnRef = React.useRef()

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const editFormRef = useRef();
    const dropdownRef = useRef(null);
    const [selectedAccount, setselectedAccount] = useState(null)
    const [activeRequest, setactiveRequest] = useState(null);
    const [replyFiles, setreplyfiles] = useState([]);
    const [requestToDelete, setrequestToDelete] = useState(null);
    const [files, setfiles] = useState([]);
    const [selectedCompanyAccountTypes, setselectedCompanyAccountTypes] = useState([]);
    const userRole = useSelector((state) => state.auth?.userInfo?.role);
    const [BmIdScreenshot, setBmIdScreenshot] = useState(null)
    const [selectedBmId, setSelectedBmId] = useState(null);
    const [isAccountOpen, setIsAccountOpen] = useState(false);
    const [selectedAccountValue, setSelectedAccountValue] = useState(null);

    const userInfo = useSelector((state) => state.auth?.userInfo);
    const { _id: clientId } = userInfo;
    const scrollBoxRef = useRef();
    const scrollToBottom = () => {
        scrollBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    useEffect(() => {
        if (activeRequest) {
            setTimeout(() => {
                scrollToBottom();
            }, 10);
        }
    }, [activeRequest]);

    const {
        isOpen: isDrawerOpen,
        onOpen: onDrawerOpen,
        onClose: onDrawerClose
    } = useDisclosure();

    const {
        isOpen: isDetailsModalOpen,
        onOpen: onDetailsModalOpen,
        onClose: onDetailsModalClose
    } = useDisclosure();

    const {
        isOpen: isDeleteModalOpen,
        onOpen: onDeleteModalOpen,
        onClose: onDeleteModalClose,
    } = useDisclosure();

    let statusBadgeColor = 'gray';

    if (activeRequest?.status === 'pending') {
        statusBadgeColor = 'yellow';
    } else if (activeRequest?.status === 'created' || activeRequest?.status === 'closed') {
        statusBadgeColor = 'gray';
    } else if (activeRequest?.status === 'open') {
        statusBadgeColor = 'green';
    }


    const getFileSource = (name) => {
        return files?.filter((item, index) => item.name == name).length
            ? files?.filter((item, index) => item.name == name)[0].value
            : ''
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setsearchValue] = useState('');
    const [searchAdAccountValue, setSearchAdAccountValue] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchAdAccountValue);

    const [currentPageForActiveAccounts, setCurrentPageForActiveAccounts] = useState(1);
    const [searchValueForActiveAccounts, setsearchValueForActiveAccounts] = useState('');


    /* QUERIES */


    const { data: adAccountsData, error: adAccountsError, isFetching, isLoading: isAccountsDataLoading, refetch } = useGetAccountsQuery({ currentPage, searchValue }, {
        pollingInterval: 500000,
        skipPollingIfUnfocused: true,
    });
    const { data: activeAccountsData, error: activeAccountsError, isFetching: isActiveAccountsFetching, isLoading: isActiveAccountsDataLoading } = useGetActiveAccountsQuery({ currentPage: currentPageForActiveAccounts, searchValue: searchValueForActiveAccounts }, {
        pollingInterval: 700000,
        skipPollingIfUnfocused: true,
    });

    const { data: BMAccounts, isLoading: isBMDataLoading, } = useGetBMAccountsQuery();

    const [removeAccount, removeResponse] = useRemoveAccountMutation()
    const [replyToAdAccountRequest, replyResponse] = useReplyToAdAccountRequestMutation();
    const [uploadAdAccountFile, uploadResponse] = useUploadAdAccountFileMutation();
    const [editAccount, editResponse] = useEditAccountMutation();
    const { data: ownedAdAccountsListData } = useGetOwnedAdsAccountsByBmIdQuery(
        { bmId: selectedBmId, searchAdAccountValue: debouncedSearchTerm },
        { skip: !selectedBmId }
    );
    const { data: clientAdAccountsListData } = useGetClientAdsAccountsByBmIdQuery(
        { bmId: selectedBmId, searchAdAccountValue: debouncedSearchTerm },
        { skip: !selectedBmId }
    );

    const { data: checkAdaccountData, error: checkAdAccountError } = useCheckAdsAccountByIdQuery(
        { adAccountId: selectedAccountValue?.value },
        { skip: !selectedAccountValue?.value }
    );

    const { data: ownedFacebookPagesList } = useGetOwnedFacebookPagesByBmIdQuery(
        { bmId: selectedBmId },
        { skip: !selectedBmId }
    );

    const { data: clientFacebookPagesList } = useGetClientFacebookPagesByBmIdQuery(
        { bmId: selectedBmId },
        { skip: !selectedBmId }
    );

    const { data: instagramAccountsList } = useGetInstagramAccountsByBmIdQuery(
        { bmId: selectedBmId },
        { skip: !selectedBmId }
    );

    const { data: ownedPixelsList } = useGetOwnedPixelsByBmIdQuery(
        { bmId: selectedBmId },
        { skip: !selectedBmId }
    );

    const { data: clientPixelsList } = useGetClientPixelsByBmIdQuery(
        { bmId: selectedBmId },
        { skip: !selectedBmId }
    );

    // console.log("searchadaccountValue", searchAdAccountValue);

    const [updateAdAccountStatus, updateStatusResponse] = useUpdateAdAccountStatusMutation();

    const {
        data: companies,
        error: getCompaniesError,
        isLoading: isCompaniesDataLoading
    } = useGetCompaniesByUserIDQuery({ userId: activeRequest?.clientId?.id }, {
        skip: !activeRequest
    });

    const adAccountsListData = useMemo(() => {
        if (!ownedAdAccountsListData && !clientAdAccountsListData) return [];

        const ownedAccounts = ownedAdAccountsListData ? ownedAdAccountsListData : [];
        const clientAccounts = clientAdAccountsListData ? clientAdAccountsListData : [];

        return [...ownedAccounts, ...clientAccounts];
    }, [ownedAdAccountsListData, clientAdAccountsListData]);

    const facebookPagesList = useMemo(() => {
        if (!ownedFacebookPagesList && !clientFacebookPagesList) return [];

        const ownedFbList = ownedFacebookPagesList ? ownedFacebookPagesList : [];
        const clientFbList = clientFacebookPagesList ? clientFacebookPagesList : [];

        return [...ownedFbList, ...clientFbList];
    }, [ownedFacebookPagesList, clientFacebookPagesList]);

    const pixelsList = useMemo(() => {
        if (!ownedPixelsList && !clientPixelsList) return [];

        const ownedList = ownedPixelsList ? ownedPixelsList : [];
        const clientList = clientPixelsList ? clientPixelsList : [];

        return [...ownedList, ...clientList];
    }, [ownedPixelsList, clientPixelsList]);

    const accountOptions = adAccountsListData?.map(account => ({
        value: account?.id,
        label: account?.name,
    })) || [];

    const facebookOptions = facebookPagesList?.map(page => ({
        value: page?.id,
        label: page?.name,
    })) || [];

    const instagramOptions = instagramAccountsList?.map(account => ({
        value: account?.id,
        label: account?.username,
    })) || [];

    const pixelOptions = pixelsList?.map(pixel => ({
        value: pixel?.id,
        label: pixel?.name,
    })) || [];

    useEffect(() => {
        if (checkAdAccountError) {

            toast({
                position: "top-right",
                title: "Error",
                description: checkAdAccountError?.data?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [checkAdAccountError]);

    useEffect(() => {
        if (searchValue != '') {
            setCurrentPage(1);
        }
    }, [searchValue])


    useEffect(() => {
        if (searchValueForActiveAccounts != '') {
            setCurrentPageForActiveAccounts(1);
        }
    }, [searchValueForActiveAccounts])


    const getCompanyDetails = (name) => {
        const details = companies?.data?.filter(({ companyName }, index) => companyName == name)[0]?.commission
        // console.log(details);
        if (Object.keys(details)?.length > 0) {
            setselectedCompanyAccountTypes(Object.keys(details))
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchAdAccountValue);
        }, 400);

        return () => {
            clearTimeout(handler);
        };
    }, [searchAdAccountValue]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest('.react-select')) {
                setIsAccountOpen(false);
                dropdownRef.current.focus();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleBmIdChange = (event) => {

        const [bmId] = event.target.value.split('|');
        setSelectedBmId(bmId);
        setSelectedAccountValue(null);
        setSearchAdAccountValue('');
        formik.setFieldValue('businessAccount', event.target.value);
        formik.setFieldValue('adsAccountId', '');
        formik.setFieldValue('adsAccountName', '');
        formik.setFieldValue('facebookPages', []);
        formik.setFieldValue('pixels', []);
        formik.setFieldValue('instagramAccounts', []);
    };

    const handleSelectChange = (value) => {
        // console.log(value);

        setSelectedAccountValue(value);
        setIsAccountOpen(false);
        setSearchAdAccountValue('');
        if (value) {
            formik.setFieldValue('adsAccountId', value?.value);
            formik.setFieldValue('adsAccountName', value?.label);
        }
    };

    const toggleDropdown = () => {
        setIsAccountOpen(prev => !prev);
    };

    useEffect(() => {
        const hasSingleType = selectedCompanyAccountTypes?.length == 1

        if (hasSingleType) {
            selectedCompanyAccountTypes.includes('cc')
                ? formik.setFieldValue('accountType', 'Credit Card')
                : formik.setFieldValue('accountType', 'Credit Line')
        }
    }, [selectedCompanyAccountTypes])


    useEffect(() => {
        if (updateStatusResponse?.isSuccess) {
            const { data: { _id } } = updateStatusResponse.data;
            if (activeRequest?._id == _id) {
                setactiveRequest(updateStatusResponse?.data?.data)
            }
            toast({
                position: "top-right",
                title: "Status updated",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (updateStatusResponse.status == "rejected") {

            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [updateStatusResponse]);

    useEffect(() => {
        if (removeResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "Account removed",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (removeResponse.status == "rejected") {
            const {
                data: { message },
            } = removeResponse.error;
            toast({
                position: "top-right",
                title: "Error",
                description: message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [removeResponse]);

    useEffect(() => {
        if (editResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "Account updated",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (editResponse.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [editResponse]);

    useEffect(() => {
        if (uploadResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "File uploaded",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (uploadResponse.status == "rejected") {

            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [uploadResponse]);

    useEffect(() => {
        if (replyResponse.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
        if (replyResponse?.data) {
            const { data: { _id } } = replyResponse.data;
            if (activeRequest?._id == _id) {
                setactiveRequest(replyResponse?.data?.data)
            }
            toast({
                position: "top-right",
                title: "Reply sent",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [replyResponse]);


    const RequestColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },
            {
                Header: 'Ad Account Id',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },
            {
                Header: 'Ad Account Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountName}</Text>)
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'verified BmId',
                accessor: 'verifiedBmId',
                Filter: '',
                filter: ''
            },
            {
                Header: 'Account Type',
                accessor: 'accountType',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    let accountTypeCopy = original?.accountType == 'cc' ? 'Credit Card' : 'Credit Line';
                    return (
                        <Text fontSize={'14px'}>{accountTypeCopy}</Text>
                    )
                }
            },
            {
                Header: 'created',
                accessor: 'createdDate',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (
                    <Text>{moment(original.createDate).format("DD-MM-YYYY hh:mm A")}</Text>
                )
            },
            {
                Header: 'updated',
                accessor: 'updatedDate',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (
                    <Text>{moment(original.updateDate).format("DD-MM-YYYY hh:mm A")}</Text>
                )
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: SelectColumnFilter,
                filter: 'includes',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {


                    let badgeColor = 'gray';
                    if (original.status == 'pending') {
                        badgeColor = 'yellow'
                    } else if (original.status == 'open') {
                        badgeColor = 'green'
                    }
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={1}
                                colorScheme={badgeColor}
                            >
                                {original.status}
                            </Badge>
                            {original.adminRead ? <CircleIcon boxSize={2} color='green.500' /> : ""}
                        </>
                    )
                }

            },
            {
                Header: "",
                accessor: "action",
                Filter: "",
                filter: "",
                disableSortBy: true,
                Cell: ({ row: { original } }) => (
                    <Flex justifyContent={"end"} alignItems={"center"} gap={"20px"}>

                        {/* {(userRole == 'admin' || userRole == 'media') ? (
                            <Tooltip label='Edit' fontSize='xs' >
                                <Box>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setactiveRequest(original);
                                            onOpen();
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        ) : null} */}

                        {userRole == 'admin' ? (
                            <Tooltip label='Edit' fontSize='xs' >
                                <Box>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setactiveRequest(original);
                                            onOpen();
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        ) : (
                            <>
                                {userRole == 'media' && original.status != 'created' ? (
                                    <Tooltip label='Edit' fontSize='xs' >
                                        <Box>
                                            <Icon
                                                _hover={{ color: "gray.500" }}
                                                as={FiEdit2}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                    setactiveRequest(original);
                                                    onOpen();
                                                }}
                                            />
                                        </Box>
                                    </Tooltip>
                                ) : null}
                            </>)}

                        <Tooltip label='View' fontSize='xs' >
                            <Box>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEye}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        setactiveRequest(original);
                                        onDrawerOpen();
                                    }}
                                />
                            </Box>
                        </Tooltip>

                        {userRole == 'admin' ? (
                            <Tooltip label='Delete' fontSize='xs' >
                                <Box>
                                    <Icon
                                        color={'red.500'}
                                        _hover={{ color: "red.300" }}
                                        as={FiDelete}
                                        cursor={"pointer"}
                                        onClick={async () => {
                                            setrequestToDelete(original._id)
                                            onDeleteModalOpen();
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        ) : null}

                    </Flex>
                ),
            },

        ],
        []
    )

    const AccountColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },
            {
                Header: 'Ad Account Id',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },
            {
                Header: 'Ad Account Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountName}</Text>)
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.currency}</Text>)
            },

            // {
            //     Header: 'Top up',
            //     accessor: '',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => {
            //         //console.log(original);
            //         const { _id } = original;
            //         const { data: topupData } = useGetTopupByAdAccountQuery(_id);

            //         // console.log({ topupData });
            //         return (
            //             <>

            //                 {topupData?.data ? (
            //                     <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup) + Number(original.initialBalance))?.toFixed(2)}</Text>
            //                 ) :
            //                     (
            //                         <Text fontSize={'14px'} textAlign={'right'}>{Number(original.initialBalance)?.toFixed(2)}</Text>
            //                     )
            //                 }
            //             </>
            //         )
            //     }
            // },
            // {
            //     Header: 'Commission',
            //     accessor: '',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => {
            //         //console.log(original);
            //         const { _id } = original;
            //         const { data: topupData } = useGetTopupByAdAccountQuery(_id);

            //         //console.log({ topupData });
            //         return (
            //             <>
            //                 {topupData?.data ? (
            //                     <Text fontSize={'14px'} textAlign={'right'}>{Number(topupData?.data?.totalCommission)?.toFixed(2)}</Text>
            //                 ) : null}
            //             </>
            //         )
            //     }
            // },
            // {
            //     Header: 'amount Spent',
            //     accessor: 'amountSpent',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.amountSpent)?.toFixed(2)}</Text>)
            // },
            // {
            //     Header: 'Balance',
            //     accessor: '',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => {
            //         //console.log(original);
            //         const { _id } = original;
            //         const { data: topupData } = useGetTopupByAdAccountQuery(_id);

            //         //console.log({ topupData });
            //         return (
            //             <>
            //                 {topupData?.data ? (
            //                     <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup) + Number(original.initialBalance) - Number(original.amountSpent))?.toFixed(2)}</Text>
            //                 ) : (
            //                     <Text fontSize={'14px'} textAlign={'right'}>{(Number(original.initialBalance) - Number(original.amountSpent))?.toFixed(2)}</Text>
            //                 )}
            //             </>
            //         )
            //     }
            // },

            {
                Header: 'Account status',
                accessor: 'accountStatus',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={2}
                                colorScheme={original.accountStatus == 1 ? 'green' : 'gray'}
                            >
                                {original.accountStatus == 1 ? 'Active' : 'In active'}
                            </Badge>
                        </>
                    )
                }
            },
        ],
        []
    )

    const handleDeleteCancel = () => {
        onDeleteModalClose();
        setrequestToDelete(null);
    };

    const handleDeleteConfirm = async () => {
        onDeleteModalClose();
        await removeAccount(requestToDelete);
    };

    const [initialFields, setinitialFields] = useState({
        companyName: '',
        description: '',
        landingPage: '',
        creativeDriveLinks: '',
        verifiedBmId: '',
        screenshotOfBusinessInfo: '',
        accountTimezone: '',
        accountType: '',
        currency: '',
        initialBalance: 0,
        businessAccount: '',
        adsAccountName: '',
        adsAccountId: '',
    });

    useEffect(() => {
        //  console.log({ activeRequest });
        if (activeRequest) {
            const { company,
                description,
                landingPage,
                creativeDriveLinks,
                verifiedBmId,
                screenshotOfBusinessInfo,
                accountTimezone,
                accountType,
                currency, initialBalance, businessAccount, pixels, pages, instagrams, adsAccountId, adsAccountName } = activeRequest;

            const updatedAccountType = accountType == 'cc' ? 'Credit Card' : 'Credit Line'

            const transformedFacebookPages = pages?.map(option => ({
                value: option.id,
                label: option.name,
            }));

            const transformedInstaAccounts = instagrams?.map(option => ({
                value: option.id,
                label: option.username,
            }));

            const transformedPixels = pixels?.map(option => ({
                value: option.id,
                label: option.name,
            }));

            setinitialFields({
                companyName: company?.name,
                description,
                landingPage,
                creativeDriveLinks,
                verifiedBmId,
                screenshotOfBusinessInfo,
                accountTimezone,
                accountType: updatedAccountType,
                currency,
                adsAccountId: adsAccountId,
                adsAccountName: adsAccountName,
                initialBalance: initialBalance ? initialBalance : 0,
                businessAccount: `${businessAccount?.businessId}|${businessAccount?.businessName}`,
                pixels: transformedPixels,
                facebookPages: transformedFacebookPages,
                instagramAccounts: transformedInstaAccounts,
            })

            if (adsAccountId && adsAccountName) {
                setSelectedAccountValue({ value: adsAccountId, label: adsAccountName })
            }

            setSelectedBmId(businessAccount?.businessId)

            setBmIdScreenshot(screenshotOfBusinessInfo)

            setfiles(
                [
                    {
                        name: "screenshotOfBusinessInfo",
                        value: screenshotOfBusinessInfo
                    },
                    {
                        name: "landingPage",
                        value: landingPage
                    },

                ])
        }

    }, [activeRequest])



    const convertAmountToNumber = (currencyString) => {
        const cleanedString = String(currencyString)?.replace(/[^\d.]/g, '');

        const numberValue = parseFloat(cleanedString);

        return numberValue;
    };

    // console.log(convertAmountToNumber('$1,234.56'));



    const formik = useFormik({
        initialValues: initialFields,
        validationSchema: editAdAccountRequestSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            //console.log(values);
            const landingPageUrl = getFileSource('landingPage')
            const screenshotOfBusinessInfoUrl = getFileSource('screenshotOfBusinessInfo')

            const { companyName: companyTitle,
                creativeDriveLinks,
                verifiedBmId,
                accountTimezone,
                accountType,
                currency,
                description, adsAccountName, adsAccountId, landingPage, initialBalance, businessAccount, facebookPages, pixels, instagramAccounts } = values;

            const [businessId, businessName] = businessAccount.split('|');

            const companyDetails = companies?.data?.filter(({ companyName }, index) => companyName == companyTitle)[0];
            const updatedAccountType = accountType == 'Credit Card' ? 'cc' : 'cl'

            const updatedInitialBalance = convertAmountToNumber(initialBalance)

            const payload = {
                company: {
                    id: companyDetails?._id,
                    name: companyDetails?.companyName,
                },
                creativeDriveLinks,
                landingPage,
                verifiedBmId,
                screenshotOfBusinessInfo: BmIdScreenshot,
                accountTimezone,
                accountType: updatedAccountType,
                currency,
                description,
                adsAccountId,
                adsAccountName,
                initialBalance: updatedInitialBalance,
                pages: facebookPages.map(page => ({
                    id: page?.value,
                    name: page?.label,
                })),
                instagrams: instagramAccounts.map(account => ({
                    id: account?.value,
                    username: account?.label,
                })),
                pixels: pixels.map(pixel => ({
                    id: pixel?.value,
                    name: pixel?.label,
                })),
                businessAccount: {
                    businessName,
                    businessId,
                },
            }


            // console.log({ payload });

            if (values) {
                await editAccount({ accountId: activeRequest?._id, payload });
                setfiles([])
            }
            formik.resetForm();
            onClose();
        },
    });

    // console.log(formik.values);

    const replyForm = useFormik({
        initialValues: {
            content: '',
            attachments: '',
        },
        validationSchema: replyToRequestSchema,
        onSubmit: async (values) => {
            const { content } = values
            const attachments_ = replyFiles?.map((file, index) => file.value)

            const payload = {
                content,
                attachments: attachments_,
                checked: true
            }

            // //console.log({ payload });

            if (values) {
                await replyToAdAccountRequest({ requestId: activeRequest?._id, payload });

            }
            replyForm.resetForm();
            setreplyfiles([])
        },
    });

    const handleFileUpload = (filename, acceptedFiles) => {

        // console.log({ filename, acceptedFiles });
        acceptedFiles.map((file, index) => {

            if (filename == 'replyAttachments') {
                replyForm.setFieldValue('attachments', file)
            }
            const reader = new FileReader();
            reader.onload = async function (e) {
                //console.log({ filename, file });
                const formData = new FormData();
                formData.append('attachments', file)
                await uploadAdAccountFile(formData)
                    .unwrap()
                    .then((payload) => {
                        const { data: { name, value } } = payload;

                        if (filename == 'replyAttachments') {
                            setreplyfiles(files => [...files, { name: 'replyAttachments', value }]);
                        }

                        if (filename == 'screenshotOfBusinessInfo') {
                            setBmIdScreenshot(value);
                            formik.setFieldValue('screenshotOfBusinessInfo', value)
                        }


                    })
                    .catch((error) => {
                        toast({
                            position: "top-right",
                            title: "Upload Error",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                    })
            };
            reader.readAsDataURL(file);
            return file;
        });
    }

    const removeFileSource = (filekey, index) => {

        if (filekey == 'replyAttachments') {
            if (index < 0 || index >= replyFiles.length) {
                return;
            }
            const newArray = [...replyFiles];
            newArray.splice(index, 1);
            setreplyfiles(newArray)
        } else {
            const newFiles = files?.filter(obj => obj.name !== filekey);
            setfiles(newFiles);
        }

        if (filekey = 'screenshotOfBusinessInfo') {
            setBmIdScreenshot(null)
            formik.setFieldValue('screenshotOfBusinessInfo', '')
        }
    }


    const companyOptions = companies?.data?.filter((item, index) => item.clientId?.id == activeRequest?.clientId?.id && item.status == true).map(({ _id, companyName }, index) => (
        <option value={companyName} key={_id}>{companyName}</option>
    ))


    const messageHistory = activeRequest && activeRequest.messages?.map(({ content, author: { name, id }, createdAt, attachments }, index) => {

        return (
            <Box mb={'10px'} key={'request' + index}>
                <Box
                    ml={id == clientId ? '20%' : '0'}
                    mr={id != clientId ? '20%' : '0'}
                    boxShadow='sm'
                >
                    <Box
                        background={id == clientId ? 'blue.50' : 'white'}
                        p={'5px 10px'} borderRadius={5} borderColor={'gray.200'} borderWidth={1}>
                        <Text fontSize={'sm'} color={"gray.600"}>{content}</Text>
                        <Flex gap={'5px'}>
                            {attachments?.length > 0 && attachments?.map((file, i) => (
                                <LightBox fileSource={file} key={'attach_' + i} />
                            ))}
                        </Flex>
                        <Text textAlign={'right'} mt={'5px'} fontSize={'9px'} color={'gray.600'}>{moment(createdAt).format("DD-MM-YYYY hh:mm A") + " • " + name}</Text>
                    </Box>
                </Box>
            </Box>
        )
    })

    //console.log({ activeRequest });

    return (
        <>
            {/* EDIT FORM */}

            <Modal scrollBehavior={'inside'} size={'2xl'} isCentered={true} isOpen={isOpen} onClose={() => { onClose(); formik.resetForm(); setSelectedAccountValue(null); }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={'center'}>Update Account Request</ModalHeader>
                    <ModalCloseButton />
                    {isCompaniesDataLoading ? (
                        <Flex minH={'300px'} alignItems={'center'} justify={'center'}>
                            <Spinner />
                        </Flex>
                    ) : (
                        <ModalBody>
                            <FormControl display={'flex'} flexDirection={'column'} gap={'20px'}>

                                <Flex gap={'20px'}>
                                    <Box flex={1}>
                                        <FormLabel fontSize='sm' mb={'10px'}>
                                            Company Name
                                        </FormLabel>

                                        <Select
                                            flex={1}
                                            name="companyName"
                                            placeholder='Company Name*'
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if (!!e.target.value) {
                                                    //console.log(e.target.value);
                                                    getCompanyDetails(e.target.value);
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.companyName}
                                            isInvalid={formik.touched.companyName && formik.errors.companyName ? true : false}
                                        >
                                            {companies && companyOptions}
                                        </Select>


                                        {formik.touched.companyName && formik.errors.companyName && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.companyName}</Text>
                                        )}
                                    </Box>

                                    <Box flex={1}>
                                        <FormLabel fontSize='sm' mb={'10px'}>
                                            Account Type
                                        </FormLabel>
                                        <Select
                                            flex={1}
                                            name="accountType"
                                            placeholder='Account Type*'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.accountType}
                                            isInvalid={formik.touched.accountType && formik.errors.accountType ? true : false}
                                            isDisabled={!formik.values.companyName}
                                        >
                                            <option value="Credit Card" >Credit Card</option>

                                            <option value="Credit Line">Credit Line</option>

                                        </Select>
                                        {formik.touched.accountType && formik.errors.accountType && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.accountType}</Text>
                                        )}
                                    </Box>

                                </Flex>

                                {formik.touched.companyName && selectedCompanyAccountTypes?.length == 1 && (
                                    <Text fontSize={'xs'} color={'orange.300'}>Please raise a ticket if you wish to change account type</Text>
                                )}

                                <Flex gap={'20px'}>

                                    <Box flex={1}>
                                        <FormLabel fontSize='sm' mb={'10px'}>
                                            Currency
                                        </FormLabel>
                                        <Select
                                            flex={1}
                                            name="currency"
                                            placeholder='Select Currency*'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.currency}
                                            isInvalid={formik.touched.currency && formik.errors.currency ? true : false}
                                        >
                                            {
                                                formik.values.accountType == 'Credit Card' ?
                                                    <>
                                                        <option value="USD">USD</option>
                                                        <option value="EUR">EUR</option>
                                                        <option value="CAD">CAD</option>
                                                        <option value="AUD">AUD</option>
                                                        <option value="GBP">GBP</option>
                                                        <option value="SGD">SGD</option>

                                                    </>
                                                    : formik.values.accountType == 'Credit Line' ?
                                                        <>
                                                            <option value="INR">INR</option>
                                                        </>
                                                        : null
                                            }

                                        </Select>
                                        {formik.touched.currency && formik.errors.currency && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.currency}</Text>
                                        )}
                                    </Box>

                                    <Box flex={1}>
                                        <FormLabel fontSize='sm' mb={'10px'}>
                                            Verified BM ID
                                        </FormLabel>
                                        <Input
                                            name='verifiedBmId'
                                            placeholder='Verified BM ID'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.verifiedBmId}
                                            isInvalid={formik.touched.verifiedBmId && formik.errors.verifiedBmId}
                                        />
                                        {formik.touched.verifiedBmId && formik.errors.verifiedBmId && (
                                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                {formik.errors.verifiedBmId}
                                            </Text>
                                        )}
                                    </Box>

                                </Flex>


                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Creative Drive Links
                                    </FormLabel>
                                    <Input
                                        name='creativeDriveLinks'
                                        placeholder='Creative Drive Links*'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.creativeDriveLinks}
                                        isInvalid={formik.touched.creativeDriveLinks && formik.errors.creativeDriveLinks}
                                    />
                                    {formik.touched.creativeDriveLinks && formik.errors.creativeDriveLinks && (
                                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                            {formik.errors.creativeDriveLinks}
                                        </Text>
                                    )}
                                </Box>


                                <Box>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Landing page
                                    </FormLabel>
                                    <Input
                                        name='landingPage'
                                        placeholder="Input your company's website URL"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.landingPage}
                                        isInvalid={formik.touched.landingPage && formik.errors.landingPage}
                                    />
                                    {formik.touched.landingPage && formik.errors.landingPage && (
                                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                            {formik.errors.landingPage}
                                        </Text>
                                    )}
                                </Box>

                                <Box>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Screenshot of business info of BM ID
                                    </FormLabel>
                                    <FileUploadBox
                                        fileSource={BmIdScreenshot}
                                        multiUpload={false}
                                        fileKey='screenshotOfBusinessInfo'
                                        onFileUpload={handleFileUpload}
                                        onRemoveClick={() => removeFileSource('screenshotOfBusinessInfo')}
                                        isInvalid={formik.touched.screenshotOfBusinessInfo && formik.errors.screenshotOfBusinessInfo ? true : false}
                                    //isUploading={newTopUpResponse.isLoading}
                                    />
                                    {formik.touched.screenshotOfBusinessInfo && formik.errors.screenshotOfBusinessInfo && (
                                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                            {formik.errors.screenshotOfBusinessInfo}
                                        </Text>
                                    )}
                                </Box>


                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Account timezone
                                    </FormLabel>
                                    <Select
                                        flex={1}
                                        name="accountTimezone"
                                        placeholder='Account timezone'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.accountTimezone}
                                        isInvalid={formik.touched.accountTimezone && formik.errors.accountTimezone}
                                    >
                                        {timezones.map(({ text }, index) => (
                                            <option key={`select-option-${index}`} value={text}>
                                                {text}
                                            </option>
                                        ))}

                                    </Select>
                                    {formik.touched.accountTimezone && formik.errors.accountTimezone && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.accountTimezone}</Text>
                                    )}
                                </Box>



                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Request text
                                    </FormLabel>
                                    <Textarea
                                        size='md'
                                        name='description'
                                        placeholder='Enter the request text'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                        isInvalid={formik.touched.description && formik.errors.description ? true : false}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.description}</Text>
                                    )}
                                </Box>

                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        BM ID
                                    </FormLabel>
                                    <Select
                                        flex={1}
                                        name="bmId"
                                        placeholder='Select BM ID'
                                        onChange={handleBmIdChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.businessAccount}
                                        isInvalid={formik.touched.businessAccount && formik.errors.businessAccount}
                                    >
                                        {BMAccounts?.map((account, index) => (
                                            <option value={`${account.businessId}|${account.businessName}`}> {account.businessName} ({account.businessId})</option>
                                        ))}
                                    </Select>
                                    {formik.touched.businessAccount && formik.errors.businessAccount && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.businessAccount}</Text>
                                    )}
                                </Box>

                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Ads Accounts
                                    </FormLabel>
                                    <Flex direction={'column'} opacity={!formik.values.businessAccount ? 0.4 : 1} pointerEvents={!formik.values.businessAccount ? 'none' : 'auto'} >
                                        <Box
                                            ref={dropdownRef}
                                            onClick={toggleDropdown}
                                            width={'100%'}
                                            borderWidth='1px'
                                            bg={'none'}
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            fontSize={'md'}
                                            p={2}
                                            pl={4}
                                            cursor="pointer"
                                            borderRadius={'md'}
                                        >
                                            <span>
                                                {selectedAccountValue?.label || initialFields?.adsAccountName || 'Select an Ad Account'}
                                            </span>

                                            <FiChevronDown size={'16px'} />
                                        </Box>
                                        {isAccountOpen && (
                                            <ReactSelect
                                                className="react-select"
                                                options={accountOptions}
                                                styles={selectStyles}
                                                inputValue={searchAdAccountValue}
                                                onInputChange={(inputValue) => setSearchAdAccountValue(inputValue)}
                                                onChange={handleSelectChange}
                                                autoFocus
                                                tabSelectsValue={false}
                                                backspaceRemovesValue={false}
                                                controlShouldRenderValue={false}
                                                isClearable={false}
                                                menuIsOpen
                                                closeMenuOnSelect={true}
                                                components={{
                                                    DropdownIndicator: () => (
                                                        <Box mr={2}>
                                                            <FiSearch />
                                                        </Box>
                                                    ),
                                                    IndicatorSeparator: () => null,
                                                }}
                                                placeholder="Search..."
                                                value={selectedAccountValue}
                                            />
                                        )}
                                    </Flex>
                                    {formik.touched.adsAccountId && formik.errors.adsAccountId && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.adsAccountId}</Text>
                                    )}
                                </Box>

                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Facebook Pages
                                    </FormLabel>
                                    <ReactSelect
                                        isMulti
                                        styles={{
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none',
                                            }),
                                        }}
                                        name="facebookPages"
                                        placeholder="Select Facebook Pages"
                                        value={formik.values.facebookPages}
                                        options={facebookOptions}
                                        onChange={(selected) => {
                                            // console.log(selected);
                                            // setSelectedFacebookPages(selected);
                                            formik.setFieldValue('facebookPages', selected)
                                        }}
                                        onBlur={formik.handleBlur}
                                        isDisabled={!formik.values.businessAccount}
                                    />
                                    {formik.touched.facebookPages && formik.errors.facebookPages && (
                                        <Text fontSize={'sm'} color="red.500">{formik.errors.facebookPages}</Text>
                                    )}
                                </Box>

                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Instagram Accounts
                                    </FormLabel>
                                    <ReactSelect
                                        isMulti
                                        styles={{
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none',
                                            }),
                                        }}
                                        placeholder="Select Instagram Accounts"
                                        name="instagramAccounts"
                                        options={instagramOptions}
                                        value={formik.values.instagramAccounts}
                                        onChange={(selected) => {
                                            // setSelectedInstagramAccounts(selected);
                                            formik.setFieldValue('instagramAccounts', selected)
                                        }}
                                        onBlur={formik.handleBlur}
                                        isDisabled={!formik.values.businessAccount}
                                    />
                                </Box>

                                <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Pixels
                                    </FormLabel>
                                    <ReactSelect
                                        isMulti
                                        styles={{
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none',
                                            }),
                                        }}
                                        placeholder="Select Pixels"
                                        name="pixels"
                                        options={pixelOptions}
                                        value={formik.values.pixels}
                                        onChange={(selected) => {
                                            // setSelectedPixels(selected);
                                            formik.setFieldValue('pixels', selected)
                                        }}
                                        onBlur={formik.handleBlur}
                                        isDisabled={!formik.values.businessAccount}
                                    />
                                    {formik.touched.pixels && formik.errors.pixels && (
                                        <Text fontSize={'sm'} color="red.500">{formik.errors.pixels}</Text>
                                    )}
                                </Box>


                                <Box flex={1}>
                                    <Text fontSize={'14px'}>
                                        {activeAccountsData?.data[0]?.clientId?.name} ({activeAccountsData?.data[0]?.clientId?.email})
                                    </Text>
                                </Box>

                                {/* <Flex gap={'20px'} >
                                    <Box flex={1}>
                                        <FormLabel fontSize='sm' mb={'10px'}>
                                            Ads Account ID
                                        </FormLabel>
                                        <Input
                                            size='md'
                                            name='adsAccountId'
                                            placeholder='Ads Account ID'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.adsAccountId}
                                            isInvalid={formik.touched.adsAccountId && formik.errors.adsAccountId ? true : false}
                                        />
                                        {formik.touched.adsAccountId && formik.errors.adsAccountId && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.adsAccountId}</Text>
                                        )}
                                    </Box>
                                    <Box flex={1}>
                                        <FormLabel fontSize='sm' mb={'10px'}>
                                            Ads Account Name
                                        </FormLabel>
                                        <Input
                                            size='md'
                                            name='adsAccountName'
                                            placeholder='Ads Account Name'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.adsAccountName}
                                            isInvalid={formik.touched.adsAccountName && formik.errors.adsAccountName ? true : false}
                                        />
                                        {formik.touched.adsAccountName && formik.errors.adsAccountName && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.adsAccountName}</Text>
                                        )}
                                    </Box>
                                </Flex> */}

                                {/* <Box flex={1}>
                                    <FormLabel fontSize='sm' mb={'10px'}>
                                        Initial Balance
                                    </FormLabel>
                                    <Input
                                        name='initialBalance'
                                        placeholder='Initial Balance'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.initialBalance}
                                        isInvalid={formik.touched.initialBalance && formik.errors.initialBalance}
                                    />
                                    {formik.touched.initialBalance && formik.errors.initialBalance && (
                                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                            {formik.errors.initialBalance}
                                        </Text>
                                    )}
                                </Box> */}
                                <Flex gap={'20px'} mb={'10px'} mt={'20px'} justify={'center'}>
                                    <Button colorScheme='orange' onClick={formik.handleSubmit}>
                                        Submit
                                    </Button>
                                    <Button onClick={() => { formik.resetForm(); onClose(); setSelectedAccountValue(null); }}>
                                        Cancel
                                    </Button>
                                </Flex>
                            </FormControl>

                        </ModalBody>
                    )}


                </ModalContent>
            </Modal>

            {/* DELETE CONFIRMATION */}

            <ConfirmDeleteModal
                copy='Are you sure you want to delete the request'
                isModalOpen={isDeleteModalOpen}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
            />

            {/* REQUEST DETAILS MODAL */}

            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isDetailsModalOpen}
                onClose={() => {
                    onDetailsModalClose();
                }}
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={"center"}>Request details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer>
                            <Table variant='simple' size={"sm"}>
                                <Tbody whiteSpace={'break-spaces'}>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Ads Account Name
                                        </Td>
                                        <Td>{activeRequest?.adsAccountName}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Ads Account ID
                                        </Td>
                                        <Td>{activeRequest?.adsAccountId}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Ads Account Type
                                        </Td>
                                        <Td>{activeRequest?.accountType == 'cc' ? 'Credit Card' : 'Credit Line'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Client
                                        </Td>
                                        <Td>{activeRequest?.clientId?.name}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Creative Drive Links
                                        </Td>
                                        <Td>{activeRequest?.creativeDriveLinks}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Currency
                                        </Td>
                                        <Td>{activeRequest?.currency}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Account Timezone
                                        </Td>
                                        <Td>{activeRequest?.accountTimezone}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Company Name
                                        </Td>
                                        <Td>{activeRequest?.company.name}</Td>
                                    </Tr>

                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Description
                                        </Td>
                                        <Td whiteSpace={'break-spaces'}>{activeRequest?.description}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Status
                                        </Td>
                                        <Td>{activeRequest?.status}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Landing Page
                                        </Td>
                                        <Td>{activeRequest?.landingPage}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Screenshot of Business Info
                                        </Td>
                                        <Td><LightBox fileSource={activeRequest?.screenshotOfBusinessInfo} /></Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Created Date
                                        </Td>
                                        <Td>{moment(activeRequest?.createDate).format("DD-MM-YYYY hh:mm A")}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Updated Date
                                        </Td>
                                        <Td>{moment(activeRequest?.updatedDate).format("DD-MM-YYYY hh:mm A")}</Td>
                                    </Tr>


                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal >

            {/* CONVERSATION HISTORY */}
            <Drawer
                isOpen={isDrawerOpen}
                placement='right'
                onClose={() => {
                    onDrawerClose();
                    setactiveRequest(null)
                }}
                finalFocusRef={btnRef}
                size={'md'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Flex align={'center'}>
                            <Text>Request History</Text>
                            <Badge ml={'10px'} fontSize={'10px'} colorScheme={statusBadgeColor}>{activeRequest?.status}</Badge>
                        </Flex>
                        <Button colorScheme='gray' variant={'outline'} size={'xs'} onClick={() => onDetailsModalOpen()}>View details</Button>
                    </DrawerHeader>


                    <DrawerBody background={'gray.50'} borderWidth={1} borderColor={'gray.300'} borderLeftWidth={0} borderRightWidth={0}>
                        <Box p={'10px'} ref={scrollBoxRef}>
                            {messageHistory}
                        </Box>
                    </DrawerBody>

                    {(userRole == 'admin' || userRole == 'media') ? (
                        <DrawerFooter>
                            <Box flex={1}>
                                <>
                                    <Flex mb={"20px"} gap={"10px"}>
                                        {activeRequest?.status != 'pending' ? (
                                            <Button
                                                size={"xs"}
                                                variant="outline"
                                                colorScheme="yellow"
                                                onClick={async () => {
                                                    await updateAdAccountStatus({
                                                        requestId: activeRequest._id,
                                                        payload: { status: "pending" },
                                                    });
                                                }}
                                            >
                                                Raise issue
                                            </Button>
                                        ) : null}

                                        {activeRequest?.status != 'created' ? (
                                            <Button
                                                size={"xs"}
                                                variant="outline"
                                                colorScheme="gray"
                                                onClick={async () => {
                                                    await updateAdAccountStatus({
                                                        requestId: activeRequest._id,
                                                        payload: { status: "created" },
                                                    });
                                                    onDrawerClose();
                                                }}
                                            >
                                                Close request
                                            </Button>
                                        ) : null}
                                    </Flex>

                                    <Box gap={'10px'}>
                                        <Box flex={1} mb={'10px'}>
                                            <Textarea
                                                size='md'
                                                name='content'
                                                placeholder='Message'
                                                onChange={replyForm.handleChange}
                                                onBlur={replyForm.handleBlur}
                                                value={replyForm.values.content}
                                                isInvalid={replyForm.touched.content && replyForm.errors.content ? true : false}
                                            />
                                            {replyForm.touched.content && replyForm.errors.content && (
                                                <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{replyForm.errors.content}</Text>
                                            )}
                                        </Box>

                                        <Flex flex={1} justify={'space-between'}>
                                            <MultiFileUploadButton
                                                fileSource={replyFiles}
                                                fileKey='replyAttachments'
                                                onFilesSelected={handleFileUpload}
                                                onRemoveClick={(index) => removeFileSource('replyAttachments', index)}
                                                isDisabled={!replyForm.values.content}
                                            />

                                            {replyForm.touched.attachments && replyForm.errors.attachments && (
                                                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                    {replyForm.errors.attachments}
                                                </Text>
                                            )}
                                            <Button
                                                size={'sm'}
                                                onClick={replyForm.handleSubmit}
                                                isDisabled={!replyForm.values.content || uploadResponse.isLoading}
                                            >Reply</Button>
                                        </Flex>
                                    </Box>
                                </>
                            </Box>
                        </DrawerFooter>
                    ) : null}

                </DrawerContent>
            </Drawer>


            <SidebarWithHeader>
                <Flex mb={'20px'} >
                    <Heading as='h1' size='xl'>
                        Ad Accounts
                    </Heading>
                </Flex>

                <Flex flexDirection={'column'} flex={'1'} >
                    <Tabs p={0} colorScheme='orange'>
                        <TabList>
                            <Tab fontWeight={'bold'}>REQUESTS</Tab>
                            <Tab fontWeight={'bold'}>ACTIVE ACCOUNTS</Tab>
                        </TabList>
                        {(isAccountsDataLoading || isActiveAccountsDataLoading) ? (
                            <Spinner mt={'20px'} />
                        ) : adAccountsError || activeAccountsError ? (
                            <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                                <Text color="red.500">
                                    {adAccountsError?.data?.message || activeAccountsError?.data?.message }
                                </Text>
                            </Flex>
                        ) : (
                            <TabPanels>
                                <TabPanel px={0}>
                                    <AppTableWithPagination
                                        columns={RequestColumns}
                                        data={adAccountsData?.data}
                                        searchTerm={searchValue}
                                        paginationMeta={adAccountsData?.meta}
                                        onPageChange={(index) => setCurrentPage(index)}
                                        onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                                        searchEnabled={true}
                                        isFetching={isFetching}
                                    />
                                </TabPanel>
                                <TabPanel px={0}>
                                    <AppTableWithPagination
                                        columns={AccountColumns}
                                        data={activeAccountsData?.data}
                                        searchTerm={searchValueForActiveAccounts}
                                        paginationMeta={activeAccountsData?.meta}
                                        onPageChange={(index) => setCurrentPageForActiveAccounts(index)}
                                        onSearchInputChange={(searchInput) => setsearchValueForActiveAccounts(searchInput)}
                                        searchEnabled={true}
                                        isFetching={isActiveAccountsFetching}
                                    />
                                </TabPanel>
                            </TabPanels>
                        )}
                    </Tabs>
                </Flex>
            </SidebarWithHeader>
        </>
    )
}

export default AccountListForAdmin