import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box, Button, Flex, Input, Select, Text, Textarea, Tooltip, useToast,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    Checkbox,
 } from '@chakra-ui/react';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useFormik } from 'formik';
import { object, string } from "yup";
import { useUpdateAdspressoAdCaroByAdIdMutation } from 'store/adspresso/adspressoAdApi';
import UrlParametersUpdate from '../UrlParametersUpdate';

let schema = object().shape({
    name: string().
        max(300, 'select atleast one type'),
    description: string().
        max(300, 'select atleast one type'),
    type: string()
        .required('Type is required'),
    message: string(),
    link: string()
        .url('Please add a valid URL')
        .when('type', {
            is: (type) => type !== 'CALL_NOW',
            then: () => string().required('Link is required'),
            otherwise: () => string().notRequired(),
        }),
    // phoneNumber: string().
    //     when('type', {
    //         is: 'CALL_NOW',
    //         then: () => string().required('Phone number is required'),
    //         otherwise: () => string().notRequired(),
    //     })
    //     .min(10, 'Phone number is too short')
    //     .max(15, 'Phone number is too long'),
})


const CaroAdUpdateForm = ({ adData, creative, call_to_action_options, call_to_action_options_instantForms }) => {

    const toast = useToast();
    const { link, message, name, type, url_tags, _id, contextual_multi_ads, fileName } = creative || {}

    const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);
    const [updateAdspressoCarocreativeAds, { isLoading: isAdspressoCaroAdUpdating }] = useUpdateAdspressoAdCaroByAdIdMutation({});


    const caroMediaFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name || '',
            message: message || '',
            type: type || '',
            link: link || '',
            url_tags: url_tags || '',
            // phoneNumber: link?.includes('tel:+') ? link.replace('tel:+', '') : '',
            fileName: fileName || '',
            enroll_status: contextual_multi_ads?.enroll_status ? contextual_multi_ads?.enroll_status :  'OPT_OUT',
        },
        validationSchema: schema,
        onSubmit: (values, { resetForm }) => {
            const { link, message, name, type, phoneNumber, url_tags, fileName, enroll_status} = values ;

            const payload = {
                name,
                fileName,
                contextual_multi_ads:{
                    enroll_status,
                },
                message,
                type,
                ...(url_tags?.trim() && { url_tags }),
                ...(link && { link: link }),
            };

            try {
                updateAdspressoCarocreativeAds({ _id, payload }).unwrap()
                    .then(response => {
                        toast({
                            position: "top-right",
                            title: "Ad updated",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                    })
                    .catch(error => {
                        const errorMessage = error?.data?.message || "An unexpected error occurred";
                        const formattedMessage = errorMessage.replace(/['"]/g, '');

                        toast({
                            position: "top-right",
                            title: "Error updating Ad",
                            description: formattedMessage,
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                    });
            } catch (error) {
                // console.log(error);

            } finally {
                resetForm();
            }
        }
    });

    const handleUrlParamChange = (constructedUrl) => {
        caroMediaFormik.setFieldValue('url_tags', constructedUrl);
    };


    return (
        <>
            <Flex py={2} direction={'column'} gap={2}>
                <Flex direction="column">
                    {Boolean(adData?.useFileName) ? (
                        <Flex mb={4} direction="column">
                            <Flex alignItems="center" mb={2} gap={2}>
                                <Text fontSize={'14px'} fontWeight="bold">Ad name</Text>
                            </Flex>
                            <Input
                                placeholder="Ad name"
                                name="fileName"
                                size={'sm'}
                                borderRadius="md"
                                value={caroMediaFormik.values.fileName}
                                onChange={caroMediaFormik.handleChange}
                            />
                        </Flex>
                    ) : null}
                {/* Multi-advertiser ads Section */}
                <Flex direction="column" mb={4} borderRadius="md" gap={2}>
                    <Checkbox
                        onChange={(e) => {
                            caroMediaFormik.setFieldValue("enroll_status", e.target.checked ? 'OPT_IN' : 'OPT_OUT');
                        }}
                        colorScheme="orange"
                        isChecked={caroMediaFormik.values.enroll_status === 'OPT_IN'}
                    >
                    <Flex align={'center'}>
                        <Text fontWeight="bold" fontSize={'14px'}>Multi-advertiser ads &nbsp;</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p="5px">
                                    <Text>Your ads can appear alongside other ads in the same ad unit to help people discover products and services from businesses that are personalized to them. Your ad creative may be resized or cropped to fit the ad unit.</Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                    </Flex>
                    </Checkbox>
                </Flex>

                <Flex alignItems="center" mb={2} gap={2}>
                    <Text fontSize="14px" fontWeight="bold">Primary text</Text>
                    <Tooltip
                        borderRadius={5}
                        label={
                        <Box fontSize="14px" p="5px">
                            <Text>
                                The primary text for your ad appears in most placements, though the position
                                varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                                characters or less.
                            </Text>
                        </Box>
                        }
                        fontSize="md"
                    >
                        <InfoOutlineIcon pointerEvents="all" />
                    </Tooltip>
                    </Flex>
                    <Textarea
                        placeholder="Tell people what your ad is about..."
                        name="message"
                        size={'sm'}
                        borderRadius="md"
                        value={caroMediaFormik.values.message}
                        onChange={caroMediaFormik.handleChange}
                    />
            </Flex>
            {caroMediaFormik.values.type !== 'CALL_NOW' ? (
                <Box direction="column">
                    <Flex alignItems="center" mb={2} gap={2}>
                        <Text fontWeight="bold" fontSize={'14px'}>Call to action</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p="5px">
                                    <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                    </Flex>
                    <Select
                        name="type"
                        borderRadius={'md'}
                        value={caroMediaFormik.values.type}
                        onChange={caroMediaFormik.handleChange}
                        size={'sm'}
                    >
                        {['SIGN_UP', 'APPLY_NOW', 'SUBSCRIBE', 'GET_OFFER', 'GET_QUOTE', 'DOWNLOAD', 'BOOK_TRAVEL'].includes(caroMediaFormik?.values?.type) ? (
                            <>
                                {call_to_action_options_instantForms?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </>
                        ) : (
                            <>
                                {call_to_action_options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </>
                        )}
                    </Select>
                </Box>
            ):null}


            {/* {creative?.type === 'CALL_NOW' ? (
                <>
                    <Box>
                        <Flex alignItems="center" mt={'10px'} mb={2}>
                            <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                            <Tooltip
                                borderRadius={5}
                                label={
                                    <Box fontSize="sm" p="5px">
                                    <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                    </Box>
                                }
                                fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>
                            <PhoneInput
                                country={'in'}
                                value={caroMediaFormik.values.phoneNumber}
                                onChange={(value) => {
                                    caroMediaFormik.setFieldValue('phoneNumber', value)
                                }}
                                inputProps={{
                                    name: 'phoneNumber',
                                    required: true,
                                    autoFocus: true,
                                }}
                                enableSearch={true}
                                searchPlaceholder="Search"
                            />

                        {caroMediaFormik.touched.phoneNumber && caroMediaFormik.errors.phoneNumber ? (
                            <Text color="red.500" fontSize={'12px'}>{caroMediaFormik.errors.phoneNumber}</Text>
                        ) : null}
                    </Box>
                </>
            ) : null} */}


                {!['CALL_NOW', 'SIGN_UP', 'APPLY_NOW', 'SUBSCRIBE', 'GET_OFFER', 'GET_QUOTE', 'DOWNLOAD', 'BOOK_TRAVEL'].includes(caroMediaFormik?.values?.type) ? (
                    <>
                        <Flex direction="column">
                            <Flex alignItems="center" gap={2} mb={'10px'}>
                                <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p="5px">
                                            <Text>Enter the URL for the webpage you want people to visit</Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>
                            <Flex direction={'column'} gap={1}>
                                <Input
                                    type="url"
                                    name="link"
                                    borderRadius={'md'}
                                    placeholder="Enter URL"
                                    value={caroMediaFormik.values.link}
                                    onChange={caroMediaFormik.handleChange}
                                    size={'sm'}
                                />
                                {caroMediaFormik.touched.link && caroMediaFormik.errors.link ? (
                                    <Text color="red.500" fontSize={'12px'}>{caroMediaFormik.errors.link}</Text>
                                ) : null}
                            </Flex>
                        </Flex>
                    </>
                ) : null}

                <Flex direction="column" gap={1}>
                    <Flex alignItems={'center'}>
                        <Text mb={2} fontWeight="bold" fontSize={'14px'}>
                            URL parameters{" "}
                            {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                            <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                        </Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p={'5px'}>
                                    <Text >
                                        Add parameters to the end of your website URL to track where your visitors are coming from.
                                    </Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                        </Tooltip>
                    </Flex>
                    <Input
                        name="url_tags"
                        placeholder="key1=value1&key2=value2"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={caroMediaFormik.values.url_tags}
                        onChange={caroMediaFormik.handleChange}
                    />
                    <Flex mt={2}>
                        <Button onClick={() => {
                            setUrlParamModalOpen(true)
                        }}
                            variant="link">
                            <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                        </Button>
                    </Flex>
                    {caroMediaFormik.touched?.url_tags && caroMediaFormik.errors?.url_tags && (
                        <Text fontSize={"12px"} color="red.500">{caroMediaFormik.errors?.url_tags}</Text>
                    )}
                </Flex>

                <Flex justifyContent="flex-start" mt={'5px'}>
                    <Button
                        colorScheme="orange"
                        onClick={() => caroMediaFormik.handleSubmit()}
                        size={'sm'}
                        isLoading={isAdspressoCaroAdUpdating}
                        isDisabled={isAdspressoCaroAdUpdating}
                    >
                        Save
                    </Button>
                </Flex>
            </Flex>

            <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <UrlParametersUpdate link={caroMediaFormik?.values} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CaroAdUpdateForm;