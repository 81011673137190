import { api } from "../api";

export const adspressoAdApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createAdspressoAdHeader: builder.mutation({
            query: (payload) => ({
                url: '/adspresso/addata/',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['AdspressoAd'],
        }),
        createAdspressoCreative: builder.mutation({
            query: (payload) => ({
                url: '/adspresso/ads/',
                method: 'POST',
                body: payload,
            }),
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                return error;
            },
            invalidatesTags: ['AdspressoAd'],
        }),
        getAdspressoAd: builder.query({
            query: (AdId) => ({
                url: `/adspresso/ads/get/${AdId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getAdspressoAdDataByCampaignId: builder.query({
            query: (campaignId) => ({
                url: `/adspresso/addata/${campaignId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        updateAdspressoAdDataByAdId: builder.mutation({
            query: ({ adId, payload }) => ({
                url: `/adspresso/addata/${adId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AdspressoAd'],
        }),
        getAdspressoCreativeByCampaignId: builder.query({
            query: (campaignId) => ({
                url: `/adspresso/ads/campaign/${campaignId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdspressoAd']
        }),
        updateAdspressoAdByAdId: builder.mutation({
            query: ({ AdId, payload }) => ({
                url: `/adspresso/ads/${AdId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AdspressoAd'],
        }),
        updateAdspressoAdCreativeById: builder.mutation({
            query: ({ adcreativeId, payload }) => ({
                url: `/adspresso/adcreatives/${adcreativeId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AdspressoAd'],
        }),
        removeAd: builder.mutation({
            query: AdId => ({
                url: `/adspresso/ads/${AdId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdspressoAd']
        }),
        removeCreative: builder.mutation({
            query: adcreativeId => ({
                url: `/adspresso/adcreatives/${adcreativeId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdspressoAd']
        }),
    }),

});

export const { useGetAdspressoAdDataByCampaignIdQuery, useUpdateAdspressoAdDataByAdIdMutation, useGetAdspressoAdQuery, useCreateAdspressoAdHeaderMutation, useCreateAdspressoCreativeMutation, useGetAdspressoCreativeByCampaignIdQuery, useUpdateAdspressoAdByAdIdMutation, useUpdateAdspressoAdCreativeByIdMutation, useRemoveCreativeMutation , useRemoveAdMutation} = adspressoAdApi;