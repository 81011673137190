import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Link,
  ListItem,
  OrderedList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import {
  useRemoveFileMutation,
  useUploadLegalFileMutation,
} from "store/companies/uploadDocumentApi";
import {
  legalDetailsIndianFormSchema,
  legalDetailsNonIndianFormSchema,
} from "utils/schema";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import FormUploadBox from "../FormUploadBox";

function LegalDetailsForm({
  entity,
  onLegalDetailsFormSubmit,
  details,
  companyId,
  isLoading,
}) {
  const toast = useToast();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const [documents, setDocuments] = useState([]);
  const [documentToRemove, setdocumentToRemove] = useState(null);
  const [uploadingFileName, setUploadingFileName] = useState([]);
  const checkTextColor = useColorModeValue("gray.600", "gray.50");

  const {
    registrationCertificate,
    additionalDocument,
    bankDeclaration,
    formTenF,
    noPeCertificate,
    taxId,
    taxResidencyCertificate,
    gstCertificate,
    panCard,
    cancelledCheque,
    bankDetails,
    itDeclaration,
    msmeCertificate,
    panNumber,
    gstNumber,
  } = details || {};


  const [indianInitialValues, setindianInitialValues] = useState({});
  const [nonIndianInitialValues, setnonIndianInitialValues] = useState({});

  useEffect(() => {
    setindianInitialValues({
      panNumber: panNumber?.value || "",
      gstNumber: gstNumber?.value || "",
      termsCheck: gstNumber?.value && panNumber?.value ? true : false,
      confirmationCheck: gstNumber?.value && panNumber?.value ? true : false,
    });
    setnonIndianInitialValues({
      taxId: taxId?.value || "",
      termsCheck: taxId?.value ? true : false,
      confirmationCheck: taxId?.value ? true : false,
    });
  }, [details]);

  /** QUERIES */

  const [uploadFile, uploadResponse] = useUploadLegalFileMutation();
  const [removeFile, removalResponse] = useRemoveFileMutation();

  useEffect(() => {
    // //console.log(uploadResponse);
    if (uploadResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Document uploaded",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (uploadResponse.status == "rejected") {
      const {
        data: { message },
      } = uploadResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [uploadResponse]);

  useEffect(() => {
    //console.log(uploadResponse);
    if (removalResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Document removed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (removalResponse.status == "rejected") {
      const {
        data: { message },
      } = removalResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [removalResponse]);

  /* ALL DOCUMENTS UPLOAD */

  const handleFileUpload = (filename, acceptedFiles) => {
    acceptedFiles.map(async (file, index) => {
      const formData = new FormData();
      formData.append(filename, file);
      // //console.log({ filename, file });
      setUploadingFileName([...uploadingFileName, filename]);
      await uploadFile({ companyId, payload: formData })
        .unwrap()
        .finally(() => {
          const filteredFileList = uploadingFileName?.filter(
            (file, index) => file == filename
          );
          setUploadingFileName(filteredFileList);
        });
    });
  };


  const removeUploadedFile = async (id) => {
    await removeFile({ companyId, payload: { id } });
  };

  const handleDeleteCancel = () => {
    onDeleteModalClose();
    setdocumentToRemove(null);
  };

  const handleDeleteConfirm = async () => {
    onDeleteModalClose();
    await removeUploadedFile(documentToRemove);
  };

  const legalDetailsIndianForm = useFormik({
    initialValues: indianInitialValues,
    enableReinitialize: true,
    validationSchema: legalDetailsIndianFormSchema,
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      if (values) {
        const { gstNumber, panNumber } = values;
        if (
          gstNumber == indianInitialValues?.gstNumber &&
          panNumber == indianInitialValues?.panNumber
        ) {
          return;
        }

        const payload = {
          gstNumber: {
            value: values.gstNumber,
          },
          panNumber: {
            value: values.panNumber,
          },
        };

        onLegalDetailsFormSubmit(payload);

        legalDetailsIndianForm.resetForm();
      }
    },
  });

  const legalDetailsNonIndianForm = useFormik({
    initialValues: nonIndianInitialValues,
    enableReinitialize: true,
    validationSchema: legalDetailsNonIndianFormSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));

      if (values) {
        const { taxId } = values;
        if (taxId == nonIndianInitialValues?.taxId) {
          return;
        }
        const payload = {
          taxId: {
            value: taxId,
          },
        };
        onLegalDetailsFormSubmit(payload);
        legalDetailsNonIndianForm.resetForm();
      }
    },
  });

  return (
    <>
      <ConfirmDeleteModal
        copy="Are you sure you want to delete the document"
        isModalOpen={isDeleteModalOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          style={{ display: "flex", flexDirection: "column", gap: 10 }}
          maxW={["320px", "640px", "inherit"]}
          m={["0 auto", "auto"]}
        >
          <Text fontSize={"sm"} mb={"10px"}>
            Please upload files in jpeg, jpg, pdf or png formats, with a maximum
            10 MB size limit.
          </Text>
          {entity == "indian" ? (
            <Box>
              <Grid
                gridTemplateColumns={"repeat( auto-fill, minmax(260px, 1fr) )"}
                gap={"40px"}
              >

                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    GST Certificate{" "}
                    <Text
                      display={"inline-flex"}
                      fontSize={"18px"}
                      color={"red.400"}
                    >
                      *
                    </Text>
                    &nbsp;
                    <Tooltip
                      borderRadius={5}
                      label={
                        <Box fontSize={"sm"} p={"5px"}>
                          <Text>
                            Please submit all 3 sides of the certificate. In the absence of the GST Certificate, please upload a declaration stating the exemption from GST along with the reason for the same.
                          </Text>
                        </Box>
                      }
                      fontSize="md"
                    >
                      <InfoOutlineIcon pointerEvents={"all"} />
                    </Tooltip>
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={gstCertificate}
                      multiUpload={false}
                      fileKey="gstCertificate"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("gstCertificate")}
                      isInvalid={
                        legalDetailsIndianForm.touched.gstCertificate &&
                          legalDetailsIndianForm.errors.gstCertificate
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.gstCertificate &&
                    legalDetailsIndianForm.errors.gstCertificate && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.gstCertificate}
                      </Text>
                    )}
                  {!!gstCertificate &&
                    !gstCertificate?.approved &&
                    gstCertificate?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            gstCertificate?.rejectReasons[
                              gstCertificate?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            gstCertificate?.rejectReasons[
                              gstCertificate?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    PAN Card{" "}
                    <Text
                      display={"inline-flex"}
                      fontSize={"18px"}
                      color={"red.400"}
                    >
                      *
                    </Text>
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={panCard}
                      multiUpload={false}
                      fileKey="panCard"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("panCard")}
                      isInvalid={
                        legalDetailsIndianForm.touched.panCard &&
                          legalDetailsIndianForm.errors.panCard
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.panCard &&
                    legalDetailsIndianForm.errors.panCard && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.panCard}
                      </Text>
                    )}
                  {!!panCard &&
                    !panCard?.approved &&
                    panCard?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            panCard?.rejectReasons[
                              panCard?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            panCard?.rejectReasons[
                              panCard?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Cancelled Cheque
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={cancelledCheque}
                      multiUpload={false}
                      fileKey="cancelledCheque"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("cancelledCheque")}
                      isInvalid={
                        legalDetailsIndianForm.touched.cancelledCheque &&
                          legalDetailsIndianForm.errors.cancelledCheque
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.cancelledCheque &&
                    legalDetailsIndianForm.errors.cancelledCheque && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.cancelledCheque}
                      </Text>
                    )}
                  {!!cancelledCheque &&
                    !cancelledCheque?.approved &&
                    cancelledCheque?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            cancelledCheque?.rejectReasons[
                              cancelledCheque?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            cancelledCheque?.rejectReasons[
                              cancelledCheque?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Bank Declaration&nbsp;

                    <Tooltip
                      borderRadius={5}
                      label={
                        <Box fontSize={"sm"} p={"5px"}>
                          <Text>
                            All your Bank Details must be on the Bank letterhead
                          </Text>
                        </Box>
                      }
                      fontSize="md"
                    >
                      <InfoOutlineIcon pointerEvents={"all"} />
                    </Tooltip>
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={bankDetails}
                      multiUpload={false}
                      fileKey="bankDetails"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("bankDetails")}
                      isInvalid={
                        legalDetailsIndianForm.touched.bankDetails &&
                          legalDetailsIndianForm.errors.bankDetails
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.bankDetails &&
                    legalDetailsIndianForm.errors.bankDetails && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.bankDetails}
                      </Text>
                    )}
                  {!!bankDetails &&
                    !bankDetails?.approved &&
                    bankDetails?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            bankDetails?.rejectReasons[
                              bankDetails?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            bankDetails?.rejectReasons[
                              bankDetails?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Registration Certificate{" "}
                    {/* <Text
                      display={"inline-flex"}
                      fontSize={"18px"}
                      color={"red.400"}
                    >
                      *
                    </Text>{" "} */}
                    &nbsp;
                    <Tooltip
                      borderRadius={5}
                      label={
                        <Box fontSize={"sm"} p={"5px"}>
                          <Text>Please submit any one of the following:</Text>
                          <OrderedList>
                            <ListItem>Certificate of Incorporation</ListItem>
                            <ListItem>Articles of Association</ListItem>
                            <ListItem>Memorandum of Association</ListItem>
                          </OrderedList>
                        </Box>
                      }
                      fontSize="md"
                    >
                      <InfoOutlineIcon pointerEvents={"all"} />
                    </Tooltip>
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={registrationCertificate}
                      multiUpload={false}
                      fileKey="registrationCertificate"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isInvalid={
                        legalDetailsIndianForm.touched.registrationCertificate &&
                          legalDetailsIndianForm.errors.registrationCertificate
                          ? true
                          : false
                      }
                      isUploading={uploadingFileName.includes(
                        "registrationCertificate"
                      )}
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.registrationCertificate &&
                    legalDetailsIndianForm.errors.registrationCertificate && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.registrationCertificate}
                      </Text>
                    )}
                  {!!registrationCertificate &&
                    !registrationCertificate?.approved &&
                    registrationCertificate?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            registrationCertificate?.rejectReasons[
                              registrationCertificate?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            registrationCertificate?.rejectReasons[
                              registrationCertificate?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Last year's IT Declaration{" "}
                    {/* <Text
                      display={"inline-flex"}
                      fontSize={"18px"}
                      color={"red.400"}
                    >
                      *
                    </Text> */}
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={itDeclaration}
                      multiUpload={false}
                      fileKey="itDeclaration"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("itDeclaration")}
                      isInvalid={
                        legalDetailsIndianForm.touched.itDeclaration &&
                          legalDetailsIndianForm.errors.itDeclaration
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.itDeclaration &&
                    legalDetailsIndianForm.errors.itDeclaration && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.itDeclaration}
                      </Text>
                    )}
                  {!!itDeclaration &&
                    !itDeclaration?.approved &&
                    itDeclaration?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            itDeclaration?.rejectReasons[
                              itDeclaration?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            itDeclaration?.rejectReasons[
                              itDeclaration?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    MSME Certificate
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={msmeCertificate}
                      multiUpload={false}
                      fileKey="msmeCertificate"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("msmeCertificate")}
                      isInvalid={
                        legalDetailsIndianForm.touched.msmeCertificate &&
                          legalDetailsIndianForm.errors.msmeCertificate
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.msmeCertificate &&
                    legalDetailsIndianForm.errors.msmeCertificate && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.msmeCertificate}
                      </Text>
                    )}
                  {!!msmeCertificate &&
                    !msmeCertificate?.approved &&
                    msmeCertificate?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            msmeCertificate?.rejectReasons[
                              msmeCertificate?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            msmeCertificate?.rejectReasons[
                              msmeCertificate?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Additional Documents
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={additionalDocument}
                      multiUpload={false}
                      fileKey="additionalDocument"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes(
                        "additionalDocument"
                      )}
                      isInvalid={
                        legalDetailsIndianForm.touched.additionalDocument &&
                          legalDetailsIndianForm.errors.additionalDocument
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsIndianForm.touched.additionalDocument &&
                    legalDetailsIndianForm.errors.additionalDocument && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsIndianForm.errors.additionalDocument}
                      </Text>
                    )}
                  {!!additionalDocument &&
                    !additionalDocument?.approved &&
                    additionalDocument?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            additionalDocument?.rejectReasons[
                              additionalDocument?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
              </Grid>
              <Box gap="20px">
                {panCard?.value && gstCertificate?.value ? (
                  <>
                    <Divider orientation="horizontal" my={"40px"} />
                    <Flex w={"full"} gap={"20px"} alignItems={"flex-start"}>
                      <Box w={"full"}>
                        <FormLabel
                          fontSize="sm"
                          pointerEvents={"none"}
                          mb={"10px"}
                        >
                          PAN Number{" "}
                          <Text
                            display={"inline-flex"}
                            fontSize={"18px"}
                            color={"red.400"}
                          >
                            *
                          </Text>{" "}
                          &nbsp;
                        </FormLabel>
                        <Input
                          sx={{ textTransform: "uppercase" }}
                          name="panNumber"
                          placeholder={"PAN Number"}
                          onChange={legalDetailsIndianForm.handleChange}
                          value={legalDetailsIndianForm.values.panNumber}
                        />
                        {legalDetailsIndianForm.touched.panNumber &&
                          legalDetailsIndianForm.errors.panNumber && (
                            <Text
                              mt={"5px"}
                              fontSize={"12px"}
                              color={"red.600"}
                            >
                              {legalDetailsIndianForm.errors.panNumber}
                            </Text>
                          )}
                        {!panNumber?.value &&
                          !panNumber?.approved &&
                          panNumber?.rejectReasons?.length > 0 ? (
                          <Flex gap={"5px"} mt={"5px"}>
                            <Icon
                              as={FiAlertCircle}
                              boxSize={4}
                              color={"red.600"}
                            />
                            <Box>
                              <Text
                                display={"flex"}
                                fontSize={"12px"}
                                color={"red.600"}
                              >
                                <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                                  Rejected : &nbsp;
                                </Text>{" "}
                                {
                                  panNumber?.rejectReasons[
                                    panNumber?.rejectReasons?.length - 1
                                  ]?.notes
                                }
                              </Text>
                            </Box>
                          </Flex>
                        ) : null}
                      </Box>
                      <Box w={"full"}>
                        <FormLabel
                          fontSize="sm"
                          pointerEvents={"none"}
                          mb={"10px"}
                        >
                          GST Number{" "}
                          <Text
                            display={"inline-flex"}
                            fontSize={"18px"}
                            color={"red.400"}
                          >
                            *
                          </Text>{" "}
                          &nbsp;
                        </FormLabel>
                        <Input
                          name="gstNumber"
                          placeholder={"GST Number"}
                          onChange={legalDetailsIndianForm.handleChange}
                          value={legalDetailsIndianForm.values.gstNumber}
                        />
                        {legalDetailsIndianForm.touched.gstNumber &&
                          legalDetailsIndianForm.errors.gstNumber && (
                            <Text
                              mt={"5px"}
                              fontSize={"12px"}
                              color={"red.600"}
                            >
                              {legalDetailsIndianForm.errors.gstNumber}
                            </Text>
                          )}
                        {!gstNumber?.value &&
                          !gstNumber?.approved &&
                          gstNumber?.rejectReasons?.length > 0 ? (
                          <Flex gap={"5px"} mt={"5px"}>
                            <Icon
                              as={FiAlertCircle}
                              boxSize={4}
                              color={"red.600"}
                            />
                            <Box>
                              <Text
                                display={"flex"}
                                fontSize={"12px"}
                                color={"red.600"}
                              >
                                <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                                  Rejected : &nbsp;
                                </Text>{" "}
                                {
                                  gstNumber?.rejectReasons[
                                    gstNumber?.rejectReasons?.length - 1
                                  ]?.notes
                                }
                              </Text>
                            </Box>
                          </Flex>
                        ) : null}
                      </Box>
                    </Flex>

                    <Flex direction={"column"} mt={"20px"} gap={"20px"} >
                      <Checkbox
                        colorScheme={"orange"}
                        name="confirmationCheck"
                        isChecked={
                          legalDetailsIndianForm.values.confirmationCheck
                        }
                        onChange={legalDetailsIndianForm.handleChange}
                        isDisabled={
                          !legalDetailsIndianForm.values.gstNumber ||
                          !legalDetailsIndianForm.values.panNumber
                        }
                        size="sm"
                        alignItems={"baseline"}
                      >
                        <FormLabel fontSize={"14px"} color={checkTextColor} pointerEvents={'none'}>
                          I/We hereby solemnly affirm and declare that the
                          information given herein above is true and correct to
                          the best of my/our knowledge and belief and nothing has
                          been concealed therefrom.
                        </FormLabel>
                      </Checkbox>
                      {legalDetailsIndianForm.touched.confirmationCheck &&
                        legalDetailsIndianForm.errors.confirmationCheck && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {legalDetailsIndianForm.errors.confirmationCheck}
                          </Text>
                        )}
                      <FormControl>
                        <Checkbox
                          colorScheme={"orange"}
                          name="termsCheck"
                          isChecked={legalDetailsIndianForm.values.termsCheck}
                          onChange={legalDetailsIndianForm.handleChange}
                          isDisabled={
                            !legalDetailsIndianForm.values.gstNumber ||
                            !legalDetailsIndianForm.values.panNumber
                          }
                          size="sm"
                        >
                          <FormLabel fontSize={"14px"} color={checkTextColor}>
                            I/We also agree to abide by all the{" "}
                            <Link
                              color={"gray.500"}
                              as={RouterLink}
                              to={"/terms-conditions"}
                              isExternal
                            >
                              Terms and Conditions
                            </Link>{" "}
                            of Blink Digital that are applicable to the onboarding
                            data provided and acknowledge having read the{" "}
                            <Link
                              color={"gray.500"}
                              as={RouterLink}
                              to={"/privacy-policy"}
                              isExternal
                            >
                              Privacy Policy
                            </Link>{" "}
                            .
                          </FormLabel>
                        </Checkbox>
                      </FormControl>

                      {legalDetailsIndianForm.touched.termsCheck &&
                        legalDetailsIndianForm.errors.termsCheck && (
                          <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {legalDetailsIndianForm.errors.termsCheck}
                          </Text>
                        )}
                    </Flex>

                    <Box w={"full"} mt={"20px"}>
                      <Button
                        onClick={legalDetailsIndianForm.handleSubmit}
                        isDisabled={
                          !legalDetailsIndianForm.values.gstNumber ||
                          !legalDetailsIndianForm.values.panNumber
                        }
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Box>
          ) : null}

          {entity == "non-indian" ? (
            <Box>
              <Grid
                gridTemplateColumns={"repeat( auto-fill, minmax(260px, 1fr) )"}
                gap={"40px"}
              >
                <Box w={"full"}>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Registration Certificate{" "}
                    <Text
                      display={"inline-flex"}
                      fontSize={"18px"}
                      color={"red.400"}
                    >
                      *
                    </Text>
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={registrationCertificate}
                      multiUpload={false}
                      fileKey="registrationCertificate"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes(
                        "registrationCertificate"
                      )}
                      isInvalid={
                        legalDetailsNonIndianForm.touched
                          .registrationCertificate &&
                          legalDetailsNonIndianForm.errors.registrationCertificate
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsNonIndianForm.touched.registrationCertificate &&
                    legalDetailsNonIndianForm.errors
                      .registrationCertificate && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {
                          legalDetailsNonIndianForm.errors
                            .registrationCertificate
                        }
                      </Text>
                    )}
                  {!!registrationCertificate &&
                    !registrationCertificate?.approved &&
                    registrationCertificate?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            registrationCertificate?.rejectReasons[
                              registrationCertificate?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            registrationCertificate?.rejectReasons[
                              registrationCertificate?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
                <FormControl w={"full"} isRequired>
                  <Flex align={"center"} mb={"10px"}>
                    <FormLabel
                      mb={0}
                      lineHeight={2}
                      fontSize="sm"
                      pointerEvents={"none"}
                    >
                      Bank Declaration{" "}
                    </FormLabel>
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon fontSize={"sm"} />
                      </PopoverTrigger>
                      <PopoverContent
                        color="white"
                        bg="gray.700"
                        borderColor="gray.700"
                        pr={"20px"}
                      >
                        <PopoverArrow bg="gray.700" />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Text>
                            Please provide a bank declaration with your account
                            information on the bank's letterhead.
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Box>
                    <FormUploadBox
                      fieldData={bankDeclaration}
                      multiUpload={false}
                      fileKey="bankDeclaration"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("bankDeclaration")}
                      isInvalid={
                        legalDetailsNonIndianForm.touched.bankDeclaration &&
                          legalDetailsNonIndianForm.errors.bankDeclaration
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsNonIndianForm.touched.bankDeclaration &&
                    legalDetailsNonIndianForm.errors.bankDeclaration && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsNonIndianForm.errors.bankDeclaration}
                      </Text>
                    )}
                  {!!bankDeclaration &&
                    !bankDeclaration?.approved &&
                    bankDeclaration?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            bankDeclaration?.rejectReasons[
                              bankDeclaration?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            bankDeclaration?.rejectReasons[
                              bankDeclaration?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </FormControl>
                <FormControl w={"full"}>
                  <Flex align={"center"} mb={"10px"}>
                    <FormLabel
                      mb={0}
                      lineHeight={2}
                      fontSize="sm"
                      pointerEvents={"none"}
                    >
                      Form 10F
                    </FormLabel>
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon fontSize={"sm"} />
                      </PopoverTrigger>
                      <PopoverContent
                        color="white"
                        bg="gray.700"
                        borderColor="gray.700"
                        pr={"20px"}
                      >
                        <PopoverArrow bg="gray.700" />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Text>
                            Please fill{" "}
                            <Link
                              isExternal
                              href="https://forex-media-space.ams3.digitaloceanspaces.com/public/10F-Format.docx"
                              cursor={"pointer"}
                              textDecoration={"underline"}
                              download
                            >
                              this
                            </Link>{" "}
                            form and submit. Form to be on the company
                            letterhead.{" "}
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Box>
                    <FormUploadBox
                      fieldData={formTenF}
                      multiUpload={false}
                      fileKey="formTenF"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("formTenF")}
                      isInvalid={
                        legalDetailsNonIndianForm.touched.formTenF &&
                          legalDetailsNonIndianForm.errors.formTenF
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsNonIndianForm.touched.formTenF &&
                    legalDetailsNonIndianForm.errors.formTenF && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsNonIndianForm.errors.formTenF}
                      </Text>
                    )}
                  {!!formTenF &&
                    !formTenF?.approved &&
                    formTenF?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            formTenF?.rejectReasons[
                              formTenF?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            formTenF?.rejectReasons[
                              formTenF?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </FormControl>
                <FormControl w={"full"}>
                  <Flex align={"center"} mb={"10px"}>
                    <FormLabel
                      mb={0}
                      lineHeight={2}
                      fontSize="sm"
                      pointerEvents={"none"}
                    >
                      No PE Certificate
                    </FormLabel>
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon fontSize={"sm"} />
                      </PopoverTrigger>
                      <PopoverContent
                        color="white"
                        bg="gray.700"
                        borderColor="gray.700"
                        pr={"20px"}
                      >
                        <PopoverArrow bg="gray.700" />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Text>
                            Please fill{" "}
                            <Link
                              isExternal
                              href="https://forex-media-space.ams3.digitaloceanspaces.com/public/No-PE-Format.docx"
                              cursor={"pointer"}
                              textDecoration={"underline"}
                              download
                            >
                              this
                            </Link>{" "}
                            form and submit. Form to be on the company
                            letterhead.{" "}
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Box>
                    <FormUploadBox
                      fieldData={noPeCertificate}
                      multiUpload={false}
                      fileKey="noPeCertificate"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes("noPeCertificate")}
                      isInvalid={
                        legalDetailsNonIndianForm.touched.noPeCertificate &&
                          legalDetailsNonIndianForm.errors.noPeCertificate
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsNonIndianForm.touched.noPeCertificate &&
                    legalDetailsNonIndianForm.errors.noPeCertificate && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsNonIndianForm.errors.noPeCertificate}
                      </Text>
                    )}
                  {!!noPeCertificate &&
                    !noPeCertificate?.approved &&
                    noPeCertificate?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            noPeCertificate?.rejectReasons[
                              noPeCertificate?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            noPeCertificate?.rejectReasons[
                              noPeCertificate?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </FormControl>
                <FormControl w={"full"}>
                  <Flex align={"center"} mb={"10px"}>
                    <FormLabel
                      mb={0}
                      lineHeight={2}
                      fontSize="sm"
                      pointerEvents={"none"}
                    >
                      Tax Residency Certificate
                    </FormLabel>
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon fontSize={"sm"} />
                      </PopoverTrigger>
                      <PopoverContent
                        color="white"
                        bg="gray.700"
                        borderColor="gray.700"
                        pr={"20px"}
                      >
                        <PopoverArrow bg="gray.700" />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Text>
                            Please submit a document that shows the tax
                            residency of your entity{" "}
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Box>
                    <FormUploadBox
                      fieldData={taxResidencyCertificate}
                      multiUpload={false}
                      fileKey="taxResidencyCertificate"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes(
                        "taxResidencyCertificate"
                      )}
                      isInvalid={
                        legalDetailsNonIndianForm.touched
                          .taxResidencyCertificate &&
                          legalDetailsNonIndianForm.errors.taxResidencyCertificate
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsNonIndianForm.touched.taxResidencyCertificate &&
                    legalDetailsNonIndianForm.errors
                      .taxResidencyCertificate && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {
                          legalDetailsNonIndianForm.errors
                            .taxResidencyCertificate
                        }
                      </Text>
                    )}
                  {!!taxResidencyCertificate &&
                    !taxResidencyCertificate?.approved &&
                    taxResidencyCertificate?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            taxResidencyCertificate?.rejectReasons[
                              taxResidencyCertificate?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            taxResidencyCertificate?.rejectReasons[
                              taxResidencyCertificate?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </FormControl>


                <Box w={"full"}>
                  <FormLabel
                    fontSize="sm"
                    pointerEvents={"none"}
                    mb={"10px"}
                    lineHeight={2}
                  >
                    Additional Documents
                  </FormLabel>
                  <Box>
                    <FormUploadBox
                      fieldData={additionalDocument}
                      multiUpload={true}
                      fileKey="additionalDocument"
                      fileList={documents}
                      onFileUpload={handleFileUpload}
                      onRemoveClick={(id) => {
                        setdocumentToRemove(id);
                        onDeleteModalOpen();
                      }}
                      isUploading={uploadingFileName.includes(
                        "additionalDocument"
                      )}
                      isInvalid={
                        legalDetailsNonIndianForm.touched.additionalDocument &&
                          legalDetailsNonIndianForm.errors.additionalDocument
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {legalDetailsNonIndianForm.touched.additionalDocument &&
                    legalDetailsNonIndianForm.errors.additionalDocument && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsNonIndianForm.errors.additionalDocument}
                      </Text>
                    )}
                  {!!additionalDocument &&
                    !additionalDocument?.approved &&
                    additionalDocument?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            additionalDocument?.rejectReasons[
                              additionalDocument?.rejectReasons?.length - 1
                            ]?.reason
                          }
                        </Text>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Notes : &nbsp;
                          </Text>{" "}
                          {
                            additionalDocument?.rejectReasons[
                              additionalDocument?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
              </Grid>
              <Divider my={"40px"} orientation="horizontal" />

              <Flex
                w={{ base: "full", md: "calc(50% - 20px)" }}
                gap="20px"
                direction={"row"}
                align={"flex-end"}
                mt={"20px"}
              >
                <Box w={"full"}>
                  <FormLabel fontSize="sm" pointerEvents={"none"} mb={"10px"}>
                    Tax ID{" "}
                    <Text
                      display={"inline-flex"}
                      fontSize={"18px"}
                      color={"red.400"}
                    >
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    name="taxId"
                    placeholder={"Tax ID"}
                    onChange={legalDetailsNonIndianForm.handleChange}
                    value={legalDetailsNonIndianForm.values.taxId}
                    isInvalid={
                      legalDetailsNonIndianForm.touched.taxId &&
                        legalDetailsNonIndianForm.errors.taxId
                        ? true
                        : false
                    }
                  />
                  {legalDetailsNonIndianForm.touched.taxId &&
                    legalDetailsNonIndianForm.errors.taxId && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {legalDetailsNonIndianForm.errors.taxId}
                      </Text>
                    )}
                  {!!taxId &&
                    !taxId?.approved &&
                    taxId?.rejectReasons?.length > 0 ? (
                    <Flex gap={"5px"} mt={"5px"}>
                      <Icon as={FiAlertCircle} boxSize={4} color={"red.600"} />
                      <Box>
                        <Text
                          display={"flex"}
                          fontSize={"12px"}
                          color={"red.600"}
                        >
                          <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
                            Rejected : &nbsp;
                          </Text>{" "}
                          {
                            taxId?.rejectReasons[
                              taxId?.rejectReasons?.length - 1
                            ]?.notes
                          }
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}
                </Box>
              </Flex>
              <Flex direction={"column"} mt={"20px"} gap={"20px"}>
                <Checkbox
                  colorScheme={"orange"}
                  name="confirmationCheck"
                  isChecked={legalDetailsNonIndianForm.values.confirmationCheck}
                  onChange={legalDetailsNonIndianForm.handleChange}
                  isDisabled={!legalDetailsNonIndianForm.values.taxId}
                  alignItems={"baseline"}
                >
                  <FormLabel fontSize={"14px"} color={checkTextColor}>
                    I/We hereby solemnly affirm and declare that the information
                    given herein above is true and correct to the best of my/our
                    knowledge and belief and nothing has been concealed therefrom.
                  </FormLabel>
                </Checkbox>
                {legalDetailsNonIndianForm.touched.confirmationCheck &&
                  legalDetailsNonIndianForm.errors.confirmationCheck && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {legalDetailsNonIndianForm.errors.confirmationCheck}
                    </Text>
                  )}

                <FormControl>
                  <Checkbox
                    colorScheme={"orange"}
                    name="termsCheck"
                    isChecked={legalDetailsNonIndianForm.values.termsCheck}
                    onChange={legalDetailsNonIndianForm.handleChange}
                    isDisabled={!legalDetailsNonIndianForm.values.taxId}
                  >
                    <FormLabel fontSize={"14px"} color={checkTextColor}>
                      I/We also agree to abide by all the{" "}
                      <Link
                        color={"gray.500"}
                        as={RouterLink}
                        to={"/terms-conditions"}
                        isExternal
                      >
                        Terms and Conditions
                      </Link>{" "}
                      of Blink Digital that are applicable to the onboarding
                      data provided and acknowledge having read the{" "}
                      <Link
                        color={"gray.500"}
                        as={RouterLink}
                        to={"/privacy-policy"}
                        isExternal
                      >
                        Privacy Policy
                      </Link>{" "}
                      .
                    </FormLabel>
                  </Checkbox>
                </FormControl>

                {legalDetailsNonIndianForm.touched.termsCheck &&
                  legalDetailsNonIndianForm.errors.termsCheck && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {legalDetailsNonIndianForm.errors.termsCheck}
                    </Text>
                  )}
              </Flex>
              <Button
                mt={"10px"}
                onClick={legalDetailsNonIndianForm.handleSubmit}
                isDisabled={!legalDetailsNonIndianForm.values.taxId}
              >
                Save
              </Button>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
}

export default LegalDetailsForm;
