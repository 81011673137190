import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightElement, List, ListItem, Select, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import { useGetGeoLocationsQuery } from 'store/facebookServices/facebookServicesApi';

const flattenLocations = (locationsObj, inclusionType) => {
    if (Object.entries(locationsObj)?.length == 0) {
        return;
    }

    return Object.entries(locationsObj).flatMap(([type, locations]) =>
        locations.map(location => ({
            ...location,
            inclusionType,
            //type // add the location type to each object
        }))
    );
};

const locationHierarchy = {
    'country': 3,
    'region': 2,
    'city': 1,
    'neighborhood': 0
};

// Helper function to check if a location should be replaced
const shouldRemoveLocation = (newLocation, existingLocation) => {
    const isSameCountry = newLocation.country_code === existingLocation.country_code;

    // Check hierarchy-specific relationships
    const isRegionWithinCountry =
        newLocation.type === "region" &&
        existingLocation.type === "country" &&
        newLocation.country_name === existingLocation.name;

    const isSameLocation =
        newLocation.name === existingLocation.name ||
        newLocation.region === existingLocation.name ||
        newLocation.name === existingLocation.region;

    const isSpecificExclusion =
        existingLocation.type === "country" &&
        newLocation.type === "region" &&
        existingLocation.inclusionType === "include" &&
        newLocation.inclusionType === "exclude";

    // Replace broader location only if hierarchy rules allow it
    return (
        isSameCountry &&
        (isRegionWithinCountry || isSameLocation) &&
        !isSpecificExclusion
    );
};

// Remove locations of different specificity when a new one is selected
const filterSelectedLocations = (newLocation, selectedLocations) => {
    // Filter out all locations that share the same country but have different specificity levels
    return selectedLocations.filter((location) => !shouldRemoveLocation(newLocation, location));
};



const GeoLocationsSelector = ({ onLocationsChange, preFilledIncludedLocations, preFilledExcludedLocations, onBlur, selectedZipCodes, BMID }) => {


    const inputRef = useRef(null);
    const popupRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [locationOptions, setLocationOptions] = useState([]);

    const flattenedPreFilledIncludedLocations = preFilledIncludedLocations ? flattenLocations(preFilledIncludedLocations, 'include') : [];
    const flattenedPreFilledExcludedLocations = preFilledExcludedLocations ? flattenLocations(preFilledExcludedLocations, 'exclude') : [];

    const [selectedLocations, setSelectedLocations] = useState([...flattenedPreFilledIncludedLocations, ...flattenedPreFilledExcludedLocations] || []);
    const [showPopup, setShowPopup] = useState(false);
    const [inclusionType, setInclusionType] = useState('include'); // Default to include

    const { data: locations, isLoading, isFetching, refetch } = useGetGeoLocationsQuery({ searchTerm: debouncedSearchTerm, BMID }, {});


    // console.log({ flattenedZipcodes });
    console.log({ selectedLocations });

    useEffect(() => {
        if (selectedZipCodes?.length) {
            let flattenedZipcodes_ = flattenLocations({ 'zips': selectedZipCodes }, 'include');
            setSelectedLocations((prev) => [...prev, ...flattenedZipcodes_])
        }
    }, [selectedZipCodes])


    useEffect(() => {
        if (searchTerm?.length === 0) {
            setDebouncedSearchTerm('');
            setShowPopup(false);
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length > 0 && searchTerm === debouncedSearchTerm) {
            refetch();
        }
    }, [searchTerm, debouncedSearchTerm]);

    useEffect(() => {
        if (locations?.data?.length > 0) {
            setShowPopup(true);
            setLocationOptions(locations?.data);
        } else {
            setShowPopup(false);
        }
    }, [locations, isFetching]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                popupRef.current &&
                !popupRef.current.contains(event.target)
            ) {
                setShowPopup(false);
                setLocationOptions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLocationSelect = (location) => {
        const locationWithInclusionType = { ...location, inclusionType };

        setSelectedLocations(prevLocations => {
            const updatedLocations = filterSelectedLocations(locationWithInclusionType, prevLocations);
            const finalLocations = [...updatedLocations, locationWithInclusionType];

            //console.log({ finalLocations });


            // Call the parent function to notify about the updated selected locations
            setTimeout(() => onLocationsChange(finalLocations), 0);

            return finalLocations;
        });

        setSearchTerm('');
        setDebouncedSearchTerm('');
        setShowPopup(false);
        setLocationOptions([]);
    };

    const removeLocation = (id) => {
        setSelectedLocations((prevLocations) => {
            const updatedLocations = prevLocations.filter((location) => location.key !== id);
            setTimeout(() => onLocationsChange(updatedLocations), 0);
            return updatedLocations;
        });
    };

    // console.log(selectedLocations);


    const hasIncludeEntry = selectedLocations?.some(
        (location) => location?.inclusionType === "include"
    );


    return (
        <Box>
            <Box mt="10px" mb={'5px'} maxHeight="200px" overflowY="auto">
                {selectedLocations?.map((location, index) => (
                    <Flex key={`${location.key}-${index}`} bg={'white'} align={'center'} justify={'space-between'} width={'full'} py={'5px'} px={'10px'} borderRadius={'md'} mb={'5px'}>
                        <Flex flex={1} pr={'10px'} align={'center'}>
                            <FaMapMarkerAlt color={location.inclusionType == 'include' ? '#00A500' : '#FA353B'} size="16px" />
                            <Flex justify={'space-between'} flex={1} align={'center'}>
                                <Text ml={'5px'} fontSize={'14px'} fontWeight={'bold'}>{location.name} </Text>
                                <Text ml={'5px'} fontSize={'12px'}>{location.type} ({location.inclusionType})</Text>
                            </Flex>
                        </Flex>
                        <CloseIcon _hover={{ cursor: 'pointer', color: 'red.400' }} boxSize={3} color='gray.500' onClick={() => removeLocation(location?.key)} />
                    </Flex>
                ))}
            </Box>

            {/* Dropdown for inclusion/exclusion */}
            <Flex>
                <Box position={'relative'}>
                    <FaMapMarkerAlt size="14px" color={inclusionType == 'include' ? '#00A500' : '#FA353B'} style={{ position: 'absolute', left: 0, top: 8, left: 5 }} />
                    <Select
                        value={inclusionType}
                        onChange={(e) => setInclusionType(e.target.value)}
                        mb={2}
                        size={'sm'}
                        maxW={'auto'}
                        style={{ paddingLeft: '22px' }}
                        borderRadius={'md'}
                        borderRight={0}
                        borderRightRadius={0}
                    >
                        <option value="include"> Include</option>
                        <option value="exclude" disabled={!hasIncludeEntry}>Exclude</option>
                    </Select>
                </Box>

                <Box position="relative" flex={1}>
                    <InputGroup alignItems={'center'}>
                        <InputLeftElement height={'32px'}>
                            <SearchIcon boxSize={4} color='gray.500' />
                        </InputLeftElement>
                        <Input
                            id='geo_locations'
                            name='geo_locations'
                            ref={inputRef}
                            value={searchTerm}
                            onChange={(e) => { setSearchTerm(e.target.value); }}
                            placeholder="Search locations"
                            autoComplete="off"
                            size={'sm'}
                            borderRadius={'md'}
                            borderLeftRadius={0}
                            onBlur={onBlur}
                            onFocus={() => {
                                setLocationOptions([]);
                                if (locationOptions?.length > 0) {
                                    setShowPopup(true);
                                }
                            }}
                        />
                        <InputRightElement height={'32px'}>
                            {isFetching ? (
                                <Spinner boxSize={3} />
                            ) : (
                                searchTerm?.length > 0 && (
                                    <CloseIcon boxSize={3} color='gray.500' onClick={() => setSearchTerm('')} />
                                )
                            )}
                        </InputRightElement>
                    </InputGroup>

                    {isLoading ? (
                        <Flex align={'center'} justify={'center'} minH={'200px'} minW={'200px'}>
                            <Spinner />
                        </Flex>
                    ) : (
                        showPopup && locationOptions.length > 0 && (
                            <List
                                ref={popupRef}
                                position="absolute"
                                top="100%"
                                width="100%"
                                border="1px solid #ccc"
                                backgroundColor="white"
                                zIndex="1000"
                                maxHeight="200px"
                                overflowY="auto"
                            >
                                {locationOptions.map((location) => (
                                    <ListItem
                                        key={location.region_id + location.name}
                                        p={'5px'}
                                        px={'10px'}
                                        cursor="pointer"
                                        _hover={{ backgroundColor: '#f0f0f0' }}
                                        onClick={() => handleLocationSelect(location)}
                                    >
                                        <Flex justify={'space-between'}>
                                            <Text fontSize={'14px'}>{location.name}</Text>
                                            <Text fontSize={'12px'}>{location.type}</Text>
                                        </Flex>
                                    </ListItem>
                                ))}
                            </List>
                        )
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default GeoLocationsSelector;
