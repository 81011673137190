import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';

const styles = {
    global: (props) => ({
        body: {
            bg: mode(
                // light mode value retrieved from theme
                props.theme.semanticTokens.colors['chakra-body-bg']._light,
                // your custom value for dark mode
                '#000',
            )(props),
        },
    }),
};

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    fonts: {
        heading: `'Katwijk-Bold', sans-serif`,
        body: `'Katwijk-Light', sans-serif`,
    },
    colors: {
        gray: {
            50: '#f5f5f5',
            100: '#d0d0d0',
            200: '#c8c8c8',
            300: '#d0d0d0',
            400: '#b1b1b1',
            500: '#969696',
            600: '#a1a1a1',
            700: '#757575',
            800: '#464646',
            900: '#2b2b2b',
        },
        brand: {
            100: '#ee9173',
            200: '#ea7869',
            300: '#e7646b',
            400: '#915490',
            700: '#ee9173',
            800: '#ea7869',
            900: '#e7646b',
        },
        orange: {
            200: '#ee9173',
            300: '#ea7869',
            500: '#ea7869',
            600: '#e7646b'
        }
    },
    components: {
        Input: {
            baseStyle: {
                field: {
                    bg: "inherit", // Default background color (applies to dark mode)
                },
            },
            variants: {
                outline: {
                    field: {
                        bg: "white", // Light mode background color
                        _dark: {
                            bg: "inherit", // Dark mode background color
                        },
                    },
                },
                filled: {
                    field: {
                        bg: "white",
                        _dark: {
                            bg: "inherit",
                        },
                    },
                },
                flushed: {
                    field: {
                        bg: "white",
                        _dark: {
                            bg: "inherit",
                        },
                    },
                },
                unstyled: {
                    field: {
                        bg: "white",
                        _dark: {
                            bg: "inherit",
                        },
                    },
                },
            },
            defaultProps: {
                variant: "outline",
            },
        },
        Select: {
            baseStyle: {
                field: {
                    bg: "inherit", // Default background color (applies to dark mode)
                },
            },
            variants: {
                outline: {
                    field: {
                        bg: "white", // Light mode background color
                        _dark: {
                            bg: "inherit", // Dark mode background color
                        },
                    },
                },
                filled: {
                    field: {
                        bg: "white",
                        _dark: {
                            bg: "inherit",
                        },
                    },
                },
                flushed: {
                    field: {
                        bg: "white",
                        _dark: {
                            bg: "inherit",
                        },
                    },
                },
                unstyled: {
                    field: {
                        bg: "white",
                        _dark: {
                            bg: "inherit",
                        },
                    },
                },
            },
            defaultProps: {
                variant: "outline",
            },
        },
        Textarea: {
            baseStyle: {
                bg: "inherit", // Default background color (applies to dark mode)
            },
            variants: {
                outline: {
                    bg: "white", // Light mode background color
                    _dark: {
                        bg: "inherit", // Dark mode background color
                    },
                },
                filled: {
                    bg: "white",
                    _dark: {
                        bg: "inherit",
                    },
                },
                flushed: {
                    bg: "white",
                    _dark: {
                        bg: "inherit",
                    },
                },
                unstyled: {
                    bg: "white",
                    _dark: {
                        bg: "inherit",
                    },
                },
            },
            defaultProps: {
                variant: "outline",
            },
        },
        // Checkbox: {
        //     baseStyle: {
        //         control: {
        //             bg: "inherit", // Default background color (applies to dark mode)
        //             _checked: {
        //                 bg: "white", // Light mode background color when checked
        //                 _dark: {
        //                     bg: "inherit", // Dark mode background color when checked
        //                 },
        //             },
        //         },
        //     },
        // },
        // Radio: {
        //     baseStyle: {
        //         control: {
        //             bg: "inherit", // Default background color (applies to dark mode)
        //             _checked: {
        //                 bg: "white", // Light mode background color when checked
        //                 _dark: {
        //                     bg: "inherit", // Dark mode background color when checked
        //                 },
        //             },
        //         },
        //     },
        // },
    },

    //styles
}

const theme = extendTheme(config)

export default theme