import { api } from '../api'

export const facebookServicesApi = api.injectEndpoints({

    endpoints: (builder) => ({
        getPixelById: builder.query({
            query: (pixelId) => `/ads-accounts/adspixels/${pixelId}/adspixel`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getPixelByAdaccountId: builder.query({
            query: (adsAccountId) => `/ads-accounts/pixels/${adsAccountId}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        createPixel: builder.mutation({
            query: ({ adsAccountId, payload }) => ({
                url: `/ads-accounts/${adsAccountId}/adspixels`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                error.message = response.data.message;
                return error;
            },
        }),
        getFacebookPages: builder.query({
            query: (adsAccountId) => `/ads-accounts//pages/${adsAccountId}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getInstagramAccounts: builder.query({
            query: (adsAccountId) => `/ads-accounts/instagrams/${adsAccountId}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        // getGeoLocations: builder.query({
        //     query: ({ searchTerm, BMID }) => `adsets/search?q=${searchTerm}&type=adgeolocation&businessId=${BMID}&limit=10`,
        //     transformResponse: (response, meta, arg) => response.data,
        // }),
        getGeoLocations: builder.query({
            query: ({ searchTerm, BMID }) => ({
                url: `adsets/search?q=${searchTerm}&type=adgeolocation&businessId=${BMID}&limit=10`,
                method: 'POST',
                // body: {} 
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getDetailedTargeting: builder.query({
            query: ({ searchTerm, adsAccountId, BMID }) => `adsets/targetingsearch/${adsAccountId}?q=${searchTerm}&businessId=${BMID}&limit=20`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        // getLocales: builder.query({
        //     query: ({ searchTerm }) => `adsets/search?type=adlocale&q=${searchTerm}&limit=20`,
        //     transformResponse: (response, meta, arg) => response.data,
        // }),
        getLocales: builder.query({
            query: ({ searchTerm,BMID }) => ({
                url: `adsets/search?type=adlocale&q=${searchTerm}&businessId=${BMID}&limit=20`,
                method: 'POST',
                // body: {} 
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        // matchBulkLocations: builder.query({
        //     query: ({ zips, BMID }) => `adsets/search?type=adgeolocationmeta&zips=${zips}&businessId=${BMID}`,
        //     transformResponse: (response, meta, arg) => response.data,
        // }),
        matchBulkLocations: builder.query({
            query: ({ zips, BMID }) => ({
                url: `adsets/search?type=adgeolocationmeta&businessId=${BMID}`,
                method: 'POST',
                body: { zips } 
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetPixelByAdaccountIdQuery,
    useGetPixelByIdQuery,
    useCreatePixelMutation,
    useGetFacebookPagesQuery,
    useGetInstagramAccountsQuery,
    useGetGeoLocationsQuery,
    useGetDetailedTargetingQuery,
    useGetLocalesQuery,
    useMatchBulkLocationsQuery
} = facebookServicesApi;
