import { AddIcon, ChevronDownIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import SearchAdaccountAdspresso from "components/bmControl/SearchAdaccountAdspresso";
import AdspressoCampaignForm from "components/campaignForms/AdspressoCampaignForm";
import AdspressoTable from "components/campaignTables/AdspressoTable";
import SidebarWithHeader from "components/SidebarWithHeader";
import { useFormik } from "formik";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateAdspressoCampaignMutation } from "store/adspresso/adspressoCampaignApi";
import { useGetBmAdAccountsQuery } from "store/BmAccounts/bmAccountApi";
import { selectAdAccountAssigned, selectAdAccountLoading } from "store/BmAccounts/bmAccountSlice";
import { useGetcampaignFormQuery } from "store/campaigns/campaignsApi";
import { campaignFormSchema } from "utils/schema";

const normalizeTimezone = (timezone) => {
  // Normalize timezone names like "Asia/Calcutta" to "Asia/Kolkata"
  const timezoneMapping = {
    "Asia/Calcutta": "Asia/Kolkata",
  };

  return timezoneMapping[timezone] || timezone;
};

function AdspressoForClient() {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get('business_id');
  const hasAdAccountAssigned = useSelector(selectAdAccountAssigned);
  const adAccountLoading = useSelector(selectAdAccountLoading);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQueryTriggered, setIsQueryTriggered] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [afterValue, setAftervalue] = useState('');

  const { data: AdAccountData, refetch: refetchAdaccountData, isLoading:isAdAccountLoading, isFetching:isAdAccountFetching, error:AdAccountError, isError:isAdaccountError } = useGetBmAdAccountsQuery(
    {
        search: searchTerm ? searchTerm : '',
        pageSize:'20',
        after: searchTerm ? '' : afterValue,
    },
);

  const { data: dataCampaign, error: errorCampaign, isLoading: isLoadingCampaign } = useGetcampaignFormQuery(undefined, { skip: !isQueryTriggered });
  const [createCampaignData, { isLoading: isCreating, error: createError }] = useCreateAdspressoCampaignMutation();

  useEffect(() => {
    if (AdAccountError) {

      toast({
        position: "top-right",
        title: "Error",
        description: AdAccountError?.data?.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [AdAccountError]);

  useEffect(() => {
    if (hasSearched && searchTerm === '') {
      refetchAdaccountData();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (AdAccountData) {
      const newAccounts = AdAccountData.data.filter(
        (account) => !adAccounts.some((existingAccount) => existingAccount.account_id === account.account_id)
      );
      setAdAccounts((prevAccounts) => [...prevAccounts, ...newAccounts]);;
    }
  }, [AdAccountData]);

  const campaignFormik = useFormik({
    initialValues: {
      adsAccounts: [],
      name: "",
      objective: "OUTCOME_LEADS",
      status: "PAUSED",
    },
    validationSchema: campaignFormSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name, objective, status, adsAccounts } = values;
      const modifiedAdsAccounts = adsAccounts.map(({ currency, ...rest }) => rest);
      const payload = {
        name,
        objective,
        status,
        adsAccounts: modifiedAdsAccounts,
      };

      createCampaignData({ payload })
        .unwrap()
        .then((response) => {
          toast({
            position: "top-right",
            title: "Campaign created",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          resetForm();
          handleCloseModal();
          navigate(`/adspresso-campaigns/${response.data._id}`);
        })
        .catch(() => {
          toast({
            position: "top-right",
            title: "Error in creating",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        })
        .finally(() => {
          setSubmitting(false);
          localStorage.removeItem('selectedAdspressoAdsAccount');
          setSelectedAdAccounts([]);
          setAftervalue('');
        });
    },
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsQueryTriggered(true);
  };

  const handleCloseModal = () => {
    campaignFormik.resetForm();
    setIsModalOpen(false);
    setIsQueryTriggered(false);
    setSelectedAdAccounts([]);
    setAftervalue('');
    localStorage.removeItem('selectedAdspressoAdsAccount');
  };


  const handleAdaccountChange = (value) => {

      if (value) {
        
        const isAlreadySelected = selectedAdAccounts.some(
          (account) => account?.adsAccountId === value?.account_id
        );

        if (!isAlreadySelected) {
          const accountDetails = {
            adsAccountName: value?.account?.name,
            adsAccountId: value?.account?.account_id,
            id: value?.account?.id,
            businessName: value?.account?.business?.name,
            businessId: value?.account?.business?.id,
            currency: value?.account?.currency,
            accountTimezone: value?.account?.timezone_name,
          };

          // Check for currency consistency by including the new account in the validation
          const currencies = [
            ...new Set([...selectedAdAccounts.map((account) => account.currency), accountDetails.currency])
          ];

          if (currencies.length > 1) {
            campaignFormik.setFieldError("adsAccounts", "Selected Ad Accounts have different currencies.");
            return; // Prevent adding the account if currencies are inconsistent
          }

          // Normalize and check for timezone consistency by including the new account in the validation
          const normalizedTimezones = [
            ...new Set([
              ...selectedAdAccounts.map((account) => normalizeTimezone(account.accountTimezone)),
              normalizeTimezone(accountDetails.accountTimezone),
            ]),
          ];

          if (normalizedTimezones.length > 1) {
            campaignFormik.setFieldError("adsAccounts", "Selected Ad Accounts have different timezones.");
            return; // Prevent adding the account if timezones are inconsistent
          }

          // Add the account to the selectedAdAccounts state
          setSelectedAdAccounts((prevState) => {
            const updatedAccounts = [accountDetails, ...prevState];

            // Update the form field with the new selected accounts (filtered)
            const filteredAccounts = updatedAccounts.map(({ accountTimezone, ...rest }) => rest);

            campaignFormik.setFieldValue("adsAccounts", filteredAccounts);
            return updatedAccounts;
          });
        }
      }

  };

  const handleSearchChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  // Function to remove an account from selectedAdAccounts
  const handleRemoveAdAccount = (accountId) => {
    // Remove the account from the selectedAdAccounts state
    setSelectedAdAccounts((prevState) => {
      const updatedAccounts = prevState.filter(account => account.adsAccountId !== accountId);

      // Update campaignFormik values (adsAccounts)
      // Remove the account from form values as well
      const filteredAccounts = updatedAccounts.map(({ accountTimezone, ...rest }) => rest);
      campaignFormik.setFieldValue("adsAccounts", filteredAccounts);

      return updatedAccounts;
    });
  };

  const handleLoadMore = (after) => {
    setAftervalue(after);
  };


  return (
    <SidebarWithHeader>
      {isAdaccountError ? (
        <Flex justify="center" align="center" height="80vh">
          <Text color="red.500">
            {AdAccountError?.data?.message}
          </Text>
        </Flex>
      // ) : (isAdAccountLoading) ? (
      //   <Flex justify="center" align="center" height="80vh">
      //     <Spinner mt="20px" />
      //   </Flex>
      ) : (
        <>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent maxWidth="840px">
              <ModalHeader>Create new campaign</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="row" justify="space-between" gap="20px">
                  {/* Ad Accounts Section (Left Side) */}
                  <Flex
                    direction="column"
                    mb={"20px"}
                    flex={0.5}
                    minH={"360px"}
                    maxHeight="290px"
                    overflowY="auto"
                    p={1}
                    gap={2}
                  >
                    {/* Display chosen accounts */}
                    <Text mb={2} fontWeight={"bold"}>
                      Choose Ad Accounts
                    </Text>

                    {/* Search Ad Account Section (Fixed) */}
                    {/* <Box position="sticky" zIndex={2}> */}
                    <SearchAdaccountAdspresso
                      onInitialisation={() => { }}
                      accountOptions={AdAccountData?.data}
                      onAccountChange={handleAdaccountChange}
                      onSearchInputChange={handleSearchChange}
                      onSearchInputTouched={setHasSearched}
                      AdsAccountIdFromParams={''}
                      isAdAccountLoading={isAdAccountLoading}
                      isAdAccountFetching={isAdAccountFetching}
                      pageMeta={AdAccountData?.meta}
                      onLoadMore={handleLoadMore}
                      selectedAccounts={selectedAdAccounts}
                      onRemoveAccount={handleRemoveAdAccount}
                    />
                    {/* </Box> */}

                    {/* Scrollable List of Selected Accounts and View More */}
                    <Box overflowY="auto" maxHeight="200px" mt={2}>
                      <Stack spacing={1} gap={'4px'}>
                        {selectedAdAccounts.map((account) => (
                          <Flex key={account.adsAccountId} align="center" direction="row" gap={1}>
                            {/* Account name and account ID with currency on separate lines */}
                            <Flex direction="column" align="flex-start" flex={0.9} pl={1}>
                              <Text fontSize="12px" fontWeight={"bold"}>
                                {account?.adsAccountName?.length > 38
                                  ? `${account.adsAccountName.substring(0, 38)}...`
                                  : account?.adsAccountName}
                              </Text>
                              <Flex gap={"5px"}>
                                <Text fontSize="10px" color="gray.800">
                                  {account?.adsAccountId}
                                </Text>
                                <Text fontSize="10px" color="gray.800" fontWeight={"bold"}>
                                  ({account?.currency})
                                </Text>
                              </Flex>
                            </Flex>

                            {/* Remove button */}
                            <Flex justify="flex-end" flex={0.1}>
                              <Tooltip label="Remove" aria-label="Remove account tooltip" fontSize={'12px'}>
                                <IconButton
                                  icon={<CloseIcon />}
                                  size="sm"
                                  variant="ghost"
                                  colorScheme="red"
                                  onClick={() => handleRemoveAdAccount(account.adsAccountId)}
                                  aria-label="Remove account"
                                />
                              </Tooltip>
                            </Flex>
                          </Flex>
                        ))}
                      </Stack>
                    </Box>
                  </Flex>

                  {/* Vertical Divider */}
                  <Box borderLeft="1px solid #ccc" height="250px" />

                  {/* Form Section (Right Side) */}
                  <Flex direction="column" flex={0.5} width={'100%'} minH={"310px"} maxHeight="290px" p={1}>
                    <AdspressoCampaignForm
                      values={campaignFormik.values}
                      handleChange={campaignFormik.handleChange}
                      handleBlur={campaignFormik.handleBlur}
                      errors={campaignFormik.errors}
                      touched={campaignFormik.touched}
                      dataCampaign={dataCampaign}
                      isLoadingCampaign={isLoadingCampaign}
                      errorCampaign={errorCampaign}
                      isSubmitting={campaignFormik.isSubmitting || isCreating}
                      handleSubmit={campaignFormik.handleSubmit}
                      createError={createError}
                      handleCloseModal={handleCloseModal}
                    />
                  </Flex>
                </Flex>
              </ModalBody>
              <ModalFooter justifyContent={'flex-start'} mt={-10}>
                <Flex direction={'column'}>
                  <Flex alignItems={'center'} gap={'5px'}>
                    <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                    <Text fontSize={'12px'}>
                      Choose multiple ad accounts with the{" "}
                      <Text as="span" fontWeight="bold"> same currency and</Text> {" "}
                      <Text as="span" fontWeight="bold">timezone</Text> to create bulk campaigns.
                    </Text>
                  </Flex>
                  {campaignFormik.errors.adsAccounts && (
                    <Text fontSize={"12px"} color="red.500" mt={"5px"}>
                      {campaignFormik.errors.adsAccounts}
                    </Text>
                  )}
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Button colorScheme="orange" onClick={handleOpenModal} size={'sm'}>
            <AddIcon boxSize={3} />
            &nbsp;Create
          </Button>

          <Box py={"20px"}>
            <AdspressoTable />
          </Box>
        </>
      )}
    </SidebarWithHeader>
  );

}
export default AdspressoForClient;