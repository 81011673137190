import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeCompanies:null,
};

export const companySlice = createSlice({
    initialState,
    name: 'companySlice',
    reducers: {
        setActiveCompanies: (state, { payload }) => {
            state.activeCompanies = payload
        }
    },
});

export default companySlice.reducer;

export const { setActiveCompanies } = companySlice.actions;