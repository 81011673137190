import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import moment from 'moment';
import { CalendarIcon, ChevronDownIcon } from '@chakra-ui/icons';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';

const START_DATE = new Date('2021-10-18'); 

const staticRanges = [
    ...defaultStaticRanges, 
    ...createStaticRanges([
        {
            label: 'Last 7 days',
            range: () => {
                const sevenDaysAgo = moment().subtract(7, 'days').toDate();
                return {
                    startDate: sevenDaysAgo,
                    endDate: new Date()
                };
            }
        },
        {
            label: 'Last 14 days',
            range: () => {
                const fourteenDaysAgo = moment().subtract(14, 'days').toDate();
                return {
                    startDate: fourteenDaysAgo,
                    endDate: new Date()
                };
            }
        },
        {
            label: 'Last 30 days',
            range: () => {
                const thirtyDaysAgo = moment().subtract(30, 'days').toDate();
                return {
                    startDate: thirtyDaysAgo,
                    endDate: new Date()
                };
            }
        },
        {
            label: 'Maximum',
            range: () => ({
                startDate: START_DATE,
                endDate: new Date(), 
            })
        },
    ])
];

const presetMap = {
    "this_week": "This Week",
    "last_week": "Last Week",
    "last_7_days": "Last 7 Days",
    "last_14_days": "Last 14 Days",
    "last_30_days": "Last 30 Days",
    "yesterday": "Yesterday",
    "today": "Today",
    "this_month": "This Month",
    "last_month": "Last Month",
    "maximum": "Maximum"
};

const DateRangeSelector = ({ onDateRangeChange, initialStartDate, initialEndDate, initailDatePerest }) => {

    const inputBackground = useColorModeValue('white', 'inherit');
    const hoverColor = useColorModeValue("gray.50", "gray.800");
    const cancelButtonColor = useColorModeValue("gray", "gray.800");
    const updateButtonColor = useColorModeValue("orange", "gray");
    const { colorMode, toggleColorMode } = useColorMode();
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [datePreset, setDatePreset] = useState('');
    const [tempStartDate, setTempStartDate] = useState(moment().subtract(7, 'days').toDate());
    const [tempEndDate, setTempEndDate] = useState(new Date());

    useEffect(() => {
        if (initialStartDate && initialEndDate) {
            setStartDate(initialStartDate);
            setEndDate(initialEndDate);
            setDatePreset(presetMap[initailDatePerest] || '');
            setTempStartDate(initialStartDate);
            setTempEndDate(initialEndDate);
        }
    }, [initialStartDate, initialEndDate, initailDatePerest]);
    
    // Ref for the date picker container
    const datePickerRef = useRef(null);
    const buttonRef = useRef(null);

    // Close the picker when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside of the date range picker or the button
            if (datePickerRef.current && !datePickerRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowDateRangePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setTempStartDate(startDate);
        setTempEndDate(endDate);
    };

    const handleCancel = () => {
        setTempStartDate(startDate); // Reset to original start date
        setTempEndDate(endDate); // Reset to original end date
        setShowDateRangePicker(false); // Close the picker
    };

    const handleUpdate = () => {
        const updatedStartDate = tempStartDate;
        const updatedEndDate = tempEndDate;

        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);

        const start = moment(updatedStartDate).startOf('day');
        const end = moment(updatedEndDate).startOf('day');

        // Check for "Today"
        if (start.isSame(moment().startOf('day'), 'day') && end.isSame(moment().endOf('day'), 'day')) {
            setDatePreset("Today");
            onDateRangeChange(updatedStartDate, updatedEndDate, "today");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "Yesterday"
        if (start.isSame(moment().subtract(1, 'days').startOf('day'), 'day') &&
            end.isSame(moment().subtract(1, 'days').endOf('day'), 'day')) {
            setDatePreset("Yesterday");
            onDateRangeChange(updatedStartDate, updatedEndDate, "yesterday");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "This Week"
        const startOfWeek = moment().startOf('week').startOf('day'); // Start of the current week (Sunday)
        const endOfWeek = moment().endOf('week').endOf('day'); // End of the current week (Saturday)

        if (start.isSameOrAfter(startOfWeek) && end.isSameOrBefore(endOfWeek)) {
            setDatePreset("This Week");
            onDateRangeChange(updatedStartDate, updatedEndDate, "this_week");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "Last Week"
        const lastWeekStart = moment().subtract(1, 'week').startOf('week').startOf('day');
        const lastWeekEnd = moment().subtract(1, 'week').endOf('week').endOf('day');
        if (start.isSame(lastWeekStart, 'day') && end.isSame(lastWeekEnd, 'day')) {
            setDatePreset("Last Week");
            onDateRangeChange(updatedStartDate, updatedEndDate, "last_week");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "Last Month"
        const startOfLastMonth = moment().subtract(1, 'month').startOf('month').startOf('day');
        const endOfLastMonth = moment().subtract(1, 'month').endOf('month').endOf('day');
        if (start.isSame(startOfLastMonth, 'day') && end.isSame(endOfLastMonth, 'day')) {
            setDatePreset("Last Month");
            onDateRangeChange(updatedStartDate, updatedEndDate, "last_month");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "This Month"
        const startOfMonth = moment(start).startOf('month').startOf('day');
        const endOfMonth = moment(start).endOf('month').endOf('day');
        if (start.isSame(startOfMonth, 'day') && end.isSame(endOfMonth, 'day')) {
            setDatePreset("This Month");
            onDateRangeChange(updatedStartDate, updatedEndDate, "this_month");
            setShowDateRangePicker(false);
            return;
        }

        // Check for "Last 7 Days"
        if (start.isSame(moment().subtract(7, 'days').startOf('day'), 'day') && 
            end.isSame(moment().endOf('day'), 'day')) {
            setDatePreset("Last 7 Days");
            onDateRangeChange(updatedStartDate, updatedEndDate, "last_7_days");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "Last 14 Days"
        if (start.isSame(moment().subtract(14, 'days').startOf('day'), 'day') &&
            end.isSame(moment().endOf('day'), 'day')) {
            setDatePreset("Last 14 Days");
            onDateRangeChange(updatedStartDate, updatedEndDate, "last_14_days");
            setShowDateRangePicker(false); 
            return;
        }

        // Check for "Last 30 Days"
        if (start.isSame(moment().subtract(30, 'days').startOf('day'), 'day') &&
            end.isSame(moment().endOf('day'), 'day')) {
            setDatePreset("Last 30 Days");
            onDateRangeChange(updatedStartDate, updatedEndDate, "last_30_days");
            setShowDateRangePicker(false);
            return;
        }

        // Check for "Maximum" range 
        if (start.isSame(moment('2021-10-18').startOf('day'), 'day') &&
            end.isSame(moment().endOf('day'), 'day')) {
            setDatePreset("Maximum");
            onDateRangeChange(updatedStartDate, updatedEndDate, "maximum");
            setShowDateRangePicker(false); 
            return;
        }

        setDatePreset(""); 
        onDateRangeChange(updatedStartDate, updatedEndDate, "");
        setShowDateRangePicker(false); 
    };
    
    const formatDateRange = (startDate, endDate) => {
        // If preset is 'Today' or 'Yesterday', only show startDate
        return `${moment(startDate).format('YYYY-MM-DD')}${(datePreset === "Today" || datePreset === "Yesterday") ? '' : ` - ${moment(endDate).format('YYYY-MM-DD')}`}`;
    };

    const buttonText = datePreset 
        ? `${datePreset}: ${formatDateRange(startDate, endDate)}`
        : formatDateRange(startDate, endDate);

    return (
        <Box>
            <Button
                ref={buttonRef}
                size="sm"
                bg={inputBackground}
                fontWeight={'normal'}
                borderRadius={'5px'}
                border={'1px'}
                borderColor="gray.200" 
                _hover={{
                    borderColor: 'gray.500',  
                    bg: hoverColor          
                }}
                leftIcon={<CalendarIcon />}
                onClick={() => setShowDateRangePicker(prevState => !prevState)}  
                rightIcon={<ChevronDownIcon boxSize={5}/>}
            >
                {buttonText || "Select Date Range"}
            </Button>
            {showDateRangePicker && (
                <Box
                    ref={datePickerRef}
                    position="absolute"
                    zIndex={99999}
                    mt={4}
                    right={5}
                    bg={'#fff'}
                >
                    <Box
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)" 
                        border="1px solid #ccc"  
                        borderRadius="2px" 
                        className={colorMode == 'dark' ? 'dark-mode' : 'light-mode'}
                    >
                        <DateRangePicker
                            ranges={[{
                                startDate: tempStartDate,
                                endDate: tempEndDate,
                                key: 'selection',
                            }]}
                            onChange={handleDateSelect}
                            showMonthAndYearPickers={true}
                            months={2}
                            direction="horizontal"
                            staticRanges={staticRanges}
                            rangeColors={["#EA7869"]} 
                            inputRanges={[]}
                            style={{
                                width: '250px',  
                                height: 'auto',  
                            }}
                        />
                        <Box display="flex" justifyContent="right" p={2} gap={2} mb={2} mt={-4}>
                            <Button onClick={handleCancel} colorScheme={cancelButtonColor} bg={'gray.100'} size="sm">Cancel</Button>
                            <Button onClick={handleUpdate} colorScheme={updateButtonColor}  bg={'orange.500'} size="sm">Update</Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default DateRangeSelector;
