import React, { useState, useEffect } from 'react';
import { Box, Flex, Spinner, Tooltip, Icon, IconButton, Text } from '@chakra-ui/react';
import { MdFacebook, MdOutlinePhonelink } from 'react-icons/md';
import { IoSquareOutline } from "react-icons/io5";
import { AiOutlineInstagram } from 'react-icons/ai';
import { IoLogoFacebook } from 'react-icons/io';
import { IoPhonePortrait } from 'react-icons/io5';
import { TbRectangle, TbRectangleVertical } from "react-icons/tb";
import { RiMessengerLine } from "react-icons/ri";
import { useGetAdPreviewsQuery } from 'store/campaigns/adsApi';
import placementMapping from '../../utils/placementMapping.json';
import adFormatMapping from '../../utils/adFormatMapping.json';

const iconMapping = {
    "AiOutlineInstagram": <AiOutlineInstagram />,
    "MdFacebook": <MdFacebook />,
    "RiMessengerLine": <RiMessengerLine />,
    "IoLogoFacebook": <IoLogoFacebook />,
    "IoPhonePortrait": <IoPhonePortrait />
};

const AdFormatDisplay = ({ ad }) => {
    const adFormat = adFormatMapping[ad.format] || { name: 'No Format', icon: null };
    
    return (
      <Flex alignItems="center" gap={2}>
        {/* Display the icon */}
        <Box>
          {iconMapping[adFormat.icon] || null}
        </Box>
  
        {/* Display the ad format name */}
        <Text py={4} fontSize={'12px'}>
          {adFormat.name || 'No Format'}
        </Text>
      </Flex>
    );
  };


// Mapping function to get the selected placements based on platform type
const getSelectedPlacements = (platformType) => {
    switch (platformType) {
        case 'all':
            return [
                "INSTAGRAM_REELS",
                "INSTAGRAM_STORY",
                "INSTAGRAM_EXPLORE_CONTEXTUAL",
                "INSTAGRAM_PROFILE_REELS",
                "INSTAGRAM_PROFILE_FEED",
                "MOBILE_FEED_BASIC",
                "FACEBOOK_REELS_MOBILE",
                // "INSTAGRAM_REELS_OVERLAY",
                "FACEBOOK_PROFILE_FEED_MOBILE",
                "SUGGESTED_VIDEO_MOBILE",
                "INSTREAM_VIDEO_MOBILE",
                "MARKETPLACE_MOBILE",
                "FACEBOOK_STORY_MOBILE",
                "MESSENGER_MOBILE_INBOX_MEDIA",
                // "MESSENGER_MOBILE_STORY_MEDIA"
            ];
        case 'tab':
            return [
                "INSTAGRAM_SEARCH_GRID",
                "INSTAGRAM_EXPLORE_CONTEXTUAL",
                "INSTAGRAM_PROFILE_REELS",
                "INSTAGRAM_EXPLORE_GRID_HOME",
                "INSTAGRAM_PROFILE_FEED",
                "MOBILE_FEED_BASIC",
                "SUGGESTED_VIDEO_MOBILE",
                "MARKETPLACE_MOBILE",
                "MESSENGER_MOBILE_INBOX_MEDIA"
            ];
        case 'mobile':
            return [
                "INSTAGRAM_REELS",
                "INSTAGRAM_STORY",
                "FACEBOOK_REELS_MOBILE",
                "FACEBOOK_PROFILE_FEED_MOBILE",
                "INSTREAM_VIDEO_MOBILE",
                "FACEBOOK_STORY_MOBILE",
                "MOBILE_INTERSTITIAL",
                "MESSENGER_MOBILE_STORY_MEDIA"
            ];
        case 'desktop':
            return [
                "RIGHT_COLUMN_STANDARD",
                "MARKETPLACE_SEARCH_ADS_MOBILE"
            ];
        default:
            return [];
    }
};

const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber.startsWith('tel:+')) {
        return `tel:+${phoneNumber}`;
    }
    return phoneNumber;
};

function AdPreviewCaro({adCreative, file, adsAccountData }) {
    const { adset: placements } = adCreative;
    const [selectedPlacements, setSelectedPlacements] = useState([]);
    const [creative, setCreative] = useState(null);
    const [selectedPlatform, setSelectedPlatform] = useState('all');    

    const updatedCreative = encodeURIComponent(JSON.stringify(creative));

    const shouldFetchAdPreview = updatedCreative &&
        (creative?.object_story_spec?.link_data?.child_attachments?.length > 0) &&
        (creative?.object_story_spec?.link_data?.child_attachments?.every(attachment => attachment?.link)) &&
        (creative?.object_story_spec?.link_data?.link || creative?.object_story_spec?.link_data?.call_to_action?.value?.link) && 
        adsAccountData && selectedPlacements.length > 0;

    const { data: AdPreview, isLoading, error } = useGetAdPreviewsQuery(
        {
            adsAccountId: adsAccountData?.account_id,
            creative: updatedCreative,
            ad_format: selectedPlacements, 
        },
        { skip: !shouldFetchAdPreview }
    );


    // Constructing the creative object from the file data
    useEffect(() => {
        if (file) {
            
            const child_attachments = file?.creative?.object_story_spec?.link_data?.child_attachments?.map((creative) => ({
                ...(file?.creative?.object_story_spec?.link_data?.call_to_action?.type !== 'CALL_NOW' ? { link: creative.link } : { link : `https://facebook.com/${file?.creative?.object_story_spec?.page_id}/`}),
                    call_to_action: file?.creative?.object_story_spec?.link_data?.call_to_action?.type === 'CALL_NOW'
                        ? {
                            type: file?.creative?.object_story_spec?.link_data?.call_to_action?.type,
                            value: {
                                link: formatPhoneNumber(creative?.call_to_action?.value?.link)
                            }
                        }
                        : {
                            type: file?.creative?.object_story_spec?.link_data?.call_to_action?.type
                },
                picture: creative.picture || '',
                name: creative.name || '',
                description: creative.description || '',
                ...(creative?.video_id && {
                    video_id: creative?.video_id,
                }),
            }));

            const creative = {
                name: file?.creative?.name,
                hash: file?.creative?.hash,
                object_story_spec: {
                    link_data: {
                        message: file?.creative?.object_story_spec?.link_data?.message,
                        description: file?.creative?.object_story_spec?.link_data?.description,
                        ...(file?.creative?.object_story_spec?.link_data?.call_to_action?.type !== 'CALL_NOW' ? { link: file?.creative?.object_story_spec?.link_data?.link } : { link : `https://facebook.com/${file?.creative?.object_story_spec?.page_id}/`}),
                        call_to_action: file?.creative?.object_story_spec?.link_data?.call_to_action?.type === 'CALL_NOW'
                            ? {
                                type: file?.creative?.object_story_spec?.link_data?.call_to_action?.type,
                                value: {
                                    link: formatPhoneNumber(file?.creative?.object_story_spec?.link_data?.call_to_action?.value?.link,)
                                }
                            }
                            : {
                                type: file?.creative?.object_story_spec?.link_data?.call_to_action?.type
                        },
                        child_attachments,
                    },
                    instagram_actor_id: file?.creative?.object_story_spec?.instagram_actor_id ? file?.creative?.object_story_spec?.instagram_actor_id : file?.page_backed_insta_id,
                    page_id: file?.creative?.object_story_spec?.page_id,
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: 'OPT_OUT',
                        },
                    },
                },
            };
            setCreative(creative);
        }
    }, [file]);

    // Helper function to unescape the string
    const unescapeHtml = (str) => {
        const string = str.replace(/\\"/g, '"').replace(/\\'/g, "'").replace(/\\\\/g, '\\');
        return string;
    };

    // Helper function to map raw positions to placement names
    const mapPositionsToPlacements = (platform, positions) => {
        const platformMapping = placementMapping[platform];
        return positions.map(position => platformMapping[position]);
    };

    // Handle icon button clicks to set the selected placements
    const handleIconClick = (platformType) => {
        setSelectedPlatform(platformType);

        // Get the raw positions from the parent
        const rawPositions = {
            facebook: placements?.targeting?.facebook_positions || [],
            instagram: placements?.targeting?.instagram_positions || [],
            audienceNetwork: placements?.targeting?.audience_network_positions || [],
            messenger: placements?.targeting?.messenger_positions || [],
        };

        // Map the raw positions to their mapped values
        const mappedPlacements = [
            ...mapPositionsToPlacements('instagram', rawPositions.instagram),
            ...mapPositionsToPlacements('facebook', rawPositions.facebook),
            ...mapPositionsToPlacements('audienceNetwork', rawPositions.audienceNetwork),
            ...mapPositionsToPlacements('messenger', rawPositions.messenger),
        ];

        // Get the selected placements based on platformType
        const newPlacements = getSelectedPlacements(platformType);

        // Filter the mapped placements to only include the ones that are in both the parent's placements and the selected type
        const filteredPlacements = mappedPlacements.filter(placement => newPlacements.includes(placement));

        setSelectedPlacements(filteredPlacements); // Update the selected placements based on intersection
    };

    useEffect(() => {
        // Set initial placements based on the 'all' selection
        if (selectedPlatform === 'all') {
            handleIconClick('all');
        }
    }, [selectedPlatform]);

    return (
        <Box>
            {/* Preview icons (All, Tablet, Mobile, Desktop) */}
            <Flex justifyContent="center" gap={6} my={4} wrap="wrap">
                <Tooltip label="All" fontSize="sm" placement="top">
                    <IconButton
                        onClick={() => handleIconClick('all')}
                        icon={<Icon as={MdOutlinePhonelink} />}
                        aria-label="All"
                        boxSize={6}
                        color={selectedPlatform === 'all' ? 'blue.500' : 'gray.500'}
                        _hover={selectedPlatform === 'all' ? { bg: '#C5D8EB ' } : { bg: 'gray.50' }}
                        bg={selectedPlatform === 'all' ? '#E0EDF7' : 'none'}
                    />
                </Tooltip>
                <Tooltip label="Feeds, In-stream ads for videos and reels, Search results" fontSize="sm" placement="top">
                    <IconButton
                        onClick={() => handleIconClick('tab')}
                        icon={<Icon as={IoSquareOutline} />}
                        aria-label="Tablet"
                        boxSize={6}
                        color={selectedPlatform === 'tab' ? 'blue.500' : 'gray.500'}
                        _hover={selectedPlatform === 'tab' ? { bg: '#C5D8EB ' } : { bg: 'gray.50' }}
                        bg={selectedPlatform === 'tab' ? '#E0EDF7' : 'none'}
                    />
                </Tooltip>
                <Tooltip label="Stories and Reels, Apps and sites" fontSize="sm" placement="top">
                    <IconButton
                        onClick={() => handleIconClick('mobile')}
                        icon={<Icon as={TbRectangleVertical} />}
                        aria-label="Mobile"
                        boxSize={6}
                        color={selectedPlatform === 'mobile' ? 'blue.500' : 'gray.500'}
                        _hover={selectedPlatform === 'mobile' ? { bg: '#C5D8EB ' } : { bg: 'gray.50' }}
                        bg={selectedPlatform === 'mobile' ? '#E0EDF7' : 'none'}
                    />
                </Tooltip>
                <Tooltip label="A Right column, Search results" fontSize="sm" placement="top">
                    <IconButton
                        onClick={() => handleIconClick('desktop')}
                        icon={<Icon as={TbRectangle} />}
                        aria-label="Desktop"
                        boxSize={6}
                        color={selectedPlatform === 'desktop' ? 'blue.500' : 'gray.500'}
                        _hover={selectedPlatform === 'desktop' ? { bg: '#C5D8EB ' } : { bg: 'gray.50' }}
                        bg={selectedPlatform === 'desktop' ? '#E0EDF7' : 'none'}
                    />
                </Tooltip>
            </Flex>

            {/* Ad Preview */}
            {isLoading ? (
                <Flex justifyContent={'center'} alignItems={'center'} height={'60vh'}>
                    <Spinner />
                </Flex>
            ) : error ? (
                <Box>Error loading ad preview!</Box>
            ) : AdPreview && AdPreview.length > 0 ? (
                <Box display="flex" flexWrap="wrap" maxH={'900px'} h={'900px'} overflowY={'auto'}>
                    {AdPreview.map((ad, index) => (
                        <Box key={index} width="50%">
                            <Box>
                                 <Flex alignItems="center" gap={2}>
                                    <AdFormatDisplay ad={ad}/>
                                </Flex>
                                {ad.body ? (
                                    <Box
                                        dangerouslySetInnerHTML={{ __html: unescapeHtml(ad.body) }}
                                        width="300px"
                                        height="560px"
                                        bg="#EFEFEF"
                                        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        borderRadius="8px"
                                        className='ad-preview-wrapper'
                                    />
                                ) : (
                                    <Box>No preview available for this format.</Box>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box h={'500px'} display="flex" alignItems={'center'} justifyContent={'center'}>
                    Add media to see ad examples.
                </Box>
            )}
        </Box>
    );
}

export default AdPreviewCaro;
