import {
    Box, Flex,
    Select,
    SkeletonText,
    Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetPagesQuery } from 'store/BmAccounts/bmAccountApi';

function FaceBookPageAdspressoSelector({ adsAccount, onSelectedPage, selectedPageId }) {

    const { adsAccountId, adsAccountName } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState(selectedPageId || '');
    const userInfo = useSelector((state) => state.auth?.userInfo);
    // const { data: facebookPages, isLoading } = useGetFacebookPagesQuery(account_id);

    const { data: facebookPages = [], isLoading:isFbPageLoading } = useGetPagesQuery(
        { search: '',  },
    );

    useEffect(() => {
        if(selectedPageId){
            setselectedPage(selectedPageId || '');
        }
    }, [selectedPageId]);

    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, adsAccountId);
    };
    

    return (
        <Box>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{adsAccountName}</Text>
                    <Text fontSize={'12px'}>{adsAccountId}</Text>
                </Box>

                <Box flex={1}>
                    {isFbPageLoading ? (
                         <Flex justifyContent={'center'} alignItems={'center'}>
                            <SkeletonText width="80%" noOfLines={2}  skeletonHeight={2}/>
                        </Flex>
                    ) : (
                        <Select
                            placeholder="Facebook page"
                            value={selectedPage}
                            onChange={handleEventPageChange}
                            borderRadius={'md'}
                            size={'sm'}
                            isDisabled={userInfo?.role !== 'client'}
                        >
                            {facebookPages?.map((page) => (
                                <option key={page?.id} value={page?.id}>
                                    {page?.name ? page?.name : 'Unnamed Page'}
                                </option>
                            ))}
                        </Select>
                    )}
                </Box>
            </Flex>
        </Box>
    )
}

export default FaceBookPageAdspressoSelector