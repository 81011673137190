import { Spinner} from '@chakra-ui/react';
import { useGetAdsetFormQuery } from 'store/campaigns/adsetFormApi';
import { useGetcampaignByIdQuery } from 'store/campaigns/campaignsApi';
import LeadUpdateForm from './LeadUpdateForm';
import SalesUpdateForm from './SalesUpdateForm';

const AdSetUpdateForm = ({ adsetData, onClose, onSaveSuccess }) => {

    const { campaign_id : campaignId, } = adsetData || {};
    
    const { data: campaignData, error: campaignError, isLoading: isLoadingCampaign } = useGetcampaignByIdQuery({campaignId});

    const campaignType = campaignData?.objective;

    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });    

    const renderForm = () => {
        if (campaignType === 'OUTCOME_SALES') {
            if (isLoadingSales) {
                return <Spinner size={'sm'} />;
            }
            if (dataSales.data && campaignData) {
                return (
                    <SalesUpdateForm
                        data={dataSales.data}
                        campaignData={campaignData}
                        selectedAdset={adsetData}
                        onEditModalClose={onClose}
                        onClose={onClose}
                        context="adsetEdit"
                        onSaveSuccess={onSaveSuccess}
                    />
                );
            }
        } else if (campaignType === 'OUTCOME_LEADS') {
            if (isLoadingLeads) {
                return <Spinner size={'sm'} />;
            }
            if (dataLeads.data && campaignData) {
                return (
                    <LeadUpdateForm
                        data={dataLeads.data}
                        campaignData={campaignData}
                        selectedAdset={adsetData}
                        onEditModalClose={onClose}
                        onClose={onClose}
                        context="adsetEdit"
                        onSaveSuccess={onSaveSuccess}
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
         {renderForm()}
        </>    
    );
};

export default AdSetUpdateForm;
