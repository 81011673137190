import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Checkbox, Collapse, Divider, Flex, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Select, Stack, Text, Tooltip, useColorModeValue, useToast, Link } from '@chakra-ui/react';
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon, TimeIcon } from '@chakra-ui/icons';
import PixelConversionSelector from 'components/adspresso/PixelConversionSelector';
import PlacementsSelector from 'components/adspresso/PlacmentsSelector';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useCreateAdspressoAdsetsMutation, useUpdateAdspressoAdsetMutation } from 'store/adspresso/adspressoAdsetapi';
import { leadFormSchema, leadFormSchematrackEdit } from 'utils/schema';
import AgeGenderSelector from 'components/adspresso/AgeGenderSelector';
import DetailedTargetingSelector from 'components/adspresso/DetailedTargetingSelector';
import GeoLocationsSelector from 'components/adspresso/GeoLocationsSelector';
import moment from 'moment';
import { useSelector } from "react-redux";
import currencySymbolMap from 'currency-symbol-map';
import { flattenTargeting, removeEmptyValues, formatUnixTimestamp } from 'utils/functions';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import LocaleSelector from 'components/adspresso/LocaleSelector';
import { useUpdatePublishAdsetMutation } from 'store/publishTracking/trackingApi';
import BulkLocationSelector from 'components/adspresso/BulkLocationSelector';
import AdScheduler from 'components/adspresso/AdScheduler';
import Audience from './Audience';

function pluralizeType(type) {
    const pluralMapping = {
        country: 'countries',
        city: 'cities',
        region: 'regions',
        zip: 'zips',
        neighborhood: 'neighborhoods',
        geo_market: 'geo_markets',
        electoral_district: 'electoral_districts',
        subcity: 'subcities',
        place: 'places',
        address: 'addresses'
    };
    return pluralMapping[type] || `${type}s`; // Default pluralize rule by appending 's'
}


const transformGeolocations = (locations) => {
    const geoLocations = {};
    const excludedGeoLocations = {};

    locations.forEach(location => {
        const pluralizedType = pluralizeType(location.type);

        // if (!geoLocations[pluralizedType]) {
        //     geoLocations[pluralizedType] = [];
        // }
        // geoLocations[pluralizedType].push(location);

        // Determine whether to add to inclusions or exclusions
        //exclude neighborhood type

        if (location.inclusionType === 'include') {
            if (!geoLocations[pluralizedType]) {
                geoLocations[pluralizedType] = [];
            }
            geoLocations[pluralizedType].push(location);
        } else if (location.inclusionType === 'exclude') {
            if (!excludedGeoLocations[pluralizedType]) {
                excludedGeoLocations[pluralizedType] = [];
            }
            excludedGeoLocations[pluralizedType].push(location);
        }
    });

    let nonEmptyGeoLocations = Object.keys(geoLocations)?.length ? { geo_locations: geoLocations } : null;
    let nonEmptyExcludedGeoLocations = Object.keys(excludedGeoLocations)?.length ? { excluded_geo_locations: excludedGeoLocations } : null;

    return {
        ...nonEmptyGeoLocations,
        ...nonEmptyExcludedGeoLocations
    };
};

const transformDetailedTargets = (detailedTargets) => {
    const result_object = {};

    detailedTargets?.forEach(item => {
        const { type } = item;
        if (!result_object[type]) {
            result_object[type] = [];
        }
        result_object[type].push(item);
    });

    return { ...result_object };
};

function LeadForm({ data, adsetsListData, campaignData, selectedAdset, onEditModalClose, onClose, isEditing, context, onSaveSuccess }) {

    const inputBackground = useColorModeValue('white', 'inherit');
    const backgroundColor = useColorModeValue('gray.50', 'gray.800');

    const toast = useToast();
    const { name: campaignName, adsAccounts, objective, buying_type } = campaignData?.form || campaignData || {};

    let currentAdsetData;
    if (context == "trackEdit") {
        currentAdsetData = adsetsListData?.form;
    } else {
        currentAdsetData = adsetsListData?.data?.filter((adset) => adset._id == selectedAdset)?.[0];
    }

    let firstAdAccountId;
    if (context == "trackEdit") {
        firstAdAccountId = campaignData?.adsAccount?.adsAccountId;
    } else {
        firstAdAccountId = adsAccounts[0]?.adsAccountId;
    }

    let firstBMID;
    if (context == "trackEdit") {
        firstBMID = campaignData?.adsAccount?.businessId;
    } else {
        firstBMID = adsAccounts[0]?.businessId;
    }

    // console.log({ adsAccounts });


    const [startDate, setStartDate] = useState(currentAdsetData?.start_time ? new Date(currentAdsetData?.start_time * 1000) : new Date());
    const [endDate, setEndDate] = useState(() => {
        if (currentAdsetData?.end_time) {
            return new Date(currentAdsetData.end_time * 1000);
        }
        const defaultEndDate = new Date(startDate);
        defaultEndDate.setDate(defaultEndDate.getDate() + 30);
        return defaultEndDate;
    });

    const [errorDateMessage, setErrorDateMessage] = useState('');

    const [showEndDate, setShowEndDate] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const [placements, setPlacements] = useState({});
    const [geoLocations, setGeoLocations] = useState({});
    const [zipCodes, setzipCodes] = useState([]);
    const [ageGender, setAgeGender] = useState({
        age_min: currentAdsetData?.targeting?.age_min ? currentAdsetData?.targeting?.age_min : 18,
        age_max: currentAdsetData?.targeting?.age_max ? currentAdsetData?.targeting?.age_min : 65,
        genders: currentAdsetData?.targeting?.gender ? currentAdsetData?.targeting?.gender : []
    });
    const genderMap = { all: [0], men: [1], women: [2] };
    const [interests, setInterests] = useState([]);
    const [initialInterests, setInitialInterests] = useState(flattenTargeting(currentAdsetData?.targeting));
    const [tempBudgetValue, setTempBudgetValue] = useState('');
    const [pixelError, setpixelError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [facebookPageError, setfacebookPageError] = useState(false);
    const [isBulkLocationSelectorVisible, setisBulkLocationSelectorVisible] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);
    const [adSchedule, setAdSchedule] = useState([]);
    const [timezone, setTimezone] = useState('USER');
    const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
    const currencyCode = activeAccountsData?.data[0]?.currency;
    const currencySymbol = currencySymbolMap(currencyCode)
    const [createAdsetsData, { isLoading: isAdsetsCreating, error: createError }] = useCreateAdspressoAdsetsMutation();
    const [updateAdspressoAdset, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdsetMutation({ adsetId: selectedAdset });
    const [updatePublishAdset] = useUpdatePublishAdsetMutation({});


    // console.log({ currentAdsetData });

    const adSetFormik = useFormik({
        initialValues: {
            name: currentAdsetData?.name || '',
            destination_type: currentAdsetData?.destination_type || 'WEBSITE',
            optimization_goal: currentAdsetData?.optimization_goal || 'OFFSITE_CONVERSIONS',
            promoted_object: context === 'trackEdit' ? (!Array.isArray(currentAdsetData.promoted_object)
                ? currentAdsetData.promoted_object
                : {})
                : (currentAdsetData?.promoted_object || []),
            billing_event: currentAdsetData?.billing_event || 'IMPRESSIONS',
            bid_strategy: currentAdsetData?.bid_strategy || 'LOWEST_COST_WITHOUT_CAP',
            bid_amount: Number(currentAdsetData?.bid_amount) / 100 || '',
            budget: currentAdsetData?.daily_budget ? 'daily_budget' : 'lifetime_budget',
            daily_budget: Number(currentAdsetData?.daily_budget) / 100 || 800,
            lifetime_budget: Number(currentAdsetData?.lifetime_budget) / 100 || 14000,
            geo_locations: currentAdsetData?.targeting?.geo_locations ? Object.values(currentAdsetData?.targeting?.geo_locations).flat() : [] || [],
            locales: currentAdsetData?.targeting?.locales || [],
            cpa: Number(currentAdsetData?.cpa) || '',
            context: 'trackEdit',
        },
        enableReinitialize: true,
        validationSchema: context == "trackEdit" ? leadFormSchematrackEdit : leadFormSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const { budget, name, bid_strategy, destination_type, optimization_goal, billing_event, daily_budget, bid_amount, lifetime_budget, promoted_object, geo_locations, locales, cpa } = values;

            if (values.optimization_goal == 'OFFSITE_CONVERSIONS' && values.promoted_object?.length != adsAccounts?.length) {
                setpixelError(true);
                setSubmitting(false)
                return;
            }

            if (values.optimization_goal == "QUALITY_CALL" && values.promoted_object?.length != adsAccounts?.length) {
                setfacebookPageError(true);
                setSubmitting(false)
                return;
            }

            let transformedPromotedObject;

            if (context !== 'trackEdit') {
                if (optimization_goal === 'OFFSITE_CONVERSIONS') {
                    transformedPromotedObject = promoted_object.map(obj => ({
                        adsAccountId: obj.adsAccountId,
                        pixel_id: obj.pixel_id,
                        custom_event_type: obj.custom_event_type,
                    }));
                } else if (optimization_goal === 'QUALITY_CALL') {
                    transformedPromotedObject = promoted_object.map(obj => ({
                        adsAccountId: obj.adsAccountId,
                        page_id: obj.page_id,
                    }));
                }
            } else {
                transformedPromotedObject = promoted_object;
            }

            const bidDetails = bid_strategy === 'LOWEST_COST_WITH_BID_CAP'
                ? { bid_amount: bid_amount * 100 }
                : {}


            const selectedAdsetData = adsetsListData?.data?.find(adset => adset._id === selectedAdset);
            const targetingId = isEditing && selectedAdsetData ? selectedAdsetData.targeting?._id : undefined;

            //let transformParam = isEditing ? Object.values(geo_locations).flat() : geo_locations;

            const geoLocationList = geo_locations?.length ? transformGeolocations(geo_locations) : null;
            const genderList = ageGender?.genders?.length > 0 ? { genders: ageGender.genders } : null;
            const detailedTargetList = interests?.length > 0 ? transformDetailedTargets(interests) : (initialInterests?.length > 0 ? transformDetailedTargets(initialInterests) : null);
            const filteredPlacements = Object.fromEntries(
                Object.entries(placements).filter(([_, value]) => value.length > 0)
            );

            const localeList = locales?.length > 0 ? { locales: locales } : null;

            const payload = {
                name,
                cpa: Number(cpa),
                destination_type, optimization_goal, billing_event,
                bid_strategy,
                promoted_object: transformedPromotedObject,
                campaign: campaignData._id,
                targeting: {
                    ...(targetingId ? { _id: targetingId } : {}),
                    ...filteredPlacements,
                    age_min: ageGender.age_min,
                    age_max: ageGender.age_max,
                    ...geoLocationList,
                    ...genderList,
                    ...detailedTargetList,
                    ...localeList
                },
                ...bidDetails,
                currency: currencyCode,
                ...(budget === 'daily_budget' ? { daily_budget: Number(daily_budget) * 100 } : {}),
                ...(budget === 'lifetime_budget' ? { lifetime_budget: Number(lifetime_budget) * 100 } : {}),
                start_time: startDate ? moment(startDate).unix() : 0,
                ...(budget === 'lifetime_budget' ? { end_time: endDate ? moment(endDate).unix() : 0 } : {}),
                ...(budget === 'daily_budget' && showEndDate ? { end_time: endDate ? moment(endDate).unix() : 0 } : {}),
                adset_schedule: adSchedule,
                ...(adSchedule && adSchedule.length > 0 ? { pacing_type: ["day_parting"] } : {}),
            };

            const filteredPayload = removeEmptyValues(payload);
            //  console.log({ filteredPayload });

            if (isEditing) {
                if (context === 'trackEdit') {

                    const updatePayload = {
                        payload: {
                            ...filteredPayload,
                            _id: currentAdsetData?._id,
                        },
                        trackingId: adsetsListData?._id,
                    };

                    updatePublishAdset(updatePayload)
                        .unwrap()
                        .then(response => {
                            toast({
                                position: "top-right",
                                title: "Ad set updated",
                                status: "success",
                                duration: 4000,
                                isClosable: true,
                            });
                            onEditModalClose();
                            resetForm();
                            onSaveSuccess();
                            onClose();
                        })
                        .catch(error => {
                            let errorMessage = "An error occurred while updating the adset.";
                            if (error && error.data && error.data.message) {
                                errorMessage = error.data.message.replace(/"/g, '');
                            } else if (error.message) {
                                errorMessage = error.message.replace(/"/g, '');
                            }
                            toast({
                                position: "top-right",
                                title: "Error updating Adset",
                                description: errorMessage,
                                status: "error",
                                duration: 4000,
                                isClosable: true,
                            });
                        })
                        .finally(() => {
                            setSubmitting(false);
                            setpixelError(false);
                        });
                } else {

                    const updatePayload = {
                        payload: filteredPayload,
                        adsetId: selectedAdset,
                    };

                    updateAdspressoAdset(updatePayload)
                        .unwrap()
                        .then(response => {
                            toast({
                                position: "top-right",
                                title: "Ad set updated",
                                status: "success",
                                duration: 4000,
                                isClosable: true,
                            });
                            // console.log('Adset updated successfully:', response);
                            onEditModalClose();
                            resetForm();
                            onSaveSuccess();
                            onClose();
                        })
                        .catch(error => {
                            let errorMessage = "An error occurred while updating the adset.";

                            if (error && error.data && error.data.message) {
                                errorMessage = error.data.message.replace(/"/g, '');
                            } else if (error.message) {
                                errorMessage = error.message.replace(/"/g, '');
                            }

                            toast({
                                position: "top-right",
                                title: "Error updating Adset",
                                description: errorMessage,
                                status: "error",
                                duration: 4000,
                                isClosable: true,
                            });
                        })
                        .finally(() => {
                            setSubmitting(false);
                            setpixelError(false);
                        });
                }
            } else {
                createAdsetsData({ payload: filteredPayload })
                    .unwrap()
                    .then(response => {
                        toast({
                            position: "top-right",
                            title: "New Ad set created",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                        // console.log('Adsets created successfully:', response);
                        resetForm();
                        onClose();
                    })
                    .catch(error => {
                        let errorMessage = "An error occurred while creating the adset.";

                        if (error && error.data && error.data.message) {
                            errorMessage = error.data.message.replace(/"/g, '');
                        } else if (error.message) {
                            errorMessage = error.message.replace(/"/g, '');
                        }

                        toast({
                            position: "top-right",
                            title: "Error creating Adset",
                            description: errorMessage,
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                    })

                    .finally(() => {
                        setSubmitting(false);
                        setpixelError(false);
                    });
            }
        },
    });

    // console.log(adSetFormik.values.geo_locations);

    useEffect(() => {
        if (adSetFormik.values.promoted_object?.length == adsAccounts?.length) {
            setpixelError(false);
        } else if (adSetFormik.isSubmitting && adSetFormik.errors.pixel_id) {
            setpixelError(true);
        }
    }, [adSetFormik.values.promoted_object, adSetFormik.isSubmitting])


    const {
        name,
        destination_type,
        optimization_goal,
        promoted_object,
        budget,
        targeting,
        bid_strategy
    } = data;

    const optimizationOptions = optimization_goal[adSetFormik.values.destination_type] || [];
    const promotedObjectFields = promoted_object?.[adSetFormik.values.optimization_goal] || {};

    useEffect(() => {
        const defaultDestinationType = adSetFormik.values.destination_type;
        const optimizationOptions = data.optimization_goal[defaultDestinationType] || [];
        const defaultOptimizationGoal = optimizationOptions.length ? optimizationOptions[0].value : '';
    }, [adSetFormik.values.destination_type]);


    useEffect(() => {
        // Update bid strategy based on optimization_goal
        adSetFormik.setFieldValue('bid_strategy', currentAdsetData?.bid_strategy || 'LOWEST_COST_WITHOUT_CAP');
    }, [currentAdsetData?.bid_strategy]);

    useEffect(() => {
        if (adSetFormik.values.optimization_goal !== currentAdsetData?.optimization_goal) {
            adSetFormik.setFieldValue('bid_amount', '');
            adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
        } else {
            adSetFormik.setFieldValue('bid_strategy', currentAdsetData?.bid_strategy);
        }
    }, [adSetFormik.values.optimization_goal])

    useEffect(() => {
        if (currentAdsetData?.optimization_goal) {
            adSetFormik.setFieldValue('optimization_goal', currentAdsetData?.optimization_goal || 'OFFSITE_CONVERSIONS');
        }
    }, [currentAdsetData?.optimization_goal]);

    useEffect(() => {
        if (adSetFormik.values.destination_type == "PHONE_CALL") {
            adSetFormik.setFieldValue('optimization_goal', 'QUALITY_CALL');
            adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
            // adSetFormik.setFieldValue('bid_amount', '');
            // adSetFormik.setFieldValue('roas_average_floor', '');
        }
        if (adSetFormik.values.destination_type === "WEBSITE") {
            // Update the optimization goal only if it's not already set
            if (!currentAdsetData?.optimization_goal) {
                adSetFormik.setFieldValue('optimization_goal', 'OFFSITE_CONVERSIONS');
            }
            else if (adSetFormik.values.optimization_goal === "QUALITY_CALL") {
                adSetFormik.setFieldValue('optimization_goal', 'OFFSITE_CONVERSIONS');
            }
        }
    }, [adSetFormik.values.destination_type]);

    useEffect(() => {
        if (adSetFormik.values.budget === 'lifetime_budget') {
            if (currentAdsetData?.end_time) {
                setEndDate(new Date(currentAdsetData?.end_time * 1000))
            } else {
                const newEndDate = new Date(startDate);
                newEndDate.setDate(newEndDate.getDate() + 30); // 30 days from startDate
                setEndDate(newEndDate);
            }
        }
    }, [adSetFormik.values.budget]);

    useEffect(() => {
        if (currentAdsetData?.daily_budget && currentAdsetData?.end_time) {
            setShowEndDate(true);
            setEndDate(new Date(currentAdsetData.end_time * 1000));
        } else {
            setShowEndDate(false);
        }
    }, [currentAdsetData]);

    useEffect(() => {
        if (endDate <= startDate) {
            setErrorDateMessage('End date must be greater than start date.');
        } else {
            setErrorDateMessage('');
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (currentAdsetData?.adset_schedule?.length > 0) {
            setIsScheduled(true);
        } else {
            setIsScheduled(false);
        }
    }, [currentAdsetData]);

    const handleBudgetChange = (e) => {
        const selectedBudgetType = e.target.value;
        setTempBudgetValue(selectedBudgetType);
        if (selectedBudgetType === 'daily_budget') {
            adSetFormik.setFieldValue('lifetime_budget', Number(adSetFormik.values.lifetime_budget));
            adSetFormik.setFieldValue('daily_budget', Number(adSetFormik.values.daily_budget));
        } else if (selectedBudgetType === 'lifetime_budget') {
            adSetFormik.setFieldValue('daily_budget', Number(adSetFormik.values.daily_budget));
            adSetFormik.setFieldValue('lifetime_budget', Number(adSetFormik.values.lifetime_budget));
        }
        adSetFormik.setFieldValue('budget', selectedBudgetType);
    };

    const handlePixelIdSelection = (id, adsAccountId) => {
        // const { promoted_object } = adSetFormik.values;

        // if (context === 'trackEdit') {
        //     const updatedPromotedObject = {
        //         ...promoted_object,
        //         pixel_id: id,
        //         custom_event_type: 'LEAD',
        //     };

        //     adSetFormik.setFieldValue('promoted_object', updatedPromotedObject);
        // } else {
        //     const updatedPromotedObjects = promoted_object.map(obj =>
        //         obj.adsAccountId === adsAccountId ? { ...obj, pixel_id: id, custom_event_type: 'LEAD' } : obj
        //     );

        //     if (!promoted_object.find(obj => obj.adsAccountId === adsAccountId)) {
        //         updatedPromotedObjects.push({ adsAccountId, pixel_id: id, custom_event_type: 'LEAD' });
        //     }
        // }
        const updatedPromotedObjects = adSetFormik.values.promoted_object.map(obj =>
            obj.adsAccountId === adsAccountId ? { ...obj, pixel_id: id } : obj
        );
        if (!adSetFormik.values.promoted_object.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedPromotedObjects.push({ adsAccountId, pixel_id: id });
        }


        adSetFormik.setFieldValue('promoted_object', updatedPromotedObjects);

    };

    const handleEventTypeSelection = (type, adsAccountId) => {

        const updatedPromotedObjects = adSetFormik.values.promoted_object.map(obj =>
            obj.adsAccountId === adsAccountId ? { ...obj, custom_event_type: type } : obj
        );
        if (!adSetFormik.values.promoted_object.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedPromotedObjects.push({ adsAccountId, custom_event_type: type });
        }

        adSetFormik.setFieldValue('promoted_object', updatedPromotedObjects);
    };

    const handleLocationsChange = (locations) => {
        setGeoLocations(locations)
        adSetFormik.setFieldValue('geo_locations', locations);
    };

    // console.log(adSetFormik.values.geo_locations);


    const handleAgeGenderChange = (data) => {

        setAgeGender(prevState => {
            const newState = {
                age_min: data.age_min,
                age_max: data.age_max,
                genders: genderMap[data.gender] || []
            };
            return newState;
        });
    };

    const handleInterestsChange = (interests) => {
        setInterests(interests);
        setInitialInterests(flattenTargeting(interests));
    };


    const handleFacebookPageSelection = (pageId, adsAccountId) => {
        const { promoted_object } = adSetFormik.values;

        if (context === 'trackEdit') {
            const updatedPromotedObject = {
                ...promoted_object,
                page_id: pageId,
            };

            adSetFormik.setFieldValue('promoted_object', updatedPromotedObject);
        } else {
            const updatedAccounts = promoted_object.map(obj =>
                obj.adsAccountId === adsAccountId ? { ...obj, page_id: pageId } : obj
            );

            if (!promoted_object.find(obj => obj.adsAccountId === adsAccountId)) {
                updatedAccounts.push({ adsAccountId, page_id: pageId });
            }

            adSetFormik.setFieldValue('promoted_object', updatedAccounts);
        }
    };

    const handleAdScheduleChange = (newSchedule) => {
        const adScheduleData = newSchedule.map(scheduleEntry => ({
            ...scheduleEntry,
            timezone_type: timezone
        }));
        setAdSchedule(adScheduleData);
    };

    const handleSave = () => {
        // Set all fields to touched to trigger validation
        Object.keys(adSetFormik.values).forEach(field => {
            adSetFormik.setFieldTouched(field, true);
        });

        setIsSaving(true);

        const errors = adSetFormik.errors;
        const errorKeys = Object.keys(errors);

        // Check for validation errors
        if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];

            // First, try to scroll to the element by name
            const errorElementByName = document.querySelector(`[name="${firstErrorKey}"]`);
            if (errorElementByName) {
                errorElementByName.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }

            // If not found, try to scroll to the element by error class
            const errorElementByClass = document.querySelector(`.error-class-${firstErrorKey}`);
            if (errorElementByClass) {
                errorElementByClass.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }

        if (
            (adSetFormik.values.budget === 'lifetime_budget' && (errorDateMessage || endDate <= startDate)) ||
            (adSetFormik.values.budget === 'daily_budget' && showEndDate && (errorDateMessage || endDate <= startDate))
        ) {
            return;
        }
        adSetFormik.handleSubmit();
    };

    // console.log(adSetFormik.errors);
    // console.log(adSetFormik.values.geo_locations);

    const buildTargetingSpec = () => {

        const geoLocations = adSetFormik.values.geo_locations || [];
        const locales = adSetFormik.values.locales || [];

        const geoLocationList = geoLocations?.length ? transformGeolocations(geoLocations) : null;
        const genderList = ageGender?.genders?.length > 0 ? { genders: ageGender.genders } : null;
        const detailedTargetList = interests?.length > 0 ? transformDetailedTargets(interests) : (initialInterests?.length > 0 ? transformDetailedTargets(initialInterests) : null);
        const localeList = locales?.length > 0 ? { locales: locales } : null;

        const filteredPlacements = Object.fromEntries(
            Object.entries(placements).filter(([_, value]) => value.length > 0)
        );

        return {
            ...geoLocationList,
            // age_min: ageGender.age_min,
            // age_max: ageGender.age_max,
            ...genderList,
            ...detailedTargetList,
            ...localeList,
            ...filteredPlacements,
        };
    };


    return (
        <Box borderRadius="md" width="100%">
            <Flex gap={2}>
                <Flex flex={0.6} direction="column" overflowY="auto" maxHeight="calc(100vh - 200px)">
                    <Box width="100%">
                        <form onSubmit={adSetFormik.handleSubmit}>
                            {/* Ad Set Name */}
                            <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={1}>
                                <Flex>
                                    <Text fontWeight="bold">{name.label}</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                </Flex>
                                <Input
                                    name="name"
                                    placeholder={`Enter ${name.label}`}
                                    bg="#fff"
                                    value={adSetFormik.values.name}
                                    onChange={adSetFormik.handleChange}
                                    onBlur={adSetFormik.handleBlur}
                                    borderRadius="md"
                                    size={'sm'}
                                />
                                {adSetFormik.touched.name && adSetFormik.errors.name && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.name}</Text>
                                )}
                            </Flex>

                            {/* Conversion Location */}
                            <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={2}>
                                <Text fontWeight="bold">{destination_type.label1}</Text>
                                <Text fontWeight="bold">{destination_type.label2}</Text>
                                <RadioGroup
                                    name="destination_type"
                                    onChange={(value) => {
                                        adSetFormik.setFieldValue('destination_type', value)
                                        // adSetFormik.setFieldValue('promoted_object', [])
                                    }}
                                    value={adSetFormik.values.destination_type}
                                >
                                    <Stack spacing={4} direction='column' mb={2}>
                                        {destination_type.options?.map((option) => (
                                            <Flex key={option.value} align="center">
                                                <Radio colorScheme='orange' value={option.value} />
                                                <Stack ml={2} spacing={0}>
                                                    <Text>{option.label1}</Text>
                                                    <Text fontSize='sm'>{option.label2}</Text>
                                                </Stack>
                                            </Flex>
                                        ))}
                                    </Stack>
                                </RadioGroup>
                                {adSetFormik.touched.destination_type && adSetFormik.errors.destination_type && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.destination_type}</Text>
                                )}

                                {adSetFormik.values.destination_type && (
                                    <Flex direction="column" gap={2} mb={2}>
                                        <Flex alignItems={'center'}>
                                            <Text fontWeight="bold">{optimization_goal.label}</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p={'5px'}>
                                                        <Text >Choose what you want people to do when they see your ads. Your campaign objective should align with your overall business goals.</Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" ml={2} />
                                            </Tooltip>
                                        </Flex>

                                        {optimizationOptions.length === 1 ? (
                                            <Text bg="#fff" borderRadius="md" p={2} fontSize={'14px'} height={'32px'} display="flex" alignItems="center">
                                                {optimizationOptions[0].label}
                                            </Text>
                                        ) : (
                                            <Select
                                                name="optimization_goal"
                                                value={adSetFormik.values.optimization_goal}
                                                onChange={(e) => adSetFormik.setFieldValue('optimization_goal', e.target.value)}
                                                onBlur={adSetFormik.handleBlur}
                                                bg="#fff"
                                                borderRadius="md"
                                                size={'sm'}
                                            >
                                                {optimizationOptions.map((option, index) => (
                                                    <option key={`${option.value}-${index}`} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}

                                            </Select>
                                        )}
                                        {adSetFormik.touched.optimization_goal && adSetFormik.errors.optimization_goal && (
                                            <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.optimization_goal}</Text>
                                        )}
                                    </Flex>
                                )}
                                <Flex direction="column">
                                    {/* Promoted Object */}
                                    {(adSetFormik.values.optimization_goal === 'OFFSITE_CONVERSIONS' || adSetFormik.values.optimization_goal === 'VALUE') && (
                                        context === "trackEdit" ? (
                                            campaignData?.adsAccount && (
                                                <PixelConversionSelector
                                                    key={`${campaignData?.adsAccount.adsAccountId}-0`}
                                                    adsAccount={campaignData?.adsAccount}
                                                    adSetFormik={adSetFormik}
                                                    promotedObjectFields={promotedObjectFields}
                                                    promotedObjectData={currentAdsetData?.promoted_object}
                                                    onSelectedPixelId={(id) => handlePixelIdSelection(id, campaignData.adsAccount.adsAccountId)}
                                                    onSelectedEventType={(type) => handleEventTypeSelection(type, campaignData.adsAccount.adsAccountId)}
                                                    errors={adSetFormik.errors}
                                                    onBlur={adSetFormik.handleBlur}
                                                />
                                            )
                                        ) : (
                                            adsAccounts?.map((account, index) => (
                                                <>
                                                    <PixelConversionSelector
                                                        key={`${account?.adsAccountId}-${index}`}
                                                        adsAccount={account}
                                                        adSetFormik={adSetFormik}
                                                        promotedObjectFields={promotedObjectFields}
                                                        promotedObjectData={currentAdsetData?.promoted_object?.filter((field) => field?.adsAccountId === account?.adsAccountId)?.[0]}
                                                        onSelectedPixelId={(id) => handlePixelIdSelection(id, account.adsAccountId)}
                                                        onSelectedEventType={(type) => handleEventTypeSelection(type, account.adsAccountId)}
                                                        onBlur={adSetFormik.handleBlur}
                                                        isSaving={isSaving}
                                                    />
                                                    {
                                                        index != (adsAccounts?.length - 1) ? (
                                                            <Divider />
                                                        ) : null
                                                    }
                                                </>
                                            ))
                                        )
                                    )}

                                    {pixelError || adSetFormik.touched.promoted_object && adSetFormik.errors.promoted_object === 'Pixel id is required' && (
                                        <Text fontSize={'sm'} color="red.500" className="error-class-promoted_object">
                                            {/* {'Pixels & events are required'} */}
                                        </Text>
                                    )}

                                    <Flex gap={2} direction="column">
                                        {adSetFormik.values.optimization_goal === 'QUALITY_CALL' && (
                                            <>
                                                <Flex alignItems="center">
                                                    <Text fontWeight="bold">Facebook pages</Text>
                                                    <Text display="inline-flex" fontSize="18px" color="red.400" mx={2}>*</Text>
                                                    <Tooltip
                                                        borderRadius={5}
                                                        label={
                                                            <Box fontSize="sm" p="5px">
                                                                <Text>Choose the Facebook Page that you want to promote. If the Page you want isn't listed, contact the Page Admin.</Text>
                                                            </Box>
                                                        }
                                                        fontSize="md"
                                                    >
                                                        <InfoOutlineIcon pointerEvents="all" />
                                                    </Tooltip>
                                                </Flex>

                                                {context === "trackEdit" ? (
                                                    campaignData?.adsAccount && (
                                                        <FaceBookPageSelector
                                                            adsAccount={campaignData.adsAccount}
                                                            selectedPageId={currentAdsetData?.promoted_object?.page_id}
                                                            onSelectedPage={(page) => handleFacebookPageSelection(page, campaignData.adsAccount.adsAccountId)}
                                                            key={`fb_pages_${campaignData.adsAccount.adsAccountId}`}
                                                        />
                                                    )
                                                ) : (
                                                    adsAccounts?.map((account) => (
                                                        <FaceBookPageSelector
                                                            adsAccount={account}
                                                            selectedPageId={currentAdsetData?.promoted_object?.filter((account_) => account_?.adsAccountId === account?.adsAccountId)?.[0]?.page_id}
                                                            onSelectedPage={(page) => handleFacebookPageSelection(page, account.adsAccountId)}
                                                            key={`fb_pages_${account.adsAccountId}`}
                                                        />
                                                    ))
                                                )}

                                                {facebookPageError || adSetFormik.touched.promoted_object && adSetFormik.errors.promoted_object === 'Facebook page id is required' && (
                                                    <Text fontSize={'sm'} color="red.500" className="error-class-promoted_object">
                                                        {'Facebook page id is required'}
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                </Flex>

                                {/* Bid Amount Field */}
                                <Flex direction="column">
                                    {adSetFormik.values.optimization_goal &&
                                        bid_strategy?.option[1]?.optimization_goal.includes(adSetFormik.values.optimization_goal) && (
                                            <Flex direction="column">
                                                <Flex alignItems={'center'}>
                                                    <Text mb={2} fontWeight="bold">
                                                        Cost per result goal{" "}
                                                        <Text as="span" fontWeight="normal">Optional</Text>
                                                    </Text>
                                                    <Tooltip
                                                        borderRadius={5}
                                                        label={
                                                            <Box fontSize="sm" p={'5px'}>
                                                                <Text >
                                                                    Set a goal if you're aiming for a certain cost per result. To set your goal,
                                                                    use cost per result data from previous campaigns with the same optimisation event
                                                                    and attribution settings. If you don't set a cost per result goal, Meta will focus
                                                                    on spending your entire budget and getting the most results.<br></br>
                                                                    Cost per result goal is only available with some performance goal selections.
                                                                </Text>
                                                            </Box>
                                                        }
                                                        fontSize="md"
                                                    >
                                                        <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                                    </Tooltip>
                                                </Flex>

                                                <InputGroup>
                                                    <Input
                                                        name="bid_amount"
                                                        type="number"
                                                        placeholder={`${currencySymbol}X.XX`}
                                                        value={adSetFormik.values.bid_amount}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            adSetFormik.handleChange(e);
                                                            if (value) {
                                                                adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITH_BID_CAP');
                                                            } else {
                                                                adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
                                                            }
                                                        }}
                                                        onBlur={adSetFormik.handleBlur}
                                                        bg="#fff"
                                                        borderRadius={'md'}
                                                        size={'sm'}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <InputRightAddon height={'32px'} fontSize={'14px'}>
                                                        {currencyCode}
                                                    </InputRightAddon>
                                                </InputGroup>

                                                {adSetFormik.touched.bid_amount && adSetFormik.errors.bid_amount && (
                                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.bid_amount}</Text>
                                                )}
                                            </Flex>
                                        )}
                                    {/* Conditionally render the bid strategy radio options if bid_amount has a value */}
                                    {(adSetFormik.values.bid_amount) && (adSetFormik.values.destination_type == "WEBSITE") && (
                                        <Flex direction="column" mt={4}>
                                            <Text mb={2} fontWeight="bold">Bid strategy</Text>
                                            <RadioGroup
                                                name="bid_strategy"
                                                value={adSetFormik.values.bid_strategy}
                                                onChange={(value) => adSetFormik.setFieldValue('bid_strategy', value)}
                                            >

                                                <Stack direction="row" spacing={4}>
                                                    <Flex direction="row" align="center" spacing={2}>
                                                        <Radio colorScheme='orange' value="LOWEST_COST_WITH_BID_CAP" />
                                                        <Flex direction="column" ml={2}>
                                                            <Text fontWeight="bold">Cost per result goal</Text>
                                                            <Text fontSize="sm" color="gray.500">Best for getting the most volume</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Stack>
                                            </RadioGroup>
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>

                            {/* Budget Scheduling */}
                            <Flex bg={backgroundColor} p={4} flexDirection="column" borderRadius="md" gap={4} mt={4}>
                                <Text fontWeight="bold">Budget & schedule</Text>
                                {/* Budget */}
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">{budget.label}</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text >
                                                    Your ad set budget is the daily or lifetime amount that you want to spend on this ad set.
                                                    A daily budget is the average that you'll spend every day. A lifetime budget is the maximum that you'll spend during the lifetime of your ad set.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>
                                <Flex>
                                    <Select
                                        name="budget"
                                        value={adSetFormik.values.budget}
                                        onChange={handleBudgetChange}
                                        onBlur={adSetFormik.handleBlur}
                                        bg="#fff"
                                        borderRadius={'md'}
                                        borderRight={'none'}
                                        borderRightRadius="0"
                                        maxW={'200px'}
                                        size={'sm'}
                                    >
                                        {budget.options?.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                    {adSetFormik.values.budget === 'daily_budget' && (
                                        <InputGroup>
                                            <Input
                                                name="daily_budget"
                                                placeholder={currencySymbol}
                                                bg="#fff"
                                                type="number"
                                                value={String(adSetFormik.values.daily_budget)}
                                                onChange={(e) => adSetFormik.setFieldValue('daily_budget', e.target.value)}
                                                onBlur={adSetFormik.handleBlur}
                                                borderRight="0"
                                                borderLeftRadius="none"
                                                size={'sm'}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            <InputRightAddon height={'32px'} fontSize={'14px'}>
                                                {currencyCode}
                                            </InputRightAddon>
                                        </InputGroup>
                                    )}
                                    {adSetFormik.values.budget === 'lifetime_budget' && (
                                        <InputGroup>
                                            <Input
                                                name="lifetime_budget"
                                                placeholder={currencySymbol}
                                                bg="#fff"
                                                type="number"
                                                value={adSetFormik.values.lifetime_budget}
                                                onChange={(e) => adSetFormik.setFieldValue('lifetime_budget', e.target.value)}
                                                onBlur={adSetFormik.handleBlur}
                                                borderRight="0"
                                                borderLeftRadius="none"
                                                size={'sm'}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            <InputRightAddon height={'32px'} fontSize={'14px'}>
                                                {currencyCode}
                                            </InputRightAddon>
                                        </InputGroup>
                                    )}
                                </Flex>
                                {adSetFormik.touched.budget && adSetFormik.errors.budget && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.budget}</Text>
                                )}
                                {adSetFormik.values.budget === 'daily_budget' && adSetFormik.touched.daily_budget && adSetFormik.errors.daily_budget && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.daily_budget}</Text>
                                )}
                                {adSetFormik.values.budget === 'lifetime_budget' && adSetFormik.touched.lifetime_budget && adSetFormik.errors.lifetime_budget && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.lifetime_budget}</Text>
                                )}

                                {/* Schedule */}
                                <Flex alignItems={'center'} mt={2}>
                                    <Text fontWeight="bold">Schedule</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text >
                                                    You can choose to run your ads continuously starting today or only during a specific date range.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>

                                <Flex direction="column" gap={2}>
                                    {/* Start Date */}
                                    <Text fontWeight={'bold'}>Start date</Text>
                                    <Flex alignItems="center" gap={6}>
                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" bg={inputBackground} p={1} >
                                            <Flex alignItems="center" gap={2}>
                                                <CalendarIcon ml={1} />
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        setStartDate(date)
                                                    }}
                                                    minDate={new Date()}
                                                    dateFormat="dd/MM/yyyy"
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    customInput={
                                                        <input
                                                            type="text"
                                                            placeholder={formatUnixTimestamp(currentAdsetData?.start_time)}
                                                            style={{
                                                                fontSize: '14px',
                                                                fontFamily: 'Katwijk-Light,sans-serif',
                                                                padding: '4px',
                                                                border: 'none',
                                                                width: '100%',
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Flex>
                                        </Box>
                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={'8px'} bg={inputBackground}>
                                            <Flex alignItems="center" gap={2}>
                                                <TimeIcon />
                                                <Text fontSize="14px">{moment(startDate).format("HH:mm")}</Text>
                                                {/* <Text fontSize="12px">IST</Text> */}
                                            </Flex>
                                        </Box>
                                    </Flex>

                                    {/* End Date */}
                                    {(adSetFormik.values.budget === 'daily_budget' || adSetFormik.values.budget === 'lifetime_budget') && (
                                        <Flex direction="column" gap={2} mt={4}>
                                            {adSetFormik.values.budget === 'daily_budget' && (
                                                <Checkbox
                                                    colorScheme='orange'
                                                    isChecked={showEndDate}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        setShowEndDate(!showEndDate);
                                                    }}
                                                >
                                                    Set an end date
                                                </Checkbox>
                                            )}
                                            {(adSetFormik.values.budget === 'lifetime_budget' || showEndDate) && (
                                                <>
                                                    <Text fontWeight={'bold'}>End date</Text>
                                                    <Flex alignItems="center" gap={6}>
                                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" bg={inputBackground} p={1} >
                                                            <Flex alignItems="center" gap={2}>
                                                                <CalendarIcon ml={1} />
                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date) => setEndDate(date)}
                                                                    minDate={new Date()}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={15}
                                                                    timeCaption="Time"
                                                                    customInput={
                                                                        <input
                                                                            type="text"
                                                                            placeholder={"dd/mm/yyyy"}
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                fontFamily: 'Katwijk-Light,sans-serif',
                                                                                padding: '4px',
                                                                                border: 'none',
                                                                                width: '100%',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Flex>
                                                        </Box>

                                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={'8px'} bg={inputBackground}>
                                                            <Flex alignItems="center" gap={2}>
                                                                <TimeIcon />
                                                                <Text fontSize="14px">{moment(endDate).format("HH:mm")}</Text>
                                                                {/* <Text fontSize="12px">IST</Text> */}
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                    <Flex>
                                                        {errorDateMessage && <Text fontSize={'12px'} color="red.500">{errorDateMessage}</Text>}
                                                    </Flex>
                                                </>
                                            )}
                                        </Flex>
                                    )}
                                </Flex>

                                <Flex alignItems={'center'} mt={2}>
                                    <Text fontWeight="bold">Ad scheduling</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text >
                                                    You can schedule your ads to deliver on specific hours and days of the week based on the viewer's or your ad account's time zone.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>
                                <Flex>
                                    <Tooltip
                                        borderRadius={5}
                                        p={2}
                                        ml={20}
                                        label="Ad scheduling is only available when you use lifetime budget."
                                        isDisabled={adSetFormik.values.budget === 'lifetime_budget'}
                                    >
                                        <span>
                                            <Checkbox
                                                colorScheme='orange'
                                                isChecked={isScheduled}
                                                onChange={(e) => setIsScheduled(e.target.checked)}
                                                isDisabled={adSetFormik.values.budget !== 'lifetime_budget'}
                                            >
                                                Run ads on a schedule
                                            </Checkbox>
                                        </span>
                                    </Tooltip>
                                </Flex>
                                {isScheduled && (
                                    <>
                                        <Flex mb={4} direction={'column'} gap={2}>
                                            <Select
                                                value={timezone}
                                                onChange={(e) => setTimezone(e.target.value)}
                                                size={'sm'}
                                                borderRadius="md"
                                            >
                                                <option value="USER">Use viewer's time zone</option>
                                                <option value="ADVERTISER">Use this ad account's time zone</option>
                                            </Select>
                                            {timezone == "USER" && <Text fontSize={'12px'}>We'll schedule your ads based on the time zone of the person seeing it.</Text>}
                                            {timezone == "ADVERTISER" && <Text fontSize={'12px'}>We'll schedule your ads based on ad account's time zone</Text>}
                                        </Flex>
                                        <AdScheduler onAdScheduleChange={handleAdScheduleChange} adsetScheduleData={currentAdsetData?.adset_schedule} />
                                    </>
                                )}
                            </Flex>

                            {/* Audience controls  */}
                            <Flex bg={backgroundColor} p={4} flexDirection="column" borderRadius="md" gap={4} mt={4} overflow="visible" zIndex={1000} >
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">Audience controls</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text >
                                                    <strong>Setting audience controls </strong><br></br>
                                                    With Advantage+ audience, our system searches within these criteria for a more specific audience. To give our system flexibility to get results, we recommend setting these controls to create a broad audience.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>
                                {/* Targeting */}
                                <Flex flexDirection="column">
                                    <Flex alignItems={'center'}>
                                        <Text fontWeight="bold">{targeting.geo_locations.label}</Text>
                                        <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="sm" p={'5px'}>
                                                    <Text >
                                                        Choosing a broad area to show your ads within can improve results. For example, by adding a country instead of several cities.
                                                    </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                                        </Tooltip>
                                    </Flex>
                                    <Text fontSize={'14px'}>Reach people living in or recently in this location.</Text>
                                    <GeoLocationsSelector
                                        preFilledExcludedLocations={currentAdsetData?.targeting?.excluded_geo_locations}
                                        preFilledIncludedLocations={currentAdsetData?.targeting?.geo_locations}
                                        selectedZipCodes={zipCodes}
                                        onLocationsChange={handleLocationsChange}
                                        onBlur={adSetFormik.handleBlur}
                                        BMID={firstBMID}
                                    />
                                    {adSetFormik.touched.geo_locations && adSetFormik.errors.geo_locations && (
                                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.geo_locations}</Text>
                                    )}
                                </Flex>
                                <Link fontWeight={'bold'} fontSize={'xs'} textDecoration={'underline'} onClick={() => setisBulkLocationSelectorVisible(true)}>Add locations in bulk</Link>
                                <BulkLocationSelector
                                    isVisible={isBulkLocationSelectorVisible}
                                    onClose={() => setisBulkLocationSelectorVisible(false)}
                                    onSubmit={(zips) => {
                                        setzipCodes(zips)
                                        let selectedLocations = adSetFormik.values.geo_locations
                                        adSetFormik.setFieldValue('geo_locations', [...new Set([...selectedLocations, ...zips])]);
                                    }}
                                    BMID={firstBMID}
                                />

                                <Divider />

                                {/* Age Gender & Detailed targeting */}
                                <Box>
                                    <Box>
                                        <Flex alignItems="center" justifyContent="space-between">
                                            <Flex flexDirection={'column'} gap={2}>
                                                <Text fontWeight={'bold'}>Audience</Text>
                                                <Button onClick={() => setIsExpanded(!isExpanded)} variant="link" rightIcon={isExpanded ? <ChevronUpIcon color={'gray.800'} /> : <ChevronDownIcon color={'gray.800'} />}>
                                                    <Text fontSize={'14px'} color={'gray.800'}>{isExpanded ? 'Hide Options' : 'Show More Options'}</Text>
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Box>

                                    {/* Collapsible content */}
                                    <Collapse in={isExpanded}>
                                        <Flex direction="column" gap={4} pt={4}>
                                            <Flex direction="column">
                                                <Text fontWeight="bold">Detailed targeting</Text>
                                                <DetailedTargetingSelector
                                                    preFilledTargets={flattenTargeting(currentAdsetData?.targeting)}
                                                    adsAccounts={adsAccounts ? adsAccounts : campaignData?.adsAccount}
                                                    onInterestsChange={handleInterestsChange}
                                                    BMID={firstBMID}
                                                />
                                            </Flex>
                                            <AgeGenderSelector
                                                preFilledData={currentAdsetData?.targeting}
                                                onChange={handleAgeGenderChange} />
                                            <Box>
                                                <Flex align={'center'} mt={'10px'}>
                                                    <Text fontWeight="bold">Languages</Text>
                                                    <Box>
                                                        <Tooltip
                                                            borderRadius={5}
                                                            label={
                                                                <Box fontSize="sm" p={'5px'}>
                                                                    <Text >
                                                                        Enter a language to show your ads to people who use a language that isn't common to your location. Otherwise, leave this blank.
                                                                    </Text>
                                                                </Box>
                                                            }
                                                            fontSize="md"
                                                        >
                                                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                                                        </Tooltip>
                                                    </Box>
                                                </Flex>
                                                <LocaleSelector
                                                    BMID={firstBMID}
                                                    preFilledLocales={currentAdsetData?.targeting?.locales}
                                                    onLocalesChange={(locales) => adSetFormik.setFieldValue('locales', locales)}
                                                />
                                            </Box>
                                        </Flex>
                                    </Collapse>
                                </Box>
                            </Flex>


                            {/* Placements Section */}
                            <Flex bg={backgroundColor} flexDirection="column" borderRadius="md" mt={4} mb={4}>
                                <PlacementsSelector
                                    currentAdsetData={currentAdsetData?.targeting}
                                    optimization_goal={adSetFormik.values.optimization_goal}
                                    objective={objective}
                                    buying_type={buying_type}
                                    billing_event={adSetFormik.values.billing_event}
                                    destination_type={adSetFormik.values.destination_type}
                                    onPlacementsChange={setPlacements}
                                    account_id={firstAdAccountId}
                                    BMID={firstBMID}
                                />
                            </Flex>

                            <Flex direction="column" bg={backgroundColor} p={4} borderRadius="md" gap={1}>
                                <Flex>
                                    <Text fontWeight="bold">Breakeven CPA</Text>
                                </Flex>
                                <Input
                                    name="cpa"
                                    type="number"
                                    placeholder={`Enter the cpa`}
                                    bg="#fff"
                                    value={adSetFormik.values.cpa}
                                    onChange={adSetFormik.handleChange}
                                    onBlur={adSetFormik.handleBlur}
                                    borderRadius="md"
                                    size={'sm'}
                                    onWheel={(e) => e.target.blur()}
                                />
                                {/* {adSetFormik.touched.cpa && adSetFormik.errors.cpa && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.cpa}</Text>
                                )} */}
                            </Flex>

                            {/* Submit Button */}
                            <Flex justify="flex-start" my={'20px'} >
                                <Button
                                    colorScheme="orange"
                                    isLoading={adSetFormik.isSubmitting}
                                    //type="submit"
                                    onClick={handleSave}
                                    size={'sm'} >Save</Button>
                            </Flex>
                        </form>
                    </Box>
                </Flex>
                <Flex flex={0.4} direction="column" position="sticky" top="0" maxHeight="100vh" overflowY="auto">
                    {/* {adsAccounts?.map((account, index) => ( */}
                    <Audience adsAccount={adsAccounts[0]} targeting_spec={buildTargetingSpec()} />
                    {/* ))} */}
                </Flex>
            </Flex>
        </Box>
    );
}

export default LeadForm;
