import { Flex, Spinner} from '@chakra-ui/react';
import { useGetAdsetFormQuery, useGetAdsetByIDQuery } from 'store/campaigns/adsetFormApi';
import { useGetcampaignByIdQuery } from 'store/campaigns/campaignsApi';
import AcrossLeadUpdateForm from './AcrossLeadsUpdateForm';
import AcrossSalesUpdateForm from './AcrossSalesUpdateForm';

const AcrossAdSetUpdate = ({ selectedAdset, onClose, onSaveSuccess }) => {

    const { campaign_id : campaignId, account_id} = selectedAdset || {};
    
    const { data: campaignData, isLoading:isCampaignLoading } = useGetcampaignByIdQuery(
        {campaignId, adsAccountId:account_id},
        { skip: !campaignId }
    );

    const adsAccountData = {
        account_id: selectedAdset?.account_id,
        name: selectedAdset?.account_name,
        timezone_name: selectedAdset?.timezone_name,
        currency: selectedAdset?.currency
    }

    const campaignType = campaignData?.[campaignId]?.objective;

    const { data: adsetData, isLoading:isAdsetLoading } = useGetAdsetByIDQuery({
        adsAccountId:account_id,
        adsetId:selectedAdset?.adset_id
    }, { skip: !account_id || !selectedAdset?.adset_id });

    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });    

    const renderForm = () => {
  
        if (isLoadingSales || isLoadingLeads || isAdsetLoading || isCampaignLoading) {
            return (
                <Flex justify="center" align="center" height="70vh">
                    <Spinner size="md" />
                </Flex>
            );
        }

        if (campaignType === 'OUTCOME_SALES' && dataSales?.data && campaignData) {
            return (
                <AcrossSalesUpdateForm
                    data={dataSales?.data}
                    campaignData={campaignData}
                    adsAccountData={adsAccountData}
                    selectedAdset={adsetData}
                    onEditModalClose={onClose}
                    onClose={onClose}
                    context="adsetEdit"
                    onSaveSuccess={onSaveSuccess}
                />
            );
        } else if (campaignType === 'OUTCOME_LEADS' && dataLeads?.data && campaignData) {
            return (
                <AcrossLeadUpdateForm
                    data={dataLeads?.data}
                    campaignData={campaignData}
                    adsAccountData={adsAccountData}
                    selectedAdset={adsetData}
                    onEditModalClose={onClose}
                    onClose={onClose}
                    context="adsetEdit"
                    onSaveSuccess={onSaveSuccess}
                />
            );
        }

        return null;
    };

    return (
        <>
         {renderForm()}
        </>    
    );
};

export default AcrossAdSetUpdate;
