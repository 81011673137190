import { createSlice } from '@reduxjs/toolkit';

const savedCampaignId = localStorage.getItem('selectedCampaignId');
const savedAdsetId = localStorage.getItem('selectedAdsetId');
const savedAdsId = localStorage.getItem('selectedAdsId');
const initialState = {
  selectedCampaignId: savedCampaignId ? savedCampaignId : null,
  selectedAdsetId: savedAdsetId ? savedAdsetId : null,
  selectedAdsId: savedAdsId ? savedAdsId : null,
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setSelectedCampaignId: (state, action) => {
      state.selectedCampaignId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedCampaignId'); // Remove from local storage
      } else {
        localStorage.setItem('selectedCampaignId', action.payload); // Set the new value to local storage
      }
    },
    setSelectedAdsetId: (state, action) => {
      state.selectedAdsetId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdsetId'); // Remove from local storage
      } else {
        localStorage.setItem('selectedAdsetId', action.payload); // Set the new value to local storage
      }
    },
    setSelectedAdsId: (state, action) => {
      state.selectedAdsId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdsId'); // Remove from local storage
      } else {
        localStorage.setItem('selectedAdsId', action.payload); // Set the new value to local storage
      }
    },
  },
});

export const { setSelectedCampaignId, setSelectedAdsetId, setSelectedAdsId } = campaignsSlice.actions;

export const selectSelectedCampaignId = (state) => state.campaigns.selectedCampaignId;
export const selectSelectedAdsetId = (state) => state.campaigns.selectedAdsetId;
export const selectSelectedAdsId = (state) => state.campaigns.selectedAdsId;

export default campaignsSlice.reducer;
