import { useEffect, useState, useCallback } from "react";
import SidebarWithHeader from 'components/SidebarWithHeader';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Flex, Text, Select, Icon, Box, Tooltip, Spinner, useToast } from '@chakra-ui/react';
import { MdFolderOpen, MdWindow, MdOutlineFolderSpecial, MdClose } from 'react-icons/md';
import CampaignsTable from 'components/campaignTables/CampaignsTable';
import AdSetsTable from "components/campaignTables/AdSetsTable";
import AdsTable from "components/campaignTables/AdsTable";
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { toZonedTime } from "date-fns-tz";
import DateRangeSelector from "components/campaignTables/DateRangeSelector";
import { TbRefresh } from "react-icons/tb";
import { selectSelectedAdsetId, selectSelectedAdsId, selectSelectedCampaignId, setAdsetsForInsights, setAfter, setBefore, setCampaignsForInsights, setCurrentPage, setSelectedAdset, setSelectedAdsetId, setSelectedAdsId, setSelectedCampaignId, selectAccessAllAdAccounts, setSelectedCampaigns, setPresentPage } from "store/campaigns/campaignsSlice";
import { selectSelectedAdAccounts, selectAdAccountAssigned, selectAdAccountLoading, selecthasAdAccountChecked, selectSelectedBmAccounts } from "store/BmAccounts/bmAccountSlice";
import { useGetBmAdAccountsQuery } from "store/BmAccounts/bmAccountApi";
import SearchAdaccount from "components/bmControl/SearchAdaccount";
import AllCampaignsTable from "components/campaignTables/AllCampaignsTable";
import AllAdSetsTable from "components/campaignTables/AllAdsetsTable";
import AllAdsTable from "components/campaignTables/AllAdsTable";


function CampaignsForClient() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const toast = useToast();
    const queryParams = new URLSearchParams(location.search);
    const businessId = queryParams.get('business_id');
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const selectedAdsId = useSelector(selectSelectedAdsId);
    const selectedAdAccounts = useSelector(selectSelectedAdAccounts);
    const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount);
    const hasAdAccountAssigned = useSelector(selectAdAccountAssigned);
    const adAccountLoading = useSelector(selectAdAccountLoading);
    const accessAllAdAccounts = useSelector(selectAccessAllAdAccounts);

    const [searchParams] = useSearchParams();
    const adsAccountIdFromParams = searchParams.get('adsAccountId');
    const params = new URLSearchParams(window.location.search);

    const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [datePreset, setDatePreset] = useState('last_7d');
    const [selectedTab, setSelectedTab] = useState(parseInt(searchParams.get('tab') || '0', 10));
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [afterValue, setAftervalue] = useState(''); 

    const { data: AdAccountData, refetch: refetchAdaccountData, isLoading:isAdAccountLoading, isFetching:isAdAccountFetching, error:AdAccountError, isError:isAdaccountError } = useGetBmAdAccountsQuery(
        {
            search: searchTerm ? searchTerm : '',
            pageSize:'20',
            after: searchTerm ? '' : afterValue,
        },
    );

    useEffect(() => {
        if (AdAccountError) {

            toast({
                position: "top-right",
                title: "Error",
                description: AdAccountError?.data?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [AdAccountError]);

    useEffect(() => {
        if (businessId) {
            refetchAdaccountData();
            setSearchTerm('')
        }
    }, [businessId]);

    useEffect(() => {
        if (hasSearched && searchTerm === '') {
            refetchAdaccountData();
        }
    }, [searchTerm]);


    useEffect(() => {

        // If accountTimezone is available, calculate dates based on that
        if (selectedAccountData?.accountTimezone) {
            const accountTimezone = selectedAccountData?.accountTimezone;
            const initialStartDate = toZonedTime(moment().subtract(7, 'days').toDate(), accountTimezone);
            const initialEndDate = toZonedTime(new Date(), accountTimezone);

            setStartDate(initialStartDate);
            setEndDate(initialEndDate);
        }
    }, [selectedAccountData]);


    useEffect(() => {
        const storedAccount = JSON.parse(localStorage.getItem('selectedAdsAccount'));
    
        if (selectedAdsAccountId) {
            return; 
        }
        
        if (adsAccountIdFromParams) {
            setSelectedAdsAccountId(adsAccountIdFromParams);
            return; 
        }
        
        if (storedAccount) {
            setSelectedAdsAccountId(storedAccount?.value);
            return; 
        }
    
        if (selectedAdAccounts?.length > 0) {
            const defaultAccountId = selectedAdAccounts[0]?.account_id;
            setSelectedAdsAccountId(defaultAccountId);
        }
    }, [selectedAdAccounts, adsAccountIdFromParams]);

    useEffect(() => {
        // Handle URL parameters for date and ads account ID on initial load
        const dateParam = searchParams.get('date');
        if (dateParam) {
            try {
                const decodedDateParam = decodeURIComponent(dateParam);
                const [dateRange, preset] = decodedDateParam.split(',');
                const [urlStartDate, urlEndDate] = dateRange.split('_');

                // Set the state based on the URL parameters
                setStartDate(urlStartDate ? moment(urlStartDate, "YYYY-MM-DD").toDate() : null);
                setEndDate(urlEndDate ? moment(urlEndDate, "YYYY-MM-DD").toDate() : null);
                setDatePreset(preset || '');
            } catch (error) {
                console.error("Error parsing date from URL:", error);
            }
        }

    }, []);

    useEffect(() => {
        const campaignIdFromUrl = params.get('campaignIds'); 
        const adsetIdFromUrl = params.get('adsetIds');
        const objectiveFromUrl = params.get('objective');

        const campaignObj = {
            campaign_id:campaignIdFromUrl,
            objective:objectiveFromUrl
        }
        
        if (campaignIdFromUrl && objectiveFromUrl ) {
            dispatch(setSelectedCampaignId(campaignObj));
        }

        if(adsetIdFromUrl){
            dispatch(setSelectedAdsetId(adsetIdFromUrl));
        }
    }, []);

    
    useEffect(() => {
        if (selectedAdsAccountId) {
            const account = selectedAdAccounts?.find(acc => acc?.account_id === selectedAdsAccountId);
            setSelectedAccountData(account);
        }
    }, [selectedAdsAccountId, selectedAccountData, AdAccountData, selectedAdAccounts]);


    const handleDateRangeChange = (startDate, endDate, datePreset) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setDatePreset(datePreset);
    };
    

    // Update the URL when startDate, endDate, or datePreset change
    useEffect(() => {

        const publishDestination = localStorage.getItem('sourcePublish');

        // Always include the selected adsAccountId first
        if (selectedAdsAccountId && publishDestination !== 'publish') {
            params.set('adsAccountId', selectedAdsAccountId);
        }else if(selectedAdsAccountId && !adsAccountIdFromParams){
            params.set('adsAccountId', selectedAdsAccountId);
        }


        // Setting the date parameter in the format: 2024-10-26_2024-11-25%2Clast_30d
        const formattedDate = startDate && endDate
            ? `${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")},${datePreset}`
            : datePreset;

        if (formattedDate) {
            params.set('date', encodeURIComponent(formattedDate));
        }

        if (selectedCampaignId) {
            params.set('campaignIds', selectedCampaignId?.campaign_id ? selectedCampaignId?.campaign_id : selectedCampaignId);
            params.set('objective', selectedCampaignId?.objective);
        }

        if (selectedAdsetId) {
            params.set('adsetIds', selectedAdsetId?.adset_id ? selectedAdsetId?.adset_id : selectedAdsetId);
        }
        if (selectedAdsId) {
            params.set('adsIds', selectedAdsId);
        }
        if (selectedTab) {
            params.set('tab', selectedTab);
        }
        // Update the URL with the new parameters
        navigate({ search: params.toString() }, { replace: true });
    }, [startDate, endDate, datePreset, selectedAdsAccountId, selectedCampaignId, selectedAdsetId, selectedAdsId]);


    const resetSelectionsAndParams = () => {
        // Remove parameters from the URL to reset the state
        dispatch(setCampaignsForInsights([]));
        dispatch(setAdsetsForInsights([]));
        dispatch(setAfter(''));
        dispatch(setBefore(''));
        dispatch(setCurrentPage(0));
        dispatch(setPresentPage(1));
        localStorage.removeItem('after')
        localStorage.removeItem('before')
        localStorage.removeItem('currentPage')
    };

    const handleRefresh = useCallback(() => {
        setShouldRefresh(true);

    }, []);

    const handleRefreshComplete = () => {
        setShouldRefresh(false);
    };

    const handleAdaccountChange = (value) => {
        if (value?.value !== selectedAdsAccountId) {
            setSelectedAdsAccountId(value?.value);
            // Reset selections and parameters
            dispatch(setSelectedCampaignId(null));
            dispatch(setSelectedCampaigns(null));
            dispatch(setSelectedAdsetId(null));
            dispatch(setSelectedAdset(null));
            dispatch(setSelectedAdsId(null));
            resetSelectionsAndParams();
        }
    }

    const handleSearchChange = useCallback((value) => {
        setSearchTerm(value);
    }, []);

    const handleLoadMore = (after) => {
        setAftervalue(after);
    };

    return (
        <SidebarWithHeader>
        {isAdaccountError ? (
            <Flex justify="center" align="center" height="80vh">
                <Text color="red.500">
                    {AdAccountError?.data?.message}
                </Text>
            </Flex>
        // ) : (isAdAccountLoading) ? (
        //     <Flex justify="center" align="center" height="80vh">
        //         <Spinner mt="20px" />
        //     </Flex>
        ) : (
            <>
                <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                    <Flex gap={'10px'} align={'center'}>
                        <Text fontWeight={'bold'}>Ad accounts</Text>
                        <Box maxWidth={'400px'}>
                            {/* <Select
                                    value={selectedAdsAccountId}
                                    onChange={(e) => {
                                        setSelectedAdsAccountId(e.target.value);
                                        dispatch(setSelectedCampaignId(null));
                                        dispatch(setSelectedAdsetId(null));
                                        dispatch(setSelectedAdsId(null));
                                        resetSelectionsAndParams();  
                                    }}
                                    size={'sm'}
                                    borderRadius={'md'}
                                >
                                    {selectedAdAccounts?.map(account => (
                                        <option key={account?.id} value={account?.account_id}>
                                            {`${account.name} (${account?.account_id})`}
                                        </option>
                                    ))}
                            </Select> */}
                            {/* {AdAccountData && */}
                                <SearchAdaccount
                                    onInitialisation={setSelectedAdsAccountId}
                                    accountOptions={AdAccountData?.data}
                                    onAccountChange={handleAdaccountChange}
                                    onSearchInputChange={handleSearchChange}
                                    onSearchInputTouched={setHasSearched}
                                    AdsAccountIdFromParams={adsAccountIdFromParams}
                                    isAdAccountLoading={isAdAccountLoading}
                                    isAdAccountFetching={isAdAccountFetching}
                                    pageMeta={AdAccountData?.meta}
                                    onLoadMore={handleLoadMore}
                                    context={'campaign'}
                                />
                            {/* } */}
                        </Box>
                        <Box>
                            <Tooltip label='Refresh Table Data' fontSize='xs' >
                                <Flex>
                                    <TbRefresh cursor="pointer" onClick={handleRefresh} />
                                </Flex>
                            </Tooltip>
                        </Box>
                    </Flex>

                    <Flex align={'center'}>
                        <Box>
                            {startDate && endDate ?
                                <DateRangeSelector
                                    onDateRangeChange={handleDateRangeChange}
                                    initialStartDate={startDate}
                                    initialEndDate={endDate}
                                    initialDatePreset={datePreset}
                                    timeZone={selectedAccountData?.timezone_name}
                                />
                                : null
                            }
                        </Box>
                    </Flex>
                </Flex>

                {/* Tabs always visible, and can switch between tabs */}
                <Tabs
                    index={selectedTab}
                    onChange={(index) => {
                        setSelectedTab(index);
                        params.set('tab', index);
                        params.set('page_id', 0);
                        navigate({ search: params.toString() }, { replace: true });
                        resetSelectionsAndParams();
                    }}
                    p={0}
                    colorScheme='orange'
                    variant='enclosed-colored'
                >
                    <TabList>
                        <Tab fontWeight="bold" flex={1}>
                            <Flex justify="space-between" align="center" width="100%">
                                <Flex align="center">
                                    <Icon as={MdFolderOpen} boxSize={6} mr={2} />
                                    Campaigns
                                </Flex>
                                {selectedCampaignId ? (
                                    <Flex align="center" bg="orange.500" color="#fff" p={1} borderRadius="md">
                                        <Text fontSize="sm" fontWeight="normal" mr={2}>
                                            1 selected
                                        </Text>
                                        <Icon
                                            as={MdClose}
                                            boxSize={4}
                                            cursor="pointer"
                                            onClick={() => {
                                                dispatch(setSelectedCampaignId(null));
                                                dispatch(setSelectedCampaigns(null));
                                                params.delete('campaignIds');
                                                localStorage.removeItem('sourcePublish');
                                                navigate({ search: params.toString() }, { replace: true });
                                            }}
                                            _hover={{ bg: 'orange.600' }}
                                        />
                                    </Flex>
                                ) : null}
                            </Flex>
                        </Tab>
                        <Tab fontWeight="bold" flex={1}>
                            <Flex justify="space-between" align="center" width="100%">
                                <Flex align="center">
                                    <Icon as={MdWindow} boxSize={6} mr={2} />
                                    <Text>
                                        {(selectedCampaignId && !selectedAdsetId) ? "Ad sets for 1 Campaign " : "Ad sets"}
                                    </Text>
                                </Flex>
                                {selectedAdsetId ? (
                                    <Flex align="center" bg="orange.500" color="#fff" p={1} borderRadius="md">
                                        <Text fontSize="sm" fontWeight="normal" mr={2}>
                                            1 selected
                                        </Text>
                                        <Icon
                                            as={MdClose}
                                            boxSize={4}
                                            cursor="pointer"
                                            onClick={() => {
                                                dispatch(setSelectedAdsetId(null));
                                                dispatch(setSelectedAdset(null));
                                                params.delete('adsetIds');
                                                localStorage.removeItem('sourcePublish');
                                                navigate({ search: params.toString() }, { replace: true });
                                            }}
                                            _hover={{ bg: 'orange.600' }}
                                        />
                                    </Flex>
                                ) : null}
                            </Flex>
                        </Tab>
                        <Tab fontWeight="bold" flex={1}>
                            <Flex justify="space-between" align="center" width="100%">
                                <Flex align="center">
                                    <Icon as={MdOutlineFolderSpecial} boxSize={6} mr={2} />
                                    <Text>
                                        {selectedAdsetId ? "Ads for 1 Ad set" : selectedCampaignId ? "Ads for 1 Campaign" : "Ads"}
                                    </Text>
                                </Flex>
                                {selectedAdsId ? (
                                    <Flex align="center" bg="orange.500" color="#fff" p={1} borderRadius="md">
                                        <Text fontSize="sm" fontWeight="normal" mr={2}>
                                            1 selected
                                        </Text>
                                        <Icon
                                            as={MdClose}
                                            boxSize={4}
                                            cursor="pointer"
                                            onClick={() => {
                                                dispatch(setSelectedAdsId(null));
                                                params.delete('adsIds');
                                                localStorage.removeItem('sourcePublish');
                                                navigate({ search: params.toString() }, { replace: true });
                                            }}
                                            _hover={{ bg: 'orange.600' }}
                                        />
                                    </Flex>
                                ) : null}
                            </Flex>
                        </Tab>
                    </TabList>
                    {hasAdAccountAssigned && selectedAdsAccountId ? (
                        <>
                            <TabPanels>
                                <TabPanel px={0}>
                                    {selectedTab === 0 && (
                                    <>
                                        {accessAllAdAccounts ? (
                                            <AllCampaignsTable
                                             adsAccountData={selectedAccountData}
                                             startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                             endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                             datePreset={datePreset}
                                             setSelectedTab={setSelectedTab}
                                             onRefresh={shouldRefresh}
                                             onRefreshComplete={handleRefreshComplete}
                                         />
                                        ) : (
                                            <CampaignsTable
                                            adsAccountData={selectedAccountData}
                                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                            datePreset={datePreset}
                                            setSelectedTab={setSelectedTab}
                                            onRefresh={shouldRefresh}
                                            onRefreshComplete={handleRefreshComplete}
                                        />
                                        )} 
                                    </> 
                                    )}
                                </TabPanel>
                                <TabPanel px={0}>
                                    {selectedTab === 1 ? (
                                         <>
                                         {accessAllAdAccounts ? (
                                             <AllAdSetsTable
                                              adsAccountData={selectedAccountData}
                                              startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                              endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                              datePreset={datePreset}
                                              setSelectedTab={setSelectedTab}
                                              onRefresh={shouldRefresh}
                                              onRefreshComplete={handleRefreshComplete}
                                          />
                                         ) : (
                                            <AdSetsTable
                                            adsAccountData={selectedAccountData}
                                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                            datePreset={datePreset}
                                            setSelectedTab={setSelectedTab}
                                            onRefresh={shouldRefresh}
                                            onRefreshComplete={handleRefreshComplete}
                                            />
                                         )} 
                                     </> 
                                    ) : null}
                                </TabPanel>
                                <TabPanel px={0}>
                                    {selectedTab === 2 && (
                                        <>
                                        {accessAllAdAccounts ? (
                                            <AllAdsTable
                                             adsAccountData={selectedAccountData}
                                             startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                             endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                             datePreset={datePreset}
                                             setSelectedTab={setSelectedTab}
                                             onRefresh={shouldRefresh}
                                             onRefreshComplete={handleRefreshComplete}
                                         />
                                        ) : (
                                            <AdsTable
                                            adsAccountData={selectedAccountData}
                                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                            datePreset={datePreset}
                                            onRefresh={shouldRefresh}
                                            onRefreshComplete={handleRefreshComplete}
                                        />
                                        )} 
                                    </> 
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </>
                    ) : (
                        <Flex justify="center" align="center" height="80vh">
                            <Text>No data available</Text>
                        </Flex>
                    )}
                </Tabs>
            </>
        )} 
        </SidebarWithHeader>
    );
}

export default CampaignsForClient;