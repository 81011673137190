import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SkeletonText,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useGetPixelsByAdaccountQuery } from 'store/BmAccounts/bmAccountApi';
import { useGetCustomConversionEventsQuery, useGetCustomStatsQuery } from 'store/campaigns/adsetFormApi';
import { useCreatePixelMutation } from 'store/facebookServices/facebookServicesApi';
import * as Yup from 'yup';

const PixelConversionAdsetUpdateSelector = ({
    adsAccount,
    promotedObjectFields,
    promotedObjectData,
    onSelectedPixelId,
    onSelectedEventType,
    onBlur,
    isSaving,
}) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { account_id, name } = adsAccount || {};

    const [selectedPixelId, setSelectedPixelId] = useState(promotedObjectData?.pixel_id);
    const [selectedEventType, setSelectedEventType] = useState(
        promotedObjectData?.custom_event_str ? promotedObjectData?.custom_event_str :
            promotedObjectData?.custom_conversion_id
                ? promotedObjectData.custom_conversion_id
                : promotedObjectData?.custom_event_type || ''
    );

    const [pixelIdError, setPixelIdError] = useState(true);
    const [eventTypeError, setEventTypeError] = useState(true);

    const { data: pixelList, isLoading: isPixelLoading } = useGetPixelsByAdaccountQuery(
        {
            adsAccountId: account_id,
        }, {}
    );

    const { data: CustomeventList, isLoading:isCustomListLoading, error:isCustomEventError } = useGetCustomConversionEventsQuery({
        adsAccountId: account_id,
    }, {});

    const { data: StatusList, isLoading:isStatusListLoading, error:isStatusError } = useGetCustomStatsQuery({
        pixelId: selectedPixelId,
        adsAccountId: account_id,
    }, { skip: !selectedPixelId });

    const [createPixel, { }] = useCreatePixelMutation();

    const eventTypeOptions = promotedObjectFields.custom_event_type?.options || [];

    useEffect(() => {
        if (isCustomEventError) {
                toast({
                position: "top-right",
                title: "Error",
                description: isCustomEventError?.data?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
      }, [isCustomEventError]);

      useEffect(() => {
        if (isStatusError) {
                toast({
                position: "top-right",
                title: "Error",
                description: isStatusError?.data?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
      }, [isStatusError]);


    // This function filters and updates the eventTypeOptions
    const filterEventsByPixel = () => {
        if (selectedPixelId || CustomeventList?.length > 0 ||  StatusList?.length > 0) {
        const eventTypeFilteredOptions = eventTypeOptions?.map(eventType => ({
            ...eventType,
            category: 'Standard',
        }));
    
        const filteredEvents = (Array.isArray(CustomeventList) ? CustomeventList : [])
            .map(event => ({
                ...event,
                category: 'Custom',
            }))
            .filter(event => event?.pixel === selectedPixelId);
    
        const statusEvents = (Array.isArray(StatusList) ? StatusList : [])
            .map(status => ({
                eventName: status.eventName,
                label: status.label,
                value: status.value,
                available: status.available === undefined ? true : status.available,
                category: 'Status',
            })) || [];
    
        const uniqueStatusEvents = statusEvents.filter(statusEvent =>
            !eventTypeOptions.some(option => option.eventName === statusEvent.eventName)
        );
    
        return [...uniqueStatusEvents, ...filteredEvents, ...eventTypeFilteredOptions];
      }
    };
    
    

    const handlePixelIdChange = (event) => {
        const selectedId = event.target.value;
        setPixelIdError(!selectedId);
        setSelectedPixelId(selectedId);
        onSelectedPixelId(selectedId, account_id);

        setSelectedEventType('');
        setEventTypeError(true);
    };
    const handleEventTypeChange = (event) => {
        const selectedType = event.target.value;
        setEventTypeError(!selectedType);
        setSelectedEventType(selectedType);

        const selectedOption = event.target.selectedOptions[0]; // get the selected option
        const selectedEventOption = JSON.parse(selectedOption.getAttribute('data-option')); // parse the stored eventOption object

        let optionToSend = {};
        if(selectedEventOption?.category === 'Custom'){
            optionToSend = {
                pixel_rule: selectedEventOption?.pixel_rule,
                custom_conversion_id: selectedEventOption?.custom_conversion_id,
                custom_event_type: selectedEventOption?.value,
            }
        }else if (selectedEventOption?.category === 'Status'){
            optionToSend = {
                custom_event_type: selectedEventOption?.eventName,
                custom_event_str: selectedEventOption?.value,
            }
        }else{
            optionToSend = {
                custom_event_type: selectedEventOption?.value,
            }
        }

        if (selectedEventOption) {
            onSelectedEventType(optionToSend, account_id);
        }
    };


    const validationSchema = Yup.object({
        pixelName: Yup.string().required('Pixel name is required'),
    });

    const handleSubmit = async (values, actions) => {
        const payload = { 'name': values.pixelName };

        await createPixel({ account_id, payload }).unwrap()
            .then((response) => {
                if (response?.data) {
                    toast({
                        position: "top-right",
                        title: "Saved",
                        description: 'Pixel created',
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                if (error) (
                    toast({
                        position: "top-right",
                        title: "Error",
                        description: error?.message,
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    })
                );
            });
        actions.resetForm();
        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Pixel</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ pixelName: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <ModalBody>
                                    <FormControl id="pixel-name" isInvalid={errors.pixelName && touched.pixelName} isRequired>
                                        <FormLabel>Pixel Name</FormLabel>
                                        <Field
                                            name="pixelName"
                                            as={Input}
                                            placeholder="Enter pixel name"
                                        />
                                        {errors.pixelName && touched.pixelName ? (
                                            <div style={{ color: 'red.500', marginTop: '0.25rem' }}>{errors.pixelName}</div>
                                        ) : null}
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme="orange" mr={3} type="submit" size={'sm'}>
                                        Save
                                    </Button>
                                    <Button variant="ghost" onClick={onClose} size={'sm'}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>

            <Box mb={'10px'}>
                <Text fontWeight={'bold'} mb={2}>Ad Account: {name}</Text>
                {isPixelLoading ? (
                    <Flex>
                        <SkeletonText width="80%" noOfLines={2} skeletonHeight={2} />
                    </Flex>
                ) : pixelList?.length > 0 ? (
                    <Flex gap={'20px'} mb={2}>
                        <Box flex={1}>
                            <Flex alignItems={'center'} mb={'5px'}>
                                <Text htmlFor="pixel-id">Pixel</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text>
                                                <strong>About the pixel </strong> <br /> The pixel is a piece of code that you put on your website to measure the effectiveness of your ads. Choose the pixel that you want to use to track conversions.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>
                            <Select
                                id="pixel-id"
                                value={selectedPixelId}
                                placeholder="Select Pixel ID"
                                onChange={handlePixelIdChange}
                                size={'sm'}
                                borderRadius={'md'}
                                onBlur={onBlur}
                                isDisabled
                            >
                                {/* <option value="" disabled>Select Pixel ID</option> */}
                                {pixelList?.map((pixel) => (
                                    <option key={pixel.id} value={pixel.id}>
                                        {pixel.name}
                                    </option>
                                ))}
                            </Select>

                            {/* {pixelIdError && isSaving && !selectedPixelId && (
                                <Text color="red.500" fontSize="sm" mt={1}>
                                    Pixel ID is required.
                                </Text>
                            )} */}

                        </Box>

                        <Box flex={1}>
                            <Flex alignItems={'center'} mb={'5px'}>
                                <Text htmlFor="event-type">Conversion event</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text>
                                                Choose a conversion event associated with your Meta pixel. A conversion event is an action that you want People to take when they see your ads.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>

                            <Box>
                            {isCustomListLoading || isStatusListLoading ? (
                                <Flex justifyContent={'center'} alignItems={'center'} mt={2}>
                                    <SkeletonText width="100%" noOfLines={2}  skeletonHeight={2}/>
                                </Flex>
                            ) : (
                                <>
                                <Select
                                    id="event-type"
                                    value={selectedEventType}
                                    onChange={handleEventTypeChange}
                                    size={'sm'}
                                    isDisabled
                                    borderRadius={'md'}
                                >
                                    <option value="" disabled>Select Event Type</option>
                                    {filterEventsByPixel()?.map((eventOption, index) => {
                                        return (
                                            <option
                                                key={`${eventOption?.value}-${index}`}
                                                value={eventOption?.category === 'Custom' ? eventOption?.custom_conversion_id :  eventOption?.value}
                                                disabled={!eventOption?.available} // Disable option if it's not available
                                                data-option={JSON.stringify(eventOption)}
                                            >
                                                {eventOption?.label}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </>
                            )}
                            </Box>

                            {/* {eventTypeError && isSaving && (!selectedEventType || selectedEventType === '') && (
                                <Text color="red.500" fontSize="sm" mt={1}>
                                    Event type is required.
                                </Text>
                            )} */}
                        </Box>
                    </Flex>
                ) : (
                    <Flex gap={'10px'} >
                        <InfoOutlineIcon pointerEvents="all" color={'red.500'} />
                        <Text fontSize={'12px'} color={'red.500'}>The Meta pixel is required. To publish, set up your pixel or switch to a different optimisation, such as landing page views</Text>
                    </Flex>
                )}
            </Box >
        </>
    );
};

export default PixelConversionAdsetUpdateSelector;
