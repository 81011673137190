// AdSelection.js
import React, { useState, useEffect } from 'react';
import {
  Flex, Box, InputGroup, InputLeftElement, Input, Spinner, Checkbox, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, Button, Icon
} from '@chakra-ui/react';
import { SearchIcon } from "@chakra-ui/icons";
import { FiChevronsLeft, FiChevronLeft, FiChevronRight, FiChevronsRight } from 'react-icons/fi';
import { useGetAdsListQuery } from 'store/campaigns/adsApi';

const AdSelection = ({ adAccounts, onSelectedAdsChange, AdsData }) => {

  const [selectedAds, setSelectedAds] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);

  const { data: adsList, isLoading } = useGetAdsListQuery({
    currentPage,
    searchValue: debouncedSearchValue,
    adsAccountId: adAccounts?.adsAccountId,
    adset_id: ''
  }, { skip: !adAccounts?.adsAccountId });

  // console.log(adsList);


  useEffect(() => {
    if (AdsData && AdsData?.some(filter => filter?.field === "ad.id" && filter?.operator === "IN")) {
      const adIds = AdsData?.find(filter => filter?.field === "ad.id" && filter?.operator === "IN").value;
      const newSelectedAds = {};
      adIds?.forEach(adId => {
        newSelectedAds[adId] = true;
      });
      setSelectedAds(newSelectedAds);
    }
  }, [AdsData]);

  useEffect(() => {
    if (adsList) {
      setTotalPages(adsList?.meta?.totalPages);
    }
  }, [adsList]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    // Call the callback
    if (onSelectedAdsChange) {
      onSelectedAdsChange(selectedAds);
    }
  }, [selectedAds, onSelectedAdsChange]);

  const handleAdsSelection = (adId) => {
    setSelectedAds((prev) => ({
      ...prev,
      [adId]: !prev[adId],
    }));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  return (
    <Flex direction={'column'} p={4} gap={4} height="100vh">
      {/* Search Bar */}
      <InputGroup>
        <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
        <Input
          fontSize={'14px'}
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          mb={4}
        />
      </InputGroup>

      {/* Ads List */}
      <Flex direction={'column'}>
        {isLoading ? (
          <Flex justify="center" align="center" height="100%">
            <Spinner size="sm" />
          </Flex>
        ) : adsList?.data ? (
          Object.values(
            adsList.data.reduce((acc, ad) => {
              if (!acc[ad.campaign.id]) {
                acc[ad.campaign.id] = { campaign: ad.campaign, adsets: {} };
              }
              if (!acc[ad.campaign.id].adsets[ad.adset.id]) {
                acc[ad.campaign.id].adsets[ad.adset.id] = { adset: ad.adset, ads: [] };
              }
              acc[ad.campaign.id].adsets[ad.adset.id].ads.push(ad);
              return acc;
            }, {})
          ).map(({ campaign, adsets }, campaignIndex) => {
            const isCampaignSelected = Object.values(adsets).every(adset =>
              selectedAds[adset.adset.id] || adset.ads.every(ad => selectedAds[ad.id])
            );

            const handleCampaignChange = () => {
              const newSelectedAds = { ...selectedAds };
              const campaignAds = Object.values(adsets).flatMap(adset => adset.ads);

              if (isCampaignSelected) {
                campaignAds.forEach(ad => delete newSelectedAds[ad.id]);
              } else {
                campaignAds.forEach(ad => { newSelectedAds[ad.id] = true; });
              }
              setSelectedAds(newSelectedAds);
            };

            return (
              <Accordion allowToggle key={`campaign-${campaign.id}-${campaignIndex}`}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Checkbox colorScheme={'orange'} isChecked={isCampaignSelected} onChange={handleCampaignChange}>
                        {campaign.name || "Campaign name is not available"}
                      </Checkbox>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Accordion allowToggle>
                      {Object.values(adsets).map(({ adset, ads }, adsetIndex) => {
                        const isAdsetSelected = ads.every(ad => selectedAds[ad.id]);
                        const handleAdsetChange = () => {
                          const newSelectedAds = { ...selectedAds };
                          if (isAdsetSelected) {
                            ads.forEach(ad => delete newSelectedAds[ad.id]);
                          } else {
                            ads.forEach(ad => { newSelectedAds[ad.id] = true; });
                          }
                          setSelectedAds(newSelectedAds);
                        };

                        return (
                          <AccordionItem key={`adset-${adset.id}-${adsetIndex}`}>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                <Checkbox colorScheme={'orange'} isChecked={isAdsetSelected} onChange={handleAdsetChange}>
                                  {adset.name || "Adset name is not available"}
                                </Checkbox>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4} ml={2}>
                            <Flex direction="column">
                              {ads.map((ad, adIndex) => {

                                return (
                                  <Checkbox
                                    colorScheme="orange"
                                    key={`ad-${ad.id}-${adIndex}`}
                                    isChecked={!!selectedAds[ad.id]}
                                    onChange={() => handleAdsSelection(ad.id)}
                                  >
                                    {/* Flex container for image and name in a row */}
                                    <Flex align="center" direction="row" wrap="nowrap" p={2}>
                                      {/* Image */}
                                      {ad?.imageUrl &&
                                        <img
                                          src={ad?.imageUrl}
                                          alt={`${ad?.name}`}
                                          style={{
                                            width: '45px',
                                            height: '35px',
                                            objectFit: 'cover',
                                            border: '0.5px solid grey',
                                            marginRight: '10px',
                                          }}
                                        />
                                      }
                                      
                                      {/* Name */}
                                      <span>{ad.name || "Ad name is not available"}</span>
                                    </Flex>
                                  </Checkbox>
                                );
                              })}
                            </Flex>
                          </AccordionPanel>
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );
          })
        ) : (
          <Text>No ads available.</Text>
        )}
      </Flex>

      {/* Pagination */}
      {totalPages > 1 && (
        <Flex align={'center'} justify={['start', 'end']} gap={'5px'}>
          <Box mr={2} fontSize='sm'>
            Page{' '}
            <Text fontWeight={'bold'} display={'inline-flex'}>
              {currentPage} of {totalPages}
            </Text>
          </Box>

          <Button size={'xs'} onClick={() => setCurrentPage(1)} isDisabled={currentPage === 1}>
            <Icon boxSize={4} as={FiChevronsLeft} />
          </Button>

          <Button size={'xs'} onClick={handlePreviousPage} isDisabled={currentPage === 1}>
            <Icon boxSize={4} as={FiChevronLeft} />
          </Button>

          <Button size={'xs'} onClick={handleNextPage} isDisabled={currentPage >= totalPages}>
            <Icon boxSize={4} as={FiChevronRight} />
          </Button>

          <Button size={'xs'} onClick={() => setCurrentPage(totalPages)} isDisabled={currentPage >= totalPages}>
            <Icon boxSize={4} as={FiChevronsRight} />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AdSelection;
