import { api } from "../api";

export const adsetFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdsetForm: builder.query({
            query: ({ campaign_type }) => ({
                url: `/adsets/form`,
                params: { campaign_type },
            }),
            providesTags: ['AdsetForm'],
        }),
        getAdsetsList: builder.query({
            query: ({ currentPage, searchValue, adsAccountId, campaign_ids, adset_id, start_date, date_preset, end_date }) => {

              let url = `/adsets/${adsAccountId}?`;

              if (campaign_ids && campaign_ids.length > 0) {
                url += `campaign_ids=${JSON.stringify(campaign_ids)}&`;
              }
              if (adset_id) {
                url += `adset_id=${adset_id}&`;
              }
              if (currentPage) {
                url += `page=${currentPage}&`;
              }
              if (searchValue) {
                url += `search=${searchValue}&`;
              }
              if (start_date) {
                url += `start_date=${start_date}&`;
              }
              if (end_date) {
                url += `end_date=${end_date}&`;
              }
              if (date_preset) {
                url += `date_preset=${date_preset}&`;
              }
          
              // Remove the trailing "&" if it exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;
          
              return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),   
        getAdsets: builder.query({
            query: ({ businessId, adsetIds,}) => ({
                url: `/adsets/get/?businessId=${businessId}&adsetIds=${encodeURIComponent(JSON.stringify(adsetIds))}`, 
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
          }),              
        updateAdset: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adsets/${adsetId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        updateAdsetStatus: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adsets/update/status/${adsetId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        getAdsetPlacements: builder.query({
            query: ({ optimization_goal, objective, buying_type, billing_event, account_id, destination_type, effective_device_platforms, businessId }) => ({
                url: `/adsets/ad_campaign_placement`,
                params: {
                    optimization_goal,
                    objective,
                    buying_type,
                    billing_event,
                    account_id,
                    destination_type,
                    effective_device_platforms,
                    businessId
                },
            }),
        }),
        getAudienceEstimateList: builder.query({
            query: ({ adsAccountId, businessId, targeting_spec }) => ({
                url: `/adsets/reachestimate/${adsAccountId}?businessId=${businessId}&targeting_spec=${targeting_spec}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
        getConversionEvents: builder.query({
            query: ({ pixelId, businessId }) => ({
                url: `/adsets/stats/${pixelId}?businessId=${businessId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
    }),
});

export const {
    useGetAdsetFormQuery,
    useGetAdsetsListQuery,
    useGetAdsetsQuery,
    useUpdateAdsetMutation,
    useGetAdsetPlacementsQuery,
    useUpdateAdsetStatusMutation,
    useGetAudienceEstimateListQuery,
    useGetConversionEventsQuery
} = adsetFormApi;
