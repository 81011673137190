import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    SkeletonText,
    SkeletonCircle,
    HStack,
    Skeleton
} from '@chakra-ui/react';
import CarouselAdUpdateForm from 'components/genericForms/CarouselAdUpdateForm';
import SingleImageAdUpdateForm from 'components/genericForms/SingleImageAdUpdateForm';
import SingleVideoAdUpdateForm from 'components/genericForms/SingleVideoAdUpdateForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FiDelete, FiEdit2, FiEye } from "react-icons/fi";
import { useGetAdsListQuery, useUpdateAdStatusMutation, useGetAdComparisonQuery, useGetAdsInsightsQuery, useDeleteAdMutation } from 'store/campaigns/adsApi';
import moment from 'moment';
import { selectSelectedAdsetId, selectSelectedCampaignId, selectSelectedAdsId, setSelectedAdsId, selectAdsForInsights, selectAfter, setAfter, setBefore, selectBefore, selectCurrentPage, setCurrentPage, setAdsForInsights } from 'store/campaigns/campaignsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import currencySymbolMap from 'currency-symbol-map';
import AppTableWithPageMeta from 'components/AppTableWithPageMeta';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import { AddIcon } from '@chakra-ui/icons';
import CreateAdForm from './Forms/AdForms/CreateAdForm';

const call_to_action_options = [
    { value: 'SHOP_NOW', label: 'Shop Now' },
    { value: 'LEARN_MORE', label: 'Learn More' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
];

const call_to_action_options_instantForms = [
    { value: 'SUBSCRIBE', label: 'Subscribe' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
    { value: 'GET_OFFER', label: 'Get offer' },
    { value: 'GET_QUOTE', label: 'Get quote' },
    { value: 'DOWNLOAD', label: 'Download' },
    { value: 'BOOK_TRAVEL', label: 'Book now' },
    { value: 'SIGN_UP', label: 'Sign Up' },
];

const formatScore = (score) => {
    if (score === 0) {
        return '-';
    }
    return `${score.toFixed(2)}%`;
};

function AdsTable({ adsAccountData, startDate, endDate, datePreset, onRefresh, onRefreshComplete }) {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const selectedAdsId = useSelector(selectSelectedAdsId);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const AdsForInsights = useSelector(selectAdsForInsights);
    const after = useSelector(selectAfter);
    const before = useSelector(selectBefore);
    const currentPage = useSelector(selectCurrentPage);
    const currencySymbol = currencySymbolMap(adsAccountData?.currency);

    const { isOpen: isOpenComparison, onOpen: onOpenComparison, onClose: onCloseComparison } = useDisclosure();
    const isSearchDisabled = Boolean(selectedAdsId);
    const isAdsetSelected = Boolean(selectedAdsetId);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMounted = useRef(false);
    const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAd, setSelectedAd] = useState(null);
    const [switchStates, setSwitchStates] = useState({});
    const [adsListWithInsights, setAdsListWithInsights] = useState([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isLoadingAccountChange, setIsLoadingAccountChange] = useState(false);
    const [isLoadingAdsWithInsights, setIsLoadingAdsWithInsights] = useState(true);

    const { data: adsList, isFetching: isAdsListFetching, isLoading: isAdsListLoading, refetch: refetchAdsList, error: adsListError } = useGetAdsListQuery({
        pageSize: '20',
        ...(searchValue ? {} : { after, before }),
        searchValue,
        adsAccountId: adsAccountData?.account_id,
        adset_ids: selectedAdsetId ? [selectedAdsetId?.adset_id ? selectedAdsetId?.adset_id : selectedAdsetId] : [],
        campaign_ids: selectedCampaignId ? [selectedCampaignId?.campaign_id ? selectedCampaignId?.campaign_id : selectedCampaignId] : [],
        ad_ids: selectedAdsId ? [selectedAdsId] : [],
    }, { skip: !adsAccountData?.account_id });

    const { data: adsInsights, refetch: refetchAdsInsights, isLoading: isAdsInsightsLoading, error: AdsInsightsError } = useGetAdsInsightsQuery({
        searchValue,
        adsAccountId: adsAccountData?.account_id,
        ...(datePreset ? {} : { start_date: startDate, end_date: endDate }), // Conditionally add startDate and endDate
        date_preset: datePreset,
        ads: AdsForInsights?.length > 0 ? AdsForInsights : [],
    }, { skip: !adsAccountData?.account_id || AdsForInsights?.length === 0 });

    const shouldFetchComparison = selectedAd?.hashes && selectedAd?.hashes.length > 0;

    const { data: adsComparison, isLoading: isComparisonLoading, refetch: comparisonRefetch } = useGetAdComparisonQuery(
        {
            hashes: selectedAd?.hashes ? selectedAd?.hashes : '',
        },
        { skip: !selectedAd || !shouldFetchComparison || !isOpenComparison }
    )

    const [fetchedComparison, setfetchedComparison] = useState(null)

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);
    
    useEffect(() => {
        if (adsComparison?.length > 0) {
            setfetchedComparison(adsComparison)
        }
    }, [adsComparison])

    useEffect(() => {
        if (selectedAd?.hashes && selectedAd?.hashes.length > 0 && isOpenComparison) {
            comparisonRefetch();
        }
    }, [selectedAd, isOpenComparison])

    const [updateAdStatus, { isLoading: isAdStatusUpdating }] = useUpdateAdStatusMutation({});
    const [deleteAd] = useDeleteAdMutation();



    useEffect(() => {
        if (adsAccountData?.account_id) {
            setIsLoadingAccountChange(true);
            refetchAdsList();
        }
    }, [adsAccountData?.account_id]);

    useEffect(() => {
        if (onRefresh) {
            refetchAdsList();
            onRefreshComplete();
        }
    }, [onRefresh, refetchAdsList]);

    useEffect(() => {
        if (adsList?.data) {
            const initialSwitchStates = {};
            adsList.data.forEach(ad => {
                initialSwitchStates[ad?.id] = ad.status;
            });
            setSwitchStates(initialSwitchStates);

            const formattedAds = adsList?.data.reduce((acc, ad) => {

                const promotedObject = ad?.adset?.promoted_object;
                const customEventType = promotedObject?.custom_event_type;

                // Only add campaigns to the list if custom_event_type is present
                if (ad?.id && customEventType) {
                    const adData = {
                        id: ad?.id,
                        promoted_object: { custom_event_type: customEventType },
                        destination_type: ad?.adset?.destination_type,
                        optimization_goal: ad?.adset?.optimization_goal
                    };

                    if (ad?.cpa) {
                        adData.cpa = ad?.cpa;
                    }

                    acc.push(adData);

                } else {
                    const adData = {
                        id: ad?.id,
                        destination_type: ad?.adset?.destination_type,
                        optimization_goal: ad?.adset?.optimization_goal
                    };

                    if (ad?.cpa) {
                        adData.cpa = ad?.cpa;
                    }

                    acc.push(adData);
                }
                return acc;
            }, []);

            dispatch(setAdsForInsights(formattedAds));
        }
    }, [adsList]);

    useEffect(() => {
        if (adsList?.data || adsInsights) {

            const adsWithInsights = adsList?.data.map(ad => {
                const insightData = adsInsights?.find(insight => insight?.ad_id === ad?.id);

                return {
                    ...ad,
                    insight: insightData?.insights || {},
                };
            });
            setAdsListWithInsights(adsWithInsights);

            setTimeout(() => {
                setIsLoadingAdsWithInsights(false);
            }, 100);
        }
    }, [adsList, adsInsights]);

    useEffect(() => {
        if (adsList?.data || adsInsights) {
            setIsLoadingAccountChange(false);
        }
    }, [adsList, adsInsights]);


    const handleSwitchChange = (adId, currentState) => {

        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adId]: newStatus }));
        updateAdStatus({
            adId,
            payload: {
                status: newStatus,
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
            }
        }).unwrap()
            .then(() => {
                if (isMounted.current) {
                    refetchAdsList();
                    toast({
                        position: "top-right",
                        title: `Ad ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    toast({
                        position: "top-right",
                        title: `Error updating Ad status`,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                }
                console.error('Error updating Ad status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adId]: currentState }));
            });
    };

    const handlePageChange = ({ after, before, currentPage }) => {
        if (after) {
            dispatch(setAfter(after));
            dispatch(setBefore(''));
        } else if (before) {
            dispatch(setBefore(before));
            dispatch(setAfter(''));
        }

        dispatch(setCurrentPage(currentPage));
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set('page_id', currentPage);

        navigate({ search: params.toString() }, { replace: true });

    }, [currentPage]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const storedPage = params.get('page_id');

        if (storedPage && !isNaN(storedPage)) {
            const parsedPage = parseInt(storedPage, 10);
            if (parsedPage >= 0 && parsedPage !== currentPage) {
                dispatch(setCurrentPage(parsedPage));
            }
        } else if (currentPage !== 0) {
            dispatch(setCurrentPage(0));
        }
    }, [location.search]);

    const handleDeleteAd = (adData) => {

        deleteAd({ adId: adData?.id, adsAccountId: adsAccountData?.account_id, })
            .unwrap()
            .then(response => {
                refetchAdsList();

                toast({
                    position: "top-right",
                    title: "Ad deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting ad",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    const isActive = switchStates[original?.id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad is ON' : 'Ad is OFF';

                    return (
                        <Flex gap={'10px'} alignItems={'center'} justifyContent={'center'}>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original?.id, original.status)}
                                        isDisabled={userInfo?.role !== 'client'}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isSelected = selectedAdsId === original?.id;

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            {original?.imageUrl ? (
                                <img
                                    src={original?.imageUrl}
                                    alt={`${original?.name} thumbnail`}
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        minWidth: '45px',
                                        border: '0.5px solid grey',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <img
                                    src={original?.imageUrl}
                                    alt={' '}
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        minWidth: '45px',
                                        border: '0.5px solid grey',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"}
                                        color={userInfo?.role === 'client' ? "inherit" : "gray.300"}
                                        onClick={() => {
                                            if (userInfo?.role === 'client') {
                                                setSelectedAd(original);
                                                onOpen();
                                            }
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                            <Flex gap={'10px'} alignItems={'center'}>
                                <Text
                                    // _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    fontWeight={isSelected ? 'bold' : 'normal'}
                                    color={isSelected ? 'orange.500' : 'inherit'}
                                    fontSize={'14px'}
                                >
                                    {original.name.length > 30 ? `${original.name.slice(0, 30)}...` : original.name}
                                </Text>
                            </Flex>
                        </Flex>
                    )
                },
            },
            {
                Header: "Delivery",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {


                    const getStatusMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return 'Delivery error';
                            case 'CAMPAIGN_PAUSED':
                                return 'Campaign Off';
                            case 'ADSET_PAUSED':
                                return 'Adset Off';
                            case 'PAUSED':
                                return 'Off';
                            case 'IN_PROCESS':
                                return 'Processing';
                            case 'PENDING_REVIEW':
                                return 'In review';
                            default:
                                return status || 'Unknown';
                        }
                    };

                    const errorMessage = original?.issues_info?.[0]?.error_message || '';

                    // Function to determine circle color and border based on status
                    const getCircleStyles = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return { bg: 'rgb(161,11,24)', border: 'none' };  // Red for delivery error
                            case 'CAMPAIGN_PAUSED':
                            case 'ADSET_PAUSED':
                                return { bg: 'rgb(216,221,225)', border: 'none' };  // Gray for Campaign/Adset Off
                            case 'ACTIVE':
                                return { bg: 'rgb(255,255,255)', border: '2px solid green' };  // Gray background with green border for ACTIVE
                            case 'IN_PROCESS':
                            case 'PENDING_REVIEW':
                                return { bg: 'rgb(255,255,255)', border: '2px solid green' };
                            default:
                                return { bg: 'rgb(255,255,255)', border: 'none' };  // Default gray with no border
                        }
                    };

                    const circleStyles = getCircleStyles(original?.effective_status);

                    const formatTooltipMessage = (message) => {
                        return message.split('\n').map((part, index) => (
                            <span key={index}>
                                {part}
                                <br />
                            </span>
                        ));
                    };

                    // Determine tooltip message
                    const getTooltipMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return errorMessage;
                            case 'PAUSED':
                                return 'This ad is turned off.';
                            case 'ACTIVE':
                                return 'This campaign is on and running normally.';
                            case 'ADSET_PAUSED':
                                return 'This ad is on, but its ad set is turned off.';
                            case 'CAMPAIGN_PAUSED':
                                return 'This ad set is on, but its campaign is turned off.';
                            case 'IN_PROCESS':
                                return 'Your ad is processing.\nMeta is processing your recent updates to your ad. Some updates may take several hours.';
                            default:
                                return '';  // No tooltip for other statuses
                        }
                    };

                    const tooltipMessage = getTooltipMessage(original?.effective_status);

                    return (
                        <Flex>
                            {/* Only show Tooltip when status is WITH_ISSUES */}
                            {original?.effective_status === 'WITH_ISSUES' ? (
                                <Flex alignItems={'center'}>
                                    <Box
                                        bg={circleStyles.bg}
                                        border={circleStyles.border}
                                        borderRadius="50%"
                                        boxSize="0.8rem"
                                        display="inline-block"
                                        mr={2}
                                    />
                                    <Tooltip label={formatTooltipMessage(tooltipMessage)} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                        <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                    </Tooltip>
                                </Flex>
                            ) : (
                                <Flex alignItems={'center'}>
                                    {(original?.effective_status === 'PAUSED' || original?.effective_status === 'ARCHIVED') ? (null) : (
                                        <Box
                                            bg={circleStyles.bg}
                                            border={circleStyles.border}
                                            borderRadius="50%"
                                            boxSize="0.8rem"
                                            display="inline-block"
                                            mr={2}
                                        />
                                    )}
                                    {/* Skip Tooltip for PENDING_REVIEW */}
                                    {original?.effective_status !== 'PENDING_REVIEW' && (
                                        <Tooltip label={formatTooltipMessage(tooltipMessage)} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                            <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                        </Tooltip>
                                    )}
                                    {original?.effective_status === 'PENDING_REVIEW' && (
                                        <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    )
                }
            },
            {
                Header: "Comparison",
                accessor: "Comparison",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex align={'center'} justifyContent={'center'}>
                        <Tooltip label="View Comparison" fontSize='xs' >
                            <Flex>
                                <Icon
                                    as={FiEye}
                                    cursor="pointer"
                                    onClick={() => {
                                        setSelectedAd(original);
                                        onOpenComparison();
                                    }}
                                    _hover={{ color: "gray.500" }}
                                />
                            </Flex>
                        </Tooltip>
                    </Flex>
                ),
            },
            {
                Header: "Budget",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const { daily_budget, lifetime_budget } = original?.adset;

                    const displayLifetimeBudget = Number(lifetime_budget) > 0 ? (Number(lifetime_budget) / 100).toFixed(2) : null;
                    const displayDailyBudget = Number(daily_budget) > 0 ? (Number(daily_budget) / 100).toFixed(2) : null;

                    // Determine which budget to display
                    const isLifetimeBudget = displayLifetimeBudget !== null;
                    const displayBudget = isLifetimeBudget
                        ? `${currencySymbol}${displayLifetimeBudget}`
                        : (displayDailyBudget !== null
                            ? `${currencySymbol}${displayDailyBudget}`
                            : "No Budget");
                    const budgetType = isLifetimeBudget ? "Lifetime" : (displayDailyBudget !== null ? "Daily" : "");

                    return (
                        <Flex direction={'column'} mb={-4}>
                            <Text fontSize={'14px'}>{displayBudget}</Text>
                            {budgetType && <Text fontSize={'10px'} color={'gray.500'}>{budgetType}</Text>}
                        </Flex>
                    );
                }
            },
            {
                Header: "Spend",
                accessor: "spend",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'14px'}>{original.insight?.spend ? `${currencySymbol}${Number(original.insight.spend).toFixed(2)}` : '-'}</Text>
                    )

                },
            },
            {
                Header: "Breakeven CPA",
                accessor: "cpa",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'} fontWeight={'bold'}>{original.cpa ? `${currencySymbol}${Number(original.cpa).toFixed(2)}` : '-'}</Text>
                ),
            },
            {
                Header: "Cost per result",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Box>
                        <Text fontSize={'14px'} fontWeight={'bold'}>{original.insight?.cpr ? `${currencySymbol}${Number(original.insight.cpr).toFixed(2)}` : '-'}</Text>
                    </Box>
                )
            },
            {
                Header: "Scoring",
                accessor: "score",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const score = original.insight?.score || 0;
                    return <Text fontSize={'14px'} fontWeight={'bold'}>{formatScore(score)}</Text>;
                },
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex direction={'column'} mb={-4}>
                        <Text fontSize={'14px'}>{original.insight?.results ? original.insight?.results : '-'}</Text>
                        <Text fontSize={'10px'} color={'gray.500'}>{original.insight?.eventName ? (original.insight?.eventName) : null}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.cpc ? `${currencySymbol}${Number(original.insight?.cpc).toFixed(2)}` : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original.insight?.ctr ? `${Number(original.insight?.ctr).toFixed(2)}%` : '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    return (
                        <Flex gap={'10px'}>
                            <Text fontSize={'14px'}>{original.insight?.cpm ? Number(original.insight?.cpm).toFixed(2) : '-'}</Text>
                        </Flex>
                    )
                }
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.clicks ? original.insight?.clicks : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original.insight?.purchaseValue && original.insight.purchaseValue !== '-' ?
                                `${currencySymbol}${parseFloat(original.insight.purchaseValue).toFixed(2)}` :
                                original.insight?.purchaseValue || '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const roas = original.insight?.roas;

                    // Check if roas is a valid number or a non-numeric value like '-'
                    const formattedROAS = (roas && !isNaN(parseFloat(roas)))
                        ? parseFloat(roas).toFixed(2)
                        : '-';  // Default to '-' if roas is invalid or non-numeric

                    return (
                        <Flex>
                            <Text fontSize={'14px'}>
                                {formattedROAS}
                            </Text>
                        </Flex>
                    );
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text fontSize={'14px'}>{original.status}</Text>
                    )

                },
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'}>{moment(original.created_time).format("DD-MM-YYYY hh:mm A")}</Text>

                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'}>{moment(original.updated_time).format("DD-MM-YYYY hh:mm A")}</Text>

                ),
            },
            {
                Header: "Actions",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'} alignItems={'center'}>
                        <Tooltip label='Delete' fontSize='xs' >
                            <Flex>
                                <Icon
                                    _hover={{ color: 'gray.400' }}
                                    as={FiDelete}
                                    cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"}
                                    color={userInfo?.role === 'client' ? "red.500" : "gray.300"}
                                    onClick={() => {
                                        if (userInfo?.role === 'client') {
                                            setSelectedAd(original);
                                            setIsConfirmationModalOpen(true);
                                        }
                                    }}
                                />
                            </Flex>
                        </Tooltip>
                    </Flex>
                )
            },
        ],
        [selectedAdsId, adsAccountData]
    );

    const confirmDelete = () => {
        if (selectedAd) {
            handleDeleteAd(selectedAd);
            setIsConfirmationModalOpen(false);
            setSelectedAd(null);
        }
    };

    const handleAdUpdateSuccess = () => {
        refetchAdsList();
        dispatch(setAfter(''));
        dispatch(setBefore(''));
        dispatch(setCurrentPage(0));
        localStorage.removeItem('after')
        localStorage.removeItem('before')
        localStorage.removeItem('currentPage')
    }


    const handleOnSuccess = () => {
        refetchAdsList();
    }

    return (
        <>
            <Flex mb={2} alignItems="center">
                <Tooltip
                    p={2}
                    label="To create an Ad, please select an Ad set from the Ad sets tab."
                    isDisabled={isAdsetSelected}
                    placement="right"
                    borderRadius={'sm'}
                    fontSize={'12px'}
                >
                    <Box
                        as="button"
                        display="inline-flex"
                        alignItems="center"
                        bg={!isAdsetSelected ? "#F8C9C3" : "orange.300"}
                        color={"white"}
                        borderRadius="md"
                        fontWeight={'bold'}
                        px={3}
                        py={1.5}
                        fontSize={'14px'}
                        onClick={() => {
                            if (isAdsetSelected) {
                                setIsCreateFormVisible(true);
                            }
                        }}
                        _hover={{ cursor: isAdsetSelected ? "pointer" : "not-allowed" }}
                        _disabled={{ bg: "gray.300", cursor: "not-allowed" }}
                    >
                        <AddIcon boxSize={3} />
                        &nbsp;Create
                    </Box>
                </Tooltip>
            </Flex>


            {isCreateFormVisible && (
                <CreateAdForm
                    adsAccountData={adsAccountData}
                    isOpen={isCreateFormVisible}
                    onClose={() => setIsCreateFormVisible(false)}
                    onSuccess={handleOnSuccess}
                />
            )}

            <DeleteConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmDelete}
                message="Are you sure you want to delete this Ad? This action can't be undone."
            />

            {/* Comparison Modal */}
            <Modal
                size={"6xl"}
                isCentered={true}
                isOpen={isOpenComparison}
                onClose={() => {
                    onCloseComparison();
                    setSelectedAd(null);
                    setfetchedComparison(null);
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ad Comparison</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody maxHeight="500px" overflowY="auto">
                        {isComparisonLoading ? (
                            <Flex justifyContent={'center'} alignItems={'center'} gap={5} h={'10vh'}>
                                <SkeletonCircle size="10" />
                                <SkeletonText height="5" width="80%" mb={4} />
                            </Flex>
                        ) : (
                            fetchedComparison && fetchedComparison?.length > 0 ? (
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>OFF/ON</Th>
                                            <Th>Ad Account</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Campaign Name</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Ad Name</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Spend</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Breakeven CPA</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Cost per result</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }} >Scoring</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Results</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>CPC</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>CTR</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>CPM</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Clicks</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Purchase Value</Th>
                                            <Th sx={{ whiteSpace: 'nowrap' }}>Roas</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {fetchedComparison.map((ad, index) => {

                                            const adStatus = adsList?.data.find(adItem => adItem?.id === ad?.ad?.ad_id)?.status;

                                            return (
                                                <Tr key={index}>
                                                    <Td>
                                                        <Flex gap={'10px'} align={'center'}>
                                                            <Tooltip label={adStatus === 'ACTIVE' ? 'Ad is ON' : 'Ad is OFF'} fontSize='xs'>
                                                                <FormControl width={'fit-content'}>
                                                                    <Switch
                                                                        colorScheme='orange'
                                                                        size={'sm'}
                                                                        isChecked={adStatus === 'ACTIVE'}
                                                                        onChange={() => handleSwitchChange(ad?.ad?.ad_id, adStatus)}
                                                                        isDisabled={userInfo?.role !== 'client'}
                                                                    />
                                                                </FormControl>
                                                            </Tooltip>
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <Box>
                                                            <Text fontSize={'14px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.adsAccount?.adsAccountName || 'N/A'}</Text>
                                                            <Text fontSize={'12px'} color={'gray.500'} sx={{ whiteSpace: 'nowrap' }}>{ad?.adsAccount?.adsAccountId || 'N/A'}</Text>
                                                        </Box>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.campaign?.name || 'N/A'}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Flex direction={'row'} alignItems={'center'}>
                                                            <img
                                                                src={ad?.creative?.thumbnail_url}
                                                                alt={`${ad?.ad?.name}`}
                                                                style={{
                                                                    width: '45px',
                                                                    minWidth: '45px',
                                                                    height: '30px',
                                                                    objectFit: 'cover',
                                                                    border: '0.5px solid grey',
                                                                    marginRight: '10px',
                                                                }}
                                                            />
                                                            <Text fontSize={'14px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.ad?.name || 'N/A'}</Text>
                                                        </Flex>
                                                    </Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }} >{ad?.spend ? `${currencySymbol}${Number(ad?.spend).toFixed(2)}` : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: '14px' }}>{ad?.cpa ? Number(ad?.cpa).toFixed(2) : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: '14px' }}>{ad?.cpr ? `${currencySymbol}${Number(ad?.cpr).toFixed(2)}` : '-'}</Td>
                                                    <Td>
                                                        <Text sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: '14px' }}>{formatScore(ad?.score || 0)}</Text>
                                                    </Td>


                                                    <Td>
                                                        <Box>
                                                            <Text sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.results ? ad?.results : '-'}</Text>
                                                            <Text fontSize={'12px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.eventName ? ad?.eventName : null}</Text>
                                                        </Box>
                                                    </Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.cpc ? `${currencySymbol}${Number(ad.cpc).toFixed(2)}` : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.ctr ? `${Number(ad?.ctr).toFixed(2)}%` : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.cpm ? Number(ad?.cpm).toFixed(2) : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.clicks ? Number(ad?.clicks).toFixed(2) : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.purchaseValue ? `${currencySymbol}${Number(ad?.purchaseValue).toFixed(2)}` : '-'}</Td>
                                                    <Td sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{ad?.roas ? Number(ad?.roas).toFixed(2) : '-'}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            ) : (
                                <Flex justifyContent={'center'} alignItems={'center'} gap={5} h={'10vh'}>
                                    <Text>No comparison data available.</Text>
                                </Flex>
                            )
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* EDIT AD MODAL */}
            <Modal
                size={"6xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedAd?.adcreative?.object_story_spec ? (
                            selectedAd?.adcreative?.object_story_spec.link_data ? (
                                selectedAd?.adcreative?.object_story_spec.link_data.child_attachments ? (
                                    <CarouselAdUpdateForm
                                        adData={selectedAd}
                                        onSuccess={handleAdUpdateSuccess}
                                        adsAccountData={adsAccountData}
                                        onClose={onClose}
                                        call_to_action_options={call_to_action_options}
                                        call_to_action_options_instantForms={call_to_action_options_instantForms}
                                    />
                                ) : (
                                    <SingleImageAdUpdateForm
                                        adData={selectedAd}
                                        adsAccountData={adsAccountData}
                                        onSuccess={handleAdUpdateSuccess}
                                        call_to_action_options={call_to_action_options}
                                        call_to_action_options_instantForms={call_to_action_options_instantForms}
                                        onClose={onClose}
                                    />
                                )
                            ) : selectedAd?.adcreative?.object_story_spec.video_data ? (
                                <SingleVideoAdUpdateForm
                                    adData={selectedAd}
                                    adsAccountData={adsAccountData}
                                    onSuccess={handleAdUpdateSuccess}
                                    call_to_action_options={call_to_action_options}
                                    call_to_action_options_instantForms={call_to_action_options_instantForms}
                                    onClose={onClose}
                                />
                            ) : (
                                <Text>No data available for the selected ad.</Text>
                            )
                        ) : (
                            <Text>No data available for the selected ad.</Text>
                        )}

                    </ModalBody>
                </ModalContent>
            </Modal>
            {adsListError ? (
                <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                    <Text color="red.500">
                        {/* {"Sorry, Ads not available at this moment. Try again later."} */}
                        {adsListError?.data?.message}
                    </Text>
                </Flex>
            ) : (isAdsListLoading || isAdsInsightsLoading || isLoadingAdsWithInsights) ? (
                <Spinner mt="20px" />
            ) : (
                <AppTableWithPageMeta
                    columns={columns}
                    data={adsListWithInsights || []}
                    searchEnabled={!isSearchDisabled}
                    isFetching={isAdsListFetching}
                    initialState={{ pageIndex: currentPage }}
                    pageMeta={adsList?.meta || {}}
                    onPageChange={handlePageChange}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
            )}
        </>
    );
}

export default AdsTable;
