import { useEffect, useState, useMemo } from "react";
import { useSelector } from 'react-redux';
import {
  Flex, Text, Box, Select, Button, useToast,
  Switch,
  FormControl,
  Tooltip,
  Icon,
  useDisclosure,
  Spinner
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import SidebarWithHeader from 'components/SidebarWithHeader';

import AppTableWithPagination from "components/AppTableWithPagination";
import CreateRuleForm from "components/adRules/CreateRuleForm";
import { useGetAdRulesQuery, useUpdateAdRuleStatusMutation, useDeleteAdRuleMutation } from "store/adRules/adRuleApi";
import moment from 'moment';
import { FiDelete, FiEdit2 } from "react-icons/fi";
import DeleteConfirmationModal from "components/genericForms/DeleteConfirmationModal";
import UpdateRuleForm from "components/adRules/UpdateRuleForm";

function AdRules() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setsearchValue] = useState('');
  const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
  const [selectedAccountData, setSelectedAccountData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchStates, setSwitchStates] = useState({});
  const [selectedAdRule, setselectedAdRule] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount)
  const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
  let firstBMID = activeAccountsData?.data[0]?.businessAccount?.businessId;
  //console.log({ firstBMID });

  const { data: adRulesList, isFetching, refetch, isLoading } = useGetAdRulesQuery({
    currentPage,
    searchValue,
    adsAccountId: selectedAdsAccountId,
    BMID: firstBMID
  }, { skip: !selectedAdsAccountId });
  

  const [updateAdRuleStatus] = useUpdateAdRuleStatusMutation({});
  const [deleteAdRule] = useDeleteAdRuleMutation();


  useEffect(() => {

    if (searchValue !== '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  useEffect(() => {
    if (activeAccountsData?.data?.length > 0) {
      setSelectedAdsAccountId(activeAccountsData.data[0].adsAccountId);
      setSelectedAccountData(activeAccountsData.data[0]);
    }
  }, [activeAccountsData]);

  useEffect(() => {
    if (activeAccountsData?.data?.length > 0) {
      const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
      setSelectedAccountData(account);
    }
  }, [selectedAdsAccountId, activeAccountsData]);

  useEffect(() => {
    if (adRulesList?.data) {
      const initialSwitchStates = {};
      adRulesList.data.forEach(adRule => {
        initialSwitchStates[adRule.adrule_id] = adRule.status;
      });
      setSwitchStates(initialSwitchStates);
    }
  }, [adRulesList]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSuccess = () => refetch();

  const handleSwitchChange = (AdRuleId, currentState) => {

    const newStatus = currentState === 'ENABLED' ? 'DISABLED' : 'ENABLED';

    setSwitchStates(prev => ({ ...prev, [AdRuleId]: newStatus }));
    updateAdRuleStatus({
      AdRuleId,
      payload: { status: newStatus }
    }).unwrap()
      .then(() => {
        refetch();
        toast({
          position: "top-right",
          title: `Ad Rule ${newStatus === 'ENABLED' ? 'Enabled' : 'Disabled'}`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: `Error updating campaign status`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        console.error('Error updating campaign status:', error);

        // Revert switch state if API call fails
        setSwitchStates(prev => ({ ...prev, [AdRuleId]: currentState }));
      });
  };

  const handleDeleteAdRule = (AdRuleId) => {

    deleteAdRule(AdRuleId?.adrule_id)
      .unwrap()
      .then(response => {
        refetch();
        toast({
          position: "top-right",
          title: "Ad Rule deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad Rule",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Rule name",
        accessor: "Rule name",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {

          return (
            <Flex gap={'10px'} align={'center'}>
              <Tooltip label={''} fontSize='xs' >
                <FormControl width={'fit-content'}>
                  <Switch
                    colorScheme='orange'
                    size={'sm'}
                    isChecked={original.status === 'ENABLED'}
                    onChange={() => handleSwitchChange(original.adrule_id, original.status)}
                  />
                </FormControl>
              </Tooltip>
              <Text>{original.name}</Text>
            </Flex>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{original.status === "ENABLED" ? "Enabled" : "Disabled"}</Text>
        ),
      },
      {
        Header: "Applied to",
        accessor: "Applied to",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const entityTypeFilter = original.filters.find(filter => filter.field === "entity_type");
          return (
            <Text>
              {entityTypeFilter?.value ? "All active ads" : null}
            </Text>
          );
        },
      },
      {
        Header: "Action & condition",
        accessor: "Action & condition",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{original?.execution_type === "PAUSE" ? "Turn off ads" : "Turn on ads"}</Text>
        ),
      },
      {
        Header: "Rule results",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const ruleResults = original.ruleResult || [];

          const result = ruleResults.length === 0
            ? <Text>Never</Text>
            : (
              <Box>
                <Text>will start on</Text>
                <Text fontSize={'12px'}>{moment(ruleResults[0].timestamp).format("DD-MM-YYYY")}</Text>
              </Box>
            );

          return <Box>{result}</Box>;
        }
      },
      {
        Header: "Created by",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Box gap={'10px'}>
            <Text>{original.created_by?.name}</Text>
            <Text fontSize={'12px'}>{moment(original.createdDate).format("DD-MM-YYYY")}</Text>
          </Box>
        )
      },
      {
        Header: "Actions",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Flex gap={'10px'} alignItems={'center'}>
            <Tooltip label='Edit' fontSize='xs' >
              <Flex>
                <Icon
                  _hover={{ color: "gray.500" }}
                  as={FiEdit2}
                  cursor={"pointer"}
                  onClick={() => {
                    setselectedAdRule(original);
                    onOpen();
                  }}
                />
              </Flex>
            </Tooltip>
            <Tooltip label='Delete' fontSize='xs' >
              <Flex>
                <Icon
                  color={'red.500'}
                  _hover={{ color: 'gray.400' }}
                  as={FiDelete}
                  cursor={"pointer"}
                  onClick={() => {
                    setselectedAdRule(original);
                    setIsConfirmationModalOpen(true);
                  }}
                />
              </Flex>
            </Tooltip>
          </Flex>
        )
      },
    ],
    []
  );

  const confirmDelete = () => {
    if (selectedAdRule) {
      handleDeleteAdRule(selectedAdRule);
      setIsConfirmationModalOpen(false);
      setselectedAdRule(null);
    }
  };

  return (
    <>
    {hasActiveAdAccount ? (
         <SidebarWithHeader>
         <>
           <DeleteConfirmationModal
             isOpen={isConfirmationModalOpen}
             onClose={() => setIsConfirmationModalOpen(false)}
             onConfirm={confirmDelete}
             message="Are you sure you want to delete this rule? This action can't be undone."
           />
   
           <Flex direction={'column'} gap={4}>
   
             <Flex gap={'10px'} flex={1} align={'center'} mb={4}>
               <Text fontWeight={'bold'}>Ad accounts</Text>
               <Box flex={0.4}>
                 <Select
                   value={selectedAdsAccountId}
                   onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                   size={'sm'}
                   borderRadius={'md'}
                 >
                   {activeAccountsData?.data?.map(account => (
                     <option key={account._id} value={account.adsAccountId}>
                       {`${account.adsAccountName} (${account.adsAccountId})`}
                     </option>
                   ))}
                 </Select>
               </Box>
             </Flex>
   
             <Flex>
               <Button colorScheme="orange" onClick={openModal}>
                 <AddIcon boxSize={3} />
                 &nbsp;Create Rule
               </Button>
             </Flex>
              
             {isLoading ? (
                <Spinner mt="20px" />
                ) : (
             <AppTableWithPagination
               columns={columns}
               data={adRulesList?.data || []}
               paginationMeta={adRulesList?.meta || {}}
               onPageChange={(index) => setCurrentPage(index)}
               onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
               searchEnabled={true}
               isFetching={isFetching}
             />
            )}
           </Flex>
   
   
           <CreateRuleForm isOpen={isModalOpen} onClose={closeModal} adAccounts={selectedAccountData} onSuccess={handleSuccess} />
           <UpdateRuleForm isOpen={isOpen} onClose={onClose} adAccounts={selectedAccountData} onSuccess={handleSuccess} ruleData={selectedAdRule} />
   
         </>
       </SidebarWithHeader>
    ) : (
      <SidebarWithHeader>
      <Flex>
        <Text>
          Your Ad account is not set up yet. Please request an Ad account.
        </Text>
      </Flex>
      </SidebarWithHeader>
    )}
    </>
  )
}

export default AdRules