import { AddIcon, CloseIcon, SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    Heading,
    Icon,
    IconButton,
    Image,
    Input, InputGroup,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from '@chakra-ui/react';
import Pagination from 'components/drive/PaginationForDrive';
import SidebarWithHeader from 'components/SidebarWithHeader';
import UploadModal from 'components/StatusUploadModal';
import { useEffect, useRef, useState } from 'react';
import { FaPlayCircle } from "react-icons/fa";
import { FiEye, FiGrid, FiList } from 'react-icons/fi';
import { MdRefresh } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useFetchImagesQuery, useFetchVideosQuery } from 'store/driveUpload/uploadApi';

const DriveForAdmin = () => {

    const inputRef = useRef();
    const [showError, setShowError] = useState(false);
    const [view, setView] = useState('grid');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [previewFile, setPreviewFile] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
    const [selectedAdAccount, setSelectedAdAccount] = useState('');
    const [selectedTab, setSelectedTab] = useState('images');
    const userActiveAdaccounts = useSelector((state) => state.auth?.activeAdAccounts);
    const [mediaType, setMediaType] = useState('');
    
    const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
    
    const { data: images, isLoading: isImagesDataLoading, isFetching: isImagesFetching, refetch: refetchImages } = useFetchImagesQuery(
        { currentPage, searchValue: debouncedSearchValue, adsAccountId: selectedAdAccount?.adsAccountId },
        { skip: !selectedAdAccount?.adsAccountId || selectedTab !== 'images' }
    );

    const { data: videos, isLoading: isVideosDataLoading, refetch: refetchVideos, isFetching: isVideosFetching } = useFetchVideosQuery(
        { currentPage, searchValue: debouncedSearchValue, adsAccountId: selectedAdAccount?.adsAccountId },
        { skip: !selectedAdAccount?.adsAccountId || selectedTab !== 'videos' }
    );

    const handleMediaTypeChange = (value) => {
        setMediaType(value);
        if (value) {
            onOpen();
        }
    };

    const handleRefresh = () => {
        if (selectedTab === 'images') {
            refetchImages();
        } else {
            refetchVideos();
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 800);

        return () => {
            clearTimeout(handler); // Cleanup timeout if searchValue changes before 800ms
        };
    }, [searchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2 || searchValue?.length === "") {
                setSearchValue(searchValue);
            }
        }, 4000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])

    useEffect(() => {
        if (searchValue.trim() === '') {
            setSearchValue('');
            setShowError(false);
            return;
        }

        const hasResults = (
            (selectedTab === 'images' && images?.data?.some(file => file.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
            (selectedTab === 'videos' && videos?.data?.some(file => file.name.toLowerCase().includes(searchValue.toLowerCase())))
        );

        setShowError(!hasResults);
    }, [searchValue, images, videos, selectedTab]);


    const handleFileClick = (file) => {
        if (file) {
            setPreviewFile({
                ...file,
                type: file.creativeType,
                value: file.creativeType === 'image' ? file.url : file.source,
            });
            setIsPreviewOpen(true);
        }
    };
    //console.log(videos?.data?.filter(file => file?.creativeId == "1063997335093804")?.[0]?.source);

    //callback for video upload


    const handleVideoUploadSuccess = async (creativeId) => {
        // if(selectedTab === 'video'){
        //     setTimeout(() => {
        //         refetchVideos();
        //     }, 5000);
        // }
        // let intervalId;
        // let fetchCount = 0; // Counter for fetch attempts

        // const checkVideoSrc = () => {
        //     const sources = videos?.data?.map(file => file?.creativeId);
        //     return videos?.data?.find(file => file?.creativeId === creativeId)?.source;
        // };

        // let videoSrc = checkVideoSrc();

        // // Guard clause to check if videoSrc is already available
        // if (videoSrc) {
        //     //console.log('Video source found, exiting function.');
        //     return; // Exit early if videoSrc is found
        // }

        // intervalId = setInterval(async () => {
        //     if (!isVideosFetching && videoSrc === undefined && fetchCount < 2) {
        //         await refetchVideos();
        //         fetchCount++;
        //     }

        //     videoSrc = checkVideoSrc(); // Check for videoSrc again after refetch

        //     if (videoSrc) {
        //         clearInterval(intervalId);
        //     } else if (fetchCount >= 2) {
        //         clearInterval(intervalId);
        //     }
        // }, 5000);

        // // Optionally clear the interval after a certain timeout 
        // setTimeout(() => {
        //     clearInterval(intervalId);
        // }, 3000);
    };


    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            setSelectedAdAccount(activeAccountsData?.data[0])
        }
    }, [activeAccountsData])

    const imageList = images?.data
        ?.filter(file => !selectedAdAccount?.adsAccountId || file.adsAccount.adsAccountId === selectedAdAccount.adsAccountId)
        .map((file, index) => {

            return (
                <Flex
                    direction="column"
                    key={index}
                    pb={view === 'grid' ? 0 : 2}
                >

                    {/* <Flex justify="space-between" align="center">
                        <Box display="flex" alignItems="center" maxW={view === 'grid' ? "210px" : ''} pt={'10px'}>
                            <Box boxSize="20px" display="flex" alignItems="center" justifyContent="center" ml={2}>
                                {icon}
                            </Box>

                        </Box>
                    </Flex> */}
                    <Box
                        bgColor={view === 'grid' ? 'gray.50' : ''}
                        borderRadius={view === 'grid' ? 'lg' : 'unset'}
                        borderBottomColor={view === 'grid' ? 'unset' : 'gray.50'}
                        borderBottomWidth={view === 'grid' ? 0 : 1}
                    >
                        {view === 'grid' ? (
                            <Flex
                                w="100%"
                                h="120px"
                                direction={'column'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                                borderRadius={3}
                                p={"2px"}
                                overflow={'hidden'}
                                onClick={() => {
                                    handleFileClick(file);
                                }}
                            >
                                {file.url ? (
                                    <>
                                        <Image
                                            src={file.url}
                                            alt={file.name}
                                            objectFit="cover"
                                            width="100%"
                                            height="100%"
                                            borderRadius="3px"
                                        />
                                        <Flex
                                            position="absolute"
                                            top="0"
                                            left="0"
                                            w="100%"
                                            h="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgColor="rgba(0, 0, 0, 0.3)"
                                            opacity="0"
                                            _hover={{
                                                opacity: 1,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FiEye size={18} color="white" />
                                        </Flex>
                                    </>

                                ) : (
                                    null
                                )}

                            </Flex>
                        ) : null}
                    </Box>
                    <Text fontSize="12px" noOfLines={1} ml={'10px'}>
                        {file.name}
                    </Text>
                </Flex >
            );
        })


    const videoList = videos?.data
        ?.filter(file => !selectedAdAccount?.adsAccountId || file.adsAccount.adsAccountId === selectedAdAccount.adsAccountId)
        .map((file, index) => {

            return (
                <Flex
                    direction="column"
                    key={index}

                    pb={view === 'grid' ? 0 : 2}
                >
                    <Box
                        bgColor={view === 'grid' ? 'gray.50' : ''}
                        borderRadius={view === 'grid' ? 'lg' : 'unset'}
                        borderBottomColor={view === 'grid' ? 'unset' : 'gray.50'}
                        borderBottomWidth={view === 'grid' ? 0 : 1}
                    >
                        {view === 'grid' ? (
                            <Box
                                w="100%"
                                h="110px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                                borderRadius={3}
                                p={"2px"}
                                onClick={() => handleFileClick(file)}
                            >
                                {file.source ? (
                                    <Box w="100%" h="100%" position="relative">
                                        <video
                                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                            src={file.source}
                                            muted
                                        />
                                        <Flex
                                            position="absolute"
                                            top="0"
                                            left="0"
                                            w="100%"
                                            h="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgColor="rgba(0, 0, 0, 0.3)"
                                            opacity="0"
                                            _hover={{
                                                opacity: 1,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FaPlayCircle color="white" size="18px" />
                                        </Flex>
                                    </Box>
                                ) : (
                                    null
                                )}
                            </Box>
                        ) : null}
                    </Box>
                    <Text fontSize="13px" noOfLines={1} ml={2}>
                        {file.name}
                    </Text>
                </Flex>

            );
        })

    useEffect(() => {
        setCurrentPage(1); 
    }, [selectedTab]);

    const totalPages = selectedTab === 'images'
        ? images?.meta?.totalPages || 1
        : selectedTab === 'videos'
        ? videos?.meta?.totalPages || 1
        : 1; 
    
    const hasNextPage = currentPage < totalPages;
    const hasPrevPage = currentPage > 1;

    return (
        <SidebarWithHeader>
            <Flex mb={'10px'} alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" flexBasis={'70%'}>
                    <Heading as='h1' size='xl'>My Drive</Heading>
                    <Menu>
                        <MenuButton as={Button} colorScheme='orange' _expanded={{ bg: 'orange.600' }} ml={4} rightIcon={<ChevronDownIcon />}>
                            <AddIcon boxSize={3} mr={2} /> {'Add Media'}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => handleMediaTypeChange('image')}>Add Image</MenuItem>
                            <MenuItem onClick={() => handleMediaTypeChange('video')}>Add Video</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
                <Flex flexBasis={'30%'} justifyContent={'flex-end'}>
                    <IconButton
                        aria-label="List View"
                        icon={<FiList />}
                        ml="2"
                        onClick={() => setView('list')}
                        _hover={{
                            background: view === 'list' ? 'brand' : 'gray.300',
                        }}
                        sx={{
                            padding: '18px',
                            borderRadius: '50px 0 0 50px',
                            background: view === 'list' ? '#EA7869' : 'gray.100',
                            color: view === 'list' ? 'white' : 'inherit'
                        }}
                    />
                    <IconButton
                        aria-label="Grid View"
                        icon={<FiGrid />}
                        onClick={() => setView('grid')}
                        _hover={{
                            background: view === 'grid' ? 'brand' : 'gray.300',
                        }}
                        sx={{
                            padding: '18px',
                            borderRadius: '0 50px 50px 0',
                            background: view === 'grid' ? '#EA7869' : 'gray.100',
                            color: view === 'grid' ? 'white' : 'inherit'
                        }}
                    />
                </Flex>
            </Flex>

            <Flex alignItems="center">
                <Box width={'100%'}>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                            ref={inputRef}
                            type="text"
                            value={searchValue || ""}
                            onChange={e => {
                                setSearchValue(inputRef.current.value);
                            }}
                            placeholder={`Search...`}
                        />
                        {searchValue &&
                            <InputRightElement
                                cursor={'pointer'}
                                children={isImagesFetching || isVideosFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                onClick={() => { setSearchValue(''); setShowError(false); }} />}
                    </InputGroup>
                    {showError ? (
                        <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                            <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                        </Flex>
                    ) : null}
                </Box>
            </Flex>

            <Flex flexDirection={'column'} flex={'1'}>
                <Box mb={'20px'} gap={4}>
                    <Box mt={'20px'}>
                        <Tabs
                            size='sm'
                            position='relative'
                            variant='unstyled'
                            sx={{
                                display: "flex",
                                position: "relative",
                                flexDirection: "column",
                                flex: 1,
                            }}
                            onChange={(index) => setSelectedTab(index === 0 ? 'images' : 'videos')}
                            isLazy
                        >
                            <Flex justify={'space-between'}>
                                <TabList gap={"10px"}>
                                    <Tab

                                        borderWidth={1}
                                        borderRadius={5}
                                        _selected={{ color: "white", bg: "brand.200" }}
                                    >Images</Tab>
                                    <Tab
                                        borderWidth={1}
                                        borderRadius={5}
                                        _selected={{ color: "white", bg: "brand.200" }}
                                    >Videos</Tab>
                                    <Icon
                                        cursor="pointer"
                                        boxSize={5}
                                        _hover={{
                                            color: 'gray.400'
                                        }}
                                        as={MdRefresh}
                                        onClick={handleRefresh}
                                        mt={2}
                                    />
                                </TabList>

                                {/* <Menu >
                                    <MenuButton
                                        bg={'transparent'}
                                        borderWidth={1}
                                        as={Button}
                                        minW={'320px'}
                                        rightIcon={<ChevronDownIcon />}
                                        borderWidth={1}
                                        bg="transparent"
                                        _hover={{ bg: "transparent" }}
                                        _focus={{ boxShadow: 'outline' }}
                                        _expanded={{ bg: "transparent" }}
                                    >
                                        {
                                            selectedAdAccount?.adsAccountName?.length > 12
                                                ? `${selectedAdAccount.adsAccountName.slice(0, 12)}...`
                                                : selectedAdAccount?.adsAccountName || 'Select Ad Account'
                                        }
                                    </MenuButton>
                                    <MenuList>
                                        {userActiveAdaccounts?.map((account) => (
                                            <MenuItem
                                                key={account.adsAccountId}
                                                onClick={() => {
                                                    setSelectedAdAccount(account);
                                                    onClose();
                                                }}
                                                style={{ fontSize: '14px' }}
                                            >
                                                {account.adsAccountName}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Menu> */}
                                 <Select
                                    value={selectedAdAccount?.adsAccountId}
                                    onChange={(e) => {
                                        const selected = activeAccountsData?.data?.find(
                                            (account) => account.adsAccountId === e.target.value
                                        );
                                        setSelectedAdAccount(selected);
                                    }}
                                    width="200px"
                                >
                                    {activeAccountsData?.data?.map((account, index) => (
                                        <option key={`${account.adsAccountId}-${index}`} value={account.adsAccountId}>
                                            {account.adsAccountName}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>

                            <Divider borderWidth={1} borderBottomColor={'gray.50'} my={'20px'} />

                            <TabPanels pb={"40px"}>
                                <TabPanel p={0}>
                                    {isImagesDataLoading || isImagesFetching ? (
                                        <Spinner mt={'20px'} />
                                    ) : (
                                        <>
                                            {images?.data?.length > 0 ? (
                                                <Grid templateColumns={view === 'grid' ? 'repeat( auto-fit, minmax(110px, 110px) )' : '1fr'} gap='20px'>
                                                    {imageList}
                                                </Grid>
                                            ) : (
                                                <Text>No image assets uploaded yet. Click on <strong>'Add New'</strong> to upload</Text>
                                            )}
                                        </>
                                    )}
                                </TabPanel>
                                <TabPanel p={0}>
                                    {isVideosDataLoading || isVideosFetching ? (
                                        <Spinner mt={'20px'} />
                                    ) : (
                                        <>
                                            {videos?.data?.length > 0 ? (
                                                <Grid templateColumns={view === 'grid' ? 'repeat( auto-fit, minmax(110px, 110px) )' : '1fr'} gap='20px'>
                                                    {videoList}
                                                </Grid>
                                            ) : (
                                                <Text>No video assets uploaded yet. Click on <strong>'Add New'</strong> to upload</Text>
                                            )}
                                        </>
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                        {/* Pagination Component */}
                        <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                hasNextPage={hasNextPage}
                                hasPrevPage={hasPrevPage}
                                setCurrentPage={setCurrentPage}
                        />
                    </Box>
                </Box>
            </Flex>

            {/* Upload Modal */}
            <UploadModal
                onSuccess={handleVideoUploadSuccess}
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    setMediaType('');
                }}
                mediaType={mediaType}
            />

            {/* File Preview Modal */}
            <Modal isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {previewFile?.type === 'image' ? (
                            <Image src={previewFile.value} alt={previewFile.name} mx={'auto'} maxW={'100%'} maxH={'65vh'} borderRadius={2} />
                        ) : previewFile?.type === 'video' ? (
                            <video controls style={{ width: '100%' }}>
                                <source src={previewFile.value} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <Text>No preview available for this file type.</Text>
                        )}
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </SidebarWithHeader>
    );
};

export default DriveForAdmin;

