import React, { useCallback, useEffect, useState } from 'react';
import { Box, Input, Spinner, Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, Divider, InputRightElement, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setSelectedAdAccounts } from 'store/BmAccounts/bmAccountSlice'; 
import { FiSearch, FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { setAccessAllAdAccounts } from 'store/campaigns/campaignsSlice';

function SearchAdaccount({
    accountOptions,
    onAccountChange,
    onSearchInputChange,
    onSearchInputTouched,
    onInitialisation,
    AdsAccountIdFromParams,
    pageMeta = [],
    onLoadMore,
    isAdAccountLoading,
    isAdAccountFetching,
    context = ''
}) {
    const dispatch = useDispatch();
    const { pageSize, after, hasNextPage } = pageMeta;
    const [searchAdAccountValue, setSearchAdAccountValue] = useState('');
    const [selectedAccountValue, setSelectedAccountValue] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [allLoadedAccounts, setAllLoadedAccounts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    // Filter accounts based on searchAdAccountValue
    const filteredAccountsData = accountOptions
        ?.filter(account =>
            account?.name?.toLowerCase().includes(searchAdAccountValue.toLowerCase()) ||
            account?.account_id?.includes(searchAdAccountValue)
        )
        .map(account => ({
            value: account?.account_id,
            label: account?.name?.length > 38 ? account?.name?.slice(0, 44) + '...' : account?.name,
            account,
        })) || [];

    // Update the filtered accounts state when new data is available
    useEffect(() => {
        // Get the stored account from localStorage
        const storedAccount = JSON.parse(localStorage.getItem('selectedAdsAccount'));

        if (filteredAccountsData?.length > 0) {
            setFilteredAccounts((prev) => {
                // Create a set of existing account values (account_ids) in prev (previously filtered accounts)
                const existingAccountValues = new Set(prev?.map(account => account?.value));

                // Filter out the new accounts that already exist in prev (based on value)
                const newAccounts = filteredAccountsData?.filter(account =>
                    !existingAccountValues.has(account?.value)
                );

                // If there are new accounts, add them
                const updatedAccounts = [...prev, ...newAccounts];


                // If a stored account exists and it's not already in updatedAccounts, add it
                if (storedAccount && !existingAccountValues.has(storedAccount?.value)) {
                    // Check if the storedAccount is already in updatedAccounts before adding it
                    const storedAccountExistsInUpdated = updatedAccounts?.some(account => account?.value === storedAccount?.value);

                    if (!storedAccountExistsInUpdated) {
                        updatedAccounts?.unshift(storedAccount); // Add to the top
                    }
                }

                return updatedAccounts;
            });
        }
    }, [accountOptions]);

    useEffect(() => {
        const storedAccount = JSON.parse(localStorage.getItem('selectedAdsAccount'));

        if (accountOptions && accountOptions.length > 0) {
            setAllLoadedAccounts((prev) => {
                // Create a set of existing account_ids in prev (previously loaded accounts)
                const existingAccountIds = new Set(prev.map(account => account?.account_id));

                // Filter out the new accounts that already exist in prev
                const newAccounts = accountOptions.filter(account =>
                    !existingAccountIds.has(account?.account_id)
                );

                // Combine the new unique accounts with the previous accounts
                const updatedAccounts = [...prev, ...newAccounts];

                // If a stored account exists and it's not already in updatedAccounts, add it
                if (storedAccount && !existingAccountIds.has(storedAccount?.account?.account_id)) {
                    // Check if the storedAccount is already in updatedAccounts before adding it
                    const storedAccountExistsInUpdated = updatedAccounts.some(account => account?.account_id === storedAccount?.account?.account_id);

                    if (!storedAccountExistsInUpdated) {
                        updatedAccounts.unshift(storedAccount?.account); // Add to the top
                    }
                }

                return updatedAccounts;
            });
        }
    }, [accountOptions]);

    // Combine initial and subsequent accounts into allLoadedAccounts
    useEffect(() => {
        if (accountOptions && accountOptions?.length > 0) {
            setAllLoadedAccounts((prev) => {
                // Create a set of existing account_ids in prev (previously loaded accounts)
                const existingAccountIds = new Set(prev.map(account => account?.account_id));

                // Filter out the new accounts that already exist in prev
                const newAccounts = accountOptions?.filter(account =>
                    !existingAccountIds.has(account?.account_id)
                );

                // Combine the new unique accounts with the previous accounts
                return [...prev, ...newAccounts];
            });
        }
    }, [accountOptions]);

    useEffect(() => {
        if (allLoadedAccounts?.length > 0) {
            dispatch(setSelectedAdAccounts(allLoadedAccounts));
        }
    }, [allLoadedAccounts, AdsAccountIdFromParams]);


    useEffect(() => {
        if (filteredAccounts && filteredAccounts?.length > 0) {
            // If no account is selected, check for saved account in localStorage or URL
            if(selectedAccountValue?.value === '123'){
                dispatch(setAccessAllAdAccounts(true)); 
                return;
            }   

            if (!selectedAccountValue) {
                const storedAccount = JSON.parse(localStorage.getItem('selectedAdsAccount'));
                const accountFromParams = AdsAccountIdFromParams;
    
                let initialAccountId = storedAccount?.value || accountFromParams;
    
                const accountFromParamsExists = filteredAccounts?.some(acc => acc?.value === accountFromParams);
    
                if (accountFromParamsExists) {
                    const account = filteredAccounts.find(acc => acc?.value === accountFromParams);
                    setSelectedAccountValue(account);
                    onInitialisation(account?.value); 
                    return; 
                }

                if (accountFromParams) {
                    onSearchInputChange(accountFromParams);  // Trigger the search action with the account ID from params
                    onSearchInputTouched(true);
                    return; // Exit early since we triggered a search
                }
                if (initialAccountId) {
                    const account = filteredAccounts?.find(acc => acc?.value === initialAccountId);
                    if (account) {
                        setSelectedAccountValue(account);
                        onInitialisation(account?.value); // Send the initial account value
                    }
                } else {
                    const defaultAccount = filteredAccounts[0];
                    setSelectedAccountValue(defaultAccount);
                    onInitialisation(defaultAccount?.value);
                }
            }
        } else {
            setSelectedAccountValue(null);
            onInitialisation(null);
        }
    }, [filteredAccountsData, AdsAccountIdFromParams, onInitialisation, searchAdAccountValue]);

    // Handle account selection
    const handleSelectChange = (account) => {
        if (account) {
            localStorage.setItem('selectedAdsAccount', JSON.stringify(account));
        }
        setSelectedAccountValue(account);
        onAccountChange(account);

        setFilteredAccounts((prev) => {
            if (!prev.some(item => item?.value === account?.value)) {
                return [...prev, account];
            }
            return prev;
        });

        setSearchAdAccountValue('');
        dispatch(setAccessAllAdAccounts(false)); 
        setIsOpen(false);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchAdAccountValue?.length > 2) {
                onSearchInputChange(searchAdAccountValue);
                onSearchInputTouched(true);
            } else if (searchAdAccountValue?.length === 0) {
                onSearchInputChange('');
            }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchAdAccountValue, onSearchInputChange]);

    const handleScrollToBottom = useCallback(() => {
        if (hasNextPage && !isAdAccountFetching) {
            onLoadMore(after);
        }
    }, [hasNextPage, after, isAdAccountFetching]);

    const handleAccessAllClick = () => {
        dispatch(setAccessAllAdAccounts(true)); 
        setSelectedAccountValue({label:'Access all Ad accounts', value:'123'});
        localStorage.setItem('selectedAdsAccount', JSON.stringify({label:'Access all Ad accounts', value:'123'}));
        onAccountChange({label:'Access all Ad accounts', value:'123'});
        setSearchAdAccountValue('');
        setIsOpen(false);
    };

    const accountsToShow = searchAdAccountValue.length > 0 
            ? filteredAccountsData.filter(account => account?.value !== '123') 
            : filteredAccounts.filter(account => account?.value !== '123');

    return (
        <Box width="280px" position="relative">
            <Popover trigger="click" placement="bottom-start" isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <PopoverTrigger>
                    <Button
                        variant="outline"
                        width="280px"
                        padding="10px"
                        fontSize="13px"
                        textAlign="left"
                        size="sm"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        fontWeight={'normal'}
                        onClick={() => setIsOpen(true)}
                        _hover={{ borderColor: 'gray.500' }}
                        borderColor={isOpen ? 'gray.500' : 'gray.300'}
                    >
                        {isAdAccountLoading ? (
                            <>
                                Loading... <Spinner size="sm" />
                            </>
                        ) : (
                            <>
                                <span>
                                    {selectedAccountValue
                                        ? selectedAccountValue.label.length > 26
                                            ? selectedAccountValue.label.slice(0, 26) + '...'
                                            : selectedAccountValue.label
                                        : 'Select an Ad Account'}
                                </span>
                                <ChevronDownIcon boxSize={5} />
                            </>
                        )}
                    </Button>
                </PopoverTrigger>

                <PopoverContent width="400px" maxHeight="500px" border="1px solid" borderColor={'gray.100'} borderRadius="md" >
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        <Box>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" mt={-1}>
                                    <FiSearch size={18} />
                                </InputLeftElement>
                                <Input
                                    borderRadius={'md'}
                                    value={searchAdAccountValue}
                                    onChange={(e) => setSearchAdAccountValue(e.target.value)}
                                    placeholder="Search for an ad account"
                                    size="sm"
                                />
                                {searchAdAccountValue && (
                                    <InputRightElement mt={-1}>
                                        <FiX
                                            size={18}
                                            cursor="pointer"
                                            onClick={() => setSearchAdAccountValue('')}
                                        />
                                    </InputRightElement>
                                )}
                            </InputGroup>
                        </Box>

                        {selectedAccountValue?.value !== '123' && context === 'campaign' ? (
                            <>
                                <Button
                                    width="100%"
                                    onClick={handleAccessAllClick}
                                    mt={'10px'}
                                    border={'1px'}
                                    p={2}
                                    size={'sm'}
                                    _hover={{ bg: 'orange.500', color: 'white' }}
                                    colorScheme='orange'
                                    variant={'outline'}
                                >
                                    Access all Ad accounts
                                </Button>
                            </>
                        ):null}

                        <Divider my={2} />

                        {/* Infinite Scroll for Filtered Accounts List */}
                        <Box overflowY="auto" id={'scrollable-menu'} maxHeight="350px">
                            <InfiniteScroll
                                dataLength={accountsToShow.length}
                                next={handleScrollToBottom}
                                hasMore={hasNextPage}
                                loader={
                                    <Box display="flex" justifyContent="center" padding="10px">
                                        <Spinner size="sm" />
                                    </Box>
                                }
                                scrollThreshold={0.9}
                                scrollableTarget="scrollable-menu"
                            >
                                <Box >
                                    {accountsToShow.map((account) => (
                                        <Box
                                            key={account.value}
                                            padding="10px"
                                            cursor="pointer"
                                            bg={selectedAccountValue?.value === account.value ? "#ea7869" : 'transparent'}
                                            color={selectedAccountValue?.value === account.value ? 'white' : 'black'}
                                            fontSize={'12px'}
                                            _hover={selectedAccountValue?.value !== account.value ? { backgroundColor: "#fce4e2" } : {}}
                                            onClick={() => handleSelectChange(account)}
                                        >
                                            {account.label}
                                            <Box as="span" display="block" fontSize={'10px'}>
                                                {account.value}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </InfiniteScroll>
                        </Box>

                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

export default SearchAdaccount;