import { useEffect, useState, useMemo } from "react";
import { useSelector } from 'react-redux';
import {
  Flex, Text, Box, Select, Button, useToast,
  Switch,
  FormControl,
  Tooltip,
  Icon,
  useDisclosure,
  Spinner,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Table, Tbody, Tr, Td, 
  ModalFooter,
  Thead,
  Th
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { IoBulbOutline } from "react-icons/io5";
import SidebarWithHeader from 'components/SidebarWithHeader';

import AppTableWithPagination from "components/AppTableWithPagination";
import CreateRuleForm from "components/adRules/CreateRuleForm";
import { useGetAdRulesQuery, useUpdateAdRuleStatusMutation, useDeleteAdRuleMutation } from "store/adRules/adRuleApi";
import moment from 'moment';
import { FiDelete, FiEdit2 } from "react-icons/fi";
import DeleteConfirmationModal from "components/genericForms/DeleteConfirmationModal";
import UpdateRuleForm from "components/adRules/UpdateRuleForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAdsQuery } from "store/campaigns/adsApi";
import { useGetAdsetsQuery } from "store/campaigns/adsetFormApi";

function AdRulesForAdmin() {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adsAccountId = searchParams.get('adsAccountId');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setsearchValue] = useState('');
  const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
  const [selectedAccountData, setSelectedAccountData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchStates, setSwitchStates] = useState({});
  const [selectedAdRule, setselectedAdRule] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [adsList, setAdsList] = useState([]);
  const [adsetList, setAdsetList] = useState([]);
  const [isAdsLoading, setIsAdsLoading] = useState(false);
  const [isAdsetLoading, setIsAdsetLoading] = useState(false);
  

  const { data: adRulesList, isFetching, refetch, isLoading } = useGetAdRulesQuery({
    currentPage,
    searchValue,
    adsAccountId: selectedAdsAccountId,
    BMID: selectedAccountData?.businessAccount?.businessId,
  }, { skip: !selectedAdsAccountId || !selectedAccountData?.businessAccount?.businessId });

  const shouldSkipAds = !selectedAccountData?.businessAccount?.businessId || 
                        !isDetailsModalOpen || 
                        (modalContent?.type !== "AD" || !Array.isArray(modalContent?.ids) || modalContent?.ids?.length === 0);

  const shouldSkipAdsets = !selectedAccountData?.businessAccount?.businessId || 
                           !isDetailsModalOpen || 
                           (modalContent?.type !== "ADSET" || !Array.isArray(modalContent?.ids) || modalContent?.ids?.length === 0);

  const { data: adsData, isLoading: adsLoading } = useGetAdsQuery(
    {
      businessId: selectedAccountData?.businessAccount?.businessId,
      adsIds: modalContent?.type === "AD" ? modalContent?.ids : [],
    },
    { 
      skip: shouldSkipAds
    }
  );

  const { data: adsetData, isLoading: adsetLoading } = useGetAdsetsQuery(
    {
      businessId: selectedAccountData?.businessAccount?.businessId,
      adsetIds: modalContent?.type === "ADSET" ? modalContent?.ids : [],
    },
    { 
      skip: shouldSkipAdsets 
    }
  );
  // console.log({adsList})
  // console.log({adsetList})

  const [updateAdRuleStatus] = useUpdateAdRuleStatusMutation({});
  const [deleteAdRule] = useDeleteAdRuleMutation();

  useEffect(() => {
    if (adsData) {
      setAdsList(adsData);
      setIsAdsLoading(false);
    }
  }, [adsData]);

  useEffect(() => {
    if (adsetData) {
      setAdsetList(adsetData?.data);
      setIsAdsetLoading(false);
    }
  }, [adsetData]);

  const openDetailsModal = (content) => {

    setModalContent(content);
    setIsDetailsModalOpen(true);
    if (content?.type === 'AD' && content?.count !== 0 && !adsList.length) {
      setIsAdsLoading(true); 
    } else if (content?.type === 'ADSET'&& content?.count !== 0  && !adsetList.length) {
      setIsAdsetLoading(true); 
    }
  };
    
  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setModalContent(null);
    setAdsList([]);
    setAdsetList([]);
  };
  
  useEffect(() => {
      const params = new URLSearchParams();
      
      if (selectedAdsAccountId) {
          params.set('adsAccountId', selectedAdsAccountId);
      }
      navigate({ search: params.toString() }, { replace: true });
  }, [selectedAdsAccountId]);

  useEffect(() => {
    if (adsAccountId) {
        setSelectedAdsAccountId(adsAccountId);
    } else if (activeAccountsData?.data?.length > 0) {
        const defaultAccountId = activeAccountsData.data[0].adsAccountId;
        setSelectedAdsAccountId(defaultAccountId);
    }
  }, [activeAccountsData]);

  useEffect(() => {

    if (searchValue !== '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  useEffect(() => {
    if (activeAccountsData?.data?.length > 0) {
      const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
      setSelectedAccountData(account);
    }
  }, [selectedAdsAccountId, activeAccountsData]);

  useEffect(() => {
    if (adRulesList?.data) {
      const initialSwitchStates = {};
      adRulesList.data.forEach(adRule => {
        initialSwitchStates[adRule.adrule_id] = adRule.status;
      });
      setSwitchStates(initialSwitchStates);
    }
  }, [adRulesList]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSuccess = () => refetch();

  const handleSwitchChange = (AdRuleId, currentState) => {

    const newStatus = currentState === 'ENABLED' ? 'DISABLED' : 'ENABLED';

    setSwitchStates(prev => ({ ...prev, [AdRuleId]: newStatus }));
    updateAdRuleStatus({
      AdRuleId,
      payload: { status: newStatus }
    }).unwrap()
      .then(() => {
        refetch();
        toast({
          position: "top-right",
          title: `Ad Rule ${newStatus === 'ENABLED' ? 'Enabled' : 'Disabled'}`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: `Error updating campaign status`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        console.error('Error updating campaign status:', error);

        // Revert switch state if API call fails
        setSwitchStates(prev => ({ ...prev, [AdRuleId]: currentState }));
      });
  };

  const handleDeleteAdRule = (AdRuleId) => {

    deleteAdRule(AdRuleId?.adrule_id)
      .unwrap()
      .then(response => {
        refetch();
        toast({
          position: "top-right",
          title: "Ad Rule deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad Rule",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Rule name",
        accessor: "Rule name",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {

          return (
            <Flex gap={'10px'} align={'center'}>
              <Tooltip label={''} fontSize='xs' >
                <FormControl width={'fit-content'}>
                  <Switch
                    colorScheme='orange'
                    size={'sm'}
                    isChecked={original.status === 'ENABLED'}
                    onChange={() => handleSwitchChange(original.adrule_id, original.status)}
                    isDisabled={userInfo?.role !== 'client'}
                  />
                </FormControl>
              </Tooltip>
              <Text>
                {original.name}
              </Text>
            </Flex>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{original.status === "ENABLED" ? "Enabled" : "Disabled"}</Text>
        ),
      },
      {
        Header: "Applied to",
        accessor: "Applied to",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const entityTypeFilter = original.filters.find(filter => filter.field === "entity_type");
          const adsetFilter = original.filters.find(filter => filter.field === "adset.id");
          const adFilter = original.filters.find(filter => filter.field === "ad.id");
      
          let displayText = "";
          let modalContent = {};
      
          if (entityTypeFilter?.value === "AD") {
            const adCount = adFilter?.value ? adFilter.value.length : 0;
            displayText = adCount > 0 ? `${adCount} Ad${adCount > 1 ? 's' : ''}` : "All active ads";
            modalContent = {
              type: "AD",
              count: adCount,
              ids: adFilter?.value || []
            };
          } else if (entityTypeFilter?.value === "ADSET") {
            const adsetCount = adsetFilter?.value ? adsetFilter.value.length : 0;
            displayText = adsetCount > 0 ? `${adsetCount} Ad Set${adsetCount > 1 ? 's' : ''}` : "All active adsets";
            modalContent = {
              type: "ADSET",
              count: adsetCount,
              ids: adsetFilter?.value || []
            };
          } else {
            displayText = "All active ads";
            modalContent = {
              type: "AD",
              count: 0,
              ids: []
            };
          }
      
          return (
            <Text
              color="orange.500"
              fontWeight={'semibold'}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => openDetailsModal(modalContent)}
            >
              {displayText}
            </Text>
          );
        },
      },
      {
        Header: "Action & condition",
        accessor: "Action & condition",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return(
          <Text>{original?.execution_type === "PAUSE" ? "Turn off ads" : "Turn on ads"}</Text>
        )},
      },
      {
        Header: "Rule results",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const ruleResults = original.ruleResult || [];

          const result = ruleResults.length === 0
            ? <Text>Never</Text>
            : (
              <Box>
                <Text>will start on</Text>
                <Text fontSize={'12px'}>{moment(ruleResults[0].timestamp).format("DD-MM-YYYY")}</Text>
              </Box>
            );

          return <Box>{result}</Box>;
        }
      },
      {
        Header: "Created by",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Box gap={'10px'}>
            <Text>{original.created_by?.name}</Text>
            <Text fontSize={'12px'}>{moment(original.createdDate).format("DD-MM-YYYY")}</Text>
          </Box>
        )
      },
      {
        Header: "Actions",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Flex gap={'10px'} alignItems={'center'}>
            <Tooltip label='Edit' fontSize='xs' >
              <Flex>
                <Icon
                  as={FiEdit2}
                  cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"} 
                  color={userInfo?.role === 'client' ? "inherit" : "gray.300"} 
                  // onClick={() => {
                  //   setselectedAdRule(original);
                  //   onOpen();
                  // }}
                />
              </Flex>
            </Tooltip>
            <Tooltip label='Delete' fontSize='xs' >
              <Flex>
                <Icon
                  cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"}
                  color={userInfo?.role === 'client' ? "red.500" : "gray.300"} 
                  as={FiDelete}

                  // onClick={() => {
                  //   setselectedAdRule(original);
                  //   setIsConfirmationModalOpen(true);
                  // }}
                />
              </Flex>
            </Tooltip>
          </Flex>
        )
      },
    ],
    []
  );

  const confirmDelete = () => {
    if (selectedAdRule) {
      handleDeleteAdRule(selectedAdRule);
      setIsConfirmationModalOpen(false);
      setselectedAdRule(null);
    }
  };

  return (
      <SidebarWithHeader>
         <>
           <DeleteConfirmationModal
             isOpen={isConfirmationModalOpen}
             onClose={() => setIsConfirmationModalOpen(false)}
             onConfirm={confirmDelete}
             message="Are you sure you want to delete this rule? This action can't be undone."
           />

          {/* Applied to Modal */}
          <Modal isOpen={isDetailsModalOpen} onClose={closeDetailsModal} size="3xl" isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Group</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction={'column'} gap={2}>
                  <Text fontSize={'14px'}>
                    {modalContent?.type === "AD" 
                      ? (modalContent?.count || 0) > 0 
                        ? `${modalContent?.count} Ads Managed by this Rule:` 
                        : "All Active Ads Managed by this Rule" 
                      : (modalContent?.count || 0) > 0 
                        ? `${modalContent?.count} Ad Set Managed by this Rule:` 
                        : "All Active Ad Sets Managed by this Rule"
                    }
                  </Text>

                  {/* Ads */}
                  {isAdsLoading && modalContent?.type === "AD" ? (
                    <Flex justify="center" align="center">
                      <Spinner size="sm" />
                    </Flex>
                  ) : modalContent?.type === "AD" && adsList?.length > 0 && modalContent?.count > 0 ? (
                    <Flex direction={'column'} gap={4}>
                      <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                        <IoBulbOutline />
                        <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                          Your rule will be applied to {adsList?.length} Ads in your account at the time that the rule is running.
                        </Text>
                      </Flex>
                      <Flex>
                        <Table variant="simple" width="100%" border="1px solid #e0e0e0">
                          <Thead>
                            <Tr>
                              <Th padding="8px" width="100%" border="1px solid #e0e0e0">Ad Name</Th>
                            </Tr>
                          </Thead>
                          <Tbody style={{ maxHeight: '200px', overflowY: 'auto', display: 'block' }}>
                            {adsList?.map((ad, index) => (
                              <Tr key={index} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <Td padding="6px">
                                  <Text fontSize="12px" padding="0">{ad?.name}</Text>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    </Flex>
                  ) : modalContent?.type === "AD" ? (
                    <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                      <IoBulbOutline />
                      <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                        Your rule will be applied to all active Ads in your account at the time the rule is running.
                      </Text>
                    </Flex>
                  ) : null}

                  {/* Ad Sets */}
                  {isAdsetLoading && modalContent?.type === "ADSET" ? (
                    <Flex justify="center" align="center">
                      <Spinner size="sm" />
                    </Flex>
                  ) : modalContent?.type === "ADSET" && adsetList?.length > 0 && modalContent?.count > 0 ? (
                    <Flex direction={'column'} gap={4}>
                      <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                        <IoBulbOutline />
                        <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                          Your rule will be applied to {adsetList?.length} Ad Sets in your account at the time that the rule is running.
                        </Text>
                      </Flex>
                      <Flex>
                        <Table variant="simple" width="100%" border="1px solid #e0e0e0">
                          <Thead>
                            <Tr>
                              <Th padding="8px" width="100%" border="1px solid #e0e0e0">Ad Set Name</Th>
                            </Tr>
                          </Thead>
                          <Tbody style={{ maxHeight: '200px', overflowY: 'auto', display: 'block' }}>
                            {adsetList?.map((adset, index) => (
                              <Tr key={index} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <Td padding="6px">
                                  <Text fontSize="12px" padding="0">{adset?.name}</Text>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    </Flex>
                  ) : modalContent?.type === "ADSET" ? (
                    <Flex gap={2} bg={'gray.50'} p={3} borderRadius={'sm'}>
                      <IoBulbOutline />
                      <Text fontSize={'12px'} letterSpacing={'-0.3px'}>
                        Your rule will be applied to all active Ad Sets in your account at the time the rule is running.
                      </Text>
                    </Flex>
                  ) : null}

                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button size={'sm'} onClick={closeDetailsModal}>
                  {modalContent?.count ? 'Cancel' : 'OK'}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

           <Flex direction={'column'} gap={4}>
             <Flex gap={'10px'} flex={1} align={'center'}>
               <Text fontWeight={'bold'}>Ad accounts</Text>
               <Box maxWidth={'400px'}>
                 <Select
                   value={selectedAdsAccountId}
                   onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                   size={'sm'}
                   borderRadius={'md'}
                 >
                   {activeAccountsData?.data?.map(account => (
                     <option key={account._id} value={account.adsAccountId}>
                       {`${account.adsAccountName} (${account.adsAccountId})`}
                     </option>
                   ))}
                 </Select>
               </Box>
             </Flex>
   
            {userInfo && userInfo?.role == 'client' && (
            <Flex>
              <Button colorScheme="orange" onClick={openModal}>
                <AddIcon boxSize={3} />
                &nbsp;Create Rule
              </Button>
            </Flex>
            )}
              
             {isLoading ? (
                <Spinner mt="20px" />
              ) : (
             <AppTableWithPagination
               columns={columns}
               data={adRulesList?.data || []}
               paginationMeta={adRulesList?.meta || {}}
               onPageChange={(index) => setCurrentPage(index)}
               onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
               searchEnabled={true}
               isFetching={isFetching}
             />
            )}
           </Flex>
   
   
           <CreateRuleForm isOpen={isModalOpen} onClose={closeModal} adAccounts={selectedAccountData} onSuccess={handleSuccess} />
           <UpdateRuleForm isOpen={isOpen} onClose={onClose} adAccounts={selectedAccountData} onSuccess={handleSuccess} ruleData={selectedAdRule} />
   
         </>
       </SidebarWithHeader>
  )
}

export default AdRulesForAdmin;