import { CalendarIcon, ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon, TimeIcon } from '@chakra-ui/icons';
import { Box, Button, Card, Checkbox, Collapse, Divider, Flex, Input, InputGroup, InputRightAddon, Link, Radio, RadioGroup, Select, Stack, Text, Tooltip, useToast } from '@chakra-ui/react';
import AdScheduler from 'components/adspresso/AdScheduler';
import AdvantagePlusAudience from 'components/adspresso/AdvantagePlusAudience';
import AgeGenderSelectorForCampaigns from 'components/adspresso/AgeGenderSelectorForCampaigns';
import AttributeSetting from 'components/adspresso/AttributeSetting';
import BulkLocationSelector from 'components/adspresso/BulkLocationSelector';
import DetailedTargetingSelector from 'components/adspresso/DetailedTargetingSelector';
import FaceBookPageAdspressoSelector from 'components/adspresso/FaceBookPageAdspressoSelector';
import GeoLocationsUpdateSelector from 'components/adspresso/GeoLocationsUpdateSelector';
import LocaleUpdateSelector from 'components/adspresso/LocaleUpdateSelector';
import MinuteSecondSpinner from 'components/adspresso/MinuteSecondSpinner';
import PixelConversionSelector from 'components/adspresso/PixelConversionSelector';
import PlacementsSelector from 'components/adspresso/PlacmentsSelector';
import Audience from 'components/campaignForms/AdSetForms/Audience';
import BotNullifierCampaigns from 'components/adspresso/BotNullifierCampaigns';
import currencySymbolMap from 'currency-symbol-map';
import { toZonedTime } from 'date-fns-tz';
import { useFormik } from 'formik';
import countries from 'i18n-iso-countries';
import moment from 'moment';
import 'moment-timezone';
import { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BsStars } from 'react-icons/bs';
import { SlGraph } from 'react-icons/sl';
import { useCreateAdsetMutation } from 'store/campaigns/adsetFormApi';
import { removeEmptyValues } from 'utils/functions';
import { leadFormSchema } from 'utils/schema';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function pluralizeType(type) {
    const pluralMapping = {
        country: 'countries',
        city: 'cities',
        region: 'regions',
        zip: 'zips',
        neighborhood: 'neighborhoods',
        geo_market: 'geo_markets',
        electoral_district: 'electoral_districts',
        subcity: 'subcities',
        place: 'places',
        address: 'addresses'
    };
    return pluralMapping[type] || `${type}s`; // Default pluralize rule by appending 's'
}


const transformGeolocations = (locations) => {

    const geoLocations = {
        geo_locations: {}
    };

    // Iterate over each location and group them by their pluralized type
    locations.forEach(location => {
        const pluralizedType = pluralizeType(location.type);

        // Initialize the array for the type if not already initialized
        if (!geoLocations.geo_locations[pluralizedType]) {
            geoLocations.geo_locations[pluralizedType] = [];
        }

        // Push the location to the corresponding type array
        geoLocations.geo_locations[pluralizedType].push(location);
    });

    return geoLocations;
};


const transformExcludeGeolocations = (locations) => {
    // Create the base structure for excluded geo locations
    const excludedGeoLocations = {
        excluded_geo_locations: {}
    };

    // Iterate over each excluded location and group them by their pluralized type
    locations.forEach(location => {
        const pluralizedType = pluralizeType(location.type);

        // Initialize the array for the type if not already initialized
        if (!excludedGeoLocations.excluded_geo_locations[pluralizedType]) {
            excludedGeoLocations.excluded_geo_locations[pluralizedType] = [];
        }

        // Push the excluded location to the corresponding type array
        excludedGeoLocations.excluded_geo_locations[pluralizedType].push(location);
    });

    return excludedGeoLocations;
};


const transformDetailedTargets = (detailedTargets) => {
    const result_object = {};

    detailedTargets?.forEach(item => {
        const { type, id, name } = item;

        if (type === 'relationship_statuses') {
            if (!result_object[type]) {
                result_object[type] = [];
            }
            result_object[type].push(Number(id));
        } else {
            // For other types,
            if (!result_object[type]) {
                result_object[type] = [];
            }
            result_object[type].push({ id, name });
        }
    });

    return {
        flexible_spec: [result_object]
    };
};



const filterUnwantedType = (data) => {
    return Object.fromEntries(
        Object.entries(data).filter(([key, _]) => key !== "location_types")
    );
};

const flattenPreFilledLocations = (locationsObj, inclusionType) => {


    const filteredLocations = filterUnwantedType(locationsObj);

    // console.log({ filteredLocations });


    const flattenedLocations = [];

    // Flatten countries
    if (filteredLocations.countries) {
        filteredLocations.countries.forEach(country => {
            flattenedLocations.push({
                key: country,
                country_code: country,
                name: countries.getName(country, 'en') || country, // Use the country name
                type: 'country', // We assume it's a country
                inclusionType,
            });
        });
    }

    // Flatten regions
    if (filteredLocations.regions) {
        filteredLocations.regions.forEach(region => {
            flattenedLocations.push({
                key: region.key,
                country_code: region.country,
                name: region.name,
                region: region.region,
                type: 'region', // It's a region
                inclusionType,
            });
        });
    }

    // Flatten cities
    if (filteredLocations.cities) {
        filteredLocations.cities.forEach(city => {
            flattenedLocations.push({
                key: city.key,
                country_code: city.country,
                name: city.name,
                region: city.region,
                type: 'city', // It's a city
                inclusionType,
            });
        });
    }

    // Flatten zips
    if (filteredLocations.zips) {
        filteredLocations.zips.forEach(zip => {
            flattenedLocations.push({
                key: zip.key,
                country_code: zip.country,
                name: zip.name,
                region: zip.region,
                type: 'zip', // It's a zip code
                inclusionType,
            });
        });
    }

    return flattenedLocations;
};


function CreateLeadsForm({ data, campaignData, adsAccountData, campaign_id, onClose, onSaveSuccess }) {

    const toast = useToast();
    const { objective, buying_type, name: campaignName, } = campaignData?.[campaign_id] || {};

    const adsAccount = campaignData?.adsAccount;

    const firstAdAccountId = campaignData?.adsAccount?.adsAccountId;

    const timeZone = adsAccountData?.timezone_name;

    let timeZoneAbbreviation = '';
    
    if (timeZone) {
        timeZoneAbbreviation = moment.tz(timeZone).format('z');
        if (!timeZoneAbbreviation || timeZoneAbbreviation.startsWith('+')) {
            timeZoneAbbreviation = 'UTC'; 
        }
    } else {
        timeZoneAbbreviation = '';  
    }

    const [startTime, setStartTime] = useState(() => {
    
        // If no start_time is provided, set the default to the current time in the selected timeZone
        if (timeZone) {
            const currentTimeInTimeZone = moment.tz(timeZone).format("HH:mm");
            return currentTimeInTimeZone;
        }
    
        return "00:00"; // Fallback if no timezone is provided (or any other case)
    });
    
    const [startDate, setStartDate] = useState(() => {
        const date = new Date();
        return toZonedTime(date, timeZone);
    });

    const [endTime, setEndTime] = useState(() => {
        // If no end_time is provided, set the default to the current time in the selected timeZone
        if (timeZone) {
            const currentEndTimeInTimeZone = moment.tz(timeZone).format("HH:mm");
            return currentEndTimeInTimeZone;
        }
    
        return "00:00"; // Fallback if no timezone 
    });

    const [endDate, setEndDate] = useState(() => {
        const defaultEndDate = new Date(startDate);
        defaultEndDate.setDate(defaultEndDate.getDate() + 30);
        return toZonedTime(defaultEndDate, timeZone);
    });

    const [errorDateMessage, setErrorDateMessage] = useState('');

    const [showEndDate, setShowEndDate] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [placements, setPlacements] = useState({});
    const [geoLocations, setGeoLocations] = useState({});

    const [showAdvantage, setShowAdvantage] = useState(false);

    const genderMap = { all: [0], men: [1], women: [2] };
    const [interests, setInterests] = useState([]);
    const [budgetType, setBudgetType] = useState('lifetime_budget');
    const [isScheduled, setIsScheduled] = useState(false);
    const [adSchedule, setAdSchedule] = useState([]);
    const [timezone, setTimezone] = useState('USER');
    const [zipCodes, setzipCodes] = useState([]);
    const [isAttributeExpanded, setAttributeIsExpanded] = useState(false);
    const [isBulkLocationSelectorVisible, setisBulkLocationSelectorVisible] = useState(false);
    const currencyCode = adsAccountData?.currency;
    const currencySymbol = currencySymbolMap(currencyCode);
    const [createAdset, { isLoading: isAdsetCreating }] = useCreateAdsetMutation();

    const adSetFormik = useFormik({
        initialValues: {
            name: '',
            destination_type: 'WEBSITE',
            optimization_goal: 'OFFSITE_CONVERSIONS',
            promoted_object: [],
            billing_event: 'IMPRESSIONS',
            bid_strategy: 'LOWEST_COST_WITHOUT_CAP',
            bid_amount: '',
            budget: 'lifetime_budget',
            daily_budget: 800,
            lifetime_budget: 14000,
            geo_locations: [],
            excluded_geo_locations: [],
            locales: [],
            ageOriginal:{
                age_min: 18,
                age_max: 65,
            },
            genders: [0],
            ageRange: {
                age_min: 18, 
                age_max: 65 
            },
            attribution_spec: [
                { event_type: 'CLICK_THROUGH', window_days: 7 },
                { event_type: 'VIEW_THROUGH', window_days: 1 },
                { event_type: 'ENGAGED_VIDEO_VIEW', window_days: 1 },
            ],
            excluded_custom_audiences: [],
        },
        enableReinitialize: true,
        validationSchema: leadFormSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const { budget, name, bid_strategy, destination_type, optimization_goal, attribution_spec,  billing_event, daily_budget, bid_amount, lifetime_budget, geo_locations, excluded_geo_locations, locales, promoted_object, excluded_custom_audiences, ageOriginal, genders, ageRange } = values;

            // Filter attribution_spec based on optimization_goal and window_days
            const filteredAttributionSpec = attribution_spec.filter(spec => {
                // Exclude ENGAGED_VIDEO_VIEW if optimization_goal is 'VALUE'
                if (optimization_goal === 'VALUE' && spec.event_type === 'ENGAGED_VIDEO_VIEW') {
                    return false;
                }
                // Exclude items where window_days is null
                return spec.window_days !== null;
            });

            if (values.optimization_goal == 'OFFSITE_CONVERSIONS' && values.promoted_object?.length == 0) {
                setSubmitting(false)
                return;
            }

            if ((values.optimization_goal == "QUALITY_CALL" || values.optimization_goal == "LEAD_GENERATION" || values.optimization_goal == "LEAD_GENERATION" ||  values.optimization_goal == "QUALITY_LEAD")  && values.promoted_object?.length === 0) {
                setSubmitting(false)
                return;
            }

            const bidDetails = bid_strategy === 'LOWEST_COST_WITH_BID_CAP'
                ? { bid_amount: bid_amount * 100 }
                : {}


            const geoLocations = geo_locations?.length ? transformGeolocations(geo_locations) : null;
            const excludedGeoLocations = excluded_geo_locations?.length ? transformExcludeGeolocations(excluded_geo_locations) : null;

            const genderList = genders?.length > 0 ? { genders: genders } : null;
            const detailedTargetList = interests?.length > 0 ? transformDetailedTargets(interests) : null;
            const filteredPlacements = Object.fromEntries(
                Object.entries(placements).filter(([_, value]) => value.length > 0)
            );

            const localeList = locales?.length > 0 ? { locales: locales } : null;

            const updateStartTime = (startDate, time) => {
                return moment(startDate)
                    .set({
                        hours: parseInt(time.split(":")[0], 10),
                        minutes: parseInt(time.split(":")[1], 10),
                        seconds: 0,
                    })
                    .unix(); // Useing unix() to get Unix timestamp
            };

            const updateEndTime = (endDate, time) => {
                return moment(endDate)
                    .set({
                        hours: parseInt(time.split(":")[0], 10),
                        minutes: parseInt(time.split(":")[1], 10),
                        seconds: 0,
                    })
                    .unix(); 
            };

            const updatedStartTime = updateStartTime(startDate, startTime);
            const updatedEndTime = updateEndTime(endDate, endTime);

            let transformedPromotedObject;
            if (optimization_goal === 'OFFSITE_CONVERSIONS') {
                transformedPromotedObject = {
                    pixel_id: promoted_object[0].pixel_id,
                    custom_event_type: promoted_object[0].custom_event_type,
                };
            
                if (promoted_object[0].custom_conversion_id) {
                    transformedPromotedObject.custom_conversion_id = promoted_object[0].custom_conversion_id;
                }
            
                if (promoted_object[0].pixel_rule) {
                    transformedPromotedObject.pixel_rule = promoted_object[0].pixel_rule;
                }
                
                if (promoted_object[0].custom_event_str) {
                    transformedPromotedObject.custom_event_str = promoted_object[0].custom_event_str;
                }
            } else if (optimization_goal === 'QUALITY_CALL' || optimization_goal === 'LEAD_GENERATION' || optimization_goal == "QUALITY_LEAD" ) {
                transformedPromotedObject = {
                    page_id: promoted_object[0].page_id,
                };
            }

            const payload = {
                name,
                destination_type, optimization_goal, billing_event,
                bid_strategy,
                campaign: {
                    name: campaignName,
                    objective,
                    campaign_id,
                },
                targeting: {
                    ...filteredPlacements,
                    excluded_custom_audiences: excluded_custom_audiences,
                    age_min: ageOriginal.age_min,
                    age_max: showAdvantage ? 65 : ageOriginal.age_max,
                    ...(showAdvantage && {
                        targeting_automation: {
                            advantage_audience: 1
                        },
                        age_range: [ageRange?.age_min, ageRange?.age_max],
                    }),
                    ...geoLocations,
                    ...excludedGeoLocations,
                    ...genderList,
                    ...detailedTargetList,
                    ...localeList
                },
                promoted_object: transformedPromotedObject,
                ...bidDetails,
                currency: currencyCode,
                ...(budget === 'daily_budget' ? { daily_budget: Number(daily_budget) * 100 } : {}),
                ...(budget === 'lifetime_budget' ? { lifetime_budget: Number(lifetime_budget) * 100 } : {}),
                start_time: updatedStartTime,
                ...(budget === 'lifetime_budget' ? { end_time: updatedEndTime } : {}),
                ...(budget === 'daily_budget' && showEndDate ? { end_time: updatedEndTime } : {}),
                adset_schedule: adSchedule,
                ...(adSchedule && adSchedule.length > 0 ? { pacing_type: ["day_parting"] } : {}),
                ...(['OFFSITE_CONVERSIONS', 'VALUE'].includes(optimization_goal) && { 
                    attribution_spec: filteredAttributionSpec.length > 0 ? filteredAttributionSpec : undefined 
                }),
            };

            const filteredPayload = removeEmptyValues(payload);

            // console.log({ filteredPayload });


            createAdset({ payload: filteredPayload, adsAccountId: firstAdAccountId })
                .unwrap()
                .then(response => {
                    toast({
                        position: "top-right",
                        title: "Ad set created",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    resetForm();
                    onSaveSuccess();
                    onClose();
                })
                .catch(error => {
                    let errorMessage = "An error occurred while creating the adset.";
                    if (error && error.data && error.data.message) {
                        errorMessage = error.data.message.replace(/"/g, '');
                    } else if (error.message) {
                        errorMessage = error.message.replace(/"/g, '');
                    }
                    toast({
                        position: "top-right",
                        title: "Error updating Adset",
                        description: errorMessage,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    },
    );


    const {
        name,
        destination_type,
        optimization_goal,
        promoted_object,
        budget,
        targeting,
        bid_strategy
    } = data;

    const optimizationOptions = optimization_goal[adSetFormik.values.destination_type] || [];
    const promotedObjectFields = promoted_object?.[adSetFormik.values.optimization_goal] || {};

    useEffect(() => {
        const defaultDestinationType = adSetFormik.values.destination_type;
        const optimizationOptions = data.optimization_goal[defaultDestinationType] || [];
        const defaultOptimizationGoal = optimizationOptions.length ? optimizationOptions[0].value : '';
        adSetFormik.setFieldValue('optimization_goal', defaultOptimizationGoal|| 'OFFSITE_CONVERSIONS');
    }, [adSetFormik.values.destination_type]);

    useEffect(() => {
        if (adSetFormik.values.destination_type == "PHONE_CALL") {
            adSetFormik.setFieldValue('optimization_goal', 'QUALITY_CALL');
            adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
        }
        if (adSetFormik.values.destination_type == "ON_AD") {
            adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
        }
        if (adSetFormik.values.destination_type === "WEBSITE") {
            // Update the optimization goal only if it's not already set
            if (adSetFormik.values.optimization_goal === "QUALITY_CALL") {
                adSetFormik.setFieldValue('optimization_goal', 'OFFSITE_CONVERSIONS');
            }
        }
    }, [adSetFormik.values.destination_type]);

    useEffect(() => {
        if (adSetFormik.values.budget === 'lifetime_budget') {
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + 30); // 30 days from startDate
            setEndDate(newEndDate);
        }
    }, [adSetFormik.values.budget]);

    useEffect(() => {
        if (endDate <= startDate) {
            setErrorDateMessage('End date must be greater than start date.');
        } else {
            setErrorDateMessage('');
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (adSchedule.length > 0 && adSetFormik.values.budget === 'lifetime_budget') {
            // Ensure that each schedule entry contains the correct timezone
            const updatedAdSchedule = adSchedule.map(scheduleEntry => ({
                ...scheduleEntry,
                timezone_type: timezone
            }));
            setAdSchedule(updatedAdSchedule);
        } else if (adSetFormik.values.budget === 'daily_budget') {
            setAdSchedule([]);
        }
    }, [timezone, adSetFormik.values.budget]);

    const handleAdScheduleChange = (newSchedule) => {
        const adScheduleData = newSchedule.map(scheduleEntry => ({
            ...scheduleEntry,
            timezone_type: timezone
        }));
        setAdSchedule(adScheduleData);
    };

    const handleBudgetChange = (e) => {
        const selectedBudgetType = e.target.value;
        setBudgetType(selectedBudgetType);

        // Adjust budget values based on selection
        if (selectedBudgetType === 'daily_budget') {
            adSetFormik.setFieldValue('lifetime_budget', Number(adSetFormik.values.lifetime_budget));
        } else if (selectedBudgetType === 'lifetime_budget') {
            adSetFormik.setFieldValue('daily_budget', Number(adSetFormik.values.daily_budget));
        }
        adSetFormik.setFieldValue('budget', selectedBudgetType);
    };

    const handleLocationsChange = (locations) => {

        // Separate included and excluded locations
        const includedLocations = locations.filter(location => location.inclusionType === 'include');
        const excludedLocations = locations.filter(location => location.inclusionType === 'exclude');

        // Update the formik fields based on inclusion type
        adSetFormik.setFieldValue('geo_locations', includedLocations);
        adSetFormik.setFieldValue('excluded_geo_locations', excludedLocations);

        setTimeout(() => {
            adSetFormik.validateField('geo_locations');
        }, 0);
    };

    const handleAgeGenderChange = (updatedAgeGender) => {
        const { age_min, age_max, gender } = updatedAgeGender;
        const genderArray = genderMap[gender] || [];

        // Update the Formik state for ageOriginal and gender
        adSetFormik.setFieldValue('ageOriginal', { age_min, age_max });
        adSetFormik.setFieldValue('genders', genderArray);
    };

    const handleAgeRangeChange = (data) => {
        const { age_min, age_max } = data;
        adSetFormik.setFieldValue('ageRange', { age_min, age_max });
    }

    const handleInterestsChange = (interests) => {
        setInterests(interests);
    };

   
    const handleCustomAudienceChange = (id) => {

        const excluded_custom_audiences = [];
        excluded_custom_audiences.push(id);

        adSetFormik.setFieldValue('excluded_custom_audiences', excluded_custom_audiences);
    };

    const handlePixelIdSelection = (id, adsAccountId) => {
        const updatedPromotedObjects = adSetFormik.values.promoted_object.filter(
            obj => obj.adsAccountId !== adsAccountId
        );
        
        updatedPromotedObjects.push({
            adsAccountId,
            pixel_id: id
        });

        adSetFormik.setFieldValue('promoted_object', updatedPromotedObjects);
    };


    const handleEventTypeSelection = (option, adsAccountId) => {

         const updatedPromotedObjects = adSetFormik.values.promoted_object.map(obj =>
            obj.adsAccountId === adsAccountId ? { 
                ...option,
                adsAccountId,
                pixel_id: obj?.pixel_id 
            } : obj
        );
        
        // If the adsAccountId isn't found, add a new promoted object
        if (!adSetFormik.values.promoted_object.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedPromotedObjects.push({ 
                adsAccountId, 
                ...option
        });
    }

        adSetFormik.setFieldValue('promoted_object', updatedPromotedObjects);
    };

    const handleFacebookPageSelection = (pageId, adsAccountId) => {
        const { promoted_object } = adSetFormik.values;

        const updatedAccounts = promoted_object.map(obj =>
            obj.adsAccountId === adsAccountId ? { ...obj, page_id: pageId } : obj
        );

        if (!promoted_object.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedAccounts.push({ adsAccountId, page_id: pageId });
        }

        adSetFormik.setFieldValue('promoted_object', updatedAccounts);
    };

    // console.log(adSetFormik.values.geo_locations)

    const handleSave = () => {
        // Set all fields to touched to trigger validation
        Object.keys(adSetFormik.values).forEach(field => {
            adSetFormik.setFieldTouched(field, true);
        });

        setIsSaving(true);

        const errors = adSetFormik.errors;
        const errorKeys = Object.keys(errors);

        // Check for validation errors
        if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];

            // Check if promoted_object is empty or has invalid entries
            const promotedObject = adSetFormik.values.promoted_object;
            const optimizationGoal = adSetFormik.values.optimization_goal;
            if (
                (optimizationGoal === 'OFFSITE_CONVERSIONS' || optimizationGoal === 'VALUE') &&
                (
                    promotedObject.length === 0 ||
                    promotedObject.some(obj => !obj.custom_event_type || !obj.pixel_id)
                )
            ) {
                // Scroll to the error container
                const errorContainer = document.querySelector('.error-container');
                if (errorContainer) {
                    errorContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                return;
            }

            // First, try to scroll to the element by name
            const errorElementByName = document.querySelector(`[name="${firstErrorKey}"]`);
            if (errorElementByName) {
                errorElementByName.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }

            // If not found, try to scroll to the element by error class
            const errorElementByClass = document.querySelector(`.error-class-${firstErrorKey}`);
            if (errorElementByClass) {
                errorElementByClass.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }

        if (
            (adSetFormik.values.budget === 'lifetime_budget' && (errorDateMessage || endDate <= startDate)) ||
            (adSetFormik.values.budget === 'daily_budget' && showEndDate && (errorDateMessage || endDate <= startDate))
        ) {
            return;
        }

        adSetFormik.handleSubmit();
    };

    const buildTargetingSpec = useMemo(() => {
        const geoLocations = adSetFormik.values.geo_locations || [];
        const locales = adSetFormik.values.locales || [];
        const genders = adSetFormik.values.genders || [];
        const age_min = adSetFormik.values.ageOriginal.age_min;
        const age_max = adSetFormik.values.ageOriginal.age_max;

        const geoLocationList = geoLocations?.length ? transformGeolocations(geoLocations) : null;
        const genderList = genders?.length > 0 ? { genders: genders } : null;
        const detailedTargetList = interests?.length > 0 ? transformDetailedTargets(interests) : null;
        const localeList = locales?.length > 0 ? { locales: locales } : null;

        const filteredPlacements = Object.fromEntries(
            Object.entries(placements).filter(([_, value]) => value.length > 0)
        );

        return {
            ...geoLocationList,
            age_min,
            age_max,
            ...genderList,
            ...detailedTargetList,
            ...localeList,
            ...filteredPlacements,
        };
    }, [
        adSetFormik.values.geo_locations,
        adSetFormik.values.locales,
        adSetFormik.values.ageOriginal, 
        adSetFormik.values.genders,
        interests, 
        placements,
    ]);

    return (
        <Box borderRadius="md" width="100%">
            <Flex gap={'15px'}>
                <Flex flex={0.6} direction="column" overflowY="auto" maxHeight="calc(100vh - 200px)">
                    <Box width="100%">
                        <form onSubmit={adSetFormik.handleSubmit}>
                            {/* Ad Set Name */}
                            <Flex direction="column" mb={4} bg="gray.50" p={4} borderRadius="md" gap={1}>
                                <Flex>
                                    <Text fontWeight="bold">{name.label}</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                </Flex>
                                <Input
                                    name="name"
                                    placeholder={`Enter ${name.label}`}
                                    bg="#fff"
                                    value={adSetFormik.values.name}
                                    onChange={adSetFormik.handleChange}
                                    onBlur={adSetFormik.handleBlur}
                                    borderRadius="md"
                                    size={'sm'}
                                />
                                {adSetFormik.touched.name && adSetFormik.errors.name && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.name}</Text>
                                )}
                            </Flex>


                            {/* Conversion Location */}
                            <Flex direction="column" mb={4} bg="gray.50" p={4} borderRadius="md" gap={2}>
                                <Text fontWeight="bold">{destination_type.label1}</Text>
                                <Text fontWeight="bold">{destination_type.label2}</Text>
                                <RadioGroup
                                    name="destination_type"
                                    onChange={(value) => {
                                        adSetFormik.setFieldValue('destination_type', value)
                                        adSetFormik.setFieldValue('promoted_object', [])
                                    }}
                                    value={adSetFormik.values.destination_type}
                                >
                                    <Stack spacing={4} direction='column' mb={2}>
                                        {destination_type.options?.map((option) => (
                                            <Flex key={option.value} align="center">
                                                <Radio colorScheme='orange' value={option.value} />
                                                <Stack ml={2} spacing={0}>
                                                    <Text>{option.label1}</Text>
                                                    <Text fontSize='sm'>{option.label2}</Text>
                                                </Stack>
                                            </Flex>
                                        ))}
                                    </Stack>
                                </RadioGroup>
                                {adSetFormik.touched.destination_type && adSetFormik.errors.destination_type && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.destination_type}</Text>
                                )}

                                {adSetFormik.values.destination_type && (
                                    <Flex direction="column" gap={2} mb={2}>
                                        <Flex alignItems={'center'}>
                                            <Text fontWeight="bold">{optimization_goal.label}</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p={'5px'}>
                                                        <Text>Choose what you want people to do when they see your ads. Your campaign objective should align with your overall business goals.</Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" ml={2} />
                                            </Tooltip>
                                        </Flex>

                                        {optimizationOptions.length === 1 ? (
                                            <Text bg="#fff" borderRadius="md" p={2} fontSize={'14px'} height={'32px'} display="flex" alignItems="center">
                                                {optimizationOptions[0].label}
                                            </Text>
                                        ) : (
                                            <Select
                                                name="optimization_goal"
                                                value={adSetFormik.values.optimization_goal}
                                                onChange={(e) => adSetFormik.setFieldValue('optimization_goal', e.target.value)}
                                                onBlur={adSetFormik.handleBlur}
                                                bg="#fff"
                                                borderRadius="md"
                                                size={'sm'}
                                            >
                                                {optimizationOptions.map((option, index) => (
                                                    <option key={`${option.value}-${index}`} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}

                                            </Select>
                                        )}
                                        {adSetFormik.touched.optimization_goal && adSetFormik.errors.optimization_goal && (
                                            <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.optimization_goal}</Text>
                                        )}
                                    </Flex>
                                )}

                                <Flex direction="column" className='error-container'>
                                    {/* Promoted Object */}
                                    {(adSetFormik.values.optimization_goal === 'OFFSITE_CONVERSIONS' || adSetFormik.values.optimization_goal === 'VALUE') && (
                                        <>
                                            <PixelConversionSelector
                                                adsAccount={adsAccount}
                                                adSetFormik={adSetFormik}
                                                promotedObjectFields={promotedObjectFields}
                                                promotedObjectData={''}
                                                onSelectedPixelId={(id) => handlePixelIdSelection(id, adsAccount.adsAccountId)}
                                                // onSelectedEventType={(type) => handleEventTypeSelection(type, account.adsAccountId)}
                                                onSelectedEventType={(option, adsAccountId) => handleEventTypeSelection(option, adsAccountId)}
                                                onBlur={adSetFormik.handleBlur}
                                                isSaving={isSaving}
                                            />
                                             <Box mt={-2}>
                                                <BotNullifierCampaigns
                                                    adsAccountId={adsAccount.adsAccountId}
                                                    adsAccountData={adsAccountData}
                                                    promoted_object={adSetFormik?.values?.promoted_object}
                                                    botNullifyData={''}
                                                    onCustomAudienceChange={(id) => handleCustomAudienceChange(id)}
                                                />
                                            </Box>
                                        </>
                                    )}

                                    {adSetFormik.touched.promoted_object && adSetFormik.errors.promoted_object === 'Pixel id is required' && (
                                        <Text fontSize={'sm'} color="red.500" className="error-class-promoted_object">
                                            {/* {'Pixels & events are required'} */}
                                        </Text>
                                    )}

                                    <Flex gap={2} direction="column">
                                        {['QUALITY_CALL', 'LEAD_GENERATION', 'QUALITY_LEAD'].includes(adSetFormik.values.optimization_goal) && (  
                                            <>
                                                <Flex alignItems="center">
                                                    <Text fontWeight="bold">Facebook pages</Text>
                                                    <Text display="inline-flex" fontSize="18px" color="red.400" mx={2}>*</Text>
                                                    <Tooltip
                                                        borderRadius={5}
                                                        label={
                                                            <Box fontSize="sm" p="5px">
                                                                <Text>Choose the Facebook Page that you want to promote. If the Page you want isn't listed, contact the Page Admin.</Text>
                                                            </Box>
                                                        }
                                                        fontSize="md"
                                                    >
                                                        <InfoOutlineIcon pointerEvents="all" />
                                                    </Tooltip>
                                                </Flex>

                                                <FaceBookPageAdspressoSelector
                                                    adsAccount={adsAccount}
                                                    selectedPageId={''}
                                                    onSelectedPage={(page) => handleFacebookPageSelection(page, adsAccount.adsAccountId)}
                                                />

                                                {adSetFormik.touched.optimization_goal && adSetFormik.errors.promoted_object && (
                                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.promoted_object}</Text>
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                </Flex>

                                {/* Bid Amount Field */}
                                <Flex direction="column">
                                    {adSetFormik.values.optimization_goal && adSetFormik.values.optimization_goal !== 'QUALITY_LEAD' &&
                                        bid_strategy?.option[1]?.optimization_goal.includes(adSetFormik.values.optimization_goal) && (
                                            <Flex direction="column">
                                                <Flex alignItems={'center'}>
                                                    <Text mb={2} fontWeight="bold">
                                                        Cost per result goal{" "}
                                                        <Text as="span" fontWeight="normal">Optional</Text>
                                                    </Text>
                                                    <Tooltip
                                                        borderRadius={5}
                                                        label={
                                                            <Box fontSize="sm" p={'5px'}>
                                                                <Text>
                                                                    Set a goal if you're aiming for a certain cost per result. To set your goal,
                                                                    use cost per result data from previous campaigns with the same optimisation event
                                                                    and attribution settings. If you don't set a cost per result goal, Meta will focus
                                                                    on spending your entire budget and getting the most results.<br></br>
                                                                    Cost per result goal is only available with some performance goal selections.
                                                                </Text>
                                                            </Box>
                                                        }
                                                        fontSize="md"
                                                    >
                                                        <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                                    </Tooltip>
                                                </Flex>

                                                <InputGroup>
                                                    <Input
                                                        name="bid_amount"
                                                        type="number"
                                                        placeholder={`${currencySymbol}X.XX`}
                                                        value={adSetFormik.values.bid_amount}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            adSetFormik.handleChange(e);
                                                            if (value) {
                                                                adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITH_BID_CAP');
                                                            } else {
                                                                adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
                                                            }
                                                        }}
                                                        onBlur={adSetFormik.handleBlur}
                                                        borderRadius={'md'}
                                                        size={'sm'}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <InputRightAddon height={'32px'} fontSize={'14px'}>
                                                        {currencyCode}
                                                    </InputRightAddon>
                                                </InputGroup>

                                                {adSetFormik.touched.bid_amount && adSetFormik.errors.bid_amount && (
                                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.bid_amount}</Text>
                                                )}
                                            </Flex>
                                        )}
                                    {/* Conditionally render the bid strategy radio options if bid_amount has a value */}
                                    {(adSetFormik.values.bid_amount) && (adSetFormik.values.destination_type == "WEBSITE") && (
                                        <Flex direction="column" mt={4}>
                                            <Text mb={2} fontWeight="bold">Bid strategy</Text>
                                            <RadioGroup
                                                name="bid_strategy"
                                                value={adSetFormik.values.bid_strategy}
                                                onChange={(value) => adSetFormik.setFieldValue('bid_strategy', value)}
                                            >

                                                <Stack direction="row" spacing={4}>
                                                    <Flex direction="row" align="center" spacing={2}>
                                                        <Radio colorScheme='orange' value="LOWEST_COST_WITH_BID_CAP" />
                                                        <Flex direction="column" ml={2}>
                                                            <Text fontWeight="bold">Cost per result goal</Text>
                                                            <Text fontSize="sm" color="gray.500">Best for getting the most volume</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Stack>
                                            </RadioGroup>
                                        </Flex>
                                    )}
                                </Flex>


                                {/* Attribution setting Field */}
                                {(adSetFormik.values.optimization_goal === 'OFFSITE_CONVERSIONS' ||  adSetFormik.values.optimization_goal === 'VALUE') ? (
                                  <Box>
                                      <Divider my={4}/>
                                      <Box>
                                          <Flex alignItems="center" justifyContent="space-between">
                                              <Flex flexDirection={'column'} gap={2}>
                                                  <Button onClick={() => setAttributeIsExpanded(!isAttributeExpanded)} variant="link" rightIcon={isAttributeExpanded ? <ChevronUpIcon color={'gray.800'} /> : <ChevronDownIcon color={'gray.800'} />}>
                                                      <Text fontSize={'14px'} color={'gray.800'}>{isAttributeExpanded ? 'Hide Options' : 'Show More Options'}</Text>
                                                  </Button>
                                              </Flex>
                                          </Flex>
                                      </Box>
  
                                      <Collapse in={isAttributeExpanded}>
                                          <Flex direction="column" gap={2} pt={4}>
                                              <Flex align={'center'} mt={'10px'}>
                                                  <Text fontWeight="bold">Attribution setting</Text>
                                                      <Box>
                                                          <Tooltip
                                                              borderRadius={5}
                                                              label={
                                                                  <Box fontSize="12px" p={'5px'}>
                                                                      <Text textAlign={'justify'}>
                                                                          The attribution setting is a finite period of time during which conversions can be credited to your ads and used to inform campaign optimisation. For example, with 1-day engaged-view, our system will learn from conversions that happen within 1 day and show ads to people most likely to convert in a day. Multiple attribution settings can be selected for reporting. You can see more details when you use Compare attribution settings in the campaign table.
                                                                      </Text>
                                                                  </Box>
                                                              }
                                                              fontSize="md"
                                                          >
                                                              <InfoOutlineIcon pointerEvents="all" ml={2} />
                                                          </Tooltip>
                                                      </Box>
                                              </Flex>
                                              <AttributeSetting
                                                   setFieldValue={adSetFormik.setFieldValue}
                                                   attribution_spec={adSetFormik.values.attribution_spec}
                                                   optimization_goal={adSetFormik.values.optimization_goal}
                                                />
                                          </Flex>
                                      </Collapse>
                                  </Box>
                                ):(null)}
                            </Flex>

                            {/* Budget Scheduling */}
                            <Flex bg="gray.50" p={4} flexDirection="column" borderRadius="md" gap={4} mt={4}>
                                <Text fontWeight="bold">Budget & schedule</Text>
                                {/* Budget */}
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">{budget.label}</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text>
                                                    Your ad set budget is the daily or lifetime amount that you want to spend on this ad set.
                                                    A daily budget is the average that you'll spend every day. A lifetime budget is the maximum that you'll spend during the lifetime of your ad set.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>
                                <Flex>
                                    <Select
                                        name="budget"
                                        value={budgetType}
                                        onChange={handleBudgetChange}
                                        onBlur={adSetFormik.handleBlur}
                                        bg="#fff"
                                        borderRadius={'md'}
                                        borderRight={'none'}
                                        borderRightRadius="0"
                                        maxW={'200px'}
                                        size={'sm'}
                                    >
                                        {budget.options?.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                    {budgetType === 'daily_budget' && (
                                        <InputGroup>
                                            <Input
                                                name="daily_budget"
                                                placeholder={currencySymbol}
                                                type="number"
                                                value={String(adSetFormik.values.daily_budget)}
                                                onChange={(e) => adSetFormik.setFieldValue('daily_budget', e.target.value)}
                                                onBlur={adSetFormik.handleBlur}
                                                borderRight="0"
                                                borderLeftRadius="none"
                                                size={'sm'}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            <InputRightAddon height={'32px'} fontSize={'14px'}>
                                                {currencyCode}
                                            </InputRightAddon>
                                        </InputGroup>
                                    )}
                                    {budgetType === 'lifetime_budget' && (
                                        <InputGroup>
                                            <Input
                                                name="lifetime_budget"
                                                placeholder={currencySymbol}
                                                type="number"
                                                value={adSetFormik.values.lifetime_budget}
                                                onChange={(e) => adSetFormik.setFieldValue('lifetime_budget', e.target.value)}
                                                onBlur={adSetFormik.handleBlur}
                                                borderRight="0"
                                                borderLeftRadius="none"
                                                size={'sm'}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            <InputRightAddon height={'32px'} fontSize={'14px'}>
                                                {currencyCode}
                                            </InputRightAddon>
                                        </InputGroup>
                                    )}
                                </Flex>
                                {adSetFormik.touched.budget && adSetFormik.errors.budget && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.budget}</Text>
                                )}
                                {adSetFormik.values.budget === 'daily_budget' && adSetFormik.touched.daily_budget && adSetFormik.errors.daily_budget && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.daily_budget}</Text>
                                )}
                                {adSetFormik.values.budget === 'lifetime_budget' && adSetFormik.touched.lifetime_budget && adSetFormik.errors.lifetime_budget && (
                                    <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.lifetime_budget}</Text>
                                )}

                                {/* Schedule */}
                                <Flex alignItems={'center'} mt={2}>
                                    <Text fontWeight="bold">Schedule</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text>
                                                    You can choose to run your ads continuously starting today or only during a specific date range.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>

                                <Flex direction="column" gap={2}>
                                    {/* Start Date */}
                                    <Text fontWeight={'bold'}>Start date</Text>
                                    <Flex alignItems="center" gap={6}>
                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={1} bg="white">
                                            <Flex alignItems="center" gap={2}>
                                                <CalendarIcon ml={1} />
                                                <DatePicker
                                                    selected={startDate}

                                                    onChange={(date) => setStartDate(date)}

                                                    minDate={toZonedTime(new Date(), timeZone)}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    customInput={
                                                        <input
                                                            type="text"
                                                            placeholder={''}
                                                            style={{
                                                                fontSize: '12px',
                                                                padding: '4px',
                                                                border: 'none',
                                                                width: '210px',
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Flex>
                                        </Box>
                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={'8px'} bg="white">
                                            <Flex alignItems="center" gap={2}>
                                                <TimeIcon />
                                                <MinuteSecondSpinner value={startTime} onChange={(newTime)=>setStartTime(newTime)} />
                                                <Text fontSize="14px" color={'gray'}>{timeZoneAbbreviation}</Text>
                                            </Flex>
                                        </Box>
                                    </Flex>

                                    {/* End Date */}
                                    {(adSetFormik.values.budget === 'daily_budget' || adSetFormik.values.budget === 'lifetime_budget') && (
                                        <Flex direction="column" gap={2} mt={4}>
                                            {adSetFormik.values.budget === 'daily_budget' && (
                                                <Checkbox
                                                    colorScheme='orange'
                                                    isChecked={showEndDate}
                                                    onChange={() => setShowEndDate(!showEndDate)}
                                                >
                                                    Set an end date
                                                </Checkbox>
                                            )}
                                            {(adSetFormik.values.budget === 'lifetime_budget' || showEndDate) && (
                                                <>
                                                    <Text fontWeight={'bold'}>End date</Text>
                                                    <Flex alignItems="center" gap={6}>
                                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={1} bg="white">
                                                            <Flex alignItems="center" gap={2}>
                                                                <CalendarIcon ml={1} />
                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date) => setEndDate(date)}
                                                                    minDate={toZonedTime(new Date(), timeZone)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={15}
                                                                    timeCaption="Time"
                                                                    customInput={
                                                                        <input
                                                                            type="text"
                                                                            placeholder={"dd/mm/yyyy"}
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                padding: '4px',
                                                                                border: 'none',
                                                                                width: '210px',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Flex>
                                                        </Box>
                                                        <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={'8px'} bg="white">
                                                            <Flex alignItems="center" gap={2}>
                                                                <TimeIcon />
                                                                <MinuteSecondSpinner value={endTime} onChange={(newTime)=>setEndTime(newTime)} />
                                                                <Text fontSize="14px" color={'gray'}>{timeZoneAbbreviation}</Text>
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                    <Flex>
                                                        {errorDateMessage && <Text fontSize={'12px'} color="red.500">{errorDateMessage}</Text>}
                                                    </Flex>
                                                </>
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                                <Flex alignItems={'center'} mt={2}>
                                    <Text fontWeight="bold">Ad scheduling</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text>
                                                    You can schedule your ads to deliver on specific hours and days of the week based on the viewer's or your ad account's time zone.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>
                                <Flex>
                                    <Tooltip
                                        borderRadius={5}
                                        p={2}
                                        ml={20}
                                        label="Ad scheduling is only available when you use lifetime budget."
                                        isDisabled={adSetFormik.values.budget === 'lifetime_budget'}
                                    >
                                        <span>
                                            <Checkbox
                                                colorScheme='orange'
                                                isChecked={isScheduled}
                                                onChange={(e) => setIsScheduled(e.target.checked)}
                                                isDisabled={adSetFormik.values.budget !== 'lifetime_budget'}
                                            >
                                                Run ads on a schedule
                                            </Checkbox>
                                        </span>
                                    </Tooltip>
                                </Flex>
                                {isScheduled && adSetFormik.values.budget === 'lifetime_budget' && (
                                    <>
                                        <Flex mb={4} direction={'column'} gap={2}>
                                            <Select
                                                value={timezone}
                                                onChange={(e) => setTimezone(e.target.value)}
                                                size={'sm'}
                                                borderRadius="md"
                                            >
                                                <option value="USER">Use viewer's time zone</option>
                                                <option value="ADVERTISER">Use this ad account's time zone</option>
                                            </Select>
                                            {timezone == "USER" && <Text fontSize={'12px'}>We'll schedule your ads based on the time zone of the person seeing it.</Text>}
                                            {timezone == "ADVERTISER" && <Text fontSize={'12px'}>We'll schedule your ads based on ad account's time zone</Text>}
                                        </Flex>
                                        <AdScheduler onAdScheduleChange={handleAdScheduleChange} adsetScheduleData={[]} />
                                    </>
                                )}
                            </Flex>

                            {/* Audience controls  */}
                            <Flex bg="gray.50" p={4} flexDirection="column" borderRadius="md" gap={4} mt={4} overflow="visible" zIndex={1000} >
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">Audience controls</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p={'5px'}>
                                                <Text>
                                                    <strong>Setting audience controls </strong><br></br>
                                                    With Advantage+ audience, our system searches within these criteria for a more specific audience. To give our system flexibility to get results, we recommend setting these controls to create a broad audience.
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" ml={2} />
                                    </Tooltip>
                                </Flex>
                                {/* Targeting */}
                                <Flex flexDirection="column">
                                    <Flex alignItems={'center'}>
                                        <Text fontWeight="bold">{targeting.geo_locations.label}</Text>
                                        <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="sm" p={'5px'}>
                                                    <Text>
                                                        Choosing a broad area to show your ads within can improve results. For example, by adding a country instead of several cities.
                                                    </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                                        </Tooltip>
                                    </Flex>
                                    <Text fontSize={'14px'}>Reach people living in or recently in this location.</Text>
                                    <GeoLocationsUpdateSelector
                                        preFilledExcludedLocations={''}
                                        preFilledIncludedLocations={''}
                                        onLocationsChange={handleLocationsChange}
                                        onBlur={adSetFormik.handleBlur}
                                        selectedZipCodes={zipCodes}
                                        adsAccountData={campaignData?.adsAccount?.adsAccountId}
                                    />
                                    {adSetFormik.touched.geo_locations && adSetFormik.errors.geo_locations && (
                                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.geo_locations}</Text>
                                    )}
                                </Flex>
                                <Link fontWeight={'bold'} fontSize={'xs'} textDecoration={'underline'} onClick={() => setisBulkLocationSelectorVisible(true)}>Add locations in bulk</Link>
                                <BulkLocationSelector
                                    isVisible={isBulkLocationSelectorVisible}
                                    onClose={() => setisBulkLocationSelectorVisible(false)}
                                    onSubmit={(zips) => {
                                        setzipCodes(zips)
                                        let selectedLocations = adSetFormik.values.geo_locations
                                        adSetFormik.setFieldValue('geo_locations', [...new Set([...selectedLocations, ...zips])]);
                                    }}
                                    adsAccountId={campaignData?.adsAccount?.adsAccountId}
                                />
                                <Divider />

                                {/* Age Gender & Detailed targeting */}
                                <Box>
                                    <Box>
                                        <Flex alignItems="center" justifyContent="space-between">
                                            <Flex flexDirection={'column'} gap={2}>
                                                <Text fontWeight={'bold'}>Audience</Text>
                                                <Button onClick={() => setIsExpanded(!isExpanded)} variant="link" rightIcon={isExpanded ? <ChevronUpIcon color={'gray.800'} /> : <ChevronDownIcon color={'gray.800'} />}>
                                                    <Text fontSize={'14px'} color={'gray.800'}>{isExpanded ? 'Hide Options' : 'Show More Options'}</Text>
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Box>

                                    {/* Collapsible content */}
                                    <Collapse in={isExpanded}>
                                        <Flex direction="column" gap={4} pt={4}>
                                            {/* Audience + */}
                                            {showAdvantage ? null : (
                                                <Flex direction="column">
                                                    <Card borderLeft="4px solid green">
                                                        <Flex gap={2} p={3} direction={'column'}>
                                                            <Flex gap={2}>
                                                                <SlGraph color='green'/>
                                                                    <Text fontSize="12px" fontWeight={'bold'}>
                                                                        You could get 33% lower cost per result with Advantage+ audience
                                                                    </Text>
                                                                    <Tooltip
                                                                        borderRadius={5}
                                                                        label={
                                                                            <Box fontSize="12px" p={'5px'}>
                                                                                <Text >
                                                                                    This result is based on an experiment run between March 2023 and June 2023. The experiment compared the results of ad sets delivered using Advantage+ audience versus the original audience for 16 advertisers. This is provided to give you an idea of possible performance, but performance isn't guaranteed.
                                                                                </Text>
                                                                            </Box>
                                                                        }
                                                                        fontSize="md"
                                                                    >
                                                                        <InfoOutlineIcon pointerEvents="all"/>
                                                                    </Tooltip>
                                                            </Flex>
                                                            <Flex px={4} direction={'column'}>
                                                                <Text fontSize={'12px'}>
                                                                    Let our advanced ad technology automatically find the audience for this campaign to help improve performance. You can still set limits to control who can and can't see your ads.
                                                                </Text>
                                                                <Flex py={2}>
                                                                    <Button size={'sm'} bg={'none'} border={'1px solid gray'} onClick={() => setShowAdvantage(true)}>
                                                                        Use Advantage+ audience
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    </Card>
                                                </Flex>
                                            )}

                                            <Flex direction="column">
                                                <Text fontWeight="bold">Detailed targeting</Text>
                                                <DetailedTargetingSelector preFilledTargets={''} adsAccounts={adsAccount} onInterestsChange={handleInterestsChange} adsAccountId={campaignData?.adsAccount?.adsAccountId} />
                                            </Flex>
                                            <AgeGenderSelectorForCampaigns
                                                  onChange={handleAgeGenderChange} 
                                                  showAdvantageAudience={showAdvantage}
                                                  ageRange={adSetFormik.values.ageRange}
                                                />
                                            <Box>
                                                <Flex align={'center'} mt={'10px'}>
                                                    <Text fontWeight="bold">Languages</Text>
                                                    <Box>
                                                        <Tooltip
                                                            borderRadius={5}
                                                            label={
                                                                <Box fontSize="sm" p={'5px'}>
                                                                    <Text>
                                                                        Enter a language to show your ads to people who use a language that isn't common to your location. Otherwise, leave this blank.
                                                                    </Text>
                                                                </Box>
                                                            }
                                                            fontSize="md"
                                                        >
                                                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                                                        </Tooltip>
                                                    </Box>
                                                </Flex>
                                                <LocaleUpdateSelector
                                                    adsAccountId={campaignData?.adsAccount?.adsAccountId}
                                                    preFilledLocales={''}
                                                    onLocalesChange={(locales) => adSetFormik.setFieldValue('locales', locales)}
                                                />
                                            </Box>
                                            {showAdvantage ? (
                                                <Box>
                                                    <Divider />
                                                    <Flex py={'10px'} direction={'column'} gap={2}>
                                                        <Box display={'flex'} direction={'row'} gap={2} alignItems={'center'}>
                                                            <Text fontWeight="bold">Advantage+ audience</Text>
                                                            <BsStars />
                                                        </Box>
                                                        <Text fontSize={'12px'}>Our AI finds audiences for your ads. If you add an audience suggestion, you'll guide our AI towards people that you think are likely to respond. </Text>
                                                    </Flex>
                                                    <AdvantagePlusAudience  preFilledData = {''} ageOriginal={adSetFormik.values.ageOriginal} onChange={handleAgeRangeChange}  showAdvantage={showAdvantage}/>
                                                    <Divider my={2}/>
                                                    <Flex justifyContent={'flex-end'}>
                                                        <Link fontWeight={'bold'} fontSize={'sm'} textDecoration={'underline'} onClick={() => setShowAdvantage(false)}>Switch to original audience</Link>
                                                    </Flex>
                                                </Box>
                                            ):null}
                                        </Flex>
                                    </Collapse>
                                </Box>
                            </Flex>

                            {/* Placements Section */}
                            <Flex bg="gray.50" flexDirection="column" borderRadius="md" mt={4}>
                                <PlacementsSelector
                                    currentAdsetData={''}
                                    optimization_goal={adSetFormik.values.optimization_goal}
                                    objective={objective}
                                    buying_type={buying_type}
                                    billing_event={adSetFormik.values.billing_event}
                                    destination_type={adSetFormik.values.destination_type}
                                    onPlacementsChange={setPlacements}
                                    account_id={firstAdAccountId}
                                    adsAccountData={campaignData?.adsAccount?.adsAccountId}
                                />
                            </Flex>

                            {/* Submit Button */}
                            <Flex justify="flex-start" my={'20px'} gap={2}>
                                <Button size={'sm'} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme="orange"
                                    isLoading={adSetFormik.isSubmitting}
                                    onClick={handleSave}
                                    size={'sm'} >Save</Button>
                            </Flex>
                        </form>
                    </Box>
                </Flex>
                <Flex flex={0.4} direction="column" position="sticky" top="0" maxHeight="100vh" overflowY="auto">
                    <Audience adsAccount={campaignData?.adsAccount?.adsAccountId} targeting_spec={buildTargetingSpec} optimizationGoal={adSetFormik.values?.optimization_goal} />
                </Flex>
            </Flex>
        </Box>
    );
}

export default CreateLeadsForm;
