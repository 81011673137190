import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Radio, RadioGroup, Table, Thead, Tbody, Tr, Th, Td,
} from '@chakra-ui/react';
import BuildUrlParamsUpdate from 'components/adspresso/BuildUrlParamsUpdate';
import UrlParametersUpdate from 'components/adspresso/UrlParametersUpdate';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from "react-redux";
import { useCreateAdMutation, useGetInstantFormsByPageIdQuery } from 'store/campaigns/adsApi';
import { selectSelectedAdset } from 'store/campaigns/campaignsSlice';
import * as Yup from 'yup';

const CreateSingleAdForm = ({ call_to_action_options, call_to_action_options_instantForms, onFormValuesChange, adFormik, objective, adsAccountData, onSuccess, onClose, onSave}) => {
  
  const toast = useToast();
  const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);

  const selectedAdset = useSelector(selectSelectedAdset);
  const [isUrlModalOpen, setUrlModalOpen] = useState(false);
  const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);

  const [createAd] = useCreateAdMutation();

  const pageId = adFormik?.values?.page_id;

  const formattedPageIds = Array.isArray(pageId) ? pageId : [pageId];

  const { data: instantForms } = useGetInstantFormsByPageIdQuery(
    { pageIds: formattedPageIds },
    { skip: !formattedPageIds.length || selectedAdset?.destination_type !== 'ON_AD' }
  );


  const validationSchema = Yup.object().shape({
      name: Yup.string().required('Name is required'),
      cpa: Yup.number()
          .typeError('must be a number')
          .positive('must be a positive number'),
      creative: Yup.object().shape({
          object_story_spec: Yup.object().shape({
              link_data: Yup.object().shape({
                    destination_type: Yup.string(),
                    link: Yup.string()
                        .when('destination_type', {
                            is: (value) => value === 'WEBSITE',
                            then: () => Yup.string()
                                .required('URL is required'),
                            otherwise: () => Yup.string().notRequired(),
                    }),
                  image_hash: Yup.string().required('Creative is required'),
                  call_to_action: Yup.object().shape({
                      type: Yup.string().required('Call to action type is required'),
                      value: Yup.object().shape({
                        destinationType: Yup.string(),
                        link: Yup.string()
                            .when('destinationType', {
                                is: (value) => value === 'PHONE_CALL',
                                then: () => Yup.string()
                                    .transform((value) => value.slice(2)) // Remove the first two digits (e.g., country code like "91")
                                    .matches(/^[0-9]{10,15}$/, 'Phone number must be at least 10 digits') // Ensure remaining digits are at least 10 digits
                                    .required('Phone number is required'), // Ensure phone number is provided
                                otherwise: () => Yup.string().notRequired(),
                            }),
                      }),
                  }),
              }),
          })
      }),
      image_url: Yup.string().url('Invalid URL'),
  });

  const formik = useFormik({
      initialValues: {
          name: adFormik.values.name || '',
          hash: '',
          cpa:  '',
          adType:'image',
          creative: {
              name: '',
              url_tags:'',
              object_story_spec: {
                  link_data: {
                      destination_type: selectedAdset?.destination_type ? selectedAdset?.destination_type : '',
                      call_to_action: {
                          type: selectedAdset?.destination_type === 'WEBSITE' ? 'SHOP_NOW' : (selectedAdset?.destination_type === 'PHONE_CALL' ? 'CALL_NOW' : 'SIGN_UP'),
                          value: {
                            destinationType: selectedAdset?.destination_type ? selectedAdset?.destination_type : '',
                            link:''
                          },
                      },
                      message: '',
                      name: '',
                      description: '',
                      link: '',
                      image_hash: '',
                      video_id: ''
                  },
                  page_id: adFormik.values?.page_id,
                  instagram_actor_id: adFormik.values?.instagram_actor_id,
              },
          },
          enroll_status: adFormik.values.enroll_status ? adFormik.values.enroll_status : 'OPT_OUT',
          image_url: '',
          picture: '',
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting }) => {

          const formatPhoneNumber = (phoneNumber) => {

              if (!phoneNumber.startsWith('tel:+')) {
                  return `tel:+${phoneNumber}`;
              }
              return phoneNumber;
          };

        const adcreativePayload = values?.adType === 'video' ? {
            adcreative: {
                name: values.creative.object_story_spec.link_data.name,
                hash: values.hash,
                ...(values.creative.url_tags?.trim() && { url_tags: values.creative.url_tags}),
                object_story_spec: {
                    video_data: {
                        image_url: values?.image_url,
                        ...(values.creative.object_story_spec.link_data.message?.trim() && { 
                            message: values.creative.object_story_spec.link_data.message 
                          }),
                        video_id: values.creative.object_story_spec.link_data.image_hash,
                         // title: values.creative.object_story_spec.video_data.title,
                        link_description: values.creative.object_story_spec.link_data.description,
                        call_to_action: {
                            type: values.creative.object_story_spec.link_data.call_to_action.type,
                            value: selectedAdset?.destination_type === 'ON_AD' 
                                ? {
                                    lead_gen_form_id: values.creative.object_story_spec.link_data.call_to_action?.value?.link
                                }
                                : {
                                    link: values.creative.object_story_spec.link_data.call_to_action.type === "CALL_NOW"
                                        ? formatPhoneNumber(values.creative.object_story_spec.link_data.call_to_action.value.link)
                                        : values.creative.object_story_spec.link_data.link,
                                }
                        }                        
                    },
                    page_id: values.creative.object_story_spec.page_id,
                    instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                },
                contextual_multi_ads:{
                    enroll_status: values?.enroll_status
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: "OPT_OUT",
                        }
                    }
                },
            },
        } : {
            adcreative: {
                name: values.creative.object_story_spec.link_data.name,
                hash: values.hash,
                image_url: values?.image_url,
                ...(values.creative?.url_tags?.trim() && { url_tags: values.creative.url_tags }),
                object_story_spec: {
                    link_data: {
                        ...(values.creative.object_story_spec.link_data.message?.trim() && { 
                            message: values.creative.object_story_spec.link_data.message 
                        }),
                        description: values.creative.object_story_spec.link_data.description,
                        image_hash: values.creative.object_story_spec.link_data.image_hash,
                        //   name: values.creative.object_story_spec.link_data.name,
                        link: selectedAdset?.destination_type === 'ON_AD'
                            ? "http://fb.me/"
                            : (values.creative.object_story_spec.link_data.link
                                ? values.creative.object_story_spec.link_data.link
                                : `https://facebook.com/${values.creative.object_story_spec.page_id}/`),
                        call_to_action: {
                            type: values.creative.object_story_spec.link_data.call_to_action.type,
                            value: selectedAdset?.destination_type === 'ON_AD'
                                ? {
                                    lead_gen_form_id: values.creative.object_story_spec.link_data.call_to_action?.value?.link
                                }
                                : values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW'
                                && {
                                    link: values.creative.object_story_spec.link_data.call_to_action?.value?.link
                                        ? formatPhoneNumber(values.creative.object_story_spec.link_data.call_to_action.value.link)
                                        : undefined
                                }
                        }                        
                    },
                    page_id: values.creative.object_story_spec.page_id,
                    instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                },
                contextual_multi_ads:{
                    enroll_status: values?.enroll_status
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: "OPT_OUT",
                        },
                    },
                },
            },
        };
        
        const payload = {
            ...adcreativePayload,
            name: values.name,
            cpa: Number(values.cpa),
            adset_id: selectedAdset?.adset_id,
            adsAccount: {
                id: adsAccountData?.id,
                adsAccountId: adsAccountData?.account_id,
                adsAccountName: adsAccountData?.name,
                businessName: adsAccountData?.business?.name,
                businessId: adsAccountData?.business?.id,
            },
        };
        

          createAd({ payload })
              .unwrap()
              .then(response => {
                  onSuccess();
                  toast({
                      position: "top-right",
                      title: "Ad Created",
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .catch(error => {
                  toast({
                      position: "top-right",
                      title: "Error creating Ad",
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .finally(() => {
                  setSubmitting(false);
                  onClose();
              });
      },
  });

  const handleMediaTypeChange = (type) => {
      setMediaType(type)
      setIsDriveModalOpen(true);
      formik.setFieldValue('adType',type)
  };

  const handleMediaSelect = (file) => {
      if (file && file.length > 0) {
          const selectedFile = file[0];

          formik.setFieldValue('hash', selectedFile?.hash);
          formik.setFieldValue('image_url', selectedFile?.url ? selectedFile?.url : selectedFile?.picture);
          formik.setFieldValue('creative.object_story_spec.link_data.image_hash', selectedFile?.creativeId);
      }
      setIsDriveModalOpen(false);
  };

  useEffect(() => {
    onFormValuesChange(formik.values);
  }, [formik.values, onFormValuesChange]); 

  const handleUrlChange = (constructedUrl) => {
    formik.setFieldValue('creative.object_story_spec.link_data.link', constructedUrl); 
  };

  const handleUrlParamChange = (constructedUrl) => {
    formik.setFieldValue('creative.url_tags', constructedUrl); 
  };

  const filteredInstantForms = instantForms?.length > 0 && instantForms
        .map((formResponse) => formResponse?.data?.data) // Get the 'data' array from each formResponse
        .flat() // Flatten the array to get all forms in one array
        .filter((form) => form?.page_id === pageId); 

  return (
    <Box direction={'column'} gap={4} width={'100%'}>
        <form onSubmit={formik.handleSubmit}>
            
                <Flex direction={'column'} gap={'10px'} borderRadius="md" bg="gray.50" p={4} mb={4}>

                    <Flex direction="column">
                      <Select
                        placeholder={adFormik?.values?.format === 'CAROUSEL' ? "Add cards" : "Add media"}
                        width={'150px'}
                        name="mediaOption"
                        borderRadius={'md'}
                        value={mediaType || ""}
                        onChange={(e) => {
                          handleMediaTypeChange(e.target.value);
                        }}
                        size="sm"
                      >
                        <option value="image">{adFormik?.values?.format === 'CAROUSEL' ? "Add image cards" : "Add image"}</option>
                        <option value="video">{adFormik?.values?.format === 'CAROUSEL' ? "Add video cards" : "Add video"}</option>
                      </Select>
                    </Flex>
                    
                    {formik.touched.creative?.object_story_spec?.link_data?.image_hash && formik.errors.creative?.object_story_spec?.link_data?.image_hash && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.image_hash}</Text>
                    )}

                    <Divider my={1}/>

                    {formik.values.image_url && (
                      <Box role="group" position="relative" w="80px" h={'80px'} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
                        <Image
                          src={formik.values.image_url}
                          alt={formik.values.name}
                          objectFit="cover"
                          width="100%"
                          height="100%"
                          borderRadius="3px"
                        />            

                        <Icon
                          top={'-5px'}
                          right={'-5px'}
                          position="absolute"
                          color={'red.500'}
                          _hover={{ color: 'gray.400' }}
                          as={IoMdCloseCircle}
                          cursor="pointer"
                          boxSize={4}
                          onClick={() => 
                            formik.setFieldValue('image_url', '')
                          }
                          bg={'white'}
                          borderRadius={'50%'}
                        />
                      </Box>
                    )}

                    <Flex direction="column" gap={1}>
                        <Flex alignItems="center">
                            <Text fontWeight="bold" fontSize="14px" mr={2}>Primary text</Text>
                            {/* <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text> */}
                            <Tooltip
                                borderRadius={5}
                                label={
                                    <Box fontSize="14px" p="5px">
                                    <Text>
                                        The primary text for your ad appears in most placements, though the position
                                        varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                                        characters or less.
                                    </Text>
                                    </Box>
                                }
                                fontSize="md"
                            >
                            <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                        </Flex>
                        <Textarea
                            name="creative.object_story_spec.link_data.message"
                            placeholder="Message"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.message}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Flex alignItems="center" gap={2}>
                            <Text fontWeight="bold" fontSize={'14px'}>Headline</Text>
                            <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p="5px">
                                <Text>
                                    Add up to five brief headlines to let people know what your ad is about. Each
                                    headline can have a maximum of 255 characters. Headlines won't appear in all
                                    placements.
                                </Text>
                                </Box>
                            }
                            fontSize="md">
                            <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                        </Flex>
                        <Input
                            name="creative.object_story_spec.link_data.name"
                            placeholder="Enter a headline"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.name}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.name && formik.errors.creative?.object_story_spec?.link_data?.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Flex alignItems="center" gap={2}>
                            <Text fontWeight="bold" fontSize={'14px'}>Description</Text>
                            <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p="5px">
                                <Text>
                                    Add up to five link descriptions to emphasise why people should visit your
                                    website. This won't appear in all placements.
                                </Text>
                                </Box>
                            }
                            fontSize="md">
                            <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                        </Flex>
                        <Textarea
                            name="creative.object_story_spec.link_data.description"
                            placeholder="Include additional details"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.description}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.link_data?.description && formik.errors.creative?.object_story_spec?.link_data?.description && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.description}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Flex alignItems="center" mb={2} gap={2}>
                            <Text fontWeight="bold" fontSize="14px">Call to action</Text>
                            <Tooltip
                                borderRadius={5}
                                label={
                                <Box fontSize="sm" p="5px">
                                    <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                                </Box>
                                }
                                fontSize="md"
                            >
                                <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                        </Flex>

                        {selectedAdset?.destination_type === 'PHONE_CALL' ? (
                            <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                isDisabled
                            >
                                <option value="CALL_NOW">Call Now</option>
                            </Select>
                        ) : selectedAdset?.destination_type === 'ON_AD' && objective === 'OUTCOME_LEADS' ? (
                            <Box direction="column" mb={'10px'}>
                             
                             <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                            >
                                 {call_to_action_options_instantForms?.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                  ))}
                              </Select>
                            </Box>
                          ) : (
                            <Select
                                name="creative.object_story_spec.link_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                            >
                                {call_to_action_options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        )}

                        {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type &&
                            formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                <Text fontSize="12px" color="red.500">
                                    {formik.errors.creative.object_story_spec.link_data.call_to_action.type}
                                </Text>
                            )}
                    </Flex>

                  {selectedAdset?.destination_type === 'PHONE_CALL' ? (
                      <Flex direction="column" gap={1}>
                          <Flex alignItems="center" mt={'10px'} mb={2}>
                            <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                            <Tooltip
                                borderRadius={5}
                                label={
                                    <Box fontSize="sm" p="5px">
                                    <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                    </Box>
                                }
                                fontSize="md"
                                >
                                <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                          </Flex>
                          <PhoneInput
                              name="creative.object_story_spec.link_data.call_to_action.value.link"
                              value={formik.values.creative.object_story_spec.link_data.call_to_action.value.link}
                              onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)}
                              country={'in'}
                          />
                          {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.value.link && formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value.link && (
                            <Text fontSize="12px" color="red.500">{formik.errors.creative.object_story_spec.link_data.call_to_action.value.link}</Text>
                          )}
                      </Flex>
                  ):null}

                  {selectedAdset?.destination_type === 'WEBSITE' ? (
                    <Flex direction="column" gap={1}>
                        <Flex alignItems="center">
                            <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
                            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                            <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p="5px">
                                <Text>Enter the URL for the webpage you want people to visit</Text>
                                </Box>
                            }
                            fontSize="md"
                            >
                            <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                        </Flex>
                        <Input
                            name="creative.object_story_spec.link_data.link"
                            placeholder="Enter a URL"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.link_data.link}
                            onChange={formik.handleChange}
                        />
                        <Flex mt={2}>
                            <Button onClick={() => {
                                setUrlModalOpen(true)
                            }} 
                            variant="link">
                                <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                            </Button>
                        </Flex>
                        {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                        )}
                    </Flex>
                  ):null}


                {selectedAdset?.destination_type === 'ON_AD' && objective === 'OUTCOME_LEADS' ? (
                    <>
                        <Flex alignItems="center" gap={2} mt={2}>
                            <Text fontWeight="bold">Instant form</Text>
                            <Tooltip 
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p="5px">
                                <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                                </Box>
                            }
                            fontSize="md"
                            >
                            <InfoOutlineIcon pointerEvents="all" />
                            </Tooltip>
                        </Flex>

                        {/* Table Layout */}
                        {filteredInstantForms?.length > 0 ? (
                            <RadioGroup onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)} colorScheme="orange">
                            <Table variant="simple">
                                <Thead>
                                <Tr>
                                    <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                                    <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                                </Tr>
                                </Thead>
                                <Tbody>
                                {filteredInstantForms?.map((form) => (
                                    <Tr key={form?.id}  _hover={{ backgroundColor: '#F9F9F9' }} >
                                    <Td>
                                        <Flex alignItems="center" gap={2}>
                                        <Radio value={form?.id} />
                                        <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                            {form?.name}
                                        </Text>
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <Text fontSize={'12px'}>
                                        {new Date(form?.created_time).getFullYear()}-
                                        {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                        {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                        </Text>
                                    </Td>
                                    </Tr>
                                ))}
                                </Tbody>
                            </Table>
                            </RadioGroup>
                        ) : (
                            <Text fontSize="14px" color="gray.500">No form data available</Text>
                        )}
                    </>
                ) : null}
            </Flex>

            <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                <Text fontWeight={'bold'}>Tracking</Text>
                    <Flex direction="column" gap={1}>
                        <Flex alignItems={'center'}>
                            <Text mb={2} fontWeight="bold">
                                    URL parameters{" "}
                                {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                                <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                            </Text>
                            <Tooltip
                                borderRadius={5}
                                label={
                                    <Box fontSize="sm" p={'5px'}>
                                        <Text >
                                            Add parameters to the end of your website URL to track where your visitors are coming from.
                                        </Text>
                                    </Box>
                                }
                                fontSize="md"
                            >
                                <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                            </Tooltip>
                        </Flex>
                        <Input
                            name="creative.url_tags"
                            placeholder="key1=value1&key2=value2"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.url_tags}
                            onChange={formik.handleChange}
                        />
                        <Flex mt={2}>
                            <Button onClick={() => {
                                setUrlParamModalOpen(true)
                            }} 
                            variant="link">
                                <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                            </Button>
                        </Flex>
                        {formik.touched.creative?.url_tags && formik.errors.creative?.url_tags && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.url_tags}</Text>
                        )}
                    </Flex>
                </Flex>

                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">CPA</Text>
                        <Input
                            name="cpa"
                            placeholder="Enter CPA"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.cpa}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.cpa && formik.errors.cpa && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.cpa}</Text>
                        )}
                    </Flex>
                </Flex>

                <Flex mb={4}>
                    <Button
                        size="sm"
                        colorScheme="orange"
                        isLoading={formik.isSubmitting}
                        type="submit"
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Flex>
        </form>

        <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <BuildUrlParamsUpdate link={formik.values.creative.object_story_spec.link_data} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange}/>
            </ModalBody>
            </ModalContent>
        </Modal> 

        <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
            size={"2xl"}
            isCentered={true}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <UrlParametersUpdate link={formik.values.creative} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange}/>
            </ModalBody>
            </ModalContent>
        </Modal> 



          {/* Drive Modal */}
          <DriveModal
              isDriveModalOpen={isDriveModalOpen}
              selectedAdAccounts={[{
                      id: adsAccountData?.id,
                      adsAccountId: adsAccountData?.account_id,
                      adsAccountName: adsAccountData?.name,
                      businessName: adsAccountData?.business?.name,
                      businessId: adsAccountData?.business?.id
              }]}
              onClose={() => 
                {
                    setIsDriveModalOpen(false);
                    setMediaType(null);
              }}
              onSubmit={handleMediaSelect}
              uploadPurpose="create"
              mediaType={mediaType}
          />
    </Box>
  );
};

export default CreateSingleAdForm;
