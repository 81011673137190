import { api } from '../api'

export const uploadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchImages: builder.query({
      query: ({ pageSize, after, before, searchValue, adsAccountId }) => {
          let url = `/creatives/image/${adsAccountId}?`;
  
          if (pageSize) {
              url += `pageSize=${pageSize}&`;
          }
          if (searchValue) {
              url += `search=${searchValue}&`;
          }
          if (after) {
              url += `after=${after}&`;
          }
          if (before) {
              url += `before=${before}&`;
          }
          // Remove the trailing "&" if it exists
          url = url.endsWith('&') ? url.slice(0, -1) : url;
  
          return { url };
      },
      transformResponse: (response, meta, arg) => response.data,
      // providesTags: ['FileUpload'],
    }),
    fetchVideos: builder.query({
      query: ({ pageSize, after, before, searchValue, adsAccountId }) => {
          let url = `/creatives/video/${adsAccountId}?`;
  
          if (pageSize) {
              url += `pageSize=${pageSize}&`;
          }
          if (searchValue) {
              url += `search=${searchValue}&`;
          }
          if (after) {
              url += `after=${after}&`;
          }
          if (before) {
              url += `before=${before}&`;
          }
          // Remove the trailing "&" if it exists
          url = url.endsWith('&') ? url.slice(0, -1) : url;
  
          return { url };
      },
      transformResponse: (response, meta, arg) => response.data,
      // providesTags: ['FileUpload'],
    }),
    uploadImage: builder.mutation({
      query: ({ payload }) => ({
        url: "/creatives/image",
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ['FileUpload'],
    }),
    uploadVideo: builder.mutation({
      query: ({ payload }) => ({
        url: "/creatives/video",
        method: "POST",
        body: payload,
      }),
    }),
    // Start upload session
    startUploadSession: builder.mutation({
      query: ({file,adsAccount,businessId}) => ({
        url: `/creatives/video/?upload_phase=start&file_size=${file.size}&adsAccount=${adsAccount}&businessId=${businessId}&file_name=${file.name}`,
        method: 'POST',
      }),
    }),
    // Upload file chunk
    uploadChunk: builder.mutation({
      query: ({ upload_session_id, start_offset, end_offset, chunk, file_size, adsAccount, businessId }) => {
        
        const formData = new FormData();
        formData.append("video_file_chunk", chunk);

        return{
          url: `/creatives/video/?upload_phase=transfer&adsAccount=${adsAccount}&businessId=${businessId}&upload_session_id=${upload_session_id}&start_offset=${start_offset}&end_offset=${end_offset}&file_size=${file_size}`,
          method: 'POST',
          body :formData,
          formData:true, 
        }
      },
    }),
    // Finish the upload
    finishUpload: builder.mutation({
      query: ({ upload_session_id,video_id,file,adsAccount,payload }) => {

        return{
          url: `/creatives/video/?upload_phase=finish&upload_session_id=${upload_session_id}&file_size=${file.size}&video_id=${video_id}&file_name=${file.name}`,
          method: 'POST',
          body: payload,
       }     
      },
    }),
    getVideoById: builder.query({
      async queryFn({ CreativeIds }, _queryApi, _extraOptions, fetchWithBQ) {

        try {
          // Fetching all results for the list of CreativeIds
          const requests = CreativeIds.map((creativeId) =>
            fetchWithBQ(`/creatives/video/get/${creativeId}`)
          );
          const results = await Promise.all(requests);
          
          const hasErrors = results.some((result) => result.error);
          if (hasErrors) {
            return { error: { message: 'One or more requests failed.' } };
          }

          // Combining all the data from the results
          const data = results.map((result) => result.data);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
})

export const {
  useFetchImagesQuery,
  useFetchVideosQuery,
  useUploadImageMutation,
  useUploadVideoMutation,
  useStartUploadSessionMutation,
  useUploadChunkMutation,
  useFinishUploadMutation,
  useGetVideoByIdQuery
} = uploadApi;