import { AddIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import ReactSelect from 'react-select';
import { useCreateAdRuleMutation } from 'store/adRules/adRuleApi';
import { removeEmptyValues } from 'utils/functions';
import { createRuleSchema } from 'utils/schema';
import AdSelection from './AdSelection';
import currencySymbolMap from 'currency-symbol-map'; 
import { useSelector } from "react-redux";


const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      times.push({ value, label: value });
    }
  }
  return times;
};

const timeOptions = generateTimeOptions();

const convertTimeToMinutes = (time) => {
  if (!time) return "0"; // Default to 0 if no time is provided
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 60 + minutes).toString();
};

function CreateRuleForm({ isOpen, onClose, adAccounts, onSuccess }) {

  const toast = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAds, setSelectedAds] = useState({});
  const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
  const currencyCode = activeAccountsData?.data[0]?.currency;
  const currencySymbol = currencySymbolMap(currencyCode);

  const [customSchedules, setCustomSchedules] = useState(daysOfWeek.map(() => ({
    days: [],
    timeSlots: [{ startTime: '', endTime: '' }]
  })));

  const [createAdRule, { isLoading: isAdRuleCreating, error: createError }] = useCreateAdRuleMutation();

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSelectedAdsChange = (newSelectedAds) => {
    setSelectedAds(newSelectedAds);  
  };

  const createRuleFormik = useFormik({
    initialValues: {
      ruleName: '',
      applyTo: 'AD',
      action: 'PAUSE',
      conditionField: 'cost_per',
      conditionOperator: 'GREATER_THAN',
      conditionValue: '',
      conditionValue1: '',
      conditionValue2: '',
      timeRange: 'MAXIMUM',
      schedule: 'SEMI_HOURLY',
      notification: true,
      customSchedules: [],
    },
    validationSchema: createRuleSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {

      const formatValue = (value) => {
        if (value && !isNaN(value)) {
          return parseFloat(value) * 100;
        }
        return value;
      };

      const filters = [
        { field: "entity_type", operator: "EQUAL", value: values.applyTo },
        { field: "time_preset", operator: "EQUAL", value: values.timeRange },
        {
          field: values.conditionField,
          value: values.conditionOperator === 'IN_RANGE' || values.conditionOperator === 'NOT_IN_RANGE'
            ? [formatValue(values.conditionValue1), formatValue(values.conditionValue2)]
            : formatValue(values.conditionValue),
          operator: values.conditionOperator,
        },
      ];

      const selectedAdIds = Object.keys(selectedAds).filter(adId => selectedAds[adId]);

      if (selectedAdIds.length > 0) {
        filters.push({
          field: "ad.id",
          value: selectedAdIds,
          operator: "IN"
        });
      }

      const rulePayload = {
        name: values.ruleName,
        schedule_type: values.schedule,
        evaluation_type: "SCHEDULE",
        schedule: values.customSchedules.flatMap(schedule =>
          schedule.days.length > 0 ?
            schedule.timeSlots.map(timeSlot => ({
              days: schedule.days.map(day => daysOfWeek.indexOf(day)),
              start_minute: convertTimeToMinutes(timeSlot.startTime),
              end_minute: convertTimeToMinutes(timeSlot.endTime),
            })) : []
        ),
        filters,
        execution_type: values.action,
        adsAccount: {
          id: adAccounts?._id,
          adsAccountId: adAccounts?.adsAccountId,
          adsAccountName: adAccounts?.adsAccountName,
          businessName: adAccounts?.businessAccount?.businessName,
          businessId: adAccounts?.businessAccount?.businessId,
        }
      };
      const payload = removeEmptyValues(rulePayload);

      createAdRule(payload).unwrap()
        .then(response => {
          onSuccess();
          toast({
            position: "top-right",
            title: "Ad Rule created ",
            status: "success",
            duration: 4000,
            isClosable: true,
          });

        })
        .catch(error => {
          toast({
            position: "top-right",
            title: "Error creating Ad Rule",
            status: "error",
            description: error?.data?.message,
            duration: 4000,
            isClosable: true,
          });

        })
        .finally(() => {
          setSubmitting(false);
          resetForm();
        });
      onClose();
    },
  });

  const handleDayChange = (index, day) => {
    const newSchedules = [...customSchedules];
    if (newSchedules[index].days.includes(day)) {
      newSchedules[index].days = newSchedules[index].days.filter(d => d !== day);
    } else {
      newSchedules[index].days.push(day);
    }
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleAddTimeSlot = (scheduleIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.push({ startTime: '', endTime: '' });
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleTimeSlotChange = (scheduleIndex, slotIndex, field, value) => {
    const newSchedules = [...customSchedules];

    // Update the time slot based on the field
    newSchedules[scheduleIndex].timeSlots[slotIndex][field] = value;

    if (field === 'startTime') {
      // If both are empty, set endTime to same value(which is start time)
      if (newSchedules[scheduleIndex].timeSlots[slotIndex].endTime === '') {
        newSchedules[scheduleIndex].timeSlots[slotIndex].endTime = value;
      }

      if (newSchedules[scheduleIndex].timeSlots[slotIndex].endTime < value) {
        newSchedules[scheduleIndex].timeSlots[slotIndex].endTime = value;
      }
    } else if (field === 'endTime') {
      if (newSchedules[scheduleIndex].timeSlots[slotIndex].startTime > value) {
        newSchedules[scheduleIndex].timeSlots[slotIndex].startTime = value;
      }
    }

    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleRemoveTimeSlot = (scheduleIndex, slotIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.splice(slotIndex, 1);
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };


  return (
      <Modal isOpen={isOpen} onClose={() => { onClose(); createRuleFormik.resetForm(); setSelectedAds({}); setCurrentStep(0); }} scrollBehavior="inside" isCentered size={"4xl"} >
        <ModalOverlay />
        <ModalContent overflow='hidden' maxHeight={'100vh'}>
          <form onSubmit={createRuleFormik.handleSubmit}>
            <ModalHeader boxShadow="md" p={'12px'}>
              <Text fontWeight="bold" fontSize={'20px'}>Create rule:custom rule</Text>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody flexGrow={1} maxHeight="calc(100vh - 200px)" overflowY="auto">
              {currentStep === 0 && (
                <AdSelection
                  adAccounts={adAccounts}
                  onSelectedAdsChange={handleSelectedAdsChange}
                />
              )}

              {currentStep === 1 && (
                <>
                  <Text mb={4} fontSize={'14px'} >
                    Automatically update campaigns, ad sets, or ads in bulk by creating automated rules.
                  </Text>

                  <Flex direction="column" gap={4}>
                    {/* Rule name */}
                    <Flex direction="column" gap={1}>
                      <Text fontWeight="bold">Rule name</Text>
                      <Input
                        name="ruleName"
                        placeholder="Rule name"
                        fontSize="sm"
                        value={createRuleFormik.values.ruleName}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                      />
                      {createRuleFormik.touched.ruleName && createRuleFormik.errors.ruleName && (
                        <Text color="red.500" fontSize="12px">{createRuleFormik.errors.ruleName}</Text>
                      )}
                    </Flex>

                    {Object.keys(selectedAds).length > 0 && (
                    <Flex gap={2} alignItems={'center'}>
                      <Text>
                        {Object.keys(selectedAds).filter(adId => selectedAds[adId]).length} Ad selected
                      </Text>
                        <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="12px" p="2px">
                            <Text>Your rule will be applied to {Object.keys(selectedAds).filter(adId => selectedAds[adId]).length} ad in your account at the time that the rule is running.
                            </Text>
                          </Box>
                        }
                        fontSize="sm"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                        </Flex>
                    )}

                    {/* Apply rule to */}
                    <Flex direction="column" gap={1}>
                      <Text fontWeight="bold">Apply rule to</Text>
                      <Select
                        name="applyTo"
                        fontSize="sm"
                        value={createRuleFormik.values.applyTo}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                      >
                        {/* <option value="all_active_campaigns">All active campaigns</option>
                    <option value="all_active_ad_sets">All active ad sets</option> */}
                        <option value="AD">All active ads</option>
                      </Select>
                      {createRuleFormik.touched.applyTo && createRuleFormik.errors.applyTo && (
                        <Text color="red.500" fontSize="sm">{createRuleFormik.errors.applyTo}</Text>
                      )}
                    </Flex>

                    {/* Action */}
                    <Flex direction="column" gap={1}>
                      <Text fontWeight="bold">Action</Text>
                      <Select
                        name="action"
                        fontSize="sm"
                        value={createRuleFormik.values.action}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                      >
                        <option value="PAUSE">Turn off ads</option>
                        <option value="UNPAUSE">Turn on ads</option>
                        {/* <option value="send_notification_only">Send notification only</option> */}
                      </Select>
                      {createRuleFormik.touched.action && createRuleFormik.errors.action && (
                        <Text color="red.500" fontSize="12px">{createRuleFormik.errors.action}</Text>
                      )}
                    </Flex>

                    {/* Conditions */}
                    <Flex direction="column">
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Conditions</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>These will be used to evaluate whether the rule's action should be taken on each of your selected ad sets.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <Text fontSize={'12px'}>ALL of the following match</Text>
                      <Flex gap={2} mt={"5px"}>
                        <Flex flexBasis="30%">
                          <Select
                            name="conditionField"
                            fontSize="sm"
                            value={createRuleFormik.values.conditionField}
                            onChange={createRuleFormik.handleChange}
                          >
                            <option value="spent">Spent</option>
                            <option value="cost_per">Cost per result</option>
                            <option value="website_purchase_roas">Website purchase ROAS</option>
                            <option value="cpc">CPC</option>
                            <option value="cpm">CPM</option>
                            <option value="ctr">CTR</option>
                          </Select>
                        </Flex>

                        <Flex flexBasis="30%">
                          <Select
                            name="conditionOperator"
                            fontSize="sm"
                            value={createRuleFormik.values.conditionOperator}
                            onChange={(e) => {
                              createRuleFormik.handleChange(e);
                              if (['IN_RANGE', 'NOT_IN_RANGE'].includes(e.target.value)) {
                                createRuleFormik.setFieldValue('conditionValue', '');
                                createRuleFormik.setFieldValue('conditionValue1', '');
                                createRuleFormik.setFieldValue('conditionValue2', '');

                                createRuleFormik.setFieldTouched('conditionValue', false);
                                createRuleFormik.setFieldTouched('conditionValue1', false);
                                createRuleFormik.setFieldTouched('conditionValue2', false);
                              } else if (['GREATER_THAN', 'LESS_THAN'].includes(e.target.value)) {
                                createRuleFormik.setFieldValue('conditionValue', '');
                                createRuleFormik.setFieldTouched('conditionValue', false);
                              }
                            }}
                          >
                            <option value="GREATER_THAN">is greater than</option>
                            <option value="LESS_THAN">is smaller than</option>
                            <option value="IN_RANGE">is between</option>
                            <option value="NOT_IN_RANGE">is not between</option>
                          </Select>
                        </Flex>

                        <Flex direction="column" flexBasis={['GREATER_THAN', 'LESS_THAN'].includes(createRuleFormik.values.conditionOperator) ? "20%" : "0%"}>
                          {['GREATER_THAN', 'LESS_THAN'].includes(createRuleFormik.values.conditionOperator) && (
                            <Input
                              name="conditionValue"
                              placeholder={currencySymbol}
                              fontSize="sm"
                              value={createRuleFormik.values.conditionValue}
                              onChange={createRuleFormik.handleChange}
                              onBlur={createRuleFormik.handleBlur}
                            />
                          )}
                          {createRuleFormik.touched.conditionValue && createRuleFormik.errors.conditionValue && (
                            <Text color="red.500" fontSize="12px">{createRuleFormik.errors.conditionValue}</Text>
                          )}
                        </Flex>

                        <Flex flexBasis={['IN_RANGE', 'NOT_IN_RANGE'].includes(createRuleFormik.values.conditionOperator) ? "30%" : "0%"}>
                          {['IN_RANGE', 'NOT_IN_RANGE'].includes(createRuleFormik.values.conditionOperator) && (
                            <Flex>
                              <Flex direction="column">
                                <Input
                                  name="conditionValue1"
                                  placeholder=""
                                  fontSize="sm"
                                  value={createRuleFormik.values.conditionValue1}
                                  onChange={createRuleFormik.handleChange}
                                  onBlur={createRuleFormik.handleBlur}
                                />
                                {createRuleFormik.touched.conditionValue1 && createRuleFormik.errors.conditionValue1 && (
                                  <Text color="red.500" fontSize="12px">{createRuleFormik.errors.conditionValue1}</Text>
                                )}
                              </Flex>
                              <Flex direction="column" justifyContent={'center'} >
                                <Text mx={2}>-</Text>
                              </Flex>
                              <Flex direction="column">
                                <Input
                                  name="conditionValue2"
                                  placeholder=""
                                  fontSize="sm"
                                  value={createRuleFormik.values.conditionValue2}
                                  onChange={createRuleFormik.handleChange}
                                  onBlur={createRuleFormik.handleBlur}
                                />
                                {createRuleFormik.touched.conditionValue2 && createRuleFormik.errors.conditionValue2 && (
                                  <Text color="red.500" fontSize="12px">{createRuleFormik.errors.conditionValue2}</Text>
                                )}
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>

                    {/* Time range */}
                    <Flex direction="column" gap={1} mt={'5px'}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Time range</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>This is the number of days of data you'd like to apply your rule to.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>

                      <Select
                        name="timeRange"
                        fontSize="sm"
                        value={createRuleFormik.values.timeRange}
                        onChange={createRuleFormik.handleChange}
                        onBlur={createRuleFormik.handleBlur}
                      >
                        <option value="MAXIMUM">Maximum</option>
                        <option value="TODAY">Today</option>
                        <option value="LAST_7_DAYS">Last 7 days</option>
                        {/* <option value="last_14_days">Last 14 days</option>
                    <option value="last_30_days">Last 30 days</option> */}

                      </Select>
                      {createRuleFormik.touched.timeRange && createRuleFormik.errors.timeRange && (
                        <Text color="red.500" fontSize="sm">{createRuleFormik.errors.timeRange}</Text>
                      )}
                    </Flex>

                    {/* Schedule */}
                    <Flex direction="column" gap={1}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Schedule</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>This will determine how frequently to check the rule. Your rule will be checked in your ad account's time zone.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <RadioGroup
                        name="schedule"
                        value={createRuleFormik.values.schedule}
                        onChange={(value) => {
                          createRuleFormik.setFieldValue('schedule', value);
                          if (value === 'CUSTOM') {
                            const initialCustomSchedules = daysOfWeek.map(() => ({
                              days: [],
                              timeSlots: [{ startTime: '', endTime: '' }],
                            }));
                            createRuleFormik.setFieldValue('customSchedules', initialCustomSchedules);
                            setCustomSchedules(initialCustomSchedules);
                          } else {
                            // Clear customSchedules 
                            createRuleFormik.setFieldValue('customSchedules', []);
                            setCustomSchedules([]);
                          }
                        }}
                      >
                        <Stack spacing={5} direction='column'>
                          <Flex direction="column">
                            <Flex align="center">
                              <Radio colorScheme='orange' value='SEMI_HOURLY' />
                              <Text fontWeight="bold" ml={2}>Continuously</Text>
                            </Flex>
                            <Text fontSize={'sm'} ml={6}>Rule runs as often as possible (usually every 30-60 minutes).</Text>
                          </Flex>

                          <Flex direction="column">
                            <Flex align="center">
                              <Radio colorScheme='orange' value='DAILY' />
                              <Text fontWeight="bold" ml={2}>Daily </Text>
                            </Flex>
                            <Text fontSize={'sm'} ml={6}>at 12.00 am Kolkata Time</Text>
                          </Flex>

                          <Flex direction="column">
                            <Flex align="center">
                              <Radio colorScheme='orange' value='CUSTOM' />
                              <Text fontWeight="bold" ml={2}>Custom </Text>
                            </Flex>
                            <Text fontSize={'sm'} ml={6}>Adjust the rule schedule to run on specific days and at specific times of day.</Text>
                            {createRuleFormik.values.schedule === 'CUSTOM' && (
                              <Flex direction="column" mt={'12px'} ml={'12px'}>
                                {customSchedules.map((schedule, index) => (
                                  <Flex key={index} direction="column" gap={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Flex flexBasis="20%">
                                        <Checkbox
                                          isChecked={schedule.days.includes(daysOfWeek[index])}
                                          onChange={() => handleDayChange(index, daysOfWeek[index])}
                                          colorScheme={'orange'}
                                        >
                                          {daysOfWeek[index]}
                                        </Checkbox>
                                      </Flex>
                                      <Flex flexBasis="35%" style={{ width: '200px' }} justify="center">
                                        <ReactSelect
                                          styles={{
                                            control: (base) => ({
                                              ...base,
                                              width: '200px',
                                              fontSize: "small",
                                            }),
                                            option: (base, state) => ({
                                              ...base,
                                              backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                              color: 'black',
                                              fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                          }}
                                          options={timeOptions}
                                          isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                          value={schedule.timeSlots[0]?.startTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.startTime) : null}
                                          onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'startTime', selectedOption.value)}
                                          placeholder="00:00"
                                        />
                                      </Flex>
                                      <Text fontSize="sm">to</Text>
                                      <Flex flexBasis="35%" justifyContent="center">
                                        <ReactSelect
                                          styles={{
                                            control: (base) => ({
                                              ...base,
                                              width: '200px',
                                              fontSize: "small"
                                            }),
                                            option: (base, state) => ({
                                              ...base,
                                              backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                              color: 'black',
                                              fontSize: "small"
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                          }}
                                          options={timeOptions}
                                          isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                          value={schedule.timeSlots[0]?.endTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.endTime) : null}
                                          onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'endTime', selectedOption.value)}
                                          placeholder="00:00"
                                        />
                                      </Flex>
                                      <Flex flexBasis="10%" justifyContent="center">
                                        <IconButton
                                          icon={<AddIcon />}
                                          onClick={() => handleAddTimeSlot(index)}
                                          aria-label="Add time slot"
                                          colorScheme="orange"
                                        />
                                      </Flex>
                                    </Stack>

                                    {/* Render additional time slots */}
                                    <Flex direction="column" mt={2}>
                                      {schedule.timeSlots.slice(1).map((slot, slotIndex) => (
                                        <Flex key={slotIndex} gap={2} alignItems="center" mb={2}>
                                          <Flex flexBasis="20%">
                                            {/* Keeping the day checkbox empty to align with the first row */}
                                          </Flex>
                                          <Flex flexBasis="35%" justifyContent="center">
                                            <ReactSelect
                                              styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  width: '200px',
                                                  fontSize: "small"
                                                }),
                                                option: (base, state) => ({
                                                  ...base,
                                                  backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                                  color: 'black',
                                                  fontSize: "small"
                                                }),
                                                indicatorSeparator: () => ({
                                                  display: 'none',
                                                }),
                                              }}
                                              options={timeOptions}
                                              isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                              value={slot.startTime ? timeOptions.find(option => option.value === slot.startTime) : null}
                                              onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'startTime', selectedOption.value)}
                                              placeholder="00:00"
                                            />
                                          </Flex>
                                          <Text fontSize="sm">to</Text>
                                          <Flex flexBasis="35%" justifyContent="center">
                                            <ReactSelect
                                              styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  width: '200px',
                                                  fontSize: "small"
                                                }),
                                                option: (base, state) => ({
                                                  ...base,
                                                  backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                                  color: 'black',
                                                  fontSize: "small"
                                                }),
                                                indicatorSeparator: () => ({
                                                  display: 'none',
                                                }),
                                              }}
                                              options={timeOptions}
                                              isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                              value={slot.endTime ? timeOptions.find(option => option.value === slot.endTime) : null}
                                              onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'endTime', selectedOption.value)}
                                              placeholder="00:00"
                                            />
                                          </Flex>
                                          <Flex flexBasis="10%" justifyContent="center">
                                            <IconButton
                                              icon={<CloseIcon />}
                                              onClick={() => handleRemoveTimeSlot(index, slotIndex + 1)}
                                              aria-label="Remove time slot"
                                              colorScheme="red"
                                            />
                                          </Flex>
                                        </Flex>
                                      ))}
                                    </Flex>
                                  </Flex>
                                ))}
                              </Flex>
                            )}
                          </Flex>
                        </Stack>
                      </RadioGroup>
                      {createRuleFormik.touched.schedule && createRuleFormik.errors.schedule && (
                        <Text color="red.500" fontSize="sm">{createRuleFormik.errors.schedule}</Text>
                      )}
                    </Flex>

                    {/* Notification */}
                    <Flex direction="column" gap={2}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Notification</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>This is how you'll be notified that rule conditions have been met.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <Stack spacing={2}>
                        <Flex gap={2}>
                          <Checkbox
                            colorScheme={"orange"}
                            name="notification"
                            isChecked={createRuleFormik.values.notification}
                            onChange={createRuleFormik.handleChange}
                            isDisabled
                          />
                          <Flex direction="column" mt={'12px'}>
                            <Text fontSize="14px" fontWeight="bold">On Facebook</Text>
                            <Text fontSize="12px">You'll receive a notification when conditions for this rule have been met.</Text>
                          </Flex>
                        </Flex>
                      </Stack>
                    </Flex>

                    {/* Subscriber */}
                    <Flex direction="column" gap={1} mb={2}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text fontWeight="bold">Subscriber</Text>
                        <Tooltip
                          borderRadius={5}
                          label={
                            <Box fontSize="sm" p="5px">
                              <Text>Only people with access to this ad account can receive rule results.</Text>
                            </Box>
                          }
                          fontSize="md"
                        >
                          <InfoOutlineIcon pointerEvents="all" />
                        </Tooltip>
                      </Flex>
                      <Text>{adAccounts?.clientId?.name}</Text>
                    </Flex>
                  </Flex>
                </>

              )}

            </ModalBody>
            <ModalFooter css={{ boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)' }} p={'12px'}>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex gap={2} align="center">
                  {currentStep === 1 && (
                    <>
                      <Button variant="ghost" size={'sm'} onClick={() => {
                        onClose();
                        createRuleFormik.resetForm();
                        setSelectedAds({});
                        setCurrentStep(0);
                      }}>
                        Cancel
                      </Button>

                      <Button type="submit" size={'sm'} colorScheme="orange"
                        isDisabled={!createRuleFormik.dirty || !createRuleFormik.isValid || createRuleFormik.isSubmitting}>
                        Create
                      </Button>
                    </>
                  )}
                </Flex>

                <Flex gap={2}>
                  {currentStep > 0 && (
                    <Button size={'sm'} onClick={handleBack}>Back</Button>
                  )}

                  {currentStep === 0 && (
                    <Button size={'sm'} colorScheme="orange" onClick={handleNext}>Next</Button>
                  )}
                </Flex>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
  );
}

export default CreateRuleForm;

