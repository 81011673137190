import { useEffect, useState } from "react";
import SidebarWithHeader from 'components/SidebarWithHeader';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Flex, Text, Select, Icon, Box, Tooltip } from '@chakra-ui/react';
import { MdFolderOpen, MdWindow, MdOutlineFolderSpecial, MdRefresh, MdClose } from 'react-icons/md';
import CampaignsTable from 'components/campaignTables/CampaignsTable';
import AdSetsTable from "components/campaignTables/AdSetsTable";
import AdsTable from "components/campaignTables/AdsTable";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from 'react-router-dom';
import DateRangeSelector from "components/campaignTables/DateRangeSelector";
import { selectSelectedAdsetId, selectSelectedCampaignId, setSelectedAdsetId, setSelectedCampaignId, selectSelectedAdsId, setSelectedAdsId } from "store/campaigns/campaignsSlice";

function CampaignsForAdmin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const selectedAdsId = useSelector(selectSelectedAdsId);
    const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);

    const [searchParams] = useSearchParams();

    const adsAccountId = searchParams.get('adsAccountId');
    const campaignId = searchParams.get('campaignId');
    const adsetId = searchParams.get('adsetId');
    
    const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [datePreset, setDatePreset] = useState('last_7_days');
    const [selectedTab, setSelectedTab] = useState(parseInt(searchParams.get('tab') || '0', 10)); 
    
    useEffect(() => {
        if (adsAccountId) {
            setSelectedAdsAccountId(adsAccountId);
        } else if (activeAccountsData?.data?.length > 0) {
            const defaultAccountId = activeAccountsData.data[0].adsAccountId;
            setSelectedAdsAccountId(defaultAccountId);
        }
    }, [activeAccountsData]);

    useEffect(() => {
        // Handle URL parameters for date and ads account ID on initial load
        const dateParam = searchParams.get('date');
        if (dateParam) {
            try {
                const decodedDateParam = decodeURIComponent(dateParam);
                const [dateRange, preset] = decodedDateParam.split(',');
                const [urlStartDate, urlEndDate] = dateRange.split('_');

                // Set the state based on the URL parameters
                setStartDate(urlStartDate ? moment(urlStartDate, "YYYY-MM-DD").toDate() : null);
                setEndDate(urlEndDate ? moment(urlEndDate, "YYYY-MM-DD").toDate() : null);
                setDatePreset(preset || '');
            } catch (error) {
                console.error("Error parsing date from URL:", error);
            }
        }

    }, []);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
            setSelectedAccountData(account);
        }
    }, [selectedAdsAccountId, activeAccountsData]);


    const handleDateRangeChange = (startDate, endDate, datePreset) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setDatePreset(datePreset);
    };

    // Update the URL when startDate, endDate, or datePreset change
    useEffect(() => {
        const params = new URLSearchParams();
        
        // Always include the selected adsAccountId first
        if (selectedAdsAccountId) {
            params.set('adsAccountId', selectedAdsAccountId);
        }

        // Setting the date parameter in the format: 2024-10-26_2024-11-25%2Clast_30d
        const formattedDate = startDate && endDate 
            ? `${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")},${datePreset}`
            : datePreset;

        if (formattedDate) {
            params.set('date', encodeURIComponent(formattedDate));
        }

        // Set the 'tab' parameter based on selectedTab
        params.set('tab', selectedTab);

        if(selectedCampaignId){
            params.set('campaignIds', selectedCampaignId);
        }

        if(selectedAdsetId){
            params.set('adsetIds', selectedAdsetId);
        }
        if(selectedAdsId){
            params.set('adsIds', selectedAdsId);
        }
        // Update the URL with the new parameters
        navigate({ search: params.toString() }, { replace: true });
    }, [startDate, endDate, datePreset, selectedTab, selectedAdsAccountId]);

    return (
        <SidebarWithHeader>
            <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                <Flex gap={'10px'} align={'center'}>
                    <Text fontWeight={'bold'}>Ad accounts</Text>
                    <Box maxWidth={'400px'}>
                        <Select
                            value={selectedAdsAccountId}
                            onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                            size={'sm'}
                            borderRadius={'md'}
                        >
                            {activeAccountsData?.data?.map(account => (
                                <option key={account._id} value={account.adsAccountId}>
                                    {`${account.adsAccountName} (${account.adsAccountId})`}
                                </option>
                            ))}
                        </Select>
                    </Box>
                </Flex>

                <Flex align={'center'}>
                    <Box>
                        <DateRangeSelector 
                            onDateRangeChange={handleDateRangeChange}
                            initialStartDate={startDate}
                            initialEndDate={endDate}
                            initailDatePerest={datePreset}
                        />
                    </Box>
                </Flex>
            </Flex>

            {/* Tabs always visible, and can switch between tabs */}
            <Tabs
                index={selectedTab}
                onChange={setSelectedTab}
                p={0}
                colorScheme='orange'
                variant='enclosed-colored'
            >
                 <TabList>
                    <Tab fontWeight="bold" flex={1}>
                        <Flex justify="space-between" align="center" width="100%">
                            <Flex align="center">
                                <Icon as={MdFolderOpen} boxSize={6} mr={2} />
                                Campaigns
                            </Flex>
                            {selectedCampaignId ? (
                                <Flex align="center" bg="orange.500" color="#fff" p={1} borderRadius="md">
                                    <Text fontSize="sm" fontWeight="normal" mr={2}>
                                        1 selected
                                    </Text>
                                    <Icon
                                        as={MdClose}
                                        boxSize={4}
                                        cursor="pointer"
                                        onClick={() => {
                                            dispatch(setSelectedCampaignId(null));

                                            const params = new URLSearchParams(window.location.search);
                                            params.delete('campaignIds');  
                                            navigate({ search: params.toString() }, { replace: true });
                                        }}
                                        _hover={{ bg: 'orange.600' }}
                                    />
                                </Flex>
                            ) : null}
                        </Flex>
                    </Tab>
                    <Tab fontWeight="bold" flex={1}>
                        <Flex justify="space-between" align="center" width="100%">
                            <Flex align="center">
                                <Icon as={MdWindow} boxSize={6} mr={2} />
                                <Text> 
                                    {(selectedCampaignId && !selectedAdsetId) ? "Ad sets for 1 Campaign " : "Ad sets"}
                                </Text>
                            </Flex>
                            {selectedAdsetId ? (
                                <Flex align="center" bg="orange.500" color="#fff" p={1} borderRadius="md">
                                    <Text fontSize="sm" fontWeight="normal" mr={2}>
                                        1 selected
                                    </Text>
                                    <Icon
                                        as={MdClose}
                                        boxSize={4}
                                        cursor="pointer"
                                        onClick={() => { 
                                            dispatch(setSelectedAdsetId(null));

                                            const params = new URLSearchParams(window.location.search);
                                            params.delete('adsetIds');  
                                            navigate({ search: params.toString() }, { replace: true });
                                        }}
                                        _hover={{ bg: 'orange.600' }}
                                    />
                                </Flex>
                            ) : null}
                        </Flex>
                    </Tab>
                    <Tab fontWeight="bold" flex={1}>
                        <Flex justify="space-between" align="center" width="100%">
                            <Flex align="center">
                                <Icon as={MdOutlineFolderSpecial} boxSize={6}  mr={2} />
                                <Text>
                                    {selectedAdsetId ? "Ads for 1 Ad set" : selectedCampaignId ? "Ads for 1 Campaign" : "Ads"}
                                </Text> 
                            </Flex>
                            {selectedAdsId ? (
                                <Flex align="center" bg="orange.500" color="#fff" p={1} borderRadius="md">
                                    <Text fontSize="sm" fontWeight="normal" mr={2}>
                                        1 selected
                                    </Text>
                                    <Icon
                                        as={MdClose}
                                        boxSize={4}
                                        cursor="pointer"
                                        onClick={() => { 
                                            dispatch(setSelectedAdsId(null));

                                            const params = new URLSearchParams(window.location.search);
                                            params.delete('adsIds');  
                                            navigate({ search: params.toString() }, { replace: true });
                                        }}
                                        _hover={{ bg: 'orange.600' }}
                                    />
                                </Flex>
                            ) : null}
                        </Flex>
                    </Tab>
                </TabList>
                
                <TabPanels>
                    <TabPanel px={0}>
                        {selectedTab === 0 && (
                            <CampaignsTable
                                adsAccountId={selectedAdsAccountId}
                                startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                datePreset={datePreset}
                                setSelectedTab={setSelectedTab} 
                            />
                        )}
                    </TabPanel>
                    <TabPanel px={0}>
                        {selectedTab === 1 && (
                            <AdSetsTable 
                                adsAccountId={selectedAdsAccountId} 
                                startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                datePreset={datePreset}
                                setSelectedTab={setSelectedTab} 
                            />
                        )}
                    </TabPanel>
                    <TabPanel px={0}>
                        {selectedTab === 2 && (
                            <AdsTable
                                adsAccountData={selectedAccountData}
                                startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                                endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                                datePreset={datePreset}
                            />
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </SidebarWithHeader>
    );
}


export default CampaignsForAdmin;
