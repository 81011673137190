import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightElement, List, ListItem, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useGetDetailedTargetingQuery } from 'store/facebookServices/facebookServicesApi';


const shouldRemoveInterest = (newInterest, existingInterest) => {

    return (
        newInterest.name === existingInterest.name
    );
};

const filterSelectedInterests = (newInterest, selectedInterests) => {
    //console.log({ newInterest, selectedInterests });
    return selectedInterests.filter((interest) => !shouldRemoveInterest(newInterest, interest));
};

const targetFields = [
    'interests',
    'education_schools',
    'education_majors',
    'work_positions',
    'work_employers',
    'relationship_statuses',
    'interested_in',
    'user_adclusters',
    'college_years',
    'education_statuses',
    'family_statuses',
    'industries',
    'life_events',
    'politics',
    'behaviors',
    'income',
    'net_worth',
    'home_type',
    'home_ownership',
    'home_value',
    'ethnic_affinity',
    'generation',
    'household_composition',
    'moms',
    'office_type',
    'location_categories'
];

const relationshipStatusesMap = [
    { id: '6', name: 'Unspecified' },
    { id: '1', name: 'Single' },
    { id: '3', name: 'Married' },
    { id: '2', name: 'In a relationship' },
    { id: '4', name: 'Engaged' }
];

const DetailedTargetingUpdateSelector = ({ adsAccountId, onInterestsChange, preFilledTargets }) => {

    const inputRef = useRef(null);
    const popupRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [interestOptions, setInterestOptions] = useState([]);
    const [selectedInterests, setselectedInterests] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const { data: interests, isLoading, isFetching, refetch } = useGetDetailedTargetingQuery({ searchTerm: debouncedSearchTerm, adsAccountId: adsAccountId}, { skip: !debouncedSearchTerm?.length });

    useEffect(() => {
        if (preFilledTargets && Object.keys(preFilledTargets).length > 0) {
    
            // If flexible_spec exists and is an array, extract the first item
            const targetingData = Array.isArray(preFilledTargets?.flexible_spec)
                ? preFilledTargets?.flexible_spec[0]
                : preFilledTargets; 

            if (targetingData) {
 
                const mappedTargeting = targetFields
                    .map((targetField) => {
                        if (targetingData.hasOwnProperty(targetField)) {
                            const value = targetingData[targetField];

                            if (targetField === 'relationship_statuses') {

                                return value.map((statusId) => {
                                    const status = relationshipStatusesMap.find(
                                        (item) => item?.id === String(statusId)
                                    );
                                    return status
                                        ? { id: status.id, name: status.name, type: targetField }
                                        : null;
                                }).filter(item => item); 
                            }
                            
                            // Check if the value is an array
                            if (Array.isArray(value)) {
                                return value.map(item => ({
                                    id: item.id || item,  
                                    name: item.name || item,
                                    type: targetField
                                }));
                            } else {
                                return [{ id: value, name: value }];
                            }
                        }
                        return null;
                    })
                           
                    .filter((item) => item)  
                    .flat();  
                
                setselectedInterests(mappedTargeting);
                onInterestsChange(mappedTargeting);
            }
        }
    }, [preFilledTargets]);

    useEffect(() => {
        if (searchTerm?.length == 0) {
            setDebouncedSearchTerm('');
            setShowPopup(false);
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length > 0 && (searchTerm == debouncedSearchTerm)) {
            refetch()
        }
    }, [searchTerm, debouncedSearchTerm])


    useEffect(() => {
        if (interests?.data?.length > 0) {
            setShowPopup(true);
            setInterestOptions(interests?.data);
        } else {
            setShowPopup(false);
        }
    }, [interests]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                popupRef.current &&
                !popupRef.current.contains(event.target)
            ) {
                setShowPopup(false);
                setInterestOptions([]);
            }

        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInterestSelect = (interest) => {

        setselectedInterests(prevInterests => {
            const filteredInterests = filterSelectedInterests(interest, prevInterests);
            const updatedInterests = [...filteredInterests, interest]
            setTimeout(() => onInterestsChange(updatedInterests), 0);
            return updatedInterests;
        });

        setSearchTerm('');
        setDebouncedSearchTerm('');
        setShowPopup(false);
        setInterestOptions([]);
    };

    const removeInterestEntry = (id) => {
        setselectedInterests(prevInterests => {
            const updatedInterests = prevInterests.filter(interest => interest.id !== id);
            setTimeout(() => onInterestsChange(updatedInterests), 0);
            return updatedInterests;
        });
    };


    return (
        <Box>
            <Box my={'10px'}>
                {selectedInterests?.map((interest) => (
                    <Flex key={'selected' + interest?.id + interest?.audience_size_lower_bound * Math.random()} bg={'white'} align={'center'} justify={'space-between'} width={'full'} py={'5px'} px={'10px'} borderRadius={'md'} mb={'5px'}>

                        <Flex flex={1} pr={'10px'} align={'center'}>
                            <Flex justify={'space-between'} flex={1} align={'center'}>
                                <Text ml={'5px'} fontSize={'14px'} fontWeight={'bold'}>{interest?.name} </Text>
                                <Text ml={'5px'} fontSize={'12px'}>{interest?.type}</Text>
                            </Flex>
                        </Flex>
                        <CloseIcon _hover={{ cursor: 'pointer', color: 'red.400' }} boxSize={3} color='gray.500' onClick={() => removeInterestEntry(interest?.id)} />
                    </Flex>
                ))}
            </Box>
            <Box position="relative">
                <Flex>
                    <InputGroup >
                        <InputLeftElement height={'32px'}>
                            <SearchIcon boxSize={4} color='gray.500' />
                        </InputLeftElement>
                        <Input
                            ref={inputRef}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Add demographics, interests or behaviors"
                            autoComplete="off"
                            borderRadius={'md'}
                            size={'sm'}
                            onFocus={() => {
                                setInterestOptions([]);
                                if (interestOptions?.length > 0) {
                                    setShowPopup(true)
                                }
                            }}
                        />
                        <InputRightElement height={'32px'}>
                            {isFetching ? (
                                <Spinner boxSize={3} />
                            ) : (<>
                                {searchTerm?.length > 0 ? (
                                    <CloseIcon boxSize={3} color='gray.500' onClick={() => { setSearchTerm(''); setDebouncedSearchTerm(''); }} />
                                ) : null}
                            </>)}
                        </InputRightElement>
                    </InputGroup>
                </Flex>

                <>
                    {showPopup && interestOptions.length > 0 && (
                        <List
                            ref={popupRef}
                            position="absolute"
                            top="100%"
                            width="100%"
                            border="1px solid #ccc"
                            backgroundColor="white"
                            zIndex="1000"
                            maxHeight="200px"
                            overflowY="auto"
                        >
                            {interestOptions.map((interest) => (
                                <ListItem
                                    key={interest.id * Math.random() + interest.name}
                                    p={'5px'}
                                    px={'10px'}
                                    cursor="pointer"
                                    _hover={{ backgroundColor: '#f0f0f0' }}
                                    onClick={() => handleInterestSelect(interest)}
                                >
                                    <Flex justify={'space-between'} >
                                        <Text fontSize={'14px'}>
                                            {interest.name}
                                        </Text>
                                        <Text fontSize={'12px'}>
                                            {interest.type}
                                        </Text>
                                    </Flex>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            </Box>

        </Box>
    );
};

export default DetailedTargetingUpdateSelector;
