import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isWidgetOpen: false,
    isDriveWidgetOpen : false,
    files: [],
    selectedFiles: [], 
    choosenFiles: [], 
    uploadStatus: {},
    uploadAbortControllers: {},
};

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setIsWidgetOpen(state, action) {
            state.isWidgetOpen = action.payload;
        },
        setDriveWidgetOpen(state, action) {
            state.isDriveWidgetOpen = action.payload;
        },
        setFiles(state, action) {
            state.files = action.payload;
        },
        setChoosenFiles(state, action) {
            const newFiles = action.payload;
            
            if (newFiles.length === 0) {
                state.choosenFiles = [];
            } else {
                state.choosenFiles = [
                    ...state.choosenFiles.filter(file => !newFiles.some(newFile => newFile?.name === file?.name)),
                    ...newFiles,
                ];
            }
        },
        setSelectedLocalFiles(state, action) { 
            state.selectedFiles = action.payload;
        },         
        updateUploadStatus: (state, action) => {
            const { fileName, accountId, status, progress,reason } = action.payload;
            if (!state.uploadStatus[fileName]) {
                state.uploadStatus[fileName] = {};
            }
            state.uploadStatus[fileName][accountId] = { status, progress,reason };
        },
        resetUpload(state) {
            state.files = [];
            state.selectedFiles = []; 
            state.uploadStatus = {};
            state.currentAdAccount = null;
        },
        setUploadAbortControllers(state, action) {
            state.uploadAbortControllers = {
                ...state.uploadAbortControllers,
                ...action.payload,
            };
        },
        cancelUpload: (state, action) => {
            const { fileName, accountId } = action.payload;
            const key = `${fileName}-${accountId}`;

            if (state.uploadStatus[fileName] && state.uploadStatus[fileName][accountId]) {
                // console.log(`Cancelling upload for ${fileName} (Account: ${accountId})`);
                state.uploadStatus[fileName][accountId].status = 'cancelled';
                state.uploadStatus[fileName][accountId].progress = 0;

                const abortController = state.uploadAbortControllers[key];
                
                if (abortController) {
                    abortController.abort(); // Abort the ongoing request
                }

                delete state.uploadAbortControllers[key];   
            }
        },
    },
});

export const {
    setIsWidgetOpen,
    setDriveWidgetOpen,
    setFiles,
    setChoosenFiles,
    setSelectedLocalFiles,
    updateUploadStatus,
    resetUpload,
    setUploadAbortControllers,
    cancelUpload
} = uploadSlice.actions;

export default uploadSlice.reducer;
