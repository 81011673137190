import { api } from "../api";

export const adsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdsList: builder.query({
            query: ({ adsAccountId, currentPage, searchValue,adset_id }) => ({
                url: `/ads/${adsAccountId}?adset_id=${adset_id}&page=${currentPage}&search=${searchValue}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads']
        }),
        getAdCreative: builder.query({
            query: ({ creativeId }) => ({
                url: `/ads/adcreatives/${creativeId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads']
        }),
        getAdComparison: builder.query({
            query: ({ hashes }) => ({
                url: `/ads/comparison/?hashes=${encodeURIComponent(JSON.stringify(hashes))}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),
        updateAd: builder.mutation({
            query: ({ creativeId,payload }) => ({
                url: `/ads/${creativeId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['ads'],
        }),
        updateAdStatus: builder.mutation({
            query: ({ adId, payload }) => ({
                url: `/ads/update/status/${adId}`,
                method: 'PUT',
                body: payload
            }),
        }),
    }),

});

export const { useGetAdsListQuery, useGetAdCreativeQuery, useGetAdComparisonQuery, useUpdateAdMutation, useUpdateAdStatusMutation } = adsApi;

