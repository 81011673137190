import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, CheckboxGroup, Collapse, Divider, Flex, Icon, Input, Select, Text, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiChevronUp } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { useCreateAdspressoCreativeMutation, useGetAdspressoCreativeByCampaignIdQuery, useRemoveCreativeMutation, useUpdateAdspressoAdCreativeByIdMutation, useRemoveAdMutation } from 'store/adspresso/adspressoAdApi';
import { object, string } from "yup";
import CarouselMediaUpdateForm from './creativeUpdateForms/CarouselMediaUpdateForm';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';

function CarouselMediaCreative({ adsAccounts, adFormik, adsetsListData, campaignId, call_to_action_options, adData }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [uploadPurpose, setuploadPurpose] = useState(null);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedAdSets, setSelectedAdSets] = useState({});
  const [mediaFiles, setMediaFiles] = useState([]);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [fileBeingEdited, setFileBeingEdited] = useState(null);
  const [mediaFilesState, setMediaFilesState] = useState({});
  const [maxCards] = useState(10);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const [createAdspressoAds, { isLoading: isAdsCreating, error: createError }] = useCreateAdspressoCreativeMutation();
  const { data: AdspressoCreatives } = useGetAdspressoCreativeByCampaignIdQuery(campaignId);
  const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
  const [removeCreative, removeCreativeResponse] = useRemoveCreativeMutation();
  const [removeAd, removeAdResponse] = useRemoveAdMutation();

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };


  const handleInputChange = (fileId, field, value) => {

    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        [field]: value,
      },
    }));
  };

  const handleMediaTypeChange = (adAccounts, type) => {
    setSelectedAdAccounts(adAccounts);
    setMediaType(type);
    setIsDriveOpen(true);
  }

  const handleAdAccountChange = (selectedValues) => {
    const selectedAccounts = adsAccounts.filter(account => selectedValues.includes(account.adsAccountId));
    setSelectedAdAccounts(selectedAccounts);
  };

  const handleAdSetChange = (selectedValues) => {
    setSelectedAdSets(prev => {
      const updated = {};
      selectedAdAccounts.forEach(account => {
        updated[account.adsAccountId] = selectedValues;
      });
      return updated;
    });
  };

  useEffect(() => {
    if (mediaFiles.length > 0) {
      setOpenAccordionId(mediaFiles[0]._id);
    }
  }, [mediaFiles]);

  const handleFileSelect = (files) => {

    if (files.length > 0) {
      if (fileBeingEdited) {
        // Handle update scenario
        setMediaFiles(files);
        const selectedFile = files[0];
      
        const payload = {
          [selectedFile?.creativeType === 'video' ? 'video_id' : 'image_hash']: selectedFile?.creativeId,
          ...(selectedFile?.creativeType === 'image' ? { url: selectedFile?.url } : { thumbnail: selectedFile?.picture }),
          hash:selectedFile?.hash,
        };

        updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
          .then(response => {
            console.log('Ad creative updated successfully:', response);
          })
          .catch(error => {
            toast({
              position: "top-right",
              title: "Error updating image",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      } else {
        // Handle creation scenario
        setMediaFiles(prevFiles => {
          // Add new files only if the total number of files is less than maxCards
          const newFiles = [...prevFiles, ...files].slice(0, maxCards);
          return newFiles;
        });
      }
      setFileBeingEdited(null);
    }
  };

  const handleChangeMedia = (file, adAccount) => {
    const mediaType = file?.image_hash ? 'image' : 'video';
    setuploadPurpose('update')
    handleMediaTypeChange([adAccount], mediaType);
    setSelectedAdAccounts([adAccount]);
    setFileBeingEdited(file);
    setSelectedCreative(file)
  };


  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };

  let schema = object().shape({

    type: string()
      .max(300, 'select atleast one type')
      .required('Type is required'),
    // message: string().when('applyForAll', {
    //   is: true,
    //   then: () => string().required('Required'),
    //   otherwise: () => string().notRequired(),
    // }),
    message: string().required('Text required'),
    link: string()
      .url('Please add a valid URL') // Validates that the input is a valid URL
      .required('Website URL is required'),
    // mediaFiles: array()
    //   .min(1, 'At least one media is required'),
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: '',
      name: '',
      description: '',
      link: '',
      type: 'SHOP_NOW',
      mediaFiles: []
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      const { name, message, description, type, link } = values;

      const adcreatives = mediaFiles?.map(file => {

        let videoId = file?.creativeType == 'video' ? { video_id: file?.creativeId } : null;
        let videoURL = file?.creativeType == 'video' ? { url: file?.source } : null;
        let imageHash = file?.creativeType == 'image' ? { image_hash: file?.creativeId } : null;
        let imageURL = file?.creativeType == 'image' ? { url: file?.url } : null;
        let videoThumbnail = file?.creativeType == 'video' ? { thumbnail: file?.picture } : null;

        return (
          {
            adsAccountId: file?.adsAccount?.adsAccountId,
            message: mediaFilesState[file._id]?.message || '',
            name: mediaFilesState[file._id]?.name || '',
            description: mediaFilesState[file._id]?.description || '',
            link: mediaFilesState[file._id]?.link || '',
            type: "",
            hash:file?.hash,
            ...videoId,
            ...videoURL,
            ...imageHash,
            ...imageURL,
            ...videoThumbnail,
          }
        )
      });


      const adsAccounts = selectedAdAccounts.map(account => ({
        id: account.id,
        adsAccountId: account.adsAccountId,
        adsAccountName: account.adsAccountName,
        businessName:account?.businessName,
        businessId:account?.businessId,
      }));

      const adsets = adsAccounts.flatMap(account => selectedAdSets[account.adsAccountId] || []);
      const uniqueAdsets = adsets.filter((item, index) => adsets.indexOf(item) === index);

      const payload = {
        adType: adFormik.values.format,
        campaign: campaignId,
        adsets: uniqueAdsets,
        message: message,
        name: name,
        description: description,
        type: type,
        link: link,
        adcreatives: adcreatives,
        adsAccounts: adsAccounts,
      };

      //  console.log({ payload });

      try {
        if (uploadPurpose === 'create') {
          createAdspressoAds(payload).unwrap()
            .then(response => {
              toast({
                position: "top-right",
                title: "Ad creative added",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              //console.log('Ads creative created successfully:', response);
            })
            .catch(error => {
              const errorMessage = error?.message || "An unexpected error occurred";

              toast({
                position: "top-right",
                title: "Error creating Ad creative",
                description: errorMessage,
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            });
        }
      } catch {
        // handle exception if necessary
      } finally {
        setSelectedCreative(null);
        setuploadPurpose(null);
        setMediaType(null);
        setMediaFiles([]); // Clear the media files
        setSelectedAdAccounts([]); // Reset selected ad accounts
        setSelectedAdSets({}); // Clear selected ad sets
        resetForm();
      }

    },
  });

  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const handleDeleteCreative = (creativeId) => {

    removeCreative(creativeId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad creative deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad creative",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
        //console.error('Failed to delete Ad creative:', error);
      });
  };

  const handleDeleteAd = (adId) => {
    removeAd(adId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const confirmDelete = () => {
    if (selectedAd) {
        handleDeleteAd(selectedAd);
        setModalOpen(false);
        setSelectedAd(null);
    }
  };  

  // const uploadedCreativesList = AdspressoCreatives?.data
  //   ?.filter(creative => creative.adType === 'CAROUSEL' && creative?.adcreatives?.length > 0) 
  //   ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
  //   ?.map((creative, index) => {

  //   return (
  //     <Box key={creative?._id + index} p={'10px'} borderWidth={1} borderRadius="md" borderColor="gray.200">
  //       <Flex justifyContent={'space-between'}>
  //         <Text fontSize={'14px'} fontWeight={'bold'}>Ad Account: {creative?.adsAccount?.adsAccountName}</Text>
  //         <Tooltip label="Delete" fontSize="xs">
  //           <Box>
  //             <Icon
  //               color={'red.500'}
  //               _hover={{ color: 'gray.400' }}
  //               as={MdDelete}
  //               cursor="pointer"
  //               boxSize={5}
  //               onClick={() => {
  //                 setSelectedAd(creative?._id);
  //                 setModalOpen(true);
  //               }}
  //             />
  //           </Box>
  //         </Tooltip>
  //       </Flex>
  //       <Flex align={'flex-start'} direction={'column'}>
  //         <Text fontSize={'14px'}>Adsets:</Text>
  //         <Flex direction={'column'} ml={'5px'}>
  //           {creative?.adsets?.map((adset, adsetIndex) => (
  //             <Text key={adsetIndex + adset._id} fontSize={'12px'}>{adset.name}</Text>
  //           ))}
  //         </Flex>
  //       </Flex>

  //       {/* Small Image Preview */}
  //       <Flex wrap="wrap" gap={2} direction="column" mt={'10px'}>
  //         {creative?.adcreatives?.map((file, fileIndex) => (
  //           <Box key={file?.url + fileIndex}>
  //             <CarouselMediaUpdateForm
  //               adData={adData}
  //               adAccount={creative?.adsAccount}
  //               creative={file}
  //               onDelete={handleDeleteCreative}
  //               onChangeImage={handleChangeMedia}
  //               // onRemoveImage={handleRemoveMedia}
  //             />
  //           </Box>
  //         ))}
  //       </Flex>
  //     </Box>
  //   );
  // });



  const isAnyUrlMissing = mediaFiles.some(file => {
    const fileId = file?._id;
    const fileState = mediaFilesState[fileId] || {};
    return !fileState.link || fileState.link.trim() === '';
  });

  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
          setFileBeingEdited(null);
        }}
        onSubmit={handleFileSelect}
        mediaFormat={adFormik?.values?.format}
        selectedAdAccounts={selectedAdAccounts}
        uploadPurpose={uploadPurpose}
        adFormik={adFormik}
      />

      <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={confirmDelete}
          message="Do you wish to proceed with deleting this Ad?"
      />

      <Flex flex="3" direction="column" gap={2}>
        <Flex direction="column" mb={4}>
          {/* Ad Account Select */}
          <Flex direction={'column'} gap={'10px'} mb={'10px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad accounts</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={selectedAdAccounts.map(account => account.adsAccountId)}
              onChange={handleAdAccountChange}
            >
              <Flex
                flexWrap={'wrap'}
                gap="10px"
              >
                {adsAccounts?.map(account => (
                  <Box key={account.adsAccountId} px={'5px'} py={'2px'} width={'calc(50% - 5px)'}>
                    <Checkbox value={account.adsAccountId}>
                      <Text maxWidth="150px" fontSize={'14px'}>
                        {account.adsAccountName.length > 20
                          ? `${account.adsAccountName.substring(0, 20)}...`
                          : account.adsAccountName}
                      </Text>
                    </Checkbox>
                  </Box>
                ))}
              </Flex>

            </CheckboxGroup>
          </Flex>

          {/* Ad Set Select */}
          <Flex direction="column" gap={'5px'} mb={'20px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad sets</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={Array.from(new Set(Object.values(selectedAdSets).flat()))}
              onChange={handleAdSetChange}
              isDisabled={!selectedAdAccounts.length > 0}
            >
              <Flex
                flexWrap={'wrap'}
                gap={'10px'}
              >
                {adsetsListData?.data?.map((adSet, index) => (
                  <Box key={`${adSet._id}-${index}`} px={'5px'} py={'2px'} width={'calc(50% - 5px)'}>
                    <Checkbox value={adSet._id} display={'flex'} flex={1}>
                      <Text fontSize={'14px'}>{adSet?.name}</Text>
                    </Checkbox>
                  </Box>
                ))}
              </Flex>
            </CheckboxGroup>
          </Flex>

          <Flex direction="column" mb="10px">
            <Flex alignItems={'center'} gap={2}>
              <Text fontSize="15px" fontWeight={'bold'}>Carousel cards</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="14px" p="5px">
                    <Text>
                      Choose media and enter text for up to ten carousel cards.
                    </Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon boxSize={3} pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Text fontSize="13px" flex={1}>
              {mediaFiles.length} of {maxCards} cards added
            </Text>
            {mediaFiles?.length < 2 ? (
              <Flex align={'center'} gap={'5px'} px={'10px'} py={'5px'} my={'5px'} borderRadius={'sm'} bg={'gray.100'}>
                <InfoIcon boxSize={3} color={'gray.800'} />
                <Text fontSize="13px">Add at least 2 cards.</Text>
              </Flex>
            ) : null}
          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0}>
            <Select
              placeholder={"Add cards"}
              width={'150px'}

              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                setuploadPurpose('create');
                handleMediaTypeChange(selectedAdAccounts, e.target.value);
              }}
              size="sm"
              mb={2}
              isDisabled={Object.keys(selectedAdSets)?.length === 0 || !selectedAdAccounts?.length > 0}
            >
              <option value="image">{"Add image cards"}</option>
              <option value="video">{"Add video cards"}</option>
            </Select>
          </Flex>


          {/* Media Previews */}
          {uploadPurpose === 'create' && mediaFiles?.length > 0 && (
            <Box mt={4}>
              <Flex wrap="wrap" gap={2} direction="column">
                {mediaFiles?.map((file, index) => {
                  const fileId = file?._id;
                  const fileState = mediaFilesState[fileId] || {};

                  // Checking if URL is empty
                  const isUrlRequired = !fileState.link || fileState.link.trim() === '';

                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200" position={'relative'}>
                      <Flex alignItems="center" gap={2} justify="space-between">
                        <Flex alignItems="center" gap={2}>
                          {file?.creativeType === 'image' ? (
                            <img
                              src={file?.url}
                              alt={file?.name}
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          ) : (
                            <video
                              src={file?.source}
                              // controls
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          )}
                          <Flex direction="column">
                            <Text fontSize="10px" noOfLines={1}>
                              {fileState.name || file?.name || 'No name available'}
                            </Text>
                            <Flex gap={1}>
                              {fileState.description ? (
                                <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.description)}</Text>
                              ) : (
                                <Text fontSize="10px">Enter a description</Text>
                              )}
                              {!fileState.link ? (
                                <>
                                  <Text fontSize="10px">and</Text>
                                  <Text fontSize="10px">URL&nbsp;
                                    <Tooltip

                                      borderRadius={5}
                                      label={
                                        <Box p="5px" >
                                          <Text fontSize="12px">
                                            Your carousel card {index + 1} doesn't have a destination. Please enter a website URL for each card in your carousel.
                                          </Text>
                                        </Box>
                                      }
                                      fontSize="md"
                                    >
                                      <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                    </Tooltip>
                                  </Text>
                                </>
                              ) : null}
                            </Flex>
                            <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" gap={'5px'}>
                          <Tooltip label="Edit copy" fontSize="xs">
                            <Box>
                              <Icon
                                _hover={{ color: 'gray.500' }}
                                as={openAccordionId === file?._id ? FiChevronUp : MdEdit}
                                cursor="pointer"
                                fontSize="14px"
                                onClick={() => toggleAccordion(file?._id)}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip label="Delete" fontSize="xs">
                            <Box>
                              <Icon
                                color="red.500"
                                _hover={{ color: 'gray.400' }}
                                as={MdDelete}
                                cursor="pointer"
                                boxSize={4}
                                onClick={() => handleDeleteFile(index)}
                              />
                            </Box>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Collapse in={openAccordionId === file?._id}>
                        <Box p={4}>
                          {/* Headline Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Headline</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five brief headlines to let people know what your ad is about. Each
                                      headline can have a maximum of 255 characters. Headlines won't appear in all
                                      placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Input
                              placeholder="Tell people what your ad is about..."

                              size="sm"
                              borderRadius="md"
                              value={fileState.name || ''}
                              onChange={(e) => handleInputChange(fileId, 'name', e.target.value)}
                            />
                          </Box>

                          {/* Description Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Description</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five link descriptions to emphasize why people should visit your
                                      website. This won't appear in all placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Textarea
                              placeholder="Include additional details"

                              size="sm"
                              borderRadius="md"
                              value={fileState.description || ''}
                              onChange={(e) => handleInputChange(fileId, 'description', e.target.value)}
                              rows={2}
                            />
                          </Box>

                          {/* URL link Section */}
                          <Box direction="column">
                            <Flex alignItems="center" mb={'5px'}>
                              <Text fontSize="14px" fontWeight="bold">Website URL</Text>
                              <Text display="inline-flex" fontSize="18px" color="red.400" ml={2} mr={2}>*</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Enter the URL for the web page that you want people to visit.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Input
                              placeholder="http://www.example.com/page"

                              size="sm"
                              borderRadius="md"
                              value={fileState.link || ''}
                              onChange={(e) => handleInputChange(fileId, 'link', e.target.value)}
                              isInvalid={isUrlRequired}
                            />
                            {isUrlRequired && (
                              <Text fontSize="12px" color={"red.500"} mt={2}>Your carousel card {index + 1} doesn't have a destination. Please enter a website URL for each card in your carousel.</Text>
                            )}
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          )}
        </Flex>

        {/* Primary Text Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize="14px">Primary text</Text>
            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            size="sm"
            placeholder="Tell people what your ad is about..."

            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius="md"
          />
          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        {/* Call to action Section */}
        <Box direction="column">
          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize="14px">Call to action</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Select
            name="type"

            borderRadius="md"
            value={singleMediaFormik.values.type}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            size="sm"
          >
            {call_to_action_options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
            <Text color="red.500">{singleMediaFormik.errors.type}</Text>
          ) : null}
        </Box>

        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        <Box direction="column">
          <Flex alignItems="center" mt={'10px'} mb={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>Enter the URL for the webpage you want people to visit</Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            type="url"
            name="link"

            borderRadius={'md'}
            placeholder="Enter URL"
            value={singleMediaFormik.values.link}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            size={'sm'}
          />
          {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
          ) : null}

        </Box>


        {/* Save Button */}
        <Flex justifyContent="flex-start" mt="10px">
          <Button
            colorScheme="orange"
            onClick={() => {
              singleMediaFormik.handleSubmit();
            }}
            isLoading={isAdsCreating || isAdspressoAdsetUpdating}
            size="sm"
            isDisabled={mediaFiles?.length < 2 || isAnyUrlMissing || !adData?.name}
          >
            Save
          </Button>
        </Flex>

        {/* {uploadedCreativesList?.length > 0 && (
          <>
            <Divider my="10px" />
            <Flex direction="column" gap={2}>
              {uploadedCreativesList}
            </Flex>
          </>
        )} */}
      </Flex>
    </>
  );
};

export default CarouselMediaCreative;

