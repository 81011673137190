import { api } from "../api";

export const adsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdsList: builder.query({
            query: ({ adsAccountId, currentPage, searchValue, adset_ids, campaign_ids, ad_id, start_date, date_preset, end_date }) => {

              let url = `/ads/${adsAccountId}?`;
              
              if (campaign_ids && campaign_ids.length > 0) {
                url += `campaign_ids=${JSON.stringify(campaign_ids)}&`;
              }
              if (adset_ids && adset_ids.length > 0) {
                url += `adset_ids=${JSON.stringify(adset_ids)}&`;
              }
              if (ad_id) {
                url += `ad_id=${ad_id}&`;
              }
              if (currentPage) {
                url += `page=${currentPage}&`;
              }
              if (searchValue) {
                url += `search=${searchValue}&`;
              }
              if (start_date) {
                url += `start_date=${start_date}&`;
              }
              if (end_date) {
                url += `end_date=${end_date}&`;
              }
              if (date_preset) {
                url += `date_preset=${date_preset}&`;
              }
          
              // Remove the trailing "&" if it exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;
          
              return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),     
        getAds: builder.query({
          query: ({ businessId, adsIds,}) => ({
              url: `/ads/get/?businessId=${businessId}&adsIds=${encodeURIComponent(JSON.stringify(adsIds))}`, 
          }),
          transformResponse: (response, meta, arg) => response.data,
          providesTags: ['ads'],
        }),                  
        getAdCreative: builder.query({
            query: ({ creativeId }) => ({
                url: `/ads/adcreatives/${creativeId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads']
        }),
        getAdComparison: builder.query({
            query: ({ hashes }) => ({
                url: `/ads/comparison/?hashes=${encodeURIComponent(JSON.stringify(hashes))}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),
        updateAd: builder.mutation({
            query: ({ creativeId,payload }) => ({
                url: `/ads/${creativeId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['ads'],
        }),
        updateAdStatus: builder.mutation({
            query: ({ adId, payload }) => ({
                url: `/ads/update/status/${adId}`,
                method: 'PUT',
                body: payload
            }),
        }),
    }),
});

export const { useGetAdsListQuery, useGetAdsQuery, useGetAdCreativeQuery, useGetAdComparisonQuery, useUpdateAdMutation, useUpdateAdStatusMutation } = adsApi;

