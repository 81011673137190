import React from 'react';
import { Button, Flex, Box, Icon, Text } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronsLeft, FiChevronRight, FiChevronsRight } from 'react-icons/fi';

const Pagination = ({
  currentPage,  
  totalPages,   
  hasNextPage,   
  hasPrevPage,   
  setCurrentPage 
}) => {

  return (
    totalPages > 1 && (  
      <Flex align={'center'} justify={['start', 'end']} mt={'40px'} gap={'5px'}>
        {/* Page Number Display */}
        <Box mr={2} fontSize="sm">
          Page{' '}
          <Text fontWeight={'bold'} display={'inline-flex'}>
            {currentPage} of {totalPages} 
          </Text>
        </Box>

        {/* First Page Button */}
        <Button
          size={'xs'}
          onClick={() => setCurrentPage(1)} 
          isDisabled={!hasPrevPage}
        >
          <Icon boxSize={4} as={FiChevronsLeft} />
        </Button>

        {/* Previous Page Button */}
        <Button
          size={'xs'}
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} // Decrement from 1, not 0
          isDisabled={!hasPrevPage}
        >
          <Icon boxSize={4} as={FiChevronLeft} />
        </Button>

        {/* Next Page Button */}
        <Button
          size={'xs'}
          onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)} // Increment from 1
          isDisabled={!hasNextPage}
        >
          <Icon boxSize={4} as={FiChevronRight} />
        </Button>

        {/* Last Page Button */}
        <Button
          size={'xs'}
          onClick={() => setCurrentPage(totalPages)} // Go to the last page
          isDisabled={!hasNextPage}
        >
          <Icon boxSize={4} as={FiChevronsRight} />
        </Button>
      </Flex>
    )
  );
};

export default Pagination;
