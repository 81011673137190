import React, { useEffect, useState } from 'react';
import {
    Box, Spinner, Text, useColorModeValue, useDisclosure, Grid, Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    Badge,
    Heading,
    Table,
    Tbody,
    TableContainer,
    Thead,
    Td,
    Tr,
    Icon,
    useToast
} from '@chakra-ui/react';
import { useGetAdspressoAdsetsByCampainIdQuery, useRemoveAdspressoAdsetMutation } from 'store/adspresso/adspressoAdsetapi';
import SalesForm from './SalesForm';
import LeadsForm from './LeadsForm';
import { AddIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import { useGetAdsetFormQuery } from 'store/campaigns/adsetFormApi';
import { MdDelete } from 'react-icons/md';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import adsetMapping from '../../../utils/adsetMapping.json'
import { useSelector } from 'react-redux';
import { useGetBmAdAccountsDetailsQuery } from 'store/BmAccounts/bmAccountApi';


function AdSetForm({ campaignData, onAdsetsData}) {

    const toast = useToast();

    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();
    const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedAdset, setSelectedAdset] = useState(null);
    const [isEditing, setisEditing] = useState(false);
    const userInfo = useSelector((state) => state.auth?.userInfo);


    const addBoxBorder = useColorModeValue("gray.400", "gray.200");
    const addBoxHoverBackground = useColorModeValue("gray.50", "gray.700");
    const editIconColor = useColorModeValue("gray.600", "gray.400");
    const editIconHoverColor = useColorModeValue("gray.500", "gray.300");


    const campaignType = campaignData?.objective;
    const id = campaignData?._id;
    const adsAccountIds = campaignData?.adsAccounts?.map(account => account?.adsAccountId) || [];

    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: !(isCreateOpen || isEditModalOpen) || campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: !(isCreateOpen || isEditModalOpen) || campaignType !== 'OUTCOME_LEADS' });

    const { data: adsetsListData, error: adsetsListError, isLoading: isLoadingadsetsList, refetch } = useGetAdspressoAdsetsByCampainIdQuery(id, {
        skip: !id,
    });

    const { data: bmAdAccountsDetails, isLoading: isBmAdAccountsLoading, refetch:bmAdAccountsDetailsRefetch } = useGetBmAdAccountsDetailsQuery(
        {
            adsAccounts: adsAccountIds,
        },
        { skip: !adsAccountIds.length }
    );

    const [removeAdspressoAdset, removeResponse] = useRemoveAdspressoAdsetMutation();

    useEffect(() => {
        if (adsetsListData) {
            onAdsetsData(adsetsListData); // Send adsets data back to the parent
        }
    }, [adsetsListData]);

    const handleViewAdset = (adset) => {
        setSelectedAdset(adset);
        onViewOpen();
    };

    const handleSaveSuccess = () => {
        refetch();
    };

    const renderForm = () => {
        if (campaignType === 'OUTCOME_SALES' && dataSales && bmAdAccountsDetails?.data) {
            return <SalesForm
                data={dataSales?.data}
                adsetsListData={adsetsListData}
                campaignData={campaignData}
                onClose={onCreateClose}
                isEditing={isEditing}
                selectedAdset={selectedAdset}
                selectedAdAccounts={bmAdAccountsDetails?.data}
                onEditModalClose={() => { setSelectedAdset(null); onEditModalClose(); }}
                onSaveSuccess={handleSaveSuccess}
            />
        } else if (campaignType === 'OUTCOME_LEADS' && dataLeads && bmAdAccountsDetails?.data) {
            return <LeadsForm
                data={dataLeads?.data}
                adsetsListData={adsetsListData}
                campaignData={campaignData}
                onClose={onCreateClose}
                isEditing={isEditing}
                selectedAdset={selectedAdset}
                selectedAdAccounts={bmAdAccountsDetails?.data}
                onEditModalClose={() => { setSelectedAdset(null); onEditModalClose(); }}
                onSaveSuccess={handleSaveSuccess}
            />
        }
        return null;
    };

    const handleDeleteAdset = (id) => {

        removeAdspressoAdset(id)
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad set deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting Ad set",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
            });
    };

    const confirmDelete = () => {
        if (selectedAdset) {
            handleDeleteAdset(selectedAdset);
            setModalOpen(false);
            setSelectedAdset(null);
        }
    };

    const renderAdsetsList = () => {
        if (isLoadingadsetsList) {
            return <Spinner mt="20px" />;
        }

        if (adsetsListData?.data.length > 0) {
            return adsetsListData.data.map((adset, index) => {
                const { _id, name, daily_budget, lifetime_budget, bid_strategy, billing_event, optimization_goal, destination_type, targeting, promoted_object } = adset;

                return (
                    <Flex
                        borderWidth={1}
                        borderColor={addBoxBorder}
                        borderRadius={5}
                        p={"20px"}
                        w='100%'
                        minH={"150px"}
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                        key={_id}
                        pos={'relative'}
                    >
                        <Box>
                            <Heading as='h3' size={"sm"} mb={'10px'}>{name}</Heading>
                            <Box mb={'10px'}>
                                {/* <Text fontSize={'12px'} color={'gray.800'} fontWeight={'bold'}>Conversion location: </Text> */}
                                <Text fontSize={'14px'} color={'gray.800'} mb={'5px'}> {adsetMapping?.destinationTypes[destination_type]}</Text>
                            </Box>

                            <Box >
                                {/* <Text fontSize={'12px'} color={'gray.800'} fontWeight={'bold'}>Performance goal: </Text> */}
                                <Text fontSize={'12px'} color={'gray.800'} mb={'5px'}> {adsetMapping?.optimizationGoals[destination_type]?.find(goal => goal.value === optimization_goal)?.label}</Text>
                            </Box>

                        </Box>
                        <Flex mt={"20px"} justifyContent={'space-between'} h={'fit-content'} align={'center'}>
                            <Flex gap={'20px'}>
                                <Tooltip label='View' fontSize='xs'>
                                    <ViewIcon
                                        transition={"0.2s ease"}
                                        color={editIconColor}
                                        _hover={{ cursor: "pointer", color: editIconHoverColor }}
                                        onClick={() => handleViewAdset({ _id, name, daily_budget,lifetime_budget, bid_strategy, billing_event, optimization_goal, destination_type, targeting, promoted_object })}
                                    />
                                </Tooltip>
                                {userInfo && userInfo.role === 'client' ? (
                                <Tooltip label='Edit' fontSize='xs'>       
                                    <EditIcon
                                        transition={"0.2s ease"}
                                        color={editIconColor}
                                        _hover={{ cursor: "pointer", color: editIconHoverColor }}
                                        onClick={() => { setSelectedAdset(_id); setisEditing(true); onEditModalOpen() }}
                                    />
                                </Tooltip>
                                ): (null)}
                            </Flex>
                            {userInfo && userInfo.role === 'client' ? (
                            <Tooltip label='Delete' fontSize='xs'>
                                <Flex align={'center'} >
                                    <Icon
                                        color="red.500"
                                        _hover={{ color: 'gray.400' }}
                                        as={MdDelete}
                                        cursor="pointer"
                                        boxSize={4}
                                        onClick={() => {
                                            setSelectedAdset(_id);
                                            setModalOpen(true);
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                            ): (null)}
                        </Flex>
                    </Flex>
                );
            });
        } else {
            return (
                <Flex align={'center'} justify={'center'}>
                    <Text >Please add Ad Sets to run your campaign.</Text>
                </Flex>
            );
        }
    };  

    const handleCreateOpen = () => {
        setisEditing(false);
        onCreateOpen();
    };

    return (
        <>
            <DeleteConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={confirmDelete}
                message="Do you wish to proceed with deleting this Ad Set?"
            />

            {/* CREATE ADSET MODAL */}
            <Modal
                size={"5xl"}
                isCentered={true}
                isOpen={isCreateOpen}
                scrollBehavior={"inside"}
                onClose={onCreateClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>New Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {(isLoadingSales || isLoadingLeads || isBmAdAccountsLoading) ? (
                            <Flex justify="center" align="center" height="200px">
                                <Spinner size="lg" />
                            </Flex>
                        ) : (
                            renderForm()
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* EDIT ADSET MODAL */}
            <Modal
                size={"5xl"}
                isCentered={true}
                isOpen={isEditModalOpen}
                scrollBehavior={"inside"}
                onClose={() => { onEditModalClose(); setSelectedAdset(null); setisEditing(false); }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {(isLoadingSales || isLoadingLeads) ? (
                            <Flex justify="center" align="center" height="200px">
                                <Spinner size="lg" />
                            </Flex>
                        ) : (
                            renderForm()
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* VIEW ADSET MODAL */}
            <Modal
                size={"lg"}
                isCentered={true}
                isOpen={isViewOpen}
                scrollBehavior={"inside"}
                onClose={() => {
                    setSelectedAdset(null)
                    onViewClose()
                }
                }
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ad set Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedAdset ? (
                            <Flex gap={2} flexDirection={'column'}>
                                <TableContainer>
                                    <Table variant='simple' size={"sm"}>
                                        <Thead>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Ad set name</Td>
                                                <Td>{selectedAdset?.name}</Td>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Conversion location</Td>
                                                <Td>{adsetMapping?.destinationTypes[selectedAdset?.destination_type]}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Performance goal</Td>
                                                <Td> {adsetMapping?.optimizationGoals[selectedAdset?.destination_type]?.find(goal => goal.value === selectedAdset?.optimization_goal)?.label}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Bid strategy</Td>
                                                <Td>{adsetMapping?.bidStrategies[selectedAdset?.bid_strategy]?.label}</Td>
                                            </Tr>
                                            {selectedAdset?.daily_budget ? (
                                                <Tr>
                                                    <Td w={"240px"} color='gray.500'>Daily budget</Td>
                                                    <Td>{selectedAdset?.daily_budget/100}</Td>
                                                </Tr>
                                            ) : 
                                                <Tr>
                                                    <Td w={"240px"} color='gray.500'>Lifetime budget</Td>
                                                    <Td>{selectedAdset?.lifetime_budget/100}</Td>
                                                </Tr>
                                            }
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>When you are charged</Td>
                                                <Td textTransform={'lowercase'}>{selectedAdset?.billing_event}</Td>
                                            </Tr>
                                            {selectedAdset?.promoted_object?.length > 0 ? (
                                                <Tr>
                                                    <Td w={"240px"} color='gray.500'>Pixel </Td>
                                                    <Td>
                                                        {selectedAdset?.promoted_object?.map((pixel, index) => (
                                                            <Box key={index + pixel?.adsAccountId} p={1}>
                                                                <Text>Ad Account: {pixel?.adsAccountId}</Text>
                                                                <Text>Pixel: {pixel?.pixel_id}</Text>
                                                                <Text>Event type: {pixel?.custom_event_type}</Text>
                                                            </Box>
                                                        ))}
                                                    </Td>
                                                </Tr>
                                            ) : null}

                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Status</Td>
                                                <Td>
                                                    <Badge colorScheme={selectedAdset?.status ? 'green' : 'red'}>
                                                        {selectedAdset?.status ? 'Active' : 'Inactive'}
                                                    </Badge>
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Flex>
                        ) : (
                            <Text>No details available</Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box p={'20px'} height="100%" display="flex" flexDirection="column">
                {(isLoadingSales || isLoadingLeads) ? (
                    <Spinner mt="20px" />
                ) : (
                    <Box flex="1" overflowY="auto" display="flex" flexDirection="column">
                        <Grid gridTemplateColumns={"repeat( auto-fill, minmax(120px, 200px) )"} gap={"20px"}>
                        {userInfo && userInfo.role === 'client' ? (
                            <Flex
                                borderWidth={1}
                                borderColor={addBoxBorder}
                                borderRadius={5}
                                p={"20px"}
                                w='100%'
                                minH={"180px"}
                                _hover={{
                                    bg: addBoxHoverBackground,
                                    cursor: "pointer",
                                }}
                                alignItems={"center"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                                onClick={handleCreateOpen}
                            >
                                <AddIcon boxSize={4} mb={2} />
                                <Text size={"md"}>Add New</Text>
                            </Flex>
                            ):(null)}   
                            {renderAdsetsList()}
                        </Grid>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default AdSetForm;
