import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box, Flex,
    Select,
    SkeletonText,
    Text, 
    Card, 
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetPagesQuery } from 'store/BmAccounts/bmAccountApi';

function FaceBookPageSelector({ adsAccount, pageIdToSend, onSelectedPage, selectedPageId, adData }) {

    const { account_id, name } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState(selectedPageId || '');
    const userInfo = useSelector((state) => state.auth?.userInfo);

    const { data: facebookPages = [], isLoading: isFbPageLoading } = useGetPagesQuery(
        { search: '',  },
    );

    useEffect(() => {
        setselectedPage(selectedPageId || '');
    }, [selectedPageId]);


    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, account_id);
    };

    const isDisableStatic = (pageIdToSend === selectedPageId && pageIdToSend !== '' && selectedPageId !== '');

    return (
        <Box>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{name}</Text>
                    <Text fontSize={'12px'}>{account_id}</Text>
                </Box>

                <Box flex={1}>
                    {isFbPageLoading ? (
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <SkeletonText width="80%" noOfLines={2} skeletonHeight={2} />
                        </Flex>
                    ) : (
                        <Select
                            placeholder="Facebook page"
                            value={selectedPage}
                            onChange={handleEventPageChange}
                            borderRadius={'md'}
                            size={'sm'}
                            isDisabled={userInfo?.role !== 'client' || isDisableStatic}
                        >
                            {facebookPages?.map((page) => (
                                <option key={page?.id} value={page?.id}>
                                    {page?.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </Box>
            </Flex>
            {isDisableStatic && (
                <Card>
                    <Flex gap={2} p={3}>
                        <InfoOutlineIcon />
                            <Text fontSize="12px">
                            Select a Page at the ad set level
                            For campaigns that use the Leads objective, you must select a Page to represent your business at the ad set level. The same Page will automatically be selected for your ad.
                        </Text>
                    </Flex>
                </Card>
            )}
        </Box>
    )
}

export default FaceBookPageSelector;
