import {
    Box,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    useToast,
    Select,
    Textarea,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InstagramAccountSelector from 'components/adspresso/InstagramAccountSelector';
import PhoneInput from 'react-phone-input-2'; 
import 'react-phone-input-2/lib/style.css';


const SingleVideoAdUpdateForm = ({ adData, adsAccountData, call_to_action_options, onClose }) => {
    const toast = useToast();
    const { creative } = adData || {};
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    
    const { data: adCreative } = useGetAdCreativeQuery(
        { creativeId: creative.id },
        { skip: !creative.id }
    );

    const [updateAd] = useUpdateAdMutation();

    // console.log(adCreative)

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        creative: Yup.object().shape({
            name: Yup.string().required('Headline is required'),
            object_story_spec: Yup.object().shape({
                video_data: Yup.object().shape({
                    message: Yup.string().required('Primary Text is required'),
                    title: Yup.string().required('Headline is required'),
                    link_description: Yup.string().required('Description is required'),
                    video_id: Yup.string().required('Video ID is required'),
                    call_to_action: Yup.object().shape({
                        type: Yup.string().required('Call to action type is required'),
                        value: Yup.object().shape({
                            link: Yup.string().when('type', {
                                is: 'CALL_NOW',
                                then: Yup.string().matches(/tel:\+?[0-9]{1,15}/, 'Invalid phone number')
                            }),
                        }),
                    }),
                }),
                page_id: Yup.string().required('Facebook Page ID is required'),
                // instagram_actor_id: Yup.string().required('Instagram Actor ID is required'),
            }),
        }),
        image_url: Yup.string().url('Invalid URL'),
    });

    const formik = useFormik({
        initialValues: {
            name: adData?.name || '',
            hash: adData?.hash || '',
            creative: {
                name: adCreative?.name || '',
                object_story_spec: {
                    video_data: {
                        message: adCreative?.object_story_spec?.video_data?.message || '',
                        title: adCreative?.object_story_spec?.video_data?.title || '',
                        link_description : adCreative?.object_story_spec?.video_data?.link_description || '',
                        video_id: adCreative?.object_story_spec?.video_data?.video_id || '',
                        call_to_action: {
                            type: adCreative?.object_story_spec?.video_data?.call_to_action?.type || "SHOP_NOW",
                            value: adCreative?.object_story_spec?.video_data?.call_to_action?.value || {},
                        },
                    },
                    page_id: adCreative?.object_story_spec?.page_id || '',
                    instagram_actor_id: adCreative?.object_story_spec?.instagram_actor_id || '',
                },
            },
            image_url: adCreative?.image_url || '',
            picture: adCreative?.picture || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {

            const isCreativeChanged = () => {
                const original = adCreative || {};
                const updated = values.creative || {};
            
                // Compare specific fields
                return (
                    original.name !== updated.name ||
                    original.object_story_spec?.video_data?.message !== updated.object_story_spec?.video_data?.message ||
                    original.object_story_spec?.video_data?.title !== updated.object_story_spec?.video_data?.title ||
                    original.object_story_spec?.video_data?.link_description !== updated.object_story_spec?.video_data?.link_description ||
                    original.object_story_spec?.video_data?.video_id !== updated.object_story_spec?.video_data?.video_id ||
                    original.object_story_spec?.video_data?.call_to_action?.type !== updated.object_story_spec?.video_data?.call_to_action?.type ||
                    original.object_story_spec?.video_data?.call_to_action?.value?.link !== updated.object_story_spec?.video_data?.call_to_action?.value?.link ||
                    original.object_story_spec?.page_id !== updated.object_story_spec?.page_id ||
                    original.object_story_spec?.instagram_actor_id !== updated.object_story_spec?.instagram_actor_id ||
                    original.image_url !== values.image_url
                );
            };

            const formatPhoneNumber = (phoneNumber) => {
        
                if (!phoneNumber.startsWith('tel:+')) {
                    return `tel:+${phoneNumber}`;
                }
                return phoneNumber;
            };

            const hasCreativeChanged = isCreativeChanged();

            const payload = {
                ...(hasCreativeChanged && {
                    creative: {
                        name: values.creative.name,
                        hash: values.hash,
                        object_story_spec: {
                            video_data: {
                                image_url: values.image_url,
                                message: values.creative.object_story_spec.video_data.message,
                                video_id: values.creative.object_story_spec.video_data.video_id,
                                title: values.creative.object_story_spec.video_data.title,
                                link_description: values.creative.object_story_spec.video_data.link_description,
                                call_to_action: {
                                    type: values.creative.object_story_spec.video_data.call_to_action.type,
                                    value: {
                                        link: values.creative.object_story_spec.video_data.call_to_action.type === "CALL_NOW"
                                        ? formatPhoneNumber(values.creative.object_story_spec.video_data.call_to_action.value.link)
                                        : values.creative.object_story_spec.video_data.call_to_action.value.link,
                                    },
                                },
                            },
                            page_id: values.creative.object_story_spec.page_id,
                            instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                        },
                        degrees_of_freedom_spec: {
                            creative_features_spec: {
                                standard_enhancements: {
                                    enroll_status : "OPT_OUT"
                                }
                            }
                        },
                        adsAccount: {
                            id: adsAccountData._id,
                            adsAccountId: adsAccountData.adsAccountId,
                            adsAccountName: adsAccountData.adsAccountName,
                        },
                    },
                }),
                ...(values.name !== adData.name ? { name: values.name } : {}), // Include name only if it has changed
            };

            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            formik.setFieldValue('hash', selectedFile?.hash);
            formik.setFieldValue('image_url', selectedFile?.picture);
            formik.setFieldValue('creative.object_story_spec.video_data.video_id', selectedFile?.creativeId);
        }
        setIsDriveModalOpen(false);
    };

    if (!adCreative) {
        return <Spinner mt={'20px'} />;
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex direction={'column'} borderRadius="md" bg="gray.50" p={4} gap={4}>
                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Ad Name</Text>
                    <Input
                        name="name"
                        placeholder="Enter your ad name here..."
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Facebook Page</Text>
                    <FaceBookPageSelector
                        adsAccount={adsAccountData}
                        selectedPageId={formik.values.creative?.object_story_spec?.page_id}
                        onSelectedPage={(pageId) => {
                            formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                        }}
                    />
                    {formik.errors.creative?.object_story_spec?.page_id && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Instagram accounts</Text>
                    <InstagramAccountSelector
                        adsAccount={adsAccountData}
                        selectedAccountId={formik.values.creative?.object_story_spec?.instagram_actor_id}
                        onSelectedPage={(pageId) => {
                            formik.setFieldValue('creative.object_story_spec.instagram_actor_id', pageId);
                        }}
                    />
                    {/* {formik.errors.creative?.object_story_spec?.instagram_actor_id && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.instagram_actor_id}</Text>
                    )} */}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Primary Text</Text>
                    <Input
                        name="creative.object_story_spec.video_data.message"
                        placeholder="Message"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.creative.object_story_spec.video_data.message}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.creative?.object_story_spec?.video_data?.message && formik.errors.creative?.object_story_spec?.video_data?.message && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.message}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Headline</Text>
                    <Input
                        name="creative.object_story_spec.video_data.title"
                        placeholder="Enter a headline"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.creative.object_story_spec.video_data.title}
                        onChange={formik.handleChange}
                    />
                     {formik.touched.creative?.object_story_spec?.video_data?.title && formik.errors.creative?.object_story_spec?.video_data?.title && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.title}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Description</Text>
                    <Textarea
                        name="creative.object_story_spec.video_data.link_description"
                        placeholder="Include additional details"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.creative.object_story_spec.video_data.link_description}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.creative?.object_story_spec?.video_data?.link_description && formik.errors.creative?.object_story_spec?.video_data?.link_description && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.link_description}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                <Text fontWeight="bold">Call to Action</Text>

                {formik.values.creative.object_story_spec.video_data.call_to_action.type === 'CALL_NOW' ? (
                    <Select
                    name="creative.object_story_spec.video_data.call_to_action.type"
                    bg="#fff"
                    size="sm"
                    borderRadius="md"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creative.object_story_spec.video_data.call_to_action.type}
                    isDisabled
                    >
                    <option value="CALL_NOW">Call Now</option>
                    </Select>
                ) : (
                    <Select
                    name="creative.object_story_spec.video_data.call_to_action.type"
                    bg="#fff"
                    size="sm"
                    borderRadius="md"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creative.object_story_spec.video_data.call_to_action.type}
                    >
                    {call_to_action_options?.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </Select>
                )}

                {formik.touched.creative?.object_story_spec?.video_data?.call_to_action?.type &&
                formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.type && (
                    <Text fontSize="12px" color="red.500">
                    {formik.errors.creative.object_story_spec.video_data.call_to_action.type}
                    </Text>
                )}
                </Flex>

                {formik.values.creative.object_story_spec.video_data.call_to_action.type === 'CALL_NOW' && (
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Phone Number</Text>
                        <PhoneInput
                            name="creative.object_story_spec.video_data.call_to_action.value.link"
                            value={formik.values.creative.object_story_spec.video_data.call_to_action.value.link}
                            onChange={(value) => formik.setFieldValue('creative.object_story_spec.video_data.call_to_action.value.link', value)}
                            country={'in'} 
                        />
                        {formik.touched.creative?.object_story_spec?.video_data?.call_to_action?.value?.link && formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.value?.link && (
                            <Text fontSize="12px" color="red.500">{formik.errors.creative.object_story_spec.video_data.call_to_action.value.link}</Text>
                        )}
                    </Flex>
                )}

                {formik.values.creative.object_story_spec.video_data.call_to_action.type !== 'CALL_NOW' ?  (
                      <Flex direction="column" gap={1}>
                      <Text fontWeight="bold">Link URL</Text>
                      <Input
                          name="creative.object_story_spec.video_data.call_to_action.value.link"
                          placeholder="Enter a URL"
                          bg="#fff"
                          size='sm'
                          borderRadius="md"
                          value={formik.values.creative.object_story_spec.video_data.call_to_action.value.link}
                          onChange={formik.handleChange}
                      />
                      {formik.touched.creative?.object_story_spec?.video_data?.call_to_action?.value?.link && formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.value?.link && (
                          <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.value?.link}</Text>
                      )}
                  </Flex>
                ) : (null)}

                <Text fontWeight="bold">Creative</Text>
                <Flex direction="row" mb={4} gap={2} alignItems={'center'}>
                    <img
                        src={formik.values.image_url || ''} 
                        alt={formik.values.name}
                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                    />
                    <Button 
                        bg={'gray.50'}
                        size="xs"
                        borderWidth={1}
                        onClick={handleOpenDriveModal}>
                        Change Video
                    </Button>
                </Flex>
            </Flex>
            <Flex mt={4}>
                <Button
                    size="sm"
                    colorScheme="orange"
                    isLoading={formik.isSubmitting}
                    type="submit"
                    isDisabled={!formik.isValid}
                >
                    Save
                </Button>
            </Flex>

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccountData._id,
                    adsAccountId: adsAccountData.adsAccountId,
                    adsAccountName: adsAccountData.adsAccountName,
                }]} 
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect} 
                uploadPurpose="update" 
                mediaType="video"
            />
        </form>
    );
};

export default SingleVideoAdUpdateForm;