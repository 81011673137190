import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Grid } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const days = [
  "Sunday",   // 0
  "Monday",   // 1
  "Tuesday",  // 2
  "Wednesday",// 3
  "Thursday", // 4
  "Friday",   // 5
  "Saturday", // 6
  "Every Day",
];

const hourLabels = [
  "12", "am", 
  "", 
  "3", "am", 
  "", 
  "6", "am", 
  "", 
  "9", "am", 
  "", 
  "12", "pm", 
  "", 
  "3", "pm", 
  "", 
  "6", "pm", 
  "", 
  "9", "pm", 
  ""
];

const AdScheduler = ({ onAdScheduleChange, adsetScheduleData }) => {
  const totalHours = 24;
  const [schedule, setSchedule] = useState(Array(days.length - 1).fill(Array(totalHours).fill(false)));
  const [isDragging, setIsDragging] = useState(false);
  const [adSchedule, setAdSchedule] = useState([]);

  // Initialize the schedule based on adsetScheduleData
  useEffect(() => {
    const newSchedule = Array(days.length - 1).fill().map(() => Array(totalHours).fill(false));

    adsetScheduleData?.forEach(entry => {
      const startHour = Math.floor(entry.start_minute / 60);
      const endHour = Math.floor(entry.end_minute / 60);
      entry.days.forEach(day => {
        for (let hourIndex = startHour; hourIndex < endHour; hourIndex++) {
          newSchedule[day][hourIndex] = true; // Mark the hour as scheduled for the specific day
        }
      });
    });

    setSchedule(newSchedule);
  }, [adsetScheduleData]);

  // Update adSchedule whenever the schedule changes
  useEffect(() => {
    const newAdSchedule = [];
    schedule?.forEach((daySchedule, dayIndex) => {
      daySchedule?.forEach((isScheduled, hourIndex) => {
        if (isScheduled) {
          const startMinute = hourIndex * 60;
          const endMinute = (hourIndex + 1) * 60;
          const existingScheduleIndex = newAdSchedule.findIndex(schedule => 
            schedule.start_minute === startMinute && 
            schedule.end_minute === endMinute
          );

          if (existingScheduleIndex !== -1) {
            const existingSchedule = newAdSchedule[existingScheduleIndex];
            existingSchedule.days.push(dayIndex);
          } else {
            newAdSchedule.push({ days: [dayIndex], start_minute: startMinute, end_minute: endMinute });
          }
        }
      });
    });
    
    setAdSchedule(newAdSchedule);
    onAdScheduleChange(newAdSchedule); // Call the callback with the new schedule
  }, [schedule]);

  const handleMouseDown = (dayIndex, hourIndex) => {
    if (dayIndex === 7) {
      toggleEveryDay(hourIndex);
    } else {
      toggleSlot(dayIndex, hourIndex);
      const everyDaySelected = schedule.every((day) => day[hourIndex]);
      if (everyDaySelected) {
        toggleEveryDay(hourIndex);
      }
    }
    setIsDragging(true);
  };

  const handleMouseOver = (dayIndex, hourIndex) => {
    if (isDragging) {
      if (dayIndex === 7) {
        toggleEveryDay(hourIndex);
      } else {
        toggleSlot(dayIndex, hourIndex);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const toggleSlot = (dayIndex, hourIndex) => {
    const newSchedule = schedule.map((day, dIdx) =>
      day.map((hour, hIdx) =>
        dIdx === dayIndex && hIdx === hourIndex ? !hour : hour
      )
    );
    setSchedule(newSchedule);
  };

  const toggleEveryDay = (hourIndex) => {
    const currentEveryDayState = schedule.every((day) => day[hourIndex]);
    
    const newSchedule = schedule.map((day) =>
      day.map((hour, hIdx) => (hIdx === hourIndex ? !currentEveryDayState : hour))
    );
    
    setSchedule(newSchedule);
  };

  return (
    <Box onMouseUp={handleMouseUp}>
      <Flex alignItems="center" mb={6} gap={2} bg={'white'} p={2} borderRadius={'5px'}>
        <InfoOutlineIcon pointerEvents="all" ml={2} />
        <Text fontSize="14px">Click and drag to schedule multiple times at once.</Text>
      </Flex>
      <Grid templateColumns={`repeat(${totalHours + 1}, 1fr)`} gap={'0.5px'}>
        <Box></Box>
        {hourLabels.map((label, index) => (
           <Box key={index}  textAlign={label === "am" || label === "pm" ? "left" : "center"} fontSize="12px" flex="1" mb={1}>
              {label}
          </Box>
        ))}
        
        {/* Render Days from Monday to Saturday */}
        {days.slice(1, 7).map((day, dayIndex) => ( // Skipping Sunday i,e(index 0)
          <React.Fragment key={dayIndex}>
            <Box textAlign="right" fontSize="12px" mr={2}>{day}</Box>
            {Array.from({ length: totalHours }).map((_, hourIndex) => (
              <Box
                key={hourIndex}
                h="30px"
                borderLeft={(hourIndex % 3 === 0) ? "1px" : "none"}
                borderRight={(hourIndex === 23) ? "1px" : "none"}
                borderBottom={"1px"}
                borderTop={(dayIndex === 0) ? "1px" : "none"}
                borderColor="gray.300"
                cursor="pointer"
                bg={schedule[dayIndex + 1][hourIndex] ? "blue.100" : "white"}  
                onMouseDown={() => handleMouseDown(dayIndex + 1, hourIndex)} 
                onMouseOver={() => handleMouseOver(dayIndex + 1, hourIndex)} 
                position="relative"
              >
              </Box>
            ))}
          </React.Fragment>
        ))}
        
        {/* Render Sunday after Saturday (same row) */}
        <Box textAlign="right" fontSize="12px" mr={2}>Sunday</Box>
        {Array.from({ length: totalHours }).map((_, hourIndex) => {
          const isEveryDaySelected = schedule[0][hourIndex]; // Sunday is index 0
          return (
            <Box
              key={hourIndex}
              h="30px"
              borderLeft={(hourIndex % 3 === 0) ? "1px" : "none"}
              borderRight={(hourIndex === 23) ? "1px" : "none"}
              borderBottom={"1px"}
              borderColor="gray.300"
              cursor="pointer"
              bg={isEveryDaySelected ? "blue.100" : "white"}
              onMouseDown={() => handleMouseDown(0, hourIndex)} // Sunday is index 0
              onMouseOver={() => handleMouseOver(0, hourIndex)} // Sunday is index 0
            />
          );
        })}
        
        {/* Render Every Day Row */}
        <Box textAlign="right" fontSize="12px" mt={2} mr={2}>Every Day</Box>
        {Array.from({ length: totalHours }).map((_, hourIndex) => {
          const isEveryDaySelected = schedule.every(day => day[hourIndex]);
          return (
            <Box
              key={hourIndex}
              h="30px"
              border={"1px"}
              borderColor="gray.300"
              cursor="pointer"
              mt={2}
              bg={isEveryDaySelected ? "blue.100" : "white"}
              onMouseDown={() => handleMouseDown(7, hourIndex)}
              onMouseOver={() => handleMouseOver(7, hourIndex)}
            />
          );
        })}
      </Grid>

      <Flex mt={4} ml={'72px'} alignItems="center" gap={2}>
          <Box bg="blue.100" h="20px" w="20px"/>
          <Text fontSize="12px" mr={2}>Scheduled hours</Text>
      </Flex>
    </Box>
  );
};

export default AdScheduler;
