import { api } from '../api'

export const adRuleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAdRule: builder.mutation({
      query: (payload) => ({
        url: '/adrules',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['AdRule'],
    }),
    getAdRules: builder.query({
      query: ({ currentPage, searchValue, adsAccountId, BMID }) => ({
        url: `/adrules/${adsAccountId}?page=${currentPage}&search=${searchValue}`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    updateAdRule: builder.mutation({
      query: ({ AdRuleId, payload }) => ({
        url: `/adrules/${AdRuleId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['AdRule'],
    }),
    updateAdRuleStatus: builder.mutation({
      query: ({ AdRuleId, payload }) => ({
        url: `/adrules/update/status/${AdRuleId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['AdRule'],
    }),
    deleteAdRule: builder.mutation({
      query: AdRuleId => ({
        url: `/adrules/${AdRuleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdRule']
    }),
  }),
})

export const {
  useCreateAdRuleMutation,
  useGetAdRulesQuery,
  useUpdateAdRuleMutation,
  useUpdateAdRuleStatusMutation,
  useDeleteAdRuleMutation
} = adRuleApi;