import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box, Button, Checkbox, CheckboxGroup, Divider, Flex, Icon, Input, Modal, Select, Text, Textarea, Tooltip, useToast, Radio, RadioGroup, Table, Thead, Tbody, Tr, Th, Td,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Collapse
} from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiChevronUp } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { useCreateAdspressoCreativeMutation, useRemoveAdMutation, useUpdateAdspressoAdCreativeByIdMutation } from 'store/adspresso/adspressoAdApi';
import { array, object, string } from "yup";
import BuildUrlParams from './BuildUrlParams';
import BuildUrlParamSingle from './BuildUrlParamSingle';
import UrlParameter from './UrlParameter';
import { useGetInstantFormsByPageIdQuery } from 'store/campaigns/adsApi';
import { getFileNameWithoutExtension } from 'utils/functions';

function isValidUrl(url) {
  const regex = /^(https?:\/\/)?([\w\d\-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
  return regex.test(url);
}

const isValidPhoneNumber = (phoneNumber) => {
  const countryCodePattern = /^[0-9]{2}/;
  const phoneNumberPart = phoneNumber.slice(2); // Remove the first 2 digits (country code)
  const regex = /^[0-9]{10}$/;
  return countryCodePattern.test(phoneNumber) && regex.test(phoneNumberPart);
};


function CarouselMediaCreative({ adsAccounts, adFormik, objective, adsetsListData, campaignId, call_to_action_options, call_to_action_options_instantForms, adData }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [uploadPurpose, setuploadPurpose] = useState(null);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedAdSets, setSelectedAdSets] = useState({});
  const [mediaFiles, setMediaFiles] = useState([]);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [fileBeingEdited, setFileBeingEdited] = useState(null);
  const [mediaFilesState, setMediaFilesState] = useState({});
  const [maxCards] = useState(10);
  const [hasSameDestinationType, sethasSameDestinationType] = useState(true);
  const [activeDestinationType, setactiveDestinationType] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [links, setLinks] = useState([]);
  const [isUrlModalOpen, setUrlModalOpen] = useState(false);
  const [isSingleUrlModalOpen, setSingleUrlModalOpen] = useState(false);
  const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [url_tags, seturl_tags] = useState([]);
  const [isFocused, setIsFocused] = useState({});


  const [createAdspressoAds, { isLoading: isAdsCreating, error: createError }] = useCreateAdspressoCreativeMutation();
  const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
  const [removeAd, removeAdResponse] = useRemoveAdMutation();
  const isInstantFormAvailable = adsetsListData?.data?.some(adset =>
    adset?.destination_type === "ON_AD" &&
    objective === "OUTCOME_LEADS"
  );

  const pageIds = adData?.adsAccounts.map(account => account?.page_id);


  const { data: instantForms, isLoading: isInstantFormsLoading, error:instantFormsError} = useGetInstantFormsByPageIdQuery(
    { pageIds },
    { skip:!pageIds.length || !isInstantFormAvailable }
  );

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };


  const handleInputChange = (fileId, field, value) => {

    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        [field]: value,
      },
    }));
  };

  const handleMediaTypeChange = (adAccounts, type) => {
    setSelectedAdAccounts(adAccounts);
    setMediaType(type);
    setIsDriveOpen(true);
  }

  const handleAdAccountChange = (selectedValues) => {
    const selectedAccounts = adsAccounts.filter(account => selectedValues.includes(account.adsAccountId));
    setSelectedAdAccounts(selectedAccounts);

    // Filter out media files associated with deselected accounts
    const updatedMediaFiles = mediaFiles.filter(file =>
      selectedValues.includes(file?.adsAccount?.adsAccountId)
    );

    setMediaFiles(updatedMediaFiles);
    // Update links state with initial empty URLs for selected ad accounts
    setLinks(prevLinks => {
      const updatedLinks = selectedAccounts.map(account => ({
        adsAccountId: account?.adsAccountId,
        link: prevLinks.find(link => link?.adsAccountId === account?.adsAccountId)?.link || '' // keep previous value if exists
      }));
      return updatedLinks;
    });

    //  // Update phoneNumbers state with initial empty phone numbers for selected ad accounts
    //  setPhoneNumbers(prevPhoneNumbers => {
    //   const updatedPhoneNumbers = selectedAccounts.map(account => ({
    //     adsAccountId: account?.adsAccountId,
    //     phoneNumber: prevPhoneNumbers.find(phone => phone?.adsAccountId === account?.adsAccountId)?.phoneNumber || '' // keep previous value if exists
    //   }));
    //   return updatedPhoneNumbers;
    // });

    seturl_tags(prevUrl => {
      const updatedurl_tags = selectedAccounts.map(account => ({
        adsAccountId: account?.adsAccountId,
        url_tags: prevUrl.find(url => url?.adsAccountId === account?.adsAccountId)?.url_tags || '' // keep previous value if exists
      }));
      return updatedurl_tags;
    });
  };

  const handleAdSetChange = (selectedValues) => {
    setSelectedAdSets(prev => {
      const updated = {};
      selectedAdAccounts.forEach(account => {
        updated[account.adsAccountId] = selectedValues;
      });
      return updated;
    });
  };

  useEffect(() => {
    if (mediaFiles.length > 0) {
      setOpenAccordionId(mediaFiles[0]._id);
    }
  }, [mediaFiles]);

  const handleFileSelect = (files) => {

    if (files.length > 0) {
      if (fileBeingEdited) {
        // Handle update scenario
        setMediaFiles(files);
        const selectedFile = files[0];

        const payload = {
          [selectedFile?.creativeType === 'video' ? 'video_id' : 'image_hash']: selectedFile?.creativeId,
          ...(selectedFile?.creativeType === 'image' ? { url: selectedFile?.url } : { thumbnail: selectedFile?.picture }),
          hash: selectedFile?.hash,
          fileName: getFileNameWithoutExtension(selectedFile.name)
        };

        updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
          .then(response => {
            console.log('Ad creative updated successfully:', response);
          })
          .catch(error => {
            toast({
              position: "top-right",
              title: "Error updating image",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      } else {
        // Handle creation scenario
        setMediaFiles(prevFiles => {
          // Add new files only if the total number of files is less than maxCards
          const newFiles = [...prevFiles, ...files].slice(0, maxCards);
          return newFiles;
        });
      }
      setFileBeingEdited(null);
    }
  };

  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };

  let schema = object().shape({
    destinationType: string(),
    type: string()
      .max(300, 'select atleast one type')
      .required('Type is required'),
    // message: string().when('applyForAll', {
    //   is: true,
    //   then: () => string().required('Required'),
    //   otherwise: () => string().notRequired(),
    // }),
    // message: string().required('Text required'),
    // link: string()
    //   .url('Please add a valid URL')
    // .when('destinationType', {
    //   is: 'WEBSITE',
    //   then: () => string().required('Website URL is required'),
    //   otherwise: () => string().notRequired(),
    // }),
    // link: array().of(
    //   object().shape({
    //     adsAccountId: string().required('Ads Account ID is required'),
    //     link: string()
    //       .url('Please add a valid URL')
    //       .when('destinationType', {
    //         is: 'WEBSITE',
    //         then: () => string().required('Website URL is required'),
    //         otherwise: () => string().notRequired(),
    //       })
    //   })
    // )
    //   .when('destinationType', {
    //     is: 'WEBSITE',
    //     then: () => array().min(1, 'At least one website URL is required'),
    //     otherwise: () => array().notRequired(),
    // }),
    // phoneNumber: string().
    //   when('destinationType', {
    //     is: 'PHONE_CALL',
    //     then: () => string().required('Phone number is required'),
    //     otherwise: () => string().notRequired(),
    //   })
    //   .min(10, 'Phone number is too short')
    //   .max(15, 'Phone number is too long'),
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      destinationType: activeDestinationType,
      message: '',
      name: '',
      description: '',
      link: [],
      type: activeDestinationType === 'WEBSITE' ? 'SHOP_NOW' : (activeDestinationType === 'PHONE_CALL' ? 'CALL_NOW' : 'SIGN_UP'),
      mediaFiles: [],
      phoneNumber: [],
      url_tags: [],
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      const { name, message, description, type, link, phoneNumber, url_tags } = values;

      const urlTags = url_tags.map(account => {
        return Object.fromEntries(
          Object.entries(account).filter(([key, value]) => value !== '')
        );
      });

      const adcreatives = mediaFiles?.map(file => {

        let videoId = file?.creativeType == 'video' ? { video_id: file?.creativeId } : null;
        let videoURL = file?.creativeType == 'video' ? { url: file?.source } : null;
        let imageHash = file?.creativeType == 'image' ? { image_hash: file?.creativeId } : null;
        let imageURL = file?.creativeType == 'image' ? { url: file?.url } : null;
        let videoThumbnail = file?.creativeType == 'video' ? { thumbnail: file?.picture } : null;

        return (
          {
            adsAccountId: file?.adsAccount?.adsAccountId,
            message: mediaFilesState[file?.id || file?.creativeId]?.message || '',
            name: mediaFilesState[file?.id || file?.creativeId]?.name || '',
            description: mediaFilesState[file?.id || file?.creativeId]?.description || '',
            link: activeDestinationType == 'PHONE_CALL' ? `tel:+${mediaFilesState[file?.id || file?.creativeId]?.link}`: mediaFilesState[file?.id || file?.creativeId]?.link || '',
            type: activeDestinationType == 'PHONE_CALL' ? 'CALL_NOW' : type,
            hash:file?.hash,
            ...videoId,
            ...videoURL,
            ...imageHash,
            ...imageURL,
            ...videoThumbnail,
          }
        )
      });

      const adsAccounts = selectedAdAccounts.map(account => ({
        id: account?.id,
        adsAccountId: account?.adsAccountId,
        adsAccountName: account?.adsAccountName,
        businessName: account?.businessName,
        businessId: account?.businessId,
      }));

      const adsets = adsAccounts.flatMap(account => selectedAdSets[account.adsAccountId] || []);
      const uniqueAdsets = adsets.filter((item, index) => adsets.indexOf(item) === index);

      const payload = {
        adType: adFormik.values.format,
        contextual_multi_ads:{
          enroll_status: adFormik.values.enroll_status,
        },
        campaign: campaignId,
        adsets: uniqueAdsets,
        fileName: getFileNameWithoutExtension(mediaFiles[0]?.name),
        ...(message?.trim() && { message }),
        name: name,
        description: description,
        type: type,
        // ...(phoneNumber?.length 
        //   ? { links: phoneNumber.map(item => ({
        //       adsAccountId: item.adsAccountId,
        //       link: `tel:+${item.phoneNumber}`
        //   })) } 
        //   : { links: link }
        // ), 
        ...(link && { links: link }),
        ...(urlTags.length > 0 && { url_tags: urlTags }),
        adcreatives: adcreatives,
        adsAccounts: adsAccounts,
      };

      //  console.log({ payload });

      try {
        if (uploadPurpose === 'create') {
          createAdspressoAds(payload).unwrap()
            .then(response => {
              toast({
                position: "top-right",
                title: "Ad creative added",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              //console.log('Ads creative created successfully:', response);
            })
            .catch(error => {
              const errorMessage = error?.message || "An unexpected error occurred";

              toast({
                position: "top-right",
                title: "Error creating Ad creative",
                description: errorMessage,
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            });
        }
      } catch {
        // handle exception if necessary
      } finally {
        setuploadPurpose(null);
        setMediaType(null);
        setMediaFiles([]); // Clear the media files
        setMediaFilesState({});
        setSelectedAdAccounts([]); // Reset selected ad accounts
        setSelectedAdSets({}); // Clear selected ad sets
        resetForm();
        setactiveDestinationType('');
        setLinks([]);
        // setPhoneNumbers([]);
        seturl_tags([]);
      }

    },
  });

  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const handleDeleteAd = (adId) => {
    removeAd(adId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const confirmDelete = () => {
    if (selectedAd) {
      handleDeleteAd(selectedAd);
      setModalOpen(false);
      setSelectedAd(null);
    }
  };

  function checkSameDestinationType(selectedIds, adsets) {
    const filteredAdsets = adsets.filter(adset => selectedIds.includes(adset._id));

    if (filteredAdsets.length === 0) {
      return true;
    }

    // Get the destination_type of the first filtered adset
    const firstDestinationType = filteredAdsets[0].destination_type;

    // Loop through the filtered adsets and check if all have the same destination_type
    for (let i = 1; i < filteredAdsets.length; i++) {
      if (filteredAdsets[i].destination_type !== firstDestinationType) {
        return false;
      }
    }

    setactiveDestinationType(firstDestinationType);


    return true;
  }

  useEffect(() => {
    // console.log(checkSameDestinationType(Object.values(selectedAdSets).flat(), adsetsListData?.data))
    if (Object.values(selectedAdSets)?.flat()?.length > 1) {
      const checkresult = checkSameDestinationType(Object.values(selectedAdSets).flat(), adsetsListData?.data);
      sethasSameDestinationType(checkresult)
    } else if (Object.values(selectedAdSets)?.flat()?.length == 1) {
      //console.log(String(Object.values(selectedAdSets)?.flat()?.[0]));
      let activeSingleAdset = adsetsListData?.data?.filter((adset) => adset._id == String(Object.values(selectedAdSets)?.flat()?.[0]));
      // console.log(activeSingleAdset);
      setactiveDestinationType(activeSingleAdset?.[0]?.destination_type)
      sethasSameDestinationType(true)
    } else {
      setactiveDestinationType('')
    }

  }, [selectedAdSets])


  const isAnyUrlMissing = mediaFiles.some(file => {
    const fileId = file?.id || file?.creativeId;
    const fileState = mediaFilesState[fileId] || {};
    return !fileState.link || fileState.link.trim() === '';
  });


  // const handleLinkChange = (adsAccountId, link) => {
  //   const updatedLinks = [...singleMediaFormik.values.link];
  //   const index = updatedLinks.findIndex(item => item.adsAccountId === adsAccountId);

  //   if (index > -1) {
  //     updatedLinks[index].link = link;
  //   } else {
  //     updatedLinks.push({ adsAccountId, link });
  //   }

  //   singleMediaFormik.setFieldValue('link', updatedLinks);
  // };

  const handleLinkChange = (adsAccountId, value) => {
    setLinks(prevLinks => {
      const updatedLinks = prevLinks.map(link =>
        link.adsAccountId === adsAccountId ? { ...link, link: value } : link
      );
      singleMediaFormik.setFieldValue('link', updatedLinks);
      return updatedLinks;
    });
  };

  const handleUrlChange = (constructedUrl, adsAccountId) => {
    setLinks((prevLinks) => {
      const updatedLinks = prevLinks.map((link) =>
        link.adsAccountId === adsAccountId ? { ...link, link: constructedUrl } : link
      );
      singleMediaFormik.setFieldValue('link', updatedLinks);
      return updatedLinks;
    });
  };

  // const handlePhoneNumberChange = (adsAccountId, value) => {
  //   setPhoneNumbers(prevPhoneNumbers => {
  //     const updatedPhoneNumbers = prevPhoneNumbers.map(phone =>
  //       phone.adsAccountId === adsAccountId ? { ...phone, phoneNumber: value } : phone
  //     );
  //     singleMediaFormik.setFieldValue('phoneNumber', updatedPhoneNumbers); // Update formik field
  //     return updatedPhoneNumbers;
  //   });
  // };

  const handleSingleUrlChange = (constructedUrl, fileId) => {
    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        link: constructedUrl,
      },
    }));
  };

  const handleUrlTagChange = (adsAccountId, value) => {
    seturl_tags(prevUrl => {
      const updatedUrl = prevUrl.map(url =>
        url.adsAccountId === adsAccountId ? { ...url, url_tags: value } : url
      );
      singleMediaFormik.setFieldValue('url_tags', updatedUrl);
      return updatedUrl;
    });
  };

  const handleUrlParamChange = (constructedUrl, adsAccountId) => {
    seturl_tags(prevUrl => {
      const updatedUrl = prevUrl.map(url =>
        url.adsAccountId === adsAccountId ? { ...url, url_tags: constructedUrl } : url
      );
      singleMediaFormik.setFieldValue('url_tags', updatedUrl);
      return updatedUrl;
    });
  };

  const handleFocus = (adsAccountId) => {
    // Set the field as focused when the user starts typing
    setIsFocused(prev => ({
      ...prev,
      [adsAccountId]: true
    }));
  };

  const isAccountsMatching = !selectedAdAccounts.every(account => {
    // Count occurrences of the account's adsAccountId in mediaFiles
    const count = singleMediaFormik.values.mediaFiles.filter(mediaFile =>
      mediaFile.adsAccount?.adsAccountId === account.adsAccountId
    ).length;

    // Return true if the adsAccountId appears at least twice, otherwise false
    return count >= 2;
  });

  const allLinksAdded = (activeDestinationType === 'WEBSITE' || activeDestinationType === 'ON_AD') && links.every(link => link?.link !== '');

//  const allPhoneNumbersAdded = activeDestinationType === 'PHONE_CALL' && 
//   phoneNumbers.every(phone => phone?.phoneNumber !== '');
  
  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
          setFileBeingEdited(null);
        }}
        onSubmit={handleFileSelect}
        mediaFormat={adFormik?.values?.format}
        selectedAdAccounts={selectedAdAccounts}
        uploadPurpose={uploadPurpose}
        adFormik={adFormik}
      />

      <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
        size={"2xl"}
        isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BuildUrlParams links={links} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange} selectedAccountId={selectedAccountId} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSingleUrlModalOpen} onClose={() => setSingleUrlModalOpen(false)} scrollBehavior={"inside"}
        size={"2xl"}
        isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BuildUrlParamSingle link={mediaFilesState} onClose={() => setSingleUrlModalOpen(false)} onUrlChange={handleSingleUrlChange} selectedAccountId={selectedAccountId} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
        size={"2xl"}
        isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UrlParameter urls={url_tags} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange} selectedAccountId={selectedAccountId} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={confirmDelete}
        message="Do you wish to proceed with deleting this Ad?"
      />

      <Flex flex="3" direction="column" gap={2}>
        <Flex direction="column" mb={4}>
          {/* Ad Account Select */}
          <Flex direction={'column'} gap={'10px'} mb={'10px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad accounts</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={selectedAdAccounts.map(account => account.adsAccountId)}
              onChange={handleAdAccountChange}
            >
              {adsAccounts?.map(account => (
                <Box key={account.adsAccountId} px={'5px'} py={'2px'}>
                  <Checkbox value={account.adsAccountId}>
                    <Text fontSize={'14px'} noOfLines={2} maxWidth={'510px'}>
                      {/* {account.adsAccountName.length > 20
                          ? `${account.adsAccountName.substring(0, 20)}...`
                          : account.adsAccountName} */}
                      {account.adsAccountName}
                    </Text>
                  </Checkbox>
                </Box>
              ))}

            </CheckboxGroup>
          </Flex>

          {/* Ad Set Select */}
          <Flex direction="column" gap={'5px'} mb={'10px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad sets</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={Array.from(new Set(Object.values(selectedAdSets).flat()))}
              onChange={handleAdSetChange}
              isDisabled={!selectedAdAccounts.length > 0}
            >
              {adsetsListData?.data?.map((adSet, index) => (
                <Box key={`${adSet._id}-${index}`} px={'5px'} py={'2px'}>
                  <Checkbox value={adSet._id} display={'flex'} flex={1}>
                    <Text fontSize={'14px'} noOfLines={2} maxWidth={'510px'}>{adSet?.name}</Text>
                  </Checkbox>
                </Box>
              ))}
            </CheckboxGroup>

            {!hasSameDestinationType ? (
              <Flex>
                <Text color="red.500" fontSize={'12px'}>Conversion goals don't match &nbsp;
                </Text>
                <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="14px" p="5px">
                      <Text>
                        The selected Ad sets have different conversion goals. Please select Ad sets with the same goal (either Website or Calls). You can create a separate set of Ads for the other conversion goal thereafter.
                      </Text>
                    </Box>
                  }
                  fontSize="md"
                >
                  <InfoOutlineIcon pointerEvents="all" color={'red.500'} boxSize={4} />
                </Tooltip>
              </Flex>
            ) : null}
          </Flex>

          <Flex direction="column" mb="10px">
            <Flex alignItems={'center'} gap={2}>
              <Text fontSize="15px" fontWeight={'bold'}>Carousel cards</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="14px" p="5px">
                    <Text>
                      Choose media and enter text for up to ten carousel cards.
                    </Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon boxSize={3} pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Text fontSize="13px" flex={1}>
              {mediaFiles.length} of {maxCards} cards added
            </Text>

            {mediaFiles && selectedAdAccounts.some(account => {
              // Count occurrences of the account's adsAccountId in mediaFiles
              const count = mediaFiles.filter(mediaFile =>
                mediaFile.adsAccount?.adsAccountId === account.adsAccountId
              ).length;

              // Return true if there are fewer than 2 cards for this ad account
              return count < 2;
            }) ? (
              <Flex align={'center'} gap={'5px'} px={'10px'} py={'5px'} my={'5px'} borderRadius={'sm'} bg={'gray.100'}>
                <InfoIcon boxSize={3} color={'red.500'} />
                <Text fontSize="13px">
                  {mediaFiles.length === 1 ? "Add at least 2 cards for this ad account." : "Add at least 2 cards for each ad account."}
                </Text>
              </Flex>
            ) : null}
          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0}>
            <Select
              placeholder={"Add cards"}
              width={'150px'}
              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                setuploadPurpose('create');
                handleMediaTypeChange(selectedAdAccounts, e.target.value);
              }}
              size="sm"
              isDisabled={Object.keys(selectedAdSets)?.length === 0 || !selectedAdAccounts?.length > 0 || !hasSameDestinationType}
            >
              <option value="image">{"Add image cards"}</option>
              <option value="video">{"Add video cards"}</option>
            </Select>
          </Flex>

          {/* Media Previews */}
          {uploadPurpose === 'create' && mediaFiles?.length > 0 && (
            <Box mt={4}>
              <Flex wrap="wrap" gap={2} direction="column">
                {mediaFiles?.map((file, index) => {
                  const fileId = file?.id || file?.creativeId;

                  const fileState = mediaFilesState[fileId] || {};

                  // Checking if URL is empty
                  const isUrlRequired = !fileState.link || fileState.link.trim() === '';

                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200" position={'relative'}>
                      <Flex alignItems="center" gap={2} justify="space-between">
                        <Flex alignItems="center" gap={2}>
                          {file?.creativeType === 'image' ? (
                            <img
                              src={file?.url}
                              alt={file?.name}
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          ) : (
                            <img
                              src={file?.picture}
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          )}
                          <Flex direction="column">
                            <Text fontSize="10px" noOfLines={1}>
                              {fileState.name || file?.name || 'No name available'}
                            </Text>
                            <Flex gap={1}>
                              {(activeDestinationType === 'WEBSITE') ? (
                                <>
                                  {fileState.name ? (
                                    <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.name)}</Text>
                                  ) : (
                                    <Text fontSize="10px">Enter a headline</Text>
                                  )}
                                  {!fileState.link ? (
                                    <>
                                      <Text fontSize="10px">and</Text>
                                      <Text fontSize="10px">URL&nbsp;
                                        <Tooltip
                                          borderRadius={5}
                                          label={
                                            <Box p="5px" >
                                              <Text fontSize="12px">
                                                Your carousel card {index + 1} doesn't have a destination. Please enter a URL for each card in your carousel.
                                              </Text>
                                            </Box>
                                          }
                                          fontSize="md"
                                        >
                                          <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                        </Tooltip>
                                      </Text>
                                    </>
                                  ) : null}
                                </>
                              ) : (activeDestinationType === 'PHONE_CALL') ? (
                                <>
                                  {fileState.name ? (
                                    <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.name)}</Text>
                                  ) : (
                                    <Text fontSize="10px">Enter a headline</Text>
                                  )}
                                  {!fileState.link ? (
                                    <>
                                      <Text fontSize="10px">and</Text>
                                      <Text fontSize="10px">Phone number&nbsp;
                                        <Tooltip
                                          borderRadius={5}
                                          label={
                                            <Box p="5px" >
                                              <Text fontSize="12px">
                                                Your carousel card {index + 1} doesn't have a destination. Please enter a phone number for each card in your carousel.
                                              </Text>
                                            </Box>
                                          }
                                          fontSize="md"
                                        >
                                          <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                        </Tooltip>
                                      </Text>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </Flex>
                            <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" gap={'5px'}>
                          <Tooltip label="Edit copy" fontSize="xs">
                            <Box>
                              <Icon
                                _hover={{ color: 'gray.500' }}
                                as={openAccordionId === fileId ? FiChevronUp : MdEdit}
                                cursor="pointer"
                                fontSize="14px"
                                onClick={() => toggleAccordion(fileId)}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip label="Delete" fontSize="xs">
                            <Box>
                              <Icon
                                color="red.500"
                                _hover={{ color: 'gray.400' }}
                                as={MdDelete}
                                cursor="pointer"
                                boxSize={4}
                                onClick={() => handleDeleteFile(index)}
                              />
                            </Box>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Collapse in={openAccordionId === fileId}>
                        <Box p={4}>
                          {/* Headline Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Headline</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five brief headlines to let people know what your ad is about. Each
                                      headline can have a maximum of 255 characters. Headlines won't appear in all
                                      placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Input
                              placeholder="Tell people what your ad is about..."
                              size="sm"
                              borderRadius="md"
                              value={fileState.name || ''}
                              onChange={(e) => handleInputChange(fileId, 'name', e.target.value)}
                            />
                          </Box>

                          {/* Description Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Description</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five link descriptions to emphasize why people should visit your
                                      website. This won't appear in all placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Textarea
                              placeholder="Include additional details"
                              size="sm"
                              borderRadius="md"
                              value={fileState.description || ''}
                              onChange={(e) => handleInputChange(fileId, 'description', e.target.value)}
                              rows={2}
                            />
                          </Box>

                          {/* Conditional Input based on Destination Type */}
                          {activeDestinationType === 'PHONE_CALL' ? (
                            <>
                              <Box direction="column" mb={'10px'}>
                                <Flex alignItems="center" mb={2} gap={2}>
                                  <Text fontWeight="bold" fontSize="14px">Call to action</Text>
                                  <Tooltip
                                    borderRadius={5}
                                    label={
                                      <Box fontSize="sm" p="5px">
                                        <Text>This is the only available option.</Text>
                                      </Box>
                                    }
                                    fontSize="md"
                                  >
                                    <InfoOutlineIcon pointerEvents="all" />
                                  </Tooltip>
                                </Flex>
                                <Select
                                  name="type"
                                  borderRadius="md"
                                  value={singleMediaFormik.values.type}
                                  onChange={singleMediaFormik.handleChange}
                                  onBlur={singleMediaFormik.handleBlur}
                                  size="sm"
                                  isDisabled
                                >
                                  <option value="CALL_NOW">Call Now</option>
                                </Select>
                              </Box>
                              <Box>
                                  <Flex alignItems="center" mt={'10px'} mb={2}>
                                    <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                            <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                        >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>
                                <PhoneInput
                                  country="in"
                                  value={fileState.link || ''}
                                  onChange={(value) => handleInputChange(fileId, 'link', value)}
                                  inputProps={{
                                    name: 'phoneNumber',
                                    required: true,
                                  }}
                                  enableSearch={true}
                                  searchPlaceholder="Search"
                                />
                                {isUrlRequired && (
                                  <Text fontSize="12px" color="red.500" mt={2}>
                                    Your carousel card {index + 1} doesn't have a destination. Please enter a phone number for each card in your carousel.
                                  </Text>
                                )}
                              </Box>
                            </>
                          ) : activeDestinationType === 'WEBSITE' ? (
                            // URL Link Section
                            <Box direction="column" mb={4}>
                              <Flex alignItems="center" mb="5px">
                                <Text fontSize="14px" fontWeight="bold">Website URL</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" ml={2} mr={2}>*</Text>
                                <Tooltip
                                  borderRadius={5}
                                  label={
                                    <Box fontSize="14px" p="5px">
                                      <Text>
                                        Enter the URL for the web page that you want people to visit.
                                      </Text>
                                    </Box>
                                  }
                                  fontSize="md"
                                >
                                  <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                              </Flex>
                              <Input
                                placeholder="http://www.example.com/page"
                                size="sm"
                                borderRadius="md"
                                value={fileState.link || ''}
                                onChange={(e) => handleInputChange(fileId, 'link', e.target.value)}
                                isInvalid={isUrlRequired}
                              />
                              <Flex mt={2}>
                                <Button onClick={() => {
                                  setSingleUrlModalOpen(true)
                                  setSelectedAccountId(fileId);
                                }}
                                  variant="link">
                                  <Text fontSize={'14px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                </Button>
                              </Flex>
                              {isUrlRequired && (
                                <Text fontSize="12px" color="red.500" mt={2}>
                                  Your carousel card {index + 1} doesn't have a destination. Please enter a website URL for each card in your carousel.
                                </Text>
                              )}
                            </Box>
                          ) : null}
                        </Box>
                      </Collapse>
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          )}
        </Flex>

        <Divider />

        {/* Primary Text Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize="14px" mr={2}>Primary text</Text>
            {/* <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text> */}
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Textarea
            size="sm"
            placeholder="Tell people what your ad is about..."

            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius="md"
          />
          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        {/* Call to action Section */}
        {activeDestinationType !== 'PHONE_CALL' && activeDestinationType !== 'ON_AD' ? (
          <Box direction="column">
            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize="14px">Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"
              borderRadius="md"
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size="sm"
            >
              {call_to_action_options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
              <Text color="red.500">{singleMediaFormik.errors.type}</Text>
            ) : null}
          </Box>
        ) : activeDestinationType === 'ON_AD' && objective === 'OUTCOME_LEADS' ? (
          <Box direction="column" mb={'10px'}>
            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize="14px">Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>This is the only available option.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"
              borderRadius="md"
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size="sm"
            >
              {call_to_action_options_instantForms?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>
        ) : (
          // <Box direction="column" mb={'10px'}>
          //   <Flex alignItems="center" mb={2} gap={2}>
          //     <Text fontWeight="bold" fontSize="14px">Call to action</Text>
          //     <Tooltip
          //       borderRadius={5}
          //       label={
          //         <Box fontSize="sm" p="5px">
          //           <Text>This is the only available option.</Text>
          //         </Box>
          //       }
          //       fontSize="md"
          //     >
          //       <InfoOutlineIcon pointerEvents="all" />
          //     </Tooltip>
          //   </Flex>
          //   <Select
          //     name="type"
          //     borderRadius="md"
          //     value={singleMediaFormik.values.type}
          //     onChange={singleMediaFormik.handleChange}
          //     onBlur={singleMediaFormik.handleBlur}
          //     size="sm"
          //     isDisabled
          //   >
          //     <option value="CALL_NOW">Call Now</option>
          //   </Select>
          // </Box>
          null
        )}

        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        {activeDestinationType == 'WEBSITE' ? (
         <>
         <Divider mt={2} />
         {selectedAdAccounts.map((account, index) => {
           const link = links.find(item => item.adsAccountId === account.adsAccountId)?.link || '';
           const isUrlValid = isValidUrl(link);
           const hasFocus = isFocused[account.adsAccountId];
           
           return (
             <Flex key={`${account.adsAccountId}-${index}`} direction="column">
               <Flex alignItems="center" display="column">
                 <Text fontWeight="bold" fontSize="14px" mt="10px">
                   {account.adsAccountName}
                 </Text>
                 <Text fontSize="12px">{account.adsAccountId}</Text>
               </Flex>
               <Flex alignItems="center" mb={'6px'}>
                 <Text fontWeight="bold" fontSize="14px">
                   Website URL
                 </Text>
                 <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                 <Tooltip
                   borderRadius={5}
                   label={
                     <Box fontSize="sm" p="5px">
                       <Text>Enter the URL for the webpage you want people to visit</Text>
                     </Box>
                   }
                   fontSize="md"
                 >
                   <InfoOutlineIcon pointerEvents="all" />
                 </Tooltip>
               </Flex>
               <Input
                 type="url"
                 name={`link[${index}].link`}
                 bg="#fff"
                 borderRadius="md"
                 placeholder="Enter URL"
                 value={link}
                 onChange={(e) => handleLinkChange(account.adsAccountId, e.target.value)}
                 onFocus={() => handleFocus(account.adsAccountId)} 
                 size="sm"
               />

                <Flex mt={2}>
                  <Button onClick={() => {
                    setUrlModalOpen(true)
                    setSelectedAccountId(account?.adsAccountId);
                  }} 
                  variant="link">
                      <Text fontSize={'14px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                  </Button>
                </Flex>
               {/* Show validation error if URL is empty or invalid */}
               {hasFocus && link === '' && (
                 <Text color="red.500" fontSize="12px" mt={'5px'}>
                   {'URL is required'}
                 </Text>
               )}
               {hasFocus && !isUrlValid && link !== '' && (
                 <Text color="red.500" fontSize="12px" mt={'5px'}>
                   {'Please enter valid URL'}
                 </Text>
               )}
             </Flex>
           );
         })}
         </>
       ) : null}

              {/* {activeDestinationType === 'PHONE_CALL' ? (
            <Box>       
              {selectedAdAccounts.map((account, index) => {
                const phoneNumber = phoneNumbers.find(item => item.adsAccountId === account.adsAccountId)?.phoneNumber || '';
                const isValidNo = isValidPhoneNumber(phoneNumber);
                const hasFocus = isFocused[account.adsAccountId];

                return (
                  <Flex key={`${account.adsAccountId}-${index}`} direction="column">
                    <Flex alignItems="center" display="column">
                      <Text fontWeight="bold" fontSize="14px" mt="10px">
                        {account.adsAccountName}
                      </Text>
                      <Text fontSize="12px">{account.adsAccountId}</Text>
                    </Flex>
                    <Flex alignItems="center" mb={2} gap={2}>
                      <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      onChange={(value) => handlePhoneNumberChange(account.adsAccountId, value)}
                      name={`phoneNumber[${index}].phoneNumber`}
                      inputProps={{
                        name: `phoneNumber[${index}].phoneNumber`,
                        required: true,
                      }}
                      enableSearch={true}
                      searchPlaceholder="Search"
                      onFocus={() => handleFocus(account.adsAccountId)}
                    />
                    {hasFocus && phoneNumber === '' && (
                      <Text color="red.500" fontSize="12px" mt={'5px'}>
                        {'Phone number is required'}
                      </Text>
                    )}
                    {hasFocus && !isValidNo && phoneNumber !== '' && (
                      <Text color="red.500" fontSize="12px" mt={'5px'}>
                        {'Please enter valid phone number'}
                      </Text>
                    )}
                  </Flex>
                );
              })}
            </Box>
        ) : null} */}

      {activeDestinationType === 'ON_AD' && objective === 'OUTCOME_LEADS' ? (
          <>
          <Divider />
            <Box>
              {selectedAdAccounts.map((account, index) => {

                const matchedAccount = adData?.adsAccounts.find(
                  (adAccount) => adAccount?.adsAccountId === account?.adsAccountId
                );

                if (!matchedAccount) return null;
                // Filter instantForms based on the matchedAccount's page_id
                if (isInstantFormsLoading) {
                  return <Text>Loading...</Text>;
                }

                if (instantFormsError || !instantForms) {
                  return <Text color="red.500" fontSize={'12px'}>{instantFormsError ? instantFormsError?.message : 'Failed to load instant forms.'}</Text>;
                }

                const filteredInstantForms = instantForms
                  .map((formResponse) => formResponse.data.data) // Get the 'data' array from each formResponse
                  .flat() // Flatten the array to get all forms in one array
                  .filter((form) => form?.page_id === matchedAccount?.page_id);

                return (
                  <Flex key={`${account.adsAccountId}-${index}`} direction="column" mb={2}>
                    {/* Account Details */}
                    <Flex direction='column' mb={2}>
                      <Text fontWeight="bold" fontSize="14px">{account.adsAccountName}</Text>
                      <Text fontSize="12px">{account.adsAccountId}</Text>
                    </Flex>

                    <Flex alignItems="center" mb={2} gap={2}>
                      <Text fontWeight="bold">Instant form</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>

                    {/* Table Layout */}
                    {filteredInstantForms.length > 0 ? (
                      <RadioGroup onChange={(value) => handleLinkChange(account?.adsAccountId, value)} colorScheme="orange">
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                              <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {filteredInstantForms.map((form) => (
                              <Tr key={form?.id} _hover={{ backgroundColor: '#F9F9F9' }}>
                                <Td>
                                  <Flex alignItems="center" gap={2}>
                                    <Radio value={form?.id} />
                                    <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                      {form?.name}
                                    </Text>
                                  </Flex>
                                </Td>
                                <Td>
                                  <Text fontSize={'12px'}>
                                    {new Date(form?.created_time).getFullYear()}-
                                    {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                    {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                  </Text>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </RadioGroup>
                    ) : (
                      <Text fontSize="14px" color="gray.500">No form data available</Text>
                    )}
                  </Flex>
                );
              })}
            </Box>
          </>
        ) : null}

        <Divider mt={2} />
        {selectedAdAccounts.map((account, index) => {
          const urlTag = url_tags.find(item => item.adsAccountId === account.adsAccountId)?.url_tags || '';

          return (
            <Flex key={`${account.adsAccountId}-${index}`} direction="column">
              <Flex alignItems="center" display="column">
                <Text fontWeight="bold" fontSize="14px" mt="10px">
                  {account.adsAccountName}
                </Text>
                <Text fontSize="12px">{account.adsAccountId}</Text>
              </Flex>
              <Flex alignItems={'center'}>
                <Text mb={2} fontWeight="bold">
                  URL parameters{" "}
                  {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                  <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                </Text>
                <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="sm" p={'5px'}>
                      <Text >
                        Add parameters to the end of your website URL to track where your visitors are coming from.
                      </Text>
                    </Box>
                  }
                  fontSize="md"
                >
                  <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                </Tooltip>
              </Flex>
              <Input
                type="url"
                name={`urlTag[${index}].urlTag`}
                bg="#fff"
                borderRadius="md"
                placeholder="key1=value1&key2=value2"
                value={urlTag}
                onChange={(e) => handleUrlTagChange(account.adsAccountId, e.target.value)}
                onFocus={() => handleFocus(account.adsAccountId)}
                size="sm"
              />
              <Flex mt={2}>
                <Button onClick={() => {
                  setUrlParamModalOpen(true)
                  setSelectedAccountId(account?.adsAccountId);
                }}
                  variant="link">
                  <Text fontSize={'14px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                </Button>
              </Flex>
            </Flex>
          );
        })}

        {/* Save Button */}
        <Flex justifyContent="flex-start" mt="10px">
          <Button
            colorScheme="orange"
            onClick={() => {
              singleMediaFormik.handleSubmit();
            }}
            isLoading={isAdsCreating || isAdspressoAdsetUpdating}
            size="sm"
            isDisabled={ 
              mediaFiles?.length < 2 > 0 || 
              ((activeDestinationType === 'WEBSITE' || activeDestinationType === 'PHONE_CALL')  && isAnyUrlMissing) || 
              !hasSameDestinationType || 
              ((activeDestinationType === 'WEBSITE' || activeDestinationType === 'ON_AD')  && !allLinksAdded) ||
              // (activeDestinationType === 'PHONE_CALL' && !allPhoneNumbersAdded) ||
              isAccountsMatching
            }
          >
            Save
          </Button>
        </Flex>

        {/* {uploadedCreativesList?.length > 0 && (
          <>
            <Divider my="10px" />
            <Flex direction="column" gap={2}>
              {uploadedCreativesList}
            </Flex>
          </>
        )} */}
      </Flex>
    </>
  );
};

export default CarouselMediaCreative;

