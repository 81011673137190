import { Box, Switch, Text, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useGetCustomAudienceMutation } from 'store/campaigns/adsetFormApi';

function BotNullifier({ adsAccountId, promoted_object, onCustomAudienceChange, botNullifyData, selectedAdAccounts, onDetailsRefetch }) {

  const toast = useToast();
  const [isChecked, setIsChecked] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorUrl, setErrorUrl] = useState(null); 

  const [getCustomAudience, { data: customAudience, isLoading, error }] = useGetCustomAudienceMutation();

  useEffect(() => {
    if (error) {
      // Check if the error message contains a URL
      const errorMessage = error?.data?.message || '';
      const urlMatch = errorMessage.match(/https?:\/\/[^\s]+/); 
      
      if (urlMatch) {
       // If a URL is found, store it in the state
       setErrorUrl(urlMatch[0]);
       setIsModalOpen(true);
       setIsChecked(false);
      } else {
        toast({
          position: "top-right",
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  }, [error]);

  const handleToggle = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    setIsChecked(false);

    if (errorUrl) {
      // If errorUrl exists, open it in a new tab
      window.open(errorUrl, '_blank');
    }
  };

  useEffect(() => {
    if (botNullifyData && botNullifyData.adsAccountId === adsAccountId) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [botNullifyData, adsAccountId]);

  useEffect(() => {
    // Extract the pixel_id based on adsAccountId
    const matchedPixel = promoted_object?.find(item => item?.adsAccountId === adsAccountId);

    if (matchedPixel) {
      // If matchedPixel exists, enable the switch and proceed with the logic
      setIsSwitchDisabled(false);

      const pixelId = matchedPixel?.pixel_id;

      if (isChecked) {
        getCustomAudience({
          adsAccountId,
          payload: {
            name: "Bot traffic",
            rule: {
              inclusions: {
                operator: "or",
                rules: [
                  {
                    event_sources: [
                      {
                        type: "pixel",
                        id: pixelId
                      }
                    ],
                    retention_seconds: 15552000,
                    filter: {
                      operator: "and",
                      filters: [
                        {
                          "field": "url",
                          "operator": "i_contains",
                          "value": ""
                        }
                      ]
                    },
                    template: "TOP_TIME_SPENDERS",
                    aggregation: {
                      type: "time_spent",
                      method: "percentile",
                      operator: "in_range",
                      value: {
                        from: 95,
                        to: 100
                      }
                    }
                  }
                ]
              }
            }
          }
        })
      }
    }
  }, [isChecked, adsAccountId, promoted_object]);

  useEffect(() => {
    if (customAudience) {
      onCustomAudienceChange(customAudience?.id);
    }
  }, [customAudience]);

  return (
    <Box display="flex" alignItems="center">
      <Switch 
        isChecked={isChecked} 
        onChange={handleToggle} 
        colorScheme='orange' 
        size={'sm'} 
        isDisabled={isSwitchDisabled} 
      />
      <Text ml={2} fontWeight={'bold'} fontSize={'14px'}>Bot Nullifier</Text>

      {/* Modal for when TOS is not accepted */}
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You need to accept the terms of service for custom audiences before proceeding. Would you like to do that now?
          </ModalBody>
          <ModalFooter>
            <Button size={'sm'} mr={3} onClick={handleModalClose}>
              Cancel
            </Button>
            <Button colorScheme="orange" size={'sm'} onClick={handleConfirm}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default BotNullifier;
