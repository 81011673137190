import React from 'react';
import { useGetAdsQuery } from 'store/campaigns/adsApi';
import { useGetAdsetsQuery } from 'store/campaigns/adsetFormApi';
import { Flex, Box, Text, Spinner, Image, SkeletonText } from '@chakra-ui/react';
import { truncateString } from 'utils/functions';

function AdUpdateSelection({
  adAccounts,
  selectedAdIds,
  selectedAdsetIds
}) {

  const shouldSkipAds = !adAccounts?.business?.id || !Array.isArray(selectedAdIds) || selectedAdIds?.length === 0;
  const shouldSkipAdsets = !adAccounts?.account_id || !Array.isArray(selectedAdsetIds) || selectedAdsetIds?.length === 0;

  const { data: adsList, isLoading: isAdsLoading } = useGetAdsQuery(
    {
      businessId: adAccounts?.business?.id,
      adsAccountId: adAccounts?.account_id,
      adsIds: selectedAdIds || [],
    },
    { skip: shouldSkipAds }
  );

  const { data: adsetList, isLoading: isAdsetLoading } = useGetAdsetsQuery(
    {
      adsAccountId: adAccounts?.account_id,
      adsetIds: selectedAdsetIds || [],
    },
    { skip: shouldSkipAdsets }
  );

  // Group ads by campaign and adset
  const renderAds = (ads) => {

    if (!ads || !Array.isArray(ads)) return null;
    const groupedAds = ads.reduce((acc, ad) => {
      const campaignId = ad?.campaign?.id;
      const adsetId = ad?.adset?.id;
      const key = `${campaignId}_${adsetId}`;
  
      if (!acc[key]) {
        acc[key] = {
          campaign: ad?.campaign,
          adset: ad?.adset,
          ads: []
        };
      }
      acc[key].ads.push(ad);
      return acc;
    }, {});

    return Object.values(groupedAds)?.map((group) => (
      <Flex direction={'column'} key={`${group.campaign?.id}_${group.adset?.id}`} gap={1} border={"1px solid #ccc"}  borderRadius="4px" p={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Campaign: {truncateString(group.campaign?.name || '', 20)}</Text>
            <Text fontSize={'14px'}>Adset: {truncateString(group?.adset?.name || '', 20)}</Text>
        {group.ads?.map((ad) => (
          <Flex key={ad?.id} align="center" gap={2}>
            <Image src={ad?.imageUrl || ''} alt={''} boxSize="45px" objectFit="fill" border={"1px #ccc" } />
            <Text fontSize={'12px'}>{truncateString(ad?.name || '', 20)}</Text>
          </Flex>
        ))}
      </Flex>
    ));
  };

  const renderAdsets = (adsets) => {
  
    return adsets?.map((adset) => {
  
      return (
        <Flex direction={'column'} key={adset?.id} p={2} gap={1} border="1px solid #ccc" borderRadius="4px">
          <Text fontWeight="bold" fontSize={'14px'}>Campaign: {truncateString(adset?.campaign?.name || '', 20)}</Text>
          <Text fontSize={'14px'}>Adset: {truncateString(adset?.name || '', 20)}</Text>


        {/* Render Ads for each Adset */}
        {adset?.ads?.data?.map((ad) => (
          <Flex key={ad?.id} align="center" gap={2}>
            <Image 
              src={ad?.creative?.thumbnail_url || ''} 
              alt={ad?.name || 'Ad Image'} 
              boxSize="45px" 
              objectFit="fill" 
              border={"1px #ccc" } 
            />
            <Text fontSize={'12px'}>
              {truncateString(ad?.name || '', 20)}
            </Text>
            </Flex>
        ))}
        </Flex>
      );
    }) || null;
  };


  return (
    <Box width={'100%'}>
      <Flex direction="column" gap={2}>
        {selectedAdsetIds?.length === 0 && selectedAdIds?.length === 0 && (
          <Text>Applied to all active</Text>
        )}
        {/* Render Ads */}
        {isAdsLoading ? (
          <Box>
            <Flex justify="center" alignItems={'center'} h={'10vh'}>
              <SkeletonText width="80%" noOfLines={3}  skeletonHeight={2}/>
            </Flex>
          </Box>
        ) : (
          selectedAdIds?.length > 0 && adsList && renderAds(adsList)
        )}

        {/* Render Adsets */}
        {isAdsetLoading ? (
          <Box>
            <Flex justify="center"  alignItems={'center'} h={'10vh'}>
              <SkeletonText width="80%" noOfLines={3}  skeletonHeight={2}/>
            </Flex>
          </Box>
        ) : (
          selectedAdsetIds?.length > 0 && adsetList?.data && renderAdsets(adsetList?.data)
        )}
      </Flex>
    </Box>
  );
}

export default AdUpdateSelection;