import { useState, useEffect } from 'react';

const usePolling = () => {
    const [isPollingActive, setIsPollingActive] = useState(false); 
    const [isTabVisible, setIsTabVisible] = useState(true); 
    const [lastActivityTime, setLastActivityTime] = useState(Date.now()); // To track the last user activity time
    const [idleTimeout, setIdleTimeout] = useState(null); 
    const [activityTimeout, setActivityTimeout] = useState(null); 

    const handleUserActivity = () => {
        // Clear any existing idle or activity timeouts
        if (idleTimeout) {
            clearTimeout(idleTimeout);
        }
        if (activityTimeout) {
            clearTimeout(activityTimeout);
        }

        // Reset activity timeout to start polling after 500 ms of activity
        const newActivityTimeout = setTimeout(() => {
            setIsPollingActive(true); // Start polling after a short delay of user activity
        }, 500);
        
        setActivityTimeout(newActivityTimeout);

        const newIdleTimeout = setTimeout(() => {
            setIsPollingActive(false); // Disable polling if 10 seconds of inactivity
        }, 10000); 
        
        setIdleTimeout(newIdleTimeout);
        setLastActivityTime(Date.now());
    };

    const handleVisibilityChange = () => {
        if (document.hidden) {
            setIsTabVisible(false); // Stop polling if the tab is hidden
        } else {
            setIsTabVisible(true); // Resume polling if the tab is visible
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);

        // Listen for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup event listeners
        return () => {
            window.removeEventListener('click', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (idleTimeout) {
                clearTimeout(idleTimeout); // Clean up the idle timeout
            }
            if (activityTimeout) {
                clearTimeout(activityTimeout); // Clean up the activity timeout
            }
        };
    }, [idleTimeout, activityTimeout]); // Re-run effect if idleTimeout or activityTimeout changes

    return { isPollingActive, isTabVisible };
};

export default usePolling;
