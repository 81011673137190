import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    useToast,
    Select,
    Textarea,
    Tooltip,
    Divider,
    Switch,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Checkbox,
    RadioGroup,
    Radio,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdatePublishAdMutation } from 'store/publishTracking/trackingApi';
import DriveModal from 'components/drive/DriveModal';
import PhoneInput from 'react-phone-input-2'; 
import FaceBookPageAdspressoSelector from '../FaceBookPageAdspressoSelector';

import BuildUrlParamsUpdate from '../BuildUrlParamsUpdate';
import BuildUrlParamCarousel from '../BuildUrlParamCarousel';

import InstagramAccountSelector from '../InstagramAccountSelector';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import PublishAdPreviewCarousel from './PublishAdPreviewCarosuel';
import UrlParametersUpdate from '../UrlParametersUpdate';
import { useGetInstantFormsByPageIdQuery } from 'store/campaigns/adsApi';

const CarouselCreativeAdupdate = ({ targeting, trackingData, onClose, onSaveSuccess, call_to_action_options, call_to_action_options_instantForms }) => {

    const { adsAccount, form, _id, adset } = trackingData;

    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [isEnabled, setIsEnabled] = useState(true);
    const [isUrlModalOpen, setUrlModalOpen] = useState(false);
    const [isSingleUrlModalOpen, setSingleUrlModalOpen] = useState(false);
    const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);

    const [selectedCreativeIndex, setSelectedCreativeIndex] = useState(null);

    const [updatePublishAd] = useUpdatePublishAdMutation();

    const pageId = adset?.promoted_object?.page_id;

    const formattedPageIds = Array.isArray(pageId) ? pageId : [pageId];

    const { data: instantForms } = useGetInstantFormsByPageIdQuery(
        { pageIds: formattedPageIds },
        { skip: !formattedPageIds.length || adset?.destination_type !== 'ON_AD' }
    );

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        identity: Yup.object().shape({
            page_id: Yup.string().required('Facebook Page is required'),
        }),
        type: Yup.string().required('Type is required'),
        link: Yup.string()
        .when('type', {
            is: (value) => value !== 'CALL_NOW',
            then: () => Yup.string()
                .required('URL is required'),
            otherwise: () => Yup.string().notRequired(),
        }),
        // phoneNumber: Yup.string().
        //     when('type', {
        //         is: (value) => value === 'CALL_NOW',
        //         then: () => Yup.string()
        //             .transform((value) => value.slice(2)) // Remove the first two digits (e.g., country code like "91")
        //             .matches(/^[0-9]{10,15}$/, 'Phone number must be at least 10 digits') // Ensure remaining digits are at least 10 digits
        //             .required('Phone number is required'), // Ensure phone number is provided
        //         otherwise: () => Yup.string().notRequired(),
        // }),
        adcreative: Yup.array().of(
            Yup.object().shape({
                //message: Yup.string().required('Message is required'),
                name: Yup.string(),
                description: Yup.string(),
                //type: Yup.string().required('Type is required'),
                link: Yup.string().required('URL is required'),

            })
        )
    });

    const formik = useFormik({
        initialValues: {
            name: form?.name || "",
            message:form?.message || "",
            cpa:form?.cpa || "",
            description:form?.description || "",
            type:form?.type || "",
            link:form?.link || "",
            phoneNumber: form?.link?.includes('tel:+') ? form?.link.replace('tel:+', '') : '',
            url_tags: form?.url_tags || '',
            identity: {
                page_id: form.identity?.page_id || "",
                instagram_actor_id: form?.identity?.instagram_actor_id || "",
            },
            destination_type: adset?.destination_type,
            enroll_status: form?.contextual_multi_ads?.enroll_status ? form?.contextual_multi_ads?.enroll_status : 'OPT_OUT',
            adcreative: form.adcreative.map(creative => ({
                _id: creative._id || "",
                message: creative.message || "",
                name: creative.name || "",
                description: creative.description || "",
                type: creative.type ? creative.type : "CALL_NOW",
                link: creative.link || "",
                hash: creative?.hash || "",
                image_hash: creative.image_hash || "",
                url: creative.url || "",
                video_id: creative?.video_id || "",
                thumbnail: creative?.thumbnail || "",
            })),
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {

            const formatPhoneNumber = (phoneNumber) => {
        
                if (!phoneNumber.startsWith('tel:+')) {
                    return `tel:+${phoneNumber}`;
                }
                return phoneNumber;
            };

            const payload = {
                name: values?.name,
                message:values?.message,
                cpa:values?.cpa,
                description:values?.description,
                type:values?.type,
                link: values?.type === "CALL_NOW" 
                    ? `tel:+${values?.adcreative[values?.adcreative.length - 1]?.link}` 
                    : values?.link,
                ...(values?.url_tags?.trim() && { url_tags: values.url_tags }),
                identity: {
                    page_id: values.identity.page_id,
                    ...(values?.identity?.instagram_actor_id.trim() && {
                        instagram_actor_id: values?.identity?.instagram_actor_id,
                    }),
                },
                adcreative: values.adcreative.map((creative, index) => {
                    return {
                        link: creative?.type === 'CALL_NOW' 
                            ? formatPhoneNumber(creative?.link) 
                            : creative?.link,
                        url: creative?.url,
                        name: creative?.name,
                        message: creative?.message,
                        type: values?.type,
                        description: creative?.description,
                        ...(creative?.video_id && {
                            video_id: creative?.video_id,
                            thumbnail: creative?.thumbnail,
                            hash: creative?.hash,
                            
                        }),
                        ...(creative?.image_hash && {
                            image_hash: creative?.image_hash,
                            url: creative?.url,
                            hash: creative?.hash,
                        }),
                    };
                }),                
            };

            updatePublishAd({ trackingId: _id, payload })
                .unwrap()
                .then(response => {
                    onSaveSuccess();
                    toast({
                        position: "top-right",
                        title: "Ads updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ads",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = (mediaType, index) => {
        setSelectedMedia(mediaType);
        setSelectedCreativeIndex(index); // Set the index of the creative being edited
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file?.length > 0 && selectedCreativeIndex !== null) {
            const selectedFile = file[0];
            formik.setFieldValue('adcreative', formik.values?.adcreative?.map((creative, index) => {
                if (index === selectedCreativeIndex) {
                    return {
                        ...creative,
                        ...(selectedMedia === 'image' && {
                            image_hash: selectedFile?.creativeId,
                            hash:selectedFile?.hash,
                            url: selectedFile?.url,
                        }),
                        ...(selectedMedia === 'video' && {
                            video_id: selectedFile?.creativeId,
                            hash:selectedFile?.hash,
                            thumbnail: selectedFile?.picture,
                        }),
                    };
                }
                return creative;
            }));
        }
        setIsDriveModalOpen(false);
        setSelectedCreativeIndex(null);
    };

    const handleUrlChange = (constructedUrl) => {
        formik.setFieldValue('link', constructedUrl); 
    };

    const handleSingleUrlChange = (constructedUrl, fileIndex) => {

        // Make a copy of the current child_attachments array
        const updatedChildAttachments = [...formik.values.adcreative];
      
        // Update the 'link' of the specific fileIndex
        updatedChildAttachments[fileIndex] = {
          ...updatedChildAttachments[fileIndex],
          link: constructedUrl,
        };
      
        // Now use Formik's setFieldValue to update the form field
        formik.setFieldValue(
          "adcreative",
          updatedChildAttachments
        );
    };   

    const handleUrlParamChange = (constructedUrl) => {
        formik.setFieldValue('url_tags', constructedUrl); 
    };

    const filteredInstantForms = instantForms?.length > 0 && instantForms
        .map((formResponse) => formResponse?.data?.data) // Get the 'data' array from each formResponse
        .flat() // Flatten the array to get all forms in one array
        .filter((form) => form?.page_id === pageId);

    if (!form) {
        return <Spinner mt={'20px'} />;
    }
    
    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex gap={4}>
                <Flex direction={'column'} flex={0.4}>
                    <Flex direction={'column'} gap={4} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Ad Name</Text>
                            <Input
                                name="name"
                                placeholder="Enter ad name"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Facebook Page</Text>
                            <FaceBookPageAdspressoSelector
                                adsAccount={adsAccount}
                                selectedPageId={formik.values.identity.page_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('identity.page_id', pageId);

                                    // Reset the Instagram actor ID to an empty string
                                    formik.setFieldValue('identity.instagram_actor_id', '');
                                }}
                            />
                            {formik.errors.identity?.page_id && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.identity?.page_id}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Instagram accounts</Text>
                            <InstagramAccountSelector
                                adsAccount={adsAccount}
                                selectedInstPageId={formik?.values?.identity?.instagram_actor_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('identity.instagram_actor_id', pageId);
                                }}
                                pageID={formik?.values?.identity?.page_id}
                            />
                        </Flex>
                        <Divider />

                        {/* Multi-advertiser ads Section */}
                        <Flex direction="column" borderRadius="md" gap={2}>
                            <Checkbox
                                onChange={(e) => {
                                    formik.setFieldValue("enroll_status", e.target.checked ? 'OPT_IN' : 'OPT_OUT');
                                }}
                                colorScheme="orange"
                                isChecked={formik.values.enroll_status === 'OPT_IN'}
                            >
                                <Flex align={'center'}>
                                    <Text fontWeight="bold" fontSize={'14px'}>Multi-advertiser ads &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Your ads can appear alongside other ads in the same ad unit to help people discover products and services from businesses that are personalized to them. Your ad creative may be resized or cropped to fit the ad unit.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>
                            </Checkbox>
                        </Flex>
                    </Flex>

                    <Flex direction={'column'} gap={4} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        {formik.values.adcreative.map((creative, index) => (
                            <Flex direction={'column'} gap={'20px'} key={index} borderWidth={1} borderRadius="md" p={4}>
                                <Flex direction="column" gap={1}>
                                    <Text fontWeight="bold">Headline</Text>
                                    <Input
                                        name={`adcreative.${index}.name`}
                                        placeholder="Enter name"
                                        size='sm'
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.adcreative[index].name}
                                    />
                                    {formik.touched.adcreative?.[index]?.name && formik.errors.adcreative?.[index]?.name && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].name}</Text>
                                    )}
                                </Flex>

                                <Flex direction="column" gap={1}>
                                    <Text fontWeight="bold">Description</Text>
                                    <Textarea
                                        name={`adcreative.${index}.description`}
                                        placeholder="Enter description"
                                        size='sm'
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.adcreative[index].description}
                                    />
                                    {formik.touched.adcreative?.[index]?.description && formik.errors.adcreative?.[index]?.description && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].description}</Text>
                                    )}
                                </Flex>
                                {formik.values.adcreative?.[index]?.type === 'CALL_NOW' ? (
                                    <Flex direction="column" gap={1}>
                                        <Text fontWeight="bold">Call to action</Text>
                                                <Select
                                                    name={`adcreative.${index}.type`}
                                                    size="sm"
                                                    borderRadius="md"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.adcreative[index].type}
                                                    isDisabled
                                                    >
                                                    <option value="CALL_NOW">Call Now</option>
                                                </Select>
                                            {formik.touched.adcreative?.[index]?.type && formik.errors.adcreative?.[index]?.type && (
                                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].type}</Text>
                                            )}
                                    </Flex>
                                ):null}
                                {formik.values.adcreative?.[index]?.type === 'CALL_NOW' ? (
                                    <Flex direction="column" gap={1}>
                                    <Text fontWeight="bold">Phone Number</Text>
                                    <PhoneInput
                                        name={`adcreative.${index}.link`}
                                        value={formik.values.adcreative[index].link}
                                        onChange={(value) => formik.setFieldValue(`adcreative.${index}.link`, value)}
                                        country={'in'} 
                                    />
                                    {formik.touched.adcreative?.[index]?.link && formik.errors.adcreative?.[index]?.link && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].link}</Text>
                                    )}
                                </Flex>
                                ):(null)}

                                {adset?.destination_type === 'WEBSITE' ? (
                                <Flex direction="column" gap={1}>
                                    <Text fontWeight="bold">Website URL</Text>
                                    <Input
                                        name={`adcreative.${index}.link`}
                                        placeholder="Enter link URL"
                                        size='sm'
                                        borderRadius="md"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.adcreative[index].link}
                                        onChange={formik.handleChange}
                                    />
                                     <Flex mt={2}>
                                    <Button onClick={() => {
                                        setSingleUrlModalOpen(true)
                                        setSelectedFileIndex(index)
                                    }} 
                                    variant="link">
                                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                    </Button>
                                </Flex>
                                    {formik.touched.adcreative?.[index]?.link && formik.errors.adcreative?.[index]?.link && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].link}</Text>
                                    )}
                                </Flex>
                                ):(null)}

                                <Text fontWeight="bold">Ad creative</Text>
                                <Flex direction="row" gap={2} alignItems={'center'}>
                                    <img
                                        src={creative.image_hash ? creative.url : creative.thumbnail}
                                        alt="Thumbnail"
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                                    />
                                    <Button
                                        bg={'gray.50'}
                                        size="xs"
                                        borderWidth={1}
                                        onClick={() => handleOpenDriveModal(creative.image_hash ? 'image' : 'video', index)}>
                                        {creative.image_hash ? "Change Image" : "Change Video"}
                                    </Button>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Primary Text</Text>
                                <Textarea
                                    name="message"
                                    placeholder="Enter a text"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}
                                />
                                {formik.touched.message && formik.errors.message && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.message}</Text>
                                )}
                            </Flex>

                            {adset?.destination_type !== 'PHONE_CALL' ? (
                                <Flex direction="column" gap={1}>
                                    <Text fontWeight="bold">Call to action</Text>
                                        {adset?.destination_type === 'WEBSITE' ?  (
                                            <Select
                                                name={'type'}
                                                size="sm"
                                                borderRadius="md"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.type}
                                            >
                                                {call_to_action_options?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Select>
                                        ) : (
                                            <Select
                                            name={'type'}
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.type}
                                        >
                                            {call_to_action_options_instantForms?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                    {formik.touched.type && formik.errors.type && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.type}</Text>
                                    )}  
                                </Flex>
                            ):null}

                            {/* {formik.values.type === 'CALL_NOW' && (
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Phone Number</Text>
                                <PhoneInput
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                                    country={'in'}
                                />                        
                                {formik.errors?.phoneNumber && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors?.phoneNumber}</Text>
                                )}
                            </Flex>
                            )} */}

                            {adset?.destination_type === 'WEBSITE' ? (
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Website URL</Text>
                                <Input
                                    name="link"
                                    placeholder="Enter a URL"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                />
                                <Flex mt={2}>
                                    <Button onClick={() => {
                                        setUrlModalOpen(true)
                                    }} 
                                    variant="link">
                                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                    </Button>
                                </Flex>
                                {formik.touched.link && formik.errors.link && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.link}</Text>
                                )}
                                </Flex>
                            ):(null)}

                            {adset?.destination_type === 'ON_AD' ? (
                                    <>
                                        <Flex alignItems="center" gap={2} mt={2}>
                                            <Text fontWeight="bold">Instant form</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p="5px">
                                                        <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" />
                                            </Tooltip>
                                        </Flex>
        
                                        {/* Table Layout */}
                                        {filteredInstantForms?.length > 0 ? (
                                            <RadioGroup onChange={(value) => formik.setFieldValue('link', value)} value={formik.values.link} colorScheme="orange">
                                                <Table variant="simple">
                                                    <Thead>
                                                        <Tr>
                                                            <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                                                            <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {filteredInstantForms?.map((form) => (
                                                            <Tr key={form?.id} _hover={{ backgroundColor: '#F9F9F9' }} >
                                                                <Td>
                                                                    <Flex alignItems="center" gap={2}>
                                                                        <Radio value={form?.id} />
                                                                        <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                                                            {form?.name}
                                                                        </Text>
                                                                    </Flex>
                                                                </Td>
                                                                <Td>
                                                                    <Text fontSize={'12px'}>
                                                                        {new Date(form?.created_time).getFullYear()}-
                                                                        {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                                                        {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                                                    </Text>
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </RadioGroup>
                                        ) : (
                                            <Text fontSize="14px" color="gray.500">No form data available</Text>
                                        )}
                                    </>
                                ) : null}
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Text fontWeight={'bold'}>Tracking</Text>
                        <Flex direction="column" gap={1}>
                            <Flex alignItems={'center'}>
                                <Text mb={2} fontWeight="bold">
                                        URL parameters{" "}
                                    {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                                    <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                                </Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text >
                                                Add parameters to the end of your website URL to track where your visitors are coming from.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                </Tooltip>
                            </Flex>
                            <Input
                                name="url_tags"
                                placeholder="key1=value1&key2=value2"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.url_tags}
                                onChange={formik.handleChange}
                            />
                            <Flex mt={2}>
                                <Button onClick={() => {
                                    setUrlParamModalOpen(true)
                                }} 
                                variant="link">
                                    <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                </Button>
                            </Flex>
                            {formik.touched?.url_tags && formik.errors?.url_tags && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors?.url_tags}</Text>
                            )}
                        </Flex>
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">CPA</Text>
                        <Input
                            name="cpa"
                            placeholder="Enter CPA"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cpa}
                        />
                    </Flex>
                    </Flex>

                    <Flex mb={2}>
                        <Button
                            size="sm"
                            colorScheme="orange"
                            isLoading={formik.isSubmitting}
                            type="submit"
                            isDisabled={!formik.isValid}
                        >
                            Save
                        </Button>
                    </Flex>
                </Flex>

                <Flex flex={0.6} px={4} direction={'column'} justifyContent={'space-between'} borderRadius={'md'} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)">
                    <Flex py={2} gap={2} direction={'column'}>
                        <Flex py={2} gap={2}>
                            <Switch isChecked={isEnabled} onChange={() => setIsEnabled(prevState => !prevState) } />
                            <Text fontWeight={'bold'} fontSize={'14px'}>Ad preview  </Text>  
                        </Flex>
                        <Divider />
                        {isEnabled && 
                            <PublishAdPreviewCarousel placements={targeting} file={formik.values} adsAccountData={adsAccount} />
                        }
                    </Flex>
                    <Flex my={2} alignItems={'center'}>
                        <Text fontSize={'12px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="12px" p="5px">
                                    <Text>
                                        Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                    </Text>
                                </Box>
                            }
                            fontSize="md"
                            >
                            <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                        </Tooltip>
                    </Flex>
                </Flex> 
            </Flex>

            <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BuildUrlParamsUpdate link={formik.values} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange}/>
                </ModalBody>
                </ModalContent>
            </Modal> 

            <Modal isOpen={isSingleUrlModalOpen} onClose={() => setSingleUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BuildUrlParamCarousel link={formik.values.adcreative} onClose={() => setSingleUrlModalOpen(false)} onUrlChange={handleSingleUrlChange} selectedFileIndex={selectedFileIndex}/>
                </ModalBody>
                </ModalContent>
            </Modal>  

            <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <UrlParametersUpdate link={formik.values} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange}/>
                </ModalBody>
                </ModalContent>
            </Modal> 

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccount._id,
                    adsAccountId: adsAccount.adsAccountId,
                    adsAccountName: adsAccount.adsAccountName,
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={selectedMedia}
            />
        </form>
    );
};

export default CarouselCreativeAdupdate;