import React, { useState, useEffect, useRef } from 'react';
import { Box, Select, Radio, RadioGroup, Stack, FormControl, FormLabel, Flex } from '@chakra-ui/react';

const AgeGenderSelector = ({ preFilledData, onChange }) => {
    const { genders: genders_ } = preFilledData || {};
    const genderMap = ['all', 'men', 'women'];

    const [minAge, setMinAge] = useState(preFilledData?.age_min || 18);
    const [maxAge, setMaxAge] = useState(preFilledData?.age_max || 65);
    const [gender, setGender] = useState(genderMap[genders_] || 'all');

    const previousAgeGenderRef = useRef({
        age_min: 18,
        age_max: 65,
        gender: 'all'
    });

    const minAgeOptions = Array.from({ length: 65 - 18 + 1 }, (_, i) => i + 18);
    const maxAgeOptions = [...Array.from({ length: 47 }, (_, i) => i + 18), '65+']; // Adding '65+' as last option

    useEffect(() => {
        const currentAgeGender = {
            age_min: minAge,
            age_max: maxAge,
            gender: gender
        };

        // Only call if any change
        const newAgeGender = JSON.stringify(currentAgeGender);
        if (newAgeGender !== JSON.stringify(previousAgeGenderRef.current)) {
            previousAgeGenderRef.current = currentAgeGender; //latest state
            onChange(currentAgeGender);
        }
    }, [minAge, maxAge, gender, onChange]);

    const handleMaxAgeChange = (e) => {
        const value = e.target.value;
        setMaxAge(value === '65+' ? 65 : Number(value)); // Set 65 when '65+' is selected
    };

    return (
        <Box>
            <FormControl as="fieldset" mb={5}>
                <FormLabel as="legend" fontWeight={'bold'}>Age</FormLabel>
                <Stack direction="row" spacing={4}>
                    <Box>
                        <FormLabel fontSize={'14px'}>Min Age</FormLabel>
                        <Select value={minAge} onChange={(e) => setMinAge(Number(e.target.value))} size={'sm'} borderRadius={'md'} bg={'#fff'}>
                            {minAgeOptions.map((age) => (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <FormLabel fontSize={'14px'}>Max Age</FormLabel>
                        <Select value={maxAge === 65 ? '65+' : maxAge} onChange={handleMaxAgeChange} size={'sm'} borderRadius={'md'} bg={'#fff'}>
                            {maxAgeOptions.map((age) => (
                                <option key={age} value={age}>
                                    {age === 65 ? '65+' : age} 
                                </option>
                            ))}
                        </Select>
                    </Box>
                </Stack>
            </FormControl>

            <FormControl as="fieldset">
                <FormLabel as="legend" fontWeight={'bold'}>Gender</FormLabel>
                <RadioGroup value={gender} onChange={setGender} colorScheme='orange'>
                    <Stack direction="row" spacing={5}>
                        <Radio bg={'#fff'} value="all">All</Radio>
                        <Radio bg={'#fff'} value="men">Men</Radio>
                        <Radio bg={'#fff'} value="women">Women</Radio>
                    </Stack>
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default AgeGenderSelector;
