import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex
} from '@chakra-ui/react';
import { InfoOutlineIcon } from "@chakra-ui/icons";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex alignItems={'center'} gap={2}>
                        <InfoOutlineIcon color={'red.500'} pointerEvents="all" fontSize={'18px'} />
                        <Text fontSize={'20px'}>Confirm Deletion ?</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Text>{message}</Text>
                </ModalBody>
                <ModalFooter gap={2}>
                    <Button onClick={onClose} size={'sm'} >
                        Cancel
                    </Button>
                    <Button colorScheme="orange" onClick={onConfirm} size={'sm'} >
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteConfirmationModal;
