import { api } from '../api'

export const trackingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublishStatus: builder.query({
      query: ({ campaignId }) => `/adspresso/tracklist/${campaignId}`,
      transformResponse: (response, meta, arg) => response.data,
    }),
    updatePublishCampaign: builder.mutation({
      query: ({ trackingId, payload }) => ({
          url: `/adspresso/tracklist/campaign/${trackingId}`,
          method: 'PUT',
          body: payload
      }),
    }),
    updatePublishAdset: builder.mutation({
      query: ({ trackingId, payload }) => ({
          url: `/adspresso/tracklist/adset/${trackingId}`,
          method: 'PUT',
          body: payload
      }),
    }),
    updatePublishAd: builder.mutation({
      query: ({ trackingId, payload }) => ({
          url: `/adspresso/tracklist/ad/${trackingId}`,
          method: 'PUT',
          body: payload
      }),
    }),
  }),
})

export const {
    useGetPublishStatusQuery,
    useUpdatePublishCampaignMutation,
    useUpdatePublishAdsetMutation,
    useUpdatePublishAdMutation,
} = trackingApi;