import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import AdSetUpdateForm from 'components/genericForms/AdsetUpdateForm';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { Link, useSearchParams } from 'react-router-dom';
import { useGetAdsetsListQuery, useUpdateAdsetStatusMutation } from 'store/campaigns/adsetFormApi';
import adsetMapping from '../../utils/adsetMapping.json'
import moment from 'moment';
import { selectSelectedAdsetId, selectSelectedCampaignId, setSelectedAdsetId } from 'store/campaigns/campaignsSlice';
import { useSelector,useDispatch } from 'react-redux';

function AdSetsTable({ adsAccountId, startDate, endDate, datePreset, setSelectedTab  }) {
    const dispatch = useDispatch();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const toast = useToast();
    const isSearchDisabled = Boolean(selectedAdsetId);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAdset, setselectedAdset] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const { data: adsetsList, isFetching, isLoading, error: adsetsListError, refetch } = useGetAdsetsListQuery({
        currentPage,
        searchValue,
        adsAccountId,
        start_date: startDate,
        end_date: endDate,
        date_preset: datePreset,
        campaign_ids: selectedCampaignId ? [selectedCampaignId] : [], 
        adset_id: selectedAdsetId ? [selectedAdsetId] : [],
    }, { skip: !adsAccountId });

    const [updateAdsetStatus, { isLoading: isAdsetStatusUpdating }] = useUpdateAdsetStatusMutation({});

    // console.log({adsetsList});

    const handleSaveSuccess = () => {
        refetch();
    };
    
    useEffect(() => {
        
        if (adsAccountId) {
            refetch();
        }
    }, [adsAccountId])

    useEffect(() => {
        if (adsetsList?.data) {
            const initialSwitchStates = {};
            adsetsList.data.forEach(adset => {
                initialSwitchStates[adset.adset_id] = adset.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [adsetsList]);

    const handleSwitchChange = (adsetId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adsetId]: newStatus }));
        updateAdsetStatus({
            adsetId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Adset ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Adset status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Adset status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adsetId]: currentState }));
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
    
                    const isActive = switchStates[original.adset_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad set is ON' : 'Ad set is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>              
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.adset_id, original.status)}
                                        isDisabled={userInfo?.role !== 'client'}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isSelected = selectedAdsetId === original?.adset_id;

                    return (
                    <Flex gap={'10px'} alignItems={'center'}> 
                        <Tooltip label='Edit' fontSize='xs' >
                            <Flex>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEdit2}
                                    cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"} 
                                    color={userInfo?.role === 'client' ? "inherit" : "gray.300"}
                                    onClick={() => {
                                        if (userInfo?.role === 'client') {
                                            setselectedAdset(original);
                                            onOpen();
                                        }
                                    }}
                                />
                            </Flex>
                        </Tooltip>
                        <Flex gap={'10px'} alignItems={'center'}>
                                <Link
                                    to={'#'} 
                                    onClick={() => {
                                        setSelectedTab(2);
                                        dispatch(setSelectedAdsetId(original?.adset_id)); 
                                    }}                      
                                >
                                    <Text
                                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        fontWeight={isSelected ? 'bold' : 'normal'}
                                        color={isSelected ? 'orange.500' : 'inherit'}
                                    >
                                        {original.name}
                                    </Text>
                                </Link>
                        </Flex>
                    </Flex>
                )}
            },            
            // {
            //     Header: "Ad set ID",
            //     accessor: "",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => (    
            //         <Text>{original.adset_id}</Text>
            //     ),
            // },
            {
                Header: "Spend",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.spend || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.results}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.cpc || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.ctr || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.cpm || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.clicks || '-'}</Text>
                    </Flex>
                )
            },
            // {
            //     Header: "Engagement",
            //     accessor: "",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => (
            //         <Flex gap={'10px'}>
            //             <Text fontSize={'12px'}>{original.insight?.engagement}</Text>
            //         </Flex>
            //     )
            // },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.purchaseValue}</Text>
                    </Flex>
                )
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.roas}</Text>
                    </Flex>
                )
            },
            {
                Header: "Destination Type",
                accessor: "WEBSITE",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const label = adsetMapping?.destinationTypes[original.destination_type] || 'N/A';
                    return <Text>{label}</Text>;
                }
            },
            {
                Header: "Optimization Goal",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const destinationType = original.destination_type;
                    const optimizationGoal = adsetMapping?.optimizationGoals[destinationType]?.find(goal => goal.value === original.optimization_goal);
                    return <Text>{optimizationGoal ? optimizationGoal.label : 'N/A'}</Text>;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.status}</Text>
                ),
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
        ],
        [selectedAdsetId]
    );

    return (
        <>
            {/* EDIT ADSET MODAL */}
            <Modal
                size={"5xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={() => {onClose(); setselectedAdset(null)}}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AdSetUpdateForm adsetData={selectedAdset} onClose={onClose} onSaveSuccess={handleSaveSuccess} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {isLoading ? (
                <Spinner mt="20px" />
                ) : adsetsListError ? (  
                    <Text color="red.500" mt="20px">{"Sorry, Ad Sets not available at this moment. Try again later."}</Text>
                ) : (
                    <AppTableWithPagination
                        columns={columns}
                        data={adsetsList?.data || []}
                        searchEnabled={!isSearchDisabled}
                        isLoading={false}
                        isFetching={isFetching}
                        initialState={{ pageIndex: currentPage - 1 }}
                        paginationMeta={adsetsList?.meta || {}}
                        onPageChange={setCurrentPage}
                        onSearchInputChange={setSearchValue}
                        searchTerm={searchValue}
                    />
                )
            }
        </>
    );

}

export default AdSetsTable;
