import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import {
  Box, Button, Collapse, Flex, Icon, Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio, RadioGroup,
  Select,
  Table,
  Tbody,
  Td,
  Text, Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';

import BuildUrlParamSingle from 'components/adspresso/BuildUrlParamSingle';
import BuildUrlParamsUpdate from 'components/adspresso/BuildUrlParamsUpdate';
import UrlParametersUpdate from 'components/adspresso/UrlParametersUpdate';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiChevronUp } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useCreateAdMutation, useGetInstantFormsByPageIdQuery } from 'store/campaigns/adsApi';
import { selectSelectedAdset } from 'store/campaigns/campaignsSlice';
import { number, object, string } from "yup";

function CreateCarouselAdForm({ adFormik, onFormValuesChange, call_to_action_options_instantForms, objective, onClose, call_to_action_options, adsAccountData, onSuccess, onSave }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [mediaFilesState, setMediaFilesState] = useState({});
  const [maxCards] = useState(10);

  const selectedAdset = useSelector(selectSelectedAdset);

  const [isUrlModalOpen, setUrlModalOpen] = useState(false);
  const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);
  const [isSingleUrlModalOpen, setSingleUrlModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  const [createAd, {isLoading: isAdsCreating} ] = useCreateAdMutation();

  const pageId = adFormik?.values?.page_id;

  const formattedPageIds = Array.isArray(pageId) ? pageId : [pageId];

  const { data: instantForms } = useGetInstantFormsByPageIdQuery(
    { pageIds: formattedPageIds },
    { skip: !formattedPageIds.length || selectedAdset?.destination_type !== 'ON_AD'  }
  );

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  const handleInputChange = (fileId, field, value) => {

    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        [field]: value,
      },
    }));
  };

  const handleMediaTypeChange = (type) => {
    setMediaType(type);
    setIsDriveOpen(true);
  }

  useEffect(() => {
    if (mediaFiles.length > 0) {
      setOpenAccordionId(mediaFiles[0]._id);
    }
  }, [mediaFiles]);

  const handleFileSelect = (files) => {

    if (files.length > 0) {
        setMediaFiles(prevFiles => {
          // Add new files only if the total number of files is less than maxCards
          const newFiles = [...prevFiles, ...files].slice(0, maxCards);
          return newFiles;
        });
      }
  };

  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };

  let schema = object().shape({
    name: string().required('Name is required'),
    cpa: number()
          .typeError('must be a number')
          .positive('must be a positive number'),
    destinationType: string(),
    type: string()
      .max(300, 'select atleast one type')
      .required('Type is required'),
    // message: string().when('applyForAll', {
    //   is: true,
    //   then: () => string().required('Required'),
    //   otherwise: () => string().notRequired(),
    // }),
    // message: string().required('Text required'),
    link: string()
    .when('destinationType', {
      is: 'WEBSITE',
      then: () => string().required('URL is required'),
      otherwise: () => string().notRequired(),
    }),
    // phoneNumber: string().
    //   when('destinationType', {
    //     is: 'PHONE_CALL',
    //     then: () => string()
    //           .transform((value) => value.slice(2)) // Remove the first two digits (e.g., country code like "91")
    //           .matches(/^[0-9]{10,15}$/, 'Phone number must be at least 10 digits') // Ensure remaining digits are at least 10 digits
    //           .required('Phone number is required'), 
    //     otherwise: () => string().notRequired(),
    //   })
    //   .min(10, 'Phone number is too short')
    //   .max(15, 'Phone number is too long'),
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      destinationType: selectedAdset?.destination_type ? selectedAdset?.destination_type : '',
      message: '',
      name: adFormik.values?.name,
      description: '',
      link: '',
      type: selectedAdset?.destination_type === 'WEBSITE' ? 'SHOP_NOW' : (selectedAdset?.destination_type === 'PHONE_CALL' ? 'CALL_NOW' : 'SIGN_UP'),
      mediaFiles: [],
      child_attachments:[],
      instagram_actor_id: adFormik.values?.instagram_actor_id,
      page_id: adFormik.values?.page_id,
      phoneNumber: '',
      cpa:'',
      url_tags:'',
      enroll_status: adFormik.values.enroll_status ? adFormik.values.enroll_status : 'OPT_OUT',
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      const { name, message, type, link, phoneNumber, cpa, url_tags, enroll_status } = values;

      const payload = {
        name: name,
        cpa: Number(cpa),
        adset_id: selectedAdset?.adset_id,
        adcreative: {
          name: name ,
          ...(url_tags && { url_tags }),  
          object_story_spec: {
              link_data: {
                ...(message && { message: message }),
                link: selectedAdset?.destination_type === 'ON_AD'
                    ? "http://fb.me/"
                    : (link ? link : `https://facebook.com/${adFormik.values?.page_id}/`),
                    call_to_action: selectedAdset?.destination_type === 'ON_AD'
                      ? {
                          type,
                          value: {
                            lead_gen_form_id: link
                          }
                        }
                      : (type === 'CALL_NOW'
                          ? {
                              type,
                              value: {
                                link: `tel:+${mediaFilesState[mediaFiles[mediaFiles.length - 1]?.id || mediaFiles[mediaFiles.length - 1]?.creativeId]?.link}`
                              }
                            }
                        : { type }),
                  child_attachments: mediaFiles?.map((file) => {
                      if (file?.creativeType === 'video') {
                          return {
                            link: selectedAdset?.destination_type === 'ON_AD'
                              ? "http://fb.me/"
                              : (type === 'CALL_NOW'
                                  ? `https://facebook.com/${adFormik.values?.page_id}/`
                                  : mediaFilesState[file?.id || file?.creativeId]?.link
                                ),
                            call_to_action: selectedAdset?.destination_type === 'ON_AD'
                            ? {
                                type,
                              }
                            : (type === 'CALL_NOW'
                                ? {
                                    type,
                                    value: {
                                      link: `tel:+${mediaFilesState[file?.id || file?.creativeId]?.link}`
                                    }
                                  }
                                : { type }),
                              picture: file?.picture,
                              name: mediaFilesState[file.id || file?.creativeId]?.name ,
                              video_id: file?.creativeId,
                              description: mediaFilesState[file.id || file?.creativeId]?.description,
                              hash: file?.hash,
                          };
                      } else if (file?.creativeType === 'image') {
                          return {
                            link: selectedAdset?.destination_type === 'ON_AD'
                              ? "http://fb.me/"
                              : (type === 'CALL_NOW'
                                  ? `https://facebook.com/${adFormik.values?.page_id}/`
                                  : mediaFilesState[file?.id || file?.creativeId]?.link
                                ),
                                call_to_action: selectedAdset?.destination_type === 'ON_AD'
                                ? {
                                    type,
                                  }
                                : (type === 'CALL_NOW'
                                    ? {
                                        type,
                                        value: {
                                          link: `tel:+${mediaFilesState[file?.id || file?.creativeId]?.link}`
                                        }
                                      }
                                    : { type }),
                              picture: file?.url,
                              name: mediaFilesState[file.id || file?.creativeId]?.name,
                              //image_hash: file?.creativeId,
                              description: mediaFilesState[file.id || file?.creativeId]?.description,
                              hash: file?.hash,
                          };
                      }
                      return null; 
                  }).filter(Boolean), // Remove null values from the array
              },
              instagram_actor_id: adFormik.values?.instagram_actor_id,
              page_id: adFormik.values?.page_id,
          },
          contextual_multi_ads:{
            enroll_status: enroll_status
          },
          degrees_of_freedom_spec: {
              creative_features_spec: {
                  standard_enhancements: {
                      enroll_status: "OPT_OUT",
                  },
              },
          },
        },
        adsAccount: {
          id: adsAccountData?.id,
          adsAccountId: adsAccountData?.account_id,
          adsAccountName: adsAccountData?.name,
          businessName: adsAccountData?.business?.name,
          businessId: adsAccountData?.business?.id,
        },
      };
      createAd({ payload })
              .unwrap()
              .then(response => {
                  onSuccess();
                  toast({
                      position: "top-right",
                      title: "Ad Created",
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .catch(error => {
                  toast({
                      position: "top-right",
                      title: "Error creating Ad",
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                  });
              })
              .finally(() => {
                  setMediaType(null);
                  setMediaFiles([]); // Clear the media files
                  setMediaFilesState({});
                  resetForm();
                  onClose();
          });
      },
  });


  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const isAnyUrlMissing = mediaFiles.some(file => {
    const fileId = file?.id || file?.creativeId;
    const fileState = mediaFilesState[fileId] || {};
    return !fileState.link || fileState.link.trim() === '';
    
  });

  useEffect(() => {
    
    // Transform media files and update child_attachments
    const updatedChildAttachments = mediaFiles?.map((file) => {
      if (file?.creativeType === 'video') {
        return {
          link: mediaFilesState[file.id || file?.creativeId]?.link,
          picture: file?.picture,
          name: mediaFilesState[file.id || file?.creativeId]?.name,
          video_id: file?.creativeId,
          description: mediaFilesState[file.id || file?.creativeId]?.description,
        };
      } else if (file?.creativeType === 'image') {
        return {
          link: mediaFilesState[file.id || file?.creativeId]?.link,
          image_hash: file?.creativeId,
          picture: file?.url,
          name: mediaFilesState[file.id || file?.creativeId]?.name,
          description: mediaFilesState[file.id || file?.creativeId]?.description,
        };
      }
      return null; 
    }).filter(Boolean);
  
    singleMediaFormik.setFieldValue('child_attachments', updatedChildAttachments);
  }, [ mediaFilesState, mediaFiles]);

  const handleUrlChange = (constructedUrl) => {
     singleMediaFormik.setFieldValue('link', constructedUrl); 
  };

  const handleSingleUrlChange = (constructedUrl, fileId) => {
    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        link: constructedUrl,  
      },
    }));
  };

  useEffect(() => {
    onFormValuesChange(singleMediaFormik?.values);
  }, [singleMediaFormik.values, onFormValuesChange]); 


  const handleUrlParamChange = (constructedUrl) => {
    singleMediaFormik.setFieldValue('url_tags', constructedUrl); 
  };

  const filteredInstantForms = instantForms?.length > 0 && instantForms
    .map((formResponse) => formResponse?.data?.data) // Get the 'data' array from each formResponse
    .flat() // Flatten the array to get all forms in one array
    .filter((form) => form?.page_id === pageId); 


  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
        }}
        onSubmit={handleFileSelect}
        mediaFormat={adFormik?.values?.format}
        selectedAdAccounts={[{
          id: adsAccountData?.id,
          adsAccountId: adsAccountData?.account_id,
          adsAccountName: adsAccountData?.name,
          businessName: adsAccountData?.business?.name,
          businessId: adsAccountData?.business?.id
        }]}
        uploadPurpose="create"
        adFormik={adFormik}
      />

      <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
          size={"2xl"}
          isCentered={true}>
          <ModalOverlay />
          <ModalContent>
          <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <BuildUrlParamsUpdate link={singleMediaFormik?.values} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange}/>
          </ModalBody>
          </ModalContent>
      </Modal> 

      <Modal isOpen={isSingleUrlModalOpen} onClose={() => setSingleUrlModalOpen(false)} scrollBehavior={"inside"}
          size={"2xl"}
          isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BuildUrlParamSingle link={mediaFilesState} onClose={() => setSingleUrlModalOpen(false)} onUrlChange={handleSingleUrlChange} selectedAccountId={selectedAccountId}/>
          </ModalBody>
        </ModalContent>
      </Modal>  

      <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
            size={"2xl"}
            isCentered={true}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <UrlParametersUpdate link={singleMediaFormik.values} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange}/>
            </ModalBody>
            </ModalContent>
      </Modal> 

      <Flex direction={'column'} width={'100%'} gap={2}>
      <Flex direction="column" gap={2} bg={'gray.50'} p={4} borderRadius={'md'}>
        <Flex direction="column" mb={4}>
          
          <Flex direction="column" mb="10px">
            <Flex alignItems={'center'} gap={2}>
              <Text fontSize="15px" fontWeight={'bold'}>Carousel cards</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="14px" p="5px">
                    <Text>
                      Choose media and enter text for up to ten carousel cards.
                    </Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon boxSize={3} pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Text fontSize="13px" flex={1}>
              {mediaFiles.length} of {maxCards} cards added
            </Text>
            {mediaFiles?.length < 2 ? (
              <Flex align={'center'} gap={'5px'} px={'10px'} py={'5px'} my={'5px'} borderRadius={'sm'} bg={'gray.100'}>
                <InfoIcon boxSize={3} color={'gray.800'} />
                <Text fontSize="13px">Add at least 2 cards.</Text>
              </Flex>
            ) : null}
          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0}>
            <Select
              placeholder={"Add cards"}
              width={'150px'}
              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                handleMediaTypeChange(e.target.value);
              }}
              size="sm"
            >
              <option value="image">{"Add image cards"}</option>
              <option value="video">{"Add video cards"}</option>
            </Select>
          </Flex>


          {/* Media Previews */}
          {mediaFiles?.length > 0 && (
            <Box mt={4}>
              <Flex wrap="wrap" gap={2} direction="column">
                {mediaFiles?.map((file, index) => {
                  const fileId = file?.id || file?.creativeId;       
                  const fileState = mediaFilesState[fileId] || {};

                  // Checking if URL is empty
                  const isUrlRequired = !fileState.link || fileState.link.trim() === '';

                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200" position={'relative'}>
                      <Flex alignItems="center" gap={2} justify="space-between">
                        <Flex alignItems="center" gap={2}>
                          {file?.creativeType === 'image' ? (
                            <img
                              src={file?.url}
                              alt={file?.name}
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          ) : (
                            <video
                              src={file?.source}
                              // controls
                              style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }}
                            />
                          )}
                          <Flex direction="column">
                            <Text fontSize="10px" noOfLines={1}>
                              {fileState.name || file?.name || 'No name available'}
                            </Text>
                            <Flex gap={1}>
                            {(selectedAdset?.destination_type === 'WEBSITE') ? (
                                <>
                                      {fileState.name ? (
                                        <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.name)}</Text>
                                      ) : (
                                        <Text fontSize="10px">Enter a headline</Text>
                                      )}
                                      {!fileState.link ? (
                                        <>
                                          <Text fontSize="10px">and</Text>
                                          <Text fontSize="10px">URL&nbsp;
                                            <Tooltip
                                              borderRadius={5}
                                              label={
                                                <Box p="5px" >
                                                  <Text fontSize="12px">
                                                    Your carousel card {index + 1} doesn't have a destination. Please enter a URL for each card in your carousel.
                                                  </Text>
                                                </Box>
                                              }
                                              fontSize="md"
                                            >
                                              <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                            </Tooltip>
                                          </Text>
                                        </>
                                      ) : null}
                                </>
                              ) : (selectedAdset?.destination_type === 'PHONE_CALL') ? (
                                <>
                                {fileState.name ? (
                                  <Text fontSize="10px" noOfLines={1}>{truncateName(fileState.name)}</Text>
                                ) : (
                                  <Text fontSize="10px">Enter a headline</Text>
                                )}
                                {!fileState.link ? (
                                  <>
                                    <Text fontSize="10px">and</Text>
                                    <Text fontSize="10px">Phone number&nbsp;
                                      <Tooltip
                                        borderRadius={5}
                                        label={
                                          <Box p="5px" >
                                            <Text fontSize="12px">
                                              Your carousel card {index + 1} doesn't have a destination. Please enter a phone number for each card in your carousel.
                                            </Text>
                                          </Box>
                                        }
                                        fontSize="md"
                                      >
                                        <InfoOutlineIcon color={'red.500'} pointerEvents="all" />
                                      </Tooltip>
                                    </Text>
                                  </>
                                ) : null}
                                </>
                              ):null}
                            </Flex>
                            <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" gap={'5px'}>
                          <Tooltip label="Edit copy" fontSize="xs">
                            <Box>
                              <Icon
                                _hover={{ color: 'gray.500' }}
                                as={openAccordionId === fileId ? FiChevronUp : MdEdit}
                                cursor="pointer"
                                fontSize="14px"
                                onClick={() => toggleAccordion(fileId)}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip label="Delete" fontSize="xs">
                            <Box>
                              <Icon
                                color="red.500"
                                _hover={{ color: 'gray.400' }}
                                as={MdDelete}
                                cursor="pointer"
                                boxSize={4}
                                onClick={() => handleDeleteFile(index)}
                              />
                            </Box>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Collapse in={openAccordionId === fileId}>
                        <Box p={4}>
                          {/* Headline Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Headline</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five brief headlines to let people know what your ad is about. Each
                                      headline can have a maximum of 255 characters. Headlines won't appear in all
                                      placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Input
                              placeholder="Tell people what your ad is about..."
                              size="sm"
                              borderRadius="md"
                              value={fileState.name || ''}
                              onChange={(e) => handleInputChange(fileId, 'name', e.target.value)}
                            />
                          </Box>

                          {/* Description Section */}
                          <Box direction="column" mb={4}>
                            <Flex alignItems="center" mb={2} gap={2}>
                              <Text fontSize="14px" fontWeight="bold">Description</Text>
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize="14px" p="5px">
                                    <Text>
                                      Add up to five link descriptions to emphasize why people should visit your
                                      website. This won't appear in all placements.
                                    </Text>
                                  </Box>
                                }
                                fontSize="md"
                              >
                                <InfoOutlineIcon pointerEvents="all" />
                              </Tooltip>
                            </Flex>
                            <Textarea
                              placeholder="Include additional details"
                              size="sm"
                              borderRadius="md"
                              value={fileState.description || ''}
                              onChange={(e) => handleInputChange(fileId, 'description', e.target.value)}
                              rows={2}
                            />
                          </Box>

                          {/* Conditional Input based on Destination Type */}
                          {selectedAdset?.destination_type === 'PHONE_CALL' ? (
                            <>
                             <Box direction="column" mb={'10px'}>
                                <Flex alignItems="center" mb={2} gap={2}>
                                  <Text fontWeight="bold" fontSize="14px">Call to action</Text>
                                  <Tooltip
                                    borderRadius={5}
                                    label={
                                      <Box fontSize="sm" p="5px">
                                        <Text>This is the only available option.</Text>
                                      </Box>
                                    }
                                    fontSize="md"
                                  >
                                    <InfoOutlineIcon pointerEvents="all" />
                                  </Tooltip>
                                </Flex>
                                <Select
                                  name="type"
                                  borderRadius="md"
                                  value={singleMediaFormik.values.type}
                                  onChange={singleMediaFormik.handleChange}
                                  onBlur={singleMediaFormik.handleBlur}
                                  size="sm"
                                  isDisabled
                                >
                                  <option value="CALL_NOW">Call Now</option>
                                </Select>
                              </Box>
                              <Box>
                                  <Flex alignItems="center" mt={'10px'} mb={2}>
                                    <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                            <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                        >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>
                                <PhoneInput
                                  country="in"
                                  value={fileState.link || ''}
                                  onChange={(value) => handleInputChange(fileId, 'link', value)}
                                  inputProps={{
                                    name: 'phoneNumber',
                                    required: true,
                                  }}
                                  enableSearch={true}
                                  searchPlaceholder="Search"
                                />
                                {isUrlRequired && (
                                  <Text fontSize="12px" color="red.500" mt={2}>
                                    Your carousel card {index + 1} doesn't have a destination. Please enter a phone number for each card in your carousel.
                                  </Text>
                                )}
                              </Box>
                            </>
                          ) :  selectedAdset?.destination_type === 'WEBSITE' ? (
                            // URL Link Section
                            <Box direction="column" mb={4}>
                              <Flex alignItems="center" mb="5px">
                                <Text fontSize="14px" fontWeight="bold">Website URL</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" ml={2} mr={2}>*</Text>
                                <Tooltip
                                  borderRadius={5}
                                  label={
                                    <Box fontSize="14px" p="5px">
                                      <Text>
                                        Enter the URL for the web page that you want people to visit.
                                      </Text>
                                    </Box>
                                  }
                                  fontSize="md"
                                >
                                  <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                              </Flex>
                              <Input
                                placeholder="http://www.example.com/page"
                                size="sm"
                                borderRadius="md"
                                value={fileState.link || ''}
                                onChange={(e) => handleInputChange(fileId, 'link', e.target.value)}
                                isInvalid={isUrlRequired}
                              />
                               <Flex mt={2}>
                                  <Button onClick={() => {
                                    setSingleUrlModalOpen(true)
                                    setSelectedAccountId(fileId);
                                  }} 
                                variant="link">
                                    <Text fontSize={'14px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                </Button>
                              </Flex>
                              {isUrlRequired && (
                                <Text fontSize="12px" color="red.500" mt={2}>
                                  Your carousel card {index + 1} doesn't have a destination. Please enter a website URL for each card in your carousel.
                                </Text>
                              )}
                            </Box>
                          ):null}
                        </Box>
                      </Collapse>

                    </Box>
                  );
                })}
              </Flex>
            </Box>
          )}
        </Flex>
        {/* <Divider /> */}
        {/* Primary Text Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize="14px" mr={2}>Primary text</Text>
            {/* <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text> */}
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Textarea
            size="sm"
            placeholder="Tell people what your ad is about..."

            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius="md"
          />
          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        {/* Call to action Section */}
        {selectedAdset?.destination_type !== 'PHONE_CALL' && selectedAdset?.destination_type !== 'ON_AD' ? (
            <Box direction="column">
              <Flex alignItems="center" mb={2} gap={2}>
                <Text fontWeight="bold" fontSize="14px">Call to action</Text>
                <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="sm" p="5px">
                      <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                    </Box>
                  }
                  fontSize="md"
                >
                  <InfoOutlineIcon pointerEvents="all" />
                </Tooltip>
              </Flex>
              <Select
                name="type"
                borderRadius="md"
                value={singleMediaFormik.values.type}
                onChange={singleMediaFormik.handleChange}
                onBlur={singleMediaFormik.handleBlur}
                size="sm"
              >
                {call_to_action_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
                <Text color="red.500">{singleMediaFormik.errors.type}</Text>
              ) : null}
            </Box>
          ) : selectedAdset?.destination_type === 'ON_AD' && objective === 'OUTCOME_LEADS' ? (
            <Box direction="column" mb={'10px'}>
              <Flex alignItems="center" mb={2} gap={2}>
                <Text fontWeight="bold" fontSize="14px">Call to action</Text>
                <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="sm" p="5px">
                      <Text>This is the only available option.</Text>
                    </Box>
                  }
                  fontSize="md"
                >
                  <InfoOutlineIcon pointerEvents="all" />
                </Tooltip>
              </Flex>
              <Select
                name="type"
                borderRadius="md"
                value={singleMediaFormik.values.type}
                onChange={singleMediaFormik.handleChange}
                onBlur={singleMediaFormik.handleBlur}
                size="sm"
              >
                  {call_to_action_options_instantForms?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                  ))}
              </Select>
            </Box>
          ) : (
            // <Box direction="column" mb={'10px'}>
            //   <Flex alignItems="center" mb={2} gap={2}>
            //     <Text fontWeight="bold" fontSize="14px">Call to action</Text>
            //     <Tooltip
            //       borderRadius={5}
            //       label={
            //         <Box fontSize="sm" p="5px">
            //           <Text>This is the only available option.</Text>
            //         </Box>
            //       }
            //       fontSize="md"
            //     >
            //       <InfoOutlineIcon pointerEvents="all" />
            //     </Tooltip>
            //   </Flex>
            //   <Select
            //     name="type"
            //     borderRadius="md"
            //     value={singleMediaFormik.values.type}
            //     onChange={singleMediaFormik.handleChange}
            //     onBlur={singleMediaFormik.handleBlur}
            //     size="sm"
            //     isDisabled
            //   >
            //     <option value="CALL_NOW">Call Now</option>
            //   </Select>
            // </Box>
            null
        )}

        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize="12px">{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        {selectedAdset?.destination_type === 'WEBSITE' ? (
        <Box direction="column">
          <Flex alignItems="center" mt={'10px'} mb={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>Enter the URL for the webpage you want people to visit</Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            type="url"
            name="link"
            borderRadius={'md'}
            placeholder="Enter URL"
            value={singleMediaFormik.values.link}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            size={'sm'}
          />
           <Flex mt={2}>
              <Button onClick={() => {
                  setUrlModalOpen(true)
              }} 
              variant="link">
                  <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
              </Button>
          </Flex>
          {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
          ) : null}
        </Box>
        ) : null}

        {selectedAdset?.destination_type === 'ON_AD' && objective === 'OUTCOME_LEADS' ? (
            <>
                <Flex alignItems="center" gap={2} mt={2}>
                    <Text fontWeight="bold">Instant form</Text>
                    <Tooltip 
                    borderRadius={5}
                    label={
                        <Box fontSize="sm" p="5px">
                        <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                        </Box>
                    }
                    fontSize="md"
                    >
                    <InfoOutlineIcon pointerEvents="all" />
                    </Tooltip>
                </Flex>

                {/* Table Layout */}
                {filteredInstantForms?.length > 0 ? (
                    <RadioGroup onChange={(value) => singleMediaFormik.setFieldValue('link', value)} colorScheme="orange">
                    <Table variant="simple">
                        <Thead>
                        <Tr>
                            <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                            <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        {filteredInstantForms?.map((form) => (
                            <Tr key={form?.id}  _hover={{ backgroundColor: '#F9F9F9' }} >
                            <Td>
                                <Flex alignItems="center" gap={2}>
                                <Radio value={form?.id} />
                                <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                    {form?.name}
                                </Text>
                                </Flex>
                            </Td>
                            <Td>
                                <Text fontSize={'12px'}>
                                {new Date(form?.created_time).getFullYear()}-
                                {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                </Text>
                            </Td>
                            </Tr>
                        ))}
                        </Tbody>
                    </Table>
                    </RadioGroup>
                ) : (
                    <Text fontSize="14px" color="gray.500">No form data available</Text>
                )}
            </>
        ) : null}

        {/* {selectedAdset?.destination_type == 'PHONE_CALL' ? (v
          <Box >
            <Flex alignItems="center" mt={'10px'} mb={2}>
              <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
              <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
              <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="sm" p="5px">
                      <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                    </Box>
                  }
                  fontSize="md"
                >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <PhoneInput
              country={'in'}
              value={singleMediaFormik.values.phoneNumber}
              onChange={(value) => {
                singleMediaFormik.setFieldValue('phoneNumber', value);
              }}
              name='phoneNumber'
              inputProps={{
                name: 'phoneNumber',
                required: true,
                //autoFocus: true,
              }}
              enableSearch={true}
              searchPlaceholder="Search"
            />
            {singleMediaFormik.touched.phoneNumber && singleMediaFormik.errors.phoneNumber ? (
              <Text color="red.500" fontSize={'12px'} mt={'5px'}>{singleMediaFormik.errors.phoneNumber}</Text>
            ) : null}
          </Box>
        ) : null} */}
      </Flex>

      <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
        <Text fontWeight={'bold'}>Tracking</Text>
            <Flex direction="column" gap={1}>
                <Flex alignItems={'center'}>
                    <Text mb={2} fontWeight="bold">
                            URL parameters{" "}
                        {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                        <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                    </Text>
                    <Tooltip
                        borderRadius={5}
                        label={
                            <Box fontSize="sm" p={'5px'}>
                                <Text >
                                    Add parameters to the end of your website URL to track where your visitors are coming from.
                                </Text>
                            </Box>
                        }
                        fontSize="md"
                    >
                        <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                    </Tooltip>
                </Flex>
                <Input
                    name="url_tags"
                    placeholder="key1=value1&key2=value2"
                    bg="#fff"
                    size='sm'
                    borderRadius="md"
                    value={singleMediaFormik.values.url_tags}
                    onChange={singleMediaFormik.handleChange}
                />
                <Flex mt={2}>
                    <Button onClick={() => {
                        setUrlParamModalOpen(true)
                    }} 
                    variant="link">
                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                    </Button>
                </Flex>
                {singleMediaFormik.touched?.url_tags && singleMediaFormik.errors?.url_tags && (
                    <Text fontSize={"12px"} color="red.500">{singleMediaFormik.errors?.url_tags}</Text>
                )}
            </Flex>
      </Flex>

      <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4}>
          <Flex direction="column" gap={1}>
              <Text fontWeight="bold">CPA</Text>
              <Input
                  name="cpa"
                  placeholder="Enter CPA"
                  bg="#fff"
                  size='sm'
                  borderRadius="md"
                  value={singleMediaFormik.values.cpa}
                  onBlur={singleMediaFormik.handleBlur}
                  onChange={singleMediaFormik.handleChange}
              />
              {singleMediaFormik.touched.cpa && singleMediaFormik.errors.cpa && (
                  <Text fontSize={"12px"} color="red.500">{singleMediaFormik.errors.cpa}</Text>
              )}
          </Flex>
      </Flex>


       {/* Save Button */}
       <Flex>
          <Button
            colorScheme="orange"
            onClick={() => {
              singleMediaFormik.handleSubmit();
              onSave();
            }}
            isLoading={isAdsCreating}
            size="sm"
            isDisabled={
              mediaFiles?.length < 2 ||
              ((selectedAdset?.destination_type === 'WEBSITE' || selectedAdset?.destination_type === 'PHONE_CALL')  && isAnyUrlMissing)
            }
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default CreateCarouselAdForm;

