import { RiImageFill, RiVideoFill } from 'react-icons/ri';
import moment from 'moment';

const getFileType = (name) => {
    const extension = name?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
        case 'bmp':
            return { icon: <RiImageFill />, label: 'Image', type: 'image' };
        case 'mp4':
        case 'avi':
        case 'mov':
        case 'mkv':
        case 'webm':
            return { icon: <RiVideoFill />, label: 'Video', type: 'video' };
        default:
            return { icon: null, label: 'Unknown', type: 'unknown' };
    }
};

const toCurrency = (amount, currency) => {
    const symbol = currency === 'INR' ? '₹' : ''
    let number = parseFloat(amount);
    return symbol + number.toLocaleString(currency);
}

function getExtension(filename) {
    return filename?.split('.').pop()
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function hasDecimal(number) {
    const numberAsString = number.toString();
    return numberAsString.includes('.');
}

function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength) + '...';
    }
}

const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
        return name?.substring(0, maxLength) + '...';
    }
    return name;
};

const flattenTargeting = (data) => {
    if (!data || Object.keys(data)?.length == 0) {
        return;
    }
    const targetFields = [
        'interests',
        'education_schools',
        'education_majors',
        'work_positions',
        'work_employers',
        'relationship_statuses',
        'interested_in',
        'user_adclusters',
        'college_years',
        'education_statuses',
        'family_statuses',
        'industries',
        'life_events',
        'politics',
        'behaviors',
        'income',
        'net_worth',
        'home_type',
        'home_ownership',
        'home_value',
        'ethnic_affinity',
        'generation',
        'household_composition',
        'moms',
        'office_type',
        'location_categories'
    ];

    // Extract and flatten only the fields that are present in the object
    const resultArray = targetFields
        .filter(field => data[field])  // Only include fields that exist
        .flatMap(field => data[field]); // Flatten arrays into one

    return resultArray;
};

const getEndOfNextMonth = () => {
    return moment().add(1, 'month').endOf('month').toDate();
};

const removeEmptyValues = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => {
            // Check if value is not null, not an empty string, not an empty array, and not an empty object
            return (
                value != null && 
                value !== '' && 
                !(Array.isArray(value) && value.length === 0) && 
                !(typeof value === 'object' && Object.keys(value).length === 0) 
            );
        })
    );
};

const formatErrorMessages = (errors) => {
    if (Array.isArray(errors)) {
        const uniqueErrors = new Set();
        errors.forEach(error => {
            Object.values(error).forEach(message => uniqueErrors.add(message));
        });
        return Array.from(uniqueErrors).join(', ');

    }
    return typeof errors === 'string' ? errors : 'An unknown error occurred';
};

const convertUnixToDate = (timestamp) => {
    return new Date(timestamp * 1000); // Convert Unix seconds to milliseconds
};

const formatUnixTimestamp = (timestamp) => {
    const date = convertUnixToDate(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero
    const day = ('0' + date.getDate()).slice(-2); // Adding leading zero
    return `${year}-${month}-${day}`; // YYYY-MM-DD format
};

// Group the selected locations by country
const groupGeoLocations = (selectedLocations) => {
    selectedLocations.reduce((acc, location) => {
        const countryName = location.country_name;

        if (!acc[countryName]) {
            acc[countryName] = [];
        }

        acc[countryName].push(location);

        return acc;
    }, {});
}

export { toCurrency, getExtension, isJsonString, hasDecimal, truncateString, getFileType, flattenTargeting, removeEmptyValues, getEndOfNextMonth, formatErrorMessages, formatUnixTimestamp, truncateName, groupGeoLocations };
