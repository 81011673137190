import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightElement, List, ListItem, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useGetLocalesQuery } from 'store/facebookServices/facebookServicesApi';

const LocaleSelector = ({ onLocalesChange, preFilledLocales,BMID}) => {
    const inputRef = useRef(null);
    const popupRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [localeOptions, setlocaleOptions] = useState([]);
    const [selectedLocales, setselectedLocales] = useState(preFilledLocales?.length > 0 ? preFilledLocales : []);
    const [showPopup, setShowPopup] = useState(false);

    const { data: locales, isLoading, isFetching, refetch } = useGetLocalesQuery({ searchTerm: debouncedSearchTerm, BMID }, { skip: !debouncedSearchTerm?.length });



    useEffect(() => {
        if (searchTerm?.length == 0) {
            setDebouncedSearchTerm('');
            setShowPopup(false);
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length > 0 && (searchTerm == debouncedSearchTerm)) {
            refetch()
        }
    }, [searchTerm, debouncedSearchTerm])



    useEffect(() => {

        if (locales?.data?.length > 0) {
            setShowPopup(true);
            setlocaleOptions(locales?.data);
        } else {
            setShowPopup(false);
        }
    }, [locales, isFetching]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                popupRef.current &&
                !popupRef.current.contains(event.target)
            ) {
                setShowPopup(false);
                setlocaleOptions([]);
            }

        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handlelocaleselect = (locale) => {


        setselectedLocales((prevLocales) => {
            const localeExists = prevLocales.some(l => l.key === locale.key);

            // Replace the existing locale if it exists, otherwise add it
            const updatedLocales = localeExists
                ? prevLocales.map(l => l.key === locale.key ? locale : l) // Replace the locale
                : [...prevLocales, locale]; // Add the new locale

            // Use setTimeout to avoid state updates during render
            setTimeout(() => onLocalesChange(updatedLocales), 0);

            return updatedLocales;
        });

        setSearchTerm('');
        setDebouncedSearchTerm('');
        setlocaleOptions([]);
        setShowPopup(false);
    };

    //console.log(localeOptions);


    const removeLocaleEntry = (key) => {
        setselectedLocales(prevlocales => {
            const updatedlocales = prevlocales.filter(locale => locale.key !== key);
            setTimeout(() => onLocalesChange(updatedlocales), 0);
            return updatedlocales;
        });
    };



    return (
        <Box>
            <Box my={'10px'}>
                {selectedLocales?.map((locale) => (
                    <Flex key={'selected' + locale?.id + locale.key} bg={'white'} align={'center'} justify={'space-between'} width={'full'} py={'5px'} px={'10px'} borderRadius={'md'} mb={'5px'}>

                        <Flex flex={1} pr={'10px'} align={'center'}>
                            <Flex justify={'space-between'} flex={1} align={'center'}>
                                <Text ml={'5px'} fontSize={'14px'} fontWeight={'bold'}>{locale?.name} </Text>
                                {/* <Text ml={'5px'} fontSize={'12px'}>{locale?.key}</Text> */}
                            </Flex>
                        </Flex>
                        <CloseIcon _hover={{ cursor: 'pointer', color: 'red.400' }} boxSize={3} color='gray.500' onClick={() => removeLocaleEntry(locale?.key)} />
                    </Flex>
                ))}
            </Box>
            <Box position="relative">
                <Flex>
                    <InputGroup >
                        <InputLeftElement height={'32px'}>
                            <SearchIcon boxSize={4} color='gray.500' />
                        </InputLeftElement>
                        <Input
                            ref={inputRef}
                            value={searchTerm}
                            onChange={(e) => {
                                setlocaleOptions([]);
                                setSearchTerm(e.target.value)
                            }}
                            placeholder="Search Languages"
                            autoComplete="off"
                            borderRadius={'md'}
                            size={'sm'}
                            onFocus={() => {
                                setlocaleOptions([]);
                                if (localeOptions?.length > 0) {
                                    setShowPopup(true)
                                }
                            }}
                        />
                        <InputRightElement height={'32px'}>
                            {isFetching ? (
                                <Spinner boxSize={3} />
                            ) : (<>
                                {searchTerm?.length > 0 ? (
                                    <CloseIcon boxSize={3} color='gray.500' onClick={() => { setSearchTerm(''); setDebouncedSearchTerm(''); }} />
                                ) : null}
                            </>)}
                        </InputRightElement>
                    </InputGroup>
                </Flex>

                <>

                    {showPopup && localeOptions?.length > 0 && (
                        <List
                            ref={popupRef}
                            //position="absolute"
                            top="100%"
                            width="100%"
                            border="1px solid #ccc"
                            backgroundColor="white"
                            zIndex="1000"
                            maxHeight="200px"
                            overflowY="auto"
                        >
                            {localeOptions?.map((locale) => (
                                <ListItem
                                    key={locale.region_id + locale.name}
                                    p={'5px'}
                                    px={'10px'}
                                    cursor="pointer"
                                    _hover={{ backgroundColor: '#f0f0f0' }}
                                    onClick={() => handlelocaleselect(locale)}
                                >
                                    <Flex justify={'space-between'} align={'center'}>
                                        <Text fontSize={'14px'}>
                                            {locale.name}
                                        </Text>
                                        {/* <Text fontSize={'12px'}>
                                            {locale.key}
                                        </Text> */}
                                    </Flex>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            </Box>

        </Box>
    );
};

export default LocaleSelector;
