import { api } from "../api";

export const adsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createAd: builder.mutation({
          query: ({ payload }) => ({
              url: `/ads`,
              method: 'POST',
              body: payload,
        }),
        invalidatesTags: ['ads'],
        }),
        getAdsList: builder.query({
            query: ({ pageSize, adsAccountId, searchValue, adset_ids, campaign_ids, ad_ids, after, before}) => {

              let url = `/ads/${adsAccountId}?`;
              
              if (campaign_ids && campaign_ids.length > 0) {
                url += `campaign_ids=${JSON.stringify(campaign_ids)}&`;
              }
              if (adset_ids && adset_ids.length > 0) {
                url += `adset_ids=${JSON.stringify(adset_ids)}&`;
              }
              if (ad_ids && ad_ids.length) {
                url += `ad_ids=${JSON.stringify(ad_ids)}&`;
              }
              if (pageSize) {
                url += `pageSize=${pageSize}&`;
              }
              if (after) {
                url += `after=${after}&`;
              }
              if (before) {
                url += `before=${before}&`;
              }
              if (searchValue) {
                url += `search=${searchValue}&`;
              }
          
              // Remove the trailing "&" if it exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;
          
              return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }), 
        getAdsInsights: builder.query({
          query: ({ searchValue, adsAccountId, ads, start_date, date_preset, end_date }) => {
              let url = `/ads/${adsAccountId}/insights?`;
      
              if (ads && ads.length > 0) {
                  url += `ads=${encodeURIComponent(JSON.stringify(ads))}&`;
              }
              if (searchValue) {
                  url += `search=${searchValue}&`;
              }
              if (start_date) {
                  url += `start_date=${start_date}&`;
              }
              if (end_date) {
                  url += `end_date=${end_date}&`;
              }
              if (date_preset) {
                  url += `date_preset=${date_preset}&`;
              }
              // Remove the trailing "&" if it exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;
      
              return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),  
        getAllAdsInsights: builder.query({
          async queryFn({ searchValue, ads, start_date, date_preset, end_date }, _queryApi, _extraOptions, fetchWithBQ) {
              
              try{
                const requests = ads.map((ad) => {

                    const { account_id, ...adsWithoutAccountId } = ad; 
        
                    let url = `/ads/${account_id}/insights?`;
        
                    if (searchValue) {
                        url += `search=${searchValue}&`;
                    }
                    if (start_date) {
                        url += `start_date=${start_date}&`;
                    }
                    if (end_date) {
                        url += `end_date=${end_date}&`;
                    }
                    if (date_preset) {
                        url += `date_preset=${date_preset}&`;
                    }
                    if (adsWithoutAccountId) {
                        // sending the campaign object without the account_id
                        url += `ads=${encodeURIComponent(JSON.stringify([adsWithoutAccountId]))}&`; 
                    }
                    // Remove the trailing "&" if it exists
                    url = url.endsWith('&') ? url.slice(0, -1) : url;
        
                    return fetchWithBQ(url);
                });
                
                const results = await Promise.all(requests);
          
                // Handle errors
                const hasErrors = results.some((result) => result.error);
                if (hasErrors) {
                    return { error: { message: 'One or more requests failed.' } };
                }

                // Merging all data arrays into a single array
                const mergedData = results.flatMap((result) => result.data.data);
                return { data: mergedData };

              } catch (error) {
                return { error };
              } finally {
            }
          },

        }),   
        getAds: builder.query({
          query: ({ businessId, adsAccountId, adsIds }) => ({
              url: `/ads/get/?businessId=${businessId}&adsAccountId=${adsAccountId}&adsIds=${encodeURIComponent(JSON.stringify(adsIds))}`, 
          }),
          transformResponse: (response, meta, arg) => response.data,
          providesTags: ['ads'],
        }),                  

        getAdCreative: builder.query({
            query: ({ creativeId, adsAccountId}) => ({
                url: `/ads/adcreatives/${creativeId}?adsAccountId=${adsAccountId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads']
        }),
        getAdComparison: builder.query({
            query: ({ hashes }) => ({
                url: `/ads/comparison/?hashes=${encodeURIComponent(JSON.stringify(hashes))}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['ads'],
        }),
        updateAd: builder.mutation({
            query: ({ creativeId,payload }) => ({
                url: `/ads/${creativeId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['ads'],
        }),
        updateAdStatus: builder.mutation({
            query: ({ adId, payload }) => ({
                url: `/ads/update/status/${adId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        deleteAd: builder.mutation({
          query: ({ adId, adsAccountId })=> ({
            url: `/ads/${adId}?adsAccountId=${adsAccountId}`,
            method: 'DELETE',
          }),
          invalidatesTags: ['ads']
      }),
      getAdPreviews: builder.query({
        query: ({ adsAccountId, creative, ad_format }) => {

          let url = `/ads/${adsAccountId}/generatepreviews?`;
          
          if (ad_format && ad_format.length > 0) {
            url += `ad_format=${JSON.stringify(ad_format)}&`;
          }
          if (creative) {
            url += `creative=${creative}&`;
          }
      
          // Remove the trailing "&" if it exists
          url = url.endsWith('&') ? url.slice(0, -1) : url;
      
          return { url };
        },
        transformResponse: (response, meta, arg) => response.data,
        providesTags: ['ads'],
      }), 
      getAdspressoAllAds: builder.query({
        query: ({currentPage}) => ({
            url: `/adspresso/tracklist/adspresso/ads?page=${currentPage}`,
        }),
        transformResponse: (response, meta, arg) => response.data,
      }),
      getAdspressoAllAdsList: builder.query({
        query: ({ ids, adset }) => {

          const idsParam = ids ? encodeURIComponent(JSON.stringify(ids)) : null;
          const adsetParam = adset ? encodeURIComponent(JSON.stringify(adset)) : null;

          let url = "/ads/adspresso/list";
          if (idsParam && !adsetParam) {
            url += `?ids=${idsParam}`;
          } else if (adsetParam) {
            url += `?adset=${adsetParam}`;
          }
      
          return { url };
        },
        transformResponse: (response, meta, arg) => response.data,
      }), 
      getInstantFormsByPageId: builder.query({
        async queryFn({ pageIds }, _queryApi, _extraOptions, fetchWithBQ) {
          try {
            // Fetching all results for the list of pageIds
            const requests = pageIds.map((pageId) =>
              fetchWithBQ(`/ads/${pageId}/leadgen_forms`)
            );
            const results = await Promise.all(requests);
      
            // Handle errors
            const hasErrors = results.some((result) => result.error);
            if (hasErrors) {
              return { error: { message: 'One or more requests failed.' } };
            }
      
            // Combining all the data from the results
            const data = results.map((result) => result.data);
            return { data };
          } catch (error) {
            return { error };
          }
        },
        transformResponse: (response, meta, arg) => response.data.data.data,
        providesTags: ['ads'],
      }),
    }),
});

export const { 
  useCreateAdMutation, 
  useGetAdsListQuery, 
  useGetAdsInsightsQuery, 
  useGetAdsQuery, 
  useGetAdCreativeQuery, 
  useGetAdComparisonQuery, 
  useUpdateAdMutation, 
  useUpdateAdStatusMutation, 
  useDeleteAdMutation, 
  useGetAdPreviewsQuery ,
  useGetInstantFormsByPageIdQuery,
  useGetAdspressoAllAdsQuery,
  useGetAdspressoAllAdsListQuery,
  useGetAllAdsInsightsQuery,
} = adsApi;