import React, { useEffect, useState } from "react";
import SidebarWithHeader from 'components/SidebarWithHeader';
import {
    Box,
    Flex,
    Select,
    Text,
    Icon,
    Tooltip,
    Button,
    Stack
} from '@chakra-ui/react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdsTable from 'components/campaignTables/AdsTable';
import { MdRefresh } from 'react-icons/md';
import moment from 'moment';
import DateRangeSelector from "components/campaignTables/DateRangeSelector";


function ManageAds() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const adsAccountId = searchParams.get('adsAccountId');
    const adsetId = searchParams.get('adsetId');
    const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [datePreset, setDatePreset] = useState("");
    const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount);
    const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            if (adsAccountId) {
                setSelectedAdsAccountId(adsAccountId);
            } else {
                setSelectedAdsAccountId(activeAccountsData.data[0].adsAccountId);
            }
            setSelectedAccountData(activeAccountsData.data[0]);
        }
    }, [activeAccountsData, adsAccountId]);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
            setSelectedAccountData(account);
        }
    }, [selectedAdsAccountId, activeAccountsData]);

    const handleDateRangeChange = (startDate, endDate, datePreset) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setDatePreset(datePreset);
    };

    return (
        <>
            {hasActiveAdAccount ? (
                <SidebarWithHeader>
                    <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                        <Flex gap={'10px'}  align={'center'}>
                            <Text fontWeight={'bold'}>Ad accounts</Text>
                            <Box>
                                <Select
                                    value={selectedAdsAccountId}
                                    onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                                    size={'sm'}
                                    borderRadius={'md'}
                                >
                                    {activeAccountsData?.data?.map(account => (
                                        <option key={account._id} value={account.adsAccountId}>
                                            {`${account.adsAccountName} (${account.adsAccountId})`}
                                        </option>
                                    ))}
                                </Select>
                            </Box>
                            {adsAccountId || adsetId ? (
                                <Tooltip label='Reset' fontSize='xs' placement='top'>
                                    <Flex>
                                        <Icon
                                            cursor="pointer"
                                            boxSize={5}
                                            _hover={{
                                                color: 'gray.400'
                                            }}
                                            as={MdRefresh}
                                            onClick={() => {
                                                navigate('/manage-ads');
                                            }}
                                        />
                                    </Flex>
                                </Tooltip>
                            ) : null}
                        </Flex>
                        
                        <Flex align={'center'}>
                            <DateRangeSelector 
                                onDateRangeChange={handleDateRangeChange}
                                initialStartDate={startDate}
                                initialEndDate={endDate}
                            />
                        </Flex>
                    </Flex>

                    <Flex width={'full'} align={'center'} justify={'space-between'}>
                        <Flex flex={1} justify={'center'} borderWidth={1} bg={'gray.50'} >
                            <Link to={'/manage-campaigns'} justify={'center'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                                <Text  >Campaigns</Text>
                            </Link>
                        </Flex >
                        <Flex flex={1} justify={'center'} borderWidth={1} bg={'gray.50'}>
                            <Link to={'/manage-adsets'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                                <Text>Ad sets</Text>
                            </Link>
                        </Flex>
                        <Flex flex={1} justify={'center'} borderWidth={1} borderTopColor={'orange.600'} borderBottom={'none'}>
                            <Link style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                                <Text fontWeight={'bold'} color="orange.600"> Ads</Text>
                            </Link>
                        </Flex>
                    </Flex>

                    <Box p={'10px'} borderWidth={1} borderTop={0} pt={'20px'}>
                        <AdsTable
                            adsAccountData={selectedAccountData}
                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : ''}
                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : ''}
                            datePreset={datePreset}
                        />
                    </Box>
                </SidebarWithHeader>
            ) : (
                <SidebarWithHeader>
                    <Flex>
                        <Text>
                            Your Ad account is not set up yet. Please request an Ad account.
                        </Text>
                    </Flex>
                </SidebarWithHeader>
            )}
        </>
    );
}

export default ManageAds;
