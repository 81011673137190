import SidebarWithHeader from 'components/SidebarWithHeader';
import { useEffect, useState } from "react";

import {
    Box,
    Flex,
    Select,
    Text,
    Icon
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AdSetsTable from 'components/campaignTables/AdSetsTable';
import { MdRefresh } from 'react-icons/md';
import { useSelector } from "react-redux";

function ManageAdsetsForAdmin() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const adsAccountId = searchParams.get('adsAccountId');
    const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {

            if (adsAccountId) {
                setSelectedAdsAccountId(adsAccountId);
            } else {
                setSelectedAdsAccountId(activeAccountsData.data[0].adsAccountId);
            }
            setSelectedAccountData(activeAccountsData.data[0]);
        }
    }, [activeAccountsData, adsAccountId]);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
            setSelectedAccountData(account);
        }
    }, [selectedAdsAccountId, activeAccountsData]);

    // console.log(startDate, endDate);



    return (
        <SidebarWithHeader>
            <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                <Flex gap={'10px'} flex={1} align={'center'}>
                    <Text fontWeight={'bold'}>Ad accounts</Text>
                    <Box flex={0.5}>
                        <Select
                            value={selectedAdsAccountId}
                            onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                            size={'sm'}
                            borderRadius={'md'}
                        >
                            {activeAccountsData?.data?.map(account => (
                                <option key={account._id} value={account.adsAccountId}>
                                    {`${account.adsAccountName} (${account.adsAccountId})`}
                                </option>
                            ))}
                        </Select>
                    </Box>
                    {adsAccountId ? (
                        <Icon
                            cursor="pointer"
                            boxSize={5}
                            _hover={{
                                color: 'gray.400'
                            }}
                            as={MdRefresh}
                            onClick={() => {
                                navigate('/manage-adsets');
                            }}
                        />
                    ) : null}
                </Flex>

                <Flex justifyContent={'flex-end'} alignItems="center">
                    <Flex alignItems="center" gap="10px">
                        <Box >
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                                className="date-picker-input"
                                style={{ width: 'auto' }}
                            />
                        </Box>
                        <Box >
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                                className="date-picker-input"
                                style={{ width: 'auto', fontsize: '12px' }}
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
            <Flex width={'full'} align={'center'} justify={'space-between'} mb={'20px'}>
                <Flex flex={1} justify={'center'} borderWidth={1} bg={'gray.50'} >
                    <Link to={'/manage-campaigns'} justify={'center'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }} >
                        <Text >Campaigns</Text>
                    </Link>
                </Flex >
                <Flex flex={1} justify={'center'} borderWidth={1} borderTopColor={'orange.600'} borderBottom={'none'}>
                    <Link style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                        <Text fontWeight={'bold'} color="orange.600">Ad sets</Text>
                    </Link>
                </Flex>
                <Flex flex={1} justify={'center'} borderWidth={1} bg={'gray.50'}>
                    <Link to={'/manage-ads'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                        <Text> Ads</Text>
                    </Link>
                </Flex>
            </Flex>
            <AdSetsTable adsAccountId={selectedAdsAccountId} />
        </SidebarWithHeader >
    );
}

export default ManageAdsetsForAdmin;
