import {
    Box,
    Flex,
    Heading,
    Text,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    Input,
    useToast,
    Tooltip,
    Spinner,
    Th, Td, Table, Thead, Tbody, Tr
} from '@chakra-ui/react';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGetPublishStatusQuery, useUpdatePublishCampaignMutation } from 'store/publishTracking/trackingApi';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdArrowBack } from "react-icons/io";
import { TiArrowBack } from "react-icons/ti";
import SalesForm from 'components/campaignForms/AdSetForms/SalesForm';
import LeadForm from 'components/campaignForms/AdSetForms/LeadsForm';
import { useGetAdsetFormQuery } from 'store/campaigns/adsetFormApi';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { usePublishAdspressoCampaignMutation } from 'store/adspresso/adspressoCampaignApi';
import SingleCreativeAdupdate from 'components/adspresso/creativeTrackingForms/SingleCreativeAdupdate';
import CarouselCreativeAdupdate from 'components/adspresso/creativeTrackingForms/CarouselCreativeAdupdate';

export const PublishTracking = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedTracking, setSelectedTracking] = useState(null);
    const [editType, setEditType] = useState('');
    const [shouldRefetch, setShouldRefetch] = useState(true);
    const [previousErrors, setPreviousErrors] = useState(false);

    const { data: trackingData, refetch } = useGetPublishStatusQuery({ campaignId: id },
        { pollingInterval: shouldRefetch ? 10000 : null, skipPollingIfUnfocused: true }
    );

    let campaignType = null;
    let createCampaignTracking;

    //console.log({trackingData})

    if (trackingData) {

        const selectedAccountId = selectedTracking?.adsAccount?.id;
        createCampaignTracking = trackingData.find(tracking =>
            tracking.action === 'createCampaign' && tracking?.adsAccount?.id === selectedAccountId
        );

        if (createCampaignTracking) {
            campaignType = createCampaignTracking?.form?.objective;
        }
    }

    const [updatePublishCampaign] = useUpdatePublishCampaignMutation({});
    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });
    const [publishAdspressoCampaign, { isSuccess, isError }] = usePublishAdspressoCampaignMutation();

    useEffect(() => {
        if (trackingData) {
            const allSuccess = trackingData.every(tracking => tracking?.status === 'success');
            const hasError = trackingData.some(tracking => tracking?.status === 'error');
            const hasPending = trackingData.some(tracking => tracking?.status === 'pending');

            if (hasPending) {
                setShouldRefetch(true);
            } else if (hasError || allSuccess) {
                setShouldRefetch(false);
            }
        }
    }, [trackingData]);

    const handleEditClick = (tracking) => {
        setSelectedTracking(tracking);
        setEditType(tracking?.action);
        setIsEditOpen(true);
    };

    const groupedTrackingData = trackingData?.reduce((acc, tracking) => {
        const accountName = tracking?.adsAccount?.adsAccountName;
        if (!acc[accountName]) {
            acc[accountName] = [];
        }
        acc[accountName].push(tracking);
        return acc;
    }, {});

    // Formik setup for edit campaign
    const campaignFormik = useFormik({
        initialValues: {
            name: selectedTracking?.form?.name || '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {

            const { name } = values;

            const payload = {
                ...selectedTracking?.form,
                name: name,
            };

            updatePublishCampaign({
                trackingId: selectedTracking?._id,
                payload
            }).unwrap()
                .then(() => {
                    refetch();
                    toast({
                        position: "top-right",
                        title: "Campaign updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    setIsEditOpen(false);
                })
                .catch((error) => {
                    toast({
                        position: "top-right",
                        title: "Error updating campaign",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    console.error('Error updating campaign:', error);
                });
        },
    });

    const handleSaveSuccess = () => {
        refetch();
    };

    const handleRePublish = async () => {
        await publishAdspressoCampaign(id)
            .unwrap()
            .then((response) => {
                refetch();
                // setTimeout(() => {
                //     navigate(`/adspresso-campaigns/${id}`);
                //     //navigate(`/campaigns/${id}`);
                // }, 1000);
            })
    }

    useEffect(() => {
        if (isSuccess) {
            toast({
                position: "top-right",
                title: "Campaign Re-published",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            toast({
                position: "top-right",
                title: "Error Re-publishing campaign",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [isError])

    const TrackingTable = ({ title, actions, actionType }) => {
        const filteredActions = actions?.filter(tracking => tracking?.action === actionType);

        const getPath = () => {
            switch (actionType) {
                case 'createCampaign':
                    return '/manage-campaigns';
                case 'createAdset':
                    return '/manage-adsets';
                case 'createAd':
                    return '/manage-ads';
                default:
                    return '';
            }
        };

        const getTrackingId = (tracking) => {
            return tracking[actionType === 'createCampaign' ? 'campaign' :
                actionType === 'createAdset' ? 'adset' :
                    'ad']?.[`${actionType.split('create')[1].toLowerCase()}_id`];
        };

        return (
            <>
                {filteredActions.length > 0 && (
                    <>
                        <Tbody>
                            <Tr >
                                {/* <Td colSpan={3} border="none" textAlign="left">
                                    <Text fontSize={'14px'} fontWeight='bold'> {title}</Text>
                                </Td> */}
                                <Text fontSize={'14px'} fontWeight='bold' p={'5px'}> {title}</Text>
                            </Tr>
                            {filteredActions.map((tracking) => {
                                const adsAccountId = tracking?.adsAccount?.adsAccountId;
                                const trackingId = getTrackingId(tracking);
                                const path = getPath();
                                const name = encodeURIComponent(tracking?.form?.name);

                                return (
                                    <Tr key={tracking?._id} >
                                        <Td fontSize={'14px'} p={'5px'}>{tracking?.form?.name}</Td>
                                        <Td p={'5px'}>
                                            <Link
                                                to={`${path}?${actionType === 'createCampaign' ? 'campaignId' :
                                                    actionType === 'createAdset' ? 'adsetId' :
                                                        'adId'}=${trackingId}&adsAccountId=${adsAccountId}&name=${name}`}
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                <Text fontSize='14px' fontWeight='bold'>{trackingId}</Text>
                                            </Link>
                                        </Td>
                                        {tracking?.status === 'error' && !tracking?.updated ? (
                                            <Td textAlign={'center'} p={'5px'}>
                                                <Flex direction="column" alignItems="center">
                                                    <Flex gap={2} alignItems={'center'}>
                                                        <Box role='group' position={"relative"} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
                                                            <Tooltip
                                                                label={
                                                                    <Box fontSize="sm" >
                                                                        <Text fontWeight="bold">{tracking?.error?.error_user_title}</Text>
                                                                        <Text>{tracking?.error?.error_user_msg || tracking?.error?.message}</Text>
                                                                    </Box>
                                                                }
                                                                hasArrow
                                                                fontSize='md'>
                                                                <Text color='red.500' fontSize={'14px'}>{tracking?.status}</Text>
                                                            </Tooltip>
                                                        </Box>
                                                        <Tooltip label='' fontSize='xs'>
                                                            <Icon
                                                                _hover={{ color: "gray.500" }}
                                                                as={FiEdit2}
                                                                cursor={"pointer"}
                                                                onClick={() => handleEditClick(tracking)}
                                                            />
                                                        </Tooltip>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                        ) : (
                                            <Td textAlign={'center'} p={'5px'}>
                                                <Box display="inline-flex" alignItems="center" gap={2}>
                                                    {tracking?.updated ? (
                                                        <Text color={'green.500'} fontSize={'14px'}>updated</Text>
                                                    ) : (
                                                        <Text color={'green.500'} fontSize={'14px'}>{tracking?.status}</Text>
                                                    )}
                                                    {tracking.status === 'pending' ? <Spinner size="sm" /> : null}
                                                </Box>
                                            </Td>
                                        )}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </>
                )}
            </>
        );
    };

    const currentErrors = trackingData && trackingData.some(tracking => tracking?.status === 'error');

    useEffect(() => {
        if (trackingData) {
            setPreviousErrors(prev => currentErrors || prev); // Update the flag based on current tracking data
        }
    }, [trackingData]);

    return (
        <SidebarWithHeader>
            <Box >
                <Flex mb={'20px'} alignItems={'center'} gap={2}>
                    <Tooltip label="Back" fontSize="xs">
                        <Box>
                            <Icon
                                cursor="pointer"
                                boxSize={6}
                                as={TiArrowBack}
                                onClick={() => {
                                    navigate('/adspresso-campaigns');
                                }}
                            />
                        </Box>
                    </Tooltip>
                    <Heading as='h1' size='lg'>Publish Status</Heading>
                </Flex>

                {groupedTrackingData && Object.entries(groupedTrackingData).map(([accountName, actions]) => {

                    return (
                        <Box key={accountName} mb={'20px'} p={'20px'} borderWidth={1} borderRadius={'md'}>
                            <Flex justifyContent={'space-between'}>
                                <Heading as='h3' size='md' color={'orange.500'} mb={'10px'}>{accountName}</Heading>
                            </Flex>

                            <Table variant='simple' size={'md'} >
                                <Thead bg={'gray.50'} >
                                    <Tr>
                                        <Th width={"30%"} px={'5px'} py={'10px'}>Name</Th>
                                        <Th width={"30%"} px={'5px'} py={'10px'}>ID</Th>
                                        <Th width={"40%"} px={'5px'} py={'10px'} textAlign="center">Status</Th>
                                    </Tr>
                                </Thead>
                                <TrackingTable title="Campaign" actions={actions} actionType="createCampaign" />
                                <TrackingTable title="Ad Set" actions={actions} actionType="createAdset" />
                                <TrackingTable title="Ad" actions={actions} actionType="createAd" />
                            </Table>
                        </Box>
                    );
                })}

                {previousErrors && (
                    <Flex justifyContent={'flex-end'} mt={4}>
                        <Button colorScheme="orange" size={'sm'} onClick={handleRePublish}>
                            Re-Publish
                        </Button>
                    </Flex>
                )}


                {/* Edit forms */}
                {isEditOpen && (
                    <>
                        {editType === 'createCampaign' && (
                            <Modal
                                size={"2xl"}
                                isCentered={true}
                                isOpen={isEditOpen}
                                onClose={() => setIsEditOpen(false)}
                            >
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Edit Campaign</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <form onSubmit={campaignFormik.handleSubmit}>
                                            <Box width="100%" maxWidth="600px" bg="gray.50" p={4} borderRadius="md">
                                                <Flex direction="column">
                                                    <Box mb={4}>
                                                        <Flex align="center">
                                                            <Text mb={2}>
                                                                Campaign Name
                                                            </Text>
                                                        </Flex>
                                                        <Input
                                                            placeholder="Enter Campaign Name"
                                                            bg="#fff"
                                                            name="name"
                                                            value={campaignFormik.values.name}
                                                            onChange={campaignFormik.handleChange}
                                                            onBlur={campaignFormik.handleBlur}
                                                        />
                                                        {campaignFormik.touched.name && campaignFormik.errors.name && (
                                                            <Text fontSize={'sm'} color="red.500">{campaignFormik.errors.name}</Text>
                                                        )}
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Flex mt={4}>
                                                <Button
                                                    colorScheme="orange"
                                                    isLoading={false}
                                                    isDisabled={!campaignFormik.isValid}
                                                    type="submit"
                                                    size={'sm'}
                                                    mb={'10px'}
                                                >
                                                    Save
                                                </Button>
                                            </Flex>
                                        </form>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        )}
                        {editType === 'createAdset' && (
                            <Modal
                                size={"2xl"}
                                isCentered={true}
                                isOpen={isEditOpen}
                                scrollBehavior={"inside"}
                                onClose={() => setIsEditOpen(false)}
                            >
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>
                                        <Text >Edit Ad Set</Text>
                                        {selectedTracking?.error && (
                                            <>
                                                <Text fontWeight="normal" fontSize={'14px'} color='red.500'>{selectedTracking?.error?.error_user_title}</Text>
                                                <Flex alignItems={'center'} gap={'5px'}>
                                                    <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                                                    <Text fontSize={'12px'} fontWeight="normal" >{selectedTracking?.error?.error_user_msg}</Text>
                                                </Flex>
                                            </>
                                        )}
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        {campaignType === 'OUTCOME_SALES' && isLoadingSales ? (
                                            <Spinner size={'sm'} />
                                        ) : (
                                            campaignType === 'OUTCOME_SALES' && dataSales && selectedTracking && (
                                                <SalesForm
                                                    data={dataSales.data}
                                                    adsetsListData={selectedTracking}
                                                    campaignData={createCampaignTracking}
                                                    isEditing={"isEditing"}
                                                    selectedAdset={selectedTracking?.adset?.id}
                                                    onClose={() => setIsEditOpen(false)}
                                                    onEditModalClose={() => setIsEditOpen(false)}
                                                    onSaveSuccess={handleSaveSuccess}
                                                    context={'trackEdit'}
                                                />
                                            )
                                        )}
                                        {campaignType === 'OUTCOME_LEADS' && isLoadingLeads ? (
                                            <Spinner size={'sm'} />
                                        ) : (
                                            campaignType === 'OUTCOME_LEADS' && dataLeads && selectedTracking && (
                                                <LeadForm
                                                    data={dataLeads.data}
                                                    adsetsListData={selectedTracking}
                                                    campaignData={createCampaignTracking}
                                                    selectedAdset={selectedTracking?.adset?.id}
                                                    onEditModalClose={() => setIsEditOpen(false)}
                                                    isEditing={"isEditing"}
                                                    onClose={() => setIsEditOpen(false)}
                                                    onSaveSuccess={handleSaveSuccess}
                                                    context={'trackEdit'}
                                                />
                                            )
                                        )}
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        )}
                        {editType === 'createAd' && (
                            <Modal
                                size={"2xl"}
                                isCentered={true}
                                isOpen={isEditOpen}
                                onClose={() => setIsEditOpen(false)}
                                scrollBehavior={"inside"}
                            >
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>
                                        <Text >Edit Ad</Text>
                                        {selectedTracking?.error && (
                                            <>
                                                <Text fontWeight="normal" fontSize={'14px'} color='red.500'>{selectedTracking?.error?.error_user_title}</Text>
                                                <Flex alignItems={'center'} gap={'5px'}>
                                                    <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                                                    <Text fontSize={'12px'} fontWeight="normal" >{selectedTracking?.error?.error_user_msg || selectedTracking?.error?.message}</Text>
                                                </Flex>
                                            </>
                                        )}
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        {Array.isArray(selectedTracking?.form?.adcreative) && selectedTracking?.form?.adcreative.length > 0 ? (
                                            <CarouselCreativeAdupdate
                                                trackingData={selectedTracking}
                                                onClose={() => setIsEditOpen(false)}
                                                onSaveSuccess={handleSaveSuccess}
                                            />
                                        ) : (
                                            <SingleCreativeAdupdate
                                                trackingData={selectedTracking}
                                                onClose={() => setIsEditOpen(false)}
                                                onSaveSuccess={handleSaveSuccess}
                                            />
                                        )}
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        )}
                    </>
                )}
            </Box>
        </SidebarWithHeader>
    );
};

