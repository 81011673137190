import { AddIcon, ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    Select,
    Spinner,
    Text,
    Textarea,
    Tooltip,
    useToast,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    Switch,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Radio, RadioGroup, Table, Thead, Tbody, Tr, Th, Td,
} from '@chakra-ui/react';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useGetAdCreativeQuery, useUpdateAdMutation, useGetInstantFormsByPageIdQuery } from 'store/campaigns/adsApi';
import * as Yup from 'yup';
import UrlParametersUpdate from 'components/adspresso/UrlParametersUpdate';
import PhoneInput from 'react-phone-input-2';
import InstagramAccountUpdateSelector from 'components/adspresso/InstagramAccountUpdateSelector';
// import AdPreviewCaro from './AdPreviewCaro';
import BuildUrlParamsUpdate from 'components/adspresso/BuildUrlParamsUpdate';
import BuildUrlParamCarousel from 'components/adspresso/BuildUrlParamCarousel';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Ad name is required'),
    cpa: Yup.number()
        .typeError('must be a number')
        .positive('must be a positive number'),
    creative: Yup.object().shape({
        name: Yup.string().required('Creative name is required'),
        object_story_spec: Yup.object().shape({
            link_data: Yup.object().shape({
                destination_type: Yup.string(),
                link: Yup.string()
                    .when('destination_type', {
                        is: (value) => value === 'WEBSITE',
                        then: () => Yup.string()
                            .required('URL is required'),
                        otherwise: () => Yup.string().notRequired(),
                    }),
                call_to_action: Yup.object().shape({
                    type: Yup.string().required('Call to action type is required'),
                    value: Yup.object().shape({
                        destinationType: Yup.string(),
                        link: Yup.string()
                            .when('destinationType', {
                                is: (value) => value === 'PHONE_CALL',
                                then: () => Yup.string()
                                    .transform((value) => value.slice(2)) // Remove the first two digits (e.g., country code like "91")
                                    .matches(/^[0-9]{10,15}$/, 'Phone number must be at least 10 digits') // Ensure remaining digits are at least 10 digits
                                    .required('Phone number is required'), // Ensure phone number is provided
                                otherwise: () => Yup.string().notRequired(),
                            }),
                    }),
                }),
                child_attachments: Yup.array().of(
                    Yup.object().shape({
                        link: Yup.string().required('URL is required'),
                        picture: Yup.string().required('Creative is required'),
                        call_to_action: Yup.object().shape({
                            type: Yup.string().required('Call to action type is required'),
                            value: Yup.object().shape({
                                destinationType: Yup.string(),
                                link: Yup.string()
                                    .when('destinationType', {
                                        is: (value) => value === 'PHONE_CALL',
                                        then: () => Yup.string()
                                            .transform((value) => {
                                                if (typeof value === 'string') {
                                                    return value.replace(/^tel:\+?/, ''); // Removes "tel:" and "+" if present
                                                }
                                                return value;
                                            })
                                            .matches(/^[0-9]{10,15}$/, 'Phone number must be between 10 and 15 digits')
                                            .required('Phone number is required'),
                                        otherwise: () => Yup.string().notRequired(),
                                    }),

                            }),
                        }),
                        //description: Yup.string().required('Description is required'),
                    })
                ).min(1, 'At least one creative is required'),
            }),
            page_id: Yup.string().required('Facebook Page is required'),
        }),
    }),
});

const AcrossCarouselAdUpdateForm = ({ adData, onClose, onSuccess, call_to_action_options, call_to_action_options_instantForms }) => {
    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [isUrlModalOpen, setUrlModalOpen] = useState(false);
    const [isSingleUrlModalOpen, setSingleUrlModalOpen] = useState(false);
    const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);
    const [selectedCreativeIndex, setSelectedCreativeIndex] = useState(null);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [isEnabled, setIsEnabled] = useState(true);
    const [isMediaTypeSelectionOpen, setIsMediaTypeSelectionOpen] = useState(false);

    const adsAccountData = {
        account_id: adData?.account_id,
        name: adData?.account_name,
    }

    const { data: adCreative, refetch, isLoading } = useGetAdCreativeQuery(
        {
            creativeId: adData?.id,
            adsAccountId: adData?.account_id
        },
        { skip: !adData?.id }
    );

    const [updateAd] = useUpdateAdMutation();
    const pageId = adData?.adset?.promoted_object?.page_id;

    const formattedPageIds = Array.isArray(pageId) ? pageId : [pageId];
  
    const { data: instantForms, isLoading: isInstantFormsLoading} = useGetInstantFormsByPageIdQuery(
      { pageIds: formattedPageIds },
      { skip: !formattedPageIds.length || adData?.adset?.destination_type !== 'ON_AD' }
    );

    let pageIdToSend = null;
    if (
        adCreative?.campaign?.objective === "OUTCOME_LEADS" &&
        adCreative?.adset?.destination_type === "PHONE_CALL" &&
        adCreative?.adset?.promoted_object?.page_id
    ) {
        pageIdToSend = adCreative.adset.promoted_object.page_id;
    }

    const formik = useFormik({
        initialValues: {
            name: adData?.name || "",
            hash: adData?.hash || '',
            cpa: adData?.cpa || '',
            page_backed_insta_id: '',
            creative: {
                name: adCreative?.creative?.name || "",
                url_tags: adCreative?.creative?.url_tags || '',
                object_story_spec: {
                    link_data: {
                        message: adCreative?.creative?.object_story_spec?.link_data?.message || "",
                        link: adCreative?.creative?.object_story_spec?.link_data?.link || "",
                        destination_type: adCreative?.adset?.destination_type ? adCreative?.adset?.destination_type : '',
                        call_to_action: {
                            type: adCreative?.creative?.object_story_spec?.link_data?.call_to_action?.type || "SHOP_NOW",
                            value: {
                                link: adData?.adset?.destination_type === 'ON_AD' ? adCreative?.creative?.object_story_spec?.link_data?.call_to_action?.value?.lead_gen_form_id : adCreative?.creative?.object_story_spec?.link_data?.call_to_action?.value?.link,
                                destinationType: adCreative?.adset?.destination_type ? adCreative?.adset?.destination_type : '',
                            }
                        },
                        child_attachments: adCreative?.creative?.object_story_spec?.link_data?.child_attachments || [
                            {
                                link: "", name: "", description: "", image_hash: "", video_id: "", picture: "", hash: "",
                                call_to_action: {
                                    type: "",
                                    value: {
                                        link: ""
                                    }
                                }
                            },
                        ],
                    },
                    page_id: adCreative?.creative?.object_story_spec?.page_id || "",
                    instagram_actor_id: adCreative?.creative?.object_story_spec?.instagram_actor_id || '',
                },
            },
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting, resetForm }) => {

            const formatPhoneNumber = (phoneNumber) => {
                if (!phoneNumber.startsWith('tel:+')) {
                    return `tel:+${phoneNumber}`;
                }
                return phoneNumber;
            };

            // Function to check if creative has changed
            const isCreativeChanged = () => {
                const original = adCreative?.creative || {};
                const updated = values.creative || {};

                return (
                    original.name !== updated.name ||
                    original.url_tags !== updated.url_tags ||
                    original.object_story_spec?.link_data?.message !== updated.object_story_spec?.link_data?.message ||
                    original.object_story_spec?.link_data?.link !== updated.object_story_spec?.link_data?.link ||
                    original.object_story_spec?.link_data?.call_to_action?.type !== updated.object_story_spec?.link_data?.call_to_action?.type ||
                    original.object_story_spec?.link_data?.call_to_action?.value?.link !== updated.object_story_spec?.link_data?.call_to_action?.value?.link ||
                    original.object_story_spec?.link_data?.child_attachments !== updated.object_story_spec?.link_data?.child_attachments ||
                    original.object_story_spec?.page_id !== updated.object_story_spec?.page_id ||
                    original.object_story_spec?.instagram_actor_id !== updated.object_story_spec?.instagram_actor_id
                );
            };

            const hasCreativeChanged = isCreativeChanged();

            const payload = {
                name: values.name,
                hash: values.hash,
                ...(typeof values.cpa === 'string' && values.cpa.trim() && { cpa: values.cpa }),
                ...(hasCreativeChanged && {
                    creative: {
                        name: values.creative.name,
                        ...(values.creative?.url_tags?.trim() && { url_tags: values.creative.url_tags }),
                        object_story_spec: {
                            link_data: {
                                ...(values.creative.object_story_spec.link_data.message?.trim() && {
                                    message: values.creative.object_story_spec.link_data.message
                                }),
                                link: adData?.adset?.destination_type === 'ON_AD'
                                    ? "http://fb.me/"
                                        : (values.creative.object_story_spec.link_data.link
                                            ? values.creative.object_story_spec.link_data.link
                                            : `https://facebook.com/${values.creative.object_story_spec.page_id}/`),
                                call_to_action: {
                                        type: values.creative.object_story_spec.link_data.call_to_action.type, 
                                            value: adData?.adset?.destination_type === 'ON_AD'
                                                ? {
                                                    lead_gen_form_id: values.creative.object_story_spec.link_data.call_to_action?.value?.link
                                                }
                                                : ( values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW'
                                                    ? {
                                                        link: values.creative.object_story_spec.link_data.call_to_action?.value?.link
                                                            ? formatPhoneNumber(values.creative.object_story_spec.link_data.call_to_action.value.link)
                                                            : undefined
                                                    }  : {})
                                },
                                child_attachments: values.creative.object_story_spec.link_data.child_attachments
                                    .map(child => {
                                        const { image_hash, id, call_to_action, ...rest } = child;

                                        const cleanedRest = Object.fromEntries(
                                            Object.entries(rest).filter(([key, value]) => value && value !== "")
                                        );

                                        if (values.creative.object_story_spec.link_data.call_to_action?.type === 'CALL_NOW') {
                                            return {
                                                ...cleanedRest,
                                                call_to_action: {
                                                    type: values.creative.object_story_spec.link_data.call_to_action?.type,
                                                    value: {
                                                        link: call_to_action?.value?.link
                                                            ? formatPhoneNumber(call_to_action.value.link)
                                                            : undefined
                                                    }
                                                }
                                            };
                                        }

                                        return {
                                            ...cleanedRest,
                                            call_to_action: {
                                                type: values.creative.object_story_spec.link_data.call_to_action?.type,
                                            }
                                        };
                                    }),
                            },
                            page_id: values.creative.object_story_spec.page_id,
                            ...(values.creative.object_story_spec.instagram_actor_id?.trim() && {
                                instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                            }),
                        },
                        degrees_of_freedom_spec: {
                            creative_features_spec: {
                                standard_enhancements: {
                                    enroll_status: "OPT_OUT"
                                }
                            }
                        },
                    },
                }),
                adsAccount: {
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                },
            };

            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    onSuccess();
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        description: error?.data?.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    resetForm();
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = (mediaType, index) => {
        setSelectedMedia(mediaType);
        setSelectedCreativeIndex(index);
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            // Update the specific creative in the Formik state
            formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', formik.values.creative.object_story_spec.link_data.child_attachments.map((creative, index) => {
                if (index === selectedCreativeIndex) {
                    return {
                        ...creative,
                        ...(selectedMedia === 'video' && {
                            video_id: selectedFile?.creativeId,
                            hash: selectedFile?.hash,
                            picture: selectedFile?.picture,
                        }),
                        ...(selectedMedia === 'image' && {
                            image_hash: selectedFile?.creativeId,
                            hash: selectedFile?.hash,
                            picture: selectedFile?.url,
                        }),
                    };
                }
                return creative;
            }));
        }
        setIsDriveModalOpen(false);
        setSelectedCreativeIndex(null);
    };

    const handleAddCreativeClick = () => {
        setIsMediaTypeSelectionOpen(!isMediaTypeSelectionOpen);
    };

    const handleMediaTypeSelection = (type) => {
        setSelectedMedia(type);
        setIsMediaTypeSelectionOpen(false);

        const newAttachment = {
            link: "",
            name: "",
            description: "",
            picture: "",
            id: "",
            call_to_action: {
                type: 'SHOP_NOW',
                value: {
                    link: ''
                }
            }
        };

        const pageId = formik.values.creative.object_story_spec.page_id;

        if (formik.values.creative.object_story_spec.link_data.call_to_action.type === 'CALL_NOW') {
            newAttachment.call_to_action = {
                type: 'CALL_NOW',
                value: {
                    link: '',
                }
            };
            newAttachment.link = pageId ? `https://facebook.com/${pageId}/` : '';
        }

        if (type === 'image') {
            newAttachment.image_hash = type;
            newAttachment.id = type;
        } else if (type === 'video') {
            newAttachment.video_id = type;
            newAttachment.id = type;
        }

        formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', [
            ...(formik.values.creative.object_story_spec.link_data.child_attachments || []),
            newAttachment
        ]);
    };


    if (isLoading) {
        return  <Flex justify="center" align="center" height="60vh">
                    <Spinner size="md" />
                </Flex>
    }

    const handleUrlChange = (constructedUrl) => {
        formik.setFieldValue('creative.object_story_spec.link_data.link', constructedUrl);
    };

    const handleSingleUrlChange = (constructedUrl, fileIndex) => {

        // Make a copy of the current child_attachments array
        const updatedChildAttachments = [...formik.values.creative.object_story_spec.link_data.child_attachments];

        // Update the 'link' of the specific fileIndex
        updatedChildAttachments[fileIndex] = {
            ...updatedChildAttachments[fileIndex],
            link: constructedUrl,
        };

        // Now use Formik's setFieldValue to update the form field
        formik.setFieldValue(
            "creative.object_story_spec.link_data.child_attachments",
            updatedChildAttachments
        );
    };

    const handleUrlParamChange = (constructedUrl) => {
        formik.setFieldValue('creative.url_tags', constructedUrl);
    };

    const filteredInstantForms = instantForms?.length > 0 && instantForms
        .map((formResponse) => formResponse?.data?.data) // Get the 'data' array from each formResponse
        .flat() // Flatten the array to get all forms in one array
        .filter((form) => form?.page_id === pageId); 

    return (
        <form onSubmit={formik.handleSubmit}>
            {/* <Flex gap={4}>  */}
            {/* <Flex direction={'column'} maxH={'1050px'} overflowY={'auto'}> */}
            <Flex direction={'column'}>
                <Flex direction={'column'} gap={'20px'} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    {/* Ad Name Section */}
                    <Flex direction={'column'} gap={'10px'}>
                        <Text fontWeight="bold">Ad name</Text>
                        <Input
                            name="name"
                            placeholder="Enter your ad name here..."
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={'10px'}>
                        <Text fontWeight="bold">Facebook Page</Text>
                        <FaceBookPageSelector
                            adsAccount={adsAccountData}
                            selectedPageId={formik.values.creative.object_story_spec.page_id}
                            pageIdToSend={pageIdToSend}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.page_id', pageId);

                                // Reset the Instagram actor ID to an empty string
                                formik.setFieldValue('creative.object_story_spec.instagram_actor_id', '');
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.page_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                        )}
                    </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Instagram accounts</Text>
                            <InstagramAccountUpdateSelector
                                pageID={formik.values.creative?.object_story_spec?.page_id}
                                adsAccount={adsAccountData}
                                selectedInstPageId={formik.values.creative?.object_story_spec?.instagram_actor_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('creative.object_story_spec.instagram_actor_id', pageId);
                                }}
                                onPageBack={(pageId) => {
                                    formik.setFieldValue('page_backed_insta_id', pageId);
                                }}
                            />
                            {formik.errors.creative?.object_story_spec?.instagram_actor_id && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.instagram_actor_id}</Text>
                            )}
                        </Flex>
                </Flex>
                <Flex direction={'column'} gap={'15px'} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    {/* Creative Attachments Section */}
                    {formik.values.creative.object_story_spec.link_data.child_attachments.map((creative, index) => {

                        return (
                            <Flex direction={'column'} key={index} borderWidth={1} p={4} borderRadius="md" gap={'20px'}>
                                <Flex direction={'column'} gap={'10px'}>
                                    <Flex justifyContent="space-between" alignItems="center" >
                                        <Text fontWeight="bold">Headline</Text>
                                        <Tooltip label="Delete" fontSize="xs">
                                            <Box>
                                                <Icon
                                                    color={'red.500'}
                                                    _hover={{ color: 'gray.400' }}
                                                    as={MdDelete}
                                                    cursor="pointer"
                                                    boxSize={5}
                                                    onClick={() => {
                                                        const updatedAttachments = formik.values.creative.object_story_spec.link_data.child_attachments.filter((_, i) => i !== index);
                                                        formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', updatedAttachments);
                                                    }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Flex>
                                    <Input
                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.name`}
                                        placeholder="Enter a name"
                                        size='sm'
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={creative.name}
                                    />
                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name}</Text>
                                    )}
                                </Flex>

                                <Flex direction="column" gap={'20px'}>
                                    {/* Description Section */}
                                    <Flex direction="column" gap={'10px'} >
                                        <Text fontWeight="bold">Description</Text>
                                        <Input
                                            name={`creative.object_story_spec.link_data.child_attachments.${index}.description`}
                                            placeholder="Enter a description"
                                            size='sm'
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={creative.description}
                                        />
                                        {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description}</Text>
                                        )}
                                    </Flex>

                                    {adData?.adset?.destination_type === 'PHONE_CALL' ?
                                            (
                                                <>
                                                    {/* <Flex direction="column" gap={1}>
                                                        <Text fontWeight="bold">Call to Action</Text>

                                                        <Select
                                                            name={`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.type`}
                                                            bg="#fff"
                                                            size="sm"
                                                            borderRadius="md"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={creative?.call_to_action?.type}
                                                            isDisabled
                                                        >
                                                            <option value="CALL_NOW">Call Now</option>
                                                        </Select>
                                                    </Flex> */}

                                                    <Flex direction="column" gap={1}>
                                                        <Text fontWeight="bold">Phone Number</Text>
                                                        <PhoneInput
                                                            name={`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.value.link`}
                                                            value={creative?.call_to_action?.value?.link}
                                                            onChange={(value) => formik.setFieldValue(`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.value.link`, value)}
                                                            country={'in'}
                                                        />
                                                    </Flex>

                                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.call_to_action?.value?.link && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.call_to_action?.value?.link && (
                                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.call_to_action?.value?.link}</Text>
                                                )}
                                            </>
                                        ) : adData?.adset?.destination_type === 'WEBSITE' ? (
                                            <>
                                            {/* <Flex direction="column" gap={1}>
                                                <Text fontWeight="bold">Call to Action</Text>

                                                <Select
                                                    name={`creative.object_story_spec.link_data.child_attachments.${index}.call_to_action.type`}
                                                    bg="#fff"
                                                    size="sm"
                                                    borderRadius="md"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={creative?.call_to_action?.type}
                                                >
                                                   {call_to_action_options?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                                </Select>
                                            </Flex> */}

                                            <Flex direction="column" gap={'10px'}>
                                                <Text fontWeight="bold">Website URL</Text>
                                                <Input
                                                    name={`creative.object_story_spec.link_data.child_attachments.${index}.link`}
                                                    placeholder="Enter a URL"
                                                    size='sm'
                                                    borderRadius="md"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={creative?.link}
                                                />
                                                 <Flex mt={2}>
                                                    <Button onClick={() => {
                                                        setSingleUrlModalOpen(true)
                                                        setSelectedFileIndex(index)
                                                    }} 
                                                    variant="link">
                                                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                                    </Button>
                                                </Flex>
                                                {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && (
                                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link}</Text>
                                                )}
                                            </Flex>
                                            </>
                                        ):null}
                                </Flex>


                                    <Flex direction={'column'} gap={'10px'}>
                                        <Text fontWeight="bold">Creative</Text>
                                        <Flex direction="row" gap={'10px'} alignItems={'center'}>
                                            {/* Conditionally render either an image or the add icon */}
                                            {((creative?.id === 'image' && !creative?.picture) || (creative?.id === 'video' && !creative?.picture)) ? (
                                                <AddIcon />
                                            ) : (
                                                <img
                                                    src={creative?.video_id ? creative?.picture : creative?.picture || ''}
                                                    alt={creative?.name}
                                                    style={{ width: '100px', height: '100px', objectFit: 'cover', border: '0.5px solid grey', borderRadius: '5px' }}
                                                />
                                            )}

                                            <Button
                                                bg={'gray.50'}
                                                size="xs"
                                                borderWidth={1}
                                                onClick={() => handleOpenDriveModal(creative?.video_id ? 'video' : 'image', index)}
                                            >
                                                {creative?.id === 'image' && !creative?.picture ?
                                                    "Add Image" :
                                                    creative?.id === 'video' && !creative?.picture ?
                                                        "Add Video" :
                                                        (creative?.video_id ? "Change Video" : "Change Image")
                                                }
                                            </Button>
                                        </Flex>
                                        {formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.picture && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.picture}</Text>
                                        )}
                                    </Flex>

                                </Flex>
                            )
                        })}

                        <Flex justifyContent="flex-end" >
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    onClick={handleAddCreativeClick}
                                    colorScheme='orange'
                                    _expanded={{ bg: 'orange.600' }}
                                    rightIcon={<ChevronDownIcon />}
                                    size='sm'
                                    sx={{ minWidth: "200px" }}
                                >
                                    {'Add Creative'}
                                </MenuButton>
                                <MenuList sx={{
                                    minWidth: "160px",
                                    position: "absolute",
                                    left: 10
                                }}>
                                    <MenuItem
                                        onClick={() => handleMediaTypeSelection('image')}
                                        fontSize="12px"
                                    >
                                        Add image creative
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMediaTypeSelection('video')}
                                        fontSize="12px"
                                    >
                                        Add video creative
                                    </MenuItem>
                                </MenuList>

                            </Menu>
                        </Flex>

                    </Flex>
                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Box>
                            <Flex direction={'column'} gap={'20px'}>
                                {/* Primary Text Section */}
                                <Flex direction="column" gap={'10px'}>
                                    <Text fontWeight="bold">Primary Text</Text>
                                    <Textarea
                                        name="creative.object_story_spec.link_data.message"
                                        placeholder="Enter a text"
                                        bg="#fff"
                                        size='sm'
                                        borderRadius="md"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.creative.object_story_spec.link_data.message}
                                    />
                                    {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                                    )}
                                </Flex>

                                <Flex direction="column" gap={'10px'}>
                                    <Text fontWeight="bold">Call to Action</Text>

                                    {adData?.adset?.destination_type === 'PHONE_CALL' ? (
                                        <Select
                                            name="creative.object_story_spec.link_data.call_to_action.type"
                                            bg="#fff"
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                            isDisabled
                                        >
                                            <option value="CALL_NOW">Call Now</option>
                                        </Select>
                                    ) : adData?.adset?.destination_type === 'WEBSITE' ? (
                                        <Select
                                            name="creative.object_story_spec.link_data.call_to_action.type"
                                            bg="#fff"
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                        >
                                            {call_to_action_options?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    ): (
                                        <Select
                                            name="creative.object_story_spec.link_data.call_to_action.type"
                                            bg="#fff"
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                        >
                                            {call_to_action_options_instantForms?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    )}

                                    {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type &&
                                        formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                            <Text fontSize="12px" color="red.500">
                                                {formik.errors.creative.object_story_spec.link_data.call_to_action.type}
                                            </Text>
                                        )}
                                </Flex>
                                {adData?.adset?.destination_type === 'PHONE_CALL' ? (
                                    <Flex direction="column" gap={'10px'}>
                                        <Text fontWeight="bold">Phone Number</Text>
                                        <PhoneInput
                                            name="creative.object_story_spec.link_data.call_to_action.value.link"
                                            value={formik.values.creative.object_story_spec.link_data.call_to_action?.value?.link}
                                            onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)}
                                            country={'in'}
                                        />
                                        {formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value?.link && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.value?.link}</Text>
                                        )}
                                    </Flex>
                                 ) : adData?.adset?.destination_type === 'WEBSITE' ? (
                                    <Flex direction="column" gap={'10px'}>
                                        <Text fontWeight="bold">Website URL</Text>
                                        <Input
                                            name="creative.object_story_spec.link_data.link"
                                            placeholder="Enter a webiste url"
                                            bg="#fff"
                                            size='sm'
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.creative.object_story_spec.link_data.link}
                                        />
                                        {formik.errors.creative?.object_story_spec?.link_data?.link && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                                        )}
                                    </Flex>
                                 ): adData?.adset?.destination_type === 'ON_AD' && adData?.campaign?.objective === 'OUTCOME_LEADS' ? (   
                                    <>
                                    <Flex alignItems="center" gap={2} mt={2}>
                                        <Text fontWeight="bold">Instant form</Text>
                                        <Tooltip 
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                            <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                        >
                                        <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
        
                                    {/* Table Layout */}
                                    {filteredInstantForms?.length > 0 ? (
                                        <RadioGroup onChange={(value) => formik.setFieldValue('creative.object_story_spec.link_data.call_to_action.value.link', value)} value={formik.values.creative.object_story_spec.link_data.call_to_action.value.link} colorScheme="orange">
                                        <Table variant="simple">
                                            <Thead>
                                            <Tr>
                                                <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                                                <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                                            </Tr>
                                            </Thead>
                                            <Tbody>
                                            {filteredInstantForms?.map((form) => (
                                                <Tr key={form?.id}  _hover={{ backgroundColor: '#F9F9F9' }} >
                                                <Td>
                                                    <Flex alignItems="center" gap={2}>
                                                    <Radio value={form?.id} />
                                                    <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                                        {form?.name}
                                                    </Text>
                                                    </Flex>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={'12px'}>
                                                    {new Date(form?.created_time).getFullYear()}-
                                                    {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                                    {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                                    </Text>
                                                </Td>
                                                </Tr>
                                            ))}
                                            </Tbody>
                                        </Table>
                                        </RadioGroup>
                                    ) : (
                                        <Text fontSize="14px" color="gray.500">No form data available</Text>
                                    )}
                                    </>
                                ) : null}
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Text fontWeight={'bold'}>Tracking</Text>
                        <Flex direction="column" gap={1}>
                            <Flex alignItems={'center'}>
                                <Text mb={2} fontWeight="bold">
                                        URL parameters{" "}
                                    {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                                    <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                                </Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text >
                                                Add parameters to the end of your website URL to track where your visitors are coming from.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                </Tooltip>
                            </Flex>
                            <Input
                                name="creative.url_tags"
                                placeholder="key1=value1&key2=value2"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.creative.url_tags}
                                onChange={formik.handleChange}
                            />
                            <Flex mt={2}>
                                <Button onClick={() => {
                                    setUrlParamModalOpen(true)
                                }} 
                                variant="link">
                                    <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                </Button>
                            </Flex>
                            {formik.touched.creative?.url_tags && formik.errors.creative?.url_tags && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.url_tags}</Text>
                            )}
                        </Flex>
                    </Flex>
                    
                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Flex direction="column" gap={'10px'}>
                            <Text fontWeight="bold">CPA</Text>
                            <Input
                                name="cpa"
                                placeholder="Enter CPA"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.cpa}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.cpa && formik.errors.cpa && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.cpa}</Text>
                            )}
                        </Flex>
                    </Flex>

                    <Flex>
                        <Button
                            size="sm"
                            colorScheme="orange"
                            isLoading={formik.isSubmitting}
                            type="submit"
                            mb={'20px'}
                        >
                            Save
                        </Button>
                    </Flex>
                </Flex>

                {/* <Box flex={0.6} px={4} borderRadius={'md'} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)">
                    <Flex py={2} gap={2}>
                        <Switch isChecked={isEnabled} onChange={() => setIsEnabled(prevState => !prevState) } />
                        <Text fontWeight={'bold'} fontSize={'14px'}>Ad preview  </Text>  
                    </Flex>
                    <Divider />
                    {isEnabled && 
                        <AdPreviewCaro adCreative={adCreative} file={formik?.values} adsAccountData={adsAccountData} />
                    }
                    <Flex mt={2} alignItems={'center'}>
                        <Text fontSize={'12px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="12px" p="5px">
                                <Text>
                                    Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                </Text>
                                </Box>
                            }
                            fontSize="md"
                            >
                            <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                        </Tooltip>
                    </Flex>
                </Box> */}
            {/* </Flex> */}

            <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BuildUrlParamsUpdate link={formik.values.creative.object_story_spec.link_data} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange}/>
                </ModalBody>
                </ModalContent>
            </Modal> 


            <Modal isOpen={isSingleUrlModalOpen} onClose={() => setSingleUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BuildUrlParamCarousel link={formik.values.creative.object_story_spec.link_data.child_attachments} onClose={() => setSingleUrlModalOpen(false)} onUrlChange={handleSingleUrlChange} selectedFileIndex={selectedFileIndex}/>
                </ModalBody>
                </ModalContent>
            </Modal>  

            <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <UrlParametersUpdate link={formik.values.creative} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange}/>
                </ModalBody>
                </ModalContent>
            </Modal> 

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={selectedMedia}
            />
        </form>
    );
};

export default AcrossCarouselAdUpdateForm;