import {
    Box, Flex,
    Select,
    Text,
    Spinner,
    Input
} from '@chakra-ui/react';
import { useState } from 'react';
import { useGetInstagramAccountsQuery } from 'store/facebookServices/facebookServicesApi';

function InstagramAccountSelector({ adsAccount, onSelectedPage, selectedAccountId }) {

    const { adsAccountId, adsAccountName } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState();

    const { data: instagramAccounts, isLoading } = useGetInstagramAccountsQuery(adsAccountId);

    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, adsAccountId);
    };

    return (
        <Box>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{adsAccountName}</Text>
                    <Text fontSize={'12px'}>{adsAccountId}</Text>
                </Box>

                <Box flex={1}>
                    {isLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        <>
                            {instagramAccounts?.length > 0 ? (
                                <Select
                                    placeholder="Instagram account"
                                    value={selectedPage}
                                    onChange={handleEventPageChange}
                                    borderRadius={'md'}
                                    size={'sm'}
                                >
                                    {instagramAccounts?.map((account) => (
                                        <option key={account.id} value={account.id}>
                                            {account.username}
                                        </option>
                                    ))}
                                </Select>
                            ) : (
                                <Input
                                    borderRadius={'md'}
                                    isDisabled
                                    value={'No account found'}
                                    size={'sm'}
                                />
                            )}

                        </>

                    )}
                </Box>
            </Flex>
        </Box>
    );
}

export default InstagramAccountSelector;
