import { api } from '../api'
import { setOnboarding } from '../auth/authSlice'

export const companiesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCompany: builder.query({
            query: companyId => ({
                url: `/companies/${companyId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Company']
        }),
        getCompanies: builder.query({
            query: ({ currentPage, searchValue }) => `/companies?page=${currentPage}&filter=${searchValue}`,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Companies'],
            invalidatesTags: ['AdAccounts']
        }),
        getCompaniesByUserID: builder.query({
            query: ({ userId }) => ({
                url: `/companies/client/${userId}`,
            }),
            transformResponse: (response, meta, arg) => response.data.data
        }),
        getAllCompanies: builder.query({
            query: () => `/companies`,
            transformResponse: (response, meta, arg) => response.data,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    //console.log({ data });
                    const activeCompanies = data?.data?.filter((company) => company.status === true)
                    let isOnboarded = activeCompanies?.length > 0 ? true : false
                    dispatch(setOnboarding(isOnboarded));
                } catch (error) {
                    // //console.log(error);
                }
            },
            providesTags: ['Companies'],
            invalidatesTags: ['AdAccounts']
        }),
        addNewCompany: builder.mutation({
            query: company => ({
                url: '/companies',
                method: 'POST',
                body: company
            }),
            invalidatesTags: ['Companies']
        }),
        editCompany: builder.mutation({
            query: ({ companyId, data }) => ({
                url: `/companies/${companyId}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Companies']
        }),
        removeCompany: builder.mutation({
            query: companyId => ({
                url: `/companies/${companyId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Companies']
        }),
        addIndianLegalDetails: builder.mutation({
            query: body => ({
                url: `/companies/legal/${body.companyId}/indian`,
                method: 'POST',
                body: body.payload
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        addNonIndianLegalDetails: builder.mutation({
            query: body => ({
                url: `/companies/legal/${body.companyId}/non-indian`,
                method: 'POST',
                body: body.payload
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        getBankCodes: builder.query({
            query: () => ({
                url: `/companies/bank/codes`,
            }),
            transformResponse: (response, meta, arg) => response.data
        }),
        addIndianBankDetails: builder.mutation({
            query: body => ({
                url: `/companies/bank/indian`,
                method: 'POST',
                body: body.payload
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        addNonIndianBankDetails: builder.mutation({
            query: body => ({
                url: `/companies/bank/non-indian`,
                method: 'POST',
                body: body.payload
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        editIndianBankDetails: builder.mutation({
            query: body => ({
                url: `/companies/bank/${body.id}/indian`,
                method: 'PUT',
                body: body.payload
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        editNonIndianBankDetails: builder.mutation({
            query: body => ({
                url: `/companies/bank/${body.id}/non-indian`,
                method: 'PUT',
                body: body.payload
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        assignClientToCompany: builder.mutation({
            query: ({ companyId, data }) => ({
                url: `/companies/${companyId}/assign`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["Company", "Companies"]
        }),
        getBankList: builder.query({
            query: () => '/banks',
            transformResponse: (response, meta, arg) => response.data,
        }),
    })
})

export const {
    useGetCompanyQuery,
    useGetCompaniesQuery,
    useGetAllCompaniesQuery,
    useAddNewCompanyMutation,
    useEditCompanyMutation,
    useRemoveCompanyMutation,
    useAddIndianLegalDetailsMutation,
    useAddNonIndianLegalDetailsMutation,
    useAddIndianBankDetailsMutation,
    useAddNonIndianBankDetailsMutation,
    useAssignClientToCompanyMutation,
    useEditIndianBankDetailsMutation,
    useEditNonIndianBankDetailsMutation,
    useGetBankListQuery,
    usePrefetch,
    useGetCompaniesByUserIDQuery,
    useGetBankCodesQuery,
} = companiesApi;