import { AddIcon, CloseIcon, InfoOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Fade,
    Flex,
    FormControl,
    Grid,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { FaPlayCircle } from "react-icons/fa";
import { FiEye, FiUpload } from 'react-icons/fi';
import { IoMdCloseCircle } from "react-icons/io";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { selectAfter1, selectBefore1, selectCurrentPage1, setAfter, setBefore, setCurrentPage } from 'store/campaigns/campaignsSlice';
import {
    useFetchImagesQuery, useFetchVideosQuery,
    useFinishUploadMutation,
    useGetVideoByIdQuery,
    useStartUploadSessionMutation, useUploadChunkMutation,
    useUploadImageMutation
} from 'store/driveUpload/uploadApi';
import { cancelUpload, setChoosenFiles, setDriveWidgetOpen, setFiles, setSelectedLocalFiles, setUploadAbortControllers, updateUploadStatus } from "store/driveUpload/uploadSlice";
import { truncateName } from 'utils/functions';
import DriveWidget from './DriveWidget';
import Pagination from './PaginationForDrive';

const MAX_IMAGE_UPLOAD_SIZE_MB = 30; // Max size for images
const MAX_VIDEO_UPLOAD_SIZE_GB = 4; // Max size for videos
const MAX_IMAGE_UPLOAD_SIZE_BYTES = MAX_IMAGE_UPLOAD_SIZE_MB * 1024 * 1024;
const MAX_VIDEO_UPLOAD_SIZE_BYTES = MAX_VIDEO_UPLOAD_SIZE_GB * 1024 * 1024 * 1024;
const chunkSize = 5 * 1024 * 1024;

function DriveModal({ isDriveModalOpen, selectedAdAccounts, mediaType, mediaFormat, onClose, onSubmit, uploadPurpose, adFormik }) {

    const inputRef = useRef();
    const toast = useToast();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose: onDropModalClose } = useDisclosure();
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showError, setShowError] = useState(false);
    const after = useSelector(selectAfter1);
    const before = useSelector(selectBefore1);
    const { isDriveWidgetOpen } = useSelector(state => state.upload);
    const currentPage = useSelector(selectCurrentPage1);
    const [videoUploadCompleted, setVideoUploadCompleted] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const selectedAdAccount = selectedAdAccounts[activeTabIndex];
    const [missingSourceVideos, setMissingSourceVideos] = useState([]);
    const attemptRef = useRef(0);
    const [storedVideos, setStoredVideos] = useState([]);
    const [localFiles, setLocalFiles] = useState([]);
    const { files, uploadStatus: uploadingStatus, choosenFiles, selectedFiles: selFiles } = useSelector(state => state.upload);

    const { data: images, isLoading: isImagesDataLoading, isFetching: isImagesFetching, refetch: refetchImages } = useFetchImagesQuery(
        {
            pageSize: '24',
            ...(searchValue ? {} : { after, before }),
            searchValue: debouncedSearchValue,
            adsAccountId: selectedAdAccount?.adsAccountId
        },
        { skip: !selectedAdAccount?.adsAccountId || mediaType !== 'image' || !isDriveModalOpen }
    );

    const { data: videos, isLoading: isVideosDataLoading, refetch: refetchVideos, isFetching: isVideosFetching } = useFetchVideosQuery(
        {
            pageSize: '24',
            ...(searchValue ? {} : { after, before }),
            searchValue: debouncedSearchValue,
            adsAccountId: selectedAdAccount?.adsAccountId
        },
        { skip: !selectedAdAccount?.adsAccountId || mediaType !== 'video' || !isDriveModalOpen }
    );

    const { data: videoIdData, isLoading: isVideosIdDataLoading, refetch: refetchVideosIds, isFetching: isVideosIdFetching } = useGetVideoByIdQuery(
        {
            CreativeIds: missingSourceVideos?.creativeId ? [missingSourceVideos?.creativeId] : []
        },
        { skip: !missingSourceVideos?.creativeId }
    );




    const [uploadImage, { isLoading: isImageLoading }] = useUploadImageMutation();
    const [startUploadSession, { isLoading: isUploadSessionLoading }] = useStartUploadSessionMutation();
    const [uploadChunk, { isLoading: isUploadChunkLoading }] = useUploadChunkMutation();
    const [finishUpload, { isLoading: isUploadFinishLoading }] = useFinishUploadMutation();

    const isAllAccountsSelected = selectedAdAccounts?.every(adAccount =>
        selectedFiles.some(file => file?.adsAccount?.adsAccountId === adAccount.adsAccountId)
    );

    const handleRefresh = () => {
        if (mediaType === 'image') {
            refetchImages();
        } else {
            setStoredVideos([]);
            refetchVideos();
        }
    };

    useEffect(() => {
        // Reseting files when the modal opens
        if (isDriveModalOpen) {
            setSelectedFiles([]);
            dispatch(setChoosenFiles([]))
            if (mediaType === 'image') {
                refetchImages();
            } else if (mediaType === 'video') {
                setMissingSourceVideos([]);
                setStoredVideos([]);
                setVideoUploadCompleted(false);
                refetchVideos();
            }
        }
    }, [isDriveModalOpen]);

    useEffect(() => {
        let intervalId = null

        if (videoUploadCompleted && videoIdData) {
            let successfulVideoCount = 0;
            const totalVideos = videoIdData.length; // Total videos to check
            videoIdData.forEach((videoItem) => {
                const videoData = videoItem?.data;
                if (videoData) {

                    function checkVideoId() {
                        const videoFound = videoData?.picture;
                        const isVideoReady = videoData?.status?.video_status === "ready";

                        if (videoFound && isVideoReady) {
                            successfulVideoCount++; // Increment successful videocount

                            setStoredVideos((prevStoredVideos) => {
                                const videoExists = prevStoredVideos.some(
                                    (storedVideo) => storedVideo.id === videoData.id
                                );

                                if (!videoExists) {
                                    return [...prevStoredVideos, videoData];
                                } else {
                                    return prevStoredVideos;
                                }
                            });

                            // Check if all videos have been processed
                            if (successfulVideoCount === totalVideos) {
                                // All videos are found and ready
                                dispatch(setSelectedLocalFiles([]));
                                setLocalFiles([]);
                                dispatch(setDriveWidgetOpen(false));
                            }

                            if (intervalId) {
                                clearInterval(intervalId);
                            }

                        } else {
                            if (attemptRef.current < 10) {
                                attemptRef.current += 1;
                                refetchVideosIds();
                            } else {
                                if (intervalId) {
                                    clearInterval(intervalId);
                                }
                            }
                        }
                    };

                    intervalId = setInterval(checkVideoId, 4000);

                }
            });
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [videoIdData, videoUploadCompleted]);


    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2 || searchValue?.length === "") {
                setSearchValue(searchValue);
            }
        }, 4000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])

    const handleSelection = (file) => {
        if (uploadPurpose === 'update') {
            setSelectedFiles([file]); // Only allow one file to be selected
        } else if (uploadPurpose === 'create') {
            if (selectedFiles.some(f => f.creativeId === file.creativeId)) {
                setSelectedFiles(selectedFiles.filter(f => f.creativeId !== file.creativeId)); // Deselect if already selected
            } else {
                setSelectedFiles([...selectedFiles, file]); // Add to selection
            }
        }
    };

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast({
                title: "Upload Error",
                description: "The uploaded file exceeds the maximum size limit.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
            return;
        }

        const validFiles = [];
        const newLocalFiles = []; // Array to store original File objects

        for (const file of acceptedFiles) {
            if (file.type.startsWith("image/") && file.size > MAX_IMAGE_UPLOAD_SIZE_BYTES) {
                toast({
                    title: "Upload Error",
                    description: `The image ${file.name} exceeds the maximum size of 30 MB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
            } else if (file.type.startsWith("video/") && file.size > MAX_VIDEO_UPLOAD_SIZE_BYTES) {
                toast({
                    title: "Upload Error",
                    description: `The video ${file.name} exceeds the maximum size of 4 GB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
            } else {
                validFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    thumbnail: URL.createObjectURL(file),
                    progress: 0,
                    status: "pending",
                    // file:file,
                });
                newLocalFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    thumbnail: URL.createObjectURL(file),
                    progress: 0,
                    status: "pending",
                    file: file,
                });
            }
        }

        if (validFiles.length > 0) {
            dispatch(setFiles(validFiles));
            setLocalFiles(newLocalFiles);
        }
    };

    const handleUploadAll = async (filesToUpload) => {

        dispatch(setFiles(filesToUpload));
        console.log((window.navigator.onLine ? 'on' : 'off') + 'line');
        window.addEventListener('online', () => {
            toast({
                title: "You are back online!",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
            });
        });

        window.addEventListener('offline', () => {
            toast({
                title: "You are offline. Some functionality may be unavailable.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
            });
        });

        const uploadPromises = [];
        try {
            for (const file of filesToUpload) {

                const adsAccountsArray = selectedAdAccount ? [{
                    id: selectedAdAccount?.id,
                    adsAccountId: selectedAdAccount?.adsAccountId,
                    adsAccountName: selectedAdAccount?.adsAccountName,
                    businessName: selectedAdAccount?.businessName,
                    businessId: selectedAdAccount?.businessId,
                }] : [];

                if (adsAccountsArray.length === 0) {
                    toast({
                        title: "Account Error",
                        description: "Please select a valid Ads Account.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                    });
                    return;
                }

                if (file.type.startsWith("image/")) {
                    const uploadImagePromise = (async () => {
                        const formData = new FormData();
                        formData.append('filename', file.file);
                        formData.append('adsAccounts', JSON.stringify(adsAccountsArray));

                        let progressInterval;

                        try {
                            const uploadDuration = 3000;
                            const interval = 100;
                            const totalSteps = uploadDuration / interval;

                            let progress = 0;
                            progressInterval = setInterval(() => {
                                if (progress < 100) {
                                    progress += Math.round(100 / totalSteps);
                                    adsAccountsArray.forEach(account => {
                                        dispatch(updateUploadStatus({ fileName: file.name, accountId: account.adsAccountId, status: "uploading", progress }));
                                    });
                                }
                            }, interval);

                            const response = await uploadImage({ payload: formData }).unwrap();

                            // Check if the response contains a "File already exist" message
                            const fileExists = response?.data?.some(item => item?.message === "File already exist");

                            if (fileExists) {
                                toast({
                                    title: "Upload Error",
                                    description: "File already exists",
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true,
                                    position: "top-right",
                                });

                                dispatch(setChoosenFiles([]))

                                // Dispatch failed status for the upload
                                adsAccountsArray.forEach(account => {
                                    dispatch(updateUploadStatus({
                                        fileName: file.name,
                                        accountId: account.adsAccountId,
                                        status: "failed",
                                        progress: 0,
                                    }));
                                });
                            } else {
                                // If no error
                                const updatedFiles = response?.data?.map(item => ({
                                    adsAccount: {
                                        adsAccountId: item?.adsAccount?.adsAccountId,
                                    },
                                    id: item?._id,
                                    url: item?.[item?.name]?.url,
                                    name: item?.name,
                                    status: item?.status || "ACTIVE",
                                    creativeId: item?.creativeId,
                                    hash: item?.hash,
                                    fileSize: item?.fileSize,
                                    creativeType: item?.creativeType,
                                }));

                                dispatch(setChoosenFiles(updatedFiles));

                                // Update status to "done" for each account
                                adsAccountsArray.forEach(account => {
                                    dispatch(updateUploadStatus({
                                        fileName: file.name,
                                        accountId: account.adsAccountId,
                                        status: "done",
                                        progress: 100,
                                    }));
                                });
                            }
                            clearInterval(progressInterval);
                            
                        } catch (error) {
                            clearInterval(progressInterval);
                            adsAccountsArray.forEach(account => {
                                dispatch(updateUploadStatus({ fileName: file.name, accountId: account.adsAccountId, status: "failed", progress: 0 }));
                            });
                            dispatch(setChoosenFiles([]));
                            toast({
                                title: "Upload Error",
                                description: error?.data?.message,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                                position: "top-right",
                            });
                        }
                    })();

                    uploadPromises.push(uploadImagePromise);
                } else if (file.type.startsWith("video/")) {
                    adsAccountsArray.forEach(selectedAdAccount => {
                        const uploadVideoPromise = (async () => {
                            const abortController = new AbortController();
                            const controllerKey = `${file.name}-${selectedAdAccount.adsAccountId}`;
                            dispatch(setUploadAbortControllers({ [controllerKey]: abortController }));

                            try {
                                const startSessionData = await startUploadSessionWithRetry({
                                    file,
                                    adsAccount: selectedAdAccount?.adsAccountId,
                                    businessId: selectedAdAccount?.businessId
                                });

                                // Check for errors in start session data
                                if (startSessionData?.data?.status === 'error') {
                                    const reason = startSessionData?.data?.message;
                                    toast({
                                        title: "Upload Error",
                                        description: reason,
                                        status: "error",
                                        duration: 5000,
                                        isClosable: true,
                                        position: "top-right",
                                    });
                                    dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "failed", progress: 0, reason }));
                                    return;
                                }

                                const initialProgress = 10;
                                dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "uploading", progress: initialProgress }));

                                const chunks = splitFile(file);
                                const uploadState = loadUploadState(file.name) || {};
                                let allChunksUploaded = true;

                                for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
                                    if (abortController?.signal?.aborted) {
                                        dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "cancelled", progress: 0, reason: 'Upload cancelled' }));
                                        allChunksUploaded = false;
                                        break;
                                    }
                                    const { chunk, start, end } = chunks[chunkIndex];
                                    const chunkId = `${start}-${end}`;

                                    if (uploadState[chunkId] === 'done') {
                                        continue;
                                    }

                                    try {
                                        await uploadChunkWithRetry({
                                            upload_session_id: startSessionData?.data?.upload_session_id,
                                            start_offset: start,
                                            end_offset: end,
                                            chunk,
                                            adsAccount: selectedAdAccount?.adsAccountId,
                                            businessId: selectedAdAccount?.businessId,
                                            file_size: file?.size
                                        }, abortController);

                                        const progress = Math.round(((chunkIndex + 1) / chunks.length) * 90);
                                        dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "uploading", progress: initialProgress + progress }));
                                        saveUploadState(file.name, chunkId, 'done');

                                    } catch (uploadError) {
                                        allChunksUploaded = false;
                                        console.error(`Chunk ${chunkIndex} failed with error: ${uploadError.message}`);
                                        chunkIndex--; // Retry the same chunk
                                    }
                                }

                                if (!allChunksUploaded) {
                                    dispatch(cancelUpload({ fileName: file.name, accountId: selectedAdAccount.adsAccountId }));
                                }

                                if (allChunksUploaded && !abortController.signal.aborted) {
                                    await finishUpload({
                                        upload_session_id: startSessionData?.data?.upload_session_id,
                                        file,
                                        video_id: startSessionData?.data?.video_id,
                                        payload: {
                                            adsAccount: {
                                                id: selectedAdAccount?.id,
                                                adsAccountId: selectedAdAccount?.adsAccountId,
                                                adsAccountName: selectedAdAccount?.adsAccountName,
                                                businessName: selectedAdAccount?.businessName,
                                                businessId: selectedAdAccount?.businessId,
                                            }
                                        },
                                    }).unwrap().then((response) => {
                                        setVideoUploadCompleted(true);
                                        setMissingSourceVideos(response?.data);
                                    }).catch(() => {
                                        setMissingSourceVideos([]);
                                        dispatch(setSelectedLocalFiles([]));
                                    });
                                    dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "done", progress: 100 }));
                                    clearUploadState(file.name);
                                } else {
                                    dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "failed", progress: 0, reason: 'Upload Cancelled' }));
                                }
                            } catch (error) {
                                dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "failed", progress: 0 }));
                            }
                        })();

                        uploadPromises.push(uploadVideoPromise);
                    });
                }
            }

            // Wait for all uploads to complete
            await Promise.all(uploadPromises);

        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            dispatch(setFiles([]));
            setLocalFiles([]);
        }

    };

    const saveUploadState = (fileName, chunkId, status) => {
        const uploadState = JSON.parse(localStorage.getItem(`upload_state_${fileName}`)) || {};
        uploadState[chunkId] = status;
        localStorage.setItem(`upload_state_${fileName}`, JSON.stringify(uploadState));
    };

    const loadUploadState = (fileName) => {
        return JSON.parse(localStorage.getItem(`upload_state_${fileName}`)) || {};
    };

    const clearUploadState = (fileName) => {
        localStorage.removeItem(`upload_state_${fileName}`);
    };

    const splitFile = (file) => {
        const chunks = [];
        let start = 0;

        while (start < file?.file?.size) {
            const end = Math.min(start + chunkSize, file?.file?.size);
            const chunk = file?.file.slice(start, end);
            chunks.push({ start, end, chunk });
            start = end;
        }
        return chunks;
    };

    const startUploadSessionWithRetry = async (params, retries = 3) => {
        const { file, adsAccount, businessId } = params;

        for (let attempt = 0; attempt < retries; attempt++) {
            try {
                if (!navigator.onLine) {
                    await new Promise(resolve => {
                        window.addEventListener('online', () => resolve(), { once: true });
                    });
                }

                const startSessionData = await Promise.race([
                    startUploadSession({ file, adsAccount, businessId }).unwrap(),
                    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000)), // 10 seconds timeout
                ]);

                return startSessionData; // Return successful response

            } catch (error) {
                if (attempt === retries - 1) {
                    throw error; // If last attempt, throw the error
                }

                const retryDelay = Math.pow(2, attempt) * 1000; // Exponential backoff
                await new Promise(resolve => setTimeout(resolve, retryDelay));
            }
        }
    };

    const uploadChunkWithRetry = async (params, abortController, retries = 3) => {
        const { upload_session_id, start_offset, end_offset, chunk, adsAccount, businessId, file_size } = params;

        for (let attempt = 0; attempt < retries; attempt++) {
            if (abortController.signal.aborted) {
                throw new Error('Upload cancelled');
            }

            try {
                if (!navigator.onLine) {
                    await new Promise(resolve => {
                        window.addEventListener('online', () => resolve(), { once: true });
                    });
                }

                const uploadResponse = await Promise.race([
                    uploadChunk({
                        upload_session_id,
                        start_offset,
                        end_offset,
                        chunk,
                        adsAccount,
                        businessId,
                        file_size,
                    }).unwrap(),
                    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000)), // 10 seconds timeout
                ]);

                return uploadResponse; // If upload is successful, return the response

            } catch (error) {
                if (attempt === retries - 1) {
                    throw error; // Throw error if this was the last attempt
                }

                const retryDelay = Math.pow(2, attempt) * 1000; // Exponential backoff
                await new Promise(resolve => setTimeout(resolve, retryDelay));
            }
        }
    };

    const fileImageList = choosenFiles?.map((file, index) => {
        const isSelected = selFiles.some(selectedFile => selectedFile.name === file.name);

        const isUploading = Object.values(uploadingStatus[file.name] || {}).some(
            accountStatus => accountStatus.status === "uploading"
        );

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '2px solid #3577E5' : '1px solid transparent'}
                    className="group"
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                        _hover={{ cursor: "pointer" }}
                    >
                        {file?.thumbnail ? (
                            <Image
                                src={file?.thumbnail}
                                alt={file.name}
                                objectFit="cover"
                                width="100%"
                                height="100%"
                                borderRadius="2px"
                                bg={'gray'}
                                style={{
                                    filter: 'grayscale(100%) opacity(50%)',
                                    pointerEvents: 'none'
                                }}
                            />
                        ) : file?.url ? (
                            <Image
                                src={file?.url}
                                alt={file.name}
                                objectFit="cover"
                                width="100%"
                                height="100%"
                                borderRadius="2px"
                            />
                        ) : null}

                        {isUploading && (
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                w="100%"
                                h="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bg="rgba(255, 255, 255, 0.7)"
                                borderRadius="2px"
                            >
                                <Spinner size="md" color="blue.500" />
                            </Box>
                        )}

                        <Box
                            position="absolute"
                            bottom={2}
                            left={2}
                            bg={'white'}
                            borderRadius={'sm'}
                            p={1}
                            opacity={0}
                            _groupHover={{ opacity: 1 }}
                        >
                            <Popover trigger="hover">
                                <PopoverTrigger>
                                    <FiEye
                                        size={10}
                                        fontWeight={'bold'}
                                        _hover={{ color: "#3577E5", cursor: "pointer" }}
                                    />
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <Box p={2} bg="gray.50" borderRadius="md">
                                        <Image
                                            src={file.thumbnail}
                                            alt={file.name}
                                            borderRadius="md"
                                            mx={'auto'}
                                            width="400px"
                                            height="300px"
                                            objectFit="contain"
                                        />
                                    </Box>
                                </PopoverContent>
                            </Popover>
                        </Box>
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>
        );
    });

    const fileVideoList = selFiles?.map((file, index) => {

        const isUploading = Object.values(uploadingStatus[file.name] || {}).some(
            accountStatus => accountStatus.status === "uploading"
        );

        const isFailed = Object.values(uploadingStatus[file.name] || {}).some(
            accountStatus => accountStatus.status === "failed"
        );

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={'1px solid transparent'}
                    className="group"
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        _hover={{ cursor: "not-allowed" }}
                    >
                        {file?.thumbnail ? (
                            <video
                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '2px', filter: 'grayscale(100%) opacity(50%)' }}
                                src={file?.thumbnail}
                                muted
                            />
                        ) : null}

                        {isUploading ? (
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                w="100%"
                                h="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bg="rgba(255, 255, 255, 0.7)"
                                borderRadius="2px"
                            >
                                <Spinner size="md" color="blue.500" />
                            </Box>
                        ) : null}
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
                {isFailed ? (
                    <Text fontSize={'10px'} ml={2} color={'red.500'}>Failed</Text>
                ) :
                    <Text fontSize={'10px'} ml={2}>Processing...</Text>
                }
            </Flex>
        );
    });


    const storedVideoList = storedVideos?.map((file, index) => {

        const isSelected = selectedFiles.some(selectedFile => selectedFile?.creativeId === file?.id);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '2px solid #3577E5' : '1px solid transparent'}
                    className="group"
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => {
                            const transformedFile = {
                                adsAccount: {
                                    adsAccountId: selectedAdAccount?.adsAccountId
                                },
                                picture: file?.picture,
                                creativeType: 'video',
                                creativeId: file?.id,
                                source: file?.source,
                                hash: file?.hash,
                                name: file?.name,
                                thumbnails: file?.thumbnails
                            };
                            handleSelection(transformedFile);
                        }}
                        _hover={{ cursor: "pointer" }}
                    >
                        {file?.picture ? (
                            <Box w="100%" h="100%" position="relative">
                                <Image
                                    src={file.picture}
                                    alt={file.id}
                                    objectFit="cover"
                                    width="100%"
                                    height="100%"
                                    borderRadius="2px"
                                />
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    bg="rgba(0, 0, 0, 0.5)"
                                    borderRadius="50%"
                                >
                                    <FaPlayCircle color="white" size="35px" />
                                </Box>
                            </Box>
                        ) : (
                            null
                        )}
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file?.name)}</Text>
            </Flex>
        );
    });

    const imageList = images?.data?.map((file, index) => {
        const isSelected = selectedFiles.includes(file);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '2px solid #3577E5' : '1px solid transparent'}
                    className="group"
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                        _hover={{ cursor: "pointer" }}
                    >
                        {file.url ? (
                            <Image
                                src={file.url}
                                alt={file.name}
                                objectFit="cover"
                                width="100%"
                                height="100%"
                                borderRadius="2px"
                            />
                        ) : null}

                        <Box
                            position="absolute"
                            bottom={2}
                            left={2}
                            bg={'white'}
                            borderRadius={'sm'}
                            p={1}
                            opacity={0}
                            _groupHover={{ opacity: 1 }}
                        >
                            <Popover trigger="hover">
                                <PopoverTrigger>
                                    <FiEye
                                        size={10}
                                        fontWeight={'bold'}
                                        _hover={{ color: "#3577E5", cursor: "pointer" }}
                                    />
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <Box p={2} bg="gray.50" borderRadius="md">
                                        <Image
                                            src={file.url}
                                            alt={file.name}
                                            borderRadius="md"
                                            mx={'auto'}
                                            width="400px"
                                            height="300px"
                                            objectFit="contain"
                                        />
                                    </Box>
                                </PopoverContent>
                            </Popover>
                        </Box>
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>
        );
    });


    const videoList = videos?.data?.map((file, index) => {

        const isSelected = selectedFiles.includes(file);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '2px solid #3577E5' : '1px solid transparent'}
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                    >
                        {file?.source ? (
                            <Box w="100%" h="100%" position="relative">
                                <video
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '2px' }}
                                    src={file?.source}
                                    muted
                                />
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    bg="rgba(0, 0, 0, 0.5)"
                                    borderRadius="50%"
                                >
                                    <FaPlayCircle color="white" size="35px" />
                                </Box>
                            </Box>
                        ) : (
                            null
                        )}
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>

        );
    })

    const selectedFileThumbnails = selectedFiles?.map((file, index) => {

        return (
            <Flex
                direction="column"
                key={index}
                bgColor={'gray.50'}
                borderRadius={'lg'}
                borderBottomColor={'unset'}
                borderBottomWidth={0}
                pb={0}

            >
                <Box
                    w="60px"
                    h="60px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    borderRadius={3}
                    p={"4px"}
                    onClick={() => handleSelection(file)}
                >
                    {file.url ? (
                        <Image
                            src={file.url}
                            alt={file.name}
                            objectFit="cover"
                            width="100%"
                            height="100%"
                            borderRadius="3px"
                        />
                    ) : (
                        null
                    )}

                    {file.source ? (
                        <Box w="40px" h="40px" position="relative">
                            <video
                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                src={file.source}
                                muted
                            />
                            <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                                bg="rgba(0, 0, 0, 0.5)"
                                borderRadius="50%"
                            >
                                <FaPlayCircle color="white" size="15px" />
                            </Box>
                        </Box>
                    ) : (
                        null
                    )}
                </Box>
            </Flex >
        );
    })


    const PageMeta = useMemo(() => {
        const currentMeta = mediaType === 'image' ? images?.meta : mediaType === 'video' ? videos?.meta : null;

        return currentMeta || {};
    }, [images, videos]);

    const handlePageChange = ({ after, before, currentPage }) => {
        if (after) {
            dispatch(setAfter({ componentKey: 'Drive', value: after }));
            dispatch(setBefore({ componentKey: 'Drive', value: '' }));
        } else if (before) {
            dispatch(setBefore({ componentKey: 'Drive', value: before }));
            dispatch(setAfter({ componentKey: 'Drive', value: '' }));
        }

        dispatch(setCurrentPage({ componentKey: 'Drive', value: currentPage }));
    };

    useEffect(() => {
        if (activeTabIndex) {
            dispatch(setAfter({ componentKey: 'Drive', value: '' }));
            dispatch(setBefore({ componentKey: 'Drive', value: '' }));
            dispatch(setCurrentPage({ componentKey: 'Drive', value: 0 }));
        }
    }, [activeTabIndex, dispatch]);


    const handleClose = () => {
        // Dispatch actions to reset the state
        dispatch(setAfter({ componentKey: 'Drive', value: '' }));
        dispatch(setBefore({ componentKey: 'Drive', value: '' }));
        dispatch(setCurrentPage({ componentKey: 'Drive', value: 0 }));
        setActiveTabIndex(0);
        setSearchValue('')
        dispatch(setFiles([]));
        dispatch(setSelectedLocalFiles([]));
        dispatch(setDriveWidgetOpen(false));
        
        if (onClose) {
            onClose();
        }
    };

    const removeFile = (index) => {
        const updatedFiles = localFiles.filter((_, i) => i !== index);
        dispatch(setFiles(updatedFiles));
        setLocalFiles(updatedFiles);
        dispatch(setChoosenFiles(updatedFiles));
        dispatch(setSelectedLocalFiles(updatedFiles));
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open
    } = useDropzone({
        onDrop,
        accept: mediaType === 'image' ? {
            "image/*": [".png", ".jpg", ".jpeg"]
        } : {
            "video/*": [".mp4", ".mov", ".avi", ".gif"]
        },
        multiple: true,
        maxFiles: 50,
        maxSize: mediaType === 'image' ? MAX_IMAGE_UPLOAD_SIZE_BYTES : MAX_VIDEO_UPLOAD_SIZE_BYTES,
        // disabled: !uploadFormik.values.adsAccountName.length,
        noClick: true,
    });

    const handleModalClose = () => {
        dispatch(setFiles([]));
        dispatch(setChoosenFiles([]));
        setLocalFiles([]);
        onDropModalClose();
        dispatch(setSelectedLocalFiles([]));
    };

    return (
        <>
            <Modal isOpen={isDriveModalOpen} onClose={handleClose} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{adFormik?.values?.format === 'carousel' ? 'Select up to 10 media' : 'Select media'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody minH={'60vh'}>
                        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'}>
                            <Box width={'100%'}>
                                {selectedAdAccounts[activeTabIndex] && (
                                    <Text fontSize="14px" fontWeight="bold" mb={2}>
                                        Ad account: {selectedAdAccounts[activeTabIndex]?.adsAccountName}
                                    </Text>
                                )}
                                <Tabs
                                    onChange={(index) => {
                                        setActiveTabIndex(index)
                                        setSearchValue('')
                                    }
                                    }
                                    isLazy
                                >
                                    <TabList gap={"10px"} style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                        {selectedAdAccounts.map((account, index) => (
                                            <Tab fontSize={'14px'} _selected={{ color: "brand.200", borderBottom: '2px' }} key={index}>{truncateName(account.adsAccountName)}</Tab>
                                        ))}
                                    </TabList>
                                    <TabPanels>
                                        {selectedAdAccounts.map((_, index) => (
                                            <TabPanel key={index} p={0} mt={'20px'}>
                                                <Flex mb={'20px'} gap={4}>
                                                    <Flex flex={0.9}>
                                                        <InputGroup size='sm'>
                                                            <InputLeftElement
                                                                pointerEvents='none'
                                                                children={<SearchIcon color='gray.300' />}
                                                            />
                                                            <Input
                                                                ref={inputRef}
                                                                type="text"
                                                                value={searchValue || ""}
                                                                onChange={e => {
                                                                    setSearchValue(inputRef.current.value);
                                                                }}
                                                                placeholder={`Search...`}
                                                            />
                                                            {searchValue &&
                                                                <InputRightElement
                                                                    cursor={'pointer'}
                                                                    children={isImagesFetching || isVideosFetching ? <Spinner size={'sm'} /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                                                    onClick={() => { setSearchValue(''); setShowError(false); }} />}
                                                        </InputGroup>
                                                        {showError ? (
                                                            <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                                                                <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                                                            </Flex>
                                                        ) : null}
                                                    </Flex>
                                                    <Flex flex={0.1} gap={2}>
                                                        <Button
                                                            as={'span'}
                                                            colorScheme="orange"
                                                            size={'sm'}
                                                            // isLoading={isUploadSessionLoading || isUploadChunkLoading || isUploadFinishLoading}
                                                            spinnerPlacement="start"
                                                            cursor={'pointer'}
                                                            onClick={onOpen}
                                                        >
                                                            <AddIcon boxSize={3} />
                                                            &nbsp;Upload
                                                        </Button>
                                                        {/* <FormControl>
                                                            <FormLabel htmlFor="file-upload">
                                                            <Button
                                                                as={'span'}
                                                                colorScheme="orange"
                                                                size={'sm'}
                                                                isLoading={isImageLoading || isVideoLoading}
                                                                spinnerPlacement="start" 
                                                                cursor={'pointer'}
                                                                onClick={onOpen}
                                                                >
                                                                <AddIcon boxSize={3} />
                                                                &nbsp;Upload
                                                            </Button>
                                                            </FormLabel>
                                                            <input
                                                                id="file-upload"
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => { 
                                                                    handleUploadAll(e.target.files[0]) 
                                                                    dispatch(setDriveWidgetOpen(true));
                                                                }}
                                                            />
                                                        </FormControl> */}
                                                        <Flex mt={2}>
                                                            <Tooltip label='Refresh' fontSize='xs' >
                                                                <Flex _hover={{
                                                                    color: 'gray.400'
                                                                }}>
                                                                    <TbRefresh cursor="pointer" onClick={handleRefresh} size={20} />
                                                                </Flex>
                                                            </Tooltip>
                                                        </Flex>
                                                    </Flex>

                                                </Flex>
                                                <Flex direction={'column'}>
                                                    {mediaType === 'image' ? (
                                                        <>
                                                            {(isImagesDataLoading || isImagesFetching) ? (
                                                                <Flex justifyContent={'center'} alignItems={'center'} gap={5} h={'45vh'}>
                                                                    <Spinner mt={'20px'} />
                                                                </Flex>
                                                            ) : (
                                                                <>
                                                                    {images?.data?.length > 0 ? (
                                                                        <Grid templateColumns={'repeat( auto-fit, minmax(95px, 95px) )'} gap='10px'>
                                                                            {fileImageList}
                                                                            {imageList}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Text>No images yet</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {mediaType === 'video' ? (
                                                        <>
                                                            {isVideosDataLoading || isVideosFetching ? (
                                                                <Flex justifyContent={'center'} alignItems={'center'} gap={5} h={'45vh'}>
                                                                    <Spinner mt={'20px'} />
                                                                </Flex>
                                                            ) : (
                                                                <>
                                                                    {videos?.data?.length > 0 ? (
                                                                        <Grid templateColumns={'repeat( auto-fit, minmax(95px, 95px) )'} gap='10px'>
                                                                            {fileVideoList}
                                                                            {storedVideoList}
                                                                            {videoList}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Text>No videos yet</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Flex>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </Flex>

                        {/* DropZone modal */}
                        <Modal isOpen={isOpen} onClose={() => handleModalClose()} size={"xl"}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Upload Files</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Box
                                        overflowY="auto"
                                        maxH="600px"
                                        h={'350px'}
                                        p={2}
                                    >
                                        <FormControl>
                                            {!files.length ? (
                                                <Center
                                                    className="dropzone"
                                                    {...getRootProps()}
                                                    sx={{
                                                        textAlign: 'center',
                                                        padding: '20px 0px',
                                                        width: '100%',
                                                        margin: '0 auto',
                                                        borderWidth: '1px',
                                                        borderColor: 'gray.600',
                                                        borderStyle: 'dashed',
                                                        background: 'gray.50',
                                                        borderRadius: 5,
                                                        minHeight: '320px',
                                                    }}
                                                    borderColor={isDragActive ? 'gray.500' : 'gray.600'}
                                                >
                                                    <Input {...getInputProps()} />
                                                    <Flex flexDirection={'column'} align={'center'} justify={'center'} gap={'10px'} minW={'220px'}>
                                                        <Icon as={FiUpload} fontSize="2xl" color={'gray.800'} />
                                                        <Text fontSize={{ base: '10px', md: '12px' }} color={'gray.800'}>
                                                            <>
                                                                Drag 'n' drop file or
                                                                <Link textDecoration={'underline'} color={'gray.800'} onClick={open} size={'xs'} ml={1}>
                                                                    Browse
                                                                </Link>
                                                            </>
                                                        </Text>

                                                    </Flex>
                                                </Center>
                                            ) : (
                                                <Fade in={files.length > 0}>
                                                    <Flex flexDirection="row" flexWrap={'wrap'} align="flex-start" gap="30px" minW="220px" pos="relative">
                                                        {files.map((file, index) => (
                                                            <Box key={file.name} role="group" position="relative" w="70px" h={'70px'} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
                                                                {file?.type.startsWith("video/") ? (
                                                                    <video
                                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                                                        src={file.thumbnail}
                                                                        muted
                                                                    />
                                                                ) : (
                                                                    <Image
                                                                        src={file.thumbnail}
                                                                        alt={file.name}
                                                                        objectFit="cover"
                                                                        width="100%"
                                                                        height="100%"
                                                                        borderRadius="3px"
                                                                    />
                                                                )}
                                                                <Icon
                                                                    top={'-5px'}
                                                                    right={'-5px'}
                                                                    position="absolute"
                                                                    color={'red.500'}
                                                                    _hover={{ color: 'gray.400' }}
                                                                    as={IoMdCloseCircle}
                                                                    cursor="pointer"
                                                                    boxSize={4}
                                                                    onClick={() => removeFile(index)}
                                                                    bg={'white'}
                                                                    borderRadius={'50%'}
                                                                />
                                                                <Text fontSize={'11px'} mt={'2px'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>{file?.name}</Text>
                                                            </Box>
                                                        ))}
                                                    </Flex>
                                                </Fade>
                                            )}
                                        </FormControl>
                                    </Box>
                                </ModalBody>

                                <ModalFooter gap={2}>
                                    <Button size={'sm'} onClick={() => handleModalClose()}>Cancel</Button>
                                    <Button colorScheme={'orange'} size={'sm'}
                                        onClick={() => {
                                            handleUploadAll(localFiles);
                                            dispatch(setDriveWidgetOpen(true));
                                            onDropModalClose();
                                            dispatch(setAfter({ componentKey: 'Drive', value: '' }));
                                            dispatch(setBefore({ componentKey: 'Drive', value: '' }));
                                            dispatch(setCurrentPage({ componentKey: 'Drive', value: 0 }));
                                            localStorage.removeItem('after');
                                            localStorage.removeItem('before');
                                        }}>Submit</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        {isDriveWidgetOpen && (
                            <Box
                                position="fixed"
                                bottom="0"
                                left="0"
                                right="0"
                                zIndex={1}
                            >
                                <Flex justifyContent="space-between" alignItems="center">
                                    <DriveWidget />
                                </Flex>
                            </Box>
                        )}

                    </ModalBody>

                    <ModalFooter>
                        <Flex direction={'column'} justifyContent={'flex-start'} width={'100%'} gap={'20px'}>
                            <Flex justifyContent={'flex-end'}>
                                {/* Pagination Component */}
                                <Pagination
                                    PageMeta={PageMeta}
                                    initialState={{ pageIndex: currentPage }}
                                    onPageChange={handlePageChange}
                                    isFetching={isImagesFetching || isVideosFetching}
                                />
                            </Flex>
                            {mediaFormat === 'CAROUSEL' && selectedFiles?.length > 0 ? (
                                <Flex justifyContent={'flex-start'} gap={'10px'} direction={'column'} bg={'gray.50'} p={'10px'}>
                                    <Text fontSize={'12px'}>Selected {mediaType}{mediaFormat === 'CAROUSEL' ? 's' : null} </Text>
                                    <Flex gap={'5px'}>
                                        {selectedFileThumbnails}
                                    </Flex>
                                </Flex>
                            ) : null}

                            <Flex justifyContent={'space-between'}>
                                <Box>
                                    {!isAllAccountsSelected ? (
                                        <Flex alignItems={'center'} gap={'5px'}>
                                            <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                                            <Text fontSize={'12px'}>  Please select at least single media from single ad account</Text>
                                        </Flex>
                                    ) : null}
                                </Box>
                                <Flex>
                                    <Button mr={3} onClick={onClose} size={'sm'}>
                                        Close
                                    </Button>
                                    <Button
                                        colorScheme={'orange'}
                                        size={'sm'}
                                        isDisabled={selectedFiles?.length === 0 || !isAllAccountsSelected}
                                        onClick={() => {
                                            if (selectedFiles?.length > 0) {
                                                onSubmit(selectedFiles, mediaType);
                                                onClose();
                                                dispatch(setAfter({ componentKey: 'Drive', value: '' }));
                                                dispatch(setBefore({ componentKey: 'Drive', value: '' }));
                                                dispatch(setCurrentPage({ componentKey: 'Drive', value: 0 }));
                                                setActiveTabIndex(0);
                                                setSearchValue('')
                                            }
                                        }}
                                    >Submit</Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DriveModal