import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Icon as ChakraIcon,
  Spinner,
  Flex,
  Icon,
  Tooltip,
  Modal,ModalBody,ModalOverlay,ModalContent,ModalFooter,ModalHeader
} from '@chakra-ui/react';
import { MdCheckCircle } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown, IoIosClose, IoMdCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setDriveWidgetOpen, resetUpload, cancelUpload,  setSelectedLocalFiles, setChoosenFiles } from "store/driveUpload/uploadSlice";

const DriveWidget = ({ isUploading }) => {

const dispatch = useDispatch();
const { files, uploadStatus, isDriveWidgetOpen, selectedFiles } = useSelector(state => state.upload);

const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

const [isOffline, setIsOffline] = useState(!navigator.onLine);
const [uploadComplete, setUploadComplete] = useState(false);
const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    dispatch(setDriveWidgetOpen(isDriveWidgetOpen));
  }, [isDriveWidgetOpen]);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (files?.length) {
        dispatch(setSelectedLocalFiles(files));
        dispatch(setChoosenFiles(files));
    }
  }, [files, dispatch]);


  useEffect(() => {
    if (selectedFiles?.length === 0) {
      setUploadComplete(false);
      return;
    } 

    const allFilesUploaded = selectedFiles?.every(file => {
      const statuses = uploadStatus[file.name] || {};
      const accounts = Object.keys(statuses);
      return accounts.length > 0 && accounts.every(accountId => statuses[accountId]?.status === "done");
    });

    const anyUploading = selectedFiles?.some(file => {
      const statuses = uploadStatus[file.name] || {};
      return Object.values(statuses).some(status => status?.status === "uploading");
    });

    setUploadComplete(allFilesUploaded && !anyUploading);
  }, [selectedFiles, uploadStatus]);

  if (!isDriveWidgetOpen) {
    return null;
  }

  const handleCloseWidget = () => {
    const hasOngoingUploads = selectedFiles?.some(file => {
      const statuses = uploadStatus[file.name] || {};
      return Object.values(statuses)?.some(status => 
        status?.status === "uploading" && status?.progress > 0 && status?.progress < 100
      );
    });
  
    const allUploadsDone = selectedFiles?.every(file => {
      const statuses = uploadStatus[file.name] || {};
      return Object.values(statuses).every(status => 
        status?.status === "done" && status?.progress === 100
      );
    });
  
    if (hasOngoingUploads) {
      setShowConfirmationModal(true);
    } else if (allUploadsDone) {
      dispatch(resetUpload());
      dispatch(setDriveWidgetOpen(false));
    }else{
      dispatch(setDriveWidgetOpen(false));
      dispatch(setSelectedLocalFiles([]));
    }
  };

  const handleContinueUploads = () => {
    setShowConfirmationModal(false);
  };

  const handleCancelUploads = () => {
    selectedFiles.forEach(file => {
      const statuses = uploadStatus[file.name] || {};
      const accounts = Object.keys(statuses);
      accounts.forEach(accountId => {
        dispatch(cancelUpload({ fileName: file.name, accountId }));
      });
    });
    dispatch(resetUpload());
    dispatch(setDriveWidgetOpen(false));
  };

  return (
    <>
    <Box
      position="fixed"
      bottom={0}
      right={0}
      width="100%"
      maxWidth="500px"
      maxHeight="400px"
      bg="#F4F4F4"
      color="black"
      boxShadow="md"
      zIndex="overlay"
      borderTopLeftRadius={10}
      borderTopRightRadius={10}
    >
      <HStack justifyContent="space-between" mb={2} py={'5px'} pl={'10px'} borderBottomColor={'gray.100'} borderBottomWidth={1}>
        <Text fontSize="sm" fontWeight="bold">
          {selectedFiles?.length === 0
            ? 'No files selected'
            : uploadComplete
              ? `(${selectedFiles?.length}) upload complete`
              : isOffline
                ? `Upload paused (offline)`
                : `Uploading ${selectedFiles?.length} files`}
        </Text>
        <Flex align={'center'}>
          <Button onClick={onToggle} variant="unstyled" size="sm">
            {isOpen ? <IoIosArrowDown size={24} /> : <IoIosArrowUp size={24} />}
          </Button>
          <Button onClick={handleCloseWidget} variant="unstyled" size="sm">
            <IoIosClose size={30} />
          </Button>
        </Flex>
      </HStack>

      <Collapse in={isOpen}>
        <Box
          maxHeight="300px"
          overflowY="auto"
          px={'10px'}
          pb={'10px'}
        >
          <VStack spacing={2} align="stretch">
            {selectedFiles?.map((file) => {
              const statuses = uploadStatus[file.name] || {};
              const accounts = Object.keys(statuses);

              return (
                <Box
                  key={file.name}
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                >
                  <Text fontSize="12px" fontWeight="bold" mb={1}>
                    {file?.name?.slice(0, 26) + '...' + file?.name?.slice(-4)}
                  </Text>
                  <VStack spacing={1} align="stretch">
                    {accounts.map((accountId) => {
                      const status = statuses[accountId] || {};
                      return (
                        <Box
                          key={accountId}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          px={'5px'}
                          py={'2px'}
                          mb={'4px'}
                          bg={status.status === "done" ? "green.50" : status.status === "failed" || status.status === "cancelled" ? "red.50" : "white"}
                        >
                          <Text fontSize="10px" flex="1" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            Ad Account ID: {accountId}
                          </Text>
                          <Flex alignItems="center" ml={2}>
                            {status.status === "uploading" && (
                              <Flex alignItems="center" gap={2}>
                                <Spinner boxSize={'14px'} thickness="2px" speed="0.65s" color="gray.800" emptyColor='gray.200' />
                                <Tooltip label="Cancel upload" fontSize="xs">
                                  <Flex>
                                    <Icon
                                      cursor="pointer"
                                      as={IoMdCloseCircle}
                                      size={16}
                                      _hover={{
                                        color: 'red.400'
                                      }}
                                      color="red"
                                      onClick={() => dispatch(cancelUpload({ fileName : file.name, accountId }))}
                                    />
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            )}
                            {status.status === "done" ? (
                              <ChakraIcon as={MdCheckCircle} color="green.500" boxSize={4} />
                            ) : status.status === "failed" || status.status === "cancelled" ? (
                              <Flex gap={2}>
                                <Text fontSize="10px">{status.reason}</Text>
                                <Icon as={IoMdCloseCircle} size={16} color="red" />
                              </Flex>
                            ) : isUploading && status.status !== "uploading" ? (
                              <Spinner size={20} />
                            ) : null}
                          </Flex>
                        </Box>
                      );
                    })}
                  </VStack>
                </Box>
              );
            })}
          </VStack>
        </Box>
      </Collapse>
    </Box>

    {/* Confirmation Modal */}
    <Modal isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} isCentered >
      <ModalOverlay />
      <ModalContent maxWidth="600px">
        <ModalHeader>
          <Flex justify="space-between" align="center" width="100%">
            <span>Cancel all uploads?</span>
            <Button onClick={() => setShowConfirmationModal(false)} variant="unstyled" size="sm">
              <IoIosClose size={34} />
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>
          Your uploads are still ongoing. Do you wish to cancel all current uploads?
        </ModalBody>
        <ModalFooter gap={2}>
          <Button colorScheme="orange" size={'sm'} onClick={handleContinueUploads}>
            Continue Uploads
          </Button>
          <Button size={'sm'} onClick={handleCancelUploads}>
            Cancel Uploads
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
  );
};

export default DriveWidget;
