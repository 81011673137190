import { ArrowDownIcon, ArrowUpIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Skeleton,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import './appTable.css';

function AppTableWithPageMeta({ columns, data = [], searchEnabled, isLoading, isFetching, initialState, pageMeta={}, onPageChange, onSearchInputChange, searchTerm, emptyMessage, total }) {

    const backgroundColor = useColorModeValue('gray.50', 'gray.700');
    const borderColor = useColorModeValue('gray.100', 'gray.700');
    const [showError, setShowError] = useState(false);
    const { pageSize, totalItems, after, before, hasNextPage, hasPrevPage } = pageMeta;
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            initialState: {
                pageIndex: initialState?.pageIndex || 0,
                pageSize: pageSize || 20,
                ...initialState,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );


    /* SEARCH */

    const inputRef = useRef();
    const [searchValue, setsearchValue] = useState(searchTerm)
    const [hasSearched, setHasSearched] = useState(false);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2) {
                onSearchInputChange(searchValue);
                setHasSearched(true);
            } else if (searchValue?.length === 0) {
                onSearchInputChange(searchValue);
                setHasSearched(false);
            }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {

        if (data?.length == 0) {
            setShowError(true);
            //setsearchValue('');
            onSearchInputChange('');
        }

    }, [data])

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])

    const handleNextPage = () => {
        if (pageMeta.hasNextPage) {
            const newPage = initialState.pageIndex + 1;
            onPageChange({ after, currentPage: newPage });
        }
    };

    const handlePreviousPage = () => {
        if (pageMeta.hasPrevPage) {
            const newPage = initialState.pageIndex - 1;
            onPageChange({ before, currentPage: newPage });
        }
    };

    return (

        <Box >
        {
            hasSearched ? (
                <Box >
                    <>
                        {searchEnabled && (
                            <Box mb={'20px'}>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<SearchIcon color='gray.300' />}
                                    />
                                    <Input
                                        ref={inputRef}
                                        type="text"
                                        value={searchValue || ""}
                                        onChange={e => {
                                            setsearchValue(inputRef.current.value);
                                        }}
                                        placeholder={`Search...`}
                                    />
                                    {searchValue &&
                                        <InputRightElement
                                            cursor={'pointer'}
                                            children={isFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                            onClick={() => { setsearchValue(''); }} />}
                                </InputGroup>
                                {showError ? (
                                    <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                                        <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                                    </Flex>
                                ) : null}
                            </Box>
                        )}

                        <TableContainer >
                            {isLoading && (
                                <Skeleton height='5px' />
                            )}
                            <Table
                                className={'sticky'}
                                variant={'simple'}
                                size='md'
                                {...getTableProps()}
                                borderWidth={1}
                                borderColor={borderColor}

                            >
                                <Thead bg={backgroundColor} >
                                    {headerGroups.map(headerGroup => (
                                        <Tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    <Flex align={'center'} gap={'10px'}>
                                                        <Box as='span'> {column.render("Header")} </Box>
                                                        {column.isSorted &&
                                                            (
                                                                <Box as="span">
                                                                    {column.isSortedDesc
                                                                        ? (<ArrowDownIcon boxSize={3} ml={2} />)
                                                                        : (<ArrowUpIcon boxSize={3} ml={2} />)
                                                                    }
                                                                </Box>
                                                            )
                                                        }
                                                        <Box ml={2} as='span'>{column?.canFilter ? column.render('Filter') : null}</Box>
                                                    </Flex>
                                                </Th>
                                            ))}
                                        </Tr>
                                    ))}
                                </Thead>
                                <Tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        // //console.log(row);
                                        return (
                                            <React.Fragment key={i + 'row'}>
                                                <Tr  {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <Td {...cell.getCellProps()}
                                                                fontSize={'sm'}
                                                            >
                                                                {cell.render("Cell")}
                                                            </Td>
                                                        );
                                                    })}
                                                </Tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </Tbody>
                            </Table>

                        </TableContainer>
                        {/* Pagination */}
                        {total === 'noTotal' ? (
                            <Flex align="center" justify={['start', 'end']} mt={4} gap={2}>
                                <Text fontSize="sm">
                                    {`${pageSize * initialState.pageIndex + 1}-${pageSize * (initialState.pageIndex + 1)}`}
                                </Text>
                                <Flex gap={2}>
                                    <Button
                                        size="sm"
                                        onClick={handlePreviousPage}
                                        isDisabled={!hasPrevPage || isFetching}
                                    >
                                        <Icon as={FiChevronLeft} />
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={handleNextPage}
                                        isDisabled={!hasNextPage || isFetching}
                                    >
                                        <Icon as={FiChevronRight} />
                                    </Button>
                                </Flex>
                            </Flex>
                        ) : totalItems >= 20 ? (
                            <Flex align="center" justify={['start', 'end']} mt={4} gap={2}>
                                <Text fontSize="sm">
                                    {totalItems > 0
                                        ? `${pageSize * initialState.pageIndex + 1}-${pageSize * (initialState.pageIndex + 1)} of ${totalItems}`
                                        : "No data available"}
                                </Text>
                                <Flex gap={2}>
                                    <Button
                                        size="sm"
                                        onClick={handlePreviousPage}
                                        isDisabled={!hasPrevPage || isFetching}
                                    >
                                        <Icon as={FiChevronLeft} />
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={handleNextPage}
                                        isDisabled={!hasNextPage || isFetching}
                                    >
                                        <Icon as={FiChevronRight} />
                                    </Button>
                                </Flex>
                            </Flex>
                        ) : null}

                    </>
                </Box>
            ) : (
                <>
                    {data?.length == 0 ? (
                        <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                            {emptyMessage ? emptyMessage : 'No data available'}
                        </Flex>
                    ) : (
                        <>
                            {searchEnabled && (
                                <Box mb={'20px'}>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<SearchIcon color='gray.300' />}
                                        />
                                        <Input
                                            ref={inputRef}
                                            type="text"
                                            value={searchValue || ""}
                                            onChange={e => {
                                                setsearchValue(inputRef.current.value);
                                            }}
                                            placeholder={`Search...`}
                                        />
                                        {searchValue &&
                                            <InputRightElement
                                                cursor={'pointer'}
                                                children={isFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                                onClick={() => { setsearchValue(''); }} />}
                                    </InputGroup>

                                </Box>
                            )}

                            <TableContainer >
                                {isLoading && (
                                    <Skeleton height='5px' />
                                )}
                                <Table
                                    className={'sticky'}
                                    variant={'simple'}
                                    size='md'
                                    {...getTableProps()}
                                    borderWidth={1}
                                    borderColor={borderColor}

                                >
                                    <Thead bg={backgroundColor} >
                                        {headerGroups.map(headerGroup => (
                                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        <Flex align={'center'}>
                                                            <Box as='span'> {column.render("Header")} </Box>
                                                            {column.isSorted &&
                                                                (
                                                                    <Box as="span">
                                                                        {column.isSortedDesc
                                                                            ? (<ArrowDownIcon boxSize={3} ml={2} />)
                                                                            : (<ArrowUpIcon boxSize={3} ml={2} />)
                                                                        }
                                                                    </Box>
                                                                )
                                                            }
                                                            <Box ml={2} as='span'>{column?.canFilter ? column.render('Filter') : null}</Box>
                                                        </Flex>
                                                    </Th>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            // //console.log(row);
                                            return (
                                                <React.Fragment key={i + 'row'}>
                                                    <Tr  {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <Td {...cell.getCellProps()}
                                                                    fontSize={'sm'}
                                                                >
                                                                    {cell.render("Cell")}
                                                                </Td>
                                                            );
                                                        })}
                                                    </Tr>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Tbody>
                                </Table>


                            </TableContainer>
                            {/* <Box mt={4}>Showing the first 20 results of {rows.length} rows</Box> */}
                            {/* Pagination */}
                            {total === 'noTotal' && data.length > 9 ? (
                            <Flex align="center" justify={['start', 'end']} mt={4} gap={2}>
                                <Text fontSize="sm">
                                    {`${pageSize * initialState.pageIndex + 1}-${pageSize * (initialState.pageIndex + 1)}`}
                                </Text>
                                <Flex gap={2}>
                                    <Button
                                        size="sm"
                                        onClick={handlePreviousPage}
                                        isDisabled={!hasPrevPage || isFetching}
                                    >
                                        <Icon as={FiChevronLeft} />
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={handleNextPage}
                                        isDisabled={!hasNextPage || isFetching}
                                    >
                                        <Icon as={FiChevronRight} />
                                    </Button>
                                </Flex>
                            </Flex>
                            ) : totalItems >= 20 ? (
                            <Flex align="center" justify={['start', 'end']} mt={4} gap={2}>
                                <Text fontSize="sm">
                                    {totalItems > 0
                                        ? `${pageSize * initialState.pageIndex + 1}-${pageSize * (initialState.pageIndex + 1)} of ${totalItems}`
                                        : "No data available"}
                                </Text>
                                <Flex gap={2}>
                                    <Button
                                        size="sm"
                                        onClick={handlePreviousPage}
                                        isDisabled={!hasPrevPage || isFetching}
                                    >
                                        <Icon as={FiChevronLeft} />
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={handleNextPage}
                                        isDisabled={!hasNextPage || isFetching}
                                    >
                                        <Icon as={FiChevronRight} />
                                    </Button>
                                </Flex>
                            </Flex>
                        ) : null}
                        </>
                    )}
                </>
            )
        }
        </Box>
    )
}

export default AppTableWithPageMeta;