import {
    Box, Flex,
    Select,
    SkeletonText,
    Spinner,
    Text
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetInstagramByAdaccountQuery, useGetInstagramByPageIDQuery, useGetInstagramPageDetailsQuery } from 'store/BmAccounts/bmAccountApi';

function InstagramAccountSelector({ isNewAdCreating = false, adsAccount, onSelectedPage, selectedInstPageId, pageID }) {

    const { adsAccountId, adsAccountName } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState(selectedInstPageId || '');
    const userInfo = useSelector((state) => state.auth?.userInfo);

    useEffect(() => {
        if (selectedInstPageId) {
            setselectedPage(selectedInstPageId);
        }
    }, [selectedInstPageId])

    // get list of instagram accounts 
    const { data: instagramAccounts, isLoading: isInstaLoading } = useGetInstagramByAdaccountQuery(
        { adsAccountId: adsAccountId }, {}
    );

    // get pagebacked instagram accounts
    const { data: pageBackedInstaAccounts, isLoading: isFacebookIdLoading, isFetching:isFacebookIdFetching } = useGetInstagramByPageIDQuery(
        { pageId: pageID },
        { skip: !pageID }
    );

    const isPageBackedInstaAvailable = pageBackedInstaAccounts?.page_backed_instagram_accounts?.data?.[0];

    
    const isPageIdPresent = pageBackedInstaAccounts?.page_backed_instagram_accounts?.data?.some(account => account.id === selectedInstPageId);

    // get instagram page details if pageId in not in pageBackedInstaAccounts
    // const { data: instaPageDetails, isLoading: isPageDetailsLoading } = useGetInstagramPageDetailsQuery(
    //     { instaId: selectedInstPageId },
    //     { skip: !selectedInstPageId }
    // );
    // console.log(instaPageDetails)
    
    // Check if the selected Instagram account is in the list of Instagram accounts
    const isSelectedAccountInList = instagramAccounts?.some(account => account.id === selectedPage);


    // Handle the Instagram account change
    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, adsAccountId);
    };


    return (
        <Box>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{adsAccountName}</Text>
                    <Text fontSize={'12px'}>{adsAccountId}</Text>
                </Box>

                <Box flex={1}>

                    {(isInstaLoading || isFacebookIdLoading || isFacebookIdFetching) ? (
                         <Flex justifyContent={'center'} alignItems={'center'}>
                            <SkeletonText width="80%" noOfLines={2}  skeletonHeight={2}/>
                        </Flex>
                    ) : (
                        <>
                            {instagramAccounts?.length > 0 ? (
                                <Select
                                    placeholder="Select"
                                    value={selectedPage}
                                    onChange={handleEventPageChange}
                                    borderRadius={'md'}
                                    size={'sm'}
                                    isDisabled={userInfo?.role !== 'client'}
                                >
                                    {instagramAccounts?.map((account) => (
                                        <option key={account?.id} value={account?.id}>
                                            {account?.username}
                                        </option>
                                    ))}

                                        <option value={isPageBackedInstaAvailable ? pageBackedInstaAccounts?.page_backed_instagram_accounts?.data[0]?.id : pageBackedInstaAccounts?.connectedAccount[0]?.id}
                                            disabled={!isPageBackedInstaAvailable && !pageBackedInstaAccounts?.connectedAccount[0]?.id}>
                                            {isPageBackedInstaAvailable ? 'Use Facebook Page' : pageBackedInstaAccounts?.connectedAccount[0]?.username ? pageBackedInstaAccounts?.connectedAccount[0]?.username : 'Use Facebook Page'}
                                        </option>

                                </Select>
                            ) : (
                                <>
                                    <Select
                                        placeholder="Select"
                                        value={selectedPage}
                                        onChange={handleEventPageChange}
                                        borderRadius={'md'}
                                        size={'sm'}
                                        isDisabled={userInfo?.role !== 'client'}
                                    >
                                        <option value={isPageBackedInstaAvailable ? pageBackedInstaAccounts?.page_backed_instagram_accounts?.data[0]?.id : pageBackedInstaAccounts?.connectedAccount[0]?.id}
                                            disabled={!isPageBackedInstaAvailable && !pageBackedInstaAccounts?.connectedAccount[0]?.id}>
                                            {isPageBackedInstaAvailable ? 'Use Facebook Page' : pageBackedInstaAccounts?.connectedAccount[0]?.username ? pageBackedInstaAccounts?.connectedAccount[0]?.username : 'Use Facebook Page'}
                                        </option>
                                    </Select>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Flex>
            {(!isInstaLoading && instagramAccounts?.length < 0) && (
                <Box mt={'10px'}>
                    <Text fontSize={'12px'}>
                        To place ads on Instagram, please select an Instagram account to represent your business. If you don't have an Instagram account, you can select your Facebook Page.
                    </Text>
                </Box>
            )}
        </Box>
    );
}

export default InstagramAccountSelector;