import {
    Box,
    Button,
    Flex,
    FormControl,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetcampaignsListQuery, useUpdateCampaignMutation, useUpdateCampaignStatusMutation } from 'store/campaigns/campaignsApi';
import { setSelectedCampaignId,selectSelectedCampaignId } from 'store/campaigns/campaignsSlice';
import * as Yup from 'yup';

function CampaignsTable({ adsAccountId, startDate, endDate, datePreset, setSelectedTab }) {

    const dispatch = useDispatch();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const isSearchDisabled = Boolean(selectedCampaignId);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const [switchStates, setSwitchStates] = useState({});

    const { data: campaignsList, isFetching, refetch, isLoading, error: campaignsListError } = useGetcampaignsListQuery({
        currentPage,
        searchValue,
        adsAccountId,
        start_date: startDate,
        end_date: endDate,
        date_preset: datePreset,
        campaign_id: selectedCampaignId ? selectedCampaignId : '',
    }, { skip: !adsAccountId });

    const [updateCampaign, { isLoading: isCampaignUpdating }] = useUpdateCampaignMutation({});
    const [updateCampaignStatus] = useUpdateCampaignStatusMutation({});
    
    useEffect(() => {
        if (adsAccountId) {
            refetch();
        }
    }, [adsAccountId]);

    useEffect(() => {
        if (campaignsList?.data) {
            const initialSwitchStates = {};
            campaignsList.data.forEach(campaign => {
                initialSwitchStates[campaign.campaign_id] = campaign.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [campaignsList]);
    
    useEffect(() => {
        if (selectedCampaign) {
            formik.setValues({
                name: selectedCampaign?.name,
                // objective: selectedCampaign.objective  
            });
        }
    }, [selectedCampaign]);

    const formik = useFormik({
        initialValues: {
            name: '',
            // objective: ''  
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Campaign name is required'),
            // objective: Yup.string().required('Objective is required')  
        }),
        onSubmit: (values) => {
            const { name } = values;
            const payload = {
                name: name,
                // objective: objective,  
            }
            updateCampaign({
                campaignId: selectedCampaign.campaign_id,
                payload
            }).unwrap()
                .then(() => {
                    refetch();
                    toast({
                        position: "top-right",
                        title: "Campaign updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    onClose();
                })
                .catch((error) => {
                    toast({
                        position: "top-right",
                        title: "Error updating campaign",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    console.error('Error updating campaign:', error);
                });
        }
    });

    const handleSwitchChange = (campaignId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [campaignId]: newStatus }));
        updateCampaignStatus({
            campaignId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Campaign ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating campaign status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating campaign status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [campaignId]: currentState }));
            });
    };


    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    const isActive = switchStates[original.campaign_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Campaign is ON' : 'Campaign is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.campaign_id, original.status)}
                                        isDisabled={userInfo?.role !== 'client'}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isSelected = selectedCampaignId === original?.campaign_id;

                    return(
                    <Flex gap={'10px'} alignItems={'center'}>    
                        <Tooltip label='Edit' fontSize='xs' >
                            <Flex>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEdit2}
                                    cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"} 
                                    color={userInfo?.role === 'client' ? "inherit" : "gray.300"}
                                    onClick={() => {
                                        if (userInfo?.role === 'client') {
                                            setSelectedCampaign(original);
                                            onOpen();
                                        }
                                    }}
                                />
                            </Flex>
                            </Tooltip>
                            <Flex gap={'10px'} alignItems={'center'}>
                                <Link
                                    to={'#'}
                                    onClick={() => {
                                        setSelectedTab(1);
                                        dispatch(setSelectedCampaignId(original?.campaign_id)); 
                                    }} 
                                >
                                    <Text
                                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        fontWeight={isSelected ? 'bold' : 'normal'}
                                        color={isSelected ? 'orange.500' : 'inherit'}
                                    >
                                        {original.name}
                                    </Text>
                                </Link>
                            </Flex>
                    </Flex>
                    
                )},
            },
            {
                Header: "Spend",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.spend || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.results}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.cpc || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.ctr || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.cpm || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.clicks || '-'}</Text>
                    </Flex>
                )
            },
            // {
            //     Header: "Engagement",
            //     accessor: "",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => (
            //         <Flex gap={'10px'}>
            //             <Text fontSize={'12px'}>{original.insight?.engagement}</Text>
            //         </Flex>
            //     )
            // },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.purchaseValue}</Text>
                    </Flex>
                )
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.roas}</Text>
                    </Flex>
                )
            },
            {
                Header: "Objective",
                accessor: "objective",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    //console.log(original);

                    let objective = '';

                    if (original.objective === 'OUTCOME_SALES') {
                        objective = 'Sales';
                    } else if (original.objective === 'OUTCOME_LEADS') {
                        objective = 'Leads';
                    }

                    return <Text>{objective}</Text>;
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    //console.log(original);

                    let statusCopy = '';
                    if (original.status == 'ARCHIVED') {
                        statusCopy = 'PUBLISHED';
                    } else {
                        statusCopy = original.status;
                    }
                    return (
                        <Text>{statusCopy}</Text>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
                ),
            },
        ],
        [selectedCampaignId]
    );

    return (
        <>

            {/* EDIT CAMPAIGN MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Campaign</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={formik.handleSubmit}>
                            <Box width="100%" maxWidth="600px" bg="gray.50" p={4} borderRadius="md">
                                <Flex direction="column" >
                                    <Box mb={4}>
                                        <Flex align="center">
                                            <Text mb={2}>
                                                Campaign Name
                                            </Text>
                                        </Flex>
                                        <Input
                                            placeholder="Enter campaign name"
                                            bg="#fff"
                                            name="name"
                                            size={'sm'}
                                            borderRadius="md"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <Text fontSize={'12px'} color="red.500" mt={1}>{formik.errors.name}</Text>
                                        )}
                                    </Box>
                                </Flex>

                                {/* Submit Button in a separate Flex container */}

                            </Box>
                            <Flex mt={4}>
                                <Button
                                    colorScheme="orange"
                                    isLoading={isCampaignUpdating}
                                    isDisabled={!formik.isValid}
                                    type="submit"
                                    size={'sm'}
                                    mb={'10px'}
                                >
                                    Save
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {isLoading ? (
                <Spinner mt="20px" />
            ) : (
                <AppTableWithPagination
                        columns={columns}
                        data={campaignsList?.data || []}
                        searchEnabled={!isSearchDisabled}
                        isLoading={false}
                        isFetching={isFetching}
                        initialState={{ pageIndex: currentPage - 1 }}
                        paginationMeta={campaignsList?.meta || {}}
                        onPageChange={setCurrentPage}
                        onSearchInputChange={setSearchValue}
                        searchTerm={searchValue}
                />
            )}
        </>
    );
}

export default CampaignsTable;
