import React from 'react';
import { Button, Flex, Box, Icon, Text } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({     
  PageMeta,
  initialState,
  onPageChange,
  isFetching
}) => {

  const {after, before,  pageSize, hasNextPage, hasPrevPage, totalItems} = PageMeta;

  const startItem = pageSize * initialState?.pageIndex + 1;
  const endItem = pageSize * (Number(initialState.pageIndex) + 1);

  const handleNextPage = () => {
    if (hasNextPage) {
      const newPage = Number(initialState.pageIndex) + 1;
      onPageChange({ after, currentPage: newPage });
    }
  };

  const handlePreviousPage = () => {
    if (hasPrevPage) {
      const newPage = Number(initialState.pageIndex) - 1;
      onPageChange({ before, currentPage: newPage });
    }
  };

  return (
    totalItems > pageSize && (
      <Flex align="center" justify={['start', 'end']} mt={4} gap={2}>
        {/* <Text fontSize="sm">
          {totalItems > 0
            ? `${startItem}-${endItem} of ${totalItems}`
            : 'No data available'}
        </Text> */}
        <Text fontSize="sm">
          {totalItems > 0
            ? `${startItem}-${endItem}`
            : 'No data available'}
        </Text>
        <Flex gap={2}>
          <Button
            size="sm"
            onClick={handlePreviousPage}
            isDisabled={!hasPrevPage || isFetching}
          >
            <Icon as={FiChevronLeft} />
          </Button>
          <Button
            size="sm"
            onClick={handleNextPage}
            isDisabled={!hasNextPage || isFetching}
          >
            <Icon as={FiChevronRight} />
          </Button>
        </Flex>
      </Flex>
    )
  );
};

export default Pagination;
