import moment from "moment";
import { api } from "../api";

export const campaignsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createCampaign: builder.mutation({
            query: ({ payload, adsAccountId }) => ({
                url: `/campaigns/${adsAccountId}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Campaigns'],
        }),
        getcampaignsList: builder.query({
            query: ({ pageSize, searchValue, adsAccountId, campaign_ids, after, before }) => {
                let url = `/campaigns/${adsAccountId}?`;
        
                if (campaign_ids && campaign_ids.length > 0) {
                    url += `campaign_ids=${encodeURIComponent(JSON.stringify(campaign_ids))}&`;
                }
                if (pageSize) {
                    url += `pageSize=${pageSize}&`;
                }
                if (searchValue) {
                    url += `search=${searchValue}&`;
                }
                if (after) {
                    url += `after=${after}&`;
                }
                if (before) {
                    url += `before=${before}&`;
                }
                // Remove the trailing "&" if it exists
                url = url.endsWith('&') ? url.slice(0, -1) : url;
        
                return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Campaigns'],
        }),   
        getcampaignsInsights: builder.query({
            query: ({ searchValue, adsAccountId, campaigns, start_date, date_preset, end_date }) => {
                let url = `/campaigns/${adsAccountId}/insights?`;
        
                if (searchValue) {
                    url += `search=${searchValue}&`;
                }
                if (start_date) {
                    url += `start_date=${start_date}&`;
                }
                if (end_date) {
                    url += `end_date=${end_date}&`;
                }
                if (date_preset) {
                    url += `date_preset=${date_preset}&`;
                }
                if (campaigns && campaigns.length > 0) {
                    url += `campaigns=${encodeURIComponent(JSON.stringify(campaigns))}&`;
                }
                // Remove the trailing "&" if it exists
                url = url.endsWith('&') ? url.slice(0, -1) : url;
        
                return { url };
            },
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Campaigns'],
        }), 
        getAllcampaignsInsights: builder.query({
            async queryFn({ searchValue, campaigns, start_date, date_preset, end_date }, _queryApi, _extraOptions, fetchWithBQ) {

                try {
                    const requests = campaigns.map((campaign) => {

                        const { account_id, ...campaignWithoutAccountId } = campaign; 

                        let url = `/campaigns/${account_id}/insights?`;

                        if (searchValue) {
                            url += `search=${searchValue}&`;
                        }
                        if (start_date) {
                            url += `start_date=${start_date}&`;
                        }
                        if (end_date) {
                            url += `end_date=${end_date}&`;
                        }
                        if (date_preset) {
                            url += `date_preset=${date_preset}&`;
                        }
                        if (campaignWithoutAccountId) {
                            // sending the campaign object without the account_id
                            url += `campaigns=${encodeURIComponent(JSON.stringify([campaignWithoutAccountId]))}&`; 
                        }
                        // Remove the trailing "&" if it exists
                        url = url.endsWith('&') ? url.slice(0, -1) : url;

                        return fetchWithBQ(url);
                    });
                    
                    const results = await Promise.all(requests);

                    // Handle errors
                    const hasErrors = results.some((result) => result.error);
                    if (hasErrors) {
                        return { error: { message: 'One or more requests failed.' } };
                    }

                    // Merging all data arrays into a single array
                    const mergedData = results.flatMap((result) => result.data.data);

                    return { data: mergedData };
                } catch (error) {
                    return { error };
                } finally {

                }
            },
        }),
        getcampaignForm: builder.query({
            query: () => ({
                url: `/campaigns/form`,
            }),
        }),
        getcampaignById: builder.query({
            query: ({ campaignId, adsAccountId }) => ({
                url: `/campaigns/get/${campaignId}?adsAccountId=${adsAccountId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Campaigns']
        }),
        updateCampaign: builder.mutation({
            query: ({ campaignId, payload }) => ({
                url: `/campaigns/${campaignId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        updateCampaignStatus: builder.mutation({
            query: ({ campaignId, payload }) => ({
                url: `/campaigns/update/status/${campaignId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        getCampaignInsights: builder.query({
            query: ({ campaignId, startDate, endDate }) => {

                let dateQuery = (moment(startDate)?.isValid() && moment(endDate)?.isValid()) ? `start_date=${startDate}&end_date=${endDate}` : ``;

                return ({
                    url: `/campaigns/insights/${campaignId}?${dateQuery}&limit=1`,
                })
            },
            transformResponse: (response, meta, arg) => response.data,
        }),
        deleteCampaign: builder.mutation({
            query: ({ campaignId, adsAccountId })=> ({
              url: `/campaigns/${campaignId}?adsAccountId=${adsAccountId}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Campaigns']
        }),
        getAdspressoAllCampaigns: builder.query({
            query: ({currentPage}) => ({
                url: `/adspresso/tracklist/adspresso/campaigns?page=${currentPage}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getAdspressoAllCampaignList: builder.query({
            query: ({ ids }) => {
                const idsParam = encodeURIComponent(JSON.stringify(ids));  
                return {
                    url: `/campaigns/adspresso/list?ids=${idsParam}`, 
                };
            },
            transformResponse: (response, meta, arg) => response.data,
        }),
    }),
});

export const { useCreateCampaignMutation, useGetcampaignsListQuery,useGetcampaignsInsightsQuery, useGetAllcampaignsInsightsQuery, useGetcampaignFormQuery, useUpdateCampaignMutation, useGetCampaignInsightsQuery, useUpdateCampaignStatusMutation, useGetcampaignByIdQuery, useDeleteCampaignMutation, useGetAdspressoAllCampaignsQuery, useGetAdspressoAllCampaignListQuery } = campaignsApi;


