import { Box, Text, Input, Spinner, Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, Divider, InputRightElement, InputGroup, InputLeftElement, Checkbox, Flex } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiSearch, FiX } from 'react-icons/fi';

function SearchAdaccountAdspresso({ selectedAccounts, onRemoveAccount, pageMeta = [], isAdAccountFetching, onLoadMore, isForStatusUpload, accountOptions, onAccountChange, onSearchInputChange, onSearchInputTouched, onInitialisation, AdsAccountIdFromParams, isAdAccountLoading, }) {

    const [searchAdAccountValue, setSearchAdAccountValue] = useState('');
    const [selectedAccountValue, setSelectedAccountValue] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { pageSize, after, hasNextPage } = pageMeta;
    const popoverRef = useRef(null);


    // Filter accounts based on searchAdAccountValue
    const filteredAccountsData = accountOptions
        ?.filter(account =>
            account?.name?.toLowerCase().includes(searchAdAccountValue.toLowerCase()) ||
            account?.account_id?.includes(searchAdAccountValue)
        )
        .map(account => ({
            value: account?.account_id,
            label: account?.name?.length > 38 ? account?.name?.slice(0, 44) + '...' : account?.name,
            account,
        })) || [];

    useEffect(() => {

        if (filteredAccountsData.length > 0) {
            setFilteredAccounts((prev) => {
                // Create a set of existing account values (account_ids) in prev (previously filtered accounts)
                const existingAccountValues = new Set(prev.map(account => account.value));

                // Filter out the new accounts that already exist in prev (based on value)
                const newAccounts = filteredAccountsData.filter(account =>
                    !existingAccountValues.has(account.value)
                );

                // If there are new accounts, add them
                const updatedAccounts = [...prev, ...newAccounts];


                return updatedAccounts;
            });
        }
    }, [accountOptions]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchAdAccountValue?.length > 2) {
                onSearchInputChange(searchAdAccountValue);
                onSearchInputTouched(true);
            } else if (searchAdAccountValue?.length === 0) {
                onSearchInputChange('');
            }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchAdAccountValue, onSearchInputChange]);

    //console.log({ searchAdAccountValue });

    const handleScrollToBottom = useCallback(() => {

        if (hasNextPage && !isAdAccountFetching) {
            onLoadMore(after);
        }
    }, [hasNextPage, after, isAdAccountFetching]);

    // Set filteredAccounts to filteredAccountsData when there's a search, else show the full list
    const accountsToShow = searchAdAccountValue.length > 0 ? filteredAccountsData : filteredAccounts;


    useEffect(() => {
        const handleScroll = () => {
            if (!popoverRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = popoverRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 20) {
                handleScrollToBottom();
            }
        };

        const popoverEl = popoverRef.current;
        if (popoverEl) {
            popoverEl.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (popoverEl) {
                popoverEl.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScrollToBottom]);

    // console.log({ accountsToShow });



    return (
        <Box width="280px" position="relative">
            {/* Popover for dropdown */}
            <Popover trigger="click" placement="bottom-start" isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <PopoverTrigger>
                    <Button
                        variant="outline"
                        width="320px"
                        padding="10px"
                        fontSize="13px"
                        textAlign="left"
                        size="sm"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        fontWeight={'normal'}
                        onClick={() => setIsOpen(true)}
                        _hover={{ borderColor: 'gray.500' }}
                        borderColor={isOpen ? 'gray.500' : 'gray.300'}
                    >
                        {isAdAccountLoading ? (
                            <>
                                Loading... <Spinner size="sm" />
                            </>
                        ) : (
                            <>
                                <span>
                                    {selectedAccountValue
                                        ? selectedAccountValue.label.length > 26
                                            ? selectedAccountValue.label.slice(0, 26) + '...'
                                            : selectedAccountValue.label
                                        : 'Select an Ad Account'}
                                </span>
                                <ChevronDownIcon boxSize={5} />
                            </>
                        )}
                    </Button>
                </PopoverTrigger>

                <PopoverContent
                    width="370px"
                    ref={popoverRef}
                    id={'scrollable-menu'}
                    maxHeight="260px" overflowY="auto" border="1px solid" borderColor={'gray.100'} borderRadius="md" >
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        {/* Search Input */}
                        <Box >
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" mt={-1}>
                                    <FiSearch size={18} />
                                </InputLeftElement>
                                <Input
                                    borderRadius={'md'}
                                    value={searchAdAccountValue}
                                    onChange={(e) => setSearchAdAccountValue(e.target.value)}
                                    placeholder="Search for an ad account"
                                    size="sm"
                                />
                                {searchAdAccountValue && (
                                    <InputRightElement mt={-1}>
                                        <FiX
                                            size={18}
                                            cursor="pointer"
                                            onClick={() => setSearchAdAccountValue('')}
                                        />
                                    </InputRightElement>
                                )}
                            </InputGroup>
                        </Box>

                        <Divider my={2} />

                        {/* Infinite Scroll for Filtered Accounts List */}
                        <Box >
                            <InfiniteScroll
                                dataLength={accountsToShow.length}
                                next={handleScrollToBottom}
                                hasMore={hasNextPage}
                                loader={
                                    <Box display="flex" justifyContent="center" padding="10px">
                                        <Spinner size="sm" />
                                    </Box>
                                }
                                scrollThreshold={0.9}
                                scrollableTarget="scrollable-menu"
                            >
                                <Box >
                                    {accountsToShow?.map((account) => {

                                        const isChecked = selectedAccounts?.some((acc) =>
                                            isForStatusUpload ? acc.account_id === account.value : acc.adsAccountId === account.value
                                        );
                                        // console.log({ isChecked });

                                        return (
                                            <Box
                                                key={account.value}
                                                padding="8px"
                                                cursor="pointer"
                                                bg={isChecked ? "#fce4e2" : 'transparent'}
                                                color={'black'}
                                                fontSize={'12px'}
                                                _hover={isChecked ? {} : { backgroundColor: "gray.50" }}
                                            >
                                                <Flex align="center">
                                                    {/* Wrapping the checkbox and label in a Flex to make both clickable */}
                                                    <Checkbox
                                                        w={'100%'}
                                                        flex={1}
                                                        isChecked={isChecked}
                                                        mr={2}
                                                        colorScheme="orange"
                                                        size="sm"
                                                        zIndex={100}
                                                        onChange={() => {
                                                            // console.log('checked', account.value);
                                                            isChecked ? onRemoveAccount(account.value) : onAccountChange(account);
                                                        }}

                                                    >
                                                        <Text fontSize="xs" >{account.label}</Text>
                                                    </Checkbox>
                                                </Flex>
                                            </Box>
                                        );

                                    })}
                                </Box>
                            </InfiniteScroll>
                        </Box>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );

}

export default SearchAdaccountAdspresso;