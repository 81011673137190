import { AddIcon, CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box, Button, Collapse, Flex, Icon, Input, Select, Text, Textarea, Tooltip, useToast,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalHeader
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import { MdClose, MdDelete, MdEdit } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useUpdateAdspressoAdCreativeByIdMutation } from 'store/adspresso/adspressoAdApi';
import { truncateName } from 'utils/functions';
import { object, string } from "yup";
import BuildUrlParamsUpdate from '../BuildUrlParamsUpdate';

let schema = object().shape({
    destinationType: string(),
    name: string().
        max(300, 'select atleast one type'),
    description: string().
        max(300, 'select atleast one type'),
    type: string()
        .required('Type is required'),
    message: string(),
    link: string()
        .when('type', {
            is: (type) => type !== 'CALL_NOW',
            then: () => string().required('Link is required'),
            otherwise: () => string().notRequired(),
        }),
    phoneNumber: string().
        when('type', {
            is: 'CALL_NOW',
            then: () => string().required('Phone number is required'),
            otherwise: () => string().notRequired(),
        })
        .min(10, 'Phone number is too short')
        .max(15, 'Phone number is too long'),
})

function CarouselMediaUpdateForm({ adData, creative, adAccount, onDelete, onChangeImage, onClick, call_to_action_options, conversionType, }) {

    const toast = useToast();
    const [isCollapsed, setisCollapsed] = useState(false);
    const [isUrlModalOpen, setUrlModalOpen] = useState(false);
    const userInfo = useSelector((state) => state.auth?.userInfo);

    const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});

    const { description, image_hash, link, message, name, thumbnail, type, url, video_id, _id } = creative || {}

    const singleMediaFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name || '',
            message: message || '',
            description: description || '',
            type: type,
            link: link || '',
            phoneNumber: link?.includes('tel:+') ? link.replace('tel:+', '') : '',
            url: url || ''
        },
        validationSchema: schema,
        onSubmit: (values, { resetForm }) => {

            const { phoneNumber, name, description, message, url, link, type } = values;
            let link_, type_;
            if (phoneNumber?.length > 0) {
                link_ = `tel:+${phoneNumber}`;
                type_ = 'CALL_NOW';
            } else {
                link_ = link;
                type_ = type;
            }

            let videoId = video_id ? { video_id } : null;
            let imageHash = image_hash ? { image_hash } : null;
            let thumbnail_ = thumbnail ? { thumbnail } : null;

            const payload = {
                name,
                description,
                message,
                url,
                link: link_,
                type: type_,
                ...thumbnail_,
                ...videoId,
                ...imageHash
            };

            // console.log({ payload });

            try {
                updateAdspressocreativeAds({ adcreativeId: _id, payload }).unwrap()
                    .then(response => {
                        toast({
                            position: "top-right",
                            title: "Ad creative updated",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                    })
                    .catch(error => {
                        const errorMessage = error?.data?.message || "An unexpected error occurred";
                        const formattedMessage = errorMessage.replace(/['"]/g, '');

                        toast({
                            position: "top-right",
                            title: "Error updating Ad creative",
                            description: formattedMessage,
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                    });
            } catch (error) {
                // console.log(error);

            } finally {
                setisCollapsed(false);
                resetForm();
            }
        }
    });

    const handleUrlChange = (constructedUrl) => {
        singleMediaFormik.setFieldValue('link', constructedUrl);
    };



    return (
        <>
            <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BuildUrlParamsUpdate link={singleMediaFormik?.values} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box key={url} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200">
                <Flex alignItems="center" gap={2} justify="space-between">
                    <Flex alignItems="center" gap={2}>
                        {url ?
                            image_hash?.length > 0 ? (
                                <img
                                    src={url}
                                    alt={name}
                                    onClick={onClick}
                                    style={{ width: '48px', height: '36px', objectFit: 'fill', borderRadius: '3px' }}
                                />
                            ) : (
                                <video
                                    src={url}
                                    onClick={onClick}
                                    style={{ width: '48px', height: '36px', objectFit: 'fill', borderRadius: '3px' }}
                                />
                            ) : (
                                <AddIcon boxSize={5} ml={2} mr={2} />
                            )}
                        <Flex direction={'column'}>
                            <Text fontSize="10px" noOfLines={1}>
                                {name}
                            </Text>

                            <Flex gap={1}>
                                {singleMediaFormik.values.description ? (
                                    <Text fontSize="10px" noOfLines={1}>{truncateName(singleMediaFormik.values?.description)}</Text>
                                ) : (
                                    <Text fontSize="10px">Enter a description</Text>
                                )}
                            </Flex>
                        </Flex>

                    </Flex>
                    <Flex alignItems="center" gap={'5px'}>
                        <Tooltip label="Edit copy" fontSize="xs">
                            <Box>
                                <Icon
                                    _hover={{ color: 'gray.500' }}
                                    as={isCollapsed ? FiChevronUp : MdEdit}
                                    cursor={userInfo?.role !== 'client' ? 'not-allowed' : 'pointer'}
                                    onClick={() => userInfo?.role === 'client' && setisCollapsed(!isCollapsed)}
                                    color={userInfo?.role !== 'client' ? 'gray.300' : 'inherit'}
                                    pointerEvents={userInfo?.role !== 'client' ? 'none' : 'auto'}
                                />
                            </Box>
                        </Tooltip>
                        <Tooltip label="Remove creative" fontSize="xs">
                            <Box>
                                <Icon
                                    _hover={{ color: 'gray.400' }}
                                    as={MdClose}
                                    cursor={userInfo?.role !== 'client' ? 'not-allowed' : 'pointer'}
                                    boxSize={5}
                                    onClick={() => userInfo?.role === 'client' && onDelete(_id)}
                                    color={userInfo?.role !== 'client' ? 'gray.300' : 'red.500'}
                                    pointerEvents={userInfo?.role !== 'client' ? 'none' : 'auto'}
                                />
                            </Box>
                        </Tooltip>
                    </Flex>
                </Flex>
                <Collapse in={isCollapsed}>
                    <Box p={'10px'}>
                        <Flex direction={'column'}>

                            <Flex position="relative" alignItems="center" gap={4} p={2} borderRadius="md" bg={'#fff'}>
                                {url ? (
                                    image_hash?.length > 0 ? (
                                        <img
                                            src={url}
                                            alt={name}
                                            onClick={onClick}
                                            style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                                        />
                                    ) : (
                                        <video
                                            src={url}
                                            onClick={onClick}
                                            // controls
                                            style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                                        />
                                    )
                                ) : (
                                    <Box p={4}>
                                        <Button
                                            bg={'gray.50'}
                                            borderWidth={1}
                                            size="sm"
                                            onClick={() => {
                                                onChangeImage(creative, adAccount);
                                            }}
                                        >
                                            {image_hash?.length > 0 ? 'Select Image' : 'Select Video'}
                                        </Button>
                                    </Box>
                                )}

                                {/* {url ?
                                <Box position="absolute" top={2} right={2} cursor="pointer" zIndex={1}>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="12px" p="5px">
                                                <Text>
                                                    Remove Image
                                                </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <CloseIcon
                                            mb={2}
                                            color="red.500"
                                            boxSize={3}
                                            pointerEvents="all"
                                            onClick={() => {
                                                onRemoveImage(_id);
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                                : null
                            } */}

                                {url ?
                                    <Flex position="absolute" bottom={2} right={2}>
                                        <Button
                                            bg={'gray.50'}
                                            size="xs"
                                            borderWidth={1}
                                            onClick={() => {
                                                onChangeImage(creative, adAccount);
                                            }}
                                        >
                                            {image_hash?.length > 0 ? 'Change Image' : 'Change Video'}
                                        </Button>
                                    </Flex>
                                    : null
                                }
                            </Flex>

                            <Box mb={'0px'} mt={'10px'}>

                                <Flex mb={'20px'} direction="column">
                                    <Flex alignItems="center" mb={2} gap={2}>
                                        <Text fontSize="14px" fontWeight="bold">Headline</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="14px" p="5px">
                                                    <Text>
                                                        Add up to five brief headlines to let people know what your ad is about. Each
                                                        headline can have a maximum of 255 characters. Headlines won't appear in all
                                                        placements.
                                                    </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
                                    <Input
                                        placeholder="Tell people what your ad is about..."
                                        name="name"
                                        size={'sm'}
                                        borderRadius="md"
                                        value={singleMediaFormik.values.name}
                                        onChange={singleMediaFormik.handleChange}
                                    />
                                </Flex>

                                <Box direction="column" mb={'15px'}>
                                    <Flex alignItems="center" mb={'10px'} gap={2}>
                                        <Text fontSize={'14px'} fontWeight="bold">Description</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="12px" p="5px">
                                                    <Text>
                                                        Add up to five link descriptions to emphasize why people should visit your
                                                        website. This won't appear in all placements.
                                                    </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
                                    <Textarea
                                        placeholder="Include additional details"
                                        name="description"
                                        size={'sm'}
                                        borderRadius="md"
                                        value={singleMediaFormik.values.description}
                                        onChange={singleMediaFormik.handleChange}
                                        rows={2}
                                    />
                                </Box>

                            {/*{singleMediaFormik?.values?.type === 'CALL_NOW' ? (
                            <Box direction="column" mb={'20px'}>
                                <Flex alignItems="center" mb={2} gap={2}>
                                    <Text fontWeight="bold" fontSize={'14px'}>Call to action</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                              <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
                                    <Select
                                        name="type"
                                        borderRadius={'md'}
                                        value={singleMediaFormik.values.type}
                                        onChange={singleMediaFormik.handleChange}
                                        size={'sm'}
                                        isDisabled
                                    >
                                            <option  value={'CALL_NOW'}>
                                                Call Now
                                            </option>
                                    </Select>
                                </Box>
                            ):null}*/}

                               {/* {singleMediaFormik?.values?.type === 'CALL_NOW' ? (
                                    <>
                                        <Box>
                                            <Flex alignItems="center" mt={'10px'} mb={2}>
                                                <Text fontWeight="bold" fontSize={'14px'}>Call now</Text>
                                                <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                                <Tooltip
                                                    borderRadius={5}
                                                    label={
                                                        <Box fontSize="sm" p="5px">
                                                            <Text>You can use a different phone number for each carousel card. This can be helpful if you have different phone numbers or extensions.</Text>
                                                        </Box>
                                                    }
                                                    fontSize="md"
                                                >
                                                    <InfoOutlineIcon pointerEvents="all" />
                                                </Tooltip>
                                            </Flex>
                                            <PhoneInput
                                                country={'in'}
                                                value={singleMediaFormik.values.phoneNumber}
                                                onChange={(value) => {
                                                    singleMediaFormik.setFieldValue('phoneNumber', value)
                                                }}
                                                inputProps={{
                                                    name: 'phoneNumber',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                enableSearch={true}
                                                searchPlaceholder="Search"
                                            />

                                            {singleMediaFormik.touched.phoneNumber && singleMediaFormik.errors.phoneNumber ? (
                                                <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.phoneNumber}</Text>
                                            ) : null}
                                        </Box>
                                    </>
                                ) : null}*/}

                                {!['CALL_NOW', 'SIGN_UP', 'APPLY_NOW', 'SUBSCRIBE', 'GET_OFFER', 'GET_QUOTE', 'DOWNLOAD', 'BOOK_TRAVEL'].includes(singleMediaFormik?.values?.type) ? (
                                    <>
                                        <Flex mb={4} direction="column">
                                            <Flex alignItems="center" gap={2} mb={'10px'}>
                                                <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
                                                <Tooltip
                                                    borderRadius={5}
                                                    label={
                                                        <Box fontSize="sm" p="5px">
                                                            <Text>Enter the URL for the webpage you want people to visit</Text>
                                                        </Box>
                                                    }
                                                    fontSize="md"
                                                >
                                                    <InfoOutlineIcon pointerEvents="all" />
                                                </Tooltip>
                                            </Flex>
                                            <Flex direction={'column'} gap={1}>
                                                <Input
                                                    type="url"
                                                    name="link"
                                                    borderRadius={'md'}
                                                    placeholder="Enter URL"
                                                    value={singleMediaFormik.values.link}
                                                    onChange={singleMediaFormik.handleChange}
                                                    size={'sm'}
                                                />
                                                <Flex mt={2}>
                                                    <Button onClick={() => {
                                                        setUrlModalOpen(true)
                                                    }}
                                                        variant="link">
                                                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                                    </Button>
                                                </Flex>
                                                {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
                                                    <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
                                                ) : null}
                                            </Flex>
                                        </Flex>
                                    </>
                                ) : null}

                                <Flex justifyContent="flex-start" mt={'20px'}>
                                    <Button
                                        colorScheme="orange"
                                        onClick={() => singleMediaFormik.handleSubmit()}
                                        size={'sm'}
                                        isLoading={isAdspressoAdsetUpdating}
                                    >
                                        Save
                                    </Button>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </Collapse>
            </Box>
        </>
    )
}

export default CarouselMediaUpdateForm;
