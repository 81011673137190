import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SidebarWithHeader from "components/SidebarWithHeader";
import {
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { useSelector } from 'react-redux';
import { useGetcampaignFormQuery } from "store/campaigns/campaignsApi";
import { campaignFormSchema } from "utils/schema";
import AdspressoCampaignForm from "components/campaignForms/AdspressoCampaignForm";
import AdspressoTable from "components/campaignTables/AdspressoTable";
import { useCreateAdspressoCampaignMutation } from "store/adspresso/adspressoCampaignApi";

function AdspressoForClient() {
  const toast = useToast();
  const navigate = useNavigate();
  const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
  const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQueryTriggered, setIsQueryTriggered] = useState(false);


  const {
    data: dataCampaign,
    error: errorCampaign,
    isLoading: isLoadingCampaign,
  } = useGetcampaignFormQuery(undefined, { skip: !isQueryTriggered });

  const [createCampaignData, { isLoading: isCreating, error: createError }] =
    useCreateAdspressoCampaignMutation();

  const campaignFormik = useFormik({
    initialValues: {
      adsAccounts: [],
      name: "",
      objective: "OUTCOME_LEADS",
      status: "PAUSED",
    },
    validationSchema: campaignFormSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name, objective, status, adsAccounts } = values;
      const modifiedAdsAccounts = adsAccounts.map(
        ({ currency, ...rest }) => rest
      );
      const payload = {
        name,
        objective,
        status,
        adsAccounts: modifiedAdsAccounts,
      };

      createCampaignData({ payload })
        .unwrap()
        .then((response) => {
          toast({
            position: "top-right",
            title: "Campaign created",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          resetForm();
          handleCloseModal();
          navigate(`/adspresso-campaigns/${response.data._id}`);
        })
        .catch((error) => {
          toast({
            position: "top-right",
            title: "Error in creating",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsQueryTriggered(true); 
  };

  const handleCloseModal = () => {
    campaignFormik.resetForm();
    setIsModalOpen(false);
    setIsQueryTriggered(false); 
  };

  const handleAdsAccountChange = (selectedIds) => {
    const selectedAccounts = activeAccountsData?.data
      .filter((account) => selectedIds.includes(account.adsAccountId))
      .map((account) => ({
        adsAccountName: account.adsAccountName,
        adsAccountId: account.adsAccountId,
        id: account._id,
        businessName: account?.businessAccount?.businessName,
        businessId: account?.businessAccount?.businessId,
        currency: account.currency,
      }));

    const currencies = selectedAccounts.map((account) => account.currency);
    const uniqueCurrencies = [...new Set(currencies)];

    if (uniqueCurrencies.length > 1) {
      campaignFormik.setFieldError(
        "adsAccounts",
        "Selected Ad Accounts have different currencies."
      );
    } else {
      campaignFormik.setFieldValue("adsAccounts", selectedAccounts);
    }
  };

  return (
    <>
      {hasActiveAdAccount ? (
        <>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent maxWidth="500px">
              <ModalHeader>Create new campaign</ModalHeader>
              <ModalCloseButton />
              <ModalBody >
                {/* Ad Accounts */}
                <Flex direction="column" mb={"20px"} maxHeight="250px" overflowY="auto">
                  <Text mb={2} fontWeight={"bold"}>
                    Choose Ad Accounts
                  </Text>
                  <CheckboxGroup
                    colorScheme={"orange"}
                    id="adsAccount"
                    value={campaignFormik.values.adsAccounts.map(
                      (account) => account.adsAccountId
                    )}
                    onChange={handleAdsAccountChange}
                  >
                    <Stack spacing={2}>
                      {activeAccountsData?.data?.map((account) => (
                        <Checkbox key={account._id} value={account.adsAccountId}>
                          <Text fontSize="14px" fontWeight="bold">
                            {account.adsAccountName}
                          </Text>
                          <Flex gap={"5px"}>
                            <Text fontSize="12px" color="gray.500">
                              {account.adsAccountId}
                            </Text>
                            <Text
                              fontSize="12px"
                              color="gray.500"
                              fontWeight={"bold"}
                            >
                              ({account.currency})
                            </Text>
                          </Flex>
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                  {campaignFormik.errors.adsAccounts && (
                    <Text fontSize={"12px"} color="red.500" mt={"5px"}>
                      {campaignFormik.errors.adsAccounts}
                    </Text>
                  )}
                </Flex>

                <AdspressoCampaignForm
                  values={campaignFormik.values}
                  handleChange={campaignFormik.handleChange}
                  handleBlur={campaignFormik.handleBlur}
                  errors={campaignFormik.errors}
                  touched={campaignFormik.touched}
                  dataCampaign={dataCampaign}
                  isLoadingCampaign={isLoadingCampaign}
                  errorCampaign={errorCampaign}
                  isSubmitting={campaignFormik.isSubmitting || isCreating}
                  handleSubmit={campaignFormik.handleSubmit}
                  createError={createError}
                />
              </ModalBody>
            </ModalContent>
          </Modal>

          <SidebarWithHeader>
            <>
              <Button colorScheme="orange" onClick={handleOpenModal}>
                <AddIcon boxSize={3} />
                &nbsp;Create
              </Button>

              <Box py={"20px"}>
                <AdspressoTable />
              </Box>
            </>
          </SidebarWithHeader>
        </>
      ) : (
        <SidebarWithHeader>
          <Flex>
            <Text>
              Your Ad account is not set up yet. Please request an Ad account.
            </Text>
          </Flex>
        </SidebarWithHeader>
      )}
    </>
  );
}

export default AdspressoForClient;
