import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isWidgetOpen: false,
    files: [],
    selectedFiles: [], 
    uploadStatus: {},
    uploadAbortControllers: {},
};

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setIsWidgetOpen(state, action) {
            state.isWidgetOpen = action.payload;
        },
        setFiles(state, action) {
            state.files = action.payload;
        },
        setSelectedFiles(state, action) { 
            state.selectedFiles = action.payload;
        },
        updateUploadStatus: (state, action) => {
            const { fileName, accountId, status, progress,reason } = action.payload;
            if (!state.uploadStatus[fileName]) {
                state.uploadStatus[fileName] = {};
            }
            state.uploadStatus[fileName][accountId] = { status, progress,reason };
        },
        resetUpload(state) {
            state.files = [];
            state.selectedFiles = []; 
            state.uploadStatus = {};
            state.currentAdAccount = null;
        },
        setUploadAbortControllers(state, action) {
            state.uploadAbortControllers = {
                ...state.uploadAbortControllers,
                ...action.payload,
            };
        },
        cancelUpload: (state, action) => {
            const { fileName, accountId } = action.payload;
            const key = `${fileName}-${accountId}`;

            if (state.uploadStatus[fileName] && state.uploadStatus[fileName][accountId]) {
                // console.log(`Cancelling upload for ${fileName} (Account: ${accountId})`);
                state.uploadStatus[fileName][accountId].status = 'cancelled';
                state.uploadStatus[fileName][accountId].progress = 0;

                const abortController = state.uploadAbortControllers[key];
                
                if (abortController) {
                    abortController.abort(); // Abort the ongoing request
                }

                delete state.uploadAbortControllers[key];   
            }
        },
    },
});

export const {
    setIsWidgetOpen,
    setFiles,
    setSelectedFiles,
    updateUploadStatus,
    resetUpload,
    setUploadAbortControllers,
    cancelUpload
} = uploadSlice.actions;

export default uploadSlice.reducer;
