import { api } from "../api";

export const adspressoAdsetFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createAdspressoAdsets: builder.mutation({
            query: ({ payload }) => ({
                url: '/adspresso/adsets/',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['AdspressoAdsets', 'AdspressoCampaign'],
        }),
        getAdspressoAdsetsByCampainId: builder.query({
            query: (campaignId) => ({
                url: `/adspresso/adsets/campaign/${campaignId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdspressoAdsets'],
        }),
        getAdspressoAdsetById: builder.query({
            query: (adsetId) => ({
                url: `/adspresso/adsets/${adsetId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        updateAdspressoAdset: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adspresso/adsets/${adsetId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        removeAdspressoAdset: builder.mutation({
            query: (adsetId) => ({
                url: `/adspresso/adsets/${adsetId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdspressoAdsets'],
        }),
    }),
});

export const { useCreateAdspressoAdsetsMutation, useRemoveAdspressoAdsetMutation, useGetAdspressoAdsetByIdQuery, useGetAdspressoAdsetsByCampainIdQuery, useUpdateAdspressoAdsetMutation } = adspressoAdsetFormApi;
