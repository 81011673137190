import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Divider,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio, RadioGroup,
    Select,
    Spinner,
    Switch,
    Table,
    Tbody,
    Td,
    Text,
    Textarea,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast,
    Checkbox,
} from '@chakra-ui/react';
import BuildUrlParamsUpdate from 'components/adspresso/BuildUrlParamsUpdate';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import InstagramAccountUpdateSelector from 'components/adspresso/InstagramAccountUpdateSelector';
import UrlParametersUpdate from 'components/adspresso/UrlParametersUpdate';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useGetAdCreativeQuery, useGetInstantFormsByPageIdQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import * as Yup from 'yup';
import AdPreviews from './AdPreviews';


const SingleVideoAdUpdateForm = ({ adData, adsAccountData, call_to_action_options, call_to_action_options_instantForms, onClose }) => {
    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);
    const [isUrlModalOpen, setUrlModalOpen] = useState(false);
    const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);

    const { data: adCreative, isLoading } = useGetAdCreativeQuery(
        {
            creativeId: adData?.id,
            adsAccountId: adsAccountData?.account_id
        },
        { skip: !adData?.id }
    );

    const [updateAd] = useUpdateAdMutation();


    const pageId = adData?.adset?.promoted_object?.page_id;

    const formattedPageIds = Array.isArray(pageId) ? pageId : [pageId];

    const { data: instantForms } = useGetInstantFormsByPageIdQuery(
        { pageIds: formattedPageIds },
        { skip: !formattedPageIds.length || adData?.adset?.destination_type !== 'ON_AD' }
    );

    let pageIdToSend = null;
    if (
        adCreative?.campaign?.objective === "OUTCOME_LEADS" &&
        adCreative?.adset?.destination_type === "PHONE_CALL" &&
        adCreative?.adset?.promoted_object?.page_id
    ) {
        pageIdToSend = adCreative.adset.promoted_object.page_id;
    }


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        cpa: Yup.number()
            .typeError('must be a number')
            .positive('must be a positive number'),
        creative: Yup.object().shape({
            // name: Yup.string().required('Headline is required'),
            object_story_spec: Yup.object().shape({
                video_data: Yup.object().shape({
                    // message: Yup.string().required('Primary Text is required'),
                    // title: Yup.string().required('Headline is required'),
                    // link_description: Yup.string().required('Description is required'),
                    video_id: Yup.string().required('Video ID is required'),
                    call_to_action: Yup.object().shape({
                        type: Yup.string().required('Call to action type is required'),
                        value: Yup.object().shape({
                            destinationType: Yup.string(),
                            link: Yup.string()
                                .when('destinationType', {
                                    is: (value) => value === 'PHONE_CALL',
                                    then: () => Yup.string()
                                        .transform((value) => {
                                            if (typeof value === 'string') {
                                                return value.replace(/^tel:\+?/, ''); // Removes "tel:" and "+" if present
                                            }
                                            return value;
                                        })
                                        .matches(/^[0-9]{10,15}$/, 'Phone number must be between 10 and 15 digits')
                                        .required('Phone number is required'),
                                    otherwise: () => Yup.string().notRequired(),
                                }),
                        }),
                    }),
                }),
                page_id: Yup.string().required('Facebook Page is required'),
                // instagram_actor_id: Yup.string().required('Instagram Actor ID is required'),
            }),
        }),
        image_url: Yup.string().url('Invalid URL'),
    });

    const formik = useFormik({
        initialValues: {
            name: adData?.name || '',
            hash: adData?.hash || '',
            cpa: adData?.cpa || '',
            page_backed_insta_id: '',
            enroll_status: adCreative?.creative?.contextual_multi_ads?.enroll_status ? adCreative?.creative?.contextual_multi_ads?.enroll_status : 'OPT_OUT',
            creative: {
                name: adCreative?.creative?.name || '',
                url_tags: adCreative?.creative?.url_tags || '',
                object_story_spec: {
                    video_data: {
                        message: adCreative?.creative?.object_story_spec?.video_data?.message || '',
                        title: adCreative?.creative?.object_story_spec?.video_data?.title || '',
                        link_description: adCreative?.creative?.object_story_spec?.video_data?.link_description || '',
                        video_id: adCreative?.creative?.object_story_spec?.video_data?.video_id || '',
                        call_to_action: {
                            type: adCreative?.creative?.object_story_spec?.video_data?.call_to_action?.type,
                            value: {
                                destinationType: adCreative?.adset?.destination_type ? adCreative?.adset?.destination_type : '',
                                link: adData?.adset?.destination_type === 'ON_AD' ? adCreative?.creative?.object_story_spec?.video_data?.call_to_action?.value?.lead_gen_form_id : adCreative?.creative?.object_story_spec?.video_data?.call_to_action?.value?.link
                            },
                        },
                    },
                    page_id: adCreative?.creative?.object_story_spec?.page_id || '',
                    instagram_actor_id: adCreative?.creative?.object_story_spec?.instagram_actor_id || '',
                },
            },
            image_url: adCreative?.creative?.image_url || '',
            picture: adCreative?.creative?.picture || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {

            const isCreativeChanged = () => {
                const original = adCreative?.creative || {};
                const updated = values.creative || {};

                // Compare specific fields
                return (
                    original.name !== updated.name ||
                    original.object_story_spec?.video_data?.message !== updated.object_story_spec?.video_data?.message ||
                    original.object_story_spec?.video_data?.title !== updated.object_story_spec?.video_data?.title ||
                    original.object_story_spec?.video_data?.link_description !== updated.object_story_spec?.video_data?.link_description ||
                    original.object_story_spec?.video_data?.video_id !== updated.object_story_spec?.video_data?.video_id ||
                    original.object_story_spec?.video_data?.call_to_action?.type !== updated.object_story_spec?.video_data?.call_to_action?.type ||
                    original.object_story_spec?.video_data?.call_to_action?.value?.link !== updated.object_story_spec?.video_data?.call_to_action?.value?.link ||
                    original.object_story_spec?.page_id !== updated.object_story_spec?.page_id ||
                    original.object_story_spec?.instagram_actor_id !== updated.object_story_spec?.instagram_actor_id ||
                    original.image_url !== values.image_url ||
                    original.url_tags !== updated.url_tags
                );
            };

            const formatPhoneNumber = (phoneNumber) => {

                if (!phoneNumber.startsWith('tel:+')) {
                    return `tel:+${phoneNumber}`;
                }
                return phoneNumber;
            };

            const hasCreativeChanged = isCreativeChanged();

            const payload = {
                ...(hasCreativeChanged && {
                    creative: {
                        name: values.creative.name,
                        hash: values.hash,
                        ...(values.creative?.url_tags?.trim() && { url_tags: values.creative.url_tags }),
                        object_story_spec: {
                            video_data: {
                                image_url: values.image_url,
                                ...(values.creative.object_story_spec.video_data.message?.trim() && {
                                    message: values.creative.object_story_spec.video_data.message
                                }),
                                video_id: values.creative.object_story_spec.video_data.video_id,
                                title: values.creative.object_story_spec.video_data.title,
                                link_description: values.creative.object_story_spec.video_data.link_description,
                                call_to_action: {
                                    type: values.creative.object_story_spec.video_data.call_to_action.type,
                                    value: adData?.adset?.destination_type === 'ON_AD'
                                        ? {
                                            lead_gen_form_id: values.creative.object_story_spec.video_data.call_to_action?.value?.link
                                        }
                                        : {
                                            link: values.creative.object_story_spec.video_data.call_to_action.type === "CALL_NOW"
                                                ? formatPhoneNumber(values.creative.object_story_spec.video_data.call_to_action.value.link)
                                                : values.creative.object_story_spec.video_data.call_to_action.value.link
                                        }
                                }
                            },
                            page_id: values.creative.object_story_spec.page_id,
                            ...(values.creative.object_story_spec.instagram_actor_id?.trim() && {
                                instagram_actor_id: values.creative.object_story_spec.instagram_actor_id,
                            }),
                        },
                        contextual_multi_ads: {
                            enroll_status: values.enroll_status,
                        },
                        degrees_of_freedom_spec: {
                            creative_features_spec: {
                                standard_enhancements: {
                                    enroll_status: "OPT_OUT"
                                }
                            }
                        },
                    },
                }),
                ...(values.name !== adData.name ? { name: values.name } : {}), // Include name only if it has changed
                ...(values.cpa && values.cpa !== '' && values.cpa !== adData.cpa ? { cpa: values.cpa } : {}),
                adsAccount: {
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                },
            };

            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        description: error?.data?.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            formik.setFieldValue('hash', selectedFile?.hash);
            formik.setFieldValue('image_url', selectedFile?.picture);
            formik.setFieldValue('creative.object_story_spec.video_data.video_id', selectedFile?.creativeId);
        }
        setIsDriveModalOpen(false);
    };


    if (isLoading) {
        return <Flex justify="center" align="center" height="60vh">
            <Spinner size="md" />
        </Flex>
    }

    const handleUrlChange = (constructedUrl) => {
        formik.setFieldValue('creative.object_story_spec.video_data.call_to_action.value.link', constructedUrl);
    };

    const handleUrlParamChange = (constructedUrl) => {
        formik.setFieldValue('creative.url_tags', constructedUrl);
    };

    const filteredInstantForms = instantForms?.length > 0 && instantForms
        .map((formResponse) => formResponse?.data?.data) // Get the 'data' array from each formResponse
        .flat() // Flatten the array to get all forms in one array
        .filter((form) => form?.page_id === pageId);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex gap={4}>
                <Box direction={'column'} gap={4} width="100%" flex={0.4}>
                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Ad Name</Text>
                            <Input
                                name="name"
                                placeholder="Enter your ad name here..."
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                            )}
                        </Flex>

                    <Flex direction="column" gap={1} mb={2}>
                        <Text fontWeight="bold">Facebook Page</Text>
                        <FaceBookPageSelector
                            adsAccount={adsAccountData}
                            selectedPageId={formik.values.creative?.object_story_spec?.page_id}
                            pageIdToSend={pageIdToSend}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.page_id', pageId);

                                // Reset the Instagram actor ID to an empty string
                                formik.setFieldValue('creative.object_story_spec.instagram_actor_id', '');
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.page_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                        )}
                    </Flex>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Instagram accounts</Text>
                        <InstagramAccountUpdateSelector
                            pageID={formik.values.creative?.object_story_spec?.page_id}
                            adsAccount={adsAccountData}
                            selectedInstPageId={formik.values.creative?.object_story_spec?.instagram_actor_id}
                            onSelectedPage={(pageId) => {
                                formik.setFieldValue('creative.object_story_spec.instagram_actor_id', pageId);
                            }}
                            onPageBack={(pageId) => {
                                formik.setFieldValue('page_backed_insta_id', pageId);
                            }}
                        />
                        {formik.errors.creative?.object_story_spec?.instagram_actor_id && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.instagram_actor_id}</Text>
                        )}
                    </Flex>
                </Flex>

                <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Flex direction="row" mb={4} gap={2} alignItems={'center'}>
                        <Flex direction={'column'} gap={2}>
                            <Text fontWeight="bold">Creative</Text>
                            {false ? (
                                <Spinner size="sm" color="gray.500" />
                            ) : (
                                formik.values.image_url && (
                                    <img
                                        src={formik.values.image_url}
                                        alt={formik.values.name}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                            border: '0.5px solid grey',
                                            borderRadius: '5px',
                                        }}
                                    />
                                )
                            )}
                        </Flex>
                        <Flex mt={6}>
                            <Button
                                bg={'gray.50'}
                                size="xs"
                                borderWidth={1}
                                onClick={handleOpenDriveModal}>
                                Change Video
                            </Button>
                        </Flex>
                    </Flex>

                    {/* Multi-advertiser ads Section */}
                    <Flex direction="column" mb={4} borderRadius="md" gap={2}>
                        <Checkbox
                            onChange={(e) => {
                                formik.setFieldValue("enroll_status", e.target.checked ? 'OPT_IN' : 'OPT_OUT');
                            }}
                            colorScheme="orange"
                            isChecked={formik.values.enroll_status === 'OPT_IN'}
                        >
                            <Flex align={'center'}>
                                <Text fontWeight="bold" fontSize={'14px'}>Multi-advertiser ads &nbsp;</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p="5px">
                                            <Text>Your ads can appear alongside other ads in the same ad unit to help people discover products and services from businesses that are personalized to them. Your ad creative may be resized or cropped to fit the ad unit.</Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>
                        </Checkbox>
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Primary Text</Text>
                        <Textarea
                            name="creative.object_story_spec.video_data.message"
                            placeholder="Message"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.video_data.message}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.video_data?.message && formik.errors.creative?.object_story_spec?.video_data?.message && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.message}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Headline</Text>
                        <Input
                            name="creative.name"
                            placeholder="Enter a headline"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.name}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.name && formik.errors.creative?.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.name}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Description</Text>
                        <Textarea
                            name="creative.object_story_spec.video_data.link_description"
                            placeholder="Include additional details"
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            value={formik.values.creative.object_story_spec.video_data.link_description}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.creative?.object_story_spec?.video_data?.link_description && formik.errors.creative?.object_story_spec?.video_data?.link_description && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.link_description}</Text>
                        )}
                    </Flex>

                    <Flex direction="column" gap={1}>
                        <Text fontWeight="bold">Call to Action</Text>

                        {adData?.adset?.destination_type === 'PHONE_CALL' ? (
                            <Select
                                name="creative.object_story_spec.video_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.video_data.call_to_action.type}
                                isDisabled
                            >
                                <option value="CALL_NOW">Call Now</option>
                            </Select>
                        ) : adData?.adset?.destination_type === 'WEBSITE' ? (
                            <Select
                                name="creative.object_story_spec.video_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.video_data.call_to_action.type}
                            >
                                {call_to_action_options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        ) : (
                            <Select
                                name="creative.object_story_spec.video_data.call_to_action.type"
                                bg="#fff"
                                size="sm"
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.creative.object_story_spec.video_data.call_to_action.type}
                            >
                                {call_to_action_options_instantForms?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        )}

                        {formik.touched.creative?.object_story_spec?.video_data?.call_to_action?.type &&
                            formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.type && (
                                <Text fontSize="12px" color="red.500">
                                    {formik.errors.creative.object_story_spec.video_data.call_to_action.type}
                                </Text>
                            )}
                    </Flex>

                    {adData?.adset?.destination_type === 'PHONE_CALL' && (
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Phone Number</Text>
                            <PhoneInput
                                name="creative.object_story_spec.video_data.call_to_action.value.link"
                                value={formik.values.creative.object_story_spec.video_data.call_to_action.value.link}
                                onChange={(value) => formik.setFieldValue('creative.object_story_spec.video_data.call_to_action.value.link', value)}
                                country={'in'}
                            />
                            {formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.value?.link && (
                                <Text fontSize="12px" color="red.500">{formik.errors.creative.object_story_spec.video_data.call_to_action.value.link}</Text>
                            )}
                        </Flex>
                    )}

                    {adData?.adset?.destination_type === 'WEBSITE' ? (
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Website URL</Text>
                                <Input
                                    name="creative.object_story_spec.video_data.call_to_action.value.link"
                                    placeholder="Enter a URL"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    value={formik.values.creative.object_story_spec.video_data.call_to_action.value.link}
                                    onChange={formik.handleChange}
                                />
                                <Flex mt={2}>
                                    <Button onClick={() => {
                                        setUrlModalOpen(true)
                                    }}
                                        variant="link">
                                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                    </Button>
                                </Flex>
                                {formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.value?.link && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.video_data?.call_to_action?.value?.link}</Text>
                                )}
                            </Flex>
                        ) : (null)}


                        {adData?.adset?.destination_type === 'ON_AD' && adData?.campaign?.objective === 'OUTCOME_LEADS' ? (
                            <>
                                <Flex alignItems="center" gap={2} mt={2}>
                                    <Text fontWeight="bold">Instant form</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                {/* Table Layout */}
                                {filteredInstantForms?.length > 0 ? (
                                    <RadioGroup onChange={(value) => formik.setFieldValue('creative.object_story_spec.video_data.call_to_action.value.link', value)} value={formik.values.creative.object_story_spec.video_data.call_to_action.value.link} colorScheme="orange">
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                                                    <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {filteredInstantForms?.map((form) => (
                                                    <Tr key={form?.id} _hover={{ backgroundColor: '#F9F9F9' }} >
                                                        <Td>
                                                            <Flex alignItems="center" gap={2}>
                                                                <Radio value={form?.id} />
                                                                <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                                                    {form?.name}
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            <Text fontSize={'12px'}>
                                                                {new Date(form?.created_time).getFullYear()}-
                                                                {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                                                {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                                            </Text>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </RadioGroup>
                                ) : (
                                    <Text fontSize="14px" color="gray.500">No form data available</Text>
                                )}
                            </>
                        ) : null}
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Text fontWeight={'bold'}>Tracking</Text>
                        <Flex direction="column" gap={1}>
                            <Flex alignItems={'center'}>
                                <Text mb={2} fontWeight="bold">
                                    URL parameters{" "}
                                    {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                                    <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                                </Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text >
                                                Add parameters to the end of your website URL to track where your visitors are coming from.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                </Tooltip>
                            </Flex>
                            <Input
                                name="creative.url_tags"
                                placeholder="key1=value1&key2=value2"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.creative.url_tags}
                                onChange={formik.handleChange}
                            />
                            <Flex mt={2}>
                                <Button onClick={() => {
                                    setUrlParamModalOpen(true)
                                }}
                                    variant="link">
                                    <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                </Button>
                            </Flex>
                            {formik.touched.creative?.url_tags && formik.errors.creative?.url_tags && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.url_tags}</Text>
                            )}
                        </Flex>
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">CPA</Text>
                            <Input
                                name="cpa"
                                placeholder="Enter CPA"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.cpa}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.cpa && formik.errors.cpa && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.cpa}</Text>
                            )}
                        </Flex>
                    </Flex>
                    <Flex mb={4}>
                        <Button
                            size="sm"
                            colorScheme="orange"
                            isLoading={formik.isSubmitting}
                            type="submit"
                            isDisabled={!formik.isValid}
                        >
                            Save
                        </Button>
                    </Flex>
                </Box>
                <Box
                    pos={'relative'}
                    flex={0.6}
                    px={4} borderRadius={'md'} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)">
                    <Flex py={2} gap={2}>
                        <Switch isChecked={isEnabled} onChange={() => setIsEnabled(prevState => !prevState)} />
                        <Text fontWeight={'bold'} fontSize={'14px'}>Ad preview  </Text>
                    </Flex>
                    <Divider />
                    {isEnabled &&
                        <AdPreviews adCreative={adCreative} file={formik?.values} adsAccountData={adsAccountData} />
                    }
                    <Flex p={2} alignItems={'center'} position={'absolute'} bottom={0}>
                        <Text fontSize={'12px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="12px" p="5px">
                                    <Text>
                                        Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                    </Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                        </Tooltip>
                    </Flex>
                </Box>

            </Flex>

            <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BuildUrlParamsUpdate link={formik.values.creative.object_story_spec?.video_data?.call_to_action?.value} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <UrlParametersUpdate link={formik.values.creative} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccountData?.id,
                    adsAccountId: adsAccountData?.account_id,
                    adsAccountName: adsAccountData?.name,
                    businessName: adsAccountData?.business?.name,
                    businessId: adsAccountData?.business?.id
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType="video"
            />
        </form>
    );
};

export default SingleVideoAdUpdateForm;