import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    useToast,
    Select,
    Textarea,
    Switch,
    Divider,
    Tooltip,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Checkbox,
    RadioGroup,
    Radio,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdatePublishAdMutation } from 'store/publishTracking/trackingApi';
import DriveModal from 'components/drive/DriveModal';
import FaceBookPageAdspressoSelector from '../FaceBookPageAdspressoSelector';
import BuildUrlParamsUpdate from '../BuildUrlParamsUpdate';
import InstagramAccountSelector from '../InstagramAccountSelector';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import PublishAdPreviews from './PublishAdPreviews';
import UrlParametersUpdate from '../UrlParametersUpdate';
import PhoneInput from 'react-phone-input-2';
import { useGetInstantFormsByPageIdQuery } from 'store/campaigns/adsApi';


const SingleCreativeAdupdate = ({ trackingData, targeting, onClose, onSaveSuccess, call_to_action_options, call_to_action_options_instantForms }) => {
    const { adsAccount, form, _id, adset } = trackingData;
    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);
    const [isUrlModalOpen, setUrlModalOpen] = useState(false);
    const [isUrlParamModalOpen, setUrlParamModalOpen] = useState(false);

    const [updatePublishAd] = useUpdatePublishAdMutation();
    
    const pageId = adset?.promoted_object?.page_id;

    const formattedPageIds = Array.isArray(pageId) ? pageId : [pageId];

    const { data: instantForms } = useGetInstantFormsByPageIdQuery(
        { pageIds: formattedPageIds },
        { skip: !formattedPageIds.length || adset?.destination_type !== 'ON_AD' }
    );

    const initialValues = {

        name: form.name || "",
        cpa: form.cpa || "",
        identity: {
            page_id: form?.identity?.page_id || "",
            instagram_actor_id: form?.identity?.instagram_actor_id || "",
        },
        destination_type: adset?.destination_type,
        adcreative: {
            _id: form.adcreative?._id || "",
            message: form.adcreative?.message || "",
            url_tags: form?.adCreative?.url_tags || '',
            name: form.adcreative?.name || "",
            description: form.adcreative?.description || "",
            type: form.adcreative?.type || "",
            hash: form.adcreative?.hash || "",
            link: form.adcreative?.link || "",
            phoneNumber: form.adcreative?.link?.includes('tel:+') ? form.adcreative?.link.replace('tel:+', '') : '',
            video_id: form.adcreative?.video_id || "",
            thumbnail: form.adcreative?.thumbnail || "",
            image_hash: form.adcreative?.image_hash || "",
            url: form.adcreative?.url || "",
            enroll_status: form?.adcreative?.contextual_multi_ads?.enroll_status ? form?.adcreative?.contextual_multi_ads?.enroll_status :  'OPT_OUT',
        },
    };
    // Validation Schema
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        identity: Yup.object().shape({
            page_id: Yup.string().required('Facebook Page is required'),
        }),
        adcreative: Yup.object().shape({
            // message: Yup.string().required('Message is required'),
            name: Yup.string(),
            description: Yup.string(),
            type: Yup.string().required('Type is required'),
            link: Yup.string()
                .when('type', {
                    is: (value) => value !== 'CALL_NOW',
                    then: () => Yup.string()
                        .required('URL is required'),
                    otherwise: () => Yup.string().notRequired(),
            }),
            phoneNumber: Yup.string().
                when('type', {
                    is: (value) => value === 'CALL_NOW',
                    then: () => Yup.string()
                        .transform((value) => value.slice(2)) // Remove the first two digits (e.g., country code like "91")
                        .matches(/^[0-9]{10,15}$/, 'Phone number must be at least 10 digits') // Ensure remaining digits are at least 10 digits
                        .required('Phone number is required'), // Ensure phone number is provided
                    otherwise: () => Yup.string().notRequired(),
            })
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            const payload = {
                name: values.name,
                cpa: values.cpa,
                identity: {
                    page_id: values.identity.page_id,
                    ...(values?.identity?.instagram_actor_id.trim() && {
                        instagram_actor_id: values?.identity?.instagram_actor_id,
                    }),
                },
                adcreative: {
                    contextual_multi_ads:{
                        enroll_status: values?.adcreative?.enroll_status,
                    },
                    _id: values.adcreative._id,
                    ...(values.adcreative.message?.trim() && { message: values.adcreative.message }),
                    name: values.adcreative.name,
                    description: values.adcreative.description,
                    type: values.adcreative.type,
                    link: values.adcreative.type === "CALL_NOW" ? `tel:+${values.adcreative.phoneNumber}`: values.adcreative.link,
                    ...(values.adcreative?.url_tags?.trim() && { url_tags: values.adcreative.url_tags }),
                    hash: values.adcreative.hash,
                    ...(formik.values.adcreative.video_id && {
                        video_id: values.adcreative.video_id,
                        thumbnail: values.adcreative.thumbnail,
                    }),
                    ...(formik.values.adcreative.image_hash && {
                        image_hash: values.adcreative.image_hash,
                        url: values.adcreative.url,
                    }),
                },
            };
            // console.log("payload",payload)
            updatePublishAd({ trackingId: _id, payload })
                .unwrap()
                .then(response => {
                    onSaveSuccess();
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];

            if (formik.values?.adcreative?.image_hash) {
                formik.setFieldValue('adcreative.image_hash', selectedFile?.creativeId);
                formik.setFieldValue('adcreative.url', selectedFile?.url);
                formik.setFieldValue('adcreative.hash', selectedFile?.hash);
            } else {
                formik.setFieldValue('adcreative.video_id', selectedFile?.creativeId);
                formik.setFieldValue('adcreative.thumbnail', selectedFile?.picture);
                formik.setFieldValue('adcreative.hash', selectedFile?.hash);
            }
        }
        setIsDriveModalOpen(false);
    };

    const handleUrlChange = (constructedUrl) => {
        formik.setFieldValue('adcreative.link', constructedUrl); 
    };

    const handleUrlParamChange = (constructedUrl) => {
        formik.setFieldValue('adcreative.url_tags', constructedUrl); 
    };

    if (!trackingData || !formik.values) {
        return <Spinner mt={'20px'} />;
    }   

    const filteredInstantForms = instantForms?.length > 0 && instantForms
        .map((formResponse) => formResponse?.data?.data) // Get the 'data' array from each formResponse
        .flat() // Flatten the array to get all forms in one array
        .filter((form) => form?.page_id === pageId);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex gap={4}>
                <Flex direction={'column'} flex={0.4}>
                    <Flex direction={'column'} gap={4} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Ad Name</Text>
                            <Input
                                name="name"
                                placeholder="Enter ad name"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Facebook Page</Text>
                            <FaceBookPageAdspressoSelector
                                adsAccount={adsAccount}
                                selectedPageId={formik?.values?.identity?.page_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('identity.page_id', pageId);

                                    // Reset the Instagram actor ID to an empty string
                                    formik.setFieldValue('identity.instagram_actor_id', '');
                                }}
                            />
                            {formik.errors.identity?.page_id && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.identity?.page_id}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Instagram accounts</Text>
                            <InstagramAccountSelector
                                adsAccount={adsAccount}
                                selectedInstPageId={formik?.values?.identity?.instagram_actor_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('identity.instagram_actor_id', pageId);
                                }}
                                pageID={formik?.values?.identity?.page_id}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction={'column'} gap={4} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        {/* Multi-advertiser ads Section */}
                        <Flex direction="column" mb={4} borderRadius="md" gap={2}>
                            <Checkbox
                                onChange={(e) => {
                                    formik.setFieldValue("adcreative.enroll_status", e.target.checked ? 'OPT_IN' : 'OPT_OUT');
                                }}
                                colorScheme="orange"
                                isChecked={formik.values.adcreative.enroll_status === 'OPT_IN'}
                            >
                            <Flex align={'center'}>
                                <Text fontWeight="bold" fontSize={'14px'}>Multi-advertiser ads &nbsp;</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p="5px">
                                            <Text>Your ads can appear alongside other ads in the same ad unit to help people discover products and services from businesses that are personalized to them. Your ad creative may be resized or cropped to fit the ad unit.</Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>
                            </Checkbox>
                        </Flex>

                        <Box>
                            <Text fontWeight="bold" mb={2}>Ad Creative</Text>
                            <Flex direction="row" gap={2} alignItems={'center'}>
                                <img
                                    src={formik.values.adcreative.image_hash ? formik.values.adcreative.url : formik.values.adcreative.thumbnail }
                                    alt={formik.values.adcreative.name}
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                                />
                                <Button
                                    bg={'gray.50'}
                                    size="xs"
                                    borderWidth={1}
                                    onClick={handleOpenDriveModal}>
                                    {formik.values?.adcreative?.image_hash ? "Change Image" : "Change Video"}
                                </Button>
                            </Flex>
                        </Box>
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Primary text</Text>
                            <Textarea
                                name="adcreative.message"
                                placeholder="Enter message"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative.message}
                            />
                            {formik.touched.adcreative?.message && formik.errors.adcreative?.message && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.message}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Headline</Text>
                            <Input
                                name="adcreative.name"
                                placeholder="Enter name"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative.name}
                            />
                            {formik.touched.adcreative?.name && formik.errors.adcreative?.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.name}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Description</Text>
                            <Textarea
                                name="adcreative.description"
                                placeholder="Enter description"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative.description}
                            />
                            {formik.touched.adcreative?.description && formik.errors.adcreative?.description && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.description}</Text>
                            )}
                        </Flex>


                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Call to action</Text>

                                {adset?.destination_type === 'PHONE_CALL' ? (
                                        <Select
                                            name="adcreative.type"
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.adcreative.type}
                                            isDisabled
                                            >
                                            <option value="CALL_NOW">Call Now</option>
                                        </Select>
                                    ) : adset?.destination_type === 'WEBSITE' ? (
                                        <Select
                                            name="adcreative.type"
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.adcreative.type}
                                        >
                                            {call_to_action_options?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    ): 
                                        <Select
                                            name="adcreative.type"
                                            size="sm"
                                            borderRadius="md"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.adcreative.type}
                                        >
                                            {call_to_action_options_instantForms?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    }

                                {formik.touched.adcreative?.type && formik.errors.adcreative?.type && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.type}</Text>
                                )}  
                        </Flex>

                        {adset?.destination_type === 'PHONE_CALL' ? (
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Phone Number</Text>
                                <PhoneInput
                                    name="adcreative.phoneNumber"
                                    value={formik.values.adcreative.phoneNumber}
                                    onChange={(value) => formik.setFieldValue('adcreative.phoneNumber', value)}
                                    country={'in'}
                                />
                                {formik.errors.adcreative?.phoneNumber && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.phoneNumber}</Text>
                                )}
                            </Flex>
                        ): adset?.destination_type === 'WEBSITE' ? (
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Website URL</Text>
                                <Input
                                    name="adcreative.link"
                                    placeholder="Enter link URL"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.adcreative.link}
                                />
                                <Flex mt={2}>
                                    <Button onClick={() => {
                                        setUrlModalOpen(true)
                                    }} 
                                    variant="link">
                                        <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                    </Button>
                                </Flex>
                                {formik.touched.adcreative?.link && formik.errors.adcreative?.link && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.link}</Text>
                                )}
                            </Flex>
                        ): adset?.destination_type === 'ON_AD' ? (
                            <>
                                <Flex alignItems="center" gap={2} mt={2}>
                                    <Text fontWeight="bold">Instant form</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Make connections with people by letting them send contact information and other details to you through a form.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                {/* Table Layout */}
                                {filteredInstantForms?.length > 0 ? (
                                    <RadioGroup onChange={(value) => formik.setFieldValue('adcreative.link', value)} value={formik.values.adcreative.link} colorScheme="orange">
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th textTransform={'none'} color={'none'} fontSize="14px">Form title</Th>
                                                    <Th textTransform={'none'} color={'none'} fontSize="14px">Creation date</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {filteredInstantForms?.map((form) => (
                                                    <Tr key={form?.id} _hover={{ backgroundColor: '#F9F9F9' }} >
                                                        <Td>
                                                            <Flex alignItems="center" gap={2}>
                                                                <Radio value={form?.id} />
                                                                <Text color={form.status === 'DRAFT' ? "gray.500" : "black"} fontSize={'12px'}>
                                                                    {form?.name}
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            <Text fontSize={'12px'}>
                                                                {new Date(form?.created_time).getFullYear()}-
                                                                {String(new Date(form?.created_time).getMonth() + 1).padStart(2, '0')}-
                                                                {String(new Date(form?.created_time).getDate()).padStart(2, '0')}
                                                            </Text>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </RadioGroup>
                                ) : (
                                    <Text fontSize="14px" color="gray.500">No form data available</Text>
                                )}
                            </>
                        ) : null}
                    </Flex>

                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                    <Text fontWeight={'bold'}>Tracking</Text>
                        <Flex direction="column" gap={1}>
                            <Flex alignItems={'center'}>
                                <Text mb={2} fontWeight="bold">
                                        URL parameters{" "}
                                    {/* <Text as="span" fontWeight="bold" color={'gray.600'} mr={1}>.</Text> */}
                                    <Text as="span" fontWeight="bold" color={'gray.600'}>Optional</Text>
                                </Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text >
                                                Add parameters to the end of your website URL to track where your visitors are coming from.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                </Tooltip>
                            </Flex>
                            <Input
                                name="adcreative.url_tags"
                                placeholder="key1=value1&key2=value2"
                                bg="#fff"
                                size='sm'
                                borderRadius="md"
                                value={formik.values.adcreative.url_tags}
                                onChange={formik.handleChange}
                            />
                            <Flex mt={2}>
                                <Button onClick={() => {
                                    setUrlParamModalOpen(true)
                                }} 
                                variant="link">
                                    <Text fontSize={'12px'} color={'gray.800'}>{'Build a URL parameter'}</Text>
                                </Button>
                            </Flex>
                            {formik.touched.adcreative?.url_tags && formik.errors.adcreative?.url_tags && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative?.url_tags}</Text>
                            )}
                        </Flex>
                    </Flex>
                            
                    <Flex direction={'column'} gap={2} borderRadius="md" bg="gray.50" p={4} mb={4}>
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">CPA</Text>
                            <Input
                                name="cpa"
                                placeholder="Enter CPA"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cpa}
                            />
                    </Flex>
                    </Flex>

                    <Flex mb={2}>
                        <Button
                            size="sm"
                            colorScheme="orange"
                            isLoading={formik.isSubmitting}
                            type="submit"
                            isDisabled={!formik.isValid}
                        >
                            Save
                        </Button>
                    </Flex>
                </Flex>

                <Flex flex={0.6} px={4} direction={'column'} justifyContent={'space-between'} borderRadius={'md'} boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)">
                    <Flex py={2} gap={2} direction={'column'}>
                        <Flex py={2} gap={2}>
                            <Switch isChecked={isEnabled} onChange={() => setIsEnabled(prevState => !prevState) } />
                            <Text fontWeight={'bold'} fontSize={'14px'}>Ad preview  </Text>  
                        </Flex>
                        <Divider />
                        {isEnabled && 
                            <PublishAdPreviews placements={targeting} file={formik.values} adsAccountData={adsAccount} />
                        }
                    </Flex>

                    <Flex my={2} alignItems={'center'}>
                        <Text fontSize={'12px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="12px" p="5px">
                                <Text>
                                    Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                </Text>
                                </Box>
                            }
                            fontSize="md"
                            >
                            <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Flex>

            <Modal isOpen={isUrlModalOpen} onClose={() => setUrlModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BuildUrlParamsUpdate link={formik.values.adcreative} onClose={() => setUrlModalOpen(false)} onUrlChange={handleUrlChange}/>
                </ModalBody>
                </ModalContent>
            </Modal> 

            <Modal isOpen={isUrlParamModalOpen} onClose={() => setUrlParamModalOpen(false)} scrollBehavior={"inside"}
                size={"2xl"}
                isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }}>Build a URL parameter</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <UrlParametersUpdate link={formik.values.adcreative} onClose={() => setUrlParamModalOpen(false)} onUrlChange={handleUrlParamChange}/>
                </ModalBody>
                </ModalContent>
            </Modal> 

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccount._id,
                    adsAccountId: adsAccount.adsAccountId,
                    adsAccountName: adsAccount.adsAccountName,
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={formik.values?.adcreative?.image_hash ? 'image' : 'video'}
            />
        </form>
    );
};

export default SingleCreativeAdupdate;
