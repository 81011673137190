import { Box, Select, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

function AdvantagePlusAudience({ preFilledData = '', ageOriginal = {}, showAdvantage = false, onChange }) {

    const [minAge, setMinAge] = useState(preFilledData?.age_range ? preFilledData?.age_range?.[0] : 18);
    const [maxAge, setMaxAge] = useState(preFilledData?.age_range ? preFilledData?.age_range?.[1] : 65);

    // Dynamically update minAgeOptions based on ageOriginal?.age_min
    const [minAgeOptions, setMinAgeOptions] = useState(Array.from({ length: 65 - 18 + 1 }, (_, i) => i + 18));

    const maxAgeOptions = [...Array.from({ length: 47 }, (_, i) => i + 18), '65+']; // Adding '65+' as last option

    // Update minAgeOptions based on ageOriginal?.age_min
    useEffect(() => {
        if (ageOriginal) {
            if (showAdvantage && ageOriginal?.age_min < 25 && minAge < 25) {
                setMinAgeOptions(Array.from({ length: 65 - ageOriginal.age_min + 1 }, (_, i) => i + ageOriginal.age_min));
                setMinAge(ageOriginal?.age_min);
                setMaxAge(ageOriginal?.age_max);
                onChange({ age_min: ageOriginal?.age_min, age_max: ageOriginal?.age_max });
            }else if(showAdvantage && ageOriginal?.age_min <= 25 ){
                setMinAgeOptions(Array.from({ length: 65 - ageOriginal.age_min + 1 }, (_, i) => i + ageOriginal.age_min));
            }else if(showAdvantage && ageOriginal?.age_min > 25 ){
                setMinAge(ageOriginal?.age_min);
                setMaxAge(ageOriginal?.age_max);
                onChange({ age_min: ageOriginal?.age_min, age_max: ageOriginal?.age_max });
            }
        } else {
            setMinAgeOptions(Array.from({ length: 65 - 18 + 1 }, (_, i) => i + 18)); // Fallback to default if age_min is not provided
        }
    }, [ageOriginal]);

    const handleMinAgeChange = (e) => {
        const value = e.target.value;
        setMinAge(Number(value));
        onChange({ age_min: Number(value), age_max: maxAge, });
    };

    const handleMaxAgeChange = (e) => {
        const value = e.target.value;
        setMaxAge(value === '65+' ? 65 : Number(value)); // Set 65 when '65+' is selected
        onChange({ age_min: minAge, age_max: Number(value) });
    };

    return (
        <Box>
            <FormControl as="fieldset" mb={5}>
                <FormLabel as="legend" fontWeight={'bold'}>Age</FormLabel>
                <Stack direction="row" spacing={4}>
                    <Box display={'flex'} direction="row" width={'150px'}>
                        <Select value={minAge} onChange={handleMinAgeChange} size={'sm'} borderRadius={'md'} bg={'#fff'} borderRightRadius={'none'}>
                            {minAgeOptions.map((age) => (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            ))}
                        </Select>
                        <Select value={maxAge === 65 ? '65+' : maxAge} onChange={handleMaxAgeChange} size={'sm'} borderRadius={'md'} bg={'#fff'} borderLeft={'none'} borderLeftRadius={'none'}>
                            {maxAgeOptions.map((age) => (
                                <option key={age} value={age}>
                                    {age === 65 ? '65+' : age}
                                </option>
                            ))}
                        </Select>
                    </Box>
                </Stack>
            </FormControl>
        </Box>
    );
}

export default AdvantagePlusAudience;
