import { array, boolean, number, object, string } from "yup";

const indianIFSCRegEx = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$");
// const panCardRegEx = new RegExp("/^[A-Z]{5}[0-9]{4}[A-Z]$/");

const loginSchema = object({
  email: string().required("Required").email("Invalid email ID"),
  password: string().required("Required").min(8, "Password must be at least 8 characters"),
});

const signUpschema = object({
  firstName: string().required("Required"),
  lastName: string().required("Required"),
  email: string().required("Required").email("Invalid email ID"),
  password: string().required("Required").min(8, "Password must be at least 8 characters"),
  phoneNumber: string().matches(/^[0-9]*$/, 'Only numbers are allowed').required('Required'),
});

const commisionFormSchema = object({
  retroComission: number().typeError('Invalid').required("Required")
    .test('is-number', 'Must be a number', (value) => {
      return !isNaN(value);
    }),
  firstRetroPartnerName: string(),
  firstRetroPartnerCommission: number().typeError('Invalid')
  // .test('is-number', 'Must be a number', (value) => {
  //   return !isNaN(value);
  // }),
  ,
  secondRetroPartnerName: string(),
  secondRetroPartnerCommission: number()
});


const legalDetailsIndianFormSchema = object({
  panNumber: string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid').required("Required"),
  gstNumber: string().matches(
    ///^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/,
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z][0-9A-Z]{1}$/,
    'Incorrect format'
  ).required("Required"),
  termsCheck: boolean().oneOf([true], 'You must accept the terms'),
  confirmationCheck: boolean().oneOf([true], 'You must confirm'),
});

const legalDetailsNonIndianFormSchema = object({
  taxId: string().required("Required"),
  termsCheck: boolean().oneOf([true], 'You must accept the terms').required('Required'),
  confirmationCheck: boolean().oneOf([true], 'You must confirm').required('Required'),
});

const bankDetailsIndianFormSchema = object({
  bankName: string().required("Required"),
  accountName: string().required("Required"),
  accountNumber: string().required("Required"),
  ifscCode: string().required("Required").matches(indianIFSCRegEx, "Incorrect format"),
  branchName: string(),
  branchCode: string(),
  branchAddress: string().required("Required"),
});

const bankDetailsNonIndianFormSchema = object({
  isUsingOnlineBankingPartner: string(),
  bankingPartner: string().required("Required"),
  otherOnlineBankingPartner: string().when('bankingPartner', {
    is: 'Others',
    then: () => string().required("Required")
  }),
  accountName: string().required("Required"),
  accountNumber: string().required("Required"),
  accountType: string(),
  iban: string().when("accountType", {
    is: "iban",
    then: () => string().required("Required"),
  }),
  swift: string().when("accountType", {
    is: "swift",
    then: () => string().required("Required"),
  }),
  bankName: string().required("Required"),
  branchAddress: string().required("Required"),
  country: string().required("Required"),
  accountAddress: string().required("Required"),
});

const newAccountSchema = object({
  accountID: string().required("Required"),
});

const replyToRequestSchema = object({
  content: string().required("Required"),
  attachments: string(),
});

const requestSchema = object({
  adsAccount: string().required("Required"),
  requestType: string().required("Required"),
  description: string().required("Required"),
  attachments: string(),
});

const adAccountRequestSchema = object({
  companyName: string().required("Required"),
  description: string().required("Required"),
  landingPage: string().url('Invalid format'),
  creativeDriveLinks: string().url('Invalid format').required('Required'),
  verifiedBmId: string().required("Required"),
  screenshotOfBusinessInfo: string(),
  accountTimezone: string().required("Required"),
  accountType: string().required("Required"),
  currency: string(),
  confirmationCheck: boolean().oneOf([true], 'You must confirm').required("Required"),
});

const editAdAccountRequestSchema = object({
  companyName: string().required("Required"),
  description: string().required("Required"),
  landingPage: string().url('Invalid format'),
  creativeDriveLinks: string().url('Invalid format').required('Required'),
  verifiedBmId: string().required("Required"),
  screenshotOfBusinessInfo: string().required("Required"),
  accountTimezone: string().required("Required"),
  accountType: string().required("Required"),
  currency: string(),
  adsAccountId: string().required("Required"),
  adsAccountName: string().required("Required"),
  initialBalance: number().typeError('Must be a number'),
  businessAccount:  string().required("Required"),
  facebookPages: array()
  .min(1, "Required"),
  pixels: array()
  .min(1, "Required"),
});

const newCompanySchema = object({
  nickName: string().required("Required"),
  companyName: string().required("Required"),
  typeOfBussiness: string().required("Required"),
  companyType: string().required("Required"),
  registerAddress: string().required("Required").min(10, "Please enter minimum 10 characters"),
  correspondenceAddress: string().required("Required").min(10, "Please enter minimum 10 characters"),
  otherBusinessType: string(),
  websiteUrl: string().required("Required"),//.matches(URLRegEx, 'Invalid'),
  serviceDescription: string().required("Required"),
  representativeName: string().required("Required"),
  representativePhone: string().required("Required"),
  representativeEmail: string().required("Required"),
  representativeDesignation: string().required("Required"),
  ownerName: string(),
  ownerPhone: string(),
});

const newClientSchema = object({
  firstName: string().required("Required"),
  phoneNumber: string().matches(/^[0-9]*$/, 'Only numbers are allowed').required('Required'),
  lastName: string(),
  email: string().required("Required").email("Invalid email ID"),
  password: string().required("Required").min(8, "Password must be at least 8 characters"),
  role: string().required("Required"),
});

const assignClientSchema = object({
  clientId: string().required("Required"),
});

const editRequestSchema = object({
  checked: string().required("Required"),
  notes: string(),
});

const transferFormSchema = object({
  fromAdsAccount: string().required("Required"),
  toAdsAccount: string().required("Required"),
  amount: string().required("Required"),
});

const legalDetailsNonIndianVerificationSchema = object({
  registrationCertificate: object({
    isApproved: string(),
    reason: string().when("isApproved", {
      is: false,
      then: string().required("required"),
    }),
    notes: string(),
  }),
});

const legalDetailsIndianVerificationSchema = object({
  registrationCertificate: object({
    isApproved: string(),
    reason: string().when("isApproved", {
      is: false,
      then: string().required("required"),
    }),
    notes: string(),
  }),
});

const approvalCardSchema = object({
  reason: string(),
  isApproved: string(),
  notes: string(),
});

const sumOfpercentSplit = (values) => {
  const total = values?.reduce((acc, value) => acc + (value?.percent || 0), 0);
  // console.log(values);
  return total === 100;

};

const topUpRequestSchema = object({
  currency: string().required("required"),
  amount: string().required("required"),
  accountType: string(),
  paymentEntityName: string().required("required"),
  paymentProvider: string().required("required"),
  paymentProviderOther: string(),
  transactionId: string().required("required"),
  paymentReceipt: string().required("required"),
  transferredTo: string().required("required"),
  transferredFrom: string().required("required"),
  percentSplit: array().of(
    object().shape({
      percent: number()
        .min(0, 'Amount must be a positive number')
        .max(100, 'Amount cannot be more than 100')
    })
  ).test('sumOfRepeater', 'Percent split sum must be 100%', sumOfpercentSplit),
  amountReceived:
    number("Invalid")
      .positive("Must be a positive value").when(
        'amount',
        (amount, schema) => schema.max(amount, 'Amount received cannot be greater than client entered amount')
      )

});

const approveTopUpSchema = object({
  approve: boolean(),
  reply: string().when("approve", {
    is: false,
    then: () => string().required("required"),
  }),
  amountReceived: number().when("approve", {
    is: true,
    then: () => string().required("required"),
  }),
});

const confirmTopUpSchema = object({
  confirm: boolean().oneOf([true], 'required').required("required")
});

const adUplaodSchema = object().shape({
  adsAccountName: array()
    .of(string().required("Account name is required"))
    .min(1, "At least one Ad Account is required"),
  files: array().min(1, 'File is required'),
});

const campaignFormSchema = object({
  adsAccounts: array()
    .min(1, "At least one Ad Account is required"),
  name: string().required('Campaign name is required'),
  objective: string().required('Objective is required'),
});

const salesFormSchema = object().shape({
  name: string().required('Ad Set name is required'),

  destination_type: string()
    .oneOf(['WEBSITE', 'PHONE_CALL'], 'Invalid conversion location')
    .required('Conversion location is required'),

  optimization_goal: string()
    .oneOf(
      [
        'OFFSITE_CONVERSIONS',
        'LINK_CLICKS',
        'LANDING_PAGE_VIEWS',
        'VALUE',
        'QUALITY_CALL'
      ],
      'Invalid performance goal'
    )
    .required('Performance goal is required'),

  billing_event: string()
    .oneOf(['IMPRESSIONS'], 'Invalid billing event')
    .required('Billing event is required'),

  budget: string().required('Budget type is required'),
  roas_average_floor: number().when('bid_strategy', {
    is: 'LOWEST_COST_WITH_MIN_ROAS',
    then: schema => schema.required('Roas amount is required').positive('Roas amount must be a positive number'),
    otherwise: schema => schema.notRequired()
  }),
  daily_budget: number().when('budget', {
    is: 'daily_budget',
    then: schema => schema.required('Daily budget is required').min(1, 'Daily budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),

  lifetime_budget: number().when('budget', {
    is: 'lifetime_budget',
    then: schema => schema.required('Lifetime budget is required').min(1, 'Lifetime budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  geo_locations: array().required('Location is required')
    .test('not-empty', 'Location is required', value => {
      return value && Object.keys(value).length > 0;
    }),
  promoted_object: array()
    .when(['optimization_goal'], {
      is: (optimization_goal) => optimization_goal === 'OFFSITE_CONVERSIONS' , // If optimization_goal is 'OFFSITE_CONVERSIONS'
      then: () => array()
        .required('Promoted object is required for OFFSITE_CONVERSIONS')
        .min(1, 'Pixel id is required')
        .of(
          object().shape({
            adsAccountId: string().required('adsAccountId is required'),
            pixel_id: string().required('Pixel Id is required'),
            custom_event_type: string().required('Event_type is required')
          })
        )
        .test('has-pixel-id-and-event', 'At least one object must have pixel_id and custom_event_type', (value) => {
          return value && value.some(obj => obj.pixel_id && obj.custom_event_type);
        }),
      otherwise: () => array().when('optimization_goal', {
        is: 'QUALITY_CALL', // If optimization_goal is 'QUALITY_CALLS'
        then: () => array()
          .required('facebook page id is required')
          .min(1, 'Facebook page id is required')
          .of(
            object().shape({
              adsAccountId: string().required('adsAccountId is required'),
              page_id: string().required('page_id is required for QUALITY_CALLS')
            })
          )
          .test('has-page-id', 'At least one object must have page_id', (value) => {
            return value && value.some(obj => obj.page_id);
          }),
        otherwise: () => array().notRequired() // Not required for other optimization goals
      })
    }),
});

const salesFormUpdateSchema = object().shape({
  name: string().required('Ad Set name is required'),

  destination_type: string()
    .oneOf(['WEBSITE', 'PHONE_CALL'], 'Invalid conversion location')
    .required('Conversion location is required'),

  optimization_goal: string()
    .oneOf(
      [
        'OFFSITE_CONVERSIONS',
        'LINK_CLICKS',
        'LANDING_PAGE_VIEWS',
        'VALUE',
        'QUALITY_CALL'
      ],
      'Invalid performance goal'
    )
    .required('Performance goal is required'),

  billing_event: string()
    .oneOf(['IMPRESSIONS'], 'Invalid billing event')
    .required('Billing event is required'),

  budget: string().required('Budget type is required'),
  roas_average_floor: number().when('bid_strategy', {
    is: 'LOWEST_COST_WITH_MIN_ROAS',
    then: schema => schema.required('Roas amount is required').positive('Roas amount must be a positive number'),
    otherwise: schema => schema.notRequired()
  }),
  daily_budget: number().when('budget', {
    is: 'daily_budget',
    then: schema => schema.required('Daily budget is required').min(1, 'Daily budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),

  lifetime_budget: number().when('budget', {
    is: 'lifetime_budget',
    then: schema => schema.required('Lifetime budget is required').min(1, 'Lifetime budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  geo_locations: array().required('Location is required')
    .test('not-empty', 'Location is required', value => {
      return value && Object.keys(value).length > 0;
    }),
});

const salesFormSchematrackEdit = object().shape({
  name: string().required('Ad Set name is required'),
  destination_type: string()
    .oneOf(['WEBSITE', 'PHONE_CALL'], 'Invalid conversion location')
    .required('Conversion location is required'),
  optimization_goal: string()
    .oneOf(
      [
        'OFFSITE_CONVERSIONS',
        'LINK_CLICKS',
        'LANDING_PAGE_VIEWS',
        'IMPRESSIONS',
        'QUALITY_CALL'
      ],
      'Invalid performance goal'
    )
    .required('Performance goal is required'),
  promoted_object: object().when('optimization_goal', {
      is: 'OFFSITE_CONVERSIONS',
      then: () => object()
        .shape({
          pixel_id: string().required('Pixel ID is required'),
          custom_event_type: string().required('Custom Event Type is required')
        })
        .required('Promoted object is required for OFFSITE_CONVERSIONS'),
      otherwise: () => object().when('optimization_goal', {
          is: 'QUALITY_CALL',
          then: () => object()
            .shape({
              page_id: string().required('Page ID is required for QUALITY_CALLS')
            })
            .required('Promoted object is required for QUALITY_CALLS')
        })
    }),
  billing_event: string()
    .oneOf(['IMPRESSIONS'], 'Invalid billing event')
    .required('Billing event is required'),
  budget: string().required('Budget type is required'),
  daily_budget: number().when('budget', {
    is: 'daily_budget',
    then: schema => schema.required('Daily budget is required').min(1, 'Daily budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  lifetime_budget: number().when('budget', {
    is: 'lifetime_budget',
    then: schema => schema.required('Lifetime budget is required').min(1, 'Lifetime budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  geo_locations: array().required('Location is required')
    .test('not-empty', 'Location is required', value => {
      return value && Object.keys(value).length > 0;
    })
});

const leadFormSchema = object().shape({
  name: string().required('Ad Set name is required'),
  destination_type: string()
    .oneOf(['WEBSITE', 'PHONE_CALL'], 'Invalid conversion location')
    .required('Conversion location is required'),
  optimization_goal: string()
    .oneOf(
      [
        'OFFSITE_CONVERSIONS',
        'LINK_CLICKS',
        'LANDING_PAGE_VIEWS',
        'IMPRESSIONS',
        'QUALITY_CALL'
      ],
      'Invalid performance goal'
    )
    .required('Performance goal is required'),
  promoted_object: array()
    .when(['optimization_goal'], {
      is: (optimization_goal) => optimization_goal === 'OFFSITE_CONVERSIONS', // If optimization_goal is 'OFFSITE_CONVERSIONS'
      then: () => array()
        .required('Promoted object is required for OFFSITE_CONVERSIONS')
        .min(1, 'Pixel id is required')
        .of(
          object().shape({
            adsAccountId: string().required('adsAccountId is required'),
            pixel_id: string().required('pixel_id is required'),
            custom_event_type: string().required('custom_event_type is required')
          })
        )
        .test('has-pixel-id-and-event', 'At least one object must have pixel_id and custom_event_type', (value) => {
          return value && value.some(obj => obj.pixel_id && obj.custom_event_type);
        }),
      otherwise: () => array().when('optimization_goal', {
        is: 'QUALITY_CALL', // If optimization_goal is 'QUALITY_CALLS'
        then: () => array()
          .required('facebook page id is required')
          .min(1, 'Facebook page id is required')
          .of(
            object().shape({
              adsAccountId: string().required('adsAccountId is required'),
              page_id: string().required('page_id is required for QUALITY_CALLS')
            })
          )
          .test('has-page-id', 'At least one object must have page_id', (value) => {
            return value && value.some(obj => obj.page_id);
          }),
        otherwise: () => array().notRequired() // Not required for other optimization goals
      })
    }),
  billing_event: string()
    .oneOf(['IMPRESSIONS'], 'Invalid billing event')
    .required('Billing event is required'),
  budget: string().required('Budget type is required'),
  // bid_amount: number().when('bid_strategy', {
  //   is: 'LOWEST_COST_WITH_BID_CAP',
  //   then: schema => schema.required('Bid amount is required').positive('Bid amount must be a positive number'),
  //   otherwise: schema => schema.notRequired()
  // }),
  daily_budget: number().when('budget', {
    is: 'daily_budget',
    then: schema => schema.required('Daily budget is required').min(1, 'Daily budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  lifetime_budget: number().when('budget', {
    is: 'lifetime_budget',
    then: schema => schema.required('Lifetime budget is required').min(1, 'Lifetime budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  geo_locations: array().required('Location is required')
    .test('not-empty', 'Location is required', value => {
      return value && Object.keys(value).length > 0;
    })
});

const leadFormUpdateSchema = object().shape({
  name: string().required('Ad Set name is required'),
  destination_type: string()
    .oneOf(['WEBSITE', 'PHONE_CALL'], 'Invalid conversion location')
    .required('Conversion location is required'),
  optimization_goal: string()
    .oneOf(
      [
        'OFFSITE_CONVERSIONS',
        'LINK_CLICKS',
        'LANDING_PAGE_VIEWS',
        'IMPRESSIONS',
        'QUALITY_CALL'
      ],
      'Invalid performance goal'
    )
    .required('Performance goal is required'),
  billing_event: string()
    .oneOf(['IMPRESSIONS'], 'Invalid billing event')
    .required('Billing event is required'),
  budget: string().required('Budget type is required'),
  daily_budget: number().when('budget', {
    is: 'daily_budget',
    then: schema => schema.required('Daily budget is required').min(1, 'Daily budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  lifetime_budget: number().when('budget', {
    is: 'lifetime_budget',
    then: schema => schema.required('Lifetime budget is required').min(1, 'Lifetime budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  geo_locations: array().required('Location is required')
    .test('not-empty', 'Location is required', value => {
      return value && Object.keys(value).length > 0;
    })
});

const leadFormSchematrackEdit = object().shape({
  name: string().required('Ad Set name is required'),
  destination_type: string()
    .oneOf(['WEBSITE', 'PHONE_CALL'], 'Invalid conversion location')
    .required('Conversion location is required'),
  optimization_goal: string()
    .oneOf(
      [
        'OFFSITE_CONVERSIONS',
        'LINK_CLICKS',
        'LANDING_PAGE_VIEWS',
        'IMPRESSIONS',
        'QUALITY_CALL'
      ],
      'Invalid performance goal'
    )
    .required('Performance goal is required'),
  promoted_object: object().when('optimization_goal', {
      is: 'OFFSITE_CONVERSIONS',
      then: () => object()
        .shape({
          pixel_id: string().required('Pixel ID is required'),
          custom_event_type: string().required('Custom Event Type is required')
        })
        .required('Promoted object is required for OFFSITE_CONVERSIONS'),
      otherwise: () => object().when('optimization_goal', {
          is: 'QUALITY_CALL',
          then: () => object()
            .shape({
              page_id: string().required('Page ID is required for QUALITY_CALLS')
            })
            .required('Promoted object is required for QUALITY_CALLS')
        })
    }),
  billing_event: string()
    .oneOf(['IMPRESSIONS'], 'Invalid billing event')
    .required('Billing event is required'),
  budget: string().required('Budget type is required'),
  daily_budget: number().when('budget', {
    is: 'daily_budget',
    then: schema => schema.required('Daily budget is required').min(1, 'Daily budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  lifetime_budget: number().when('budget', {
    is: 'lifetime_budget',
    then: schema => schema.required('Lifetime budget is required').min(1, 'Lifetime budget must be a positive number'),
    otherwise: schema => schema.notRequired(),
  }),
  geo_locations: array().required('Location is required')
    .test('not-empty', 'Location is required', value => {
      return value && Object.keys(value).length > 0;
    })
});

const adFormSchema = object({
  name: string().required('Ad name is required'),
  source: string()
    .oneOf(['CATALOG', 'MYDRIVE'], 'Invalid Creative Source option')
    .required(),
  accounts: array().required('Facebook page is required')
    .test('not-empty', 'Facebook page is required', value => {
      return value && Object.keys(value).length > 0;
    })
});

const createRuleSchema = object({
  ruleName: string().required('Rule name is required'),
  applyTo: string().required('Please select where to apply the rule'),
  action: string().required('Please select an action'),
  budgetAmount: string().when('action', {
    is: (action) => ['DECREASE_DAY', 'DECREASE_LIFETIME', 'INCREASE_DAY', 'INCREASE_LIFETIME'].includes(action),
    then: () => string().required('Required'),
    otherwise: () => string().notRequired(),
  }),
  maxLifetimeBudgetCap: string().when('action', {
    is: (action) => ['DECREASE_DAY', 'DECREASE_LIFETIME', 'INCREASE_DAY', 'INCREASE_LIFETIME'].includes(action),
    then: () => string().required('Required'),
    otherwise: () => string().notRequired(),
  }),  
  conditionField: string().required('Condition field is required'),
  conditionOperator: string().required('Condition operator is required'),
  conditionValue: string()
    .when('conditionOperator', {
      is: (value) => value === 'GREATER_THAN' || value === 'LESS_THAN',
      then: () => string().required('Required'),
      otherwise: () => string().notRequired(),
    }),
  conditionValue1: string()
    .when('conditionOperator', {
      is: (value) => value === 'IN_RANGE' || value === 'NOT_IN_RANGE',
      then: () => string().required('Required'),
      otherwise: () => string().notRequired(),
    }),
  conditionValue2: string()
    .when('conditionOperator', {
      is: (value) => value === 'IN_RANGE' || value === 'NOT_IN_RANGE',
      then: () => string().required('Required'),
      otherwise: () => string().notRequired(),
    }),
  timeRange: string().required('Please select a time range'),
  schedule: string().required('Please select a schedule'),
  customSchedules: array().of(
    object().shape({
      // days: array().min(1, 'At least one day must be selected'),
      timeSlots: array().of(
        object().shape({
          // startTime: string().required('Start time is required'),
          // endTime: string().required('End time is required'),
        })
      ).min(1, 'At least one time slot must be added'),
    })
  ),
});

export {
  transferFormSchema,
  adAccountRequestSchema, approvalCardSchema, approveTopUpSchema, assignClientSchema, bankDetailsIndianFormSchema,
  bankDetailsNonIndianFormSchema, commisionFormSchema, confirmTopUpSchema, editAdAccountRequestSchema, editRequestSchema, legalDetailsIndianFormSchema, legalDetailsIndianVerificationSchema, legalDetailsNonIndianFormSchema, legalDetailsNonIndianVerificationSchema, loginSchema, newAccountSchema, newClientSchema, newCompanySchema, replyToRequestSchema, requestSchema, signUpschema, topUpRequestSchema, adUplaodSchema, campaignFormSchema, salesFormSchema,salesFormUpdateSchema,leadFormUpdateSchema, salesFormSchematrackEdit, leadFormSchema,leadFormSchematrackEdit, adFormSchema, createRuleSchema,
};

