import React, { useState, useEffect } from 'react';
import {
    Select, FormLabel, Box, Flex, Text, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl, Input,
    Button,
    useToast,
    Tooltip,
    Spinner,
} from '@chakra-ui/react';
import { useCreatePixelMutation, useGetPixelByAdaccountIdQuery } from 'store/facebookServices/facebookServicesApi';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useGetConversionEventsQuery } from 'store/campaigns/adsetFormApi';

// const toCamelCase = (str) => {
//     return str
//         .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
//         .replace(/^([a-z])/, (match, letter) => letter.toUpperCase());
// };

const PixelConversionSelector = ({
    adsAccount,
    promotedObjectFields,
    promotedObjectData,
    onSelectedPixelId,
    onSelectedEventType,
    onBlur,
    isSaving,
}) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { adsAccountId, adsAccountName, businessId } = adsAccount || {};

    const [selectedPixelId, setSelectedPixelId] = useState(promotedObjectData?.pixel_id);
    // const [selectedEventType, setSelectedEventType] = useState(promotedObjectData?.custom_event_type || promotedObjectFields.custom_event_type?.options[0]?.value);
    const [selectedEventType, setSelectedEventType] = useState(promotedObjectData?.custom_event_type || '');
    const [pixelIdError, setPixelIdError] = useState(true);
    const [eventTypeError, setEventTypeError] = useState(true);

    const { data: pixelList, isLoading: isPixelLoading } = useGetPixelByAdaccountIdQuery(adsAccountId);
    // const { data: eventList, isLoading } = useGetConversionEventsQuery({
    //     pixelId: selectedPixelId,
    //     businessId,
    // }, { skip: !selectedPixelId });

    const [createPixel, { }] = useCreatePixelMutation();

    const eventTypeOptions = promotedObjectFields.custom_event_type?.options || [];

    // const combinedEventTypes = React.useMemo(() => {
    //     const existingEventTypes = new Set();
    //     const availableEventTypes = [];

    //     const eventListData = (eventList?.data || []).flatMap(group => group?.data || [])
    //         .map(event => ({
    //             label: event?.value,  
    //             value: event?.value.toUpperCase(),
    //             eventName: toCamelCase(event?.value), 
    //             available: true, 
    //         }));
    //     // console.log({eventListData})
    //     // Merge eventTypeOptions with eventListData 
    //     const allOptions = [...eventTypeOptions, ...eventListData];

    //     allOptions.forEach(option => {
    //         // Creating a new option 
    //         let updatedOption = { ...option };

    //         // If the option is found in eventListData, ensure it is marked available
    //         const existingOption = availableEventTypes.find(o => o.value === updatedOption.value);
    //         if (existingOption) {
    //             existingOption.available = true; 
    //         } else {
    //             availableEventTypes.push(updatedOption);  // If not found, add it as a new option
    //         }

    //         // Add to the existing set to prevent duplicates
    //         existingEventTypes.add(updatedOption.value);
    //     });

    //     return availableEventTypes; 
    // }, [eventTypeOptions, eventList]);

    //console.log({eventTypeOptions})

    const handlePixelIdChange = (event) => {
        const selectedId = event.target.value;
        setPixelIdError(!selectedId);
        setSelectedPixelId(selectedId);
        onSelectedPixelId(selectedId, adsAccountId);
    };

    const handleEventTypeChange = (event) => {
        const selectedType = event.target.value;
        setEventTypeError(!selectedType);
        setSelectedEventType(selectedType);
        onSelectedEventType(selectedType, adsAccountId);
    };

    const validationSchema = Yup.object({
        pixelName: Yup.string().required('Pixel name is required'),
    });

    const handleSubmit = async (values, actions) => {
        const payload = { 'name': values.pixelName };

        await createPixel({ adsAccountId, payload }).unwrap()
            .then((response) => {
                if (response?.data) {
                    toast({
                        position: "top-right",
                        title: "Saved",
                        description: 'Pixel created',
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                if (error) (
                    toast({
                        position: "top-right",
                        title: "Error",
                        description: error?.message,
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    })
                );
            });
        actions.resetForm();
        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Pixel</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ pixelName: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <ModalBody>
                                    <FormControl id="pixel-name" isInvalid={errors.pixelName && touched.pixelName} isRequired>
                                        <FormLabel>Pixel Name</FormLabel>
                                        <Field
                                            name="pixelName"
                                            as={Input}
                                            placeholder="Enter pixel name"
                                        />
                                        {errors.pixelName && touched.pixelName ? (
                                            <div style={{ color: 'red.500', marginTop: '0.25rem' }}>{errors.pixelName}</div>
                                        ) : null}
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme="orange" mr={3} type="submit" size={'sm'}>
                                        Save
                                    </Button>
                                    <Button variant="ghost" onClick={onClose} size={'sm'}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>

            <Box mb={'10px'}>
                <Text fontWeight={'bold'} mb={2}>Ad Account: {adsAccountName}</Text>
                {isPixelLoading ? (
                    <Spinner size={'sm'} />
                ) : pixelList?.length > 0 ? (
                    <Flex gap={'20px'} mb={2}>
                        <Box flex={1}>
                            <Flex alignItems={'center'}>
                                <Text htmlFor="pixel-id">Pixel</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text>
                                                <strong>About the pixel </strong> <br /> The pixel is a piece of code that you put on your website to measure the effectiveness of your ads. Choose the pixel that you want to use to track conversions.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>
                            <Select
                                id="pixel-id"
                                placeholder="Select Pixel ID"
                                value={selectedPixelId}
                                onChange={handlePixelIdChange}
                                size={'sm'}
                                borderRadius={'md'}
                                onBlur={onBlur}
                            >
                                {pixelList?.map((pixel) => (
                                    <option key={pixel.id} value={pixel.id}>
                                        {pixel.name}
                                    </option>
                                ))}
                            </Select>

                            {pixelIdError && isSaving && !selectedPixelId && (
                                <Text color="red.500" fontSize="sm" mt={1}>
                                    Pixel ID is required.
                                </Text>
                            )}

                        </Box>

                        <Box flex={1}>
                            <Flex alignItems={'center'}>
                                <Text htmlFor="event-type">Conversion event</Text>
                                <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text>
                                                Choose a conversion event associated with your Meta pixel. A conversion event is an action that you want People to take when they see your ads.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" />
                                </Tooltip>
                            </Flex>

                            <Select
                                id="event-type"
                                placeholder="Select Event Type"
                                value={selectedEventType}
                                onChange={handleEventTypeChange}
                                size={'sm'}
                                isDisabled={!selectedPixelId}
                                borderRadius={'md'}
                            >
                                {eventTypeOptions.map((eventOption, index) => (
                                    <option
                                        key={`${eventOption?.value}-${index}`}
                                        value={eventOption?.value}
                                        disabled={!eventOption?.available} // Disable option if it's not available
                                    >
                                        {eventOption?.label}
                                    </option>
                                ))}
                            </Select>

                            {eventTypeError && isSaving && !selectedEventType && (
                                <Text color="red.500" fontSize="sm" mt={1}>
                                    Event type is required.
                                </Text>
                            )}
                        </Box>
                    </Flex>
                ) : (
                    <Button onClick={onOpen} size={'sm'}>Create new pixel</Button>
                )}
            </Box>
        </>
    );
};

export default PixelConversionSelector;
