import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Divider,
    Flex,
    Input,
    Radio,
    RadioGroup,
    Stack,
    Text,
    Tooltip,
    useToast,
    Image,
    Select,
    useColorModeValue,
    Icon, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader,
    ModalFooter,
    Checkbox
} from '@chakra-ui/react';
import CarouselMediaCreative from 'components/adspresso/CarouselMediaCreative';
import InstagramAccountSelector from 'components/adspresso/InstagramAccountSelector';
import SingleMediaCreative from 'components/adspresso/SingleMediaCreative';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useCreateAdspressoAdHeaderMutation, useGetAdspressoAdDataByCampaignIdQuery, useUpdateAdspressoAdDataByAdIdMutation, useGetAdspressoCreativeByCampaignIdQuery, useUpdateAdspressoAdCreativeByIdMutation, useUpdateAdspressoAdCaroByAdIdMutation, useRemoveCreativeMutation, useRemoveAdMutation } from 'store/adspresso/adspressoAdApi';
import { useGetAdspressoAdsetsByCampainIdQuery } from 'store/adspresso/adspressoAdsetapi';
import { adFormSchema } from '../../utils/schema';
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DriveModal from "components/drive/DriveModal";
import DeleteConfirmationModal from "components/genericForms/DeleteConfirmationModal";
import SingleMediaUpdateForm from "components/adspresso/creativeUpdateForms/SingleMediaUpdateForm";
import CarouselMediaUpdateForm from "components/adspresso/creativeUpdateForms/CarouselMediaUpdateForm";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import AdPreviewImage from "components/adspresso/AdPreviewImage";
import AdPreviewVideo from "components/adspresso/AdPreviewVideo";
import FaceBookPageAdspressoSelector from "components/adspresso/FaceBookPageAdspressoSelector";
import AdPreviewCarousel from "components/adspresso/AdPreviewCarousel";
import { object, string } from "yup";
import PhoneInput from "react-phone-input-2";
import CaroAdUpdateForm from "components/adspresso/creativeUpdateForms/CaroAdUpdateForm";
import FaceBookPageAdsSelector from "components/adspresso/FaceBookPageAdsSelector";
import { getFileNameWithoutExtension } from "utils/functions";

const call_to_action_options = [
    { value: 'SHOP_NOW', label: 'Shop Now' },
    { value: 'LEARN_MORE', label: 'Learn More' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
    // { value: 'SIGN_UP', label: 'Sign up' },
    // { value: 'CONTACT_US', label: 'Contact Us' },
    // { value: 'BOOK_NOW', label: 'Book Now' },
];

const call_to_action_options_instantForms = [
    { value: 'SUBSCRIBE', label: 'Subscribe' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
    { value: 'GET_OFFER', label: 'Get offer' },
    { value: 'GET_QUOTE', label: 'Get quote' },
    { value: 'DOWNLOAD', label: 'Download' },
    { value: 'BOOK_TRAVEL', label: 'Book now' },
    { value: 'SIGN_UP', label: 'Sign Up' },
];

function AdForm({ campaignData, onRefresh, onRefreshComplete }) {

    const toast = useToast();
    const backgroundColor = useColorModeValue('gray.50', 'gray.800');

    const [creativesError, setcreativesError] = useState(false);
    const [isDriveOpen, setIsDriveOpen] = useState(false);
    const [mediaType, setMediaType] = useState(null);
    const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
    const [selectedAd, setSelectedAd] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [fileBeingEdited, setFileBeingEdited] = useState(null);
    const { adsAccounts, objective } = campaignData || {};
    const userInfo = useSelector((state) => state.auth?.userInfo);

    const [issetModalOpen, setIsModalOpen] = useState(false);
    const [selectedAdIndex, setSelectedAdIndex] = useState(null);
    const [isAdPreviewModalOpen, setIsAdPreviewModalOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);

    const { id } = useParams();

    const [createAdspressoAddata, { isLoading: isAddataCreating }] = useCreateAdspressoAdHeaderMutation();

    const { data: adsetsListData, error: adsetsListError, isLoading: isLoadingadsetsList } = useGetAdspressoAdsetsByCampainIdQuery(id, {
        skip: !id,
    });

    const { data: adData, refetch: refetchAdspressoAdData } = useGetAdspressoAdDataByCampaignIdQuery(id, { skip: !id });
    const [updateAdspressoAdDataByAdId, { }] = useUpdateAdspressoAdDataByAdIdMutation({});
    const { data: AdspressoCreatives, refetch: refetchAdspressoCreative } = useGetAdspressoCreativeByCampaignIdQuery(id);
    const [updateAdspressocreativeAds, { isLoading: isAdspressoAdUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
    const [removeCreative] = useRemoveCreativeMutation();
    const [removeAd, removeAdResponse] = useRemoveAdMutation();


    //console.log({ AdspressoCreatives });


    const openModal = (mediaUrl) => {
        setSelectedMedia(mediaUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedMedia(null);
    };

    useEffect(() => {
        if (onRefresh) {
            refetchAdspressoCreative();
            refetchAdspressoAdData();
            onRefreshComplete();
        }
    }, [onRefresh]);

    // Formik setup
    const adFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: adData?.name ? adData?.name : '',
            accounts: adData?.adsAccounts || [],
            source: adData?.source || 'MYDRIVE',
            format: AdspressoCreatives?.data[0]?.adType || 'SINGLE',
            useFileName: adData?.name ? false : true,
            enroll_status: 'OPT_OUT',
        },
        validationSchema: adFormSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {

            const filteredAccounts = values.accounts.map(account => {
                // Only include specific fields and remove others, including empty values
                return Object.fromEntries(
                    Object.entries(account)
                        .filter(([key, value]) =>
                            value !== '' && ['adsAccountId', 'instagram_actor_id', 'page_id'].includes(key)
                        )
                );
            });

            // If filteredAccounts is empty
            if (filteredAccounts.length === 0) {
                toast({
                    position: "top-right",
                    title: "No pages",
                    description: "Please select pages.",
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                });
                setSubmitting(false);
                resetForm();
                return;
            }

            const addataPayload = {
                ...(values.name && { name: values.name }),
                useFileName: values?.name ? values?.useFileName : true,
                campaign: campaignData?._id,
                source: values.source || "MYDRIVE",
                adsAccounts: filteredAccounts,
            };


            if (adData?.name?.length || adData?.useFileName) {

                updateAdspressoAdDataByAdId({ adId: adData?._id, payload: addataPayload }).unwrap()
                    .then(response => {
                        refetchAdspressoAdData();
                        toast({
                            position: "top-right",
                            title: "Ad data saved",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                        //console.log('Ad data created successfully:', response);
                    })
                    .catch(error => {
                        toast({
                            position: "top-right",
                            title: "Error updating ad data",
                            description: error?.data?.message,
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                        console.error('Failed to update ad data:', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                        resetForm();
                    });
            } else {
                createAdspressoAddata(addataPayload).unwrap()
                    .then(response => {
                        refetchAdspressoAdData();
                        toast({
                            position: "top-right",
                            title: "Ad data saved",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        });
                        //console.log('Ad data created successfully:', response);
                    })
                    .catch(error => {
                        toast({
                            position: "top-right",
                            title: "Error creating ad data",
                            description: error?.data?.message,
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                        console.error('Failed to create ad data:', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                        resetForm();
                    });
            }
        },
    });

    // console.log(adFormik.values);


    useEffect(() => {
        if (creativesError) {
            const timer = setTimeout(() => setcreativesError(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [creativesError]);

    useEffect(() => {

        if (adsAccounts && adsetsListData) {

            const hasInitialPageId = adData?.adsAccounts?.some(account => account.page_id && account.page_id.trim() !== '')

            if (!hasInitialPageId) {

                const updatedAccounts = adsAccounts.map(account => {

                    const adset = adsetsListData?.data?.find(adset =>
                        (adset?.destination_type === "PHONE_CALL" || adset?.destination_type === "ON_AD") &&
                        adset?.promoted_object?.some(obj => obj?.adsAccountId === account?.adsAccountId)
                    );

                    // If adset is found, get the page_id
                    const selectedPageId = adset?.promoted_object
                        .filter(obj => obj.adsAccountId === account.adsAccountId)
                        .pop()?.page_id;

                    // console.log({ adsetsListData });
                    return {
                        adsAccountId: account?.adsAccountId,
                        page_id: selectedPageId ? selectedPageId : '',
                        instagram_actor_id: '',
                    };
                });

                adFormik.setFieldValue('accounts', updatedAccounts);
            }

        }
    }, [adsAccounts, adsetsListData, objective]);


    const handleFacebookPageSelection = (pageId, adsAccountId) => {
        let updatedAccounts;

        if (pageId) {
            // If pageId is not empty, update or add the account
            updatedAccounts = adFormik.values.accounts.map(obj => {
                if (obj.adsAccountId === adsAccountId) {
                    // Set instagram_actor_id to '' when pageId changes
                    return { ...obj, page_id: pageId, instagram_actor_id: '' };
                }
                return obj;
            });

            // If the account is not found, add it
            if (!adFormik.values.accounts.find(obj => obj.adsAccountId === adsAccountId)) {
                updatedAccounts.push({ adsAccountId, page_id: pageId, instagram_actor_id: '' });
            }
        } else {
            // If pageId is empty, remove the entry with the matching adsAccountId
            updatedAccounts = adFormik.values.accounts.filter(
                obj => obj.adsAccountId !== adsAccountId
            );
        }

        // Set the updated accounts in Formik
        adFormik.setFieldValue('accounts', updatedAccounts);
    };

    const handleInstagramPageSelection = (pageId, adsAccountId) => {

        const updatedAccounts = adFormik.values.accounts.map(obj =>
            obj.adsAccountId === adsAccountId ? { ...obj, instagram_actor_id: pageId } : obj
        );

        if (!adFormik.values.accounts.find(obj => obj.adsAccountId === adsAccountId)) {
            updatedAccounts.push({ adsAccountId, instagram_actor_id: pageId });
        }
        adFormik.setFieldValue('accounts', updatedAccounts);
    };

    const renderCreativeComponent = () => {

        if (adFormik.values.format === 'CAROUSEL' && adsAccounts && adData) {
            return (
                <CarouselMediaCreative
                    adData={adData}
                    campaignId={id}
                    adFormik={adFormik}
                    adsAccounts={adsAccounts}
                    adsetsListData={adsetsListData}
                    objective={objective}
                    call_to_action_options={call_to_action_options}
                    call_to_action_options_instantForms={call_to_action_options_instantForms}
                />
            );
        } else if (adFormik.values.format === 'SINGLE' && adsAccounts && adData) {
            return (
                <SingleMediaCreative
                    adData={adData}
                    campaignId={id}
                    adFormik={adFormik}
                    adsAccounts={adsAccounts}
                    objective={objective}
                    adsetsListData={adsetsListData}
                    call_to_action_options={call_to_action_options}
                    call_to_action_options_instantForms={call_to_action_options_instantForms}
                />
            );
        }
    };

    const handleChangeMedia = (file, adAccount) => {


        const mediaType = file?.image_hash ? 'image' : 'video';
        setMediaType(mediaType);
        setIsDriveOpen(true);
        setFileBeingEdited(file);
        setSelectedAdAccounts([adAccount]);
    };

    const handleFileSelect = (files) => {


        if (files.length > 0) {
            // Handle update scenario
            const selectedFile = files[0];

            const payload = {
                [selectedFile?.creativeType === 'video' ? 'video_id' : 'image_hash']: selectedFile?.creativeId,
                ...(selectedFile?.creativeType === 'image' ? { url: selectedFile?.url } : { thumbnail: selectedFile?.picture, url: selectedFile?.source }),
                hash: selectedFile?.hash,
                fileName: getFileNameWithoutExtension(selectedFile?.name)
            };

            updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
                .then(response => {
                    //console.log('Ad creative updated successfully:', response);
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating image",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                });
        }
        setFileBeingEdited(null);
    };


    const handleDeleteCreative = (creativeId) => {

        removeCreative(creativeId)
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad creative deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting Ad creative",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
                //console.error('Failed to delete Ad creative:', error);
            });
    };

    const handleDeleteAd = (adId) => {
        removeAd(adId)
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad deleted",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error deleting Ad",
                    status: "error",
                    description: error?.data?.message,
                    duration: 4000,
                    isClosable: true,
                });
            });
    };

    const openPreviewModal = (index) => {
        setSelectedAdIndex(index);
        setIsAdPreviewModalOpen(true);
    };

    const uploadedCreativesList = AdspressoCreatives?.data
        ?.filter(creative => creative?.adcreatives?.length > 0)
        ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        ?.map((creative, index) => {
            if (adFormik?.values?.format === creative?.adType) {

                return (
                    <Box key={creative?._id + index} p={'10px'} borderWidth={1} borderRadius="md" borderColor="gray.200">
                        <Flex justifyContent={'space-between'}>
                            <Text fontSize={'14px'} fontWeight={'bold'}>Ad Account: {creative?.adsAccount?.adsAccountName}</Text>
                            <Tooltip label="Delete" fontSize="xs">
                                <Box>
                                    <Icon
                                        color={userInfo?.role !== 'client' ? 'gray.300' : 'red.500'}
                                        _hover={{ color: 'gray.400' }}
                                        as={MdDelete}
                                        cursor={userInfo?.role !== 'client' ? 'not-allowed' : 'pointer'}
                                        boxSize={5}
                                        onClick={() => {
                                            if (userInfo?.role === 'client') {
                                                setSelectedAd(creative?._id);
                                                setModalOpen(true);
                                            }
                                        }}
                                        pointerEvents={userInfo?.role !== 'client' ? 'none' : 'auto'}
                                    />
                                </Box>
                            </Tooltip>
                        </Flex>
                        <Flex align={'flex-start'} direction={'column'}>
                            <Text fontSize={'14px'}>Adsets:</Text>
                            <Flex direction={'column'} ml={'5px'}>
                                {creative?.adsets?.map((adset, adsetIndex) => (
                                    <Text key={adsetIndex + adset._id} fontSize={'12px'}>{adset.name}</Text>
                                ))}
                            </Flex>
                        </Flex>

                        {/* Small Image Preview */}
                        <Flex wrap="wrap" gap={2} direction="column" mt={'10px'}>
                            {creative?.adType === 'CAROUSEL' && (
                                <Flex py={2}>
                                    <Button
                                        onClick={() => openPreviewModal(creative?.adType)}
                                        bg={'gray.50'}
                                        borderWidth={1}
                                        size="xs"
                                        fontSize={'12px'}
                                        gap={2}
                                    >
                                        <FaEye />
                                        Ad preview
                                    </Button>
                                </Flex>
                            )}

                            {(selectedAdIndex === creative?.adType) && (
                                <Modal
                                    isOpen={isAdPreviewModalOpen}
                                    onClose={() => setIsAdPreviewModalOpen(false)}
                                    size="3xl"
                                    scrollBehavior={"inside"}
                                >
                                    <ModalOverlay />
                                    <ModalContent minH={'60vh'}>
                                        <ModalHeader>Ad preview</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            {creative && adData ? (
                                                creative?.adType === 'CAROUSEL' ? (
                                                    <AdPreviewCarousel
                                                        file={creative}
                                                        adData={adData}
                                                        destination_type ={creative?.adsets[0]?.destination_type}
                                                        placements={creative?.adsets[0]?.targeting}
                                                    />
                                                ) : (
                                                    <Text>No preview available</Text>
                                                )
                                            ) : null}
                                        </ModalBody>
                                        <ModalFooter justifyContent={'flex-start'} css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }} py={2}>
                                            <Text fontSize={'14px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="12px" p="5px">
                                                        <Text>
                                                            Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                                        </Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                                            </Tooltip>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            )}    

                            {creative?.adcreatives?.map((file, fileIndex) => {

                                return (
                                    <Box key={file?.url + fileIndex}>
                                        {/* Ad Preview Modal */}

                                        {(selectedAdIndex === file?.url + fileIndex) && (
                                            <Modal
                                                isOpen={isAdPreviewModalOpen}
                                                onClose={() => setIsAdPreviewModalOpen(false)}
                                                size="3xl"
                                                scrollBehavior={"inside"}
                                            >
                                                <ModalOverlay />
                                                <ModalContent minH={'60vh'}>
                                                    <ModalHeader>Ad preview</ModalHeader>
                                                    <ModalCloseButton />
                                                    <ModalBody>
                                                        {file && adData ? (
                                                            creative?.adType === 'SINGLE' ? (
                                                                // Check if image_hash exists, otherwise check if video_id exists
                                                                file?.image_hash ? (
                                                                    <AdPreviewImage
                                                                        file={file}
                                                                        destination_type ={creative?.adsets[0]?.destination_type}
                                                                        adData={adData}
                                                                        placements={creative?.adsets[0]?.targeting}
                                                                    />
                                                                ) : file?.video_id ? (
                                                                    <AdPreviewVideo
                                                                        file={file}
                                                                        destination_type ={creative?.adsets[0]?.destination_type}
                                                                        adData={adData}
                                                                        placements={creative?.adsets[0]?.targeting}
                                                                    />
                                                                ) : (
                                                                    <Text>No preview available</Text>
                                                                )
                                                            ) : (
                                                                null
                                                            )
                                                        ) : null}
                                                    </ModalBody>
                                                    <ModalFooter justifyContent={'flex-start'} css={{ boxShadow: '0 0px 5px rgba(0, 0, 0, 0.1)' }} py={2}>
                                                        <Text fontSize={'14px'} mr={2}>Ad rendering and interaction may vary based on device, format and other factors.</Text>
                                                        <Tooltip
                                                            borderRadius={5}
                                                            label={
                                                                <Box fontSize="12px" p="5px">
                                                                    <Text>
                                                                        Ads Manager previews are just examples and are not a substitute for reviewing Help Centre guidance and testing formats and devices to ensure that your ad delivers as intended.
                                                                    </Text>
                                                                </Box>
                                                            }
                                                            fontSize="md"
                                                        >
                                                            <InfoOutlineIcon pointerEvents="all" boxSize={3} />
                                                        </Tooltip>
                                                    </ModalFooter>
                                                </ModalContent>
                                            </Modal>
                                        )}


                                            {creative?.adType === 'SINGLE' && (
                                                <Flex py={2}>
                                                    <Button 
                                                        onClick={() => openPreviewModal(file?.url + fileIndex)}
                                                        bg={'gray.50'}
                                                        borderWidth={1}
                                                        size="xs"
                                                        fontSize={'12px'}
                                                        gap={2}
                                                    >
                                                        <FaEye />
                                                        Ad preview   
                                                    </Button>
                                                </Flex>
                                            )}

                                        {/* Based on adType */}
                                        {creative.adType === 'CAROUSEL' ? (
                                            <CarouselMediaUpdateForm
                                                adData={adData}
                                                adAccount={creative?.adsAccount}
                                                creative={file}
                                                call_to_action_options={call_to_action_options}
                                                call_to_action_options_instantForms={call_to_action_options_instantForms}
                                                conversionType={creative?.type}
                                                onDelete={handleDeleteCreative}
                                                onChangeImage={handleChangeMedia}
                                                onClick={() => openModal(file)}
                                            />
                                        ) : (
                                            <SingleMediaUpdateForm
                                                adData={adData}
                                                adAccount={creative?.adsAccount}
                                                call_to_action_options={call_to_action_options}
                                                call_to_action_options_instantForms={call_to_action_options_instantForms}
                                                creative={file}
                                                conversionType={creative?.type}
                                                onDelete={handleDeleteCreative}
                                                onChangeImage={handleChangeMedia}
                                                onClick={() => openModal(file)}
                                            />
                                        )}
                                    </Box>
                                );
                            })}

                            {creative?.adType === 'CAROUSEL' && (
                                <CaroAdUpdateForm adData={adData} creative={creative} call_to_action_options={call_to_action_options} call_to_action_options_instantForms={call_to_action_options_instantForms} />
                            )}
                        </Flex>
                    </Box>
                );
            }
            return null;
        });

    const confirmDelete = () => {
        if (selectedAd) {
            handleDeleteAd(selectedAd);
            setModalOpen(false);
            setSelectedAd(null);
        }
    };

    const creativesList = uploadedCreativesList?.filter(item => item !== null) || [];

    return (

        <>

            {/* Modal for Image/Video */}
            {/* <Modal isOpen={issetModalOpen} onClose={closeModal} size="sm">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader />
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedMedia ? (
                            selectedMedia?.video_id ? (
                                <video
                                    src={selectedMedia?.url}
                                    controls
                                    style={{ width: '100%' }}
                                />
                            ) : (
                                <Image
                                    src={selectedMedia?.url}
                                    alt="image"
                                    mx={'auto'} maxW={'100%'} maxH={'50vh'} borderRadius={2}
                                />
                            )
                        ) : (
                            <Box>No media selected</Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal> */}

            <DriveModal
                isDriveModalOpen={isDriveOpen}
                mediaType={mediaType}
                onClose={() => {
                    setIsDriveOpen(false);
                    setMediaType(null);
                }}
                mediaFormat={adFormik?.values?.format}
                onSubmit={handleFileSelect}
                selectedAdAccounts={selectedAdAccounts}
                uploadPurpose={'update'}
            />

            <DeleteConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={confirmDelete}
                message="Do you wish to proceed with deleting this Ad?"
            />

            <Flex p={'20px'} height="100%" gap={2}>
                {/* Scrollable Content */}
                <Box
                    flex="0.6"
                    overflowY="auto"
                    display="flex"
                    flexDirection="column"
                >
                    <form onSubmit={adFormik.handleSubmit}>
                        {/* Ad Name Section */}
                        <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={4}>
                            <Flex direction={'column'} gap={2}>
                                <Flex>
                                    <Text fontWeight="bold">Ad name</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                </Flex>
                                <Input
                                    placeholder="Enter your ad name here..."
                                    name="name"
                                    value={adFormik.values.name}
                                    onChange={(e) => {
                                        adFormik.handleChange(e);
                                        if (e.target.value) {
                                            adFormik.setFieldValue("useFileName", false);
                                        } else {
                                            adFormik.setFieldValue("useFileName", true);
                                        }
                                    }}
                                    onBlur={adFormik.handleBlur}
                                    size={'sm'}
                                    borderRadius={'md'}
                                    isDisabled={userInfo?.role !== 'client' || adFormik.values.useFileName}
                                />
                                {/* {adFormik.touched.name && adFormik.errors.name ? (
                                    <Text color="red.500" fontSize={'12px'}>{adFormik.errors.name}</Text>
                                ) : null} */}
                                {/* Checkbox for 'Use File Name' */}
                                <Flex direction="column" gap={2}>
                                    <Checkbox
                                        name="useFileName"
                                        isChecked={adFormik.values.useFileName}
                                        onChange={() => adFormik.setFieldValue("useFileName", !adFormik.values.useFileName)}
                                        isDisabled={adFormik.values.name}
                                        size="sm"
                                        colorScheme="orange"
                                        pl={1}
                                    >
                                        Using file name as Ad name
                                    </Checkbox>
                                </Flex>
                            </Flex>

                            {/* Identity Section */}
                            <Flex direction="column" gap={2}>
                                <Text fontWeight="bold">Identity</Text>
                                <Flex alignItems={'center'}>
                                    <Text fontWeight="bold">Facebook pages</Text>
                                    <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Your Facebook Pages or Instagram accounts represents your business in ads. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                {adsAccounts?.map((account) => {

                                    const adset = adsetsListData?.data?.find(adset =>
                                        (adset?.destination_type === "PHONE_CALL" || adset?.destination_type === "ON_AD")  &&
                                        adset?.promoted_object?.some(obj => obj?.adsAccountId === account?.adsAccountId)
                                    );

                                    // If adset is found, get the last page_id of the matching promoted_object
                                    const selectedPageId = adset?.promoted_object
                                        .filter(obj => obj.adsAccountId === account.adsAccountId)
                                        .pop()?.page_id;

                                    return (
                                        <FaceBookPageAdsSelector
                                            pageIdToSend={selectedPageId ? selectedPageId : ''}
                                            adsAccount={account}
                                            selectedPageId={selectedPageId ? selectedPageId : adData?.adsAccounts?.filter((account_) => account_?.adsAccountId == account?.adsAccountId)?.[0]?.page_id}
                                            onSelectedPage={(page) => handleFacebookPageSelection(page, account.adsAccountId)}
                                            key={`fb_pages_${account?.adsAccountId}`}
                                        />
                                    )
                                })}

                                {(adFormik.touched.accounts && adFormik?.errors.accounts) ? (
                                    <Text color="red.500" fontSize={'12px'}>{adFormik.errors.accounts}</Text>
                                ) : null}
                                <Divider />
                                <Text fontWeight="bold">Instagram accounts</Text>
                                {adsAccounts?.map((account, index) => {
                                    const pageId = adFormik.values?.accounts?.find(acc => acc.adsAccountId === account.adsAccountId)?.page_id;

                                    return (
                                        <InstagramAccountSelector
                                            adsAccount={account}
                                            selectedInstPageId={adData?.adsAccounts?.filter((account_) => account_?.adsAccountId === account?.adsAccountId)?.[0]?.instagram_actor_id}
                                            onSelectedPage={(page) => handleInstagramPageSelection(page, account.adsAccountId)}
                                            pageID={pageId || adData?.adsAccounts?.filter((account_) => account_?.adsAccountId === account?.adsAccountId)?.[0]?.page_id}
                                            key={`insta_accounts_${account?.adsAccountId}_${index}`}
                                            isNewAdCreating={!adData?.name}
                                        />
                                    )
                                })
                                }
                            </Flex>

                            <Flex justify="flex-start" >
                                <Button
                                    colorScheme="orange"
                                    isLoading={adFormik.isSubmitting}
                                    type="submit"
                                    size={'sm'}
                                    isDisabled={userInfo?.role !== 'client'}
                                >Save</Button>
                            </Flex>
                        </Flex>

                        {/* Ad Setup Section */}
                        <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={2}>
                            {/* Creative Source Section */}
                            <Flex direction="column" borderRadius="md" gap={2}>
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Creative source &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Choose how you'd like to provide the media for your ad. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                            </Flex>

                            {/* Format Section */}
                            <Flex direction="column" borderRadius="md" gap={2}>
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Format &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Choose how you'd like to structure your ad. </Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>

                                <RadioGroup
                                    onChange={(value) => {
                                        adFormik.setFieldValue("format", value);
                                        adFormik.setFieldValue('mediaOption', '')
                                    }}
                                    value={adFormik.values.format}
                                >
                                    <Stack spacing={5} direction='column'>
                                        {/* Single image or video option */}
                                        <Flex direction="column">
                                            <Flex align="center">
                                                <Radio colorScheme='orange' value='SINGLE' />
                                                <Text fontWeight="bold" ml={2}>Single image or video</Text>
                                            </Flex>
                                            <Text fontSize={'sm'} ml={6}>One image or video, or a slideshow with multiple images</Text>
                                        </Flex>

                                        {/* Carousal format option */}
                                        <Flex direction="column">
                                            <Flex align="center">
                                                <Radio colorScheme='orange' value='CAROUSEL' />
                                                <Text fontWeight="bold" ml={2}>Carousel</Text>
                                            </Flex>
                                            <Text fontSize={'sm'} ml={6}>Two or more scrollable images or videos</Text>
                                        </Flex>
                                    </Stack>
                                </RadioGroup>
                            </Flex>

                            <Divider my={2}/>
                            {/* Multi-advertiser ads Section */}
                            <Flex direction="column" borderRadius="md" gap={2}>
                                <Checkbox
                                    onChange={(e) => {
                                        adFormik.setFieldValue("enroll_status", e.target.checked ? 'OPT_IN' : 'OPT_OUT');
                                    }}
                                    colorScheme="orange"
                                    isChecked={adFormik.values.enroll_status === 'OPT_IN'}
                                >
                                <Flex align={'center'}>
                                    <Text fontWeight="bold">Multi-advertiser ads &nbsp;</Text>
                                    <Tooltip
                                        borderRadius={5}
                                        label={
                                            <Box fontSize="sm" p="5px">
                                                <Text>Your ads can appear alongside other ads in the same ad unit to help people discover products and services from businesses that are personalized to them. Your ad creative may be resized or cropped to fit the ad unit.</Text>
                                            </Box>
                                        }
                                        fontSize="md"
                                    >
                                        <InfoOutlineIcon pointerEvents="all" />
                                    </Tooltip>
                                </Flex>
                                </Checkbox>
                            </Flex>
                        </Flex>

                        <Flex direction="column" mb={4} bg={backgroundColor} p={4} borderRadius="md" gap={2}>
                             {/* Ad creative Section */}
                             <Flex direction="column">
                                <Box mb={'10px'} >
                                    <Flex alignItems={'center'}>
                                        <Text fontWeight="bold" >Ad creative &nbsp;</Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="sm" p="5px">
                                                    <Text>Add a variety of creative elements and group the ones that can be shown together. We'll combine the right elements to show each person the version of your ad that they're most likely to engage with. </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" />
                                        </Tooltip>
                                    </Flex>
                                </Box>

                                {/* Media selection */}
                                <Flex direction="column">
                                    <Flex alignItems="center" justifyContent={'space-between'}>
                                        <Flex direction='row'>
                                            <Text fontWeight="bold">Media</Text>
                                            <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                                        </Flex>
                                    </Flex>

                                    <Flex alignItems="center">
                                        {renderCreativeComponent()}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </form>
                </Box>
                <Box flex="0.4" overflowY="auto" bg={creativesList?.length > 0 ? backgroundColor : undefined} borderRadius="md" p={4}>
                    {creativesList?.length > 0 ? (
                        <>
                            <Flex direction="column" gap={2} >
                                {creativesList}
                            </Flex>
                        </>
                    ) : null}
                </Box>
            </Flex>
        </>
    );
}

export default AdForm;

