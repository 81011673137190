import React, { useState } from 'react';
import { Input, Flex } from '@chakra-ui/react';

const MinuteSecondSpinner = ({ value = "00:00", onChange }) => {
    // Parse the time (minutes and seconds) from the input
    const parseTime = (time) => {
        const [m, s] = time.split(":").map(Number);
        return { minutes: isNaN(m) ? 0 : m, seconds: isNaN(s) ? 0 : s };
    };

    // Initialize state for minutes and seconds
    const { minutes: initialMinutes, seconds: initialSeconds } = parseTime(value);
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    // Update time when either minutes or seconds change
    const updateTime = (type, newValue) => {
        let num = parseInt(newValue.replace(/\D/g, ""), 10); // Remove non-numeric input
        if (isNaN(num)) num = 0;

        // Limit minutes to a maximum of 24 and seconds to a maximum of 59
        if (type === "minutes") {
            num = Math.min(num, 24); // Restrict minutes to 24
            setMinutes(num);
            onChange(`${num.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`);
        } else {
            num = Math.min(num, 59); // Restrict seconds to 59
            setSeconds(num);
            onChange(`${minutes.toString().padStart(2, "0")}:${num.toString().padStart(2, "0")}`);
        }
    };

    // Handle keyboard events to increase/decrease values using arrow keys
    const handleKeyDown = (e, type) => {
        if (e.key === "ArrowUp") {
            updateTime(type, (type === "minutes" ? minutes + 1 : seconds + 1).toString());
        }
        if (e.key === "ArrowDown") {
            updateTime(type, (type === "minutes" ? minutes - 1 : seconds - 1).toString());
        }
    };

    return (
        <Flex>
            <Input
                value={minutes.toString().padStart(2, "0")}
                onChange={(e) => updateTime("minutes", e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "minutes")}
                textAlign="center"
                size="sm"
                variant="unstyled"
                maxW={'20px'}
            />
            <span>:</span>
            <Input
                value={seconds.toString().padStart(2, "0")}
                onChange={(e) => updateTime("seconds", e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "seconds")}
                textAlign="center"
                size="sm"
                variant="unstyled"
                maxW={'20px'}
            />
        </Flex>
    );
};

export default MinuteSecondSpinner;
