import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AcrossAdSetUpdate from 'components/acrossAdAccountForms/adset/AccrossAdsetUpdate';
import AppTableWithPagination from 'components/AppTableWithPagination';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';
import currencySymbolMap from 'currency-symbol-map';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiDelete, FiEdit2 } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDeleteAdsetMutation, useGetAdspressoAllAdsetsListQuery, useGetAdspressoAllAdsetsQuery, useGetAllAdsetInsightsQuery, useUpdateAdsetStatusMutation } from 'store/campaigns/adsetFormApi';
import { selectAdsetsForInsights, selectPresentPage, selectSelectedAdsetId, selectSelectedCampaign, selectSelectedCampaignId, setPresentPage, setSelectedAdset, setSelectedAdsetId } from 'store/campaigns/campaignsSlice';

function AllAdSetsTable({ startDate, endDate, datePreset, setSelectedTab, onRefresh, onRefreshComplete }) {
    const dispatch = useDispatch();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const isCampaignSelected = Boolean(selectedCampaignId);
    const selectedCampaign = useSelector(selectSelectedCampaign);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const AdsetsForInsights = useSelector(selectAdsetsForInsights);
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
    const [selectedAdset, setselectedAdset] = useState(null);
    const [switchStates, setSwitchStates] = useState({});
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [searchValue, setsearchValue] = useState('');
    const [adsetsListWithInsights, setAdsetsListWithInsights] = useState([]);
    const [adsetInsights, setAdsetInsights] = useState([]);
    const [isLoadingAdsetsWithInsights, setIsLoadingAdsetsWithInsights] = useState(true); 
    const [hasRefetched, setHasRefetched] = useState(false);
    const presentPage = useSelector(selectPresentPage);

    const { data: allAdsetsIds, isFetching:isAllAdsetIdsFetching,  isSuccess: isAllAdsetsIdsSuccess, refetch: refetchAllAdsetsIds } = useGetAdspressoAllAdsetsQuery({ currentPage:presentPage },{});

    const { data: allAdsetsList, isFetching: isAllAdsetsListFetching, refetch: refetchAllAdsetsList, isLoading: isAllAdsetsListLoading, error: AlladsetslistError } = useGetAdspressoAllAdsetsListQuery(
        {
            ids: allAdsetsIds?.data || [],
            campaign: selectedCampaignId ? { campaign_id: selectedCampaign?.campaign_id, adsAccountId: selectedCampaign?.account_id } : null,
        },
        {
            skip: !allAdsetsIds?.data || allAdsetsIds?.data?.length === 0 || isAllAdsetIdsFetching
        },
    );
    
    const { data: adsetsInsights,isFetching:isAdsetInsightListFetching, refetch:refetchAdsetInsights, isLoading:isAdsetInsightsLoading, error: AdsetsInsightsError } = useGetAllAdsetInsightsQuery({
        searchValue,
        ...(datePreset ? {} : { start_date: startDate, end_date: endDate }), // Conditionally add startDate and endDate
        date_preset: datePreset,
        adsets: adsetInsights?.length > 0 ? adsetInsights : [],
    }, { skip: !adsetInsights?.length === 0 });

    const [updateAdsetStatus, { isLoading: isAdsetStatusUpdating }] = useUpdateAdsetStatusMutation({});
    const [deleteAdset] = useDeleteAdsetMutation();

    const handleSaveSuccess = useCallback(() => {
        refetchAllAdsetsIds();
        setHasRefetched(false); 
    }, []);
    
    useEffect(() => {
        if (!isAllAdsetIdsFetching && !hasRefetched && allAdsetsIds?.data) {
            refetchAllAdsetsList();
            setHasRefetched(true); 
        }
    }, [isAllAdsetIdsFetching, hasRefetched]);
    

    useEffect(() => {
        if (onRefresh) {
            refetchAllAdsetsList();
            onRefreshComplete();
        }
    }, [onRefresh]);

    useEffect(() => {
        if (allAdsetsList) {
            const initialSwitchStates = {};
            allAdsetsList.forEach(adset => {
                initialSwitchStates[adset?.adset_id] = adset?.status;
            });
            setSwitchStates(initialSwitchStates);
            const formattedAdsets = allAdsetsList.reduce((acc, adset) => {

                const promotedObject = adset?.data?.[0]?.promoted_object;
                const customEventType = promotedObject?.custom_event_type;
                const accountId = adset?.account_id;

                // Only add campaigns to the list if custom_event_type is present

                if (adset?.adset_id && customEventType) {
                    acc.push({
                        id: adset?.adset_id,
                        account_id: accountId,
                        promoted_object: { custom_event_type: customEventType },
                        destination_type: adset?.destination_type,
                        optimization_goal: adset?.optimization_goal
                    });
                }else{
                    acc.push({
                        id: adset?.adset_id,
                        account_id: accountId,
                        destination_type: adset?.destination_type,
                        optimization_goal: adset?.optimization_goal
                    });
                }
                return acc;
            }, []);

            setAdsetInsights(formattedAdsets);
        }
    }, [allAdsetsList]);

    useEffect(() => {
        if (allAdsetsList || adsetsInsights) {
            const flattenedInsights = adsetsInsights?.flat().filter(insight => insight?.adset_id);
    
            const adsetsWithInsights = allAdsetsList?.map(adset => {
                const insightData = flattenedInsights?.find(insight => insight?.adset_id === adset?.adset_id);
    
                return {
                    ...adset,
                    insight: insightData?.insights || {},
                };
            });
            setAdsetsListWithInsights(adsetsWithInsights);

            setTimeout(() => {
                setIsLoadingAdsetsWithInsights(false);
            }, 100);
        }
    }, [allAdsetsList, adsetsInsights]);
    
    const handleSwitchChange = (adset, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adset?.adset_id]: newStatus }));
        updateAdsetStatus({
            adsetId : adset?.adset_id,
            payload: { 
                status: newStatus,
                adsAccount: {
                    adsAccountId: adset?.account_id,
                },
             }
        }).unwrap()
            .then(() => {
                refetchAllAdsetsList();
                toast({
                    position: "top-right",
                    title: `Adset ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Adset status`,
                    description: error?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adset?.adset_id]: currentState }));
            });
    };

    const handlePageChange = (currentPage) => {
        dispatch(setPresentPage(currentPage));
    }
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set('pageId', presentPage);

        navigate({ search: params.toString() }, { replace: true });

    }, [presentPage]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const storedPage = params.get('pageId');

        if (storedPage && !isNaN(storedPage)) {
            const parsedPage = parseInt(storedPage, 10);
            if (parsedPage >= 1 && parsedPage !== presentPage) {
                dispatch(setPresentPage(parsedPage));
            }
        } else if (presentPage !== 1) {
            dispatch(setPresentPage(1));
        }
    }, [location.search]);

     

    const handleDeleteAdset = (adsetData) => {

        deleteAdset({ adsetId: adsetData?.adset_id, adsAccountId: adsetData?.account_id, })
          .unwrap()
          .then(response => {
            refetchAllAdsetsList();

            toast({
              position: "top-right",
              title: "Ad set deleted",
              status: "success",
              duration: 4000,
              isClosable: true,
            });
          })
          .catch(error => {
            if (error?.data?.message) {
                toast({
                position: "top-right",
                title: "Error deleting ad set",
                status: "error",
                description: error?.data?.message,
                duration: 4000,
                isClosable: true,
                });
            }
          });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
    
                    const isActive = switchStates[original.adset_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad set is ON' : 'Ad set is OFF';
                    const isDisabled = userInfo?.role !== 'client' || original?.status === 'DELETED';

                    return (
                        <Flex gap={'10px'}  alignItems={'center'} justifyContent={'center'}>           
                                <Tooltip label={tooltipText} fontSize='xs' >
                                    <FormControl width={'fit-content'}>
                                        <Switch
                                            colorScheme='orange'
                                            size={'sm'}
                                            isChecked={original.status === 'ACTIVE'}
                                            onChange={() => handleSwitchChange(original, original.status)}
                                            isDisabled={isDisabled}
                                        />
                                    </FormControl>
                                </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    const isSelected = selectedAdsetId?.adset_id ? selectedAdsetId?.adset_id === original?.adset_id : selectedAdsetId  === original?.adset_id;
                    const isEditable = userInfo?.role === 'client' && original?.status !== 'DELETED';

                    return (
                    <Flex gap={'10px'} alignItems={'center'}> 
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={isEditable ? "pointer" : "not-allowed"} 
                                        color={isEditable ? "inherit" : "gray.300"}
                                        // cursor={"not-allowed"}
                                        // color={"gray.300"}
                                        onClick={() => {
                                            if (isEditable) {
                                                setselectedAdset(original);
                                                onOpen();
                                            }
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                        <Flex gap={'10px'} alignItems={'center'}>
                                <Link
                                    to={'#'} 
                                    onClick={() => {
                                        setSelectedTab(2);
                                        dispatch(setSelectedAdsetId(original)); 
                                        dispatch(setSelectedAdset(original))
                                    }}                      
                                >
                                    <Text
                                        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        fontWeight={isSelected ? 'bold' : 'normal'}
                                        color={isSelected ? 'orange.500' : 'inherit'}
                                        fontSize={'14px'}
                                    >
                                        {original.name.length > 30 ? `${original.name.slice(0, 30)}...` : original.name}
                                    </Text>
                                </Link>
                        </Flex>
                    </Flex>
                )}
            },  
            {
                Header: "Delivery",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                

                    const getStatusMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return 'Delivery error';
                            case 'CAMPAIGN_PAUSED':
                                return 'Campaign Off';
                            case 'ADSET_PAUSED':
                                return 'Adset Off';
                            case 'PAUSED':
                                return 'Off';
                            default:
                                return status || 'Unknown';
                        }
                    };
                    
                    const errorMessage = original?.issues_info?.[0]?.error_message || '';

                   // Function to determine circle color and border based on status
                    const getCircleStyles = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return { bg: 'rgb(161,11,24)', border: 'none' };  // Red for delivery error
                            case 'CAMPAIGN_PAUSED':
                            case 'ADSET_PAUSED':
                                return { bg: 'rgb(216,221,225)', border: 'none' };  // Gray for Campaign/Adset Off
                            case 'ACTIVE':
                                return { bg: 'rgb(255,255,255)', border: '2px solid green' };  // Gray background with green border for ACTIVE
                            case 'IN_PROCESS':
                            case 'PENDING_REVIEW':  
                                return { bg: 'rgb(255,255,255)', border: '2px solid green' };
                            default:
                                return { bg: 'rgb(255,255,255)', border: 'none' };  // Default gray with no border
                        }
                    };

                    const circleStyles = getCircleStyles(original?.effective_status);

                    const formatTooltipMessage = (message) => {
                        return message.split('\n').map((part, index) => (
                            <span key={index}>
                                {part}
                                <br />
                            </span>
                        ));
                    };
                    
                    // Determine tooltip message
                    const getTooltipMessage = (status) => {
                        switch (status) {
                            case 'WITH_ISSUES':
                                return errorMessage;  
                            case 'PAUSED':
                                return 'This ad set is turned off.';  
                            case 'ACTIVE':
                                return 'This campaign is on and running normally.'; 
                            case 'ADSET_PAUSED':
                                return 'This ad is on, but its ad set is turned off.'; 
                            case 'CAMPAIGN_PAUSED':
                                return 'This ad set is on, but its campaign is turned off.'; 
                            case 'DELETED':
                                return 'This ad set is deleted';
                            case 'IN_PROCESS':
                                return 'Your ad is processing.\nMeta is processing your recent updates to your ad. Some updates may take several hours.';
                            default:
                                return '';  // No tooltip for other statuses
                        }
                    };

                    const tooltipMessage = getTooltipMessage(original?.effective_status);

                    return (
                        <Flex>
                            {/* Only show Tooltip when status is WITH_ISSUES */}
                            {original?.effective_status === 'WITH_ISSUES' ? (
                                <Flex alignItems={'center'}>
                                 <Box
                                    bg={circleStyles.bg}
                                    border={circleStyles.border}
                                    borderRadius="50%" 
                                    boxSize="0.8rem"   
                                    display="inline-block"
                                    mr={2}             
                                />
                                <Tooltip label={formatTooltipMessage(tooltipMessage)} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                    <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                </Tooltip>
                                </Flex>
                            ) : (
                                <Flex alignItems={'center'}>
                                    {(original?.effective_status === 'PAUSED' || original?.effective_status === 'DELETED')  ? (null) : (
                                       <Box
                                        bg={circleStyles.bg}
                                        border={circleStyles.border}
                                        borderRadius="50%" 
                                        boxSize="0.8rem"   
                                        display="inline-block"
                                        mr={2}             
                                    />
                                    )}
                                    {/* Skip Tooltip for PENDING_REVIEW */}
                                    {original?.effective_status !== 'PENDING_REVIEW' && (
                                        <Tooltip label={formatTooltipMessage(tooltipMessage)} aria-label="Error message" hasArrow p={2} fontSize={'12px'}>
                                            <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                        </Tooltip>
                                    )}
                                    {original?.effective_status === 'PENDING_REVIEW' && (
                                        <Text fontSize={'14px'}>{getStatusMessage(original?.effective_status)}</Text>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    )}
            },   
            {
                Header: "Budget",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const { daily_budget, lifetime_budget } = original;
                    const currencySymbol = currencySymbolMap(original?.currency);
                    
                    const displayLifetimeBudget = Number(lifetime_budget) > 0 ? (Number(lifetime_budget) / 100).toFixed(2) : null;
                    const displayDailyBudget = Number(daily_budget) > 0 ? (Number(daily_budget) / 100).toFixed(2) : null;

                    // Determine which budget to display
                    const isLifetimeBudget = displayLifetimeBudget !== null;
                    const displayBudget = isLifetimeBudget 
                                            ? `${currencySymbol ? currencySymbol : ''}${displayLifetimeBudget}` 
                                            : (displayDailyBudget !== null 
                                                ? `${currencySymbol ? currencySymbol : ''}${displayDailyBudget}` 
                                                : "No Budget");
                    const budgetType = isLifetimeBudget ? "Lifetime" : (displayDailyBudget !== null ? "Daily" : "");

                    return (
                        <Flex direction={'column'} mb={-4}>
                            <Text fontSize={'14px'}>{displayBudget}</Text>
                            {budgetType && <Text fontSize={'10px'} color={'gray.500'}>{budgetType}</Text>}
                        </Flex>
                    );
                }
            },        
            {
                Header: "Spend",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const currencySymbol = currencySymbolMap(original?.currency);

                    return(
                        <Flex>
                            <Text fontSize={'14px'}>{original.insight?.spend ? `${currencySymbol}${Number(original.insight.spend).toFixed(2)}`: '-'}</Text>
                        </Flex>
                )}
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex direction={'column'} mb={-4}>
                        <Text fontSize={'14px'}>{original.insight?.results ? original.insight?.results : '-'}</Text>
                        <Text fontSize={'10px'} color={'gray.500'}>{original.insight?.eventName ? (original.insight?.eventName) : null}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const currencySymbol = currencySymbolMap(original?.currency);

                    return(
                        <Flex gap={'10px'}>
                            <Text fontSize={'14px'}>{original.insight?.cpc ? `${currencySymbol}${Number(original.insight?.cpc).toFixed(2)}` : '-'}</Text>
                        </Flex>
                )}
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>
                            {original.insight?.ctr ? `${Number(original.insight?.ctr).toFixed(2)}%` : '-'}
                        </Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.cpm ? Number(original.insight?.cpm).toFixed(2) : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'14px'}>{original.insight?.clicks ? original.insight?.clicks : '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const currencySymbol = currencySymbolMap(original?.currency);

                    return(
                        <Flex gap={'10px'}>
                            <Text fontSize={'14px'}>
                                {original.insight?.purchaseValue && original.insight.purchaseValue !== '-' ?
                                    `${currencySymbol}${parseFloat(original.insight.purchaseValue).toFixed(2)}` :
                                    original.insight?.purchaseValue || '-'}
                            </Text>
                        </Flex>
                )}
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const roas = original.insight?.roas;
            
                    // Check if roas is a valid number or a non-numeric value like '-'
                    const formattedROAS = (roas && !isNaN(parseFloat(roas))) 
                        ? parseFloat(roas).toFixed(2) 
                        : '-';  // Default to '-' if roas is invalid or non-numeric
            
                    return (
                        <Flex>
                            <Text fontSize={'14px'}>
                                {formattedROAS}
                            </Text>
                        </Flex>
                    );
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'14px'}>{original.status}</Text>
                ),
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text fontSize={'14px'}>{moment(original.created_time).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text fontSize={'14px'}>{moment(original.updated_time).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Actions",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isDeleteable = userInfo?.role === 'client' && original?.status !== 'DELETED';

                    return(
                        <Flex gap={'10px'} alignItems={'center'}>
                                <Tooltip label='Delete' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: 'gray.400' }}
                                        as={FiDelete}
                                        cursor={isDeleteable ? "pointer" : "not-allowed"} 
                                        color={isDeleteable ? "red.500" : "gray.300"} 
                                        onClick={() => {
                                            if (isDeleteable) {
                                                setselectedAdset(original);
                                                setIsConfirmationModalOpen(true);
                                            }
                                        }}
                                    />
                                </Flex>
                                </Tooltip>
                        </Flex>
                )}
            },
        ],
        [selectedAdsetId]
    );

    const confirmDelete = () => {
        if (selectedAdset) {
          handleDeleteAdset(selectedAdset);
          setIsConfirmationModalOpen(false);
          setselectedAdset(null);
        }
    };

    const handleOnSuccess = () => {
        refetchAllAdsetsList();
    }

    return (
        <>  
           {/* <Flex mb={2} alignItems="center">
            <Tooltip
                p={2}
                label="To create an Ad set, please select an Campaign from the Campaigns tab."
                isDisabled={isCampaignSelected}
                placement="right"
                borderRadius={'sm'}
                fontSize={'12px'}
            >
                <Box
                as="button"
                display="inline-flex"
                alignItems="center"
                bg={!isCampaignSelected ? "#F8C9C3" : "orange.300"} 
                color={"white"}
                borderRadius="md"
                fontWeight={'bold'}
                px={3}
                py={1.5}
                fontSize={'14px'}
                onClick={() => {
                    if (isCampaignSelected) {
                    setIsCreateFormVisible(true);
                    }
                }}
                _hover={{ cursor: isCampaignSelected ? "pointer" : "not-allowed" }} 
                _disabled={{ bg: "gray.300", cursor: "not-allowed" }} 
                >
                <AddIcon boxSize={3} />
                &nbsp;Create
                </Box>
            </Tooltip>
            </Flex>

            {isCreateFormVisible && (
                <CreateAdsetForm
                    adsAccountData={adsAccountData}
                    isOpen={isCreateFormVisible}
                    onClose={() => setIsCreateFormVisible(false)} 
                    onSuccess={handleOnSuccess}
                />
            )} */}

            <DeleteConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmDelete}
                message="Are you sure you want to delete this Ad set? This action can't be undone."
           />

            {/* EDIT ADSET MODAL */}
            <Modal
                size={"5xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={() => {onClose(); setselectedAdset(null)}}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AcrossAdSetUpdate selectedAdset={selectedAdset} onClose={onClose} onSaveSuccess={handleSaveSuccess} />
                    </ModalBody>
                </ModalContent>
            </Modal>
            {AlladsetslistError  ? (
                <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
                    <Text color="red.500">
                        {AlladsetslistError?.data?.message}
                    </Text>
                </Flex>
            ) : (isAllAdsetsListLoading || isAdsetInsightsLoading || isLoadingAdsetsWithInsights) ? (
                    <Spinner mt="20px" />
            ) : (
                <AppTableWithPagination
                    columns={columns}
                    data={adsetsListWithInsights|| []}
                    paginationMeta={allAdsetsIds?.meta || {}}
                    onPageChange={handlePageChange}
                    onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                    searchEnabled={true}
                    isFetching={isAllAdsetIdsFetching}
              />
            )}
        </>
    );

}

export default AllAdSetsTable;
