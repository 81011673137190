import { useFormik } from 'formik';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Input, Button, Flex, Text, Select, Box, RadioGroup, Stack, Radio, Checkbox, Tooltip, IconButton, useToast,
  InputGroup, InputRightAddon
} from '@chakra-ui/react';
import { InfoOutlineIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useUpdateAdRuleMutation } from 'store/adRules/adRuleApi';
import { removeEmptyValues } from 'utils/functions';
import { createRuleSchema } from 'utils/schema';
import currencySymbolMap from 'currency-symbol-map';
import AdUpdateSelection from './AdUpdateSelection';

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      times.push({ value, label: value });
    }
  }
  return times;
};

const timeOptions = generateTimeOptions();

const convertTimeToMinutes = (time) => {
  if (!time) return "0"; // Default to 0 if no time is provided
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 60 + minutes).toString();
};

const convertMinutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};


function UpdateRuleForm({ isOpen, onClose, adAccounts, onSuccess, ruleData }) {

  const toast = useToast();
  const [selectedAdIds, setSelectedAdIds] = useState(0);
  const [selectedAdsetIds, setSelectedAdsetIds] = useState(0);
  const currencyCode = adAccounts?.currency;
  const currencySymbol = currencySymbolMap(currencyCode);

  const [customSchedules, setCustomSchedules] = useState(daysOfWeek.map(() => ({
    days: [],
    timeSlots: [{ startTime: '', endTime: '' }]
  })));

  const [updateAdRule, { isLoading: isAdRuleUpdating }] = useUpdateAdRuleMutation({});

  const updateRuleFormik = useFormik({
    initialValues: {
      ruleName: ruleData?.name || '',
      applyTo: ruleData?.filters.find(filter => filter.field === 'entity_type')?.value || '',
      action: ruleData?.execution_type || '',
      budgetAmount: '',
      currencySymbol: 'PERCENTAGE',
      maxLifetimeBudgetCap: '',
      actionFrequency: '',
      conditionField: '',
      conditionOperator: '',
      conditionValue: '',
      conditionValue1: '',
      conditionValue2: '',
      timeRange: ruleData?.filters.find(filter => filter.field === 'time_preset')?.value || '',
      schedule: ruleData?.schedule_type || '',
      notification: true,
      customSchedules: [],
    },
    enableReinitialize: true,
    validateOnChange: false, 
    validateOnBlur: false,
    validationSchema: createRuleSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {

      const formatValue = (value) => {
        if (value && !isNaN(value)) {
          return parseFloat(value) * 100;
        }
        return value;
      };

      const filters = [
        { field: "entity_type", operator: "EQUAL", value: values.applyTo },
        { field: "time_preset", operator: "EQUAL", value: values.timeRange },
        ...((values.applyTo === 'ADSET') && (values.action !== 'PAUSE' && values.action !== 'UNPAUSE') ? [{
          field: "adset.budget_reset_period", 
          operator: "IN", 
          value: (values.action === 'INCREASE_DAY' || values.action === 'DECREASE_DAY') ? ['DAY'] 
                 : (values.action === 'INCREASE_LIFETIME' || values.action === 'DECREASE_LIFETIME') ? ['LIFETIME'] 
                 : []
        }] : []),
        {
          field: values.conditionField,
          value: values.conditionOperator === 'IN_RANGE' || values.conditionOperator === 'NOT_IN_RANGE'
            ? [formatValue(values.conditionValue1), formatValue(values.conditionValue2)]
            : formatValue(values.conditionValue),
          operator: values.conditionOperator,
        },
      ];


      // Apply filters based on `applyTo` value
      if (values.applyTo === 'AD' && selectedAdIds.length > 0) {
        filters.push({
          field: "ad.id",
          value: selectedAdIds,
          operator: "IN"
        });
      }

      if (values.applyTo === 'ADSET' && selectedAdsetIds.length > 0) {
        filters.push({
          field: "adset.id",
          value: selectedAdsetIds,
          operator: "IN"
        });
      }

      const rulePayload = {
        name: values.ruleName,
        schedule_type: values.schedule,
        evaluation_type: "SCHEDULE",
        schedule: values.customSchedules.flatMap(schedule =>
          schedule.days.length > 0 ?
            schedule.timeSlots.map(timeSlot => ({
              days: schedule.days.map(day => daysOfWeek.indexOf(day)),
              start_minute: convertTimeToMinutes(timeSlot.startTime),
              end_minute: convertTimeToMinutes(timeSlot.endTime),
            })) : []
        ),
        filters,
        execution_type: values.action,
        execution_type: (values.action === 'INCREASE_DAY' || values.action === 'DECREASE_DAY' || values.action === 'INCREASE_LIFETIME' || values.action === 'DECREASE_LIFETIME') ? 'CHANGE_BUDGET' : values.action,
        execution_options: (values.action === 'INCREASE_DAY' || values.action === 'DECREASE_DAY' || values.action === 'INCREASE_LIFETIME' || values.action === 'DECREASE_LIFETIME') ? [
          {
            field: "change_spec",
            value: {
              amount: values?.currencySymbol == 'ACCOUNT_CURRENCY'
                ? (values.budgetAmount * 100).toString()
                : (values.action === 'DECREASE_LIFETIME' || values.action === 'DECREASE_DAY' ? (-values.budgetAmount).toString() : values.budgetAmount.toString()),

              limit: (values.maxLifetimeBudgetCap * 100).toString() || '',
              unit: values.currencySymbol,
              target_field: null,
            },
            operator: "EQUAL",
          },
          {
            field: "action_frequency",
            value: values.actionFrequency || "60",
            operator: "EQUAL",
          },
          {
            field: "alert_preferences",
            value: {
              instant: { trigger: "CHANGE" },
            },
            operator: "EQUAL",
          },
        ] : [],
        // adsAccount: {
        //   id: adAccounts?._id,
        //   adsAccountId: adAccounts?.adsAccountId, 
        //   adsAccountName: adAccounts?.adsAccountName
        // }
      };
      const payload = removeEmptyValues(rulePayload);

      updateAdRule({ payload, AdRuleId: ruleData?.adrule_id }).unwrap()
        .then(response => {
          onSuccess();
          toast({
            position: "top-right",
            title: "Ad Rule updated ",
            status: "success",
            duration: 4000,
            isClosable: true,
          });

        })
        .catch(error => {
          toast({
            position: "top-right",
            title: "Error updating Ad Rule",
            status: "error",
            description: error?.data?.message,
            duration: 4000,
            isClosable: true,
          });

        })
        .finally(() => {
          setSubmitting(false);
          resetForm();
        });
      onClose();
    },
  });

  useEffect(() => {

    if (isOpen && ruleData) {
      const selectedAdIds = ruleData.filters
        .filter(filter => filter.field === "ad.id")
        .map(filter => filter.value).flat();

      const selectedAdsetIds = ruleData.filters
        .filter(filter => filter.field === "adset.id")
        .map(filter => filter.value).flat();

      setSelectedAdIds(selectedAdIds);
      setSelectedAdsetIds(selectedAdsetIds);

      const conditionFilter = ruleData?.filters?.find(filter =>
        ['spent', 'cost_per', 'website_purchase_roas', 'cpc', 'cpm', 'ctr'].includes(filter?.field)
      );

      if (conditionFilter) {
        updateRuleFormik.setFieldValue('conditionField', conditionFilter?.field);
        updateRuleFormik.setFieldValue('conditionOperator', conditionFilter?.operator);

        if (conditionFilter.operator === 'GREATER_THAN' || conditionFilter.operator === 'LESS_THAN') {
          updateRuleFormik.setFieldValue('conditionValue', conditionFilter?.value / 100 || '');
        }

        if (conditionFilter.operator === 'IN_RANGE' || conditionFilter.operator === 'NOT_IN_RANGE') {
          const values = conditionFilter?.value || [];
          updateRuleFormik.setFieldValue('conditionValue1', values[0] / 100 || '');
          updateRuleFormik.setFieldValue('conditionValue2', values[1] / 100 || '');
        }
      }

      if (ruleData?.schedule_type === 'CUSTOM') {
        // Initialize customSchedules for all days with empty slots
        const allDaysSchedules = daysOfWeek.map((day) => {
          const existingSchedules = ruleData.schedule.filter(schedule =>
            schedule.days.includes(daysOfWeek.indexOf(day))
          );

          // Create time slots from existing schedules or default to empty
          const timeSlots = existingSchedules.length > 0
            ? existingSchedules.map(schedule => ({
              startTime: convertMinutesToTime(schedule.start_minute),
              endTime: convertMinutesToTime(schedule.end_minute),
            }))
            : [{ startTime: '', endTime: '' }]; // Default to one empty slot if no schedules

          return {
            days: existingSchedules.length > 0 ? [day] : [], // Include day if it exists in any schedule
            timeSlots,
          };
        });

        setCustomSchedules(allDaysSchedules);
        updateRuleFormik.setFieldValue('customSchedules', allDaysSchedules);
      }

      // Check the adset.budget_reset_period filter (LIFETIME or DAY)
      const budgetResetPeriodFilter = ruleData?.filters?.find(
        filter => filter.field === 'adset.budget_reset_period'
      );

      const changeSpec = ruleData?.execution_options?.find(option => option.field === 'change_spec');
      const amount = parseFloat(changeSpec?.value?.amount) || 0; // Amount from execution_options

      // Adjust action based on the amount value and reset period
      if (budgetResetPeriodFilter) {
        // If LIFETIME reset period, check if amount is positive or negative
        if (budgetResetPeriodFilter.value.includes('LIFETIME')) {
          if (amount > 0) {
            // If amount is positive, set to "Increase"
            updateRuleFormik.setFieldValue('action', 'INCREASE_LIFETIME');
          } else {
            // If amount is negative, set to "Decrease"
            updateRuleFormik.setFieldValue('action', 'DECREASE_LIFETIME');
          }
        }

        // If DAY reset period, check if amount is positive or negative
        if (budgetResetPeriodFilter.value.includes('DAY')) {
          if (amount > 0) {
            // If amount is positive, set to "Increase"
            updateRuleFormik.setFieldValue('action', 'INCREASE_DAY');
          } else {
            // If amount is negative, set to "Decrease"
            updateRuleFormik.setFieldValue('action', 'DECREASE_DAY');
          }
        }
      }

      // Initialize execution_
      const executionOptions = ruleData.execution_options || [];
      const actionFrequency = executionOptions.find(option => option.field === 'action_frequency');

      // Set execution options fields in the form
      if (changeSpec) {

        const amount = Math.abs(parseFloat(changeSpec?.value?.amount));
        if (changeSpec?.value?.unit === 'ACCOUNT_CURRENCY') {
          updateRuleFormik.setFieldValue('budgetAmount', amount / 100 || '');
        } else {
          updateRuleFormik.setFieldValue('budgetAmount', amount || '');
        }

        updateRuleFormik.setFieldValue('maxLifetimeBudgetCap', (changeSpec?.value?.limit) / 100 || '');

        updateRuleFormik.setFieldValue('currencySymbol', changeSpec?.value?.unit || 'PERCENTAGE');
      }

      if (actionFrequency) {
        updateRuleFormik.setFieldValue('actionFrequency', actionFrequency?.value || '');
      }
    }

  }, [isOpen, ruleData]);

  const handleDayChange = (index, day) => {
    const newSchedules = [...customSchedules];
    if (newSchedules[index].days.includes(day)) {
      newSchedules[index].days = newSchedules[index].days.filter(d => d !== day);
    } else {
      newSchedules[index].days.push(day);
    }
    setCustomSchedules(newSchedules);
    updateRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleAddTimeSlot = (scheduleIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.push({ startTime: '', endTime: '' });
    setCustomSchedules(newSchedules);
    updateRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleTimeSlotChange = (scheduleIndex, slotIndex, field, value) => {
    const newSchedules = [...customSchedules];

    // Update the time slot based on the field
    newSchedules[scheduleIndex].timeSlots[slotIndex][field] = value;

    if (field === 'startTime') {
      // If both are empty, set endTime to same value(which is start time)
      if (newSchedules[scheduleIndex].timeSlots[slotIndex].endTime === '') {
        newSchedules[scheduleIndex].timeSlots[slotIndex].endTime = value;
      }

      if (newSchedules[scheduleIndex].timeSlots[slotIndex].endTime < value) {
        newSchedules[scheduleIndex].timeSlots[slotIndex].endTime = value;
      }
    } else if (field === 'endTime') {
      if (newSchedules[scheduleIndex].timeSlots[slotIndex].startTime > value) {
        newSchedules[scheduleIndex].timeSlots[slotIndex].startTime = value;
      }
    }

    setCustomSchedules(newSchedules);
    updateRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleRemoveTimeSlot = (scheduleIndex, slotIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.splice(slotIndex, 1);
    setCustomSchedules(newSchedules);
    updateRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleClose = () => {
    // Reset form values before closing the modal
    updateRuleFormik.resetForm();
    setCustomSchedules(daysOfWeek.map(() => ({
      days: [],
      timeSlots: [{ startTime: '', endTime: '' }]
    })));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="inside" isCentered size={"6xl"} >
      <ModalOverlay />
      <ModalContent maxHeight={'100vh'}>
        <form onSubmit={updateRuleFormik.handleSubmit}>
          <ModalHeader boxShadow="md" p={'12px'}>
            <Text fontWeight="bold" fontSize={'20px'}>Edit rule:custom</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Flex gap={4} height="calc(100vh - 200px)" overflow="hidden">
              <Flex flex={0.3} overflowY="auto" maxHeight={'80vh'} p={2}>
                <AdUpdateSelection
                  adAccounts={adAccounts}
                  selectedAdIds={selectedAdIds}
                  selectedAdsetIds={selectedAdsetIds}
                />
              </Flex>
              <Flex direction={'column'} flex={0.7} p={3} overflowY="auto" maxHeight={'80vh'}>
                <Text mb={4} fontSize={'14px'} >
                  Automatically update campaigns, ad sets or ads in bulk by creating automated rules.
                </Text>

                <Flex direction="column" gap={4}>
                  {/* Rule name */}
                  <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Rule name</Text>
                    <Input
                      name="ruleName"
                      placeholder="Rule name"
                      fontSize={'sm'}
                      value={updateRuleFormik.values.ruleName}
                      onChange={updateRuleFormik.handleChange}
                      onBlur={updateRuleFormik.handleBlur}
                      size={'sm'}
                      borderRadius={'md'}
                    />
                    {updateRuleFormik.touched.ruleName && updateRuleFormik.errors.ruleName && (
                      <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.ruleName}</Text>
                    )}
                  </Flex>

                  {/* Apply rule to */}
                  <Flex direction="row" gap={1} align={'center'} >
                    <Text fontWeight="bold">Apply rule to:&nbsp;</Text>
                    {/* Directly show the option without Select */}
                    <Text fontSize="sm" color='orange.600' fontWeight={'semibold'}>
                      {updateRuleFormik.values.applyTo === 'ADSET' ? (
                        selectedAdsetIds?.length > 0
                          ? `${selectedAdsetIds?.length} ad set`
                          : "All active ad sets"
                      ) : updateRuleFormik.values.applyTo === 'AD' ? (
                        selectedAdIds?.length > 0
                          ? `${selectedAdIds?.length} ad`
                          : "All active ads"
                      ) : null}
                    </Text>

                    {updateRuleFormik.touched.applyTo && updateRuleFormik.errors.applyTo && (
                      <Text color="red.500" fontSize="sm">{updateRuleFormik.errors.applyTo}</Text>
                    )}
                  </Flex>

                  {/* Action */}
                  <Flex direction="column" gap={2}>
                    <Text fontWeight="bold">Action</Text>

                    {/* Main action select */}
                    <Flex direction="row" align="center" gap={2} justifyContent="space-between">
                      <Select
                        name="action"
                        fontSize="sm"
                        size={'sm'}
                        borderRadius={'md'}
                        value={updateRuleFormik.values.action}
                        onChange={updateRuleFormik.handleChange}
                        onBlur={updateRuleFormik.handleBlur}
                        width={
                          (updateRuleFormik.values.action === "PAUSE" && updateRuleFormik.values.applyTo === "ADSET" || 'AD') ||
                            (updateRuleFormik.values.action === "UNPAUSE" && updateRuleFormik.values.applyTo === "ADSET" || 'AD')
                            ? "100%"
                            : "500px"
                        }
                      >
                        <option value="PAUSE">Turn off ads</option>
                        <option value="UNPAUSE">Turn on ads</option>
                        {updateRuleFormik.values.applyTo === "ADSET" && (
                          <>
                            <option value="INCREASE_DAY">Increase daily budget by</option>
                            <option value="DECREASE_DAY">Decrease daily budget by</option>
                            <option value="INCREASE_LIFETIME">Increase lifetime budget by</option>
                            <option value="DECREASE_LIFETIME">Decrease lifetime budget by</option>
                          </>
                        )}
                      </Select>

                      {/* Show input + select only for budget actions */}
                      {(
                        ['INCREASE_DAY', 'DECREASE_DAY', 'INCREASE_LIFETIME', 'DECREASE_LIFETIME'].includes(updateRuleFormik.values.action) &&
                        updateRuleFormik.values.applyTo === 'ADSET'
                      ) && (
                          <Flex direction="column">
                            <Flex alignItems={'center'} justifyItems={'center'}>
                              {/* Budget Amount Input */}
                              <InputGroup width="150px">
                                <Input
                                  name="budgetAmount"
                                  value={updateRuleFormik.values.budgetAmount}
                                  size={'sm'}
                                  borderRadius={'md'}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    // Apply only when 'PERCENTAGE'
                                    if (updateRuleFormik.values.currencySymbol === 'PERCENTAGE') {
                                      if (value && !isNaN(value) && Number(value) > 100) {
                                        updateRuleFormik.setFieldValue("budgetAmount", "");
                                      } else {
                                        updateRuleFormik.handleChange(e);
                                      }
                                    } else {
                                      updateRuleFormik.handleChange(e);
                                    }
                                  }}
                                  onBlur={updateRuleFormik.handleBlur}
                                  placeholder="0.00"
                                  borderRightRadius={0}
                                  borderRight={0}
                                  fontSize="12px"
                                />
                                 <InputRightAddon 
                                      bg="#fff" 
                                      boxSize={8} 
                                      borderRightRadius={0} 
                                      pl={4}  
                                      display="flex" 
                                      justifyContent="center" 
                                      alignItems="center"
                                    >
                                  <Text fontSize="12px" align={'left'}>{updateRuleFormik.values.currencySymbol === "ACCOUNT_CURRENCY" ? currencyCode : ''}</Text>
                                </InputRightAddon>
                              </InputGroup>

                              {/* Currency Symbol Select */}
                              <Select
                                name="currencySymbol"
                                fontSize="sm"
                                value={updateRuleFormik.values.currencySymbol}
                                onChange={updateRuleFormik.handleChange}
                                onBlur={updateRuleFormik.handleBlur}
                                width="120px"
                                borderLeft={0}
                                borderLeftRadius={0}
                                size={'sm'}
                                borderRightRadius={'md'}
                              >
                                <option value="PERCENTAGE">%</option>
                                <option value="ACCOUNT_CURRENCY">{currencySymbol}</option>
                              </Select>
                            </Flex>
                          </Flex>
                        )}
                    </Flex>
                    <Flex justifyContent={'flex-end'} mr={'210px'} mt={'-8px'}>
                      {updateRuleFormik.touched.budgetAmount && updateRuleFormik.errors.budgetAmount && (
                        <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.budgetAmount}</Text>
                      )}
                    </Flex>

                    {updateRuleFormik.touched.action && updateRuleFormik.errors.action && (
                      <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.action}</Text>
                    )}

                    {/* Conditional Budget and Frequency Info */}
                    {(
                      ['INCREASE_DAY', 'DECREASE_DAY', 'INCREASE_LIFETIME', 'DECREASE_LIFETIME'].includes(updateRuleFormik.values.action) &&
                      updateRuleFormik.values.applyTo === 'ADSET'
                    ) && (
                        <>
                          <Flex mt={2} mb={2}>
                            <Text fontSize="12px">
                              Your rule will apply to ad sets that are active at the time the rule runs. Only ad sets with daily budgets can be managed by this rule action.
                            </Text>
                          </Flex>

                          {(['DECREASE_DAY', 'DECREASE_LIFETIME'].includes(updateRuleFormik.values.action) &&
                            updateRuleFormik.values.currencySymbol === 'PERCENTAGE' &&
                            updateRuleFormik.values.budgetAmount >= 50 &&
                            updateRuleFormik.values.budgetAmount <= 100) && (
                              <Flex alignItems="center" mb={4} gap={2} bg={'gray.50'} p={2} borderRadius={'5px'}>
                                <InfoOutlineIcon pointerEvents="all" mb={5} />
                                <Text fontSize="12px">
                                  Decreasing your budget by this percentage each time an ad set meets rule conditions could cause it to be under-delivered.
                                </Text>
                              </Flex>
                            )}

                          <Flex direction="row" gap={4} justifyContent="space-between">
                            {/* Maximum Lifetime Budget Cap */}
                            <Flex direction="column" width="100%">
                              <Flex alignItems="center" gap={1}>
                                <Text fontWeight="bold" mb={1}>
                                  {updateRuleFormik.values.action === 'INCREASE_LIFETIME'
                                    ? 'Maximum Lifetime Budget Cap'
                                    : updateRuleFormik.values.action === 'DECREASE_LIFETIME'
                                      ? 'Minimum Lifetime Budget Floor'
                                      : updateRuleFormik.values.action === 'INCREASE_DAY'
                                        ? 'Maximum Day Budget Cap'
                                        : updateRuleFormik.values.action === 'DECREASE_DAY'
                                          ? 'Minimum Day Budget Floor'
                                          : 'Budget Cap/Floor'}
                                </Text>
                                <Tooltip
                                  borderRadius={5}
                                  label={
                                    <Box fontSize="sm" p="5px">
                                      <Text>
                                        {['DECREASE_DAY', 'DECREASE_LIFETIME'].includes(updateRuleFormik.values.action)
                                          ? 'This is the lowest amount the '
                                          : 'This is the highest amount the '}
                                        {['DECREASE_DAY', 'INCREASE_DAY'].includes(updateRuleFormik.values.action)
                                          ? 'daily budget can reach for each ad set managed by your rule.'
                                          : 'lifetime budget can reach for each ad set managed by your rule.'}

                                        <br />

                                        {['DECREASE_DAY', 'DECREASE_LIFETIME'].includes(updateRuleFormik.values.action)
                                          ? 'You may see a partial decrease if an ad set\'s '
                                          : 'You may see a partial increase if an ad set\'s '}

                                        {['DECREASE_LIFETIME', 'INCREASE_LIFETIME'].includes(updateRuleFormik.values.action)
                                          ? 'lifetime budget reaches the cap before the full percentage or currency amount set by you is applied.'
                                          : 'daily budget reaches the cap before the full percentage or currency amount set by you is applied.'}


                                        {['DECREASE_DAY', 'INCREASE_DAY'].includes(updateRuleFormik.values.action)
                                          ? ' (daily budget)'
                                          : ''}
                                      </Text>
                                    </Box>
                                  }
                                  fontSize="md"
                                >
                                  <InfoOutlineIcon mb={1} pointerEvents="all" />
                                </Tooltip>
                              </Flex>
                              <InputGroup>
                                <Input
                                  name="maxLifetimeBudgetCap"
                                  value={updateRuleFormik.values.maxLifetimeBudgetCap}
                                  onChange={updateRuleFormik.handleChange}
                                  onBlur={updateRuleFormik.handleBlur}
                                  placeholder="Enter budget cap"
                                  fontSize="sm"
                                  size={'sm'}
                                  borderRadius={'md'}
                                  borderRight={0}
                                />
                                 <InputRightAddon 
                                    bg="#fff" 
                                    fontSize="12px"
                                    height="100%" 
                                    px={2}         
                                >
                                  {currencyCode}
                                </InputRightAddon>
                              </InputGroup>
                              {updateRuleFormik.touched.maxLifetimeBudgetCap && updateRuleFormik.errors.maxLifetimeBudgetCap && (
                                <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.maxLifetimeBudgetCap}</Text>
                              )}
                            </Flex>

                            {/* Action Frequency */}
                            <Flex direction="column" width="100%">
                              <Flex alignItems="center" gap={1}>
                                <Text fontWeight="bold" mb={1}>Action Frequency</Text>
                                <Tooltip
                                  borderRadius={5}
                                  label={
                                    <Box fontSize="sm" p="5px">
                                      <Text>
                                        The minimum amount of time until the same action can be taken on an object by a rule. The default value is once every 12 hours.
                                      </Text>
                                    </Box>
                                  }
                                  fontSize="md"
                                >
                                  <InfoOutlineIcon mb={1} pointerEvents="all" />
                                </Tooltip>
                              </Flex>
                              <Select
                                name="actionFrequency"
                                fontSize="sm"
                                value={updateRuleFormik.values.actionFrequency}
                                onChange={updateRuleFormik.handleChange}
                                onBlur={updateRuleFormik.handleBlur}
                                size={'sm'}
                                borderRadius={'md'}
                              >
                                <option value="60">Once hourly</option>
                                <option value="720">Once every 12 hours (default)</option>
                                <option value="1440">Once daily</option>
                                <option value="10080">Once weekly</option>
                                <option value="20160">Every 2 weeks</option>
                              </Select>
                            </Flex>
                          </Flex>
                        </>
                      )}
                  </Flex>

                  {/* Conditions */}
                  <Flex direction="column">
                    <Flex alignItems={'center'} gap={1}>
                      <Text fontWeight="bold">Conditions</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>These will be used to evaluate whether the rule's action should be taken on each of your selected ad sets.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>
                    <Text fontSize={'12px'}>ALL of the following match</Text>
                    <Flex gap={2} mt={"10px"}>
                      <Flex flexBasis="30%">
                        <Select
                          name="conditionField"
                          fontSize={'sm'}
                          value={updateRuleFormik.values.conditionField}
                          onChange={updateRuleFormik.handleChange}
                          size={'sm'}
                          borderRadius={'md'}
                        >
                          <option value="spent">Spent</option>
                          <option value="cost_per">Cost per result</option>
                          <option value="website_purchase_roas">Website purchase ROAS</option>
                          <option value="cpc">CPC</option>
                          <option value="cpm">CPM</option>
                          <option value="ctr">CTR</option>
                        </Select>
                      </Flex>

                      <Flex flexBasis="30%">
                        <Select
                          name="conditionOperator"
                          fontSize={'sm'}
                          value={updateRuleFormik.values.conditionOperator}
                          onChange={(e) => {
                            updateRuleFormik.handleChange(e);
                            if (['IN_RANGE', 'NOT_IN_RANGE'].includes(e.target.value)) {
                              updateRuleFormik.setFieldValue('conditionValue', '');
                              updateRuleFormik.setFieldValue('conditionValue1', '');
                              updateRuleFormik.setFieldValue('conditionValue2', '');

                              updateRuleFormik.setFieldTouched('conditionValue', false);
                              updateRuleFormik.setFieldTouched('conditionValue1', false);
                              updateRuleFormik.setFieldTouched('conditionValue2', false);
                            } else if (['GREATER_THAN', 'LESS_THAN'].includes(e.target.value)) {
                              updateRuleFormik.setFieldValue('conditionValue', '');
                              updateRuleFormik.setFieldTouched('conditionValue', false);
                            }
                          }}
                          size={'sm'}
                          borderRadius={'md'}
                        >
                          <option value="GREATER_THAN">is greater than</option>
                          <option value="LESS_THAN">is smaller than</option>
                          <option value="IN_RANGE">is between</option>
                          <option value="NOT_IN_RANGE">is not between</option>
                        </Select>
                      </Flex>

                      <Flex direction="column" flexBasis={['GREATER_THAN', 'LESS_THAN'].includes(updateRuleFormik.values.conditionOperator) ? "20%" : "0%"}>
                        {['GREATER_THAN', 'LESS_THAN'].includes(updateRuleFormik.values.conditionOperator) && (
                          <Input
                            name="conditionValue"
                            placeholder={currencySymbol}
                            fontSize={'sm'}
                            value={updateRuleFormik.values.conditionValue}
                            onChange={updateRuleFormik.handleChange}
                            onBlur={updateRuleFormik.handleBlur}
                            size={'sm'}
                            borderRadius={'md'}
                          />
                        )}
                        {updateRuleFormik.touched.conditionValue && updateRuleFormik.errors.conditionValue && (
                          <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.conditionValue}</Text>
                        )}
                      </Flex>

                      <Flex flexBasis={['IN_RANGE', 'NOT_IN_RANGE'].includes(updateRuleFormik.values.conditionOperator) ? "30%" : "0%"}>
                        {['IN_RANGE', 'NOT_IN_RANGE'].includes(updateRuleFormik.values.conditionOperator) && (
                          <Flex>
                            <Flex direction="column">
                              <Input
                                name="conditionValue1"
                                placeholder=""
                                fontSize={'sm'}
                                value={updateRuleFormik.values.conditionValue1}
                                onChange={updateRuleFormik.handleChange}
                                onBlur={updateRuleFormik.handleBlur}
                                size={'sm'}
                                borderRadius={'md'}
                              />
                              {updateRuleFormik.touched.conditionValue1 && updateRuleFormik.errors.conditionValue1 && (
                                <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.conditionValue1}</Text>
                              )}
                            </Flex>
                            <Flex direction="column" justifyContent={'center'} >
                              <Text mx={2}>-</Text>
                            </Flex>
                            <Flex direction="column">
                              <Input
                                name="conditionValue2"
                                placeholder=""
                                fontSize={'sm'}
                                value={updateRuleFormik.values.conditionValue2}
                                onChange={updateRuleFormik.handleChange}
                                onBlur={updateRuleFormik.handleBlur}
                                size={'sm'}
                                borderRadius={'md'}
                              />
                              {updateRuleFormik.touched.conditionValue2 && updateRuleFormik.errors.conditionValue2 && (
                                <Text color="red.500" fontSize="12px">{updateRuleFormik.errors.conditionValue2}</Text>
                              )}
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>

                  {/* Time range */}
                  <Flex direction="column" gap={1} mt={'5px'}>
                    <Flex alignItems={'center'} gap={1}>
                      <Text fontWeight="bold">Time range</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>This is the number of days of data you'd like to apply your rule to.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>

                    <Select
                      name="timeRange"
                      fontSize={'sm'}
                      value={updateRuleFormik.values.timeRange}
                      onChange={updateRuleFormik.handleChange}
                      onBlur={updateRuleFormik.handleBlur}
                      size={'sm'}
                      borderRadius={'md'}
                    >
                      <option value="MAXIMUM">Maximum</option>
                      <option value="TODAY">Today</option>
                      <option value="LAST_7_DAYS">Last 7 days</option>
                      {/* <option value="last_14_days">Last 14 days</option>
                      <option value="last_30_days">Last 30 days</option> */}

                    </Select>
                    {updateRuleFormik.touched.timeRange && updateRuleFormik.errors.timeRange && (
                      <Text color="red.500" fontSize="sm">{updateRuleFormik.errors.timeRange}</Text>
                    )}
                  </Flex>

                  {/* Schedule */}
                  <Flex direction="column" gap={1}>
                    <Flex alignItems={'center'} gap={1}>
                      <Text fontWeight="bold">Schedule</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>This will determine how frequently to check the rule. Your rule will be checked in your ad account's time zone.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>
                    <RadioGroup
                      name="schedule"
                      value={updateRuleFormik.values.schedule}
                      onChange={(value) => {
                        updateRuleFormik.setFieldValue('schedule', value);
                        if (value === 'CUSTOM') {
                          const initialCustomSchedules = daysOfWeek.map(() => ({
                            days: [],
                            timeSlots: [{ startTime: '', endTime: '' }],
                          }));
                          updateRuleFormik.setFieldValue('customSchedules', initialCustomSchedules);
                          setCustomSchedules(initialCustomSchedules);
                        } else {
                          // Clear customSchedules 
                          updateRuleFormik.setFieldValue('customSchedules', []);
                          setCustomSchedules([]);
                        }
                      }}
                    >
                      <Stack spacing={5} direction='column'>
                        <Flex direction="column">
                          <Flex align="center">
                            <Radio colorScheme='orange' value='SEMI_HOURLY' />
                            <Text fontWeight="bold" ml={2}>Continuously</Text>
                          </Flex>
                          <Text fontSize={'sm'} ml={6}>Rule runs as often as possible (usually every 30-60 minutes).</Text>
                        </Flex>

                        <Flex direction="column">
                          <Flex align="center">
                            <Radio colorScheme='orange' value='DAILY' />
                            <Text fontWeight="bold" ml={2}>Daily </Text>
                          </Flex>
                          <Text fontSize={'sm'} ml={6}>at 12.00 am Kolkata Time</Text>
                        </Flex>

                        <Flex direction="column">
                          <Flex align="center">
                            <Radio colorScheme='orange' value='CUSTOM' />
                            <Text fontWeight="bold" ml={2}>Custom </Text>
                          </Flex>
                          <Text fontSize={'sm'} ml={6}>Adjust the rule schedule to run on specific days and at specific times of day.</Text>
                          {updateRuleFormik.values.schedule === 'CUSTOM' && (
                            <Flex direction="column" mt={'12px'} ml={'12px'}>
                              {customSchedules.map((schedule, index) => (
                                <Flex key={index} direction="column" gap={2}>
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Flex flexBasis="20%">
                                      <Checkbox
                                        isChecked={schedule.days.includes(daysOfWeek[index])}
                                        onChange={() => handleDayChange(index, daysOfWeek[index])}
                                        colorScheme={'orange'}
                                      >
                                        {daysOfWeek[index]}
                                      </Checkbox>
                                    </Flex>
                                    <Flex flexBasis="35%" style={{ width: '200px' }} justify="center">
                                      <ReactSelect
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            width: '200px',
                                            fontSize: "small"
                                          }),
                                          option: (base, state) => ({
                                            ...base,
                                            backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                            color: 'black',
                                            fontSize: "small"
                                          }),
                                          indicatorSeparator: () => ({
                                            display: 'none',
                                          }),
                                        }}
                                        options={timeOptions}
                                        isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                        value={schedule.timeSlots[0]?.startTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.startTime) : null}
                                        onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'startTime', selectedOption.value)}
                                        placeholder="00:00"
                                      />
                                    </Flex>
                                    <Text fontSize="sm">to</Text>
                                    <Flex flexBasis="35%" justifyContent="center">
                                      <ReactSelect
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            width: '200px',
                                            fontSize: "small"
                                          }),
                                          option: (base, state) => ({
                                            ...base,
                                            backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                            color: 'black',
                                            fontSize: "small"
                                          }),
                                          indicatorSeparator: () => ({
                                            display: 'none',
                                          }),
                                        }}
                                        options={timeOptions}
                                        isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                        value={schedule.timeSlots[0]?.endTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.endTime) : null}
                                        onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'endTime', selectedOption.value)}
                                        placeholder="00:00"
                                      />
                                    </Flex>
                                    <Flex flexBasis="10%" justifyContent="center">
                                      <IconButton
                                        icon={<AddIcon />}
                                        onClick={() => handleAddTimeSlot(index)}
                                        aria-label="Add time slot"
                                        colorScheme="orange"
                                      />
                                    </Flex>
                                  </Stack>

                                  {/* Render additional time slots */}
                                  <Flex direction="column" mt={2}>
                                    {schedule.timeSlots.slice(1).map((slot, slotIndex) => (
                                      <Flex key={slotIndex} gap={2} alignItems="center" mb={2}>
                                        <Flex flexBasis="20%">
                                          {/* Keeping the day checkbox empty to align with the first row */}
                                        </Flex>
                                        <Flex flexBasis="35%" justifyContent="center">
                                          <ReactSelect
                                            styles={{
                                              control: (base) => ({
                                                ...base,
                                                width: '200px',
                                                fontSize: "small"
                                              }),
                                              option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                                color: 'black',
                                                fontSize: "small"
                                              }),
                                              indicatorSeparator: () => ({
                                                display: 'none',
                                              }),
                                            }}
                                            options={timeOptions}
                                            isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                            value={slot.startTime ? timeOptions.find(option => option.value === slot.startTime) : null}
                                            onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'startTime', selectedOption.value)}
                                            placeholder="00:00"
                                          />
                                        </Flex>
                                        <Text fontSize="sm">to</Text>
                                        <Flex flexBasis="35%" justifyContent="center">
                                          <ReactSelect
                                            styles={{
                                              control: (base) => ({
                                                ...base,
                                                width: '200px',
                                                fontSize: "small"
                                              }),
                                              option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isSelected ? '#E0EDF7' : state.isFocused ? '#F2F2F2' : 'white',
                                                color: 'black',
                                                fontSize: "small",
                                              }),
                                              indicatorSeparator: () => ({
                                                display: 'none',
                                              }),
                                            }}
                                            options={timeOptions}
                                            isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                            value={slot.endTime ? timeOptions.find(option => option.value === slot.endTime) : null}
                                            onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'endTime', selectedOption.value)}
                                            placeholder="00:00"
                                          />
                                        </Flex>
                                        <Flex flexBasis="10%" justifyContent="center">
                                          <IconButton
                                            icon={<CloseIcon />}
                                            onClick={() => handleRemoveTimeSlot(index, slotIndex + 1)}
                                            aria-label="Remove time slot"
                                            colorScheme="red"
                                          />
                                        </Flex>
                                      </Flex>
                                    ))}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                        </Flex>
                      </Stack>
                    </RadioGroup>
                    {updateRuleFormik.touched.schedule && updateRuleFormik.errors.schedule && (
                      <Text color="red.500" fontSize="sm">{updateRuleFormik.errors.schedule}</Text>
                    )}
                  </Flex>

                  {/* Notification */}
                  <Flex direction="column" gap={2}>
                    <Flex alignItems={'center'} gap={1}>
                      <Text fontWeight="bold">Notification</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>This is how you'll be notified that rule conditions have been met.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>
                    <Stack spacing={2}>
                      <Flex gap={2}>
                        <Checkbox
                          colorScheme={"orange"}
                          name="notification"
                          isChecked={updateRuleFormik.values.notification}
                          onChange={updateRuleFormik.handleChange}
                          isDisabled
                        />
                        <Flex direction="column" >
                          <Text fontSize="14px" fontWeight="bold">On Facebook</Text>
                          <Text fontSize="12px">You'll receive a notification when conditions for this rule have been met.</Text>
                        </Flex>
                      </Flex>
                    </Stack>
                  </Flex>

                  {/* Subscriber */}
                  <Flex direction="column" gap={1} mb={2}>
                    <Flex alignItems={'center'} gap={1}>
                      <Text fontWeight="bold">Subscriber</Text>
                      <Tooltip
                        borderRadius={5}
                        label={
                          <Box fontSize="sm" p="5px">
                            <Text>Only people with access to this ad account can receive rule results.</Text>
                          </Box>
                        }
                        fontSize="md"
                      >
                        <InfoOutlineIcon pointerEvents="all" />
                      </Tooltip>
                    </Flex>
                    <Text>{adAccounts?.clientId?.name}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter css={{ boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)' }} p={'12px'}>
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <Flex gap={2} align="center">
                <>
                  <Button size={'sm'} onClick={handleClose}>
                    Cancel
                  </Button>

                  <Button type="submit" size={'sm'} colorScheme="orange"
                    isDisabled={!updateRuleFormik.dirty || !updateRuleFormik.isValid || updateRuleFormik.isSubmitting}
                    onClick={() => {
                      onClose();
                    }}>
                    Save
                  </Button>
                </>
              </Flex>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default UpdateRuleForm;
