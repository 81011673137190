import { Box, Flex, useToast } from '@chakra-ui/react';
import SidebarWithHeader from 'components/SidebarWithHeader';
import AdForm from 'components/campaignForms/AdForm';
import AdSetForm from 'components/campaignForms/AdSetForms/AdSetForm';
import Footer from 'components/campaignForms/layouts/Footer';
import Header from 'components/campaignForms/layouts/Header';
import Sidebar from 'components/campaignForms/layouts/Sidebar';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetAdspressoAdDataByCampaignIdQuery, useGetAdspressoCreativeByCampaignIdQuery } from 'store/adspresso/adspressoAdApi';
import { useGetAdspressoCampaignByIdQuery, usePublishAdspressoCampaignMutation } from 'store/adspresso/adspressoCampaignApi';

function AdspressoCampaignPageForClient() {
    const toast = useToast();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const adId = searchParams.get('adId');
    const [step, setStep] = useState(0);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [adsetsData, setAdsetsData] = useState(null);

    const { data: campaignData, isLoading: isLoadingCampaign } = useGetAdspressoCampaignByIdQuery(id);

    const { data: AdspressoCreatives } = useGetAdspressoCreativeByCampaignIdQuery(id, { skip: step !== 1 });
    const { data: adData } = useGetAdspressoAdDataByCampaignIdQuery(id, { skip: step !== 1 });

    const [publishAdspressoCampaign, { isSuccess, isError }] = usePublishAdspressoCampaignMutation();

    useEffect(() => {
        if (adId) {
            setStep(1);
            navigate(`/adspresso-campaigns/${id}/?adId=${adId}`);
        }
    }, [adId]);

    useEffect(() => {
        if (step === 1) {
            handleRefresh();
        }
    }, [step]);

    const handleRefresh = useCallback(() => {
        setShouldRefresh(true);
    }, []);

    const handleRefreshComplete = () => {
        setShouldRefresh(false);
    };

    useEffect(() => {
        if (campaignData?.tree && Object.keys(campaignData?.tree)) {
            navigate(`/adspresso-campaigns/`);
        }
    }, [campaignData]);

    const handleAdsetsData = (data) => {
        setAdsetsData(data);
    };

    const forms = [
        <AdSetForm key="adset" campaignData={campaignData} onAdsetsData={handleAdsetsData} />,
        <AdForm key="ad" campaignData={campaignData} onRefresh={shouldRefresh} onRefreshComplete={handleRefreshComplete} />,
    ];

    const handleNext = () => {
        if (step < forms.length - 1) setStep(step + 1);
    };

    const handleBack = () => {
        if (step > 0) setStep(step - 1);
    };

    const handlePublish = async () => {
        await publishAdspressoCampaign(id)
            .unwrap()
            .then((response) => {
                setTimeout(() => {
                    navigate(`/publish-tracking/${id}`);
                }, 1000);
            })
    };

    useEffect(() => {
        if (isSuccess) {
            toast({
                position: "top-right",
                title: "Campaign published",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            toast({
                position: "top-right",
                title: "Error publishing campaign",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [isError]);




    return (
        <SidebarWithHeader noPadding={true}>
            <Flex height="calc(100vh - 80px)">
                {/* Sidebar */}
                <Box width="250px">
                    <Sidebar step={step} setStep={setStep} forms={forms} isLoadingCampaign={isLoadingCampaign} campaignData={campaignData} />
                </Box>

                <Flex flexDirection="column" flex="1">
                    {/* Header */}
                    <Header step={step} setStep={setStep} campaignData={campaignData} adsetsData={adsetsData} />

                    {/* Main Content Area */}
                    <Box flex="1" overflowY="auto">
                        {forms[step]} {/* Display the form based on the current step */}
                    </Box>

                    {/* Footer */}
                    <Footer
                        campaignData={campaignData}
                        step={step}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        adsetsData={adsetsData}
                        forms={forms}
                        canPublish={AdspressoCreatives?.data?.length > 0}
                        onPublishCampaign={() => handlePublish()}
                        handleCloseForm={() => navigate('/adspresso-campaigns/')}
                    />
                </Flex>
            </Flex>
        </SidebarWithHeader>
    );
}

export default AdspressoCampaignPageForClient;
