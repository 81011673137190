import React, { useEffect, useState } from 'react';
import { Box, Select, Radio, RadioGroup, Stack, FormControl, FormLabel } from '@chakra-ui/react';

const AgeGenderSelectorForCampaigns = ({  
  onChange, 
  showAdvantageAudience = false, 
  ageRange = {} 
}) => {

    const [minAge, setMinAge] = useState(18);
    const [maxAge, setMaxAge] = useState(65);
    const [gender, setGender] = useState('all');

    // Change the minAgeOptions range depending on showAdvantageAudience
    const minAgeOptions = showAdvantageAudience
        ? Array.from({ length: 8 }, (_, i) => i + 18)  // Min Age 18-25
        : Array.from({ length: 65 - 18 + 1 }, (_, i) => i + 18);  // Min Age 18-65

    const maxAgeOptions = [...Array.from({ length: 47 }, (_, i) => i + 18), '65+']; // Adding '65+' as last option

    useEffect(() => {
        if (!showAdvantageAudience && ageRange) {
                onChange({ age_min: ageRange?.age_min, age_max: ageRange?.age_max });
                setMinAge(ageRange?.age_min);
                setMaxAge(ageRange?.age_max);
        }else if(showAdvantageAudience && ageRange?.age_min > 25 && minAge > 25){
                onChange({ age_min: 18, age_max: 65 });
                setMinAge(18);
                setMaxAge(65);
        }
    }, [ageRange, showAdvantageAudience]);

    const handleMinAgeChange = (e) => {
        const value = Number(e.target.value);
        setMinAge(value);
        onChange({ age_min: value, age_max: maxAge, gender });
    };

    const handleMaxAgeChange = (e) => {
        const value = e.target.value === '65+' ? 65 : Number(e.target.value);
        setMaxAge(value);
        onChange({ age_min: minAge, age_max: value, gender });
    };

    const handleGenderChange = (value) => {
        setGender(value);
        onChange({ age_min: minAge, age_max: maxAge, gender: value });
    };

    return (
        <Box>
            <FormControl as="fieldset" mb={5}>
                <FormLabel as="legend" fontWeight={'bold'}>Age</FormLabel>
                <Stack direction="row" spacing={4}>
                    <Box>
                        <FormLabel fontSize={'14px'}>Min Age</FormLabel>
                        <Select value={minAge} onChange={handleMinAgeChange} size={'sm'} borderRadius={'md'} bg={'#fff'}>
                            {minAgeOptions.map((age) => (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            ))}
                        </Select>
                    </Box>
                    {showAdvantageAudience ? (null) : (
                        <Box>
                            <FormLabel fontSize={'14px'}>Max Age</FormLabel>
                            <Select value={maxAge === 65 ? '65+' : maxAge} onChange={handleMaxAgeChange} size={'sm'} borderRadius={'md'} bg={'#fff'}>
                                {maxAgeOptions.map((age) => (
                                    <option key={age} value={age}>
                                        {age === 65 ? '65+' : age} 
                                    </option>
                                ))}
                            </Select>
                        </Box>
                    )}
                </Stack>
            </FormControl>

            <FormControl as="fieldset">
                <FormLabel as="legend" fontWeight={'bold'}>Gender</FormLabel>
                <RadioGroup value={gender} onChange={handleGenderChange} colorScheme='orange'>
                    <Stack direction="row" spacing={5}>
                        <Radio bg={'#fff'} value="all">All</Radio>
                        <Radio bg={'#fff'} value="men">Men</Radio>
                        <Radio bg={'#fff'} value="women">Women</Radio>
                    </Stack>
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default AgeGenderSelectorForCampaigns;
