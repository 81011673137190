import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon
} from '@chakra-ui/react';
import { MdOutlineFolderSpecial, MdWindow } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function Header({ step, setStep, campaignData, adsetsData }) {

    const navigate = useNavigate();

    const isAdLinkDisabled = adsetsData?.data.length === 0 || campaignData?.adsets?.length === 0;

    return (
        <Box width="100%" >
            {/* Header */}
            <Box
                position="sticky"
                top={0}
                zIndex={2}
                // bg="white"
                borderBottom="1px solid"
                borderColor="gray.200"
                p={'10px'}
                px={'20px'}
            >
                <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" flex={1}>
                        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.800" />}>
                            <BreadcrumbItem>
                                <BreadcrumbLink
                                    color={step === 0 ? 'orange.500' : 'gray.800'}
                                    onClick={() => {
                                        setStep(0);
                                        navigate(`/adspresso-campaigns/${campaignData?._id}`);
                                    }}
                                    _hover={{ textDecoration: 'none' }}
                                    _focus={{ boxShadow: 'none' }}
                                >
                                    <Flex align="center">
                                        <Icon as={MdWindow} mr={2} />
                                        Ad Set
                                    </Flex>
                                </BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem>
                                <BreadcrumbLink
                                    onClick={() => {
                                        if (!isAdLinkDisabled) {
                                            setStep(1);
                                            navigate(`/adspresso-campaigns/${campaignData?._id}/?adId=set`);
                                        }
                                    }}
                                    _hover={{ textDecoration: 'none' }}
                                    _focus={{ boxShadow: 'none' }}
                                    color={isAdLinkDisabled ? 'gray.500' : step === 1 ? 'orange.500' : 'gray.800'} 
                                    cursor={isAdLinkDisabled ? 'not-allowed' : 'pointer'}
                                >
                                    <Flex align="center">
                                        <Icon as={MdOutlineFolderSpecial} mr={2} />
                                        Ad
                                    </Flex>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Flex>

                    {/* Right section */}
                    {/* <Flex alignItems="center" gap={2} ml="auto">
                        <Button size="sm" leftIcon={<MdOutlineEdit />} colorScheme="orange" variant="ghost">
                            Edit
                        </Button>
                    </Flex> */}
                </Flex>
            </Box>
        </Box>
    );
}

export default Header;
