import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdatePublishAdMutation } from 'store/publishTracking/trackingApi';
import DriveModal from 'components/drive/DriveModal';
import FaceBookPageSelector from '../FaceBookPageSelector';

const CarouselCreativeAdupdate = ({ trackingData, onClose, onSaveSuccess }) => {

    const { adsAccount, form, _id } = trackingData;

    const toast = useToast();
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [selectedCreativeIndex, setSelectedCreativeIndex] = useState(null);

    const [updatePublishAd] = useUpdatePublishAdMutation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        identity: Yup.object().shape({
            page_id: Yup.string().required('Page ID is required'),
        }),
        adcreative: Yup.array().of(
            Yup.object().shape({
                //message: Yup.string().required('Message is required'),
                name: Yup.string(),
                description: Yup.string(),
                //type: Yup.string().required('Type is required'),
                link: Yup.string().url('Invalid URL').required('Link URL is required'),
            })
        )
    });

    const formik = useFormik({
        initialValues: {
            name: form?.name || "",
            message:form?.message || "",
            cpa:form?.cpa || "",
            description:form?.description || "",
            type:form?.type || "",
            link:form?.link || "",
            identity: {
                page_id: form.identity?.page_id || "",
            },
            adcreative: form.adcreative.map(creative => ({
                _id: creative._id || "",
                message: creative.message || "",
                name: creative.name || "",
                description: creative.description || "",
                type: creative.type || "",
                link: creative.link || "",
                hash: creative?.hash || "",
                image_hash: creative.image_hash || "",
                url: creative.url || "",
                video_id: creative?.video_id || "",
                thumbnail: creative?.thumbnail || "",
            })),
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {

            const payload = {
                name: values?.name,
                message:values?.message,
                cpa:values?.cpa,
                description:values?.description,
                type:values?.type,
                link:values?.link,
                identity: {
                    page_id: values.identity.page_id,
                },
                adcreative: values.adcreative.map((creative, index) => ({
                    ...creative,
                    ...(selectedMedia === 'video' && {
                        video_id: creative?.video_id,
                        thumbnail: creative?.thumbnail,
                        hash:creative?.hash,
                    }),
                    ...(selectedMedia === 'image' && {
                        image_hash: creative?.image_hash,
                        url: creative?.url,
                        hash:creative?.hash,
                    }),
                })),
            };

            updatePublishAd({ trackingId: _id, payload })
                .unwrap()
                .then(response => {
                    onSaveSuccess();
                    toast({
                        position: "top-right",
                        title: "Ads updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ads",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = (mediaType, index) => {
        setSelectedMedia(mediaType);
        setSelectedCreativeIndex(index); // Set the index of the creative being edited
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file?.length > 0 && selectedCreativeIndex !== null) {
            const selectedFile = file[0];
            formik.setFieldValue('adcreative', formik.values?.adcreative?.map((creative, index) => {
                if (index === selectedCreativeIndex) {
                    return {
                        ...creative,
                        ...(selectedMedia === 'image' && {
                            image_hash: selectedFile?.creativeId,
                            hash:selectedFile?.hash,
                            url: selectedFile?.url,
                        }),
                        ...(selectedMedia === 'video' && {
                            video_id: selectedFile?.creativeId,
                            hash:selectedFile?.hash,
                            thumbnail: selectedFile?.picture,
                        }),
                    };
                }
                return creative;
            }));
        }
        setIsDriveModalOpen(false);
        setSelectedCreativeIndex(null);
    };


    if (!form) {
        return <Spinner mt={'20px'} />;
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex direction={'column'} borderRadius="md" bg="gray.50" p={4} gap={4}>
                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Ad Name</Text>
                    <Input
                        name="name"
                        placeholder="Enter ad name"
                        size='sm'
                        borderRadius="md"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Facebook Page ID</Text>
                    <FaceBookPageSelector
                        adsAccount={adsAccount}
                        selectedPageId={formik.values.identity.page_id}
                        onSelectedPage={(pageId) => {
                            formik.setFieldValue('identity.page_id', pageId);
                        }}
                    />
                    {formik.errors.identity?.page_id && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.identity?.page_id}</Text>
                    )}
                </Flex>

                {formik.values.adcreative.map((creative, index) => (
                    <Flex direction={'column'} gap={'20px'} key={index} borderWidth={1} borderRadius="md" p={4} >
                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Primary text</Text>
                            <Input
                                name={`adcreative.${index}.message`}
                                placeholder="Enter message"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative[index].message}
                            />
                            {formik.touched.adcreative?.[index]?.message && formik.errors.adcreative?.[index]?.message && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].message}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Headline</Text>
                            <Input
                                name={`adcreative.${index}.name`}
                                placeholder="Enter name"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative[index].name}
                            />
                            {formik.touched.adcreative?.[index]?.name && formik.errors.adcreative?.[index]?.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].name}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Description</Text>
                            <Input
                                name={`adcreative.${index}.description`}
                                placeholder="Enter description"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative[index].description}
                            />
                            {formik.touched.adcreative?.[index]?.description && formik.errors.adcreative?.[index]?.description && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].description}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Call to action</Text>
                            <Input
                                name={`adcreative.${index}.type`}
                                placeholder="Enter type"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative[index].type}
                            />
                            {formik.touched.adcreative?.[index]?.type && formik.errors.adcreative?.[index]?.type && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].type}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Website URL</Text>
                            <Input
                                name={`adcreative.${index}.link`}
                                placeholder="Enter link URL"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adcreative[index].link}
                            />
                            {formik.touched.adcreative?.[index]?.link && formik.errors.adcreative?.[index]?.link && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.adcreative[index].link}</Text>
                            )}
                        </Flex>

                        <Text fontWeight="bold">Creative</Text>
                        <Flex direction="row" gap={2} alignItems={'center'}>
                            <img
                                src={creative.image_hash ? creative.url : creative.thumbnail}
                                alt="Thumbnail"
                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                            />
                            <Button
                                bg={'gray.50'}
                                size="xs"
                                borderWidth={1}
                                onClick={() => handleOpenDriveModal(creative.image_hash ? 'image' : 'video', index)}>
                                {creative.image_hash ? "Change Image" : "Change Video"}
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
            <Flex mt={4}>
                <Button
                    size="sm"
                    colorScheme="orange"
                    isLoading={formik.isSubmitting}
                    type="submit"
                    isDisabled={!formik.isValid}
                >
                    Save
                </Button>
            </Flex>

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccount._id,
                    adsAccountId: adsAccount.adsAccountId,
                    adsAccountName: adsAccount.adsAccountName,
                }]}
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect}
                uploadPurpose="update"
                mediaType={selectedMedia}
            />
        </form>
    );
};

export default CarouselCreativeAdupdate;
