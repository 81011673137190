import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
  useDisclosure,
  Badge,
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import { useEffect, useMemo, useState } from 'react';
import { useGetAdspressoCampaignsQuery } from 'store/adspresso/adspressoCampaignApi';
import { Link } from 'react-router-dom';
import TreeView from 'components/adspresso/TreeView';
import moment from 'moment';

function AdspressoTable() {

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState('');
  const [activeCampaign, setactiveCampaign] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    data: adspressoCampaigns,
    isLoading,
    isFetching
  } = useGetAdspressoCampaignsQuery({ currentPage, searchValue });

  useEffect(() => {
    if (searchValue != '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <>
             {original.tree && Object.keys(original.tree).length > 0 ? (
            <Text>{original.name}</Text>
            ) : (
              original.status === 'DRAFT' ? (
              <Link 
                to={`/adspresso-campaigns/${original._id}`} 
                style={{ textDecoration: 'underline' }}
              >
                {original.name}
              </Link>
            ) : (
              <Link 
                to={`/publish-tracking/${original._id}`} 
                style={{ textDecoration: 'underline' }}
              >
                {original.name}
              </Link>
            )
          )}
          </>
        ),
      },
      {
        Header: "Ad Account ID",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Box>
              {original?.adsAccounts.map((account, index) => (
                <Text key={index} fontSize={'12px'}>{account.adsAccountId}</Text>
              ))}
            </Box>
          )
        }
      },
      {
        Header: "Ad Account Name",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Box>
              {original?.adsAccounts.map((account, index) => (
                <Text key={index} fontSize={'12px'}>{account.adsAccountName}</Text>
              ))}
            </Box>
          )
        }
      },
      {
        Header: "Objective",
        accessor: "objective",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          let objective = '';

          if (original.objective == 'OUTCOME_SALES') {
            objective = 'Sales'
          } else if (original.objective == 'OUTCOME_LEADS') {
            objective = 'Leads'
          }

          return (
            <Text>
              {objective}
            </Text>
          )
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          let badgeColor = 'gray';
          if (original?.status === 'PENDING') {
            badgeColor = 'yellow';
          } else if (original?.status === 'DRAFT') {
            badgeColor = 'green';
          }
      
          return (
            <>
              <Badge
                fontSize={"10px"}
                marginRight={1}
                colorScheme={badgeColor}
              >
                {original?.status}
              </Badge>
            </>
          );
        },
      },
      
      {
        Header: "Created Date",
        accessor: "createdDate",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>

        ),
      },
    ],
    []
  );

  return (
    <>

      <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Campaign tree</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TreeView tree={activeCampaign?.tree} />
          </ModalBody>
          <ModalFooter>
            <Button size={'xs'} mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isLoading ? (
        <Spinner mt="20px" />
      ) : (
        <AppTableWithPagination
          columns={columns}
          data={adspressoCampaigns?.data || []}
          paginationMeta={adspressoCampaigns?.meta || {}}
          onPageChange={(index) => setCurrentPage(index)}
          onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
          searchEnabled={true}
          isFetching={isFetching}
        // initialState={initialTableState}
        />
      )}
    </>
  )
}


export default AdspressoTable