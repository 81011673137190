import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SidebarWithHeader from "components/SidebarWithHeader";
import {
  Flex,
  Box,
  Text,
  Button,
  Stack,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { useGetcampaignFormQuery } from "store/campaigns/campaignsApi";
import { useSelector } from "react-redux";
import { campaignFormSchema } from "utils/schema";
import AdspressoCampaignForm from "components/campaignForms/AdspressoCampaignForm";
import AdspressoTable from "components/campaignTables/AdspressoTable";
import { useCreateAdspressoCampaignMutation } from "store/adspresso/adspressoCampaignApi";

function AdspressoForAdmin() {

  const toast = useToast();
  const navigate = useNavigate();
  const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);
  const {
    data: dataCampaign,
    error: errorCampaign,
    isLoading: isLoadingCampaign,
  } = useGetcampaignFormQuery();
  const [createCampaignData, { isLoading: isCreating, error: createError }] =
    useCreateAdspressoCampaignMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    campaignFormik.resetForm();
    setIsModalOpen(false);
  };

  const campaignFormik = useFormik({
    initialValues: {
      adsAccounts: [],
      name: "",
      objective: "OUTCOME_LEADS",
      status: "PAUSED",
    },
    validationSchema: campaignFormSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name, objective, status, adsAccounts } = values;
      const modifiedAdsAccounts = adsAccounts.map(
        ({ currency, ...rest }) => rest
      );
      const payload = {
        name,
        objective,
        status,
        adsAccounts: modifiedAdsAccounts,
      };

      createCampaignData({ payload })
        .unwrap()
        .then((response) => {
          toast({
            position: "top-right",
            title: "Campaign created",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          //console.log('Campaign created successfully:', response);
          resetForm();
          handleCloseModal();
          navigate(`/adspresso-campaigns/${response.data._id}`);
        })
        .catch((error) => {
          toast({
            position: "top-right",
            title: "Error in creating",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          // console.error('Failed to create campaign:', error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleAdsAccountChange = (selectedIds) => {
    const selectedAccounts = activeAccountsData?.data
      .filter((account) => selectedIds.includes(account.adsAccountId))
      .map((account) => ({
        adsAccountName: account.adsAccountName,
        adsAccountId: account.adsAccountId,
        id: account._id,
        businessName:account?.businessAccount?.businessName,
        businessId:account?.businessAccount?.businessId,
        currency: account.currency,
      }));

    // Check if all selected accounts have the same currency
    const currencies = selectedAccounts.map((account) => account.currency);
    const uniqueCurrencies = [...new Set(currencies)];

    if (uniqueCurrencies.length > 1) {
      // Show error if currencies don't match
      campaignFormik.setFieldError(
        "adsAccounts",
        "Selected Ad Accounts have different currencies."
      );
    } else {
      campaignFormik.setFieldValue("adsAccounts", selectedAccounts);
    }
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent maxWidth="500px">
          <ModalHeader>Create new campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="400px" overflowY="auto">
            {/* Ad Accounts */}
            <Flex direction="column" mb={"20px"}>
              <Text mb={2} fontWeight={"bold"}>
                Choose Ad Accounts
              </Text>
              <CheckboxGroup
                colorScheme={"orange"}
                id="adsAccount"
                value={campaignFormik.values.adsAccounts.map(
                  (account) => account.adsAccountId
                )}
                onChange={handleAdsAccountChange}
              >
                <Stack spacing={2}>
                  {activeAccountsData?.data?.map((account) => (
                    <Checkbox key={account._id} value={account.adsAccountId}>
                      <Text fontSize="14px" fontWeight="bold">
                        {account.adsAccountName}
                      </Text>
                      <Flex gap={"5px"}>
                        <Text fontSize="12px" color="gray.500">
                          {account.adsAccountId}
                        </Text>
                        <Text
                          fontSize="12px"
                          color="gray.500"
                          fontWeight={"bold"}
                        >
                          ({account.currency})
                        </Text>
                      </Flex>
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
              {campaignFormik.errors.adsAccounts && (
                <Text fontSize={"12px"} color="red.500" mt={"5px"}>
                  {campaignFormik.errors.adsAccounts}
                </Text>
              )}
            </Flex>
            <AdspressoCampaignForm
              values={campaignFormik.values}
              handleChange={campaignFormik.handleChange}
              handleBlur={campaignFormik.handleBlur}
              errors={campaignFormik.errors}
              touched={campaignFormik.touched}
              dataCampaign={dataCampaign}
              isLoadingCampaign={isLoadingCampaign}
              errorCampaign={errorCampaign}
              isSubmitting={campaignFormik.isSubmitting || isCreating}
              handleSubmit={campaignFormik.handleSubmit}
              createError={createError}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <SidebarWithHeader>
        <>
          <Button colorScheme="orange" onClick={handleOpenModal}>
            <AddIcon boxSize={3} />
            &nbsp;Create
          </Button>

          <Box py={"20px"}>
            <AdspressoTable />
          </Box>
        </>
      </SidebarWithHeader>
    </>
  );
}

export default AdspressoForAdmin;
