import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, CheckboxGroup, Divider, Flex, Icon, Input, Select, Text, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { MdDelete } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import { useCreateAdspressoCreativeMutation, useGetAdspressoCreativeByCampaignIdQuery, useRemoveCreativeMutation, useUpdateAdspressoAdCreativeByIdMutation, useRemoveAdMutation } from 'store/adspresso/adspressoAdApi';
import { object, string } from "yup";
import SingleMediaUpdateForm from './creativeUpdateForms/SingleMediaUpdateForm';
import DeleteConfirmationModal from 'components/genericForms/DeleteConfirmationModal';


function SingleMediaCreative({ adsAccounts, adFormik, adsetsListData, campaignId, call_to_action_options, adData }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [uploadPurpose, setuploadPurpose] = useState(null);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedAdSets, setSelectedAdSets] = useState({});
  const [mediaFiles, setMediaFiles] = useState([]);
  const [fileBeingEdited, setFileBeingEdited] = useState(null);
  const [hasSameDestinationType, sethasSameDestinationType] = useState(true);
  const [activeDestinationType, setactiveDestinationType] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const [createAdspressoAds, { isLoading: isAdsCreating, error: createError }] = useCreateAdspressoCreativeMutation();
  const { data: AdspressoCreatives } = useGetAdspressoCreativeByCampaignIdQuery(campaignId);
  const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
  const [removeCreative, removeCreativeResponse] = useRemoveCreativeMutation();
  const [removeAd, removeAdResponse] = useRemoveAdMutation();

  const handleMediaTypeChange = (adAccounts, type) => {
    setSelectedAdAccounts(adAccounts);
    setMediaType(type);
    setIsDriveOpen(true);
  }

  const handleAdAccountChange = (selectedValues) => {
    const selectedAccounts = adsAccounts.filter(account => selectedValues.includes(account.adsAccountId));
    setSelectedAdAccounts(selectedAccounts);
  };

  const handleAdSetChange = (selectedValues) => {

    setSelectedAdSets(prev => {
      const updated = {};
      selectedAdAccounts.forEach(account => {
        updated[account.adsAccountId] = selectedValues;
      });
      return updated;
    });
  };

  const handleFileSelect = (files) => {

    if (files.length > 0) {
      if (fileBeingEdited) {
        // Handle update scenario
        setMediaFiles(files);
        const selectedFile = files[0];

        const payload = {
          [selectedFile?.creativeType === 'video' ? 'video_id' : 'image_hash']: selectedFile?.creativeId,
          ...(selectedFile?.creativeType === 'image' ? { url: selectedFile?.url } : { thumbnail: selectedFile?.picture }),
          hash: selectedFile?.hash,
        };


        updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
          .then(response => {
            //console.log('Ad creative updated successfully:', response);
          })
          .catch(error => {
            toast({
              position: "top-right",
              title: "Error updating image",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      } else {
        // Handle creation scenario
        setMediaFiles(prevFiles => [...prevFiles, ...files])
      }
      setFileBeingEdited(null);
    }
  };

  const handleChangeMedia = (file, adAccount) => {
    const mediaType = file?.image_hash ? 'image' : 'video';
    setuploadPurpose('update')
    handleMediaTypeChange([adAccount], mediaType);
    setSelectedAdAccounts([adAccount]);
    setFileBeingEdited(file);
    setSelectedCreative(file)
  };

  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };


  let schema = object().shape({
    destinationType: string(),
    name: string().
      max(300, 'select atleast one type'),
    description: string().
      max(300, 'select atleast one type'),
    type: string()
      .required('Type is required'),
    message: string().required('Text required'),
    link: string()
      .url('Please add a valid URL')
    .when('destinationType', {
      is: 'WEBSITE',
      then: () => string().required('Website URL is required'),
      otherwise: () => string().notRequired(),
    }),
    
    phoneNumber: string().
      when('destinationType', {
        is: 'PHONE_CALL',
        then: () => string().required('Phone number is required'),
        otherwise: () => string().notRequired(),
      })
      .min(10, 'Phone number is too short')
      .max(15, 'Phone number is too long'),
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      destinationType: activeDestinationType,
      message: '',
      name: '',
      description: '',
      type: 'SHOP_NOW',
      applyForAll: false,
      message: '',
      mediaFiles: [],
      link: '',
      phoneNumber: ''
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      //console.log("values are",values);

      const { name, message, description, type, link, phoneNumber } = values;

      const adcreatives = mediaFiles?.map(file => {

        let videoId = file?.creativeType == 'video' ? { video_id: file?.creativeId } : null;
        let videoURL = file?.creativeType == 'video' ? { url: file?.source } : null;
        let imageHash = file?.creativeType == 'image' ? { image_hash: file?.creativeId } : null;
        let imageURL = file?.creativeType == 'image' ? { url: file?.url } : null;
        let videoThumbnail = file?.creativeType == 'video' ? { thumbnail: file?.picture } : null;

        return (
          {
            adsAccountId: file?.adsAccount?.adsAccountId,
            message: "",
            name: "",
            description: "",
            type: "",
            link: "",
            hash: file?.hash,
            ...videoId,
            ...videoURL,
            ...imageHash,
            ...imageURL,
            ...videoThumbnail,
          }
        )
      });

      const adsAccounts = selectedAdAccounts.map(account => ({
        id: account.id,
        adsAccountId: account.adsAccountId,
        adsAccountName: account.adsAccountName,
        businessName:account?.businessName,
        businessId:account?.businessId,
      }));

      const adsets = adsAccounts.flatMap(account => selectedAdSets[account.adsAccountId] || []);
      const uniqueAdsets = adsets.filter((item, index) => adsets.indexOf(item) === index);

      const payload = {
        adType: adFormik.values.format,
        campaign: campaignId,
        adsets: uniqueAdsets,
        message: message,
        name: name,
        description: description,
        type: phoneNumber?.length ? 'CALL_NOW' : type,
        link: phoneNumber?.length ? `tel:+${phoneNumber}` : link,
        adcreatives: adcreatives,
        adsAccounts: adsAccounts,
      };

      // console.log({ payload });

      try {
        if (uploadPurpose === 'create') {

          createAdspressoAds(payload).unwrap()
            .then(response => {
              toast({
                position: "top-right",
                title: "Ad creative added",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              // console.log('Ads creative created successfully:', response);
            })
            .catch(error => {
              const errorMessage = error?.message || "An unexpected error occurred";

              toast({
                position: "top-right",
                title: "Error creating Ad creative",
                description: errorMessage,
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            });
        }
      } catch {
      } finally {
        setSelectedCreative(null);
        setuploadPurpose(null);
        setMediaType(null);
        setMediaFiles([]);
        setSelectedAdAccounts([]);
        setSelectedAdSets({});
        resetForm();
        setactiveDestinationType('');
      }

    },
  });

  // console.log(singleMediaFormik.values);
  // console.log(singleMediaFormik.errors);


  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const handleDeleteCreative = (creativeId) => {

    removeCreative(creativeId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad creative deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad creative",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
        //console.error('Failed to delete Ad creative:', error);
      });
  };

  const handleDeleteAd = (adId) => {
    removeAd(adId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const confirmDelete = () => {
    if (selectedAd) {
      handleDeleteAd(selectedAd);
      setModalOpen(false);
      setSelectedAd(null);
    }
  };

 
  // const uploadedCreativesList = AdspressoCreatives?.data
  //   ?.filter(creative => creative.adType === 'SINGLE' && creative?.adcreatives?.length > 0)
  //   ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
  //   ?.map((creative, index) => {

  //     return (
  //       <Box key={creative?._id + index} p={'10px'} borderWidth={1} borderRadius="md" borderColor="gray.200">
  //         <Flex justifyContent={'space-between'}>
  //           <Text fontSize={'14px'} fontWeight={'bold'}>Ad Account: {creative?.adsAccount?.adsAccountName}</Text>
  //           <Tooltip label="Delete" fontSize="xs">
  //             <Box>
  //               <Icon
  //                 color={'red.500'}
  //                 _hover={{ color: 'gray.400' }}
  //                 as={MdDelete}
  //                 cursor="pointer"
  //                 boxSize={5}
  //                 onClick={() => {
  //                   setSelectedAd(creative?._id);
  //                   setModalOpen(true);
  //                 }}
  //               />
  //             </Box>
  //           </Tooltip>
  //         </Flex>
  //         <Flex align={'flex-start'} direction={'column'}>
  //           <Text fontSize={'14px'}>Adsets:</Text>
  //           <Flex direction={'column'} ml={'5px'}>
  //             {creative?.adsets?.map((adset, adsetIndex) => (
  //               <Text key={adsetIndex + adset._id} fontSize={'12px'}>{adset.name}</Text>
  //             ))}
  //           </Flex>
  //         </Flex>

  //         {/* Small Image Preview */}
  //         <Flex wrap="wrap" gap={2} direction="column" mt={'10px'}>
  //           {creative?.adcreatives?.map((file, fileIndex) => {
  //             return (
  //               <Box key={file?.url + fileIndex}>
  //                 <SingleMediaUpdateForm
  //                   adData={adData}
  //                   adAccount={creative?.adsAccount}
  //                   creative={file}
  //                   onDelete={handleDeleteCreative}
  //                   onChangeImage={handleChangeMedia}
  //                 // onRemoveImage={handleRemoveMedia}
  //                 />
  //               </Box>
  //             );
  //           })}
  //         </Flex>
  //       </Box>
  //     );
  //   });


  function checkSameDestinationType(selectedIds, adsets) {
    const filteredAdsets = adsets.filter(adset => selectedIds.includes(adset._id));

    if (filteredAdsets.length === 0) {
      return true;
    }

    // Get the destination_type of the first filtered adset
    const firstDestinationType = filteredAdsets[0].destination_type;

    // Loop through the filtered adsets and check if all have the same destination_type
    for (let i = 1; i < filteredAdsets.length; i++) {
      if (filteredAdsets[i].destination_type !== firstDestinationType) {
        return false;
      }
    }

    setactiveDestinationType(firstDestinationType);


    return true;
  }

  useEffect(() => {
    // console.log(checkSameDestinationType(Object.values(selectedAdSets).flat(), adsetsListData?.data))
    if (Object.values(selectedAdSets)?.flat()?.length > 1) {
      const checkresult = checkSameDestinationType(Object.values(selectedAdSets).flat(), adsetsListData?.data);
      sethasSameDestinationType(checkresult)
    } else if (Object.values(selectedAdSets)?.flat()?.length == 1) {
      //console.log(String(Object.values(selectedAdSets)?.flat()?.[0]));
      let activeSingleAdset = adsetsListData?.data?.filter((adset) => adset._id == String(Object.values(selectedAdSets)?.flat()?.[0]));
      // console.log(activeSingleAdset);
      setactiveDestinationType(activeSingleAdset?.[0]?.destination_type)
      sethasSameDestinationType(true)
    } else {
      setactiveDestinationType('')
    }

  }, [selectedAdSets])

  //console.log(adsetsListData?.data);


  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
          setFileBeingEdited(null);
        }}
        mediaFormat={adFormik?.values?.format}
        onSubmit={handleFileSelect}
        selectedAdAccounts={selectedAdAccounts}
        uploadPurpose={uploadPurpose}
      />

      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={confirmDelete}
        message="Do you wish to proceed with deleting this Ad?"
      />

      <Flex flex="3" direction="column" gap={2}>
        <Flex direction="column" >
          {/* Ad Account Select */}
          <Flex direction={'column'} gap={'10px'} mb={'10px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad accounts</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={selectedAdAccounts.map(account => account.adsAccountId)}
              onChange={handleAdAccountChange}
            >
              <Flex
                flexWrap={'wrap'}
                gap="10px"
              >
                {adsAccounts?.map(account => (
                  <Box key={account.adsAccountId} px={'5px'} py={'2px'} width={'calc(50% - 5px)'}>
                    <Checkbox value={account.adsAccountId}>
                      <Text maxWidth="150px" fontSize={'14px'}>
                        {account.adsAccountName.length > 20
                          ? `${account.adsAccountName.substring(0, 20)}...`
                          : account.adsAccountName}
                      </Text>
                    </Checkbox>
                  </Box>
                ))}
              </Flex>
            </CheckboxGroup>
          </Flex>


          {/* Ad Set Select */}
          <Flex direction="column" gap={'5px'} mb={'20px'}>
            <Text fontSize={'14px'} fontWeight={'bold'}>Select Ad sets</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={Array.from(new Set(Object.values(selectedAdSets).flat()))}
              onChange={(e) => {
                handleAdSetChange(e);

              }}
              isDisabled={!selectedAdAccounts.length > 0}
            >
              <Flex
                flexWrap={'wrap'}
                gap={'10px'}
              >
                {adsetsListData?.data?.map((adSet, index) => (
                  <Box key={`${adSet._id}-${index}`} px={'5px'} py={'2px'} width={'calc(50% - 5px)'}>
                    <Checkbox value={adSet._id} display={'flex'} flex={1}>
                      <Text fontSize={'14px'}>{adSet?.name}</Text>
                    </Checkbox>
                  </Box>
                ))}
              </Flex>
            </CheckboxGroup>

            {!hasSameDestinationType ? (
              <Flex>
                <Text color="red.500" fontSize={'12px'}>Conversion goals don't match &nbsp;
                </Text>
                <Tooltip
                  borderRadius={5}
                  label={
                    <Box fontSize="14px" p="5px">
                      <Text>
                        The selected Ad sets have different conversion goals. Please select Ad sets with the same goal (either Website or Calls). You can create a separate set of Ads for the other conversion goal thereafter.
                      </Text>
                    </Box>
                  }
                  fontSize="md"
                >
                  <InfoOutlineIcon pointerEvents="all" color={'red.500'} boxSize={4} />
                </Tooltip>
              </Flex>
            ) : null}

          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0} mt={'10px'}>
            <Select
              placeholder={singleMediaFormik?.values?.format === 'CAROUSEL' ? "Add cards" : "Add media"}
              width={'150px'}
              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                setuploadPurpose('create');
                handleMediaTypeChange(selectedAdAccounts, e.target.value);
              }}
              size="sm"
              mb={2}
              isDisabled={Object.keys(selectedAdSets)?.length === 0 || !selectedAdAccounts?.length > 0 || !hasSameDestinationType}
            >
              <option value="image">{singleMediaFormik?.values?.format === 'CAROUSEL' ? "Add image cards" : "Add image"}</option>
              <option value="video">{singleMediaFormik?.values?.format === 'CAROUSEL' ? "Add video cards" : "Add video"}</option>
            </Select>
          </Flex>

          {/*  Media Previews */}
          {mediaFiles?.length > 0 && (
            <Box mt={'10px'}>
              <Flex wrap="wrap" gap={'10px'}>
                {mediaFiles?.map((file, index) => {
                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200">
                      <Box width={'80px'}>
                        {file?.creativeType === 'image' ? (
                          <img src={file?.url} alt={file?.name} style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }} />
                        ) : (
                          <video src={file?.source} style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }} />
                        )}
                        <Text fontSize="10px" noOfLines={1}>{truncateName(file?.name)}</Text>
                        <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                      </Box>
                      <Flex mt={'5px'} gap={'5px'} align={'center'} justify={'flex-end'}>
                        <Tooltip label="Delete" fontSize="xs">
                          <Box height={'20px'}>
                            <Icon
                              color={'red.500'}
                              _hover={{ color: 'gray.400' }}
                              as={MdDelete}
                              cursor="pointer"
                              fontSize={'18px'}
                              onClick={() => handleDeleteFile(index)}
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Box>
                  )
                })}
              </Flex>
            </Box>
          )}
        </Flex>

        <Divider mt={'10px'} />

        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Primary text</Text>
            <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md">
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            size={'sm'}
            placeholder="Tell people what your ad is about..."

            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius={'md'}
          />

          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Headline</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    Add up to five brief headlines to let people know what your ad is about. Each
                    headline can have a maximum of 255 characters. Headlines won't appear in all
                    placements.
                  </Text>
                </Box>
              }
              fontSize="md">
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            size={'sm'}
            placeholder="Tell people what your ad is about..."

            borderRadius={'md'}
            name="name"
            value={singleMediaFormik.values.name}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
          />

          {singleMediaFormik.touched.name && singleMediaFormik.errors.name ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.name}</Text>
          ) : null}
        </Box>

        <Box direction="column" mb={'10px'}>
          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Description</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    Add up to five link descriptions to emphasise why people should visit your
                    website. This won't appear in all placements.
                  </Text>
                </Box>
              }
              fontSize="md">
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Textarea
            size={'sm'}
            placeholder="Include additional details"

            borderRadius={'md'}
            name="description"
            value={singleMediaFormik.values.description}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            rows={3}
          />
          {singleMediaFormik.touched.description && singleMediaFormik.errors.description ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.description}</Text>
          ) : null}
        </Box>

        {activeDestinationType != 'PHONE_CALL' ? (
          <Box direction="column">

            <Flex alignItems="center" mb={2} gap={2}>
              <Text fontWeight="bold" fontSize={'14px'}>Call to action</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Select
              name="type"
              borderRadius={'md'}
              value={singleMediaFormik.values.type}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size={'sm'}
            >
              {call_to_action_options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
              <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.type}</Text>
            ) : null}
          </Box>
        ) : null}


        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        {activeDestinationType == 'WEBSITE' ? (
          <>
            <Flex alignItems="center" mt={'10px'}>
              <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
              <Text display="inline-flex" fontSize="18px" color="red.400" mr={2}>*</Text>
              <Tooltip
                borderRadius={5}
                label={
                  <Box fontSize="sm" p="5px">
                    <Text>Enter the URL for the webpage you want people to visit</Text>
                  </Box>
                }
                fontSize="md"
              >
                <InfoOutlineIcon pointerEvents="all" />
              </Tooltip>
            </Flex>
            <Input
              type="url"
              name="link"
              bg={'#fff'}
              borderRadius={'md'}
              placeholder="Enter URL"
              value={singleMediaFormik.values.link}
              onChange={singleMediaFormik.handleChange}
              onBlur={singleMediaFormik.handleBlur}
              size={'sm'}
            />
            {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
              <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
            ) : null}
          </>
        ) : null}

        {activeDestinationType == 'PHONE_CALL' ? (
          <Box >
            <Text fontWeight="bold" fontSize={'14px'} mb={'10px'}>Call now</Text>
            <PhoneInput
              country={'in'}
              value={singleMediaFormik.values.phoneNumber}
              onChange={(value) => {
                singleMediaFormik.setFieldValue('phoneNumber', value);
              }}
              name='phoneNumber'
              inputProps={{
                name: 'phoneNumber',
                required: true,
                //autoFocus: true,
              }}
              enableSearch={true}
              searchPlaceholder="Search"
            />
            {singleMediaFormik.touched.phoneNumber && singleMediaFormik.errors.phoneNumber ? (
              <Text color="red.500" fontSize={'12px'} mt={'5px'}>{singleMediaFormik.errors.phoneNumber}</Text>
            ) : null}
          </Box>
        ) : null}

        {/* Save Button */}
        <Flex justifyContent="flex-start" mt={'10px'}>
          <Button
            colorScheme="orange"
            onClick={() => singleMediaFormik.handleSubmit()}
            isLoading={isAdsCreating || isAdspressoAdsetUpdating}
            size={'sm'}
            isDisabled={!singleMediaFormik.values.mediaFiles.length > 0 || !hasSameDestinationType}
          >
            Save
          </Button>
        </Flex>

        {/* {uploadedCreativesList?.length > 0 ? (
          <>
            <Divider my={'10px'} />
            <Flex direction="column" gap={2} >
              {uploadedCreativesList}
            </Flex>
          </>
        ) : null} */}

      </Flex>
    </>
  );
}

export default SingleMediaCreative;
