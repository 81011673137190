import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { FiEye } from "react-icons/fi";
import { useAddNewRequestMutation, useGetRequestsQuery, useReplyToRequestMutation, useUpdateStatusMutation, useUploadFileMutation, usePrefetch } from 'store/requests/requestsApi';

import { AddIcon } from '@chakra-ui/icons';
import AppTable from 'components/AppTable';
import CircleIcon from 'components/CircleIcon';
import FileUploadBox from 'components/FileUploadBox';
import LegendItem from 'components/LegendItem';
import LightBox from 'components/LightBox';
import MultiFileUploadButton from 'components/MultiFileUploadButton';
import SelectColumnFilter from 'components/SelectColumnFilter';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useGetAllAccountsQuery } from 'store/adAccounts/adAccountsApi';
import { replyToRequestSchema, requestSchema } from 'utils/schema';
import AppTableWithPagination from 'components/AppTableWithPagination';
import usePolling from 'components/hook/usePolling';

function RequestsForClient() {

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [files, setfiles] = useState([]);
  const [replyFiles, setreplyfiles] = useState([]);
  const [activeRequest, setactiveRequest] = useState(null);
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const { _id: clientId } = userInfo;

  let statusBadgeColor = "gray";

  if (activeRequest?.status === "pending") {
    statusBadgeColor = "yellow";
  } else if (activeRequest?.status === "closed") {
    statusBadgeColor = "gray";
  } else if (activeRequest?.status === "open") {
    statusBadgeColor = "green";
  }

  const requestTypeLabel = {
    ad_account_disabled: "Ad account has been disabled, please review",
    share_access_to_bm: "Share access to a different BM",
    manually_charge_credit_card: "Manually charging Credit Card",
    change_credit_card: "Change Credit Card",
    ad_account_fund_transfer: "Ad account fund transfer",
    others: "Others",
  };

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const btnRef = React.useRef();

  const scrollBoxRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState('');
  const { isPollingActive, isTabVisible } = usePolling();

  const scrollToBottom = () => {
    scrollBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (activeRequest) {
      setTimeout(() => {
        scrollToBottom();
      }, 10);
    }
  }, [activeRequest]);

  /* QUERIES */

  const {
    data: requests,
    isLoading: isRequestsLoading,
    refetch: isRequestsRefetch,
    isFetching
  } = useGetRequestsQuery({ currentPage, searchValue }, {
    pollingInterval: isPollingActive && isTabVisible ? 20000 : false,
    skipPollingIfUnfocused: true,
  });

  const {
    data: accounts,
    error: getAccountsError,
    isLoading: isAccountsDataLoading,
  } = useGetAllAccountsQuery();

  const [addNewRequest, addNewResponse] = useAddNewRequestMutation();
  const [uploadFile, uploadResponse] = useUploadFileMutation();
  const [replyToRequest, replyResponse] = useReplyToRequestMutation();
  const [updateStatus, updateStatusResponse] = useUpdateStatusMutation();

  useEffect(() => {
    if (isPollingActive && isTabVisible) {
      isRequestsRefetch();
    }
  }, [isPollingActive, isTabVisible, isRequestsRefetch]);

  useEffect(() => {
    if (searchValue != '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  useEffect(() => {
    if (uploadResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "File uploaded",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (uploadResponse.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [uploadResponse]);

  useEffect(() => {
    // //console.log(replyResponse);
    if (replyResponse.status == "rejected") {
      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (replyResponse?.data) {
      //console.log("1");
      const {
        data: { _id },
      } = replyResponse.data;
      if (activeRequest?._id == _id) {
        setactiveRequest(replyResponse?.data?.data);
      }
      toast({
        position: "top-right",
        title: "Reply sent",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [replyResponse]);

  useEffect(() => {
    //console.log(result);
    if (addNewResponse.status == "rejected") {
      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (addNewResponse?.data) {
      toast({
        position: "top-right",
        title: "Request placed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [addNewResponse]);


  // //console.log({ requests });

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ticketNumber",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{"#" + original.ticketNumber}</Text>
        ),
      },
      {
        Header: "Ad Account",
        accessor: "adsAccount",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>
            {original.adsAccount?.id ? original.adsAccount?.title : "NA"}
          </Text>
        ),
      },
      {
        Header: "Request Type",
        accessor: "requestType",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{requestTypeLabel[original.requestType]}</Text>
        ),
      },
      {
        Header: "Client Email",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{original.clientId?.email}</Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          let badgeColor = "gray";
          let statusCopy = original.status;
          if (original.status == "pending") {
            badgeColor = "yellow";
            statusCopy = "In review"
          }
          if (original.status == "open") {
            badgeColor = "green";
          }
          return (
            <>
              <Badge fontSize={"10px"} marginRight={1} colorScheme={badgeColor}>
                {statusCopy}
              </Badge>
              {original.status != "closed" ? (
                <>
                  {original.clientRead ? <CircleIcon boxSize={2} color='green.500' /> : ""}
                </>
              ) : null}

            </>
          );
        },
      },
      {
        Header: "Created date",
        accessor: "createdDate",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>
            {moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}
          </Text>
        ),
      },
      {
        Header: "Updated date",
        accessor: "updatedDate",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>
            {moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}
          </Text>
        ),
      },

      {
        Header: "",
        accessor: "action",
        Filter: "",
        filter: "",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Flex justifyContent={"end"} alignItems={"center"} gap={"20px"}>
            <Tooltip label="View " fontSize="xs">
              <Box>
                <Icon
                  _hover={{ color: "gray.500" }}
                  as={FiEye}
                  cursor={"pointer"}
                  onClick={() => {
                    setactiveRequest(original);
                    onDrawerOpen();
                  }}
                />
              </Box>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    []
  );

  const initialTableState = {
    sortBy: [
      {
        id: "updatedDate",
        desc: true
      }
    ],
  };

  const formik = useFormik({
    initialValues: {
      adsAccount: "",
      requestType: "",
      description: "",
      attachments: "",
    },
    validationSchema: requestSchema,
    onSubmit: async (values) => {

      const { adsAccount, description, requestType } = values;
      const AdAccountDetails = accounts?.data?.filter(
        (account) => account._id == adsAccount
      );
      const { _id: adsAccountId, adsAccountName } = AdAccountDetails[0] || {};

      const attachments_ = files?.map((file, index) => file.value);

      const payload = {
        requestType,
        adsAccount: adsAccountId
          ? { id: adsAccountId, title: adsAccountName }
          : {},
        checked: false,
        messages: [
          {
            content: description,
            attachments: attachments_ ?? [],
          },
        ],
      };

      //console.log({ payload });

      if (values) {
        await addNewRequest(payload);
      }
      formik.resetForm();
      setfiles([]);
      onClose();
    },
  });

  const replyForm = useFormik({
    initialValues: {
      content: "",
      attachments: "",
    },
    validationSchema: replyToRequestSchema,
    onSubmit: async (values) => {
      const { content } = values;
      const attachments_ = replyFiles?.map((file, index) => file.value);

      const payload = {
        content,
        attachments: attachments_,
        checked: true,
      };

      // //console.log({ payload });

      if (values) {
        await replyToRequest({ requestId: activeRequest?._id, payload });
      }
      replyForm.resetForm();
      setreplyfiles([]);
    },
  });

  const adAccountsOptions = accounts?.data?.filter((account, index) => account.accountStatus && account.accountStatus == 1).map(
    ({ adsAccountId, adsAccountName, _id }) => (
      <option value={_id} key={_id}>
        {adsAccountName}
      </option>
    )
  );

  const handleFileUpload = (filename, acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (filename == "attachments") {
        formik.setFieldValue("attachments", file);
      }
      if (filename == "replyAttachments") {
        replyForm.setFieldValue("attachments", file);
      }
      const reader = new FileReader();
      reader.onload = async function (e) {
        //console.log({ filename, file });
        const formData = new FormData();
        formData.append("attachments", file);
        await uploadFile(formData)
          .unwrap()
          .then((payload) => {
            //console.log('fulfilled', payload)
            const { name, value } = payload?.data;
            if (filename == "attachments") {
              setfiles((files) => [...files, { name, value }]);
            }
            if (filename == "replyAttachments") {
              setreplyfiles((files) => [...files, { name, value }]);
            }

          })
          .catch((error) => {

          });
      };
      reader.readAsDataURL(file);
      return file;
    });
  };

  const removeFileSource = (filekey, index) => {
    if (filekey == "attachments") {
      if (index < 0 || index >= files.length) {
        return;
      }
      const newArray = [...files];
      newArray.splice(index, 1);
      setfiles(newArray);
    }

    if (filekey == "replyAttachments") {
      if (index < 0 || index >= replyFiles.length) {
        return;
      }
      const newArray = [...replyFiles];
      newArray.splice(index, 1);
      setreplyfiles(newArray);
    }
  };

  const messageHistory =
    activeRequest &&
    activeRequest.messages?.map(
      ({ content, author: { name, id }, createdAt, attachments }, index) => {
        return (
          <Box mb={"10px"} key={"request" + index}>
            <Box
              ml={id == clientId ? "20%" : "0"}
              mr={id != clientId ? "20%" : "0"}
              boxShadow='sm'
            >
              <Box
                background={id == clientId ? "blue.50" : "white"}
                p={"5px 10px"}
                borderRadius={5}
                borderColor={"gray.200"}
                borderWidth={1}
              >
                <Text fontSize={"sm"} color={"gray.600"}>{content}</Text>
                <Flex gap={"5px"}>
                  {attachments?.length > 0 &&
                    attachments?.map((file, i) => (
                      <LightBox fileSource={file} key={"attach_" + i} />
                    ))}
                </Flex>
                <Text
                  textAlign={"right"}
                  mt={"5px"}
                  fontSize={"9px"}
                  color={"gray.600"}
                >
                  {moment(createdAt).format("DD-MM-YYYY hh:mm A") + " • " + name}
                </Text>
              </Box>
            </Box>
          </Box>
        );
      }
    );

  useEffect(() => {
    // console.log(formik.values);
  }, [formik.values]);


  return (
    <>
      {/* VIEW CONVERSATION HISTORY */}

      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => {
          onDrawerClose();
          setactiveRequest(null);
        }}
        finalFocusRef={btnRef}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex align={"center"}>
              <Text>Request History</Text>
              <Badge
                ml={"10px"}
                fontSize={"10px"}
                colorScheme={statusBadgeColor}
              >
                {activeRequest?.status == 'pending' ? 'In review' : activeRequest?.status}
              </Badge>
            </Flex>
          </DrawerHeader>

          <DrawerBody
            //ref={scrollBoxRef}
            h={"300px"}
            background={"gray.50"}
            borderWidth={1}
            borderColor={"gray.300"}
            borderLeftWidth={0}
            borderRightWidth={0}
          >
            <Box p={"10px"} ref={scrollBoxRef}>{messageHistory}</Box>
          </DrawerBody>
          {activeRequest?.status != 'closed' ? (
            <DrawerFooter>
              <Box flex={1}>
                <Box gap={"10px"}>
                  <Box flex={1} mb={"10px"}>
                    <Textarea
                      size="md"
                      name="content"
                      placeholder="Message"
                      onChange={replyForm.handleChange}
                      onBlur={replyForm.handleBlur}
                      value={replyForm.values.content}
                      isInvalid={
                        replyForm.touched.content && replyForm.errors.content
                          ? true
                          : false
                      }
                    />
                    {replyForm.touched.content && replyForm.errors.content && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {replyForm.errors.content}
                      </Text>
                    )}
                  </Box>

                  <Flex flex={1} justify={"space-between"}>
                    <MultiFileUploadButton
                      fileSource={replyFiles}
                      fileKey="replyAttachments"
                      onFilesSelected={handleFileUpload}
                      onRemoveClick={(index) =>
                        removeFileSource("replyAttachments", index)
                      }
                      isDisabled={!replyForm.values.content}
                    />

                    {replyForm.touched.attachments &&
                      replyForm.errors.attachments && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                          {replyForm.errors.attachments}
                        </Text>
                      )}
                    <Button
                      size={"sm"}
                      onClick={replyForm.handleSubmit}
                      isDisabled={!replyForm.values.content || uploadResponse.isLoading}
                    >
                      Reply
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </DrawerFooter>
          ) : null}

        </DrawerContent>
      </Drawer>

      <Modal
        scrollBehavior={"inside"}
        size={"2xl"}
        isCentered={true}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Place a Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display={"flex"} flexDirection={"column"} gap={"20px"}>
              <Box flex={1}>
                <Select
                  flex={1}
                  name="requestType"
                  placeholder="Request Type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.requestType}
                  isInvalid={
                    formik.touched.requestType && formik.errors.requestType
                      ? true
                      : false
                  }
                >
                  {Object.keys(requestTypeLabel).map((item, key) => (
                    <option key={key} value={item}>
                      {requestTypeLabel[item]}
                    </option>
                  ))}

                </Select>
                {formik.touched.requestType && formik.errors.requestType && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.requestType}
                  </Text>
                )}
              </Box>
              {adAccountsOptions ? (
                <Box flex={1}>
                  <Select
                    flex={1}
                    name="adsAccount"
                    placeholder="Select Ad Account"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.adsAccount}
                    isInvalid={
                      formik.touched.adsAccount && formik.errors.adsAccount
                        ? true
                        : false
                    }
                  >
                    {adAccountsOptions}
                    <option value={"NA"}>NA</option>
                  </Select>
                  {formik.touched.adsAccount && formik.errors.adsAccount && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.adsAccount}
                    </Text>
                  )}
                </Box>
              ) : null}

              <Box flex={1}>
                <Textarea
                  size="md"
                  name="description"
                  placeholder="Enter the request text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isInvalid={
                    formik.touched.description && formik.errors.description
                      ? true
                      : false
                  }
                />
                {formik.touched.description && formik.errors.description && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.description}
                  </Text>
                )}
              </Box>
              <Box>
                <FormLabel fontSize="sm" mb={"10px"}>
                  Attachments
                </FormLabel>
                <FileUploadBox
                  fileSource={files}
                  multiUpload={true}
                  fileKey="attachments"
                  onFileUpload={handleFileUpload}
                  onRemoveClick={(index) =>
                    removeFileSource("attachments", index)
                  }
                  isInvalid={
                    formik.touched.attachments && formik.errors.attachments
                      ? true
                      : false
                  }
                />
                {formik.touched.attachments && formik.errors.attachments && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.attachments}
                  </Text>
                )}
              </Box>
              <Flex gap={"20px"} mb={"10px"} mt={"20px"} justify={"center"}>
                <Button
                  isDisabled={uploadResponse.isLoading}
                  onClick={formik.handleSubmit}
                  colorScheme='orange'
                >
                  Submit
                </Button>
                <Button
                  onClick={() => {
                    formik.resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <SidebarWithHeader>
        <Flex mb={"10px"}>
          <Heading as="h1" size="xl">
            Track Requests
          </Heading>
          <Spacer />
          <Button colorScheme="orange" onClick={onOpen}>
            <AddIcon boxSize={3} />
            &nbsp;Create Ticket
          </Button>
        </Flex>

        <Flex flexDirection={"column"} flex={"1"}>
          {isRequestsLoading ? <Spinner /> : (
            <>
              <Box>
                <LegendItem color="green.300" label="Open - Your request has been sent to our team." />
                <Flex align="center" mb={2}>
                  <Box w={4} h={2} bg={'yellow.300'} mr={2} />
                  <Text align={'center'} fontSize={'xs'}>In Review - Your request is In Review. Click
                    <Icon
                      boxSize={3}
                      as={FiEye}
                      pt={'2px'}
                      mb={'-2px'}
                      mx={1}
                    />to learn more.
                  </Text>
                </Flex>
                <LegendItem color="gray.300" label="Closed - Your request has been solved, and the ticket has been closed." />
                <Box mt={'20px'}>

                  <AppTableWithPagination
                    columns={columns}
                    data={requests?.data}
                    searchTerm={searchValue}
                    paginationMeta={requests?.meta}
                    onPageChange={(index) => setCurrentPage(index)}
                    onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                    searchEnabled={true}
                    isFetching={isFetching}
                    emptyMessage={'If you have a query or a concern, please contact us by creating a Ticket.'}
                  />
                </Box>
              </Box>

              {/* {!searchValue && requests?.data?.length == 0 ? (
                <Text>If you have a query or a concern, please contact us by creating a Ticket.</Text>
              ) : null} */}
            </>
          )}



        </Flex>
      </SidebarWithHeader>
    </>
  );
}

export default RequestsForClient