import { createSlice } from '@reduxjs/toolkit';

const savedCampaignId = localStorage.getItem('selectedCampaignId');
const savedCampaign = localStorage.getItem('selectedCampaign') ? JSON.parse(localStorage.getItem('selectedCampaign')) : null;
const savedAdsetId = localStorage.getItem('selectedAdsetId');
const savedAdset = localStorage.getItem('selectedAdset') ? JSON.parse(localStorage.getItem('selectedAdset')) : null;
const savedAdsId = localStorage.getItem('selectedAdsId');
const savedAfter = localStorage.getItem('after') || '';
const savedBefore = localStorage.getItem('before') || '';
const savedCurrentPage = localStorage.getItem('currentPage') ? parseInt(localStorage.getItem('currentPage'), 10) : 0;
const savedPresentPage = localStorage.getItem('presentPage') ? parseInt(localStorage.getItem('presentPage'), 10) : 1;

const initialState = {
  selectedCampaignId: savedCampaignId ? savedCampaignId : null,
  selectedCampaign : savedCampaign ? savedCampaign : null,
  selectedAdsetId: savedAdsetId ? savedAdsetId : null,
  selectedAdset: savedAdset ? savedAdset : null,
  selectedAdsId: savedAdsId ? savedAdsId : null,
  campaignsForInsights: [],
  AdsetForInsights: [],
  AdsForInsights: [],
  after: savedAfter,
  after1: '',
  before: savedBefore,
  before1: '',
  currentPage: isNaN(savedCurrentPage) ? 0 : savedCurrentPage,
  currentPage1: '',
  presentPage: isNaN(savedPresentPage) ? 1 : savedPresentPage,
  accessAllAdAccounts: false,
  listLoading: false,
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setSelectedCampaignId: (state, action) => {
      state.selectedCampaignId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedCampaignId');
      } else {
        localStorage.setItem('selectedCampaignId', action.payload);
      }
    },
    setSelectedCampaigns: (state, action) => {
      state.selectedCampaign = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedCampaign');
      } else {
        localStorage.setItem('selectedCampaign', JSON.stringify(action.payload));
      }
    },
    setSelectedAdsetId: (state, action) => {
      state.selectedAdsetId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdsetId');
      } else {
        localStorage.setItem('selectedAdsetId', action.payload);
      }
    },
    setSelectedAdset: (state, action) => {
      state.selectedAdset = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdset');
      } else {
        localStorage.setItem('selectedAdset', JSON.stringify(action.payload));
      }
    },
    setSelectedAdsId: (state, action) => {
      state.selectedAdsId = action.payload;
      if (action.payload === null) {
        localStorage.removeItem('selectedAdsId');
      } else {
        localStorage.setItem('selectedAdsId', action.payload);
      }
    },
    setCampaignsForInsights: (state, action) => {
      state.campaignsForInsights = action.payload;
    },
    setAdsetsForInsights: (state, action) => {
      state.AdsetForInsights = action.payload;
    },
    setAdsForInsights: (state, action) => {
      state.AdsForInsights = action.payload;
    },
    setAfter: (state, action) => {
      const { componentKey = 'default', value } = action.payload;
      if (componentKey === 'Drive') {
        state.after1 = value;
      } else {
        state.after = action.payload;
        localStorage.setItem('after', action.payload);
      }
    },
    setBefore: (state, action) => {
      const { componentKey = 'default', value } = action.payload;
      if (componentKey === 'Drive') {
        state.before1 = value;
      } else {
        state.before = action.payload;
        localStorage.setItem('before', action.payload);
      }
    },
    setCurrentPage: (state, action) => {
      const newPage = Math.max(0, action.payload);
      const { componentKey = 'default', value } = action.payload;
      if (componentKey === 'Drive') {
        state.currentPage1 = value;
      } else {
        state.currentPage = newPage;
        localStorage.setItem('currentPage', newPage);
      }
    },
    setPresentPage: (state, action) => {
        state.presentPage = action.payload;
        localStorage.setItem('presentPage', action.payload);
    },
    setAccessAllAdAccounts: (state, action) => {
      state.accessAllAdAccounts = action.payload;
    },
    setListLoading: (state, { payload }) => {
      state.listLoading = payload;
  },
  },
});

export const { 
  setSelectedCampaignId, 
  setSelectedCampaigns,
  setSelectedAdsetId, 
  setSelectedAdset, 
  setSelectedAdsId, 
  setCampaignsForInsights, 
  setAdsetsForInsights,
  setAdsForInsights, 
  setAfter, 
  setBefore, 
  setCurrentPage,
  setPresentPage,
  setAccessAllAdAccounts,
  setListLoading
} = campaignsSlice.actions;

export const selectSelectedCampaignId = (state) => state.campaigns.selectedCampaignId;
export const selectSelectedCampaign = (state) => state.campaigns.selectedCampaign;
export const selectSelectedAdsetId = (state) => state.campaigns.selectedAdsetId;
export const selectSelectedAdset = (state) => state.campaigns.selectedAdset;
export const selectSelectedAdsId = (state) => state.campaigns.selectedAdsId;
export const selectCampaignsForInsights = (state) => state.campaigns.campaignsForInsights;
export const selectAdsetsForInsights = (state) => state.campaigns.AdsetForInsights;
export const selectAdsForInsights = (state) => state.campaigns.AdsForInsights;
export const selectAfter = (state) => state.campaigns.after;
export const selectAfter1 = (state) => state.campaigns.after1;
export const selectBefore = (state) => state.campaigns.before;
export const selectBefore1 = (state) => state.campaigns.before1;
export const selectCurrentPage = (state) => state.campaigns.currentPage;
export const selectCurrentPage1 = (state) => state.campaigns.currentPage1;
export const selectPresentPage = (state) => state.campaigns.presentPage;
export const selectAccessAllAdAccounts = (state) => state.campaigns.accessAllAdAccounts; 
export const selectlistLoading = (state) => state.campaigns.listLoading; 

export default campaignsSlice.reducer;
