import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import CarouselAdUpdateForm from 'components/genericForms/CarouselAdUpdateForm';
import SingleImageAdUpdateForm from 'components/genericForms/SingleImageAdUpdateForm';
import SingleVideoAdUpdateForm from 'components/genericForms/SingleVideoAdUpdateForm';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2, FiEye } from "react-icons/fi";
import { useGetAdCreativeQuery, useGetAdsListQuery, useUpdateAdStatusMutation, useGetAdComparisonQuery } from 'store/campaigns/adsApi';
import moment from 'moment';
import { selectSelectedAdsetId, selectSelectedCampaignId, selectSelectedAdsId, setSelectedAdsId } from 'store/campaigns/campaignsSlice';
import { useDispatch, useSelector } from 'react-redux';

const call_to_action_options = [
    { value: 'SHOP_NOW', label: 'Shop Now' },
    { value: 'LEARN_MORE', label: 'Learn More' },
    { value: 'APPLY_NOW', label: 'Apply Now' },
];

const formatScore = (score) => {
    if (score === 0) {
        return '-';
    }
    return `${score.toFixed(2)}%`;
};

function AdsTable({ adsAccountData, startDate, endDate, datePreset }) {
    const toast = useToast();
    const dispatch = useDispatch();
    const selectedCampaignId = useSelector(selectSelectedCampaignId);
    const selectedAdsetId = useSelector(selectSelectedAdsetId);
    const selectedAdsId = useSelector(selectSelectedAdsId);
    const userInfo = useSelector((state) => state.auth?.userInfo);
  
    const { isOpen: isOpenComparison, onOpen: onOpenComparison, onClose: onCloseComparison } = useDisclosure();
    const isSearchDisabled = Boolean(selectedAdsId);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAd, setSelectedAd] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const { data: adsList, isFetching, isLoading, refetch, error: adsListError } = useGetAdsListQuery({
        currentPage,
        searchValue,
        adsAccountId: adsAccountData?.adsAccountId,
        adset_ids: selectedAdsetId ? [selectedAdsetId] : [],
        start_date: startDate,
        end_date: endDate, 
        date_preset: datePreset,
        campaign_ids: selectedCampaignId ? [selectedCampaignId] : [], 
        ad_id: selectedAdsId ? selectedAdsId : '',
    }, { skip: !adsAccountData?.adsAccountId });
    
    const { data: adCreative, isFetching: isFetchingCreative, isLoading: isLoadingCreative, refetch: refetchCreative } = useGetAdCreativeQuery(
        { creativeId: selectedAd?.creative?.id },
        { skip: !selectedAd?.creative?.id || !selectedAd }
    );

    const { data: adsComparison, isLoading: isComparisonLoading, refetch: comparisonRefetch } = useGetAdComparisonQuery(
        { hashes: selectedAd?.hashes }, 
        { skip: !selectedAd?.hashes }
    )   

    const [updateAdStatus, { isLoading: isAdStatusUpdating }] = useUpdateAdStatusMutation({});

    useEffect(() => {
        if (adsAccountData?.adsAccountId) {
            refetch();
        }
    }, [adsAccountData?.adsAccountId]);

    useEffect(() => {
        if (adsList?.data) {
            const initialSwitchStates = {};
            adsList.data.forEach(ad => {
                initialSwitchStates[ad.ad_id] = ad.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [adsList]);

    useEffect(() => {
        if (selectedAd) {
            refetchCreative();
        }
    }, [selectedAd]);

    const handleSwitchChange = (adId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adId]: newStatus }));
        updateAdStatus({
            adId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Ad ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Ad status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Ad status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adId]: currentState }));
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isActive = switchStates[original.ad_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad is ON' : 'Ad is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.ad_id, original.status)}
                                        isDisabled={userInfo?.role !== 'client'}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isSelected = selectedAdsId === original?.ad_id;
                    
                    return (
                        <Flex gap={'10px'} align={'center'}>
                           {original.imageUrl ? (
                                <img
                                    src={original.imageUrl}
                                    alt={`${original.name} thumbnail`}
                                    style={{ width: '45px', minWidth: '45px', height: '35px', objectFit: 'cover', border: '0.5px solid grey',}}
                                />
                            ) : (
                                <img
                                    src={original.imageUrl}
                                    alt={' '}
                                    style={{ width: '45px', minWidth: '45px', height: '35px', objectFit: 'cover', border: '0.5px solid grey', }}
                            />
                            )}
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={userInfo?.role === 'client' ? "pointer" : "not-allowed"} 
                                        color={userInfo?.role === 'client' ? "inherit" : "gray.300"}
                                        onClick={() => {
                                            if (userInfo?.role === 'client') {
                                                setSelectedAd(original);
                                                onOpen();
                                            }
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                            <Flex gap={'10px'} alignItems={'center'}>
                                <Text
                                    // _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    fontWeight={isSelected ? 'bold' : 'normal'}
                                    color={isSelected ? 'orange.500' : 'inherit'}
                                >
                                    {original.name}
                                </Text>
                            </Flex>
                        </Flex>
                    )            
                },
            },
            // {
            //     Header: "Ad ID",
            //     accessor: "ad_id",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => {

            //         return (
            //             <Flex gap={'10px'} align={'center'}>
            //                 <Text>{original.ad_id}</Text>
            //             </Flex>
            //         )
            //     },
            // },
            {
                Header: "Comparison",
                accessor: "Comparison",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex align={'center'} justifyContent={'center'}>
                        <Tooltip label="View Comparison" fontSize='xs' >
                            <Flex>
                                <Icon
                                    as={FiEye}
                                    cursor="pointer"
                                    onClick={() => {
                                        setSelectedAd(original);
                                        onOpenComparison();
                                    }}
                                    _hover={{ color: "gray.500" }} 
                                />
                            </Flex>
                        </Tooltip>
                    </Flex>
                ),
            },
            {
                Header: "Spend",
                accessor: "spend",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text>{original.insight?.spend || '-'}</Text>
                    )

                },
            },
            {
                Header: "Breakeven CPA",
                accessor: "cpa",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontWeight={'bold'}>{original.cpa || '-'}</Text>
                ),
            },
            {
                Header: "Achieved CPA",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Box>
                        <Text fontWeight={'bold'}>{original.insight?.cpr || '-'}</Text>
                    </Box>
                )   
            },
            {
                Header: "Scoring",
                accessor: "score",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const score = original.insight?.score || 0;
                    return <Text fontWeight={'bold'}>{formatScore(score)}</Text>;
                },
            },
            {
                Header: "Results",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.results || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPC",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.cpc || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CTR",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.ctr || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "CPM",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.cpm || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Clicks",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.clicks || '-'}</Text>
                    </Flex>
                )
            },
            // {
            //     Header: "Engagement",
            //     accessor: "",
            //     Filter: "",
            //     filter: "",
            //     Cell: ({ row: { original } }) => (
            //         <Flex gap={'10px'}>
            //             <Text fontSize={'12px'}>{original.insight?.engagement || "-"}</Text>
            //         </Flex>
            //     )
            // },
            {
                Header: "Purchase Value",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.purchaseValue || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Roas",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.insight?.roas || '-'}</Text>
                    </Flex>
                )
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text>{original.status}</Text>
                    )

                },
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
        ],
        [selectedAdsId]
    );

    return (
        <>
            {/* Comparison Modal */}
            <Modal
                size={"6xl"}
                isCentered={true}
                isOpen={isOpenComparison}
                onClose={() => {
                    onCloseComparison();
                    setSelectedAd(null); 
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ad Comparison</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody maxHeight="500px" overflowY="auto">
                        {isComparisonLoading ? ( 
                            <Flex>
                                <Spinner mt="20px"/>
                            </Flex>
                        ) : (
                            adsComparison && adsComparison?.length > 0 ? (
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>OFF/ON</Th>
                                        <Th>Ad Account</Th>
                                        <Th sx={{ whiteSpace: 'nowrap' }}>Campaign Name</Th>
                                        <Th>Ad Name</Th>  
                                        <Th>Spend</Th>
                                        <Th sx={{ whiteSpace: 'nowrap' }}>Breakeven CPA</Th>
                                        <Th sx={{ whiteSpace: 'nowrap' }}>Achieved CPA</Th>
                                        <Th>Scoring</Th>
                                        <Th>Results</Th>
                                        <Th>CPC</Th>
                                        <Th>CTR</Th>
                                        <Th>CPM</Th>
                                        <Th>Clicks</Th>
                                        {/* <Th>Engagement</Th> */}
                                        <Th sx={{ whiteSpace: 'nowrap' }}>Purchase Value</Th>
                                        <Th>Roas</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {adsComparison.map((ad, index) => {
                                       
                                        const adStatus = adsList?.data.find(adItem => adItem?.ad_id === ad?.ad?.ad_id)?.status;
                                        
                                        return(
                                        <Tr key={index}>
                                             <Td>
                                                <Flex gap={'10px'} align={'center'}>
                                                    <Tooltip label={adStatus === 'ACTIVE' ? 'Ad is ON' : 'Ad is OFF'} fontSize='xs'>
                                                        <FormControl width={'fit-content'}>
                                                            <Switch
                                                                colorScheme='orange'
                                                                size={'sm'}
                                                                isChecked={adStatus === 'ACTIVE'}
                                                                onChange={() => handleSwitchChange(ad?.ad?.ad_id, adStatus)}
                                                                isDisabled={userInfo?.role !== 'client'}
                                                            />
                                                        </FormControl>
                                                    </Tooltip>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Box>
                                                    <Text fontSize={'14px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.adsAccount?.adsAccountName|| 'N/A'}</Text>
                                                    <Text fontSize={'12px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.adsAccount?.adsAccountId || 'N/A'}</Text>
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Text fontSize={'14px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.campaign?.name || 'N/A'}</Text>
                                            </Td>
                                            <Td>
                                                <Flex direction={'row'} alignItems={'center'}>
                                                <img
                                                    src={ad?.creative?.thumbnail_url}
                                                    alt={`${ad?.ad?.name}`}
                                                    style={{
                                                        width: '35px',
                                                        height: '35px',
                                                        objectFit: 'cover',
                                                        border: '0.5px solid grey',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                 <Text fontSize={'14px'} sx={{ whiteSpace: 'nowrap' }}>{ad?.ad?.name || 'N/A'}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.spend || '-'}</Td>
                                            <Td sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{ad?.cpa || '-'}</Td>
                                            <Td sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{ad?.cpr || '-'}</Td>
                                            <Td>
                                                <Text sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{formatScore(ad?.score || 0)}</Text>
                                            </Td>

                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.results}</Td> 
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.cpc || '-'}</Td>   
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.ctr || '-'}</Td>  
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.cpm || '-'}</Td> 
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.clicks || '-'}</Td> 
                                            {/* <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.engagement || '-'}</Td> */}
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.purchaseValue || '-'}</Td>
                                            <Td sx={{ whiteSpace: 'nowrap' }}>{ad?.roas || '-'}</Td>
                                        </Tr>
                                    )})}
                                </Tbody>
                            </Table>
                        ) : (
                            <Text>No comparison data available.</Text>
                        )
                    )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* EDIT AD MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    {isLoadingCreative ? (
                          <Spinner mt="20px"/>
                    ) : adCreative?.object_story_spec ? (
                        adCreative.object_story_spec.link_data ? (
                            adCreative.object_story_spec.link_data.child_attachments ? (
                                <CarouselAdUpdateForm adData={selectedAd} adsAccountData={adsAccountData} onClose={onClose} />
                            ) : (
                                <SingleImageAdUpdateForm 
                                    adData={selectedAd} 
                                    adsAccountData={adsAccountData} 
                                    call_to_action_options={call_to_action_options}
                                    onClose={onClose} 
                                />
                            )
                        ) : adCreative.object_story_spec.video_data ? (
                            <SingleVideoAdUpdateForm 
                                adData={selectedAd} 
                                adsAccountData={adsAccountData} 
                                call_to_action_options={call_to_action_options}
                                onClose={onClose} 
                            />
                        ) : (
                            <Text>No data available for the selected ad.</Text>
                        )
                    ) : (
                        <Text>No data available for the selected ad.</Text>
                    )}

                </ModalBody>
                </ModalContent>
            </Modal>
            {isLoading ? (
                <Spinner mt="20px" />
                ) : adsListError ? (  
                    <Text color="red.500" mt="20px">{"Sorry, Ads not available at this moment. Try again later."}</Text>
                ) : (
                <AppTableWithPagination
                    columns={columns}
                    data={adsList?.data || []}
                    searchEnabled={!isSearchDisabled}
                    isLoading={false}
                    isFetching={isFetching}
                    initialState={{ pageIndex: currentPage - 1 }}
                    paginationMeta={adsList?.meta || {}}
                    onPageChange={setCurrentPage}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
                )}
        </>
    );
}

export default AdsTable;
