import { api } from '../api'
import { setHasActiveAdAccount } from '../auth/authSlice'


export const AdAccountssApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdsAccount: builder.query({
            query: (id) => ({
                url: `/ads-accounts/${id}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['UserAdAccounts']
        }),
        getAccountsByUserID: builder.query({
            query: ({ userId }) => ({
                url: `/ads-accounts/client/${userId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['UserAdAccounts']
        }),
        getAccounts: builder.query({
            query: ({ currentPage, searchValue }) => `/ads-accounts/?page=${currentPage}&filter=${searchValue}`,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getActiveAccounts: builder.query({
            query: ({ currentPage, searchValue }) => `/ads-accounts/?page=${currentPage}&filter=${searchValue}&status=created`,
            transformResponse: (response, meta, arg) => response.data,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                // CHECK IF USER HAS AN ACTIVE AD ACCOUNT
                try {
                    const { data: { data: activeAccountList } } = await queryFulfilled;
                    let hasActiveAdAccount = activeAccountList?.length > 0
                    dispatch(setHasActiveAdAccount(hasActiveAdAccount));
                } catch (error) {
                    // //console.log(error);
                }
            },
            //providesTags: ['AdAccounts'],
        }),
        getActiveAccountsList: builder.query({
            query: () => `/ads-accounts/?page=1&status=created`,
            transformResponse: (response, meta, arg) => response.data,
            //providesTags: ['AdAccounts'],
        }),
        getAllAccounts: builder.query({
            query: () => `/ads-accounts`,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getOwnedAdsAccountsByBmId: builder.query({
            query: ({ bmId, searchAdAccountValue }) => ({
                url: `/ads-accounts/owned_ad_accounts/${bmId}?search=${searchAdAccountValue}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getClientAdsAccountsByBmId: builder.query({
            query: ({ bmId, searchAdAccountValue }) => ({
                url: `/ads-accounts/client_ad_accounts/${bmId}?search=${searchAdAccountValue}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        checkAdsAccountById: builder.query({
            query: ({ adAccountId }) => ({
                url: `/ads-accounts/find/${adAccountId}`,
            }),
        }),
        getOwnedFacebookPagesByBmId: builder.query({
            query: ({ bmId }) => ({
                url: `/ads-accounts/owned_pages/${bmId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getClientFacebookPagesByBmId: builder.query({
            query: ({ bmId }) => ({
                url: `/ads-accounts/client_pages/${bmId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getInstagramAccountsByBmId: builder.query({
            query: ({ bmId }) => ({
                url: `/ads-accounts/instagram_accounts/${bmId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getOwnedPixelsByBmId: builder.query({
            query: ({ bmId }) => ({
                url: `/ads-accounts/owned_pixels/${bmId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        getClientPixelsByBmId: builder.query({
            query: ({ bmId }) => ({
                url: `/ads-accounts/client_pixels/${bmId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdAccounts'],
        }),
        addNewAccount: builder.mutation({
            query: account => ({
                url: '/ads-accounts',
                method: 'POST',
                body: account
            }),
            invalidatesTags: ['AdAccounts']
        }),
        addNewAdAccount: builder.mutation({
            query: request => ({
                url: '/ads-accounts',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['AdAccounts']
        }),
        editAccount: builder.mutation({
            query: ({ accountId, payload }) => ({
                url: `/ads-accounts/${accountId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AdAccounts']
        }),
        removeAccount: builder.mutation({
            query: accountId => ({
                url: `/ads-accounts/${accountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdAccounts']
        }),
        assignClientToAccount: builder.mutation({
            query: ({ accountId, data }) => ({
                url: `/ads-accounts/${accountId}/assign`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['AdAccounts', 'UserAdAccounts']
        }),
        uploadAdAccountFile: builder.mutation({
            query: payload => ({
                url: '/ads-accounts/upload',
                method: 'POST',
                body: payload
            }),
        }),
        replyToAdAccountRequest: builder.mutation({
            query: ({ requestId, payload }) => ({
                url: `/ads-accounts/${requestId}/reply`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AdAccounts']
        }),
        updateAdAccountStatus: builder.mutation({
            query: ({ requestId, payload }) => ({
                url: `/ads-accounts/${requestId}/status`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AdAccounts']
        }),
        getDebits: builder.query({
            query: () => "/ads-accounts/dashboard/debits",
            transformResponse: (response, meta, arg) => response.data,
        }),
        getAdAccountsForDashboard: builder.query({
            query: ({ currentPage, searchValue }) => `/ads-accounts/dashboard/adsaccounts?page=${currentPage}&filter=${searchValue}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getClientwiseDashboardTableCC: builder.query({
            query: ({ id }) => `/ads-accounts/client/${id}/dashboard/?account_type=cc`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getClientwiseDashboardTableCL: builder.query({
            query: ({ id }) => `/ads-accounts/client/${id}/dashboard/?account_type=cl`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getClientwiseDebitsCC: builder.query({
            query: ({ id }) => `ads-accounts/client/${id}/debits?account_type=cc`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getClientwiseDebitsCL: builder.query({
            query: ({ id }) => `ads-accounts/client/${id}/debits?account_type=cl`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getAdsAccountwiseDashboardTable: builder.query({
            query: ({ id }) => `ads-accounts/dashboard/adsaccounts?filter=${id}`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getBMAccounts: builder.query({
            query: () => `ads-accounts/business/accounts`,
            transformResponse: (response, meta, arg) => response.data,
        }),

    })
})

export const { useGetAllAccountsQuery, useGetAdsAccountQuery, useAddNewAdAccountMutation, useUploadAdAccountFileMutation, useAssignClientToAccountMutation, useGetAccountsByUserIDQuery, useGetAccountsQuery, useAddNewAccountMutation, useEditAccountMutation, useRemoveAccountMutation, useReplyToAdAccountRequestMutation, useUpdateAdAccountStatusMutation, useGetDebitsQuery, useGetAdAccountsForDashboardQuery, usePrefetch, useGetActiveAccountsQuery,
    useGetClientwiseDashboardTableCCQuery, useGetClientwiseDashboardTableCLQuery,
    useGetClientwiseDebitsCCQuery, useGetClientwiseDebitsCLQuery,
    useGetAdsAccountwiseDashboardTableQuery, useGetActiveAccountsListQuery,useGetClientAdsAccountsByBmIdQuery,useGetOwnedAdsAccountsByBmIdQuery, useGetInstagramAccountsByBmIdQuery,useGetClientFacebookPagesByBmIdQuery,useGetOwnedFacebookPagesByBmIdQuery, useGetOwnedPixelsByBmIdQuery,useGetClientPixelsByBmIdQuery, useCheckAdsAccountByIdQuery
    , useGetBMAccountsQuery
} = AdAccountssApi;