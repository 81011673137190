import React from 'react';
import {
    Box,
    Text,
    Flex,
    useColorModeValue,
    CheckboxGroup,
    Checkbox,
    Spinner,
    Divider
} from '@chakra-ui/react';

function Sidebar({ isLoadingCampaign, campaignData }) {
    const bgColor = useColorModeValue('inherit', '#231f20');
    const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'gray.200');

    const { name, objective, adsAccounts } = campaignData || {};
    const selectedAdAccountIds = adsAccounts?.map(account => account.adsAccountId) || [];

    const getObjectiveName = (objective) => {
        switch (objective) {
            case 'OUTCOME_SALES':
                return 'Sales';
            case 'OUTCOME_LEADS':
                return 'Leads';
            default:
                return 'Unknown Objective';
        }
    };

    return (
        <Box
            height="100%"
            // bg={bgColor}
            p={'20px'}
            px={'20px'}
            borderRight="1px solid"
            borderColor="gray.200"
            overflowY="auto"
        >

            {isLoadingCampaign ? (
                <Spinner mt={'20px'} />
            ) : (
                <Flex direction="column" align="flex-start" borderRadius="md"
                    borderWidth={1}
                    borderColor="gray.200">
                    <Box p={'10px'} width={'full'}>
                        {/* Campaign Name */}
                        {name ? (
                            <Flex
                                alignItems="center"
                                width="100%"
                                borderRadius="md"
                            >
                                <Text
                                    fontWeight="bold"
                                    color={textColor}
                                >
                                    {name}
                                </Text>
                            </Flex>
                        ) : (
                            <Flex
                                alignItems="center"
                            >
                                <Text
                                    fontWeight="normal"
                                    color={textColor}
                                >
                                    Campaign Name not Available
                                </Text>
                            </Flex>
                        )}

                        {/* Campaign Objective */}
                        {objective ? (
                            <Flex
                                alignItems="center"
                                textTransform={'uppercase'}
                            >
                                <Text
                                    fontWeight="normal"
                                    color={textColor}
                                    fontSize={'12px'}
                                >
                                    {getObjectiveName(objective)}
                                </Text>
                            </Flex>
                        ) : (
                            <Flex
                                alignItems="center"
                                width="100%"
                            >
                                <Text
                                    fontWeight="normal"
                                    color={textColor}
                                >
                                    Objective not Available
                                </Text>
                            </Flex>
                        )}
                    </Box>
                    <Divider />
                    {/* Ad Accounts */}
                    <Box p={'10px'} width={'full'}>
                        {adsAccounts && adsAccounts.length > 0 ? (
                            <Flex direction="column">
                                <Text mb={2} fontSize={'14px'}>Selected Ad Accounts</Text>
                                <CheckboxGroup value={selectedAdAccountIds} name="adsAccountName">
                                    <Flex direction="column">
                                    {adsAccounts.map((account) => (
                                            <Flex key={account.adsAccountId} direction="column">
                                                <Text fontSize="14px" fontWeight="bold">{account.adsAccountName}</Text>
                                                <Text fontSize="12px" color="gray.500">{account.adsAccountId}</Text>
                                            </Flex>
                                    ))}
                                    </Flex>
                                </CheckboxGroup>
                            </Flex>
                        ) : (
                            <Flex
                                direction="column"
                            >
                                <Text mb={2} fontWeight="bold" color={textColor}>
                                    Ad Accounts not Available
                                </Text>
                            </Flex>
                        )}
                    </Box>
                </Flex>
            )}
        </Box>
    );
}

export default Sidebar;
