import SidebarWithHeader from 'components/SidebarWithHeader';
import { useEffect, useState } from "react";

import {
    Box,
    Flex,
    Icon,
    Select,
    Text
} from '@chakra-ui/react';
import CampaignsTable from 'components/campaignTables/CampaignsTable';
import moment from "moment";
import DatePicker from 'react-datepicker';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdRefresh } from 'react-icons/md';

function ManageCampaigns() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const adsAccountId = searchParams.get('adsAccountId');
    const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount)
    const activeAccountsData = useSelector((state) => state.auth?.activeAccountsData);


    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {

            if (adsAccountId) {
                setSelectedAdsAccountId(adsAccountId);
            } else {
                setSelectedAdsAccountId(activeAccountsData.data[0].adsAccountId);
            }
            setSelectedAccountData(activeAccountsData.data[0]);
        }
    }, [activeAccountsData, adsAccountId]);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
            setSelectedAccountData(account);
        }
    }, [selectedAdsAccountId, activeAccountsData]);

    // console.log(startDate, endDate);



    return (
        <>
            {hasActiveAdAccount ?
                (
                    <SidebarWithHeader>
                        <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                            <Flex gap={'10px'} flex={1} align={'center'}>
                                <Text fontWeight={'bold'}>Ad accounts</Text>
                                <Box flex={0.5}>
                                    <Select
                                        value={selectedAdsAccountId}
                                        onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                                        size={'sm'}
                                        borderRadius={'md'}
                                    >
                                        {activeAccountsData?.data?.map(account => (
                                            <option key={account._id} value={account.adsAccountId}>
                                                {`${account.adsAccountName} (${account.adsAccountId})`}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                {adsAccountId ? (
                                    <Icon
                                        cursor="pointer"
                                        boxSize={5}
                                        _hover={{
                                            color: 'gray.400'
                                        }}
                                        as={MdRefresh}
                                        onClick={() => {
                                            navigate('/manage-campaigns');
                                        }}
                                    />
                                ) : null}
                            </Flex>

                            <Flex justifyContent={'flex-end'} alignItems="center">
                                <Flex alignItems="center" gap="10px">
                                    <Box >
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Start Date"
                                            className="date-picker-input"
                                            style={{ width: 'auto' }}
                                        />
                                    </Box>
                                    <Box >
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="End Date"
                                            className="date-picker-input"
                                            style={{ width: 'auto', fontsize: '12px' }}
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex width={'full'} align={'center'} justify={'space-between'}>
                            <Flex flex={1} justify={'center'} borderWidth={1} borderTopColor={'orange.600'} borderBottom={'none'}>
                                <Link justify={'center'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                                    <Text color="orange.600" fontWeight={'bold'}>Campaigns</Text>
                                </Link>
                            </Flex >
                            <Flex flex={1} justify={'center'} borderWidth={1} bg={'gray.50'}>
                                <Link to={'/manage-adsets'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                                    <Text>Ad sets</Text>
                                </Link>
                            </Flex>
                            <Flex flex={1} justify={'center'} borderWidth={1} bg={'gray.50'}>
                                <Link to={'/manage-ads'} style={{ padding: '8px 10px', width: '100%', height: '100%', textAlign: 'center' }}>
                                    <Text> Ads</Text>
                                </Link>
                            </Flex>
                        </Flex>
                        <Box p={'10px'} borderWidth={1} borderTop={0} pt={'20px'}>
                            <CampaignsTable
                                adsAccountId={selectedAdsAccountId}
                                startDate={moment(startDate).format("YYYY-MM-DD")}
                                endDate={moment(endDate).format("YYYY-MM-DD")}
                            />
                        </Box>
                    </SidebarWithHeader >
                ) : (
                    <SidebarWithHeader>
                        <Flex>
                            <Text>
                                Your Ad account is not set up yet. Please request an Ad account.
                            </Text>
                        </Flex>
                    </SidebarWithHeader>
                )}
        </>
    );
}

export default ManageCampaigns;
