import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Input, Divider, Button, IconButton, List, ListItem, Box as ChakraBox } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

function UrlParameter({onUrlChange, onClose, selectedAccountId, urls}) {

  const [parameters, setParameters] = useState([]);
  const [campaignSource, setCampaignSource] = useState('');
  const [campaignMedium, setCampaignMedium] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignContent, setCampaignContent] = useState('');

  const [showSourceDropdown, setShowSourceDropdown] = useState(false);
  const [showMediumDropdown, setShowMediumDropdown] = useState(false);
  const [showNameDropdown, setShowNameDropdown] = useState(false);
  const [showContentDropdown, setShowContentDropdown] = useState(false);
  const [customDropdownVisibility, setCustomDropdownVisibility] = useState({});

  const selectedUrl = urls.find(url => url.adsAccountId === selectedAccountId) || {};

  useEffect(() => {
    if (selectedUrl && selectedUrl.url) {
        const urlParamsString = selectedUrl?.url; 
        const urlParams = new URLSearchParams(urlParamsString); 
      
      // Extract and set each campaign parameter from the URL
      setCampaignSource(urlParams.get('utm_source') || '');
      setCampaignMedium(urlParams.get('utm_medium') || '');
      setCampaignName(urlParams.get('utm_campaign') || '');
      setCampaignContent(urlParams.get('utm_content') || '');

      const customParams = [];
      urlParams.forEach((value, name) => {
        if (!['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'].includes(name)) {
          customParams.push({ name, value });
        }
      });
      setParameters(customParams);
    }
  }, [selectedUrl]); 
  
  
  const dynamicParams = [
    "{{campaign.id}}",
    "{{adset.id}}",
    "{{ad.id}}",
    "{{campaign.name}}",
    "{{adset.name}}",
    "{{ad.name}}",
    "{{placement}}",
    "{{site_source_name}}"
  ];

  const addParameter = () => {
    setParameters([...parameters, { name: '', value: '' }]);
  };

  const removeParameter = (index) => {
    const updatedParameters = parameters.filter((_, i) => i !== index);
    setParameters(updatedParameters);
  };

  const handleParameterChange = (index, field, value) => {
    const updatedParameters = [...parameters];
    updatedParameters[index][field] = value;
    setParameters(updatedParameters);
  };

  const handleCampaignSourceChange = (e) => {
    setCampaignSource(e.target.value);
  };

  const handleCampaignMediumChange = (e) => {
    setCampaignMedium(e.target.value);
  };

  const handleCampaignNameChange = (e) => {
    setCampaignName(e.target.value);
  };

  const handleCampaignContentChange = (e) => {
    setCampaignContent(e.target.value);
  };

  const handleFocus = (type, index) => {
    if (type === 'source') setShowSourceDropdown(true);
    if (type === 'medium') setShowMediumDropdown(true);
    if (type === 'name') setShowNameDropdown(true);
    if (type === 'content') setShowContentDropdown(true);
    if (type === 'custom'){
      setCustomDropdownVisibility(prev => ({
        ...prev,
        [index]: true,
      }));;
    }  
  };

  const handleBlur = (type, index) => {
    setTimeout(() => {
      if (type === 'source') setShowSourceDropdown(false);
      if (type === 'medium') setShowMediumDropdown(false);
      if (type === 'name') setShowNameDropdown(false);
      if (type === 'content') setShowContentDropdown(false);
      if (type === 'custom'){
        setCustomDropdownVisibility(prev => ({
          ...prev,
          [index]: false,
        }));;
      } 
    }, 100);
  };

const handleDynamicParamSelect = (param, type, index) => {
  if (type === 'source') {
    setCampaignSource(param);
    setShowSourceDropdown(false);
  }
  if (type === 'medium') {
    setCampaignMedium(param);
    setShowMediumDropdown(false);
  }
  if (type === 'name') {
    setCampaignName(param);
    setShowNameDropdown(false);
  }
  if (type === 'content') {
    setCampaignContent(param);
    setShowContentDropdown(false);
  }
  if (type === 'custom' && index !== undefined) {
    const updatedParameters = [...parameters];
    updatedParameters[index] = { name: updatedParameters[index].name, value: param };
    setParameters(updatedParameters);
    setCustomDropdownVisibility(prev => ({
      ...prev,
      [index]: false,
    }));
  }
};


  // Construct the URL preview dynamically
  const constructUrlPreview = () => {
    let queryParams = [];
  
    const appendParam = (name, value) => {
      if (value && value.includes('{{') && value.includes('}}')) {
        queryParams.push(`${name}=${value}`);
      } else {
        queryParams.push(`${name}=${encodeURIComponent(value)}`);
      }
    };
  
    // Add campaign parameters to the URL (utm_source, utm_medium, utm_campaign, utm_content)
    if (campaignSource) appendParam("utm_source", campaignSource);
    if (campaignMedium) appendParam("utm_medium", campaignMedium);
    if (campaignName) appendParam("utm_campaign", campaignName);
    if (campaignContent) appendParam("utm_content", campaignContent);
  
    // Add custom parameters to the URL
    parameters.forEach(param => {
      if (param.name && param.value) {
        appendParam(param.name, param.value);
      }
    });
  
    return queryParams.length > 0
      ? `${queryParams.join('&')}`  
      : ''; 
  };

  
  return (
    <Flex direction={'column'}> 
      <Flex my={2}>
        <Text fontSize={'13px'} fontWeight={'black'}>{`Fill in the fields in the form below to add parameters to your website URL. To automatically get information from your campaign, ad set or ad, click on each field and select a dynamic parameter, such as ID={{ad.id}}.`}</Text>
      </Flex>

      {/* Campaign Source */}
      <Flex direction={'column'} gap={2} py={2}>
        <Flex gap={1}>
          <Flex flex={0.2}>
            <Text fontWeight={'bold'}>Campaign source</Text>
          </Flex>
          <Flex direction={'column'} flex={0.8} gap={2} position="relative">
            <Input
              placeholder="Select a dynamic parameter or enter a value."
              value={campaignSource}
              onChange={handleCampaignSourceChange}
              onFocus={() => handleFocus('source')}
              onBlur={() => handleBlur('source')}
              size="sm"
              bg="white"
              borderRadius={'md'}
            />
            {/* Dynamic parameters dropdown for Campaign Source */}
            {showSourceDropdown && (
              <ChakraBox
                bg="white"
                borderRadius="md"
                border="1px solid #ccc"
                position="absolute"
                top={10}
                left="0"
                width="100%"
                maxHeight="250px"
                overflowY="auto"
                zIndex="10"
              >
                <List spacing={1}>
                  {dynamicParams.map((param, index) => (
                    <ListItem
                      key={index}
                      px={3}
                      py={1}
                      m={1}
                      cursor="pointer"
                      fontSize={'12px'}
                      _hover={{ bg: "#f0f0f0", borderRadius:'sm' }}
                      onClick={() => handleDynamicParamSelect(param, 'source')}
                    >
                      {param}
                    </ListItem>
                  ))}
                </List>
              </ChakraBox>
            )}
            <Text fontSize={'12px'} color={'gray'} letterSpacing={'-0.1px'} py={'5px'}>To identify the source of traffic, e.g. Facebook, Instagram, a search engine or another source.</Text>
          </Flex>
        </Flex>

        {/* Campaign Medium */}
        <Flex gap={1}>
          <Flex flex={0.2}>
            <Text fontWeight={'bold'}>Campaign medium</Text>
          </Flex>
          <Flex direction={'column'} flex={0.8} gap={2} position="relative">
            <Input
              placeholder="Select a dynamic parameter or enter a value."
              value={campaignMedium}
              onChange={handleCampaignMediumChange}
              onFocus={() => handleFocus('medium')}
              onBlur={() => handleBlur('medium')}
              size="sm"
              bg="white"
              borderRadius={'md'}
            />
            {/* Dynamic parameters dropdown for Campaign Medium */}
            {showMediumDropdown && (
              <ChakraBox
                bg="white"
                borderRadius="md"
                border="1px solid #ccc"
                position="absolute"
                top={10}
                left="0"
                width="100%"
                maxHeight="250px"
                overflowY="auto"
                zIndex="10"
              >
                <List spacing={1}>
                  {dynamicParams.map((param, index) => (
                    <ListItem
                      key={index}
                      px={3}
                      py={1}
                      m={1}
                      cursor="pointer"
                      fontSize={'12px'}
                      _hover={{ bg: "#f0f0f0", borderRadius:'sm' }}
                      onClick={() => handleDynamicParamSelect(param, 'medium')}
                    >
                      {param}
                    </ListItem>
                  ))}
                </List>
              </ChakraBox>
            )}
            <Text fontSize={'12px'} color={'gray'} letterSpacing={'-0.1px'} py={'5px'}>To identify the advertising medium. For example, "banner", "email", "Facebook_Feed" or "Instagram_Story".</Text>
          </Flex>
        </Flex>

        {/* Campaign Name */}
        <Flex gap={1}>
          <Flex flex={0.2}>
            <Text fontWeight={'bold'}>Campaign name</Text>
          </Flex>
          <Flex direction={'column'} flex={0.8} gap={2} position="relative">
            <Input
              placeholder="Select a dynamic parameter or enter a value."
              value={campaignName}
              onChange={handleCampaignNameChange}
              onFocus={() => handleFocus('name')}
              onBlur={() => handleBlur('name')}
              size="sm"
              bg="white"
              borderRadius={'md'}
            />
            {/* Dynamic parameters dropdown for Campaign Name */}
            {showNameDropdown && (
              <ChakraBox
                bg="white"
                borderRadius="md"
                border="1px solid #ccc"
                position="absolute"
                top={10}
                left="0"
                width="100%"
                maxHeight="250px"
                overflowY="auto"
                zIndex="10"
              >
                <List spacing={1}>
                  {dynamicParams.map((param, index) => (
                    <ListItem
                      key={index}
                      px={3}
                      py={1}
                      m={1}
                      cursor="pointer"
                      fontSize={'12px'}
                      _hover={{ bg: "#f0f0f0", borderRadius:'sm' }}
                      onClick={() => handleDynamicParamSelect(param, 'name')}
                    >
                      {param}
                    </ListItem>
                  ))}
                </List>
              </ChakraBox>
            )}
            <Text fontSize={'12px'} color={'gray'} letterSpacing={'-0.1px'} py={'5px'}>To identify a specific promotion or strategic campaign. For example, "summer_sale".</Text>
          </Flex>
        </Flex>

        {/* Campaign Content */}
        <Flex gap={1}>
          <Flex flex={0.2}>
            <Text fontWeight={'bold'}>Campaign content</Text>
          </Flex>
          <Flex direction={'column'} flex={0.8} gap={2} position="relative">
            <Input
              placeholder="Select a dynamic parameter or enter a value."
              value={campaignContent}
              onChange={handleCampaignContentChange}
              onFocus={() => handleFocus('content')}
              onBlur={() => handleBlur('content')}
              size="sm"
              bg="white"
              borderRadius={'md'}
            />
            {/* Dynamic parameters dropdown for Campaign Content */}
            {showContentDropdown && (
              <ChakraBox
                bg="white"
                borderRadius="md"
                border="1px solid #ccc"
                position="absolute"
                bottom="100%"
                left="0"
                width="100%"
                maxHeight="250px"
                overflowY="auto"
                zIndex="10"
                mb={2}
              >
                <List spacing={1}>
                  {dynamicParams.map((param, index) => (
                    <ListItem
                      key={index}
                      px={3}
                      py={1}
                      m={1}
                      cursor="pointer"
                      fontSize={'12px'}
                      _hover={{ bg: "#f0f0f0", borderRadius:'sm' }}
                      onClick={() => handleDynamicParamSelect(param, 'content')}
                    >
                      {param}
                    </ListItem>
                  ))}
                </List>
              </ChakraBox>
            )}
            <Text fontSize={'12px'} color={'gray'} letterSpacing={'-0.1px'} py={'5px'}>To differentiate ads or urls that point to the same URL. For example, "white_logo", "black_logo".</Text>
          </Flex>
        </Flex>
      </Flex>

      {/* Custom Parameters Section (only visible when parameters exist) */}
      {parameters.length > 0 && (
        <Flex direction="column" gap={4} py={4} position="relative">
          <Divider />
          <Text fontWeight="bold" fontSize={'14px'}>Custom parameters</Text>

          {/* "Parameter name" and "Value" Labels (Appearing Only Once) */}
          <Flex gap={4}>
            <Text fontWeight="bold" fontSize="14px" flex="1">Parameter name</Text>
            <Text fontWeight="bold" fontSize="14px" flex="1">Value</Text>
          </Flex>

          {parameters.map((param, index) => (
            <Flex key={index} align="center" gap={2}>
              <Input
                placeholder="Example: Language"
                value={param.name}
                onChange={(e) => handleParameterChange(index, 'name', e.target.value)}
                size="sm"
                bg="white"
                borderRadius={'md'}
                flex="1"
              />
              <Input
                placeholder="Example: English"
                value={param.value}
                onChange={(e) => handleParameterChange(index, 'value', e.target.value)}
                size="sm"
                bg="white"
                borderRadius={'md'}
                flex="1"
                onFocus={() => handleFocus('custom', index)}
                onBlur={() => handleBlur('custom', index)}
              />
              {/* Dynamic parameters dropdown for Custom */}
              {customDropdownVisibility[index] && (
                <ChakraBox
                  bg="white"
                  borderRadius="md"
                  border="1px solid #ccc"
                  position="absolute"
                  left={300}
                  width="40%"
                  top={'100%'}
                  maxHeight="250px"
                  overflowY="auto"
                  zIndex="10"
                >
                  <List spacing={1}>
                    {dynamicParams.map((param, i) => (
                      <ListItem
                        key={i}
                        px={3}
                        py={1}
                        m={1}
                        cursor="pointer"
                        fontSize={'12px'}
                        _hover={{ bg: "#f0f0f0", borderRadius:'sm' }}
                        onClick={() => handleDynamicParamSelect(param, 'custom', index)}
                      >
                        {param}
                      </ListItem>
                    ))}
                  </List>
                </ChakraBox>
              )}
              <IconButton
                aria-label="Remove parameter"
                icon={<CloseIcon />}
                size="sm"
                bg={'gray.100'}
                onClick={() => removeParameter(index)}
              />
            </Flex>
          ))}
        </Flex>
      )}

      <Flex>
        <Button 
          bg={'gray.50'}
          size="sm"
          borderWidth={1}
          onClick={addParameter}
        >
          Add parameter
        </Button>
      </Flex>

      <Flex my={4}>
        <Divider />
      </Flex>

      {/* URL Preview Section */}
      <Flex direction={'column'}>
        <Text fontWeight="bold" fontSize={'14px'} mb={2}>Parameter preview</Text>
        <Box border={'1px'} p={2} borderColor={'gray.100'} borderRadius={'md'} bg={'gray.50'} minH={8}>
          <Text fontSize="12px" color="gray.600">{constructUrlPreview()}</Text>
        </Box>
      </Flex>

       {/* Buttons moved here */}
       <Flex justifyContent="flex-end" py={4} gap={2}>
          <Button size={'sm'} onClick={onClose}>Cancel</Button>
          <Button size={'sm'} colorScheme='orange'
           onClick={() => {
            onUrlChange(constructUrlPreview(), selectedAccountId) 
            onClose()
            }}>
            Apply
          </Button>
      </Flex>

    </Flex>
  );
}

export default UrlParameter;
